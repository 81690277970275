const renameItem = (userID, name, hood, design, sleeveLength) => {
    let returnVal = ""
    console.log(hood, sleeveLength, design)
    if ((name.toLowerCase().includes("t-shirts"))) {
        if (includesIgnoreCase(sleeveLength, "long sleeves")) {
            returnVal = "Longsleeve"
        }
        else {
            returnVal = "Tee"
        }

        if (includesIgnoreCase(design, "picture")) {
            returnVal = "Graphic " + returnVal
        }

        
    }
    else if ((name.toLowerCase().includes("sweatshirts") || name.toLowerCase().includes("sweaters"))){
        if (hood && includesIgnoreCase(hood, "hood")) {
            returnVal = "Hoodie"
        }
        else {
            returnVal = "Crewneck"
        }

        if (includesIgnoreCase(design, "picture")) {
            returnVal = "Graphic " + returnVal
        }
    }
    else {
        return name
    }

    return returnVal
  }

function includesIgnoreCase(arr, searchValue) {
    if (!arr) {
        return false
    }
    const lowerCaseSearchValue = searchValue.toLowerCase();
    return arr.some(function (element) {
        return element.toLowerCase() === lowerCaseSearchValue;
    });
}
  
  export default renameItem;