import React, { useState, useEffect } from 'react'
import { css, styled, setup } from 'goober'

setup(React.createElement)

const Ol = styled('ol')`
  margin-top: -20px;
  padding-bottom: 0;
  list-style-type: none;
  text-align: center;
`
const Li = styled('li')`
  display: inline-block;
  text-align: center;
  line-height: 4.8rem;
  padding: 0 5.5rem;
  cursor: pointer;

  color: silver;
  border-bottom: 2px solid silver;

  &:hover,
  &:before {
    color: black;
  }
  &:after {
    content: "\\00a0\\00a0";
  }   
  span {
    padding: 0 1.5rem;
  }
  &:before {
    position: relative;
    float: left;
    left: 50%;
    width: 1.2em;
    line-height: 1.4em;
    border-radius: 0;
    bottom: -3.99rem;
  }
`
const Todo = css`
  &:before {
    content: "\u039F";
    color: silver;
    background-color: white;
  }
`
const Doing = css`
  &:before {
    content: "\u2022";
    color: white;
    background-color: black;  
  }
`
const Done = css`
  &:before {
    content: "\u2713";
    color: white;
    background-color: black;
  }
`

const getStep = (defaultIndex, newIndex, length) => {
    if(newIndex <=  length){
        return newIndex;
    }
    return defaultIndex;
  }

const getTopNavStyles = (indx, length) => {
  const styles = []
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push('done')
    } else if (i === indx) {
      styles.push('doing')
    } else {
      styles.push('todo')
    }
  }
  return styles
}

const getButtonsState = (indx, length) => {
  if (indx > 0 && indx < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true
    }
  } else if (indx === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true
    }
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false
    }
  }
}

export default function MultiStep (props) {
  const { activeComponentClassName, inactiveComponentClassName } = props

  // const [activeStep] = useState(getStep(0, props.activeStep,  props.steps.length));
  const activeStep = props.activeStep;
  const setActiveStep = props.setActiveStep;
  const [stylesState, setStyles] = useState(getTopNavStyles(activeStep, props.steps.length))
  const [compState, setComp] = useState(activeStep)  
  
  useEffect(() => {
    // console.log('Index changed: ', props.activeStep);
    setStepState(props.activeStep);
  }, [props.activeStep]);
  
  const setStepState = (indx) => {
    setStyles(getTopNavStyles(indx, props.steps.length))
    setComp(indx < props.steps.length ? indx : compState)
  }


  const handleOnClick = evt => {
    if (
      evt.currentTarget.value === props.steps.length - 1 &&
      compState === props.steps.length - 1
    ) {
      setStepState(props.steps.length)
      setActiveStep(props.state.length)
    } else {
      setStepState(evt.currentTarget.value)
      setActiveStep(evt.currentTarget.value)

    }
  }

  const stepName = (index) =>{
    if(index===0){
      return "Title"
    }
    else if (index===1){
      return "Outline"
    }
    return "Blog" 
  }
  const renderSteps = () =>
    props.steps.map((s, i) => {
      if (stylesState[i] === 'todo') {
        return (
          <Li
            className={Todo}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <span>{stepName(i)}</span>
          </Li>
        )
      } else if (stylesState[i] === 'doing') {
        return (
          <Li
            className={Doing}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <span>{stepName(i)}</span>
          </Li>
        )
      } else {
        return (
          <Li
            className={Done}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <span>{stepName(i)}</span>
          </Li>
        )
      }
    })

  return (
    <div>
      <Ol>{renderSteps()}</Ol>
      {inactiveComponentClassName
        ? props.steps.map((step, index) => {
            const className = index === compState ? activeComponentClassName : inactiveComponentClassName
            return (<div className={className} key={index}>{step.component}</div>)
          })
        : <div>{props.steps[compState].component}</div>}
    </div>
  )
}
