import React, { useEffect, useState } from "react";
import { getAuth } from "@firebase/auth";
import TagUploads from "./tagUploads";

export function MMCoats({ history }) {
  let userID;
  const auth = getAuth();
  const user = auth.currentUser;
  const [proceed, setProceed] = useState(false);

  if (user !== null) {
    userID = auth.currentUser.uid;
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      const token = localStorage.getItem("token");

      if (!token || !user) {
        history.push("/");
      } else {
        setProceed(true);
      }
    });
  }, []);

  return <>
          {proceed ? 
            <TagUploads 
              collection='mm-coats' 
              userID={userID} 
            /> : 
            null}
          </>;
}
export default MMCoats;
