import { Add, Close } from '@mui/icons-material';
import React, { useState } from 'react';
import axios from 'axios'

const DragAndDropGallery = ({ images, backendReqBody, backendImageArrKeyName, backendEndpoint, rowIndex }) => {
  const [imageList, setImageList] = useState(images || []);
  const [isUploading, setIsUploading] = useState(false)
  const onDragStart = (e, index) => {
    e.dataTransfer.setData('index', index.toString());
    e.dataTransfer.setData('url', imageList[index]);
    e.dataTransfer.setData('rowIndex',rowIndex);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = async (e, index) => {
    e.preventDefault();
    if (parseInt(e.dataTransfer.getData("rowIndex")) !== rowIndex) {
        return
    }
    const draggedIndex = parseInt(e.dataTransfer.getData('index'));
    const updatedList = Array.from(imageList);
    const [draggedItem] = updatedList.splice(draggedIndex, 1);
    updatedList.splice(index, 0, draggedItem);
    try {
        await handleBackendUpdate(updatedList);
    } catch (err) {
        console.error('Error updating images:', err);
        return 
    }
    setImageList(updatedList);
  };


  const handleImageDelete = async (index) => {
    const newData = [...imageList]
    newData.splice(index, 1)
    try {
        await handleBackendUpdate(newData);
    } catch (err) {
        console.error('Error updating images:', err);
        return 
    }
    setImageList(newData)
  } 

  const handleBackendUpdate = async (imgArr) => {
    const body = backendReqBody
    body[backendImageArrKeyName] = imgArr
    try {
        const response = await axios.put(backendEndpoint, body);
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
    
    
  }

  const handleImageUpload = async (imgFiles) => {
    if (imgFiles.length < 1) {
        return
    }
    setIsUploading(true);

    const files = Array.from(imgFiles);
    const urls = [];
    try {
        for (const file of files) {
            const { name = '' } = file;

            const response = await fetch(
                'https://apie.snapwrite.ca/storeData/s3Url'
            );
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch S3 URL, status ${response.status}`
                );
            }

            const { url } = await response.json();
            if (!url) {
                throw new Error('Failed to get S3 URL');
            }

            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/jpeg',
                },
                body: file,
            };

            const uploadResponse = await fetch(url, options);
            if (!uploadResponse.ok) {
                throw new Error(
                    `Failed to upload image, status ${uploadResponse.status}`
                );
            }
            else {
                urls.push(url.split("?")[0])
                console.log(url)
            }
        }
        const newData = imageList ? [...imageList, ...urls] : [...urls];
        try {
            await handleBackendUpdate(newData);
        } catch (err) {
			console.error('Error updating images:', err);
            setIsUploading(false);
            return 
		}


        setIsUploading(false);

        setImageList(newData);
    } catch (err) {
        setIsUploading(false);
        console.error('Error uploading image:', err);
    }}

    const handleRowChange = async (url) => {
        const newData = imageList ? [...imageList, url] : [url];
        try {
            await handleBackendUpdate(newData);
        } catch (err) {
			console.error('Error updating images:', err);
            return 
		}
        setImageList(newData);
    }

  return (
    <div className='flex gap-5 flex-wrap'>
      {imageList.map((imageUrl, index) => (
        <div key={`${imageUrl}-${index}`} indexclassName='w-48 h-72 relative'>

            <div
            key={index}
            className='w-48 h-72 relative'
            draggable
            onDragStart={(e) => onDragStart(e, index)}
            onDragOver={(e) => onDragOver(e)}
            onDrop={(e) => onDrop(e, index)}
            >
                <button
                    className='absolute top-1 right-1 bg-transparent outline-none border-none cursor-pointer'
                    onClick={(e) => {
                        // handleImageDelete(
                        //     color,
                        //     imageIndex
                        // );
                        handleImageDelete(index)
                    }}
                >
                    <Close />
                </button>
                <img src={imageUrl} alt={`Image ${index}`} className="w-full h-full object-cover rounded-md"/>
            </div>
        </div>        
      ))}
      <div 
      style={imageList.length >= 1 ? {marginLeft: "50px"} : {}}
      onDrop={(e) => {
        e.preventDefault()
        const url = e.dataTransfer.getData("url")
        url ? handleRowChange(url) : handleImageUpload(e.dataTransfer.files)}}
		   onDragOver={(e) => e.preventDefault()} className='w-48 h-72 bg-lightgray-200 flex flex-col justify-center items-center cursor-pointer'>
      <label
        className='block'
        htmlFor={`add-button-${rowIndex}`}
        >
            <div 
            style={{border: "4px dashed grey"}}
            className='w-48 h-72 bg-gray-200 flex flex-col justify-center items-center cursor-pointer'>
                <Add
                    style={{
                        fontSize: '2rem',
                        margin: '10px',
                    }}
                />
                <input
                    type='file'
                    className='hidden'
                    id={`add-button-${rowIndex}`}
                    name={`add-button`}
                    multiple
                    accept='image/*'
                    onChange={(e) => {
                        handleImageUpload(
                            e.target.files
                        );
                    }}
                />
            </div>
        </label>
    </div>
    </div>
  );
};

export default DragAndDropGallery;