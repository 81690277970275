import React, { useContext, useState, useEffect, useMemo } from 'react';

import ProjectsGroup from '../Library/projectsGroup';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import GetData from './getData';
import { OrganizationContext } from '../App';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import MainSideMenuHeaderContainer from '../containers/MainSideMenuHeaderContainer';
import { Heading1 } from '../branded-elements/Elements';
import axios from 'axios';

const SharedProjects = ({ history }) => {
	let userID;
	const auth = getAuth();
	const user = auth.currentUser;
	const preference = localStorage.getItem('preference');
	const { organization, setOrganization } = useContext(OrganizationContext);

	if (user !== null) {
		userID = auth.currentUser.email;
	}

	const [activeMenu, setActiveMenu] = useState('all');
	const { caller } = useState('projectsView');

	const [sortedClothingLabels, setSortedClothingLabels] = useState([]);
	const [regularPreLovedApparel, setRegularPreLovedApparel] = useState([]);
	const [vintageApparel, setVintageApparel] = useState([]);
	const [newApparel, setNewApparel] = useState([]);
	const [allProjects, setAllProjects] = useState([]);
	const [acceptedProjects, setAcceptedProjects] = useState([]);
	const [pendingProjects, setPendingProjects] = useState([]);
	const [rejectedProjects, setRejectedProjects] = useState([]);
	const [archivedProjects, setArchivedProjects] = useState([]);
	const [lastOrganization, setLastOrganization] = useState({
		id: null,
		name: null,
	});
	const [mmApparel, setMmApparel] = useState([]);
	const [mmSportswear, setMmSportswear] = useState([]);

	const [sortedLabels, setSortedLabels] = useState([]);
	const [projectCount, setProjectCount] = useState({});

	const [tabValue, setTabValue] = useState(
		localStorage.getItem('tabValue') || '1'
	);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
		localStorage.setItem('tabValue', newValue);
	};

	useEffect(() => {
		const fetchData = async (id) => {
			const foundData = await GetData(
				id,
				setVintageApparel,
				'vintage-apparel-shared',
				'vintage-apparel-view'
			);

			// Sort foundData based on the dateCreated property
			foundData.sort(
				(a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
			);

			const filteredData = foundData.filter((item) => !item.isArchived);
			const accepted = filteredData.filter(
				(item) => item.offerAcceptStatus === 'accepted'
			);
			const rejected = filteredData.filter(
				(item) => item.offerAcceptStatus === 'rejected'
			);
			const pending = filteredData.filter(
				(item) =>
					item.offerAcceptStatus === 'pending' ||
					item.offerAcceptStatus === undefined
			);
			const archived = foundData.filter((item) => item.isArchived);

			setAllProjects(filteredData);
			setAcceptedProjects(accepted);
			setRejectedProjects(rejected);
			setPendingProjects(pending);
			setArchivedProjects(archived);

			setProjectCount({
				all: filteredData.length,
				accepted: accepted.length,
				pending: pending.length,
				rejected: rejected.length,
				archived: archived.length,
			});
		};

		if (!organization) return;
		if (lastOrganization.id !== organization?.id) {
			fetchData(organization?.id);
			getImages(organization?.id);
		}

		setLastOrganization(organization);
	}, [organization]);
	  

	
	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			const token = localStorage.getItem('token');

			if (!token || !user) {
				history.push('/');
			}
		}, []);
	}, []);


	// Function to update projectName based on ID
	const handleProjectNameChange = async (id, newName, endpoint) => {

		const body = {
			id: id,
			projectName: newName,
		};

		const currentEndpoint =
			'https://apie.snapwrite.ca/storeData/vintage-apparel-projectNameUpdate';

		try {
			const response = await axios.put(currentEndpoint, body);

			return response.data;
		} catch (error) {
			console.error('Error:', error);
			throw new Error('Failed to update project name');
		}
	};
	  

	const getImagesEndpoint = async (endpoint, id) => {
		return fetch(
			`https://apie.snapwrite.ca/storeData/${endpoint}?userID=${id}`
		)
			.then((response) => response.json())
			.then((json) => {
				let number = 0;
				json.data.map((val, index) => {
					// The image for mm-table is stored in s3Images vs other clients which is images
					if (val.images && endpoint !== 'mm-table') {
						number += val.images.length;
					} else if (val.s3Images && endpoint === 'mm-table') {
						number += val.s3Images.length;
					}
				});
				return number;
			})
			.catch((err) => {
				console.log(`Error ${err}`);
			});
	};

	const getImages = async (id) => {
		const dataEndpoints = [
			'labeling-clothes-categorization',
			'regular-preLoved-apparel',
			'vintage-apparel',
			'new-apparel',
			'mm-table',
			'mm-sports',
		];

		const allImages = await Promise.all(
			dataEndpoints.map(
				async (endpoint) => await getImagesEndpoint(endpoint, id)
			)
		);
	};

	return (
		<MainSideMenuHeaderContainer>
			<Heading1 title={'Shared Projects'} />
			<div className='mb-32'>
				<TabContext value={tabValue}>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: 'divider',
						}}
					>
						<>
							<TabList onChange={handleChange} aria-label='Tabs'>
								<Tab
									className='outline-none focus:outline-none'
									label={`All`}
									value='1'
								/>
								<TabChip totalCount={projectCount.all} />
								<Tab
									className='outline-none focus:outline-none'
									label='Pending'
									value='2'
								/>
								<TabChip totalCount={projectCount.pending} />

								<Tab
									className='outline-none focus:outline-none'
									label='Accepted'
									value='3'
								/>
								<TabChip totalCount={projectCount.accepted} />

								<Tab
									className='outline-none focus:outline-none'
									label='Rejected'
									value='4'
								/>
								<TabChip totalCount={projectCount.rejected} />
								<Tab
									className='outline-none focus:outline-none'
									label='Archived'
									value='5'
								/>
								<TabChip totalCount={projectCount.archived} />
							</TabList>
						</>
					</Box>

					{/* All View  */}
					<TabPanel value='1'>
						<ProjectsGroup
							projects={allProjects}
							setProjects={setAllProjects}
							handleProjectNameChange={handleProjectNameChange}
						/>
					</TabPanel>

					{/* Pending  */}
					<TabPanel value='2'>
						<ProjectsGroup
							projects={pendingProjects}
							setProjects={setPendingProjects}
							handleProjectNameChange={handleProjectNameChange}
						/>
					</TabPanel>

					{/* Vintage  */}
					<TabPanel value='3'>
						<ProjectsGroup
							projects={acceptedProjects}
							setProjects={setAcceptedProjects}
							handleProjectNameChange={handleProjectNameChange}
						/>
					</TabPanel>

					{/* New  */}
					<TabPanel value='4'>
						<ProjectsGroup
							projects={rejectedProjects}
							setProjects={setRejectedProjects}
							handleProjectNameChange={handleProjectNameChange}
						/>
					</TabPanel>

					{/* Archived Items  */}

					<TabPanel value='5'>
						<ProjectsGroup
							archived={true}
							projects={archivedProjects}
							setProjects={setArchivedProjects}
							handleProjectNameChange={handleProjectNameChange}
						/>
					</TabPanel>
				</TabContext>
			</div>
		</MainSideMenuHeaderContainer>
	);
};

function TabChip({ totalCount }) {
	return (
		<p className='mt-2 ml-[2px] mr-4 bg-slate-100 text-sm rounded-full w-8 h-8 text-center flex items-center justify-center text-slate-600 '>
			{totalCount}
		</p>
	);
}
export default SharedProjects;
