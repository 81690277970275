
import { useEffect, useState } from "react";
import trash from "../../../assets/trash_can.png";

import PropertiesColumn from "./tableComponents/propertiesColumn";
import ImageColumn from "./tableComponents/imageColumn";

import handleDelete from "./utils/handleRowDelete";

import MeasurementColumn from "./tableComponents/measurementColumn";

import SupplementaryImageColumn from "./tableComponents/supplementaryImageColumn";
import axios from "axios";

import { RowColumn } from "./tableList";

let buttonDisabled = true;
const VendorTable = (props) => {
  const prop = props.prop;
	const filterVendor = prop.filterVendor
  const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
  });
  if (prop.price[prop.price.length - 1] !== "") {
    prop.description.forEach((description) => {
      if (description === "") {
        buttonDisabled = false;
      } else {
        buttonDisabled = true;
      }
    });
  } else {
    buttonDisabled = true;
  }


  return (
    <table>
      <thead style={{ borderBottom: "1px solid" }}>
        <tr className="tableheader">
          {/* Item", "Product Type", "Gender", "Color", "Additional Information" */}
          {/* Item, Item Type, Brand, Size, Colours, Condition, Additional Info */}
          <th className="tablecol1"></th>
          <th className="imgcol">Item</th>
          {prop.supplementaryImages && prop.supplementaryImages.length > 0 ? (
            <th></th>
          ) : null}
          <th>Brand</th>
          <th>Item Name</th>
          <th>Item Type</th>
          <th>SKU</th>
          <th>Color(s)</th>
          <th>Condition</th>
          <th>Size</th>
          <th>Cost</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody id="rows">
        {prop.s3Images.map((key, index) => {

					// Do not show this row if the vendor type doesn't match
					if (typeof filterVendor === 'string' 
					 && typeof prop.vendor[index] === 'string' 
					 && prop.vendor[index].split(" ").join("-") !== filterVendor) 
					 
					 return null;

          return prop.s3Images[index] !== undefined ? (
            <tr
              key={index}
              id={index}
            >
              <td className="tablecol1" style={{height: "2vh", width: "1vw"}}>
              </td>
              {/* {console.log(rows)} */}
              <ImageColumn 
                images={prop.s3Images}
                singleProductModelFilter={{userID: prop.userId, sku: prop.sku[index]}}
                fileName={prop.imageFileName[index]}
                setS3Images={prop.setS3Images}
                index={index}
                template={prop.template}
                id={prop.objectID}
              />
              {prop.supplementaryImages && prop.supplementaryImages[index] ? (
                <SupplementaryImageColumn
                singleProductModelFilter={{userID: prop.userId, sku: prop.sku[index]}}
									disabled={true}
                  prop={prop}
                  index={index}
                />
              ) : null}

							{/* Brand */}
							<RowColumn disabled={true} name="brand" type="ta" index={index} prop={prop} style={{width: "102px", height: "102px"}}/>
							{/* Item Name */}
							<RowColumn disabled={true} name="itemName" type="ta" index={index} prop={prop} style={{width: "120px", height: "120px"}}/>
							{/* Item Type */}
							<RowColumn disabled={true} name="typesTaxoUpdate" type="ta" index={index} prop={prop} attr="taxonomyUpdatedType" style={{width: "105px", height: "120px"}}/>
							{/* SKU */}
							<RowColumn disabled={true} name="sku" type="ta" index={index} prop={prop} style={{width: "90px", height: "120px"}}/>
              {/* Color */}
							<RowColumn disabled={true} name="colors" type="ta" index={index} prop={prop} style={{width: "102px", height: "100px"}}/>
              {/* Condition */}
							<RowColumn disabled={true} name="condition" store="vintage" type="select" index={index} prop={prop} />
							{/* Size */}
							<RowColumn disabled={true} name="size" type="select" index={index} prop={prop} />
              {/* Cost */}
							<RowColumn disabled={true} name="costPerItem" attr="cost" type="number" index={index} prop={prop} style={{width: "102px", paddingBottom: "1.7rem"}}/>
              {/* Price */}
							<RowColumn disabled={true} name="price" type="number" index={index} prop={prop} style={{width: "102px", paddingBottom: "1.7rem"}}/>
              {/* 
              <DescriptionColumn
                index={index}
                prop={prop}
                generatePause={props.generatePause}
                setGeneratePause={props.setGeneratePause}
              />
              */}
            </tr>
          ) : null;
        })}
      </tbody>
    </table>
  );
};

export default VendorTable;
