import { useRef, useEffect, useState } from "react";
import PropertiesColumn from "../../tableComponents/propertiesColumn";
import ImageColumn from "../../tableComponents/imageColumn";
import SupplementaryImageColumn from "../../tableComponents/supplementaryImageColumn";
import handleDelete from "../../utils/handleRowDelete";
import trash from "../../../../../assets/trash_can.png"
import DescriptionColumn from "../../tableComponents/descriptionColumn";
import axios from "axios";

const DemoTable = (props) =>{
  const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
  });
  const prop = props
  const handleHover = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "visible";

    const cells = Object.values(tableRow.children);
    for (let i = 0; i < cells.length; i++) {
      let textArea = Object.values(cells[i].children);

      for (let j = 0; j < textArea.length; j++) {
        if (
          textArea[j] != null &&
          (textArea[j].nodeName == "TEXTAREA" ||
            textArea[j].nodeName == "INPUT")
        ) {
          textArea[j].style.backgroundColor = "#E5E5E5";
        }
      }
    }
  };

  // Make trash button hidden when the table row is not being hovered and return cells to their original colour
  const handleMouseOut = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "hidden";

    const cells = Object.values(tableRow.children);
    for (let i = 0; i < cells.length; i++) {
      let textArea = Object.values(cells[i].children);

      for (let j = 0; j < textArea.length; j++) {
        if (
          index % 2 === 0 &&
          textArea[j] != null &&
          (textArea[j].nodeName == "TEXTAREA" ||
            textArea[j].nodeName == "INPUT")
        ) {
          textArea[j].style.backgroundColor = "white";
        } else if (
          index % 2 !== 0 &&
          textArea[j] != null &&
          (textArea[j].nodeName == "TEXTAREA" ||
            textArea[j].nodeName == "INPUT")
        ) {
          textArea[j].style.backgroundColor = "#F3F3F3";
        }
      }
    }
  };


useEffect(() => {
  if (prop.itemGenericType && prop.itemGenericType.length>0) { 
    // Taxonomy(prop.itemGenericType.slice());
  }
  if(prop.types && prop.types.length>0 ){
    let change = true;
    prop.itemName.forEach(element => {
      if(element!==""){
        change = false
      }
    });
    if(change){
      prop.setItemName(prop.types)
    }
  }
}, []);

    return (
        <table>
          <thead style={{ borderBottom: "1px solid" }}>
            <tr className="tableheader">
              {/* Item", "Product Type", "Gender", "Color", "Additional Information" */}
              {/* Item, Item Type, Brand, Size, Colours, Condition, Additional Info */}
              <th className="tablecol1"></th>
              <th className="imgcol">Item</th>
              <th>Item Name</th>
              <th>Item Type</th>
              <th>Gender</th>
              <th>Color(s)</th>
              <th>Attributes</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody id="rows">

          {props.s3Images.map((key, index) => {
          return (
            <tr id={index} onMouseOver={(event) => handleHover(event)} onMouseOut={(event) => handleMouseOut(event)}>
              <td className = "tablecol1">
                <img src={trash} id={`trash${index}`} alt="delete" style={{height: "2vh", width: "1vw", opacity: 0.5, visibility: "hidden"}}  onClick={() => {handleDelete(index, props)}}/>
              </td>
              {/* {console.log(rows)} */}
              <ImageColumn
              singleProductModelFilter = {{userID: props.userID, sku: props.sku[index]}}
              image={props.s3Images[index]} fileName={props.imageFileName[index]} />
              <td>
                {/* Item Name */}
                <textarea
                  style={{
                    background: index % 2 !== 0 ? "#F3F3F3" : "white",
                    border: "none",
                    width: "150px",
                    resize: "none",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ",") {
                      e.preventDefault();
                    }
                  }}
                  value={props.itemName[index]}
                  onChange={(event) => props.updateVal('itemName',event.target.value, index, props.itemName, props.setItemName)}
                />
              </td>
              <td>
                {/* Item Type */}
                <textarea
                  style={{
                    background: index % 2 !== 0 ? "#F3F3F3" : "white",
                    border: "none",
                    width: "100px",
                    resize: "none",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ",") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => props.updateVal('typesTaxoUpdate', event.target.value, index, props.typesTaxoUpdate, props.setTypesTaxoUpdate)}
                  value={props.typesTaxoUpdate[index] ? props.typesTaxoUpdate[index] : null}
                />
              </td>
              
              {/* Gender */}
              <td>
                <textarea
                  style={{
                    background: index % 2 !== 0 ? "#F3F3F3" : "white",
                    border: "none",
                    width: "80px",
                    resize: "none",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ",") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => props.updateVal('genders', event.target.value, index, props.genders, props.setGender)}
                  value={props.genders[index] ? props.genders[index] : null}
                />
              </td>
              {/* Color */}
              <td>
                <textarea
                  style={{
                    background: index % 2 !== 0 ? "#F3F3F3" : "white",
                    border: "none",
                    width: "102px",
                    resize: "none",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ",") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => props.updateVal('colors', event.target.value, index, props.colors, props.setColor)}
                  value={props.colors ? props.colors[index] : null}
                />
              </td>

            <PropertiesColumn index={index} prop={prop} />
              <td>
            <textarea
            name="description"
            style={{
                background: index % 2 !== 0 ? "#F3F3F3" : "white",
                border: "none",
                height: "11em",
                resize: "none",
                width: "500px",
            }}
            onKeyDown={(e) => {                   
                if (e.key === ",") {
                e.preventDefault();
                }
            }}
            onChange={(e) => {
                if (e.currentTarget.value.includes(",")) {
                e.currentTarget.value = e.currentTarget.value.replace(
                    /,/g,
                    ";"
                );
                }
            prop.updateVal('description', e.currentTarget.value, index, prop.description, prop.setDescription);                    
            }}
            value={
                prop.description ? prop.description[index] : null
            }
            />
            {/* <div><button className="btn">Generate</button></div> */}
      </td>
             
             
            </tr>
          )
        })}
          </tbody>
        </table>
      );
          }

export default DemoTable;