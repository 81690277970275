import React, { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import axios from "axios";
import { ImageUploadCode } from "../imageUpload";
import { TagsInput } from "../tagsArea";
import { wordCount } from "../../components/wordCountArea";
import { Link, useHistory } from "react-router-dom";
;

const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
});

//Send userID, userName, tagsUsed and descriptions generated from mainProduct

export function Blog(props) {
    const history = useHistory();
    /* **************************Tags area************************** */
    const userID = props.user;
    const userName = props.userName;
    const selectedTags = (tags) => {
        // console.log(tags);
    };
    const image = props.image;
    const setImage = props.setImage;
    const suggestedTags = props.suggestedTags;
    const setSuggestedTags = props.setSuggestedTags;
    const tags = props.tags;
    const setTags = props.setTags;
    const blogTitles = props.blogTitles;
    const setBlogTitles = props.setBlogTitles;
    const blogTitle = props.blogTitle;
    const setBlogTitle = props.setBlogTitle;
    const blogOutline = props.blogOutline;
    const setBlogOutline = props.setBlogOutline;
    const titleGenerateClick = props.titleGenerateClick;
    const setTitleGenerateClick = props.setTitleGenerateClick;
    const outlineGenerateClick = props.outlineGenerateClick;
    const setOutlineGenerateClick = props.setOutlineGenerateClick;
    const activeState = props.activeState;
    const setActiveState = props.setActiveState;

    const [noOfImagesUsed, setNumberOfImagesUsed] = useState();
    const [wordLimit, setWordLimit] = useState(2500);
    const [imageLimit, setImageLimit] = useState(10);
  
    useEffect(()=>{    
        //communicate with the backend!
        fetch(`https://apie.snapwrite.ca/storeData/image-count?userID=${userID}`)
        .then(response=>response.json())
        .then(response=>{
            setNumberOfImagesUsed(response.data)
            if(userID==="S4nF6oUynvUfkVQjTIcyqyCPZQg2"){
                setImageLimit(80);
              }
        })
        .catch(err=>{
            console.log(`Error ${err}`)
        })
    },[userID])
  
  

    /* ************************************************************* */            
    
    const copyToClipboard = (text) => {
        copy(text);
        alert(`Copied  "${text}"`);
    };

    async function genDescription1(tags) {
        try {
            if(props.noOfWordsGenerated>250000){
            setBlogTitles(["contact us", "contact us", "contact us", "contact us" ]);
            }
            else{
                setTitleGenerateClick(titleGenerateClick+1);
                setBlogTitles(["Loading...", "Loading...", "Loading...", "Loading..." ]);
                const res = await backendClient.post("/blog-ideas", {
                    tags: tags,
                    user: props.user,
                });                
                let returnArray = res.data.split(/\n/)
                while(returnArray[0]===""){                                                   
                    returnArray = returnArray.slice(1)                            
                }
                const number = new RegExp(/\d/);            
                returnArray.forEach((idea, index) => {  
                    idea = idea.trim()
                    if((number.test(idea[0]) && idea[1]==='.' ) || (number.test(idea[0]) && idea[1]===')' )){
                        idea = idea.slice(2).trim()
                    }
                    else if(idea[0]==='-'){
                        idea = idea.slice(1).trim()
                    }                
                    if(idea[0] === '"' ){
                        idea = idea.slice(1, -1)
                    }      
                    returnArray[index] = idea;
                });
                // console.log(returnArray)
                setBlogTitles(returnArray);
            }
        } catch (e) {
            setBlogTitles("Some error occured");
            console.error(e);
        }
    }
    
    async function genBlogOutline(idea) {
        try {            
            if(props.noOfWordsGenerated>250000){
                setBlogOutline("contact us")    
            }
            else{

                setOutlineGenerateClick(outlineGenerateClick+1);
                const res = await backendClient.post("/blog-outline", {
                    idea: idea,
                    user: props.user,
                });
                setBlogTitle(idea)
                setBlogOutline(res.data)
                // console.log(res.data)    
                setActiveState(1)
                           
            }
        } catch (e) {
            alert("Sorry for the inconvenience. Please try again");
            console.error(e);
        }
    }

    function storeDescriptions(description1, description2, tags) {
        fetch(`https://apie.snapwrite.ca/storeData`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userID: userID,
                userName: userName,
                generatedDescription1: description1,
                generatedDescription2: description2,
                wordCount: wordCount(description1) + wordCount(description2),
                tagsUsed: tags,
            }),
        })
            .then((response) => response.json())
            .catch((err) => {
                console.log(`Error ${err}`);
            });
    }

    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function (event) {
        if (!event.target.matches(".dropbtn")) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains("show")) {
                    openDropdown.classList.remove("show");
                }
            }
        }
    };
    const handleChange = (event, index) =>{
        
        const newTags = blogTitles.slice();
        newTags[index] = event;
        setBlogTitles(newTags)    
      }
    
    return (
        
                    <div className="tools">
                        <div className="drop-and-tags">
                            {/* ************The image upload area************** */}
                            <ImageUploadCode
                                selectedTags={selectedTags}
                                setTags={setTags}
                                setSuggestedTags={setSuggestedTags}
                                setImage={setImage}
                                tags={tags}
                                suggestedTags={suggestedTags}
                                image={image}
                                noOfImagesUsed={noOfImagesUsed}            
                                noOfWordsGenerated={props.noOfWordsGenerated}
                            />
                            {/* ************************************************ */}
                            <div className="card">
                                <div className="card-header"> Tags </div>
                                <div className="card-body appTag">
                                    {/* **************************Tags area************************** */}
                                    <div className="appTag">
                                        <TagsInput
                                            selectedTags={selectedTags}
                                            tags={tags}
                                            setTags={setTags}
                                        />
                                    </div>
                                    <div className="tagsNumberDesc">
                                        Add/Edit 1-2 words to describe the subject for the blog ideas
                                    </div>
                                    <button
                                        className="btn btn-primary1"
                                        disabled={titleGenerateClick>50 || props.noOfWordsGenerated>250000 || noOfImagesUsed>1000 || noOfImagesUsed===1000}
                                        onClick={() => {                                            
                                            genDescription1(tags);                                            
                                        }}
                                    >
                                        Generate
                                    </button>
                                </div>
                            </div>
                            {/* ************************************************************* */}
                        </div>
                        <div className="descriptionSection">
                            <div>
                            <div className="card-header blogTitle-header">
                                Blog Title
                            </div>
                            <div className={`blog-idea-box ${blogTitles[0] === props.blogTitle ? "selectedHighlight" : null}`}>
                                <div className="blog-idea ">
                                    {/* {console.log(blogTitles[0])} */}
                                {blogTitles[0]!=="Blog title will appear in about 10 seconds" ? <textarea      
                                    name="blogOutline"
                                    type="text"
                                    value={blogTitles[0]}                                            
                                    onChange = {(event)=>{handleChange(event.target.value, 0)}}
                                    style={blogTitles[0] === props.blogTitle ? { fontWeight: 600 } : {fontWeight:400} }
                                    data-hj-allow
                                />  :
                                blogTitles }                                
                                {/* {blogTitles[0]!==""? (blogTitles[0].length>0? blogTitles:blogTitles[0]): blogTitles[1]} */}
                                </div>
                                <div className="card-footer-blog">
                                    <div className="wordCountArea">                                   
                                        <button className="generateOutlineButton" onClick={()=>{genBlogOutline(blogTitles[0] )}} disabled={outlineGenerateClick>50} >
                                            Generate blog outline
                                        </button>                                    
                                        <div className="p-2 bd-highlight" onClick={()=>copyToClipboard(blogTitles[0])}>
                                            copy
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className={`blog-idea-box card2 ${blogTitles[1] === props.blogTitle ? "selectedHighlight" : null}`} >
                                {/* <div className="card-header ">
                                    Blog Ideas
                                </div> */}
                                <div className="blog-idea ">
                                {blogTitles[0]!=="Blog title will appear in about 10 seconds" ?
                                <textarea      
                                    name="blogOutline"
                                    type="text"
                                    value={blogTitles[1]}                                            
                                    onChange = {(event)=>{handleChange(event.target.value, 1)}}
                                    style={blogTitles[1] === props.blogTitle ? { fontWeight: 600 } : {fontWeight:400} }
                                    data-hj-allow
                                />  : blogTitles
                                }   
                                {/* {blogTitles[0]!==""? (blogTitles[0].length>0? blogTitles:blogTitles[0]): blogTitles[2]} */}
                                </div>
                                <div className="card-footer-blog">
                                    <div className="wordCountArea">                                       
                                             <button className="generateOutlineButton" onClick={()=>{genBlogOutline(blogTitles[1])}} disabled={outlineGenerateClick>50} >
                                                Generate blog outline
                                            </button>                                                                                    
                                        <div className="p-2 bd-highlight" onClick={()=>copyToClipboard(blogTitles[1])} >
                                            copy
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className={`blog-idea-box card2 ${blogTitles[2] === props.blogTitle ? "selectedHighlight" : null}`} >
                                {/* <div className="card-header ">
                                    Blog Ideas
                                </div> */}
                                <div className="blog-idea ">
                                {blogTitles[0]!=="Blog title will appear in about 10 seconds" ?
                                <textarea      
                                    name="blogOutline"
                                    type="text"
                                    value={blogTitles[2]}                                            
                                    onChange = {(event)=>{handleChange(event.target.value, 2)}}
                                    style={blogTitles[2] === props.blogTitle ? { fontWeight: 600 } : {fontWeight:400} }
                                    data-hj-allow
                                /> : 
                                blogTitles }
                                {/* {blogTitles[0]!==""? (blogTitles[0].length>0? blogTitles:blogTitles[0]): blogTitles[3]} */}

                                </div>
                                <div className="card-footer-blog">
                                    <div className="wordCountArea">                                    
                                        <button className="generateOutlineButton" onClick={()=>{genBlogOutline(blogTitles[2])}} disabled={outlineGenerateClick>50} >
                                            Generate blog outline
                                        </button>                                    
                                        <div className="p-2 bd-highlight" onClick={()=>copyToClipboard(blogTitles[2])} >
                                            copy
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className={`blog-idea-box card2 ${blogTitles[3] === props.blogTitle ? "selectedHighlight" : null}`} >
                                {/* <div className="card-header ">
                                    Blog Ideas
                                </div> */}
                                <div className="blog-idea ">
                                {blogTitles[0]!=="Blog title will appear in about 10 seconds" ?
                                <textarea      
                                    name="blogOutline"
                                    type="text"
                                    value={blogTitles[3]}                                            
                                    onChange = {(event)=>{handleChange(event.target.value, 3)}}
                                    style={blogTitles[3] === props.blogTitle ? { fontWeight: 600 } : {fontWeight:400} }
                                    data-hj-allow
                                /> :
                                blogTitles }    
                                {/* {blogTitles[0]!==""? (blogTitles[0].length>0? blogTitles:blogTitles[0]): blogTitles[4]} */}
                                </div>
                                <div className="card-footer-blog">
                                    <div className="wordCountArea">                                   
                                        <button className="generateOutlineButton" onClick={()=>{genBlogOutline(blogTitles[3])}} disabled={outlineGenerateClick>50} >
                                            Generate blog outline
                                        </button>                                    
                                        <div className="p-2 bd-highlight" onClick={()=>copyToClipboard(blogTitles[3])} >
                                            copy
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>              
    );
}
export default Blog;
