const detachable = [
    "Removable",
    "Separable",
    "Disconnectable",
    "Unattached",
    "Separated",
    "Unfastened",
    "detachable"
  ];

  export const hoodFurAttachment = [detachable]