import testimonialImg from '../../assets/testimonial.png';

export const FormBackground = () => {
	return (
		<section className=' text=[#333333] font-["Inter", sans-serif] lg:flex lg:flex-col min-h-full justify-center items-center hidden text-center gap-5 '>
			

			<div className='text-[24px]  font-[500] mb-2'>
				How brands, retailers and resellers are <br/>using Snapwrite for their business
			</div>
			{/* <iframe
				className=' rounded-3xl'
				width='640'
				height='388'
				src='https://www.youtube.com/embed/YTGoAvvvA-M?si=lgdK7048Rju6pG0r'
				title='YouTube video player'
				frameborder='0'
				allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;'
                autoPlay={1}
			></iframe> */}
            <video
                src="https://storage.googleapis.com/test1729-1/SnapWrite%20Video%20Snippet%20-%20Draft%206.mp4"
                title='introVideo'
                className=' rounded-3xl'
				width='640'
                controls={true}
                autoPlay={true}
                muted={true}
                controlsList="nodownload"                
                playsInline=""
            ></video>
			{/* <iframe width="560" height="315" src="https://youtu.be/YTGoAvvvA-M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

			{/* Testimonials  */}
			<div className=' text-[24px] font-[500] mt-12 italic  '>
				“SnapWrite has significantly boosted <br /> our productivity and
				sales output.”
				<p className='mt-3 text-sm font-[400]'>
					<img
						className='h-10 mr-2'
						src={testimonialImg}
						alt='Amanda'
					/>{' '}
					Amanda, Founder of Tokki{' '}
				</p>
			</div>
		</section>
	);
};
