const casual = [
    "Informal",
    "Relaxed",
    "Laid-back",
    "Easygoing",
    "Everyday",
    "Nonchalant",
    "Simple",
    "casual"
  ];
  
  const elegant = [
    "Graceful",
    "Sophisticated",
    "Refined",
    "Stylish",
    "Chic",
    "Classy",
    "Upscale",
    "elegant"
  ];
  
  const sport = [
    "Athletic",
    "Active",
    "Agile",
    "Excercise",
    "Sporty style",
    "Athletic look",
    "Active-inspired",
    "Energetic attire",
    "Dynamic clothing",
    "Fitness",
    "Casual sportswear",
    "Athleisure clothing",
    "Dynamically designed",
    "Energetic attire",
    "Lively",
    "sportswear",
    "Agile fashion",
    "Exercise-inspired attire",
    "Sport-influenced look",
    "Athletic-inspired clothing",
    "sport"
  ];

  const synonymsArray = [casual, elegant, sport]

export const getStyleSynonyms = (style) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => style.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 