import { useState, useEffect } from "react";
import axios from "axios";

const BagsDescriptionColumn =(props) => {
  let prop = props.prop
  let index = props.index  

	const [description, setDescription] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(()=> {
		if (prop.description.length === 0) return;
		setDescription(prop.description[index]);
	}, [prop.description])

	const backendClient = axios.create({
			baseURL: "https://apif.snapwrite.ca/",
		});

	const generateDescription = async (index)=>{
		let tags = []

      tags = [
        `Brand: ${prop.brand[index]?prop.brand[index]:""}`,
        `${prop.itemName[index]?prop.itemName[index]:""}`,
        `Item Type: ${prop.types[index]}`,  
        `Era: ${prop.era[index]}`,
        `Color: ${prop.colors[index]}`,
        `size: ${prop.size[index]}`,
        `Material: ${prop.material[index]}`, 
        `Neckline: ${prop.neckline[index]}`,
        `${prop.style[index]?prop.style[index]:""}`,
        `${prop.sleeves[index]?prop.sleeves[index]:""}`,
        `${prop.cut[index]?prop.cut[index]:""}`, 
        `${prop.fasteningType[index]?prop.fasteningType[index]:""}`, 
        // prop.length[index],
        `${prop.lining[index]?prop.lining[index]:''}`,
        `${prop.layers[index]?prop.layers[index]:""}`,
        `${prop.pattern[index]?prop.pattern[index]:""}`,
        `${prop.skirt[index]?prop.skirt[index]:""}`,
        `${prop.rise[index]?prop.rise[index]:""}`,
        `${prop.design[index]?prop.design[index]:""}`,
      ]
    if(prop.itemGenericType[index] && prop.itemGenericType[index].toLowerCase().includes('footwear')) {
      tags = [
        `${prop.itemGenericType[index]?prop.itemGenericType[index]:''}`,
        `${prop.itemName[index]?prop.itemName[index]:""}`,
        `Brand: ${prop.brand[index]}`,
        `Item Type: ${prop.types[index]}`,  
        `Gender: ${prop.genders[index]}`,            
        `Color: ${prop.colors[index]}`, 
        `Age group or size: ${prop.size[index]}`,
        `${prop.material[index]?prop.material[index]:""}`, 
        `${prop.style[index]?prop.style[index]:""}`,
        `${prop.rise[index]? prop.rise[index]: ""}`,
        `${prop.design[index]?prop.design[index]:""}`,
        `${prop.heelHeight[index]?prop.heelHeight[index]:""}`,
        `${prop.heelShape[index]?prop.heelShape[index]:""}`,
        `${prop.height[index]? prop.height[index]:""}`,
        `${prop.toe[index]?prop.toe[index]:""}`  
      ]
  }
  // let description = prop.description[index]
  try {
		setLoading(true)
		setDescription("Loading...");
    
    const res = await backendClient.post("/clothingDescription", {
        tags: tags,
        user: 'VPQROHGh7pNC9du2JnL4fIopeoh1'
      });         
      let result = res.data
      if(result!==""){    
        if (result.includes(`"`)) {
          result = result.replace(
            /"/g,
            `'`
          );
        }    
      }
    prop.updateVal(
        'description',
        result,
        index,
        prop.description,
        prop.setDescription
      )                 
		setDescription(result)
		setLoading(false)
  } catch (err) {
    prop.updateValIndividual('description', description, index, 'description');  
    prop.updateVal(
        'description',
        description,
        index,
        prop.description,
        prop.setDescription
      )                  
		setDescription(description)
		setLoading(false)
    console.log(err)
  }
}
        
    return(
        <td>
            <textarea
            name="description"
            disabled={loading}
            placeholder="Click on the update button if you would like a paragraph descrition or you can leave if blank 😄"
            style={{
                background: index % 2 !== 0 ? "#F3F3F3" : "white",
                border: "none",
                height: "11.5em",
                resize: "none",
                width: "500px",
            }}
            onKeyDown={(e) => {                   
                if (e.key === ",") {
                e.preventDefault();
                }
            }}
            onChange={(e) => {
                if (e.currentTarget.value.includes(",")) {
                e.currentTarget.value = e.currentTarget.value.replace(
                    /,/g,
                    ";"
                );
                }
						setDescription(e.currentTarget.value);
            prop.updateVal(
                'description',
                e.currentTarget.value,
                index,
                prop.description,
                prop.setDescription
              )    
                             
            }}
            value={description}
            />
            <button className ='btn' disabled={loading} onClick={()=>{generateDescription(index)}}>Update</button>
      </td>
        
    )
}
export default BagsDescriptionColumn;