import '../css/login.css';
import React, { useContext, useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { FormBackground } from '../components/formsBackground/background';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { OrganizationContext } from '../components/App';
import AuthenticationPageView from './AuthenticationPageView';
// import { GoogleLogin } from 'react-google-login';

const Login = ({ history }) => {
	const { organization, setOrganization } = useContext(OrganizationContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorEmail, setErrorEmail] = useState('');
	const [errorPassword, setErrorPassword] = useState('');
	const [checked, setChecked] = useState(false);
	const [checkedError, setCheckedError] = useState('');

	const isMM = organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1';

	useEffect(() => {
		const token = localStorage.getItem('token');
		const preference = localStorage.getItem('preference');
		const accountType = localStorage.getItem('accountType');

		if (token) {
			if (isMM) {
				history.push('/mm-coats/new');
			}
			const auth = getAuth();
			if (auth.currentUser) {
				localStorage.setItem('token', auth.currentUser.accessToken);
				if (preference === null || accountType === null) {
					axios
						.get(
							`https://apie.snapwrite.ca/storeData/user-preferences/${auth.currentUser.uid}`
						)
						.then((res) => {
							if (
								res.data &&
								res.data.data !== null &&
								res.data.data.tablePreference !== null
							) {
								localStorage.setItem(
									'preference',
									res.data.data.tablePreference
								);

								if (res.data.data.accountType !== null) {
									localStorage.setItem(
										'accountType',
										res.data.data.accountType
									);
									if (
										auth.currentUser.email ===
											'manteauxmanteauxdemo@snapwrite.ca' ||
										auth.currentUser.email ===
											'carmela@manteaux.com' ||
										isMM
									) {
										history.push('/mm-coats/new');
									} else if (
										res.data.data.accountType === 'seller'
									) {
										history.push('/projects');
									} else {
										if (
											res.data.data.tablePreference ===
											'vintage'
										) {
											history.push('/vintage');
										} else if (
											res.data.data.tablePreference ===
											'pre-loved'
										) {
											history.push('/bulk-shopify');
										} else if (
											auth.currentUser.uid ===
												'1eMplQPlHHhX9lnwX8NTcKghui22' ||
											auth.currentUser.uid ===
												'7eXVFZlMcgY7HMKKSkCf4kb7qEt1' ||
											auth.currentUser.uid ===
												'FYLY9osdEkU7QtLsTgtmitcmqAD2' ||
											auth.currentUser.uid ===
												'My3oqQGYaST4EQaYbDEwrcYfWgn1' ||
											auth.currentUser.uid ===
												'80saF2wZqdRUhagajkE83wam8Js2'
										) {
											//vintage
											history.push('/vintage');
										} else {
											history.push('/bulk-shopify');
										}
									}
								} else {
									localStorage.setItem(
										'accountType',
										'reseller'
									);
									if (
										auth.currentUser.email ===
											'manteauxmanteauxdemo@snapwrite.ca' ||
										auth.currentUser.email ===
											'carmela@manteaux.com' ||
										isMM
									) {
										history.push('/mm-coats/new');
									} else if (
										res.data.data.tablePreference ===
										'vintage'
									) {
										history.push('/vintage');
									} else if (
										res.data.data.tablePreference ===
										'pre-loved'
									) {
										history.push('/bulk-shopify');
									} else if (
										auth.currentUser.uid ===
											'1eMplQPlHHhX9lnwX8NTcKghui22' ||
										auth.currentUser.uid ===
											'7eXVFZlMcgY7HMKKSkCf4kb7qEt1' ||
										auth.currentUser.uid ===
											'FYLY9osdEkU7QtLsTgtmitcmqAD2' ||
										auth.currentUser.uid ===
											'My3oqQGYaST4EQaYbDEwrcYfWgn1' ||
										auth.currentUser.uid ===
											'80saF2wZqdRUhagajkE83wam8Js2'
									) {
										//vintage
										history.push('/vintage');
									} else {
										history.push('/bulk-shopify');
									}
								}
							} else if (
								res.data.data &&
								res.data.data.accountType
							) {
								localStorage.setItem(
									'accountType',
									res.data.data.accountType
								);
								localStorage.setItem('preference', 'pre-loved');
								if (
									auth.currentUser.email ===
										'manteauxmanteauxdemo@snapwrite.ca' ||
									auth.currentUser.email ===
										'carmela@manteaux.com' ||
									isMM
								) {
									history.push('/mm-coats/new');
								} else if (
									res.data.data.accountType === 'seller'
								) {
									history.push('/vintage');
								} else {
									history.push('/bulk-shopify');
								}
							} else {
								localStorage.setItem('accountType', 'reseller');
								localStorage.setItem('preference', 'pre-loved');
								if (
									auth.currentUser.email ===
										'manteauxmanteauxdemo@snapwrite.ca' ||
									auth.currentUser.email ===
										'carmela@manteaux.com' ||
									isMM
								) {
									history.push('/mm-coats/new');
								} else {
									history.push('/bulk-shopify');
								}
							}
						})
						.catch((err) => {
							console.error(err);
						});
				} else if (preference && !accountType) {
					localStorage.setItem('accountType', 'reseller');
					if (
						auth.currentUser.email ===
							'manteauxmanteauxdemo@snapwrite.ca' ||
						auth.currentUser.email === 'carmela@manteaux.com' ||
						isMM
					) {
						history.push('/mm-coats/new');
					} else if (preference === 'vintage') {
						history.push('/vintage');
					} else if (preference === 'pre-loved') {
						history.push('/bulk-shopify');
					} else if (
						auth.currentUser.uid ===
							'1eMplQPlHHhX9lnwX8NTcKghui22' ||
						auth.currentUser.uid ===
							'7eXVFZlMcgY7HMKKSkCf4kb7qEt1' ||
						auth.currentUser.uid ===
							'FYLY9osdEkU7QtLsTgtmitcmqAD2' ||
						auth.currentUser.uid ===
							'My3oqQGYaST4EQaYbDEwrcYfWgn1' ||
						auth.currentUser.uid === '80saF2wZqdRUhagajkE83wam8Js2'
					) {
						//vintage
						history.push('/vintage');
					} else {
						history.push('/bulk-shopify');
					}
				} else if (preference && accountType) {
					if (
						auth.currentUser.email ===
							'manteauxmanteauxdemo@snapwrite.ca' ||
						auth.currentUser.email === 'carmela@manteaux.com' ||
						isMM
					) {
						history.push('/mm-coats/new');
					} else if (accountType === 'seller') {
						history.push('/forms');
					} else {
						if (preference === 'vintage') {
							history.push('/vintage');
						} else if (preference === 'pre-loved') {
							history.push('/bulk-shopify');
						} else if (
							auth.currentUser.uid ===
								'1eMplQPlHHhX9lnwX8NTcKghui22' ||
							auth.currentUser.uid ===
								'7eXVFZlMcgY7HMKKSkCf4kb7qEt1' ||
							auth.currentUser.uid ===
								'FYLY9osdEkU7QtLsTgtmitcmqAD2' ||
							auth.currentUser.uid ===
								'My3oqQGYaST4EQaYbDEwrcYfWgn1' ||
							auth.currentUser.uid ===
								'80saF2wZqdRUhagajkE83wam8Js2'
						) {
							//vintage
							history.push('/vintage');
						} else {
							history.push('/bulk-shopify');
						}
					}
				} else {
					localStorage.setItem('accountType', 'reseller');
					localStorage.setItem('preference', 'pre-loved');
					if (
						auth.currentUser.email ===
							'manteauxmanteauxdemo@snapwrite.ca' ||
						auth.currentUser.email === 'carmela@manteaux.com' ||
						isMM
					) {
						history.push('/mm-coats/new');
					} else {
						history.push('bulk-shopify');
					}
				}
			}
		}
	});

	useEffect(() => {
		// Set the body's style to allow scrolling when the component mounts
		document.body.style.overflow = 'auto';
	
		// Clean up the effect by restoring the original overflow style when the component unmounts
		return () => {
		  document.body.style.overflow = 'hidden';
		};
	  }, []);

	const handleGoogle = async (e) => {
		const provider = await new GoogleAuthProvider();
		const auth = getAuth();
		try {
			const result = await signInWithPopup(auth, provider);
			localStorage.setItem('token', auth.currentUser.accessToken);
		} catch (error) {
			console.error(error);
		}
	};

	const onLogin = () => {
		setLoading(true);
		const auth = getAuth();
		if (checked !== false) {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {
					localStorage.setItem(
						'token',
						userCredential._tokenResponse.idToken
					);
					if (isMM) {
						history.push('/mm-coats/new');
					}
					axios
						.get(
							`https://apie.snapwrite.ca/storeData/user-preferences/${userCredential.user.uid}`
						)
						.then((res) => {
							if (
								res.data &&
								res.data.data !== null &&
								res.data.data.tablePreference !== null &&
								res.data.data.accountType !== null
							) {
								localStorage.setItem(
									'preference',
									res.data.data.tablePreference
								);
								localStorage.setItem(
									'accountType',
									res.data.data.accountType
								);

								if (
									email ===
										'manteauxmanteauxdemo@snapwrite.ca' ||
									email === 'carmela@manteaux.com' ||
									isMM
								) {
									history.push('/mm-coats/new');
								} else if (
									res.data.data.accountType === 'seller'
								) {
									history.push('/forms');
								} else {
									if (
										res.data.data.tablePreference ===
										'vintage'
									) {
										history.push('/vintage');
									} else {
										history.push('/bulk-shopify');
									}
								}
							} else {
								if (
									email ===
										'manteauxmanteauxdemo@snapwrite.ca' ||
									email === 'carmela@manteaux.com' ||
									isMM
								) {
									history.push('/mm-coats/new');
								} else {
									history.push('/bulk-shopify');
								}
								localStorage.setItem('preference', 'pre-loved');
								localStorage.setItem('accountType', 'reseller');
							}
						})
						.catch((err) => console.error(err));
				})
				.catch((e) => {
					// console.log("error ="+e.code)
					switch (e.code) {
						case 'auth/invalid-email':
							setErrorEmail('Please check the email');
							break;
						case 'auth/wrong-password':
							setErrorPassword('Please check the password');
							break;
						case 'auth/user-not-found':
							setErrorEmail(
								`You are not signed up yet! Please click on request access`
							);
							setErrorPassword('');
							break;
						default:
							break;
					}
				})
				.finally(() => setLoading(false));
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			if (event.target.className === 'email' && password === '') {
				document.getElementsByClassName('password')[0].focus();
			} else {
				document.getElementsByClassName('logInButtton')[0].click();
			}
		}
	};

	return (
		<AuthenticationPageView>
			<div className='mt-8'>
				<h1 className='text-3xl'>Login</h1>

				<div className=''>
					<span style={{ color: 'red' }}>{errorEmail}</span>
					<input
						value={email}
						type='email'
						name='email'
						className='email'
						required='required'
						placeholder='email'
						onChange={(e) => setEmail(e.target.value)}
						onKeyDown={(event) => {
							handleKeyDown(event);
						}}
						data-hj-allow
					/>
					<span style={{ color: 'red' }}>{errorPassword}</span>
					<input
						value={password}
						placeholder='password'
						onChange={(e) => setPassword(e.target.value)}
						onKeyDown={(event) => {
							handleKeyDown(event);
						}}
						name='password'
						type='password'
						className='password'
					/>
				</div>
				<div>
					<span style={{ color: 'red' }}>{checkedError}</span>
					<div className='text-sm flex items-start mt-2 text-gray-500'>
						<input
							type='checkbox'
							className='mr-2 mt-1'
							onChange={(event) => {
								setChecked(event.target.checked);
							}}
							onKeyDown={(event) => {
								handleKeyDown(event);
							}}
						/>
						<p className='text-xs leading-6'>
							{' '}
							I agree to the&nbsp;
							<a
								href='https://drive.google.com/file/d/1yoN-XoCHkUJQ3zGqBQz6T1rmCQelXCjg/view'
								target='_blank'
								rel='noreferrer'
							>
								Terms
							</a>{' '}
							and the&nbsp;
							<a
								href='https://drive.google.com/file/d/1lmiBxHQI-Sbix7SrTMp-d5cYR2pQVGbC/view'
								target='_blank'
								rel='noreferrer'
							>
								Privacy Policy
							</a>{' '}
							before signing in.
						</p>{' '}
					</div>
				</div>

				<button
					className='logInButtton'
					onClick={() => {
						if (email === '') {
							setErrorEmail('Please enter the email');
						} else {
							setErrorEmail('');
						}
						if (password === '') {
							setErrorPassword('Please enter the password');
						} else {
							setErrorPassword('');
						}
						if (checked === false) {
							setCheckedError(
								'Please agree to the terms and conditions'
							);
						} else {
							setCheckedError('');
						}
						if (email !== '' && password !== '') {
							onLogin();
						}
					}}
				>
					Login
				</button>

				<br />
				<Link className="text-sm" to='/forgot'>Forgot password?</Link>

				<hr className='verticalLine ' />
				<p className='registerButton text-sm'>
					Don't have an account?{" "}
					<a
						href='https://www.snapwrite.ca/#Form-section'
						target='_blank'
						rel='noreferrer'
					>
						Request Access
					</a>
				</p>
			</div>
		</AuthenticationPageView>
	);
};

export default Login;
