import React from 'react'
import TagTable from './tagTable'
import SportswearTable from './sportswearTable'

const MmTables = (props) => {
    
    return (
        props.collection === "mm-coats" ?
            <TagTable
                prop ={props}
            />
        : props.collection === "mm-sports" ?
            <SportswearTable
                prop = {props}
            />
        : null
    )
    
}

export default MmTables