// import { Redirect } from "@shopify/app-bridge/actions";
// import { useAppBridge, Loading } from "@shopify/app-bridge-react";
// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// export default function Exitiframe() {
//  const app = useAppBridge();
//  const { search } = useLocation();

//  useEffect(() => {
//    if (!!app && !!search) {
//      const params = new URLSearchParams(search);
//      const redirectUri = params.get("redirectUri");
//      const url = new URL(decodeURIComponent(redirectUri));

//      if (url.hostname === location.hostname) {
//        const redirect = Redirect.create(app);
//        redirect.dispatch(
//          Redirect.Action.REMOTE,
//          decodeURIComponent(redirectUri)
//        );
//      }
//    }
//  }, [app, search]);

//  return <Loading />;
// }

import { Redirect } from "@shopify/app-bridge/actions";
import { useAppBridge, Loading } from "@shopify/app-bridge-react";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import { Banner, Layout, Page } from "@shopify/polaris";

export default function ExitIframe() {
  const app = useAppBridge();
	const history = useHistory();
  const { search } = useLocation();
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (!!app && !!search) {
      const params = new URLSearchParams(search);
      const redirectUri = params.get("redirectUri");
      const url = new URL(decodeURIComponent(redirectUri));

      if (
        [window.location.hostname, "admin.shopify.com"].includes(url.hostname) ||
        url.hostname.endsWith(".myshopify.com")
      ) {
        const redirect = Redirect.create(app);
        redirect.dispatch(
          Redirect.Action.REMOTE,
          decodeURIComponent(redirectUri)
        );
      } else {
        setShowWarning(true);
				history.push('/projects')

      }
    }
  }, [app, search, setShowWarning]);

  return showWarning ? (
		<h1>Redirecting outside of Shopify</h1>
    // <Page narrowWidth>
    //   <Layout>
    //     <Layout.Section>
    //       <div style={{ marginTop: "100px" }}>
    //         <Banner title="Redirecting outside of Shopify" status="warning">
    //           Apps can only use /exitiframe to reach Shopify or the app itself.
    //         </Banner>
    //       </div>
    //     </Layout.Section>
    //   </Layout>
    // </Page>
  ) : ( 
    <Loading />
  );
}