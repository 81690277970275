import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import MoreVertMenu from './moreVertMenu';
import '../../css/projects.css';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Avatar, Chip, Tooltip } from '@mui/material';
import timeIcon from '../../assets/icons/time-filled.png';
import tagIcon from '../../assets/icons/tag-filled.png';
import axios from 'axios';
import { deepOrange } from '@mui/material/colors';
import { OrganizationContext } from '../App';
import { useHistory } from "react-router-dom";



const ProjectViewCard = (props) => {
const history = useHistory()

	const {sharedWith, userID}	= props;
	const [collaboratorName , setCollaboratorName] = useState('');
	const [accountType, setAccountType] = useState('');
	const { handleProjectNameChange } = props;
	const cardImages =props.image.slice(0, 4);
	const [title, setTitle] = useState(
		props.name
			? props.name
			: props.customerName
			? props.customerName
			: 'Untitled Project'
	);
	const [email, setEmail] = useState(props.email || '');
	const [phone, setPhone] = useState(props.phone || '');
	const [ownerName, setOwnerName] = useState("")
	const { organization, setOrganization } = useContext(OrganizationContext);

	const linkStyle = {
		textDecoration: 'none',
		color: '#1A1F36',
	};

	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
	};

	useEffect(() => {
		const fetchUserPreferences = async () => {
			if (sharedWith) {
				try {
					const [collaboratorResponse, ownerResponse] = await Promise.all([
						axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${sharedWith}`),
						axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`)
					]);

					setCollaboratorName(collaboratorResponse.data.data.displayName);
					setAccountType(ownerResponse.data.data.accountType);
					setOwnerName(ownerResponse.data.data.displayName)
				} catch (err) {
					console.error(`Error: ${err}`);
				}
			}
		};

		fetchUserPreferences();
	}, [sharedWith, userID]);


	let template;
	let chipColor;
	if (props.path === 'themes-view') {
		template = props.description.name;
		chipColor = 'primary';
	} else {
		if (props.endpoint === 'labeling-clothes-categorization') {
			template = 'Preloved Kids';
			chipColor = 'primary';
		} else if (props.endpoint === 'regular-preLoved-apparel') {
			template = 'Preloved Adults';
			chipColor = 'secondary';
		} else if (props.endpoint === 'new-apparel') {
			template = 'New';
			chipColor = 'success';
		} else if (props.endpoint === 'vintage-apparel' || props.path === "vintage-apparel-view") {
			template = 'Vintage';
			chipColor = 'warning';
		} else if (props.path === 'mm-coats') {
			template = 'Coats & Jackets';
			chipColor = 'primary';
		} else if (props.path === 'mm-sports') {
			template = 'Sportswear';
			chipColor = 'secondary';
		}
	}

	const handleNameChange = (event) => {
		setTitle(event.target.value);

		if (props.path === 'themes-view') {
			const body = {
				newValue: { projectTitle: event.target.value },
				_id: props.description._id,
			};
			axios.put(
				'https://apie.snapwrite.ca/storeData/upate-theme-project-data',
				body
			);
		} else {
			fetch(
				`https://apie.snapwrite.ca/storeData/${props.endpoint}-projectNameUpdate`,
				{
					...requestOptions,
					body: JSON.stringify({
						id: props.id,
						projectName: event.target.value,
					}),
				}
			).then((response) => response.json());
		}
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		updateCustomerData('defaultEmail', event.target.value);
	};

	const handlePhoneChange = (event) => {
		setPhone(event.target.value);
		updateCustomerData('defaultPhoneNumber', event.target.value);
	};

	const updateCustomerData = (name, val) => {
		fetch(
			`https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					id: props.id,
					val: val,
					name: name,
				}),
			}
		)
			.then((response) => response.json())
			.catch((err) => {
				console.log(`Error ${err}`);
			});
	};

	

	const CollaborationAvatar = () => {
		const displayName =
			organization.name === collaboratorName
				? ownerName
				: collaboratorName;
		let initials;

		if (displayName.includes(' ')) {
			const parts = displayName.split(' ');
			initials =
				parts[0].substring(0, 1).toUpperCase() +
				parts[1].substring(0, 1).toUpperCase();
		} else {
			initials = displayName.substring(0, 2).toUpperCase();
		}
			
			
				
		const avatarStyles = {
			width: 28,
			height: 28,
			marginTop: 1,
			bgcolor: deepOrange[500],
			fontSize: 12,
		};

		return (
			<div className='flex gap-1'>
				<Tooltip title={displayName} placement='top'>
					<Avatar src='/broken-image.jpg' sx={avatarStyles}>
						{initials}
					</Avatar>
				</Tooltip>
			</div>
		);
	};
	  

	return (
		<article id={props.id} className=' shadow-xl rounded w-[380px] flex flex-col p-4 max-h-[460px] relative'>
    			<div className='mb-10 cursor-pointer' onClick={() => {
					if(props.parentElement === "mm-magento-view"){
						history.push(`../${props.path}`)
					} else{
					history.push(`../${props.path}/${props.id}`, { lastPath: history.location.pathname })
					}

				}}>
        	<ImageGallery cardImages={cardImages} />
    	</div>



			<div>
				<div className='flex justify-between items-center'>
					{props.displayChip !== false && <div className='flex gap-3'>
						<Chip
							label={template}
							color={chipColor}
							variant='contained'
							className='capitalize'
						/>
						{props.isArchived && (
							<Chip
								label={'Archived'}
								variant='contained'
								className='capitalize'
							/>
						)}
					</div>}
					{(props.path !== 'themes-view' && props.displayMoreVertMenu !== false) && (
						<MoreVertMenu
							endpoint={props.endpoint}
							path={props.path}
							id={props.id}
							isArchived={props.isArchived}
							setArchives={props.setArchives}
						/>
					)}
				</div>
				<div className='my-2'>
					{/* Project title  */}
					<label htmlFor='projectTitle' className='hidden'>
						Project title
					</label>
					<input
						disabled={props.isArchived || props.disableInput === true}
						onChange={(event) => {
							handleNameChange(event);
							const endpoint =
								props.path === 'mm-coats' ||
								props.path === 'mm-sports'
									? props.path
									: props.endpoint;
							if (props.path !== 'themes-view') {
								// State Variables Update
								handleProjectNameChange(
									props.id,
									event.target.value,
									endpoint
								);
							}
						}}
						id='projectTitle'
						value={title}
						className='border-none text-lg my-2 px-2 focus:outline-none focus:bg-slate-100 rounded-sm w-72 disabled:bg-inherit'
					/>

					{/* Project Statue  */}
					{props.projectCSVGenerated ? (
						<div className='text-xs text-green-600 font-light px-2'>
							Exported
						</div>
					) : (<>
						<div className='text-xs text-slate-500 font-light px-2'>
							Not Yet Exported
						</div>
						{(sharedWith ) && (<div><CollaborationAvatar /></div>)}
						
						</>
					)}
				</div>
				<div className='h-14'>
					<div className='text-sm text-gray-400 flex gap-2 items-center absolute bottom-2 '>
						<p className=' border-solid border-l-0 border-t-0 border-b-0 border-gray-400 pr-4'>
							<img loading='lazy' src={timeIcon} alt='time' />{' '}
							{new Date(props.date).toLocaleDateString()}
						</p>
						<p>
							<img loading='lazy' src={tagIcon} alt='Tag' />{' '}
							{props.itemCount === undefined
								? ''
								: props.itemCount + ' Items'}
						</p>
					</div>
				</div>
			</div>
		</article>
	);
};

export default ProjectViewCard;

function ImagePlaceholder() {
	return <div className='h-full w-40  bg-slate-100'></div>;
}

function ImageGallery({ cardImages }) {
	// Filter out empty strings to avoid overflow issues with primary images being modified
	if (Array.isArray(cardImages)) {
		cardImages = cardImages.filter(url => url);
	} else {
		cardImages = [];
	}


	return (
		<div className='flex justify-between h-48 gap-2'>
			{/* Image Gallery starts here */}
			{cardImages.length === 0 ? (
				<>
					<ImagePlaceholder /> <ImagePlaceholder />
				</>
			) : cardImages.length === 1 ? (
				<>
					{cardImages.map((imgsrc, index) => (
						<>
							{imgsrc.length > 0 ? (
								<img
									key={index}
									src={imgsrc}
									loading='lazy'
									className='h-full w-40 object-cover rounded-md'
									alt={`Item ${index}`}
								/>
							) : (
								<ImagePlaceholder />
							)}
						</>
					))}
					<ImagePlaceholder />
				</>
			) : cardImages.length === 2 ? (
				<>
					{cardImages.slice(0, 2).map((imgsrc, index) => (
						<>
							{imgsrc.length > 0 ? (
								<img
									loading='lazy'
									key={index}
									src={imgsrc}
									className='h-full w-40 object-cover rounded-md'
									alt={`Item ${index}`}
								/>
							) : (
								<ImagePlaceholder />
							)}
						</>
					))}
				</>
			) : cardImages.length === 3 ? (
				<>
					{cardImages.slice(0, 3).map((imgsrc, index) => (
						<>
							{imgsrc.length > 0 ? (
								<img
									key={index}
									src={imgsrc}
									loading='lazy'
									className='h-full w-28 object-cover rounded-md'
									alt={`Item ${index}`}
								/>
							) : (
								<ImagePlaceholder />
							)}
						</>
					))}
				</>
			) : cardImages.length === 4 ? (
				<>
					{cardImages.slice(0, 2).map((imgsrc, index) => (
						<>
							{imgsrc.length > 0 ? (
								<img
									key={index}
									src={imgsrc}
									loading='lazy'
									className='h-full w-28 object-cover rounded-md'
									alt={`Item ${index}`}
								/>
							) : (
								<ImagePlaceholder />
							)}
						</>
					))}
					<div className='w-auto flex flex-col gap-1'>
						{cardImages.slice(2, 4).map((imgsrc, index) => (
							<>
								{imgsrc.length > 0 ? (
									<img
										key={index}
										src={imgsrc}
										loading='lazy'
										className='h-1/2 w-full object-cover rounded-md'
										alt={`Item ${index}`}
									/>
								) : (
									<ImagePlaceholder />
								)}
							</>
						))}
					</div>
				</>
			) : null}
		</div>
	);
}
