import axios from 'axios';

const localHost = 'http://localhost:3500';
const snapWriteAPI = 'https://apie.snapwrite.ca';
const API_BASE_URL = snapWriteAPI;

const templateEndpoints = {
	vintage: 'vintage-apparel',
	'new-clothes': 'new-apparel',
	'pre-loved-regular': 'regular-preLoved-apparel',
	'mm-coats': 'mm-table',
	'mm-sports': 'mm-sports',
	themes: 'themes',
};

// Function to delete items and fetch updated data
const deleteItems = async (
	checkedItemsIndicesArray,
	props,
	setCheckedItemsIndicesArray,
	setSelectedProducts
) => {
	let resData;
	const { objectID, template } = props;

	let endpoint =
		templateEndpoints[template] || 'labeling-clothes-categorization';

	if (!templateEndpoints[template]) {
		console.log('Endpoint Missing:', template);
	}

	try {
		const deleteEndpoint = `${API_BASE_URL}/storeData/${endpoint}-deleteItems`;
		// Make a PUT request to delete items
		resData = await axios
			.put(deleteEndpoint, {
				indicesToDelete: checkedItemsIndicesArray,
				objectID: objectID,
			})
			.then((res) => {
				setCheckedItemsIndicesArray([]);
				if(setSelectedProducts) setSelectedProducts([]);
				return res.data;
			}); // Clear the array
	} catch (error) {
		// Handle errors here
		console.error('An error occurred:', error);
		return; // Return early on error
	}

	return resData.data;
};

export default deleteItems;
