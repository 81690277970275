import React, { useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
// import { Header } from '../header';
import { Header } from '../components/navigation/header';
// import SideMenu from '../sideMenu';
import SideMenu from '../components/sideMenu';

//MUI JOY Tabs
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';

import {
	Snackbar,
	Button,
	Alert,
	Stepper,
	Step,
	StepLabel,
	Paper,
	StepContent,
} from '@mui/material';
import axios from 'axios';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { OrganizationContext } from '../components/App';
import { Typography, Box } from '@mui/material';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormCustomization from './settings tabs/FormCustomization';
import ListingCustomization from './settings tabs/ListingCustomization';
import AccountAndOrganization from './settings tabs/AccountAndOrganization';
import MainSideMenuHeaderContainer from '../components/containers/MainSideMenuHeaderContainer';

function Settings() {
	const { organization, setOrganization } = useContext(OrganizationContext);
	const [organizationMembers, setOrganizationMembers] = useState([]);
	const [userID, setUserID] = useState('');
	const [organizationName, setOrganizationName] = useState(
		organization?.name
	);
	const [organizationID, setOrganizationID] = useState(organization?.id);
	// const [columnsChecked, setColumnsChecked] = useState(defaultTableColumns.map(d=>false));
	const [data, setData] = useState();
	const [titleTemplate, setTitleTemplate] = useState([]);
	const [titlePreview, setTitlePreview] = useState('');
	const [previewProject, setPreviewProject] = useState({
		Color: 'Navy Blue',
		Gender: 'Womens',
		Condition: 'New',
		'Item Name': 'Cotton Socks',
		Brand: 'Nike',
		Era: 'Spring 2023',
		Size: 'M',
		Type: 'Socks',
		Measurements: 'heel: 5cm\n\nlength: 10cm',
		'Description SEO':
			"Nike socks are a perfect blend of style and performance, designed to keep your feet comfortable and supported during any activity. Crafted with high-quality materials, they offer a snug fit and moisture-wicking technology to keep sweat at bay. Whether you're hitting the gym, running, or simply lounging, Nike socks provide the ultimate combination of fashion and functionality, making them a must-have addition to your athletic wardrobe.",
	});
	const [exportPreference, setExportPreference] = useState('');

	const [descriptionTemplate, setDescriptionTemplate] = useState([]);
	const [descriptionPreview, setDescriptionPreview] = useState('');
	const [customEmailCredentials, setCustomEmailCredentials] = useState({
		email: '',
		password: '',
	});
	const [emailTemplate, setEmailTemplate] = useState('');
	const [subjectTemplate, setSubjectTemplate] = useState('');
	const [openEmailSuccessSnackBar, setOpenEmailSuccessSnackBar] =
		useState(false);
	const [openEmailFailSnackBar, setOpenEmailFailSnackBar] = useState(false);
	const [modal, setModal] = useState(false);
	const [openTemplateSuccessSnackBar, setOpenTemplateSuccessSnackBar] =
		useState(false);
	const [openTemplateFailSnackBar, setOpenTemplateFailSnackBar] =
		useState(false);
	const [tabValue, setTabValue] = React.useState(0);
	const [customSKU, setCustomSKU] = useState('');
	const [metaData, setMetaData] = useState([
		{ name: '', value: '' },
		{ name: '', value: '' },
		{ name: '', value: '' },
		{ name: '', value: '' },
		{ name: '', value: '' },
		{ name: '', value: '' },
	]);

	const appPasswordInstructions = [
		{
			label: 'Turn on 2-Step Verification (required for app password)',
			description: `With 2-Step Verification, also called two-factor authentication, you can add an extra layer of security to your account in case your password is stolen.`,
			link: `https://myaccount.google.com/signinoptions/two-step-verification/enroll-welcome`,
			linkTxt: 'Click to enable 2-Step Verification ',
		},
		{
			label: 'Create & use app passwords',
			description:
				'An app password is a 16-digit passcode that gives our app to send email on your behalf without exchanging actual credentials. Copy the password, once generated.',
			link: `https://myaccount.google.com/apppasswords`,
			linkTxt: `Click to Generate App Password`,
		},
	];
	const [activeStep, setActiveStep] = useState(0);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const isMM = organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1'; // "pDDieoXBhjgkhiJnILJPw4mpb7v1"

	useEffect(() => {
		if (organization?.id === userID) {
			fetch(
				`https://apie.snapwrite.ca/storeData/user-preferences/organization/${organization?.id}`
			)
				.then((response) => response.json())
				.then((json) => {
					if (json && json.data) {
						setOrganizationMembers(json.data);
					} else {
						console.log('json or json.data is not defined');
					}
				})
				.catch((err) => console.log(err));
		}
	}, [organization, userID]);

	useEffect(() => {
		setOrganizationID(organization?.id);
		setOrganizationName(organization?.name);
	}, [organization]);

	useEffect(() => {
		let newDescriptionPreview = '';
		const n = descriptionTemplate.length;
		descriptionTemplate.forEach((value, i) => {
			if (Object.hasOwn(previewProject, value)) {
				if (
					value !== 'Description' &&
					value !== 'Description SEO' &&
					value !== 'Measurements'
				) {
					newDescriptionPreview += value + ': ';
				}
				newDescriptionPreview += previewProject[value];
			} else {
				newDescriptionPreview += value;
			}
			if (i !== n - 1) newDescriptionPreview += '\n\n';
		});
		setDescriptionPreview(newDescriptionPreview);
	}, [descriptionTemplate, previewProject]);

	useEffect(() => {
		let newTitlePreview = '';
		titleTemplate.forEach((value, i) => {
			if (i !== 0) newTitlePreview += ' ';
			if (Object.hasOwn(previewProject, value)) {
				newTitlePreview += previewProject[value];
			} else {
				newTitlePreview += value;
			}
		});
		setTitlePreview(newTitlePreview);
	}, [titleTemplate, previewProject]);

	useEffect(() => {
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserID(user.uid);

				Promise.all([
					axios.get(
						`https://apie.snapwrite.ca/storeData/user-preferences/${user.uid}`
					),
					axios.get(
						`https://apie.snapwrite.ca/storeData/csv-title/${user.uid}`
					),
					axios.get(
						`https://apie.snapwrite.ca/storeData/get-custom-email-data?userId=${user.uid}`
					),
				])
					.then(
						([
							userPreferencesResponse,
							csvTitleResponse,
							customEmailDataResponse,
						]) => {
							// Handle the response of the first request
							if (
								userPreferencesResponse.data &&
								userPreferencesResponse.data.data &&
								userPreferencesResponse.data.data.metadata
							) {
								setMetaData(
									metaData.map((item, index) => ({
										...item,
										...userPreferencesResponse.data.data
											.metadata[index],
									}))
								);
							} else {
								console.log(
									'userPreferencesResponse.data, userPreferencesResponse.data.data, or userPreferencesResponse.data.data.metadata is not defined'
								);
							}

							const exportPreference =
								userPreferencesResponse.data.data
									.exportPreference || 'shopify';
							setExportPreference(exportPreference);

							// Handle the response of the second request
							const csvTitleData = csvTitleResponse.data.data;
							if (
								csvTitleData &&
								csvTitleData.titleBlocks &&
								csvTitleData.descriptionBlocks
							) {
								const titleBlockArr =
									csvTitleData.titleBlocks.length > 0
										? csvTitleData.titleBlocks.map(
												(title) => title.value
										  )
										: ['Brand', 'Type', 'Size'];
								const descriptionBlockArr =
									csvTitleData.descriptionBlocks.length > 0
										? csvTitleData.descriptionBlocks.map(
												(title) => title.value
										  )
										: ['Brand', 'Size', 'Condition'];
								setTitleTemplate(titleBlockArr);
								setDescriptionTemplate(descriptionBlockArr);
							} else {
								setTitleTemplate(['Brand', 'Type', 'Size']);
								setDescriptionTemplate([
									'Brand',
									'Size',
									'Condition',
								]);
								console.log(
									'csvTitleData, csvTitleData.titleBlocks, or csvTitleData.descriptionBlocks is not defined'
								);
							}

							const defaultEmailTemplate = {
								subject: `{{organizationName}} || {{displayName}} : Your Order Form`,
								message:
									'Hey {{customerName}},\nThis is {{organizationName}} || {{displayName}}! We finished going through your drop off and for what we selected, we can offer ${{cashOffer}} cash or ${{storeCredit}} store credit.\nWe can send you an etransfer or e-gift card if you send us your email address.\nYou can pick up the remainder during our open hours or we can donate to a family in need on your behalf. Have a good day.',
							};
							// Handle the response of the third request

							const customEmailData =
								customEmailDataResponse.data.data;
							if (customEmailData) {
								setSubjectTemplate(
									customEmailData.subjectTemplate.length > 0
										? customEmailData.subjectTemplate
										: defaultEmailTemplate.subject
								);
								setEmailTemplate(
									customEmailData.bodyTemplate.length > 0
										? customEmailData.bodyTemplate
										: defaultEmailTemplate.message
								);
								setCustomEmailCredentials({
									...customEmailCredentials,
									email: customEmailData.email,
									password: customEmailData.password,
								});
							} else {
								setSubjectTemplate(
									defaultEmailTemplate.subject
								);
								setEmailTemplate(defaultEmailTemplate.message);
								console.log('customEmailData is not defined');
							}
						}
					)
					.catch((err) => console.log(err));
			}
		});

		document.body.style.overflow = 'auto';
	}, []);

	const updateOrganizationID = () => {
		// localStorage.setItem("organizationID", organizationID);
		setOrganization({ ...organization, id: organizationID });
		const body = {
			name: 'organizationID',
			val: organizationID,
			userID: userID,
		};
		fetch(
			'https://apie.snapwrite.ca/storeData/user-preferences-updateVal',
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(body),
			}
		);
	};

	const fetchData = async (user) => {
		try {
			const data = await axios.get(
				`https://apie.snapwrite.ca/storeData/csv-title/${user.uid}`
			);
		} catch (error) {
			return;
		}
		console.log(data.data);
		const titleArr = [];
		const descArr = [];
		if (data.data.data) {
			data.data.data.titleBlocks.forEach((block) => {
				titleArr.push(block.value);
			});
			data.data.data.descriptionBlocks.forEach((block) => {
				descArr.push(block.value);
			});
		}

		setTitleTemplate(titleArr);
		setDescriptionTemplate(descArr);
	};

	// Modal Functions
	const toggle = () => setModal(!modal);
	const modalCloseBtn = (
		<button className='close' onClick={toggle} type='button'>
			&times;
		</button>
	);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<MainSideMenuHeaderContainer
		name={undefined}
		>
			<section>
				{/* Alerts for Credentials  */}
				<Snackbar
					open={openEmailSuccessSnackBar}
					onClose={() => setOpenEmailSuccessSnackBar(false)}
					autoHideDuration={2000}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Alert
						onClose={() => setOpenEmailSuccessSnackBar(false)}
						severity='success'
						sx={{ width: '100%' }}
					>
						The credentials were updated successfully!
					</Alert>
				</Snackbar>
				<Snackbar
					open={openEmailFailSnackBar}
					onClose={() => setOpenEmailFailSnackBar(false)}
					autoHideDuration={2000}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Alert
						onClose={() => setOpenEmailFailSnackBar(false)}
						severity='error'
						sx={{ width: '100%' }}
					>
						Something went wrong! Please try again.
					</Alert>
				</Snackbar>

				{/* Alerts for Template  */}
				<Snackbar
					open={openTemplateSuccessSnackBar}
					onClose={() => setOpenTemplateSuccessSnackBar(false)}
					autoHideDuration={2000}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Alert
						onClose={() => setOpenTemplateSuccessSnackBar(false)}
						severity='success'
						sx={{ width: '100%' }}
					>
						Template saved successfully!
					</Alert>
				</Snackbar>
				<Snackbar
					open={openTemplateFailSnackBar}
					onClose={() => setOpenTemplateFailSnackBar(false)}
					autoHideDuration={2000}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Alert
						onClose={() => setOpenTemplateFailSnackBar(false)}
						severity='error'
						sx={{ width: '100%' }}
					>
						Something went wrong! Please try again.
					</Alert>
				</Snackbar>

				{/* Component Starts here  */}
				<div className=' flex gap-10 '>
					<div>
						<div>
							<h1
								className=' text-3xl font-medium pb-0 mb-2 mt-1 tracking-wider'
								sx={{
									color: '#333',
									fontFamily: 'Inter',
									lineHeight: '123.5%',
									letterSpacing: '0.25px',
									fontFeatureSettings:
										"'clig' off, 'liga' off",
								}}
							>
								Settings
							</h1>
							<>
								<Tabs
									aria-label='Settings Tabs'
									size='lg'
									defaultValue={0}
									className=' bg-white min-w-[50rem] xl:w-[65vw] pt-1'
								>
									<TabList>
										<Tab
											variant='plain'
											color='primary'
											className=' bg-white focus:outline-none'
											sx={{
												color: 'rgba(0, 0, 0, 0.6)',
												fontSize: '14px',
												paddingRight: '16px',
												paddingLeft: '16px',
												'&.Mui-selected': {
													color: '#2196F3',
													outline: '#2196F3',
												},
											}}
										>
											Account & Organization
										</Tab>
										<Tab
											variant='plain'
											color='primary'
											className=' bg-white focus:outline-none'
											sx={{
												color: 'rgba(0, 0, 0, 0.6)',
												fontSize: '14px',
												paddingRight: '16px',
												paddingLeft: '16px',
												'&.Mui-selected': {
													color: '#2196F3',
													outline: '#2196F3',
												},
											}}
										>
											Listing Customization
										</Tab>
										<Tab
											variant='plain'
											color='primary'
											className=' bg-white focus:outline-none'
											sx={{
												color: 'rgba(0, 0, 0, 0.6)',
												fontSize: '14px',
												paddingRight: '16px',
												paddingLeft: '16px',
												'&.Mui-selected': {
													color: '#2196F3',
													outline: '#2196F3',
												},
											}}
										>
											Form Customization
										</Tab>
									</TabList>
									<TabPanel value={0} sx={{ paddingLeft: 0 }}>
										<AccountAndOrganization
											setOrganization={setOrganization}
											organization={organization}
											organizationName={organizationName}
											userID={userID}
											setOrganizationID={
												setOrganizationID
											}
											organizationID={organizationID}
											setOrganizationName={
												setOrganizationName
											}
											organizationMembers={
												organizationMembers
											}
											exportPreference={exportPreference}
											setExportPreference={
												setExportPreference
											}
										/>
									</TabPanel>
									<TabPanel value={1} sx={{ paddingLeft: 0 }}>
										<ListingCustomization
											userID={userID}
											titleTemplate={titleTemplate}
											setTitleTemplate={setTitleTemplate}
											titlePreview={titlePreview}
											descriptionTemplate={
												descriptionTemplate
											}
											setDescriptionTemplate={
												setDescriptionTemplate
											}
											descriptionPreview={
												descriptionPreview
											}
											metaData={metaData}
											setMetaData={setMetaData}
											organization={organization}
											setOrganization={setOrganization}
											organizationMembers={
												organizationMembers
											}
										/>
									</TabPanel>
									<TabPanel value={2} sx={{ paddingLeft: 0 }}>
										<FormCustomization
											emailTemplate={emailTemplate}
											setEmailTemplate={setEmailTemplate}
											customEmailCredentials={
												customEmailCredentials
											}
											userID={userID}
											setOpenEmailSuccessSnackBar={
												setOpenEmailSuccessSnackBar
											}
											setOpenEmailFailSnackBar={
												setOpenEmailFailSnackBar
											}
											setCustomEmailCredentials={
												setCustomEmailCredentials
											}
											subjectTemplate={subjectTemplate}
											setSubjectTemplate={
												setSubjectTemplate
											}
											setOpenTemplateSuccessSnackBar={
												setOpenTemplateSuccessSnackBar
											}
											setOpenTemplateFailSnackBar={
												setOpenTemplateFailSnackBar
											}
											toggle={toggle}
										/>
									</TabPanel>
								</Tabs>
							</>
						</div>

						<>
							{/* Email Credentials Modal */}
							<section>
								<Modal isOpen={modal} toggle={toggle}>
									<ModalHeader
										toggle={toggle}
										close={modalCloseBtn}
									>
										Generate App Password in Gmail Account
									</ModalHeader>
									<ModalBody>
										<Box sx={{ maxWidth: 400 }}>
											<Stepper
												activeStep={activeStep}
												orientation='vertical'
											>
												{appPasswordInstructions.map(
													(step, index) => (
														<Step key={step.label}>
															<StepLabel
																optional={
																	index ===
																	2 ? (
																		<Typography variant='caption'>
																			Last
																			step
																		</Typography>
																	) : null
																}
															>
																{step.label}
															</StepLabel>
															<StepContent>
																<Typography
																	sx={{
																		marginTop: 2,
																	}}
																>
																	{
																		step.description
																	}
																</Typography>
																<div className='my-4'>
																	<a
																		href={
																			step.link
																		}
																		target='_blank'
																		rel='noreferrer'
																	>
																		{
																			step.linkTxt
																		}
																	</a>
																</div>
																<Box
																	sx={{
																		mb: 2,
																	}}
																>
																	<div>
																		<Button
																			variant='contained'
																			onClick={
																				handleNext
																			}
																			sx={{
																				mt: 1,
																				mr: 1,
																			}}
																		>
																			{index ===
																			appPasswordInstructions.length -
																				1
																				? 'Finish'
																				: 'Continue'}
																		</Button>
																		<Button
																			disabled={
																				index ===
																				0
																			}
																			onClick={
																				handleBack
																			}
																			sx={{
																				mt: 1,
																				mr: 1,
																			}}
																		>
																			Back
																		</Button>
																	</div>
																</Box>
															</StepContent>
														</Step>
													)
												)}
											</Stepper>
											{activeStep ===
												appPasswordInstructions.length && (
												<Paper
													square
													elevation={0}
													sx={{ p: 3 }}
												>
													<Typography
														sx={{
															fontSize: 14,
															textAlign: 'center',
															fontWeight: 300,
														}}
													>
														Please paste the
														generated password and
														hit Submit.
													</Typography>
													<Button
														onClick={handleReset}
														sx={{ mt: 1, mr: 1 }}
													>
														Reset
													</Button>
												</Paper>
											)}
										</Box>
									</ModalBody>
									<ModalFooter>
										<Button onClick={toggle}>Close</Button>
									</ModalFooter>
								</Modal>
							</section>
						</>
					</div>
				</div>
			</section>
		</MainSideMenuHeaderContainer>
	);
}
const CopyToClipboardButton = ({ text }) => {
	const [open, setOpen] = useState(false);
	const handleClick = () => {
		setOpen(true);
		navigator.clipboard.writeText(text);
	};

	return (
		<>
			<Button startIcon={<ContentCopyIcon />} onClick={handleClick}>
				Copy
			</Button>
			<Snackbar
				open={open}
				onClose={() => setOpen(false)}
				autoHideDuration={2000}
				message='Copied to clipboard'
			/>
		</>
	);
};

export default Settings;
