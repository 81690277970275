const backSynonyms = [
    // "Open back",
    // "crossover back",
    // "backless",
    // "open-backed",
    // "cut out twist back",
    // "tie back",
    // "split back",
    "back"
  ];
  
  const boatneckSynonyms = [
    "Boat neck",
    "boat-neck",
    "boat neckline",
    "boatneck"
  ];
  
  const bowSynonyms = [
    // "Bow tie",
    "bow-detailed",
    "bow"
  ];
  
  const collarSynonyms = [
    "Collared",
    "collar"
  ];
  
  const cowlSynonyms = [
    "Draped",
    "ruched front",
    "ruched-front",
    "cowl neck",
    "drape neck",
    "cowlneck",
    "drapeneck",
    "cowl"
  ];
  
  const crewSynonyms = [
    "Crewneck",
    "Crew neck",
    "round neck",
    "round neckline",
    "crew-neck",
    "crew"
  ];
  
  const halterSynonyms = [
    "Halter neck",
    "halter top",
    "halter"
  ];
  
  const heartSynonyms = [
    "Sweetheart",
    "sweetheart neckline",
    "sweetheart neck",
    "heart neck",
    "sweetheart-neckline",
    "heart"
  ];
  
  const hoodedSynonyms = [
    "Hoodie",
    "hood",
    "hooded"
  ];
  
  const keyholeSynonyms = [
    "Hole cut out",
    "cut out",
    "cutout",
    "cut-out",
    "twist-detail",
    "keyhole"
  ];
  
  const lowCutSynonyms = [
    "Plunge",
    "deep V",
    "scoop neck",
    "deep v-neck",
    "scoop-neck",
    "low cut V neck",
    "low-cut V neck",
    "deep plunge",
    "low cut",
    "low-cut",
    "plunge detail",
    "sexy V neck",
    "low cut"
  ];
  
  const offShoulderSynonyms = [
    "Off the shoulder",
    "off-the-shoulder",
    "off-shoulder",
    "off shoulder"
  ];
  
  const oneShoulderSynonyms = [
    "Asymmetrical neck",
    "asymmetrical shoulder",
    "drop shoulder",
    "one-shoulder",
    "one shoulder"
  ];
  
  const roundSynonyms = [
    "Round neck",
    "round neckline",
    "boatneck",
    "crew",
    "round"
  ];
  
  const squareSynonyms = [
    "Square neck",
    "square-neck",
    "square neckline",
    "square"
  ];
  
  const vNeckSynonyms = [
    "V-neck",
    "V neckline",
    "V neck"
  ];
  
  const wrapSynonyms = [
    "wrap"
  ];
  

const synonymsArray = [backSynonyms, boatneckSynonyms, bowSynonyms, collarSynonyms, cowlSynonyms, 
                       crewSynonyms, halterSynonyms, heartSynonyms, hoodedSynonyms, keyholeSynonyms, 
                       lowCutSynonyms, offShoulderSynonyms, oneShoulderSynonyms, roundSynonyms,
                       squareSynonyms, vNeckSynonyms, wrapSynonyms]

export const getNecklineSynonyms = (neckline) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => neckline.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
}