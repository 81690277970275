import React, { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import axios from "axios";
import { ImageUploadCode } from "../imageUpload";
import { TagsInput } from "../tagsArea";
import { SideMenu } from "../../components/sideMenu";
import { Header } from "../../components/navigation/header";
import {
  wordCount,
  characterCount,
  sentenceCount,
  tagsList,
  keywordsMatched,
} from "../../components/wordCountArea";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

// localStorage.setItem("requestPerMinute",parseInt(0))
export function AltTextGenerator(props) {
  
  const userID = props.user;
  const userName = props.userName;
  const selectedTags = (tags) => {
    // console.log(tags);
  };
  const [noOfWordsGenerated, setNumberOfWordsGenerated] = useState();
  const [noOfImagesUsed, setNumberOfImagesUsed] = useState();
  const [wordLimit, setWordLimit] = useState(20000);
  const [imageLimit, setImageLimit] = useState(100);
  const [projectName, setProjectName] = useState("");
  const [image, setImage] = useState("");
  const [suggestedTags, setSuggestedTags] = useState([""]);
  const [tags, setTags] = useState([]);

  
  const [altTexts, setAltTexts] = useState(["Your alt text will appear in about 5 seconds", "Your alt text will appear in about 5 seconds", "Your alt text will appear in about 5 seconds"]);
  const [generate, setGenerate] = useState("Generate");

  const [objectID, setObjectID] = useState("")
  const [selectedAltText, setSelectedAltText] = useState("") 

  const generateButtonClick = props.generateButtonClick;
  const setGenerateButtonClick = props.setGenerateButtonClick;

  const unlimitedUsers = ["NpGB6zItW1OcpizZJbD88VsfKlf2", "1BILFjc4JNM0LDmmOUfdr2rRfNH3"]
  
  useEffect(()=>{    
    //communicate with the backend!
    fetch(`https://apie.snapwrite.ca/storeData/word-count?userID=${userID}`)
    .then(response=>response.json())
    .then(response=>{
        setNumberOfWordsGenerated(response.data)
        if(userID==="S4nF6oUynvUfkVQjTIcyqyCPZQg2"){
          setWordLimit(22417);
        }
    })
    .catch(err=>{
        console.log(`Error ${err}`)
    })
},[userID, generate])

useEffect(()=>{    
  //communicate with the backend!
  fetch(`https://apie.snapwrite.ca/storeData/image-count?userID=${userID}`)
  .then(response=>response.json())
  .then(response=>{
      setNumberOfImagesUsed(response.data)
      if(userID==="S4nF6oUynvUfkVQjTIcyqyCPZQg2"){
        setImageLimit(80);
      }
  })
  .catch(err=>{
      console.log(`Error ${err}`)
  })
},[userID, generate])

  useEffect(() => {
    const projectName = JSON.parse(localStorage.getItem("altTextProjectName"));
    const altImageData = JSON.parse(localStorage.getItem("altImage"));
    const altSuggestionData = JSON.parse(
      localStorage.getItem("altSuggestedTags")
    );
    const altTagsData = JSON.parse(localStorage.getItem("altTags"));
    const altTexts = JSON.parse(localStorage.getItem("altTexts"))
    const objectID = JSON.parse(localStorage.getItem("altTextObjectID"))
    const generate = JSON.parse(localStorage.getItem("altTextGenerate"))
    if (altImageData !== null && image === "") {
      setProjectName(projectName)
      setImage(altImageData);
      setSuggestedTags(altSuggestionData);
      setTags(altTagsData);      
      setAltTexts(altTexts);
      setObjectID(objectID);
      setGenerate(generate);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("altTextProjectName", JSON.stringify(projectName));
    localStorage.setItem("altImage", JSON.stringify(image));
    localStorage.setItem("altSuggestedTags", JSON.stringify(suggestedTags));
    localStorage.setItem("altTags", JSON.stringify(tags));    
    localStorage.setItem("altTexts", JSON.stringify(altTexts))
    localStorage.setItem("altTextObjectID", JSON.stringify(objectID))
    localStorage.setItem("altTextGenerate", JSON.stringify(generate))
  }, [
    projectName,
    image,
    suggestedTags,
    tags,
    altTexts,
    objectID,
    generate
  ]);

  const reset = () => {
    setProjectName("")
    if(noOfWordsGenerated>wordLimit && !unlimitedUsers.includes(userID)){
      setAltTexts([
        "Please Contact Us at contact@snapwrite.ca to increase limit",
        "Please Contact Us at contact@snapwrite.ca to increase limit",
      ])  
    }
    else if((noOfImagesUsed > imageLimit || noOfImagesUsed === imageLimit) &&  !unlimitedUsers.includes(userID)){
      setAltTexts([        
          "Please Contact Us at contact@snapwrite.ca to increase image limit",
          "Please Contact Us at contact@snapwrite.ca to increase image limit",
        ])
    }
    else{
      setAltTexts(["Your alt text will appear in about 5 seconds", "Your alt text will appear in about 5 seconds", "Your alt text will appear in about 5 seconds"])    
    }
    setTags([]);
    setImage("");
    setSuggestedTags([""]);
    setGenerate("Generate");
    setObjectID("")
  };
  async function genAltText(tags) {
    setAltTexts(["Loading...", "Loading...", "Loading..."]);

    try {
      setGenerateButtonClick(generateButtonClick + 1);
      setAltTexts(["Loading...", "Loading...", "Loading..."]);
      
      const res = await backendClient.post("/alt-text", {
        tags: tags,
        user: props.user,
      });
      
      genAltText2(tags, res.data);
    } catch (e) {
      setAltTexts(["Please try again"])
      console.error(e);
    }
  }
  async function genAltText2(tags, altText1) {
    try {
      setGenerateButtonClick(generateButtonClick + 1);
      
      const res = await backendClient.post("/alt-text", {
        tags: tags,
        user: props.user,
      });
      
      genAltText3(tags, altText1, res.data);
    } catch (e) {
      setAltTexts(["Please try again"])
      console.error(e);
    }
  }
  async function genAltText3(tags, altText1, altText2) {
    try {
      setGenerateButtonClick(generateButtonClick + 1);
      
      const res = await backendClient.post("/alt-text", {
        tags: tags,
        user: props.user,
      });
      
      setAltTexts([altText1, altText2, res.data]);
      storeDescriptions(altText1, altText2, res.data, tags);
    } catch (e) {
      setAltTexts(["Please try again"])
      console.error(e);
    }
  }

  async function reGenerateAltText(tags) {
    try {        
      setAltTexts([...altTexts, "Loading..."])
      setGenerateButtonClick(generateButtonClick + 1);      
      const res = await backendClient.post("/alt-text", {
        tags: tags,
        user: props.user,
      });
      updateStoredAltText(res.data)                  
      setAltTexts([...altTexts, res.data])                    
    } catch (e) {
      setAltTexts([...altTexts, "Please try again"])                    
      console.error(e);
    }
  }

  function storeDescriptions(description1, description2, description3, tags) {
    // console.log(`description1: ${description1}\ndescription2: ${description2}\ndescription3: ${description3}`)
    fetch(`https://apie.snapwrite.ca/storeData/alt-text`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        userName: userName,
        projectName: projectName,
        imageUploaded: image,
        suggestedTags: suggestedTags,
        tagsUsed: tags,
        generatedAltTexts: [description1, description2, description3],      
        wordCount: wordCount(description1) + wordCount(description2) + wordCount(description3) ,
      }),
    })
      .then((response) => response.json())
      .then(response=> setObjectID(response.data._id))
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }
  
    
  function updateStoredAltText(reGeneratedAltText) {
    // console.log("from store = " + [description1, description2]);    
    fetch(`https://apie.snapwrite.ca/storeData/alt-texts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: objectID,
        generatedAltTexts: [...altTexts, reGeneratedAltText]        
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }


  const updateAltText = (index, event) =>{      
    const altTextArray = altTexts.slice();
    // console.log("altextArray = " + altTextArray[index]) 
    altTextArray[index] = event.target.value;
    
    setAltTexts(altTextArray);

    fetch(`https://apie.snapwrite.ca/storeData/alt-texts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: objectID,
        generatedAltTexts: altTextArray 
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }

  const updateSelectedAltText = (text) => {
    setSelectedAltText(text);
    fetch(`https://apie.snapwrite.ca/storeData/selected-alt-text`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: objectID,
        selectedAltText: selectedAltText
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });

  }

  return (
    <div className="App">
      
      <Header reset={reset} setProjectName={setProjectName} projectName={projectName}/>
      
      {/* ******************************the <main> section****************************** */}
      <div className="interface">
        <SideMenu />
        {/* The grey section where the user can upload image, edit tags and get the description */}
        <div className="playGround">
          {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
          <div className="tools">
            <div className="drop-and-tags">
              {/* ************The image upload area************** */}
              <ImageUploadCode
                selectedTags={selectedTags}
                setTags={setTags}
                setSuggestedTags={setSuggestedTags}
                setImage={setImage}
                tags={tags}
                suggestedTags={suggestedTags}
                image={image}
                noOfImagesUsed={noOfImagesUsed}            
                noOfWordsGenerated={noOfWordsGenerated}
              />
              {/* ************************************************ */}
              <div className="card">
                <div className="card-header"> Tags </div>
                <div className="card-body appTag">
                  {/* **************************Tags area************************** */}
                  <div className="appTag">
                    <TagsInput
                      selectedTags={selectedTags}
                      tags={tags}
                      setTags={setTags}
                    />
                  </div>
                  <div className="tagsNumberDesc">
                    Add or edit 2-3 tags describing unique features
                  </div>
                  <button
                    className="btn btn-primary1"
                    disabled={generateButtonClick > 52 || noOfWordsGenerated>wordLimit || altTexts.length>3 || noOfImagesUsed>imageLimit || noOfImagesUsed===imageLimit}
                    onClick={() => {                    
                        if (generate === "Generate") {
                            genAltText(tags);
                            setGenerate("Re-Generate");
                          } else if (generate === "Re-Generate") {
                            reGenerateAltText(tags);
                          }                          
                        }}                         
                  >
                    {generate}
                  </button>
                </div>
              </div>
              {/* ************************************************************* */}
            </div>
            <div className="altTextSection">
              <div>
                <div className="card-header alt-text-header">Alt Text</div>
                {altTexts.map((altText, index) => {
                  return (
                    <div className={`card  card1 card2 ${altText===selectedAltText ? 'selectedHighlight' : null}` }>                      
                      <div className="card-body alt-text-card">
                        {altText!=="Your alt text will appear in about 5 seconds" ? 
                        <textarea
                          name="blogOutline"
                          type="text"
                          value={altText}
                          onChange={(event) =>
                            updateAltText(index, event)
                          }
                          data-hj-allow
                        />
                        :
                         altText}
                      </div>
                      <div className="card-footer-alt">
                        <div className="wordCountArea">
                          <div className="p-2 bd-highlight">
                            Count (Character / Word / Sentence) :{" "}
                            {characterCount(altText)} / {wordCount(altText)} /{" "}
                            {sentenceCount(altText)}
                            <br /> Keywords matched:{" "}
                            {keywordsMatched(tags, altText)}/{tags.length}&nbsp;
                            <select
                              className="dropdownTags"
                              name="TagsList"
                              id="TagsList"
                            >
                              {tagsList(tags, altText)}
                            </select>
                          </div>
                          <div className="copyButtonIcon"  color="grey"
                              onClick={()=>updateSelectedAltText(altText)}>
                            select
                          </div>
                          <div className="copyButtonIcon"  color="grey"
                              onClick={()=>copy(altText)}>
                            copy
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AltTextGenerator;
