import React from "react";
 let numberOfTags = 6;
//  let totalCharacterCount = 44
let totalCharacterCount = 0

export const TagsInput = (props) => {
    const tags = props.tags;
    const setTags = props.setTags;
    const setDescriptionData = props.setDescriptionData
    
    const removeTags = (indexToRemove) => {
      totalCharacterCount -= tags[indexToRemove].length
      let tagsArray = tags;
      if(props.setDescriptionData!==undefined){
        setDescriptionData({...props.descriptionData, tagsUsed : [...tags.filter((_, index) => index !== indexToRemove)]})
      }
      else{
        setTags([...tags.filter((_, index) => index !== indexToRemove)]);
      }
      --numberOfTags;
    };
    const addTags = (event) => {      
      if (event.target.value !== "") {

        // let tagData = event.target.value;
        // if(event.target.value[event.target.value.length -1] === '.' || event.target.value[event.target.value.length -1] === ','){
        //   tagData[event.target.value.length -1] = '';
        //   totalCharacterCount--;  
        // }

        totalCharacterCount += event.target.value.length;
        if(props.setDescriptionData!==undefined){
          let tagsArray = tags.slice();
          tagsArray.push(event.target.value);
          setDescriptionData({...props.descriptionData, tagsUsed: tagsArray})

        }
        else{
          setTags([...tags, event.target.value]);
        }
        // props.selectedTags([...tags, event.target.value]);
        event.target.value = "";
        ++numberOfTags;
      }
    };

    const handleChange = (event, index) =>{
      const newTags = tags.slice();
      newTags[index] = event.target.value;
      if(props.setDescriptionData!==undefined){
        setDescriptionData({...props.descriptionData, tagsUsed: newTags})
      }
      else{
        setTags(newTags)
      }
      // props.selectedTags(newTags)
    
    }

    
    const characterLimit = (totalCharacterCount) => {
      return(198-totalCharacterCount);
    }

  
    return (
      /////////////////////////////////Tags////////////////////////////////////////
      <div className="tags-input">
        <ul id="tags">
          {tags.map((tag, index) => {
            let inputFieldID = `tagTypeArea${index}`
            return (
            <li key={index} className="tag">
              <span className="tag-title">
              <input
                name="tagTypeArea"
                id={inputFieldID}
                value={tag}
                className="tagEditArea"
                type="text"
                maxLength={characterLimit(totalCharacterCount)}                  
                onChange={(event) => handleChange(event, index)}        
                onKeyUp={(event) => (event.key === "Enter" || event.key === "," || event.key === "."? event.target.blur() : null)}
                style={{ width: `${tag.length * (55/6)}px` }}
                data-hj-allow
              />
              </span>
              &nbsp;&nbsp;
              <span className="tag-close-icon" onClick={() => removeTags(index)}>
                <i className="fas fa-times"></i>
              </span>
            </li>
          )})}
          { totalCharacterCount < 198? <input
          name="tagTypeArea"
          type="text"
          maxLength={characterLimit(totalCharacterCount)}          
          onKeyUp={(event) => (event.key === "Enter" || event.key === "," || event.key === "."? addTags(event) : null)}
          data-hj-allow
        />:null}          
        {/* {console.log(totalCharacterCount)} */}
        </ul>      
      </div>
    );
  };
  


  export default TagsInput;