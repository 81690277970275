import { useRef, useEffect, useState, useCallback } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import updateColumnValue from '../../utils/updateAttribute';
import PropTypes from 'prop-types';
import { IconButton, TableCell } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TableSupplementaryImageData = ({
	disabled,
	index,
	supplementaryImages: initialSupplementaryImages,
	images,
	template,
	objectID,
	singleProductModelFilter,
}) => {
	let rowIndex = index;
	const uploadedImages = initialSupplementaryImages[rowIndex];
	const [expand, setExpand] = useState(false);
	const ref = useRef();
	const nullRef = useRef();

	const [supplementaryImages, setSupplementaryImages] = useState([]);

	useEffect(() => {
		if (initialSupplementaryImages.length === 0) return;
		setSupplementaryImages(initialSupplementaryImages[rowIndex] || []);
	}, [initialSupplementaryImages]);

	const uploadImage = useCallback(
		async (e) => {
			const addedImages = [];
			for (
				let i = 0;
				i < e.target.files.length && supplementaryImages.length + i < 6;
				i++
			) {
				const { url } = await fetch(
					'https://apie.snapwrite.ca/storeData/s3Url'
				).then((res) => res.json());

				await fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'image/jpeg',
					},
					body: e.target.files[i],
				}).catch((err) => console.error(err));
				addedImages.push(url.split('?')[0]);
			}
			let updatedImages = [...supplementaryImages, ...addedImages];
			setSupplementaryImages(updatedImages);
			updateColumnValue(
				singleProductModelFilter,
				template,
				'supplementaryImages',
				updatedImages,
				objectID,
				rowIndex,
				'supplementaryImages'
			);
		},
		[supplementaryImages, images, template, objectID]
	);

	const handleClickOutside = useCallback((event) => {
		if (nullRef.current && nullRef.current.contains(event.target)) return;
		if (ref.current && !ref.current.contains(event.target)) {
			setExpand(false);
		}
	}, []);


	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [handleClickOutside]);

	return (
		<TableCell
		sx={{
			borderBottom: "none"
		}}			
		>
			{/* Upload Images */}
			<div
				className='uploaded-images-wrapper'
				onClick={() => setExpand(!expand)}
				ref={ref}
			>
				{(supplementaryImages && supplementaryImages.length === 1) ? (
					<div className='relative max-h-44 max-w-xs object-contain'>
					{/* <img
						key={i + img}
						className='h-44 shadow'
						src={img}
						alt='upload'
						loading='lazy'
					/> */}
						<img
							key={`${rowIndex}-${supplementaryImages[0]}`}
							className='max-h-44 max-w-xs h-44 w-44  object-contain'
							src={supplementaryImages[0]}
							alt='upload'
						/>
						{!disabled && (!expand || (supplementaryImages && supplementaryImages.length > 0 )) && (
							<IconButton
								sx={{
									position: 'absolute',
									top: 0,
									right: 0,
								}}
								onClick={(event) => {
									event.stopPropagation()

									setSupplementaryImages([])
									updateColumnValue(
										singleProductModelFilter,
										template,
										'supplementaryImages',
										[],
										objectID,
										rowIndex,
										'supplementaryImages'
									);
								}}
							>
								<CloseIcon />
							</IconButton>
						)}
				</div>
				) : (
					<div
						className={`flex  ${
							expand ? 'space-x-5' : ' -space-x-28 '
						}`}
					>
						{supplementaryImages.map((img, i) => {
							return (
								<div className='relative max-h-44 max-w-xs object-contain'>
									<img
										key={i + img}
										className='h-44 shadow'
										src={img}
										alt='upload'
										loading='lazy'
									/>
									{!disabled && (expand || ( img && img.length > 0)) && (
										<IconButton
											sx={{
												position: 'absolute',
												top: 0,
												right: 0,
											}}
											onClick={(event) => {
												event.stopPropagation()
												setSupplementaryImages(
													supplementaryImages.filter(
														(d, ind) => ind !== i
													)
												);
												updateColumnValue(
													singleProductModelFilter,
													template,
													'supplementaryImages',
													supplementaryImages.filter(
														(d, ind) => ind !== i
													),
													objectID,
													rowIndex,
													'supplementaryImages'
												);
											}}
										>
											<CloseIcon />
										</IconButton>
									)}
								</div>
							);
						})}
					</div>
				)}
			</div>

			<div className='flex justify-center'>
				<label
					style={{
						display:
							supplementaryImages.length >= 6 ? 'none' : null,
						marginTop: supplementaryImages.length ? 30 : 0,
					}}
					htmlFor={'myFile' + rowIndex}
					className='pc'
				>
					{supplementaryImages.length === 0 ? (
						<div className='upload-image-btn'>
							<FileUploadIcon color='black' />
							<span style={{ textAlign: 'center' }}>
								{' '}
								PNG, JPEG files only
							</span>
						</div>
					) : (
						<div
							className='upload-image-btn'

						>
							<FileUploadIcon color='black' />
						</div>
					)}
				</label>
				<input
					disabled={disabled}
					multiple
					type='file'
					name={'myFile' + rowIndex}
					id={'myFile' + rowIndex}
					className='upload-image-btn drop-zone__input'
					accept='image/*'
					onChange={uploadImage}
				/>
			</div>
		</TableCell>
	);
};

TableSupplementaryImageData.propTypes = {
	disabled: PropTypes.bool,
	supplementaryImages: PropTypes.array.isRequired,
	images: PropTypes.array.isRequired,
	template: PropTypes.string.isRequired,
	objectID: PropTypes.string.isRequired,
	index: PropTypes.number,
};

export default TableSupplementaryImageData;
