import { useParams } from "react-router";
import { getAuth } from '@firebase/auth';
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import React, { useEffect, useState, useRef } from "react";
import createWixCSV from "../../../products/bulk/shopify/utils/generateWixCSV.js";
import createcsv from "../../../products/bulk/shopify/utils/generateCsv.js";
import TableList from "../../../products/bulk/shopify/tableList.js";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import updateColumnValue from "../../../products/bulk/shopify/utils/updateAttribute.js";
import TaxonomyUpdate from "../../../products/bulk/shopify/taxonomyUpdate.js";
import updateSortedLabelData from "../../../products/bulk/shopify/utils/updateTable.js";
import { ImportButton } from "./shopifyClothings.js";
import MainSideMenuHeaderContainer from "../../containers/MainSideMenuHeaderContainer.js";
import { PrimaryButton, PrimaryIconButton } from "../../branded-elements/Elements.js";
import stateArrayDBassignment from "../../../products/bulk/shopify/utils/stateDBassignment.js";

import getProductCategoryForPricing from "../../../products/bulk/shopify/utils/pricePrediction/predictPrice.js";
import adjustedConditionForPrice from "../../../products/bulk/shopify/utils/pricePrediction/itemConditionAdjustor.js";

//Request Splitting
import splitArray from "../../../products/bulk/shopify/utils/requestSplitting/splitImages.js";
import getResponse from "../../../products/bulk/shopify/utils/requestSplitting/parallelRequests.js";

const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
  });

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ClothingMasterTable = ({history}) => {
    const auth = getAuth();
    const [userID, setUserID] = useState()
    useEffect(() => {
        auth.onAuthStateChanged(user => {
                const token = localStorage.getItem("token");      
                if (!token || !user) {
                    history.push("/");
                }else {        
            setUserID(user.uid);    
                }
        })   
      },[])
    const {template, id} = useParams()
    const objectID = id


     
  const [projectName, setProjectName] = useState("");
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [waitingText, setWaitingText] = useState("Loading...");
  const [csvDownload, SetCsvDownload] = useState([]);
  const [itemGenericType, setItemGenericType] = useState([]);
  const [s3Images, setS3Images] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [supplementaryImages, setSupplementaryImages] = useState([]);
  const [imageFileName, setImageFileName] = useState([]);
  const [itemName, setItemName] = useState([]);
  const [types, setType] = useState([]);
  const [typesTaxoUpdate, setTypesTaxoUpdate] = useState([]);
  const [brand, setBrand] = useState([]);
  const [sku, setSku] = useState([])
  const [size, setSize] = useState([]);
  const [sizeNotes, setSizeNotes] = useState([]);
  const [condition, setCondition] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [description, setDescription] = useState([]);
  const [conditionDescription, setConditionDescription] = useState([]);
  const [price, setPrice] = useState([]);
  const [costPerItem, setCostPerItem] = useState([]);
  const [retailPrice, setRetailPrice] = useState([]);
  const [genders, setGender] = useState([]);
  const [colors, setColor] = useState([]);
  const [additionalInfos, setAddtionaInfo] = useState([]);
  const [variantGrams, setVariantGrams] = useState([]);
  const [generateDataLoading, setGenerateDataLoading] = useState(false)

  /* Properties */
  const [tags, setTags] = useState([]);

  const [material, setMaterial] = useState([]);
  const [length, setLength] = useState([]);
  const [style, setStyle] = useState([]);
  const [age, setAge] = useState([]);
  const [design, setDesign] = useState([]);
  const [closure, setClosure] = useState([]);
  const [fastening, setFastening] = useState([]);
  const [fastening_type, setFasteningType] = useState([]);
  const [belt, setBelt] = useState([]);
  const [cut, setCut] = useState([]);
  const [neckline, setNeckline] = useState([]);
  const [sleeves, setSleeves] = useState([]);
  const [hood, setHood] = useState([]);
  const [typeObj, setTypeObj] = useState([]);
  const [lining, setLining] = useState([]);

  const [layers, setLayers] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [skirt, setSkirt] = useState([]);
  const [rise, setRise] = useState([]);
  const [heelHeight, setHeelHeight] = useState([]);
  const [heelShape, setHeelShape] = useState([]);
  const [height, setHeight] = useState([]);
  const [toe, setToe] = useState([]);

  const [era, setEra] = useState([]);
  const [madeIn, setMadeIn] = useState([]);

  //Measurements
  const [waistMeasure, setWaistMeasure] = useState([]);
  const [hipMeasure, setHipMeasure] = useState([]);
  const [bottomInSeam, setBottomInSeam] = useState([]);
  const [riseMeasure, setRiseMeasure] = useState([]);
  const [thighMeasure, setThighMeasure] = useState([]);
  const [legOpening, setLegOpening] = useState([]);
  const [lengthMeasure, setLengthMeasure] = useState([]);
  const [sleeveInSeam, setsleeveInSeam] = useState([]);
  const [pitToPitMeasure, setPitToPitMeasure] = useState([]);
  const [shoulderMeasure, setShoulderMeasure] = useState([]);
  //shoes
  const [heelHeightMeasure, setHeelHeightMeasure] = useState([]);
	const [soleMeasure, setSoleMeasure] = useState([])
	const [inSoleMeasure, setInSoleMeasure] = useState([])
	const [shoeWidth, setShoeWidth] = useState([])

  const [isVendorColSelected, setIsVendorColSelected] = useState(false)

  // concerns
  const [concerns, setConcerns] = useState([])
  
	const [projectData, setProjectData] = useState([]);
  const [accountType, setAccountType] = useState("reseller")

  
	// Snackbar
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [fail, setFail] = useState(false);

  const [dataGenerated, setDataGenerated] = useState(false)

  const [fetchedBrands, setFetchedBrands] = useState()
  
  // User's Preffered ItemTypes
  const [preferredItemTypes, setPreferredItemTypes] = useState()

	const handleSuccessImport = () => {
    setOpen(true);
  };

	const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fetchBrands = async () => {
    const brands = await axios.get(`https://apie.snapwrite.ca/storeData/brands`)
    setFetchedBrands(brands.data.data.brands)
  }
  
  const fetchItemTypes = async () => {

    auth.onAuthStateChanged(async (user) => {      
      if (user) {                  
        const itemTypes = await axios.get(`https://apie.snapwrite.ca/storeData/user-preferences-taxonomy/${user.uid}`)
        setPreferredItemTypes(itemTypes.data.data)
      }
    })   
    
  }

  const setDefaultStates = (json) => {
    if(json.data.isProductFeedGenerated){
      setDataGenerated(json.data?.isProductFeedGenerated || false)
    }
    setS3Images(json.data.images);
    if (json.data.backImage) {
      setBackImage(json.data.backImage);
    } else {
      setBackImage(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.supplementaryImages &&
      json.data.supplementaryImages.length > 0
    ) {
      setSupplementaryImages(json.data.supplementaryImages);
    } else {
      setSupplementaryImages(Array(json.data.images.length).fill([]));
    }
    if (json.data.projectName) {
      setProjectName(json.data.projectName);
    } else {
      setProjectName("");
    }
    if (json.data.fileNames) {
      setImageFileName(json.data.fileNames);
    } else {
      setImageFileName(Array(json.data.images.length).fill(""));
    }
    if (json.data.itemName) {
      setItemName(json.data.itemName);
    } else {
      setItemName(Array(json.data.images.length).fill(""));
    }
    if (json.data.itemGenericType) {
      setItemGenericType(json.data.itemGenericType);
    } else {
      setItemGenericType(Array(json.data.images.length).fill(""));
    }
    if (json.data.taxonomyUpdatedType) {
      setTypesTaxoUpdate(json.data.taxonomyUpdatedType);
    } else {
      setTypesTaxoUpdate(Array(json.data.images.length).fill(""));
    }
    if (json.data.brand) {
      setBrand(json.data.brand);
    } else {
      setBrand(Array(json.data.images.length).fill(""));
    }
    if(json.data.sku) {
      setSku(json.data.sku);
    } else {
      setSku(Array(json.data.images.length).fill(""));
    }            
    if(json.data.vendor) {
      setVendor(json.data.vendor);
    } else {
      setVendor(Array(json.data.images.length).fill(""));
    }
    if (json.data.types) {
      setType(json.data.types);
    } else {
      setType(Array(json.data.images.length).fill(""));
    }
    if (json.data.gender) {
      setGender(json.data.gender);
    } else {
      setGender(Array(json.data.images.length).fill(""));
    }
    if (json.data.size) {
      setSize(json.data.size);
    } else {
      setSize(Array(json.data.images.length).fill(""));
    }
    if (json.data.sizeNotes) {
      setSizeNotes(json.data.sizeNotes);
    } else {
      setSizeNotes(Array(json.data.images.length).fill(""));
    }
    if (json.data.condition) {
      setCondition(json.data.condition);
    } else {
      setCondition(Array(json.data.images.length).fill(""));
    }
    if (json.data.conditionDescription) {
      setConditionDescription(json.data.conditionDescription);
    } else {
      setConditionDescription(Array(json.data.images.length).fill(""));
    }
    if (json.data.description) {
      setDescription(json.data.description);
    } else {
      setDescription(Array(json.data.images.length).fill(""));
    }
    if (json.data.colors) {
      setColor(json.data.colors);
    } else {
      setColor(Array(json.data.images.length).fill(""));
    }
    if (json.data.cost) {
      setCostPerItem(json.data.cost);
    } else {
      setCostPerItem(Array(json.data.images.length).fill(""));
    }
    if (json.data.price) {
      setPrice(json.data.price);
    } else {
      setPrice(Array(json.data.images.length).fill(""));
    }
    if (json.data.retailPrice) {
      setRetailPrice(json.data.retailPrice);
    } else {
      setRetailPrice(Array(json.data.images.length).fill(""));
    }
    if (json.data.additionalInfo) {
      setAddtionaInfo(json.data.additionalInfo);
    } else {
      setAddtionaInfo(Array(json.data.images.length).fill(""));
    }
    if (json.data.variantGrams) {
      setVariantGrams(json.data.variantGrams);
    } else {
      setVariantGrams(Array(json.data.images.length).fill(""));
    }
    if (json.data.concerns) {
      setConcerns(json.data.concerns);
    } else {
      setConcerns(
        Array(json.data.images.length).fill('')
      );
    }

    // Attributes
    if (json.data.material) {
      setMaterial(json.data.material);
    } else {
      setMaterial(Array(json.data.images.length).fill(""));
    }
    if (json.data.sleeves) {
      setSleeves(json.data.sleeves);
    } else {
      setSleeves(Array(json.data.images.length).fill(""));
    }
    if (json.data.length) {
      setLength(json.data.length);
    } else {
      setLength(Array(json.data.images.length).fill(""));
    }
    if (json.data.style) {
      setStyle(json.data.style);
    } else {
      setStyle(Array(json.data.images.length).fill(""));
    }
    if (json.data.age) {
      setAge(json.data.age);
    } else {
      setAge(Array(json.data.images.length).fill(""));
    }
    if (json.data.closure) {
      setClosure(json.data.closure);
    } else {
      setClosure(Array(json.data.images.length).fill(""));
    }
    if (json.data.fastening) {
      setFastening(json.data.fastening);
    } else {
      setFastening(Array(json.data.images.length).fill(""));
    }
    if (json.data.fastening_type) {
      setFasteningType(json.data.fastening_type);
    } else {
      setFasteningType(Array(json.data.images.length).fill(""));
    }
    if (json.data.neckline) {
      setNeckline(json.data.neckline);
    } else {
      setNeckline(Array(json.data.images.length).fill(""));
    }
    if (json.data.cut) {
      setCut(json.data.cut);
    } else {
      setCut(Array(json.data.images.length).fill(""));
    }
    if (json.data.design) {
      setDesign(json.data.design);
    } else {
      setDesign(Array(json.data.images.length).fill(""));
    }
    if (json.data.hood) {
      setHood(json.data.hood);
    } else {
      setHood(Array(json.data.images.length).fill(""));
    }
    if (json.data.typeObj) {
      setTypeObj(json.data.typeObj);
    } else {
      setTypeObj(Array(json.data.images.length).fill(""));
    }
    if (json.data.belt) {
      setBelt(json.data.belt);
    } else {
      setBelt(Array(json.data.images.length).fill(""));
    }
    if (json.data.lining) {
      setLining(json.data.lining);
    } else {
      setLining(Array(json.data.images.length).fill(""));
    }
    if (json.data.layers) {
      setLayers(json.data.layers);
    } else {
      setLayers(Array(json.data.images.length).fill(""));
    }
    if (json.data.pattern) {
      setPattern(json.data.pattern);
    } else {
      setPattern(Array(json.data.images.length).fill(""));
    }
    if (json.data.skirt) {
      setSkirt(json.data.skirt);
    } else {
      setSkirt(Array(json.data.images.length).fill(""));
    }
    if (json.data.rise) {
      setRise(json.data.rise);
    } else {
      setRise(Array(json.data.images.length).fill(""));
    }
    if (json.data.heelHeight) {
      setHeelHeight(json.data.heelHeight);
    } else {
      setHeelHeight(Array(json.data.images.length).fill(""));
    }
    if (json.data.heelShape) {
      setHeelShape(json.data.heelShape);
    } else {
      setHeelShape(Array(json.data.images.length).fill(""));
    }
    if (json.data.height) {
      setHeight(json.data.height);
    } else {
      setHeight(Array(json.data.images.length).fill(""));
    }
    if (json.data.toe) {
      setToe(json.data.toe);
    } else {
      setToe(Array(json.data.images.length).fill(""));
    }
    if (json.data.tags) {
      setTags(json.data.tags);
    } else {
      setTags(json.data.tags);
    }

    // Vintage

    if (json.data.era && json.data.era.length === json.data.images.length) {
      setEra(json.data.era);
    } else {
      setEra(Array(json.data.images.length).fill(""));
    }

    if (
      json.data.madeIn &&
      json.data.madeIn.length === json.data.images.length
    ) {
      setMadeIn(json.data.madeIn);
    } else {
      setMadeIn(Array(json.data.images.length).fill(""));
    }

    // Measurements

    if (
      json.data.legOpening &&
      json.data.legOpening.length === json.data.images.length
    ) {
      setLegOpening(json.data.legOpening);
    } else {
      setLegOpening(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.riseMeasure &&
      json.data.riseMeasure.length === json.data.images.length
    ) {
      setRiseMeasure(json.data.riseMeasure);
    } else {
      setRiseMeasure(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.bottomInSeam &&
      json.data.bottomInSeam.length === json.data.images.length
    ) {
      setBottomInSeam(json.data.bottomInSeam);
    } else {
      setBottomInSeam(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.thighMeasure &&
      json.data.thighMeasure.length === json.data.images.length
    ) {
      setThighMeasure(json.data.thighMeasure);
    } else {
      setThighMeasure(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.lengthMeasure &&
      json.data.lengthMeasure.length === json.data.images.length
    ) {
      setLengthMeasure(json.data.lengthMeasure);
    } else {
      setLengthMeasure(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.sleeveInSeam &&
      json.data.sleeveInSeam.length === json.data.images.length
    ) {
      setsleeveInSeam(json.data.sleeveInSeam);
    } else {
      setsleeveInSeam(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.pitToPitMeasure &&
      json.data.pitToPitMeasure.length === json.data.images.length
    ) {
      setPitToPitMeasure(json.data.pitToPitMeasure);
    } else {
      setPitToPitMeasure(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.shoulderMeasure &&
      json.data.shoulderMeasure.length === json.data.images.length
    ) {
      setShoulderMeasure(json.data.shoulderMeasure);
    } else {
      setShoulderMeasure(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.waistMeasure &&
      json.data.waistMeasure.length === json.data.images.length
    ) {
      setWaistMeasure(json.data.waistMeasure);
    } else {
      setWaistMeasure(Array(json.data.images.length).fill(""));
    }
    if (
      json.data.hipMeasure &&
      json.data.hipMeasure.length === json.data.images.length
    ) {
      setHipMeasure(json.data.hipMeasure);
    } else {
      setHipMeasure(Array(json.data.images.length).fill(""));
    }
    //shoes measurement
    setHeelHeightMeasure(stateArrayDBassignment(json.data.heelHeightMeasure, json.data.images.length))
    setSoleMeasure(stateArrayDBassignment(json.data.soleMeasure, json.data.images.length))
    setInSoleMeasure(stateArrayDBassignment(json.data.inSoleMeasure, json.data.images.length))
    setShoeWidth(stateArrayDBassignment(json.data.shoeWidth, json.data.images.length))
  }

  const endpoints = {
    "vintage": "https://apie.snapwrite.ca/storeData/vintage-apparel",
    "pre-loved-regular": "https://apie.snapwrite.ca/storeData/regular-preLoved-apparel",
    "new-clothes": "https://apie.snapwrite.ca/storeData/new-apparel"
  };

  let endpoint = endpoints[template] || "https://apie.snapwrite.ca/storeData/labeling-clothes-categorization";
  useEffect(() => {
    fetchBrands()
    fetchItemTypes()
    fetch(`${endpoint}/${objectID}`)
      .then((response) => response.json())
      .then((json) => {        
        setDefaultStates(json)
        setProjectData(json.data)
      })
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }, [csvDownload, isLoading]);
  let link = `https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`;


  const updateVal = (name, event, index, val, setVal) => {
    const singleProductModelFilter = {userID, sku: sku[index]}

    const newVal = val.slice();
    if (isNaN(event)) {
      if (event.includes(`"`)) {
        event = event.replace(/"/g, `'`);
      }
    }
    newVal[index] = event;
    setVal(newVal);
    if(newVal.length>0 && objectID!==""){
      updateColumnValue(singleProductModelFilter, template, name, newVal, objectID)
    }
  };


  const updateValIndividual = (name, event, index, key, ) => {
    const singleProductModelFilter = {userID, sku: sku[index]}
    const newVal = event;
    if (isNaN(event)) {
      if (event.includes(`"`)) {
        event = event.replace(/"/g, `'`);
      }
    }
    if (newVal !== null && newVal !== undefined && objectID !== "") {
      updateColumnValue(singleProductModelFilter, template, name, event, objectID, index, key);
    }
  };
  
  const updateProjectName = async (event) => {
    setProjectName(event);
    if (objectID !== "") {
      try {
        const response = await fetch(
          `${link}-projectNameUpdate`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: objectID,
              projectName: event,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
      } catch (err) {
        console.error(`Error: ${err}`);
      }
    }
  };

  const generateCSVData = async (button, isImport=false) => {
    setLoading(true);
    setWaitingText("Enhancing SEO. Please Wait for about a minute");
    const json = await fetch(`${link}/${objectID}`)
      .then((response) => response.json())
    const preferences = await axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`)    
    const preferenceData = preferences.data.data  
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'taxonomyOptions', val: [...new Set([...preferenceData.taxonomyOptions, ...json.data.taxonomyUpdatedType.filter(item => typeof item === 'string')])]})
    let data =
      button === "wixCSV"
        ? await createWixCSV(
            template,
            userID,
            id,
            isImport
          )
        : await createcsv(
            template,
            userID,
            id,
            isImport
          );

    SetCsvDownload(data);

    setLoading(false);
    setWaitingText("Loading...");
    return data;
  };

  function downloadCSV(csvAsObj) {
    const headers = Object.keys(csvAsObj[0]);
    const csvRows = [];

    const headerRow = headers.join(",");
    csvRows.push(headerRow);

    for (const item of csvAsObj) {
      const values = headers.map((header) => item[header]);
      const csvRow = values.join(",");
      csvRows.push(csvRow);
    }

    const csvString = csvRows.join("\n");
    const csvData = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(
      csvData,
      `${projectName !== "" ? projectName : "Import"}_SnapWriteAI.csv`
    );
  }

  const convertBase64 = (image) => {
    return new Promise((resolve, reject) => {
        fetch(image)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);                        
          };
          reader.onerror  = (error) => {
            reject(error)
          }
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.log('Error:', error);
        });      
    });
  };




  const uploadImage = async () => {
    try {
      const response = await axios.get(`${endpoint}/${objectID}`);
      projectBodyRef.current = response.data.data;
  } catch (err) {
      console.log(`Error ${err}`);
      return;
  }

    let images = projectBodyRef.current.images;    
    setGenerateDataLoading(true);        
    let imageFiles = [];
    for (let index = 0; index < images.length; index++) {
      if (images[index] === "" || !images[index]) {
        continue;
      }
			imageFiles.push(images[index])
		}

    if(imageFiles.length!==images.length){
      alert("please upload images in all the rows or delete the row with no image")
    }
    else{
      console.log(imageFiles.length)
      const result = await getResponse(splitArray(imageFiles), userID)
      // sort here
      const resultMap = result.reduce((acc, obj) => {
        acc[obj.image] = obj;
        return acc;
        }, {});
        
      const arrangedResult = imageFiles.map(image => resultMap[image]);
        
      console.log(arrangedResult);
      for (let i = 0; i < imageFiles.length; i++) {
        await getSuggestions(				
					i,
          {data: result[i]}
        )
        setProjectData(projectBodyRef.current);
        setConcerns(projectBodyRef.current.concerns);
        setS3Images(projectBodyRef.current.images);
        setBackImage(projectBodyRef.current.backImage);
        setSupplementaryImages(projectBodyRef.current.supplementaryImages);
        setType(projectBodyRef.current.types);
        setItemGenericType(projectBodyRef.current.itemGenericType);
        setTypesTaxoUpdate(projectBodyRef.current.taxonomyUpdatedType);
        setItemName(projectBodyRef.current.itemName);
        setBrand(projectBodyRef.current.brand);
        setSku(projectBodyRef.current.sku);
        setVendor(projectBodyRef.current.vendor);
        setSize(projectBodyRef.current.size);
        setSizeNotes(projectBodyRef.current.sizeNotes);
        setCostPerItem(projectBodyRef.current.cost);
        setPrice(projectBodyRef.current.price);
        setRetailPrice(projectBodyRef.current.retailPrice);
        setCondition(projectBodyRef.current.condition);
        setConditionDescription(projectBodyRef.current.conditionDescription);
        setDescription(projectBodyRef.current.description);
        setImageFileName(projectBodyRef.current.fileNames);
        setColor(projectBodyRef.current.colors);
        setGender(projectBodyRef.current.gender);
        setVariantGrams(projectBodyRef.current.variantGrams);
        setTags(projectBodyRef.current.tags);
        setMaterial(projectBodyRef.current.material);
        setSleeves(projectBodyRef.current.sleeves);
        setStyle(projectBodyRef.current.style);
        setLength(projectBodyRef.current.length);
        setAge(projectBodyRef.current.age);
        setClosure(projectBodyRef.current.closure);
        setFastening(projectBodyRef.current.fastening);
        setFasteningType(projectBodyRef.current.fastening_type);
        setNeckline(projectBodyRef.current.neckline);
        setCut(projectBodyRef.current.cut);
        setHood(projectBodyRef.current.hood);
        setTypeObj(projectBodyRef.current.typeObj);
        setBelt(projectBodyRef.current.belt);
        setDesign(projectBodyRef.current.design);
        setLining(projectBodyRef.current.lining);
        setLayers(projectBodyRef.current.layers);
        setPattern(projectBodyRef.current.pattern);
        setSkirt(projectBodyRef.current.skirt);
        setRise(projectBodyRef.current.rise);
        setHeelHeight(projectBodyRef.current.heelHeight);
        setHeelShape(projectBodyRef.current.heelShape);
        setHeight(projectBodyRef.current.height);
        setToe(projectBodyRef.current.toe);
        setEra(projectBodyRef.current.era);
        setMadeIn(projectBodyRef.current.madeIn);
        setLengthMeasure(projectBodyRef.current.lengthMeasure);
        setsleeveInSeam(projectBodyRef.current.sleeveInSeam);
        setPitToPitMeasure(projectBodyRef.current.pitToPitMeasure);
        setShoulderMeasure(projectBodyRef.current.shoulderMeasure);
        setWaistMeasure(projectBodyRef.current.waistMeasure);
        setHipMeasure(projectBodyRef.current.hipMeasure);
        setBottomInSeam(projectBodyRef.current.bottomInSeam);
        setRiseMeasure(projectBodyRef.current.riseMeasure);
        setThighMeasure(projectBodyRef.current.thighMeasure);
        setLegOpening(projectBodyRef.current.legOpening);
        setAddtionaInfo(projectBodyRef.current.additionalInfo);
        setHeelHeightMeasure(projectBodyRef.current.heelHeightMeasure);
        setSoleMeasure(projectBodyRef.current.soleMeasure);
        setInSoleMeasure(projectBodyRef.current.inSoleMeasure);
        setDataGenerated(true) // Hide Generate Data Button
      }
      setGenerateDataLoading(false);

      if(id!==""){
        await updateSortedLabelData(
          template,
          userID,
          projectName,
          projectBodyRef.current.images,
          projectBodyRef.current.backImage,
          projectBodyRef.current.supplementaryImages,
          projectBodyRef.current.types,
          projectBodyRef.current.itemGenericType,
          projectBodyRef.current.taxonomyUpdatedType,
          projectBodyRef.current.itemName,
          projectBodyRef.current.brand,
          projectBodyRef.current.sku,
          projectBodyRef.current.vendor,
          projectBodyRef.current.size,
          projectBodyRef.current.sizeNotes,
          projectBodyRef.current.costPerItem,
          projectBodyRef.current.price,
          projectBodyRef.current.retailPrice,
          projectBodyRef.current.condition,
          projectBodyRef.current.conditionDescription,
          projectBodyRef.current.description,
          projectBodyRef.current.imageFileName,
          projectBodyRef.current.colors,
          projectBodyRef.current.genders,
          projectBodyRef.current.variantGrams,
          projectBodyRef.current.tags,
          projectBodyRef.current.material,
          projectBodyRef.current.sleeves,
          projectBodyRef.current.style,
          projectBodyRef.current.length,
          projectBodyRef.current.age,
          projectBodyRef.current.closure,
          projectBodyRef.current.fastening,
          projectBodyRef.current.fastening_type,
          projectBodyRef.current.neckline,
          projectBodyRef.current.cut,
          projectBodyRef.current.hood,
          projectBodyRef.current.typeObj,
          projectBodyRef.current.belt,
          projectBodyRef.current.design,
          projectBodyRef.current.lining,
          projectBodyRef.current.layers,
          projectBodyRef.current.pattern,
          projectBodyRef.current.skirt,
          projectBodyRef.current.rise,
          projectBodyRef.current.heelHeight,
          projectBodyRef.current.heelShape,
          projectBodyRef.current.height,
          projectBodyRef.current.toe,
          projectBodyRef.current.era,
          projectBodyRef.current.madeIn,
          projectBodyRef.current.lengthMeasure,
          projectBodyRef.current.sleeveInSeam,
          projectBodyRef.current.pitToPitMeasure,
          projectBodyRef.current.shoulderMeasure,
          projectBodyRef.current.waistMeasure,
          projectBodyRef.current.hipMeasure, 
          projectBodyRef.current.bottomInSeam,
          projectBodyRef.current.riseMeasure,
          projectBodyRef.current.thighMeasure,
          projectBodyRef.current.legOpening,
          projectBodyRef.current.concerns,
          id        
        )



       
      }

      const body = {
        _id: objectID
      };
      
      switch (template) {
        case 'vintage':
          endpoint = '/vintage-apparel-product-feed-status';
          break;
        case 'new-clothes':
          endpoint = '/new-apparel-product-feed-status';
          break;
        case 'pre-loved-regular':
          endpoint = '/regular-preLoved-product-feed-status';
          break;
        default:
          break;
      }
      
      const url = `https://apie.snapwrite.ca/storeData${endpoint}`;
      
      try {
        await axios.put(url, body);
        setDataGenerated(true);
      } catch (err) {
        console.error(err);
      }
      
    }
    
    projectBodyRef.current = {}; // reset the projectBodyRef
    
    }
	

	const projectBodyRef = useRef({});

	function updateProperty(key, value, index) {
	// Initialize key if it doesn't exist
		if (!projectBodyRef.current.hasOwnProperty(key)) {
			if (objectID === '') {
				projectBodyRef.current[key] = [];
			} else {
				return; // Don't update if objectID exists and key doesn't exist
			}
		}

		if (value && value.length > 0 && value !== '') {
			projectBodyRef.current[key][index] = value;
		} else if (key === 'supplementaryImages') {
			projectBodyRef.current[key][index] = [];
		} else {
			projectBodyRef.current[key][index] = '';
		}

	}
	  
  
  async function getSuggestions(index, res) {

    try {
      //Item Description
      updateProperty('description', description[index] || res.data.description, index);
      updateProperty('concerns', concerns[index] || res.data.concerns, index);
      updateProperty('backImage', backImage[index] || res.data.backImage, index);
      

      const firstLetterCapital = (str) =>{        
        return (str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))        
      }

  
      if(res.data.item.toString().replace(/,/g, " ")){
        let typeVal = firstLetterCapital(res.data.item.toString().replace(/,/g, " "));
        updateProperty('itemGenericType', itemGenericType[index] || typeVal, index);
      }
     
      let typesToPush;
      if(res.data.type.length===0){
        if(res.data.item.toString().replace(/,/g, " ")){
          let typeVal = firstLetterCapital(res.data.item.toString().replace(/,/g, " "));
          if(typeVal.includes('/')){
            typeVal = typeVal.substring(typeVal.indexOf('/') + 1)
          }
          typesToPush = typeVal     
        } else {
          typesToPush = ""
        }        
      }
      else{
        typesToPush = (firstLetterCapital(res.data.type.toString().replace(/,/g, " ")));                
      }
      updateProperty('types', types[index] || typesToPush, index);


      let TaxonomyTypeToPush = (TaxonomyUpdate(userID, res.data.item.toString(), res.data.type.toString(), template))    
      updateProperty('taxonomyUpdatedType', typesTaxoUpdate[index] || TaxonomyTypeToPush, index);  

      
      //Product Color
      let colorToPush;
      if (!colors[index] || colors[index].trim() === "") {
       colorToPush = (firstLetterCapital(res.data.color.toString().replace(/,/g, " ")));
      }     
      updateProperty('colors', colors[index] || colorToPush, index);     

      let materialToPush;
      if(res.data.material){
        materialToPush = (res.data.material.join(', '));
      }
      else {
        materialToPush = ("")
      }
      updateProperty('material', material[index] || materialToPush, index);

      let lengthToPush;
      if(res.data.length){
        lengthToPush = res.data.length.join(', ');
      }
      else {
        lengthToPush = ""
      }
      updateProperty('length', length[index] || lengthToPush, index);

      let styleToPush;
      if(res.data.style){
        styleToPush = res.data.style.join(', ');
      }
      else {
        styleToPush = ""
      }
      updateProperty('style', style[index] || styleToPush, index);

      let ageToPush
      if(res.data.age) {
       ageToPush = (res.data.age.join(', '))
      } else {
       ageToPush = ("")
      }
      updateProperty('age', age[index] || ageToPush, index);

      let designToPush
      if(res.data.design) {
       designToPush = (res.data.design.join(', '))
      } else {
       designToPush = ("")
      }
      updateProperty('design', design[index] || designToPush, index);

      let closureToPush
      if(res.data.closure) {
       closureToPush = (res.data.closure.join(', '))
      } else {
       closureToPush = ("")
      }
      updateProperty('closure', closure[index] || closureToPush, index);

      let fasteningToPush;
      if(res.data.fastening) {
       fasteningToPush = (res.data.fastening.join(', '))
      } else {
       fasteningToPush = ("")
      }
      updateProperty('fastening', fastening[index] || fasteningToPush, index);

      let fasteningTypeToPush;
      if(res.data.fasteningType) {
       fasteningToPush = (res.data.fasteningType.join(', '))
      } else {
       fasteningToPush = ("")
      }
      updateProperty('fastening_type', fastening_type[index] ||  fasteningTypeToPush, index);

      let beltToPush;
      if(res.data.belt) {
       beltToPush = (res.data.belt.join(', '))
      } else {
       beltToPush = ("")
      }
      updateProperty('belt', belt[index] || beltToPush, index);      
      
      let cutToPush;
      if(res.data.cut) {
       cutToPush = (res.data.cut.join(', '))
      } else {
       cutToPush = ("")
      }
      updateProperty('cut', cut[index] || cutToPush, index);
      
      let necklineToPush;
      if(res.data.neckline) {
        necklineToPush = (res.data.neckline.join(', '))
      } else {
        necklineToPush = ("")
      }      
      updateProperty('neckline', neckline[index] ||  necklineToPush, index);

      let sleevesToPush;
      if(res.data.sleeves) {
       sleevesToPush =(res.data.sleeves.join(', '))
      } else {
       sleevesToPush =("")
      }
      updateProperty('sleeves', sleeves[index] || sleevesToPush, index);

      let hoodToPush
      if(res.data.hood) {
        hoodToPush = (res.data.hood.join(', '))
      } else {
        hoodToPush = ("")
      }
      updateProperty('hood', hood[index] || hoodToPush, index);

      let liningToPush  
      if(res.data.lining) {
        liningToPush = (res.data.lining.join(', '))
      } else {
        liningToPush = ("")
      }
      updateProperty('lining', lining[index] || liningToPush, index);

      let layersToPush
      if(res.data.layers) {
      layersToPush = (res.data.layers.join(', '))
      } else {
      layersToPush = ("")
      }
      updateProperty('layers', layers[index] || layersToPush, index);

      let patternToPush
      if(res.data.pattern) {
        patternToPush = (res.data.pattern.join(', '))
      } else {
        patternToPush = ("")
      }
      updateProperty('pattern', pattern[index] || patternToPush, index);

      let skirtToPush
      if(res.data.skirt) {
       skirtToPush = (res.data.skirt.join(', '))
      } else {
       skirtToPush = ("")
      }
      updateProperty('skirt', skirt[index] || skirtToPush, index);

      let riseToPush
      if(res.data.rise) {
       riseToPush = (res.data.rise.join(', '))
      } else {
       riseToPush = ("")
      }
      updateProperty('rise', rise[index] || riseToPush, index);
      
      let heelHeigthToPush
      if(res.data.heelHeight) {
       heelHeigthToPush = (res.data.heelHeight.join(', '))
      } else {
       heelHeigthToPush = ("")
      } 
      updateProperty('heelHeight', heelHeight[index] ||  heelHeigthToPush, index);
      
      let heelShapeToPush
      if(res.data.heelShape) {
        heelShapeToPush = (res.data.heelShape.join(', '))
      } else {
        heelShapeToPush = ("")
      } 
      updateProperty('heelShape', heelShape[index] || heelShapeToPush, index);
      
      let heightToPush
      if(res.data.height) {
       heightToPush = (res.data.height.join(', '))
      } else {
       heightToPush = ("")
      } 
      updateProperty('height', height[index] || heightToPush, index);
      
      let toeToPush
      if(res.data.toe) {
       toeToPush = (res.data.toe.join(', '))
      } else {
       toeToPush = ("")
      }
      updateProperty('toe', toe[index] || toeToPush, index);

      let typesToUpdate
      if(res.data.types) {
        typesToUpdate = (res.data.types.join(', '))        
      } else {
        typesToUpdate = ("")
      }
      updateProperty('types', types[index] || typesToUpdate, index);

      //Product Gender
      let genderToPush;
      
      if((userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || userID==="t4gFVUQejYQYBY6dhwOp2mkYMTe2" || userID ==='WbahZZa5HhfWAa6SyJLYnl31kMG3' || template ==='vintage' || template ==='new-clothes' || template ==='pre-loved-regular')){
        if(res.data.gender.includes("Boys")){
          genderToPush = ("Men");
        }
        else if (res.data.gender.includes("Girls")){
          genderToPush = ("Women");  
        }
      } else{
        genderToPush = (firstLetterCapital(res.data.gender.toString().replace(/,/g, " ")));
      }
      updateProperty('gender', genders[index] || genderToPush, index);

      //Variant Grams
      updateProperty('variantGrams', variantGrams[index] || res.data.variantGrams, index);
      updateProperty('tags', tags[index] || res.data.tags, index);
      setDataGenerated(true)
    } catch (e) {
      console.log("Please try using a different image");
      console.error(e);
    }
  }

  const GenerateDataButton = () => {
    return (
      <div className="tag-suggestions" style={{ display: "inline-block" }}>
        <ul className="suggestionTags" style={{ display: "flex", gap: "20px" }}>
          {/*wix button on master table */}
          {userID === "NpGB6zItW1OcpizZJbD88VsfKlf2" && dataGenerated && (
            <PrimaryIconButton title={"Generate WIX CSV"} 
              onClick={async () => {
                generateCSVData("wixCSV").then((result) => {
                  downloadCSV(result);
                });
              }}
            />
          )}

          {window.location !== window.parent.location && (
            <ImportButton
              {...{
                csvDownload,
                projectName,
                setFail,
                setSuccess,
                generateCSVData,
                userID,
                size,
                template,
                objectID,
              }}
            />
          )}
          {/* {dataGenerated && (
            <button
              variant="outlined"
              className="btn px-3"
              onClick={async () => {
                const isImport = false;
                await generateCSVData("shopifyCSV", isImport);
                const generateButton = document.getElementById("csvLinkButton");

                generateButton.click();
              }}
            >
              Shopify CSV
            </button>
          )} */}
          {!dataGenerated && (
            <PrimaryButton title={"Generate Product Feed"} onClick={uploadImage} />
          )}

          <CSVLink
            id="csvLinkButton"
            hidden
            className="btn"
            data={csvDownload}
            // onClick={async ()=> await generateCSVData()}

            filename={`${
              projectName !== "" ? projectName : "Import"
            }_SnapWriteAI.csv`}
          />
          {/* <CSVLink data={csvDownload}>Download me</CSVLink> */}
        </ul>
      </div>
    );
  };

 
  return (
<>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Import Successful!
        </Alert>
      </Snackbar>
      <MainSideMenuHeaderContainer
      updateProjectName={updateProjectName}
      setProjectName={setProjectName}
      projectName={projectName}
      name={"labels"}
      >
              {s3Images.length === 0 && rows !== "Loading..." && !isLoading && !generateDataLoading? (
                <></>
              ) : (
                <>
                  {isLoading || generateDataLoading? (
                    <h2 style={{ textAlign: "center" }}>
                      {waitingText}
                      {waitingText ===
                      "Enhancing SEO. Please Wait for about a minute" ? (
                        <>
                          <br />
                          <img
                            src="https://media.tenor.com/fJ2vm3_UhcAAAAAi/google-working.gif"
                            alt="loading..."
                          />
                        </>
                      ) : (
                        <>
                          <br />
                          <img
                            src="https://tenor.com/view/working-hahacc-gif-18886234"
                            alt="loading..."
                          />
                        </>
                      )}
                    </h2>
                  ) : (
                    <div style={{paddingBottom: 70, marginLeft:10}} id="observation-data">
                          {!isLoading && !generateDataLoading && (<div className= {isVendorColSelected? "fixed right-5 top-[6.2rem]": " fixed right-5 top-[6.2rem]"}>
                            <GenerateDataButton />
                          </div>)}
                      <TableList
                          projectData={projectData}
                          setProjectData={setProjectData}
                          accountType={accountType}
                          setAccountType={setAccountType}
                          fetchedBrands={fetchedBrands}
                          preferredItemTypes={preferredItemTypes}
                          template={template}
                          objectID={objectID}
                          setImageFileName={setImageFileName}
                          setS3Images={setS3Images}
                          updateVal={updateVal}
                          updateValIndividual={updateValIndividual}
                          setType={setType}                          
                          s3Images={s3Images}
                          supplementaryImages={supplementaryImages}
                          setSupplementaryImages={setSupplementaryImages}
                          backImage={backImage}
                          setBackImage={setBackImage}
                          imageFileName={imageFileName}
                          itemGenericType={itemGenericType}
                          setItemGenericType={setItemGenericType}
                          types={types}
                          typesTaxoUpdate={typesTaxoUpdate} 
                          setTypesTaxoUpdate={setTypesTaxoUpdate}                 
                          brand={brand}
                          setBrand={setBrand}
                          vendor={vendor}
                          setVendor={setVendor}
                          condition={condition}
                          setCondition={setCondition}
                          conditionDescription={conditionDescription}
                          setConditionDescription={setConditionDescription}
                          description={description}
                          setDescription={setDescription}
                          itemName={itemName}       
                          setItemName={setItemName}   
                          size={size}          
                          setSize={setSize}
                          sizeNotes={sizeNotes}
                          setSizeNotes={setSizeNotes}
                          price={price}   
                          setPrice={setPrice}       
                          retailPrice={retailPrice}
                          setRetailPrice={setRetailPrice}
                          costPerItem={costPerItem} 
                          setCostPerItem={setCostPerItem}         
                          genders={genders}             
                          setGender={setGender}       
                          colors={colors}     
                          setColor={setColor}
                          additionalInfos={additionalInfos}
                          setAdditionalInfo={setAddtionaInfo}
                          variantGrams={variantGrams}
                          setVariantGrams={setVariantGrams}
                          tags={tags}
                          setTags={setTags}
                          userID={userID}                          
                          sku={sku}
                          setSku={setSku}
                          material={material}
                          setMaterial={setMaterial}
                          length={length}
                          setLength={setLength}
                          style={style}
                          setStyle={setStyle}
                          age={age}
                          setAge={setAge}                          
                          design= {design}
                          setDesign={setDesign}
                          closure= {closure}
                          setClosure={setClosure}
                          fastening={fastening}
                          setFastening={setFastening}
                          fasteningType= {fastening_type}
                          setFasteningType={setFasteningType}
                          belt= {belt}
                          setBelt={setBelt}
                          cut= {cut}
                          setCut={setCut}
                          neckline= {neckline}
                          setNeckline={setNeckline}
                          sleeves= {sleeves}
                          setSleeves={setSleeves}
                          hood= {hood}
                          setHood={setHood}
                          typeObj= {typeObj}                              
                          setTypeObj={setTypeObj}

                          lining={lining}
                          setLining={setLining}
                          layers={layers}
                          setLayers={setLayers}
                          pattern={pattern}
                          setPattern={setPattern}
                          skirt={skirt}
                          setSkirt={setSkirt}
                          rise={rise}
                          setRise={setRise}
                          heelHeight={heelHeight}
                          setHeelHeight={setHeelHeight}
                          heelShape={heelShape}
                          setHeelShape={setHeelShape}
                          height={height}
                          setHeight={setHeight}
                          toe ={toe}
                          setToe={setToe}
                          concerns={concerns}
                          setConcerns={setConcerns}
                        
                        // Measurements

                        waistMeasure={waistMeasure}
                        setWaistMeasure={setWaistMeasure}
                        hipMeasure={hipMeasure}
                        setHipMeasure={setHipMeasure}
                        bottomInSeam={bottomInSeam}
                        setBottomInSeam={setBottomInSeam}
                        riseMeasure={riseMeasure}
                        setRiseMeasure={setRiseMeasure}
                        thighMeasure={thighMeasure}
                        setThighMeasure={setThighMeasure}
                        legOpening={legOpening}
                        setLegOpening={setLegOpening}
                        sleeveInSeam={sleeveInSeam}
                        setsleeveInSeam={setsleeveInSeam}
                        pitToPitMeasure={pitToPitMeasure}
                        setPitToPitMeasure={setPitToPitMeasure}
                        shoulderMeasure={shoulderMeasure}
                        setShoulderMeasure={setShoulderMeasure}
                        lengthMeasure={lengthMeasure}
                        setLengthMeasure={setLengthMeasure}
                        // shoes
                        heelHeightMeasure={heelHeightMeasure}
                        setHeelHeightMeasure={setHeelHeightMeasure}
                        soleMeasure={soleMeasure}
                        setSoleMeasure={setSoleMeasure}
                        inSoleMeasure={inSoleMeasure}
                        setInSoleMeasure={setInSoleMeasure}
                        shoeWidth={shoeWidth}
                        setShoeWidth={setShoeWidth}
                        // Vintage

                        era={era}
                        setEra={setEra}
                        madeIn={madeIn}
                        setMadeIn={setMadeIn}
                        isLoading={isLoading}
                        setLoading={setLoading}
                         // Utilized for ExportButton.js
                         downloadCSV = {downloadCSV}
                         generateCSVData = {generateCSVData}
                         isVendorColSelected={isVendorColSelected}
                         setIsVendorColSelected={setIsVendorColSelected}
                      />
                    </div>
                  )}
                </>
              )}
              
							{!isLoading && !generateDataLoading &&  // hide buttons when loading
              <div style={{zIndex: dataGenerated ? 1 : 10, position: "absolute", bottom: 20, left: "50%"}} className="suggestions">
                <GenerateDataButton />
              </div>
							}	

          
            </MainSideMenuHeaderContainer>
</>
  );
};

export default ClothingMasterTable;
