import { useState, useEffect, useContext } from "react";
import trash from "../../../../../assets/trash_can.png";
import ImageColumn from "../../tableComponents/imageColumn";
import { handleDeleteIndividual } from "../../utils/handleRowDelete";
import SupplementaryImageColumn from "../../tableComponents/supplementaryImageColumn";
import axios from "axios";
import { RowColumn } from "../../tableList";
import AttributesColumn from "./attributesColumn";
import { OrganizationContext } from "../../../../../components/App";
import SportswearAttributesColumn from "./sportswearAttributesColumn";
import { TableRow, TableHead, TableBody, TableCell, Stack, Typography } from "@mui/material";

const SportswearTable = ({prop}) => {
  const [deletedRows, setDeletedRows] = useState([]);
  const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
  });

  const props = prop
  // console.log(props.colorFR)
  const {handleRowSelection} = props

  
	const {organization, setOrganization} = useContext(OrganizationContext)

  const userID =  organization?.id || props.userID;


  const handleHover = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "visible";

  };

  // Make trash button hidden when the table row is not being hovered and return cells to their original colour
  const handleMouseOut = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "hidden";

  };
  const modelHeightOptions = [{value: "5'7\"", label: "5'7\""}, {value: "5'8\"", label: "5'8\""}, {value: "5'9\"", label: "5'9\""}, {value: "5'10\"", label: "5'10\""}, {value: "5'11\"", label: "5'11\""}]
  // const modelSizeOptions = [{value: "SMALL", label: "SMALL"}, {value: "MEDIUM", label: "MEDIUM"}, {value: "LARGE", label: "LARGE"}, {value: "4", label: "4"}, {value: "6", label: "6"}, {value: "8", label: "8"}, {value: "1X", label: "1X"}]
  const modelSizeOptions = [{value: "S", label: "S"}, {value: "M", label: "M"}, {value: "L", label: "L"}, {value: "4", label: "4"}, {value: "6", label: "6"}, {value: "8", label: "8"}, {value: "1X", label: "1X"}, {value: "that is one size, fits Small (size 6) to Large (size 14)", label: "O/S"}]
  

  const dropdownOptions = {}
  const isSeasonP4 = props.season === "p4-coats" || props.season === "p4-sports"





  const defaultOptions = [
    "1",
    "2",
    "3"
  ]

  const descriptionSubheadStyle = {
    color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
    fontFamily: 'sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '0.15px',
    paddingLeft: '30px',
    marginBottom: '-20px',

  };
  
  console.log(organization)
  return (
    organization &&
    <table>
      <TableHead style={{ borderBottom: '1px solid' }}>
        <tr className="tableheader">
          <th className="tablecol1 p-2"></th>
          <th className="imgcol p-2">Front View</th>
          <th className="pl-4">Side View</th>
          <th className='pl-4'>Back View</th>
          <th className='pl-4'>Tag</th>
          <th className='pl-4'>Attributes</th>
          <th className='pl-4'>Titles</th>
          {isSeasonP4 ? 
            <th className='pl-4'>Descriptions</th> 
            : 
            <>
              <th className='pl-4'>Description EN</th>
              <th className='pl-4'>Description FR</th>
            </>
          } 
          <th className='pl-4'>Supplier</th>          
          <th className='pl-4'>Brand</th>
          <th className='pl-4'>Style Code</th>
          <th className='pl-4'>Length</th>
          <th className='pl-4'>Material</th>
          <th className='pl-4'>Model</th>
          <th className='pl-4'>Class</th>
          <th className='pl-4'>Retail 1 Description</th>
          <th className='pl-4'>Color FR</th>          
          <th>Notes</th>
        </tr>
      </TableHead>
      <TableBody id="rows">
        {props.s3Images.map((key, index) => {
          const rows = []
          rows.push(props.s3Images[index] !== undefined &&
              !deletedRows.includes(index) ? (
              <TableRow
                key={props.objectID[index]}
                id={index}
                style={{ verticalAlign: 'top' }}
              >
                <TableCell className="tablecol1">
                  {/* <img
                    src={trash}
                    id={`trash${index}`}
                    alt="delete"
                    style={{
                      height: "2vh",
                      width: "1vw",
                      opacity: 0.5,
                      visibility: "hidden",
                    }}
                    onClick={() => {
                      let actualIndex = index; // When we delete a row, the # of rows in database decreases, but not in the frontend
                      deletedRows.forEach((d) => {
                        // (to prevent changing props). actualIndex = index in database. index = index in frontend.
                        if (d < index) actualIndex--;
                      });
                      handleDeleteIndividual(actualIndex, props);
                      setDeletedRows([...deletedRows, index]);
                    }}
                  /> */}
                  <input
                    type="checkbox"
                    style={{ backgroundColor: "#e3fc00" }}
                    onChange={async (e) => {
                      handleRowSelection(e, index);
                    }}
                  />
                </TableCell>
                <ImageColumn
                singleProductModelFilter = {{userID: props.userID, sku: props.sku[index]}}  
                  mm
                  attr = "s3Images"
                  setParentVal={props.setS3Images}
                  images={props.s3Images}
                  fileName={props.imageFileName[index]}
                  setS3Images={props.setS3Images}
                  index={index}
                  template={props.template}
                  id={props.objectID}
                  
                />
                <ImageColumn
                singleProductModelFilter = {{userID: props.userID, sku: props.sku[index]}}
                  attr = "sideImages"
                  mm
                  setParentVal={props.setSideImages}
                  name={"Side"}
                  noScroll
                  noHover
                  images={props.sideImages}
                  fileName={props.imageFileName[index]}
                  setS3Images={props.setSideImages}
                  index={index}
                  template={props.template}
                  id={props.objectID}  
                />
                <ImageColumn
                singleProductModelFilter = {{userID: props.userID, sku: props.sku[index]}}
                  attr = "backImages"
                  mm
                  setParentVal={props.setBackImages}
                  name={"Back"}
                  noScroll
                  noHover
                  images={props.backImages}
                  fileName={props.imageFileName[index]}
                  setS3Images={props.setBackImages}
                  index={index}
                  template={props.template}
                  id={props.objectID}  
                />
                <ImageColumn
                singleProductModelFilter = {{userID: props.userID, sku: props.sku[index]}}
                  attr = "itemImages"
                  mm
                  setParentVal={props.setItemImages}
                  name={"Tag"}
                  noScroll
                  noHover
                  images={props.itemImages}
                  fileName={props.imageFileName[index]}
                  setS3Images={props.setItemImages}
                  index={index}
                  template={props.template}
                  id={props.objectID}  
                />
                <TableCell>
                  <SportswearAttributesColumn 
                    closureOptions={organization.closureOptions}
                    hoodOptions={organization.hoodOptions}
                    liningOptions={organization.liningOptions}
                    pocketOptions={organization.pocketOptions}
                    drawstringOptions={organization.drawstringOptions}
                    sleeveCuffsOptions={organization.sleeveCuffsOptions}
                    sleeveLengthOptions={organization.sleeveOptions}
                    collarOptions={organization.collarOptions}
                    interiorPocketOptions={organization.interiorPocketOptions}
                    patternOptions = {organization.patternOptions}
                    necklineOptions = {organization.necklineOptions}
                    skirtOptions={organization.skirtOptions}

                    prop={props} 
                    index={index} 
                    userID={props.userID}
                  />
                </TableCell>
                <TableCell>
                  <div style = {{display: "flex", height: "fit-content", paddingLeft:10}}>
                  <RowColumn label="English" name="titleEN" type="muiText" index={index} prop={props} />
                  </div>
                  <br></br>
                  <div style = {{display: "flex", height: "fit-content", paddingLeft:10}}>
                  <RowColumn label="Français" name="titleFR" type="muiText" index={index} prop={props} />
                  </div>
                </TableCell>
                <TableCell >
                  <Stack gap={9}>
                        <div>
                          <Typography sx={descriptionSubheadStyle}>English</Typography>
                          <RowColumn
                            name='descriptionEN'
                            type='description'
                            index={index}
                            prop={props}
                          />
                        </div>
                        <div>
                          <Typography sx={descriptionSubheadStyle}>Français</Typography>
                          <RowColumn
                            name='descriptionFR'
                            type='description'
                            index={index}
                            prop={props}
                          />
                        </div>
                  </Stack>
                </TableCell>
                <TableCell>
                  <div style = {{display: "flex", height: "fit-content"}}>
                    <RowColumn
                      label = 'Code'
                      name="supplierCode"
                      type="muiText"
                      index={index}
                      prop={props}
                    />                  
                  </div>
                  <br></br>
                  <div style = {{display: "flex", height: "fit-content"}}>
                    <RowColumn
                      label = "Name"
                      name="supplierName"
                      type="muiText"
                      index={index}
                      prop={props}
                    />                  
                  </div>
                </TableCell>
                
                
                <RowColumn  name="brand" type="ta" index={index} prop={props} />
                <RowColumn
                  name="styleCode"
                  type="ta"
                  index={index}
                  prop={props}
                />
                <TableCell>
                  <div style={{minWidth: "200px"}}>
                <RowColumn numeric conversion={0.393701} label="CM" otherLabel="IN" otherName="inches" otherAttr="lengthInches" name="length" type="linkedPropertyItem"  index={index} prop={props} />
                
                <RowColumn
                noTD
                  name="fit"
                  userID = {props.userID}
                  noNotes
                  noSave
                  label="Fit"
                  type="autocomplete"
                  autocompleteOptions={organization.fitOptions}
                  index={index}
                  prop={props}
                /> 
                </div>
                </TableCell>
                <TableCell> 
                <RowColumn
                  noTD
                  name="careInstructions"
                  label="Care Instructions"
                  userID = {props.userID}
                  noNotes
                  type="autocomplete"
                  autocompleteOptions={organization.careInstructionsOptions}
                  index={index}
                  prop={props}
                />        
                <br></br>
                <RowColumn
                noTD
                  name="fabric"
                  userID = {props.userID}
                  noNotes
                  noSave
                  label="Fabric Composition"
                  type="autocomplete"
                  autocompleteOptions={organization.fabricOptions}
                  index={index}
                  prop={props}
                />   
                 
                {/* <br></br>
                <RowColumn
                noTD
                  name="exteriorMaterialOptions"
                  userID = {props.userID}
                  noNotes
                  noSave
                  label="Exterior Material Details"
                  type="autocomplete"
                  autocompleteOptions={exteriorMaterialOptions}
                  index={index}
                  prop={props}
                />   
                <br></br>
                <RowColumn
                noTD
                  name="liningMaterialOptions"
                  userID = {props.userID}
                  noNotes
                  noSave
                  label="Lining Material Details"
                  type="autocomplete"
                  autocompleteOptions={liningMaterialOptions}
                  index={index}
                  prop={props}
                />    */}
                </TableCell>
                
                <TableCell>
                  <RowColumn name="modelHeight" type="stackItemSelect" label={"Height"} selectOptions={modelHeightOptions} index={index} prop={props} />
                  <br></br>
                  <RowColumn name="modelSize" type="stackItemSelect" label={"Item Size"} selectOptions={modelSizeOptions} index={index} prop={props} />
                </TableCell>
                
                <RowColumn name="webCode" attr="web_class_code" type="ta" index={index} prop={props} />
                <RowColumn
                  name="retail1Description"
                  type="ta"
                  index={index}
                  prop={props}
                />
                {/*<RowColumn name="colorEN" type="ta" index={index} prop={props} />*/}
								<TableCell style={{display:"grid", paddingBottom:"18.1rem"}}>
								{
									props.colorFR[index].map((color, i) => 
										<RowColumn key={i} colorOption = {i}  name="colorFR" type="ta" index={index} prop={props} />
									)
								}
								</TableCell>
               
                
                <RowColumn textBoxHeight="130px" textBoxWidth="300px" name="notes" type="ta" index={index} prop={props} />
                {/* <RowColumn setParentVal={props.setClosure} name="closure" type="ta" index={index} prop={props} /> */}
              </TableRow>
            ) : null);
          return rows
        })}
      </TableBody>
    </table>
  );
};

export default SportswearTable;
