import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

//this config is being used for both development and production environment. Though, it is a best practice creating a second project and have two configs: one for production (prodConfig) and another for development (devConfig), so you choose the config based on the environment.

const config = {
  apiKey: "AIzaSyDkSNBm1plRVQH3wLanZ3S9iSH8u1jI_O4",
  authDomain: "snapwriteai.firebaseapp.com",  
  projectId: "snapwriteai",
  storageBucket: "snapwriteai.appspot.com",
  messagingSenderId: "10628816475",
  appId: "1:10628816475:web:391708244ce1f4b31e297e",
  measurementId: "G-DQS5KWPDZC"
};

if (!firebase.apps.length) {
  //initializing with the config object
  firebase.initializeApp(config);
}

//separting database API and authentication

const auth = firebase.auth();

const facebookProvider = new firebase.auth.FacebookAuthProvider();

export {  auth, facebookProvider };
