import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import copy from "copy-to-clipboard";
import axios from "axios";
import ImageUploadCode from "../../../products/imageUpload";
import TagsInput from "../../../products/tagsArea";
import { SideMenu } from "../../sideMenu";
import { Header } from "../../navigation/header";
import {
  wordCount,
  characterCount,
  sentenceCount,
  tagsList,
  keywordsMatched,
} from "../../wordCountArea";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

const AltTextData = ({history}) => {
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
        history.push('/')
    }
},[])
  const { id } = useParams();

  const [altTextData, setAltTextData] = useState({
    projectName: "",
    imageUploaded: "",
    suggestedTags: [""],
    tagsUsed: [""],
    generatedAltTexts: [""],
  });
  

  useEffect(() => {    
    fetch(`https://apie.snapwrite.ca/storeData/alt-texts/${id}`)
      .then((response) => response.json())
      .then((json) => {
        setAltTextData(json.data);
      })
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }, []);

  const updateStoredAltText = () => {        
    if(id === JSON.parse(localStorage.getItem("altTextObjectID"))){
      localStorage.setItem("altTexts", JSON.stringify(altTextData.generatedAltTexts))   
    }
    fetch(`https://apie.snapwrite.ca/storeData/alt-texts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: id,
        generatedAltTexts: altTextData.generatedAltTexts
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }

  async function reGenerateAltText(tags) {
    if(altTextData.generatedAltTexts.length < 3){

      try {
        let altTexts = altTextData.generatedAltTexts;
        altTexts.push("Loading...");
        setAltTextData({ ...altTextData, generatedAltTexts: altTexts });      
        const res = await backendClient.post("/alt-text", {
          tags: tags,
          user: altTextData.userID,
        });
        altTexts[altTexts.length - 1] = res.data;
        setAltTextData({ ...altTextData, generatedAltTexts: altTexts });
        updateStoredAltText()
      } catch (e) {
        alert("Error Occurred! Try again. If retry does not work contact us :)")          
        console.error(e);
      }
    }
  }

  const updateAltText = (index, event) => {
    const descriptions = altTextData.generatedAltTexts;
    descriptions[index] = event.target.value;
    setAltTextData({...altTextData, generatedAltTexts: descriptions });
    updateStoredAltText();
  }

  return (
    <div className="App">
      <Header projectName={altTextData.projectName}/>

      {/* ******************************the <main> section****************************** */}
      <div className="interface">
        <SideMenu />
        {/* The grey section where the user can upload image, edit tags and get the description */}
        <div className="playGround">
          {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
          <div className="tools">
            <div className="drop-and-tags">
              {/* ************The image upload area************** */}
              <ImageUploadCode
                selectedTags={altTextData.suggestedTags}
                setDescriptionData={setAltTextData}
                tags={altTextData.tags}
                suggestedTags={altTextData.suggestedTags}
                image={altTextData.imageUploaded}
                descriptionData={altTextData}
              />
              {/* ************************************************ */}
              <div className="card">
                <div className="card-header"> Tags </div>
                <div className="card-body appTag">
                  {/* **************************Tags area************************** */}
                  <div className="appTag">
                    <TagsInput
                      descriptionData={altTextData}
                      tags={altTextData.tagsUsed}
                      setDescriptionData={setAltTextData}
                    />
                  </div>
                  <div className="tagsNumberDesc">
                    Add or edit 2-3 tags describing unique features
                  </div>
                  <button
                    className="btn btn-primary1"
                    disabled={altTextData.generatedAltTexts.length>3}
                    onClick={() => {
                      reGenerateAltText(altTextData.tagsUsed);
                    }}
                  >
                    Re-Generate
                  </button>
                </div>
              </div>
              {/* ************************************************************* */}
            </div>
            <div className="altTextSection">
              <div>
                <div className="card-header alt-text-header">Alt Text</div>
                {altTextData.generatedAltTexts.map((altText, index) => {
                  return (
                    <div className="card  card1 card2">
                      <div className="card-body alt-text-card">
                        {altText !==
                        "Your alt text will appear in about 5 seconds" ? (
                          <textarea
                            name="blogOutline"
                            type="text"
                            value={altText}
                            onChange={ (event) => {
                              updateAltText(index, event)  
                            }}
                          />
                        ) : (
                          altText
                        )}
                      </div>
                      <div className="card-footer-alt">
                        <div className="wordCountArea">
                          <div className="p-2 bd-highlight">
                            Count (Character / Word / Sentence) :{" "}
                            {characterCount(altText)} / {wordCount(altText)} /{" "}
                            {sentenceCount(altText)===0 ? 1 : sentenceCount(altText)}
                            <br /> Keywords matched:{" "}
                            {keywordsMatched(altTextData.tagsUsed, altText)}/
                            {altTextData.tagsUsed.length}&nbsp;
                            <select
                              className="dropdownTags"
                              name="TagsList"
                              id="TagsList"
                            >
                              {tagsList(altTextData.tagsUsed, altText)}
                            </select>
                          </div>

                          <div className="copyButtonIcon"  color="grey"
                              onClick={()=>copy(altText)}>
                            copy
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AltTextData;
