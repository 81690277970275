import React from 'react';
import AuthenticationPageBg from '../assets/authenticationbg.png';
import snapWriteLogo from '../assets/sw-logo.png';
import { Avatar } from '@mui/material';
import testimonialImg from '../assets/testimonial.png';

export default function AuthenticationPageView({ children }) {
	return (
		<section className='h-screen w-screen flex justify-center font-inter '>
			{/* The background image */}
			<img
				src={AuthenticationPageBg}
				className='fixed w-100 -z-10 h-full object-cover object-center filter blur-[10px] brightness-50'
				alt='backgroundImage'
			/>

			{/* main content  */}
			<div className=' min-w-max w-full mx-5 xl:mx-0 xl:w-10/12 pt-10 flex flex-col md:gap-5 xl:gap-[100px] max-w-[1800px]'>
				{/* Logo of SnapWrite */}
				<img
					src={snapWriteLogo}
					className='w-[130px] md:w-[180px] lg:w-[220px]'
					alt='snapWriteLogo'
				/>

				<div className='flex flex-col-reverse gap-10 items-center landscape:flex-row landscape:justify-between'>
					<div className='text-[#333333] hidden md:block'>
						<h2 className='text-[30px] font-[600] text-center tracking-[0.25px] max-w-[640px] p-4'>
							Empowering businesses for brands, retailers, and
							resellers
						</h2>
						<div className='py-[10px] xl:py-[40px] border-dotted border-t-0 border-r-0 border-l-0 mb-[40px] border-b-[1px] w-[26rem] xl:w-[40rem] mx-auto'>
							<video
								src='https://storage.googleapis.com/test1729-1/SnapWrite%20Video%20Snippet%20-%20Draft%206.mp4'
								title='introVideo'
								className='w-full h-auto rounded-2xl'
								controls={true}
								autoPlay={true}
								muted={true}
								controlsList='nodownload'
								playsInline=''
							/>
						</div>
						<div className='flex flex-col items-center gap-[13px]'>
							<p className='w-[358px] text-center text-[16px] font-[400]'>
								“Snapwrite has significantly boosted our
								productivity and sales output.”
							</p>
							<p className='text-[12px] flex items-center justify-center gap-2'>
								<Avatar src={testimonialImg} alt='Amanda' />
								Amanda, Founder of Tokki{' '}
							</p>
						</div>
					</div>
					<div className='mt-32 max-w-[24rem] self-start portrait:mx-auto bg-white py-3 shadow-authentication px-5'>{children}</div>
				</div>
			</div>
		</section>
	);
}
