import { useCallback, useEffect, useState, useRef, useMemo, useContext } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import { Button, Chip } from '@mui/material';

import updateColumnValue from './utils/updateAttribute';
//Implement delete functionality with checkbox
import deleteItems from './utils/deleteItems';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';

import ExportButton from './tableComponents/ExportButton';
import { useParams } from 'react-router';

import {
	FloatingButton,
	Heading1,
	PrimaryButton,
	PrimaryIconButton,
	SWDropdown,
	SWModal,
} from '../../../components/branded-elements/Elements';
import TableView from './tableComponents/TableView';
import { OrganizationContext } from '../../../components/App';
import zIndex from '@mui/material/styles/zIndex';
import { generateEbayCSV } from '../../../utils/generateEbayCSV';
import { createEbayCSV } from '../ebay/createEbayCSV';

import { CSVLink } from "react-csv";

let buttonDisabled = true;
let receiverEmail = ''
let receiverID = ''

const CustomizedTable = (props) => {
	//  state variables for the New table component
	const { projectData, setProjectData, uploadImage, template } = props;

	//  state variables for the Old table component
	const prop = props.propsData;
	const propsData = props.propsData;
	const { isVendorColSelected, setIsVendorColSelected, offerAcceptStatus,setOfferAcceptStatus, accountType, setAccountType } = propsData;
	const [headers, setHeaders] = useState();
	const [userID, setUserID] = useState();
	const [store, setStore] = useState('tokki');
	const [noConditionNotes, setNoConditionNotes] = useState(false);
	const [sizeType, setSizeType] = useState('select');
	const [columnUpdated, setColumnUpdated] = useState(false);
	const { id } = useParams();
	const { objectID, setIsItemsDeleted, isItemsDeleted, setLoading, sharedWith, setSharedWith } =
		props.propsData;

		const {organization} = useContext(OrganizationContext)

	// Checkbox variables for enabling bulk Item deletion functionality
	const [isActionItemsDisabled, setIsActionItemsDisabled] = useState(true);
	const [themeDropdownList, setThemeDropdownList] = useState([]);
	const [exportButtonPreference, setExportButtonPreference] =
		useState('shopify');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const fileInputRef = useRef(null);
	// Used for delete Items function
	const [checkedItemsIndicesArray, setCheckedItemsIndicesArray] = useState(
		[]
	);
	const [selectedProducts, setSelectedProducts] = useState([]);
	// console.log(projectData.userID)
	// console.log(projectData.sharedWith)
	const [openOfferModal, setOpenOfferModal] = useState(false)
	const [openRequestChangesModal, setOpenRequestChangesModal] = useState(false);
	const [changeRequestText, setChangeRequestText] = useState('');
	const [openChangeModal, setOpenChangeModal] = useState(false);
	const [makeChangeText, setMakeChangeText] = useState('')
	
	const [ebayCSVData, setEbayCSVData] = useState('')

	const handleTextareaChange = (event) => {
		setChangeRequestText(event.target.value);
	};
	//Used for Export Buttons
	const { generateCSVData, downloadCSV } = propsData;

	// request changes button
	// const [concerns, setConcerns] = useState(propsData.concerns)
	let tempConcerns = [""];
	if (Array.isArray(projectData.images)) {
		tempConcerns = Array(projectData.images.length).fill("");
	}
	if (("concerns" in propsData)) {
		tempConcerns = propsData.concerns
	}
	const [concerns, setConcerns] = useState(tempConcerns)
	// console.log(props)
	// console.log(projectData)

	if (prop.price[prop.price.length - 1] !== '') {
		prop.description.forEach((description) => {
			if (description === '') {
				buttonDisabled = false;
			} else {
				buttonDisabled = true;
			}
		});
	} else {
		buttonDisabled = true;
	}

	let uid;


	const options = [
		'attributes',
		'brand',
		'color',
		'condition',
		'costPerItem',
		'description',
		'era',
		'gender',
		'itemName',
		'itemType',
		'measurements',
		'price',
		'retailPrice',
		'size',
		'sku',
		'vendor',
		'concerns',
	];

	const dresses = [
		'blazer dresses',
		'blouse dresses',
		'bodycon dresses',
		'fit & flare dresses',
		'sheath dresses',
		'slip dresses',
		'casual dresses',
	];
	const pants = [
		'Pants',
		'beach pants',
		'cargo',
		'culottes',
		'suit trousers',
		'sweatpants',
	];

	const shorts = ['hotpants', 'swim shorts'];

	const skirts = [
		'a-line skirts',
		'balloon skirts',
		'beach skirts',
		'mermaid skirts',
		'pencil & straight skirts',
		'pleated skirts',
		'tiered skirts',
		'tutu skirts',
		'wrap skirts',
	];

	function downloadEbayCSV(csvData, fileName) {
		const blob = new Blob([csvData], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}

	const changeProjectStatus = async(value) => {
		const body = {
		  status: value,
		  _id: objectID
		}
	
		const response = await axios
			.put('https://apie.snapwrite.ca/storeData/vintage-apparel-update-offer-status', body)
			.then((res) => res.data.status)
			.catch((err) => console.log(err));
	setOfferAcceptStatus(response)
	  }


	const tees = ['t-shirts', 'vests', 'polo-shirts'];

	const tops = ['blouses', 'shell tops', 'tunics', 'tops'];
	const sweaters = ['sweaters', 'cardigans'];

	const lightWeightJacket = ['windstoppers & softshells'];

	const fetchThemes = async (userID) => {
		try {
			const response = await axios.get(
				`https://apie.snapwrite.ca/storeData/single-product/${userID}`
			);
			const uniqueThemes = new Set(
				response.data.data
					.filter((product) => product.theme)
					.map((product) => product.theme)
					.filter((theme) => theme !== undefined)
			);

			// Convert Set back to an array
			const themeList = Array.from(uniqueThemes);

			setThemeDropdownList(themeList);

			// setThemeDropdownList(response.data.data)
		} catch (error) {
			console.error('Error fetching themes:', error);
		}
	};

	const fetchUserPreference = async (userID) => {
		axios
			.get(
				`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`
			)
			.then((res) => {
				if (res.data.data.exportPreference) {
					setExportButtonPreference(res.data.data.exportPreference);
					setAccountType(res.data.data.accountType)
				}
				// if (res.data.data.email) {

				// }
				// console.log(res.data.data.email)
			});
	};

	const fetchChecked = async (user) => {
		const response = await axios.get(
			`https://apie.snapwrite.ca/storedata/table-column?userID=${user}&template=${props.propsData.template}`
		);
		let formatted = response.data;

		if (Array.isArray(formatted.data)) {
			formatted.data = formatted.data[0];
		}

		setHeaders({ ...formatted.data });
		if (setIsVendorColSelected !== undefined) {
			setIsVendorColSelected(formatted.data.vendor);
		}
	};

	const fetchEmail = async (userID) => {
		axios
			.get(
				`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`
			)
			.then((res) => {
				if (res.data.data.email) {
					receiverEmail = res.data.data.email
				}
			});
	};

	const handleSendEmail = (recipientEmail, recipientID) => {
		console.log(recipientID)
		const body = {
			recipient: recipientEmail,
			subject: "Changes to Snapwrite Project",
			message: 'There has been changes made to your project, please check the concerns column for more information. Affected products are highlighted.',
			id: recipientID
		}
		
		axios.post("https://apie.snapwrite.ca/storeData/send-email-change", body)
		.then((res) => 
			console.log('email sent ' + recipientEmail)
			// console.log(res)
		)
		.catch(err => console.error(err))
		// console.log('email sent ' + recipientEmail)
	}

	useEffect(() => {
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				uid = user.uid;
				setUserID(user.uid);
				fetchChecked(user.uid);
				fetchThemes(user.uid);
				fetchUserPreference(user.uid);
				// tokki
				if (
					user.uid === 'zjkiN1cq9Eb4oHsrA923OoJ9Sxz1' ||
					user.uid === 'IMe1VYnWOQgQuER8JQHSI0iKNre2'
				) {
					setNoConditionNotes(true);
					setStore('tokki');
					setSizeType('ta');
				}
				// RCL
				else if (user.uid === 'Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1') {
					setNoConditionNotes(true);
					setSizeType('ta');
					setStore('royal');
					if (
						propsData.itemGenericType &&
						propsData.itemGenericType.length > 0
					) {
						Taxonomy(propsData.itemGenericType.slice());
					}
					if (propsData.types && propsData.types.length > 0) {
						let change = true;
						propsData.itemName.forEach((element) => {
							if (element !== '') {
								change = false;
							}
						});
						if (change) {
							propsData.setItemName(propsData.types);
						}
					}
				}
				// PBP
				else if (
					user.uid === 'CXNDhrE6FfNRdXNm6nUQlmck29f2' ||
					user.uid === 'xHaYXalwv8X8RpBLZju0PaQlWW23'
				) {
					setSizeType('ta');
					setNoConditionNotes(true);
					setStore('piece');
					if (propsData.types) {
						// console.log(propsData.types);
						Taxonomy(propsData.types);
					}

					if (
						propsData.vendor.length === 0 ||
						propsData.vendor === ''
					) {
						let arr = Array(propsData.s3Images.length).fill('');
						propsData.setVendor(arr);
					}
				}
				//Evergreen
				else if (user.uid === 'cKIubugLu0R3lv64AaQX37Qg0xz1') {
					setSizeType('ta');
					setNoConditionNotes(true);
					setStore('piece'); //Evergeen not updated with new columns
					if (propsData.types && propsData.types.length > 0) {
						let change = true;
						propsData.itemName.forEach((element) => {
							if (element !== '') {
								change = false;
							}
						});
						if (change) {
							let singleProductModelFilter = false;
							propsData.setItemName(propsData.types);
							updateColumnValue(
								singleProductModelFilter,
								'preLovedKids',
								'itemName',
								propsData.types,
								propsData.objectID
							);
						}
					}
				}
				// BonTot
				else if (
					user.uid === 'HDlknVR9NpWxhs9WWlfvnwAQq4b2' ||
					user.uid === 'ZFoq2NIsDmZgVTW4g88O1B8JA822'
				) {
					setSizeType('ta');
					setStore('bontot');
				}
				// THG
				else if (user.uid === 'AgVEaDRk3tVtnfqAKiMYWLlRICG2') {
					setSizeType('ta');
					setStore('tiny');
					setNoConditionNotes(true);
				} else if (prop.template === 'preLovedKids') {
					setSizeType('ta');
				}
				// CherryPick
				else if (
					user.uid === '9BjTXuFQ6TMYrn5cy6dclMyYqDz1' ||
					user.uid === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1' ||
					user.uid === '1eMplQPlHHhX9lnwX8NTcKghui22' ||
					user.uid === 'FYLY9osdEkU7QtLsTgtmitcmqAD2' ||
					user.uid === '80saF2wZqdRUhagajkE83wam8Js2'
				) {
					setStore('vintage');
				} else if (prop.template === 'vintage') {
					setStore('loose');
				}
			}
		});
	}, [columnUpdated]);

	const updateTaxonomy = (type, index) => {
		//RCL
		if (uid === 'Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1') {
			let typeArray = type;
			let changed = false;

			type.map((key, i) => {
				if (
					type[i].toLowerCase().includes('upper') &&
					!propsData.types[i].toLowerCase().includes('top')
				) {
					changed = true;
					typeArray[i] = 'Top';
				} else if (
					type[i].toLowerCase().includes('pants') &&
					!(
						propsData.types[i].toLowerCase().includes('bottom') ||
						propsData.types[i].toLowerCase().includes('shorts')
					)
				) {
					typeArray[i] = 'Bottom';
					changed = true;
				} else if (
					propsData.types[i].toLowerCase().includes('shorts')
				) {
					typeArray[i] = 'Shorts';
					changed = true;
				} else if (
					type[i].toLowerCase().includes('jackets') &&
					!propsData.types[i].toLowerCase().includes('coat/jacket')
				) {
					typeArray[i] = 'Coat/Jacket';
					changed = true;
				} else if (
					(type[i].toLowerCase().includes('baby') ||
						type[i].toLowerCase().includes('dungarees')) &&
					!propsData.types[i]
						.toLowerCase()
						.includes('romper/overalls')
				) {
					typeArray[i] = 'Romper/Overalls';
					changed = true;
				} else if (
					type[i].toLowerCase().includes('footwear') &&
					!propsData.types[i].toLowerCase().includes('shoes')
				) {
					typeArray[i] = 'Shoes';
					changed = true;
				} else if (
					type[i].toLowerCase().includes('skirt') &&
					!propsData.types[i].toLowerCase().includes('skirt')
				) {
					typeArray[i] = 'Skirt';
					changed = true;
				}
			});
			if (changed) {
				propsData.setType(typeArray);
				propsData.setTypesTaxoUpdate(typeArray);
			}
		}
		// PBP
		else if (
			uid === 'CXNDhrE6FfNRdXNm6nUQlmck29f2' ||
			uid === 'xHaYXalwv8X8RpBLZju0PaQlWW23'
		) {
			// console.log("updateTxaonomy type " + type)
			let typeArray = type;
			// console.log(typeArray[index][0])
			if (tees.includes(type[index])) {
				typeArray[index] = 'Tees';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (tops.includes(type[index])) {
				typeArray[index] = 'Tops';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (sweaters.includes(type[index])) {
				typeArray[index] = 'Sweaters';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (skirts.includes(type[index])) {
				typeArray[index] = 'Skirts';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (shorts.includes(type[index])) {
				typeArray[index] = 'Shorts';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (pants.includes(type[index])) {
				typeArray[index] = 'Pants';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (dresses.includes(type[index])) {
				typeArray[index] = 'Dresses';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (lightWeightJacket.includes(type[index])) {
				typeArray[index] = 'Light weight jackets';
				propsData.setTypesTaxoUpdate(typeArray);
			} else if (type[index] === 'sweatshirts') {
				typeArray[index] = 'Sweatshirts';
				propsData.setTypesTaxoUpdate(typeArray);
			}
		}
	};

	const Taxonomy = (type) => {
		// RCL
		if (uid === 'Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1') {
			// console.log(type.length);
			if (type.length !== undefined) {
				if (type.length > 1) {
					updateTaxonomy(type);
				} else {
					updateTaxonomy(type, 0);
				}
			}
		}
		// PBP
		else if (
			uid === 'CXNDhrE6FfNRdXNm6nUQlmck29f2' ||
			uid === 'xHaYXalwv8X8RpBLZju0PaQlWW23'
		) {
			if (type.length > 1) {
				type.map((key, index) => {
					updateTaxonomy(type, index);
				});
			} else {
				updateTaxonomy(type, 0);
			}
		}
	};

	const updateCost = (index, price) => {
		if (userID === 'Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1') {
			let cost = price * 0.35;
			cost = Math.round((cost + Number.EPSILON) * 100) / 100;
			propsData.updateVal(
				'cost',
				cost,
				index,
				propsData.costPerItem,
				propsData.setCostPerItem
			);
		}
	};

	const updatePrice = (index, cost) => {
		//PBP
		if (
			userID === 'CXNDhrE6FfNRdXNm6nUQlmck29f2' ||
			userID === 'xHaYXalwv8X8RpBLZju0PaQlWW23'
		) {
			const price = cost * 2;
			propsData.updateVal(
				'price',
				price,
				index,
				propsData.price,
				propsData.setPrice
			);
		}
	};

	const history = useHistory();

	useEffect(() => {
		// tokki taxo update
		if (
			uid === 'zjkiN1cq9Eb4oHsrA923OoJ9Sxz1' ||
			uid === 'IMe1VYnWOQgQuER8JQHSI0iKNre2'
		) {
			let taxoExists = false;
			let i = 0;

			if (prop.typesTaxoUpdate) {
				for (i = 0; i < prop.typesTaxoUpdate.length; i++) {
					if (prop.typesTaxoUpdate[i] !== '') {
						taxoExists = true;
						i = prop.typesTaxoUpdate.length;
					}
				}
				if (!taxoExists) {
					prop.setTypesTaxoUpdate(prop.types);
				}
			} else {
				prop.setTypesTaxoUpdate(prop.types);
			}
		}
		//THG
		else if (uid === 'AgVEaDRk3tVtnfqAKiMYWLlRICG2') {
			let taxoExists = false;
			let i = 0;

			if (propsData.typesTaxoUpdate) {
				for (i = 0; i < propsData.typesTaxoUpdate.length; i++) {
					if (propsData.typesTaxoUpdate[i] !== '') {
						taxoExists = true;
						i = propsData.typesTaxoUpdate.length;
					}
				}
				if (!taxoExists) {
					propsData.setTypesTaxoUpdate(propsData.types);
				}
			} else {
				propsData.setTypesTaxoUpdate(propsData.types);
			}
		}
	}, [prop.types, headers]);

	const DeleteItemsButton = () => {
		return (
			<PrimaryIconButton
				icon={<DeleteOutlineTwoToneIcon />}
				color='secondary'
				disabled={isActionItemsDisabled}
				onClick={async () => {
					const response = await deleteItems(
						checkedItemsIndicesArray,
						props.propsData,
						setCheckedItemsIndicesArray,
						setSelectedProducts
					);
					// Sets the useEffect Dependency on shopifyClothing to true if on Project view else redirected to project view
					if (setIsItemsDeleted) {
						setIsItemsDeleted(!isItemsDeleted);
					} else {
						window.location.reload();
					}
				}}
			/>
		);
	};

	
	const BulkThemeUpdateInput = () => {
		const [selectedTheme, setSelectedTheme] = useState('');
		const [isAddClicked, setIsAddClicked] = useState(false); // Utilized for MUI Autocomplete value WorkAround
		const onInputChange = useCallback(
			(e, newValue) => {
				setSelectedTheme(newValue);
			},
			[isAddClicked]
		);

		const updateThemes = async () => {
			const selectedProductsBody = {
				selectedProducts: selectedProducts,
				newTheme: selectedTheme,
				userID: userID,
				template: template,
			};
			// https://apie.snapwrite.ca/storeData/add-theme
			const updateSingleProductThemeAndRemoveFromRegularModel =
				await axios
					.post(
						'https://apie.snapwrite.ca/storeData/add-theme',
						selectedProductsBody
					)
					.then((res) => {
						if (res.data.isProjectEmpty) {
							history.push(
								`/themes-view/${res.data.newThemeData[0]._id}`
							);
						} else {
							window.location.reload();
						}
					})
					.catch((err) => console.log(err))
					.finally(() => {
						setSelectedProducts([]);
						setCheckedItemsIndicesArray([]);
					});
		};

		return (
			<>
				<SWDropdown
					label='Themes'
					id='bulk-theme'
					list={themeDropdownList}
					onChange={(e, value) => {
						setSelectedTheme(value);
					}}
					disabled={isActionItemsDisabled}
					onInputChange={onInputChange}
					buttonTitle={'Add'}
					buttonOnClick={updateThemes}
				/>
			</>
		);
	};


	const shareOfferWithReseller = async () => {
		const demoSellerID = 'Rs6zT7PWzAcuaJ59C3WFSZY6e0D2'; // For Demo purposes

		const requestBody = {
			sellerId: demoSellerID,
			projectId: objectID,
			skus : prop.sku,
		};
		const serverResponse = await axios
			.put(
				' https://apie.snapwrite.ca/storeData/vintage-apparel-shared-create',
				requestBody
			)
			.then((response) => {
				setNotificationMessage(response.data.message);
				setSnackbarOpen(true);
				setSharedWith(response.data.data.sharedWith);
				setOpenOfferModal(false)
			});
	};

	const shareOfferWithVendor = async () => {
		if (accountType === 'reseller') {
			receiverID = projectData._id
			console.log(receiverID)
			fetchEmail(projectData.userID)
		} else if (accountType === 'seller') {
			// receiverID = projectData.sharedWith
			receiverID = projectData._id
			console.log(receiverID)
			fetchEmail(projectData.sharedWith)
		}
		console.log(projectData)
		console.log(projectData.userID)
		console.log(projectData.sharedWith)
		const demoVendorID = 'club5szcbMa6sJZ8BkmtOklpDh52';
		const requestBody = {
			userName: demoVendorID,
			projectId: objectID,
			concerns: changeRequestText,
			selectedProducts: selectedProducts,
		};
		if (selectedProducts.length === 0) {
			for (let i=0; i < projectData.images.length; i++) {
				let unSelectedProduct = {
					index: i,
					objectID: objectID,
					imageUrl: projectData.images[i]
				}
				selectedProducts.push(unSelectedProduct)
			}
		}

		for(let i=0; i < selectedProducts.length; i++){
			await updateColumnValue(
				selectedProducts[i].imageUrl,
				template, // template
				'concerns', // state
				changeRequestText, // event
				objectID, // id
				selectedProducts[i].index, // index
				'concerns' // key
			);
		}
		
		try {
			const serverResponse = await axios
			.put(
				'https://apie.snapwrite.ca/storeData/vintage-apparel-updateVal',
				requestBody
			)
			.then((response) => {
				setConcerns(changeRequestText)
				setNotificationMessage(response.data.message);
				setSnackbarOpen(true);
				// setSharedWith(response.data.data.sharedWith);
				setChangeRequestText('');
				setOpenRequestChangesModal(false);
				setOpenChangeModal(false);
				// changeProjectStatus('pending')
			});
		} catch (error) {
			console.log(error)
		}
		handleSendEmail(receiverEmail, receiverID)

	};

	const OfferButtonForReseller = () => {
		
		return (
			organization?.accountType === 'seller' &&
			(sharedWith &&
			sharedWith.length >
				0 ? // <Chip label="Offer Pending Approval" color="warning" />
			null : (
				<>
					<PrimaryButton
						title={'Send Offer'}
						onClick={() => setOpenOfferModal(true)}
					/>
				</>
			))
		);
	};

	const ActionToolbar = () => {
		return (
			<div
				style={{
					display: 'flex',
					padding: 'var(--none, 0px)',
					alignItems: 'flex-start',
					gap: ' var(--2, 16px)',
					paddingBottom: '30px',
					paddingTop: '16px',
				}}
			>
				{prop.template !== 'themes' && <BulkThemeUpdateInput />}
				<ExportButton
					userID={userID}
					generateCSVData={generateCSVData}
					downloadCSV={downloadCSV}
					template={propsData.template}
					projectId={id || objectID}
					exportButtonPreference={exportButtonPreference}
				/>
				<OfferButtonForReseller />
				<DeleteItemsButton />
				{userID === "19BvrgznH1Se16RnM8aTvvW8LDC3" || userID === "NpGB6zItW1OcpizZJbD88VsfKlf2" ? 
					<PrimaryButton
						title={'Ebay CSV'}
						onClick={async () => {
							try {
								const csvData = await createEbayCSV(
									template,
									userID,
									objectID
								);
								
								if (csvData) {
									setEbayCSVData(csvData)
									// const csvString = csvData
									// 	.map((row) => row.join(','))
									// 	.join('\n');
										
									//  downloadEbayCSV(csvString, `ebay_import_data`);
									const generateButton = document.getElementById("ebayCsvLinkButton");

									generateButton.click();
								} else {
									console.log('Failed to generate CSV data');
								}
							} catch (error) {
								console.error('Error:', error);
							}
						}}
					/> : null	
				}								
			</div>
		);
	};
	
	return (
		<>
		{headers && (
				<>
					<div className='flex items-center gap-3'>
						<Heading1
							title={
								template === 'preLovedKids'
									? 'Pre-loved (Kids)'
									: template === 'pre-loved-regular'
									? 'Pre-loved (Regular)'
									: template === 'vintage'
									? 'Vintage'
									: template === 'new-clothes'
									? 'New'
									: template === 'mm-coats'
									? 'Coats and Jackets'
									: template === 'mm-sports'
									? 'Sports Wear'
									: template === 'themes'
									? `Theme: ${prop.theme}`
									: null
							}
						/>
						{sharedWith && (
							<Chip
								label={
									offerAcceptStatus === 'pending'
										? 'Offer Pending Approval'
										: offerAcceptStatus === 'accepted'
										? 'Offer Accepted'
										: offerAcceptStatus === 'rejected'
										? 'Offer Rejected'
										: null
								}
								variant='filled'
								color={
									offerAcceptStatus === 'pending'
										? 'warning'
										: offerAcceptStatus === 'accepted'
										? 'success'
										: offerAcceptStatus === 'rejected'
										? 'error'
										: 'default'
								}
							/>
						)}
					</div>

					<TableView
						projectData={projectData}
						setProjectData={setProjectData}
						brandAutocompleteOptions={prop.fetchedBrands}
						taxonomyAutocompleteOptions={prop.preferredItemTypes}
						template={template}
						ActionToolbar={ActionToolbar}
						checkedItemsIndicesArray={checkedItemsIndicesArray}
						selectedProducts={selectedProducts}
						setIsActionItemsDisabled={setIsActionItemsDisabled}
						setCheckedItemsIndicesArray={
							setCheckedItemsIndicesArray
						}
						propData={props.propsData}
						setSelectedProducts={setSelectedProducts}
						sharedWith={sharedWith}
					/>

					{sharedWith &&
						accountType === 'reseller' &&
						offerAcceptStatus === 'pending' && (
							<div className='fixed bottom-14  left-1/2 right-1/2 flex w-96 gap-4' style={{zIndex: '6'}}>
								<Button
									variant='contained'
									color='success'
									onClick={() =>
										changeProjectStatus('accepted')
									}
								>
									Accept Offer
								</Button>
								<Button
									variant='contained'
									color='warning'
									onClick={() =>
										setOpenRequestChangesModal(true)
									}
								>
									Request Changes
								</Button>
								<Button
									variant='contained'
									color='error'
									onClick={() =>
										changeProjectStatus('rejected')
									}
								>
									Reject Offer
								</Button>
							</div>
						)}
					
						{sharedWith &&
						accountType === 'seller' &&
						offerAcceptStatus === 'pending' && (
							<div className='fixed bottom-14  left-1/2 right-1/2 flex w-96 gap-4' style={{zIndex: '6'}}>
								<Button
									variant='contained'
									color='warning'
									onClick={() =>
										setOpenChangeModal(true)
									}
								>
									Changes Made
								</Button>
							</div>
						)}
					<>
						{offerAcceptStatus === 'pending' &&
							!(
								accountType === 'reseller' &&
								sharedWith.length > 0
							) && (
								<FloatingButton
									title={'Add Row'}
									onClick={() => {
										fileInputRef.current.click();
									}}
								/>
							)}

						<input
							ref={fileInputRef}
							multiple
							type='file'
							name='myFile'
							id='myFile'
							className='drop-zone_input'
							accept='image/*'
							onChange={(e) => uploadImage(e, 'upload')}
							style={{ display: 'none' }}
						/>

						{/* Offer Modal  */}
						<SWModal
							title={'Send Offer'}
							open={openOfferModal}
							handleClose={() => setOpenOfferModal(false)}
							acceptButtonTitle={'Agree & Send'}
							acceptButtonOnClickFunction={shareOfferWithReseller}
							rejectButtonTitle={'Cancel'}
						>
							<p className='py-3 w-72'>
								Your offer is not a definite agreement but a
								proposal for the reseller to consider. You will
								be notified when there are any updates to your
								offer or if it has been accepted.
							</p>
						</SWModal>
						<SWModal
							title={selectedProducts.length === 0 ? 'No Products Selected, Requesting Changes for the Entire Project':'Request Changes for Selected Products'}
							open={openRequestChangesModal}
							handleClose={() => setOpenRequestChangesModal(false)}
							acceptButtonTitle={'Submit'}
							acceptButtonOnClickFunction={shareOfferWithVendor}
							rejectButtonTitle={'Cancel'}
						>
							<textarea 
								className="w-full h-40 p-2 border rounded" 
								placeholder="Describe the changes needed"
								value={changeRequestText}
								// onChange={handleTextareaChange}
								onChange={(e) => setChangeRequestText(e.target.value)}
							></textarea>
						</SWModal>
						<SWModal
							title={selectedProducts.length === 0 ? 'No Products Selected, Making Changes for the Entire Project':'Make Changes'}
							open={openChangeModal}
							handleClose={() => setOpenChangeModal(false)}
							acceptButtonTitle={'Submit'}
							acceptButtonOnClickFunction={shareOfferWithVendor}
							rejectButtonTitle={'Cancel'}
						>
							<textarea 
								className="w-full h-40 p-2 border rounded" 
								placeholder="Describe the changes made"
								value={changeRequestText}
								// onChange={handleTextareaChange}
								onChange={(e) => setChangeRequestText(e.target.value)}
							></textarea>
						</SWModal>
					</>
					</>
			)}

			<CSVLink
							id="ebayCsvLinkButton"
							hidden
							className="btn"
							data={ebayCSVData}
							// onClick={async ()=> await generateCSVData()}
				
							filename={`SnapWriteAI.csv`}
						  />
		</>)

};


export default CustomizedTable;
