const long = [
    "Extended",
    "Lengthy",
    "long"
  ];
  
  const middle = [
    "Intermediate",
    "Midsize",
    "Average",
    "Midway",
    "Medium",
    "In-between",
    "Moderate",
    "middle"
  ];
  
  const short = [
    "Brief",
    "Petite",
    "Small",
    "Mini",
    "Compact",
    "Miniature",
    "short"
  ];

  const synonymsArray = [long, middle, short]

export const getLengthSynonyms = (length) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => length.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 