import axios from "axios"

const updateSKUstart = async(val, userID) => {
    const body = {
      "userID": userID,
      "name": "sku",
      "val": val
      }
    await axios.put(`https://apie.snapwrite.ca/storeData/user-preferences-updateVal`, body)
  }

  export default updateSKUstart