  const laceSynonyms = [
    "Scallop trim",
    "scallop-trim",
    "lace-trimmed",
    "lace-detail",
    "shapelace",
    "lacework",
    "lace"
  ];
  
  const logoSynonyms = [
    // "Embossed",
    // "graphic",
    "branded",
    "logo"
  ];
  
  const melangeSynonyms = [
    // "Cotton crew neck",
    // "cotton jersey",
    "melange",
    "cotton"
  ];
  
  const patternedSynonyms = [
    "Print",
    "printed",
    "pattern",
    // "embroidery",
    // "checkered",
    // "anamalistic",
    // "checked",
    // "camouflage",
    // "floral",
    "geometric",
    // "polka dot",
    // "stripe",
    "abstract",
    "patterned"
  ];
  
  const pictureSynonyms = [
    "Photo",
    "printed",
    "custom",
    "portrait",
    "picture",
    "graphic"
  ];
  
  const plainSynonyms = [
    "Classic",
    "cotton",
    "cotton-blend",
    "relaxed",
    "everywhere",
    "wholesale",
    "classic-fit",
    "blank",
    "gildan",
    "basic",
    "canvas",
    "casual",
    "plain"
  ];
  
  const shinySynonyms = [
    "Sparkly",
    "satin",
    "metallic",
    "glitter",
    "party",
    // "gold",
    // "silver",
    // "bronze",
    "silk",
    "pearly",
    // "sequin",
    "glittery",
    "sparkle",
    "jewel",
    "shiny"
  ];
  
  const textSynonyms = [
    // "Graphic",
    // "embossed",
    "‘<text>’",
    "“<text>”",
    "letters",
    "wavy letters",
    // "letter embossed",
    "letter",
    "writing",
    "text"
  ];
  

const synonymsArray = [laceSynonyms, logoSynonyms, melangeSynonyms, patternedSynonyms, pictureSynonyms, plainSynonyms, shinySynonyms, textSynonyms]

export const getDesignSynonyms = (design) => {
  let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => design.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList]
        }
    }
    return toReturn;
}