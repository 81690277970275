const polyester = [
    "PET",
    "Dacron",
    "Terylene",
    "Polyethylene",
    "Synthetic",
    "Polyblend",
    "Poly",
    "Plastic fabric",
    "Microfiber",
    "polyester"
  ];
  
  const rayon = [
    "Viscose",
    "Artificial",
    "Cellulose",
    "Regenerated",
    "Modal",
    "Lyocell",
    "Synthetic",
    "Eco",
    "rayon"
  ];
  
  const acrylic = [
    "Synthetic",
    "Acryl",
    "acrylic"
  ];
  
  const cotton = [
    "Fiber",
    "Textile",
    "cotton"
  ];
  
  const pima = [
    "Nylon",
    "Polyamide",
    "pima"
  ];
  
  const duck_down = [
    "Waterfowl",
    "feathers",
    "plumage",
    "duck down"
  ];
  
  const feathers = [
    "Plumage",
    "Quill",
    "plume",
    "down",
    "feather"
  ];
  
  const silk = [
    "Satin",
    "Sateen",
    "Silken",
    "Glossy",
    "Smooth",
    "Lustrous",
    "Shiny",
    "Soft",
    "silks"
  ];
  
  const spandex = [
    "Elastane",
    "Lycra",
    "Stretch",
    "Elastic",
    "elastomer",
    "spandex"
  ];
  
  const viscose = [
    "Synthetic",
    "Cellulose",
    "viscose"
  ];
  
  const wool = [
    "Fleece",
    "Sheep",
    "wool"
  ];
  
  const cashmere = [
    "Fine",
    "Luxury",
    "Goat",
    "cashmere"
  ];

  const synonymsArray = [polyester, rayon, acrylic, cotton, pima, duck_down, 
                        feathers, silk, spandex, viscose, wool, cashmere]

export const getMaterialSynonyms = (material) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => material.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 