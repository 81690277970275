import React, {useEffect, useState} from 'react'
import SideMenu from '../components/sideMenu'
import { Header } from '../components/navigation/header'
import { getAuth } from '@firebase/auth';
import axios from 'axios'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Grid, Card, CardContent, Typography } from '@mui/material';
import MainSideMenuHeaderContainer from '../components/containers/MainSideMenuHeaderContainer';
import { Heading1 } from '../components/branded-elements/Elements';


const FormPage = () => {

    const auth = getAuth();
    const [userID, setUserID] = useState()
    const [forms, setForms] = useState([])
    const [userMap, setUserMap] = useState()
    // const [totalPay, setTotalPay] = useState(0)
    // const [totalCredit, setTotalCredit] = useState(0)
    // const [numDropoffs, setNumDropoffs] = useState(0)
    const history = useHistory()
    useEffect(() => {
        auth.onAuthStateChanged(user => {
                const token = localStorage.getItem("token");      
                if (!token || !user) {
                    history.push("/");
                }else {        
            setUserID(user.uid);            
                }
        })   
      },[])

      useEffect(() => {
        if (userID && userID !== "") {
        axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`)
        .then(res => {
            let formData = [];
            let userMap = {}
            // let totalPayout = 0
            // let totalStoreCredit = 0
            // let dropoffs = 0
            console.log(res.data)

            fetch("https://apie.snapwrite.ca/storeData/users")
				.then((response) => response.json())
				.then((userJson) => {
                    
                    for (let user of userJson) {
                        userMap[user.uid] = {name: user.displayName, email: user.email}
                    }
                    setUserMap(userMap)
                })

            Promise.all(res.data.data.sentForms.map(async (form, index) => {

							if (typeof form !== 'string') return;
							if (form.substr(0, 13) === "/vendor-view/") { // form = '/vendor-view/648fe10c8d0599a3180282da?t=vintage&v=Coels-Capsule'
								const urlParams = new URLSearchParams(form.split("?")[1]);
								const template = urlParams.get('t');
								const filterVendor = urlParams.get('v');
								if (typeof template !== 'string' || typeof filterVendor !== 'string') return;
								let link = `https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`;
								if (template === "vintage") {
									link = `https://apie.snapwrite.ca/storeData/vintage-apparel`;
								} else if (template === "new-clothes") {
									link = `https://apie.snapwrite.ca/storeData/new-apparel`;
								} else if (template === "pre-loved-regular") {
									link = `https://apie.snapwrite.ca/storeData/regular-preLoved-apparel`;
								}
								const data = (await (await fetch(link+form.substr(12))).json()).data
								const {userID, dateCreated} = data;
								let storeCredit = 0;
								let cashOffer = 0;
								let itemName = "";
								data.vendor.forEach((v, i)=> { // ['Coels Capsule', 'Adidas', 'Nike']
									if (v.split(" ").join("-") === filterVendor) {
										storeCredit += Number(data.price[i]);
										cashOffer += Number(data.cost[i]);
										itemName = itemName.concat("1");
									}
								})
								await formData.push({_id: form, itemName, storeCredit, cashOffer, dateCreated, index: index, userID})
								return;
							}

							try {
								const formInfo = await axios.get(`https://apie.snapwrite.ca/storeData/apparel-form-categorization/${form}`);
								await formData.push({...formInfo.data.data, index: index});
								// totalPayout += formInfo.data.data.cashOffer
								// totalStoreCredit += formInfo.data.data.storeCredit
								// dropoffs += 1
							} catch (error) {
								console.log(error)
							}

            }))
            .then(() => {
                
                setForms(formData);
                // setTotalPay(totalPayout)
                // setTotalCredit(totalStoreCredit)
                // setNumDropoffs(dropoffs)
            })
            .catch((error) => {
                console.error(error);
            });

        })
    }
      }, [userID])
      
    
  return (
    <MainSideMenuHeaderContainer>

            <br />
            {/**
            <h4 style = {{margin: 0, padding: 0, marginLeft: "10px", paddingBottom: "7px"}}>Stats</h4>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="h6">Total Cash Payout</Typography>
                        <Typography color = "green" variant="h4">{"$" + totalPay}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">Total Store Credit</Typography>
                        <Typography color = "green" variant="h4">{"$" + totalCredit}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">Number of Dropoffs</Typography>
                        <Typography variant="h4">{numDropoffs}</Typography>
                    </Grid>
                    </Grid>
                </CardContent>
                </Card>
                <br />
            */}
            <Heading1 title={"Forms"} />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow style = {{backgroundColor: "white"}}>
                        <TableCell>From</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Item Count</TableCell>
                        <TableCell>Store Credit Offer</TableCell>
                        <TableCell>Cash Offer</TableCell>
                        <TableCell>Date Created</TableCell>

                    </TableRow>
                    </TableHead>
                    <TableBody>
                    
                        {forms.sort((a, b) => b.index - a.index).map((form) => {
														form.cashOffer = Number(form.cashOffer)
														form.storeCredit = Number(form.storeCredit)
                            const cashOffer = form.cashOffer === "" || !form.cashOffer ? "$0" : "$" + form.cashOffer
                            const storeCreditOffer = form.storeCredit === "" || !form.storeCredit ? "$0" : "$" + form.storeCredit
                            let link = form._id;
														if (link.substr(0, 13) !== '/vendor-view/') {
															link = `../form-seller-view/${form._id}`	
														}
                            return (
                                
                                userMap &&  userMap[form.userID] && (
                                <>
                                <TableRow style = {{cursor: "pointer"}} onClick={()=> {
																	history.push(link)	
																}}>
                                    <TableCell>{userMap[form.userID].name}</TableCell>
                                    <TableCell>{userMap[form.userID].email}</TableCell>
                                    <TableCell>{form.itemName.length}</TableCell>
                                    <TableCell>{storeCreditOffer}</TableCell>
                                    <TableCell>{cashOffer}</TableCell>
                                    <TableCell>{new Date(form.dateCreated).toLocaleDateString('en-CA')}</TableCell>
                                </TableRow>   
                                </>)
                            )
                        })}
                        
                    </TableBody>
                </Table>
            </TableContainer>
            <br></br>
            <div className='h-[60vh] flex justify-center items-center'>
            {forms && forms.length === 0 && <h2>You have not been sent any forms yet</h2>}
            </div>
            

    </MainSideMenuHeaderContainer>
  )
}

export default FormPage