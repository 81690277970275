import axios from 'axios';
import generateSKU from '../products/bulk/shopify/utils/skuCreator/skuGenerate';
import updateSKUstart from '../products/bulk/shopify/utils/skuCreator/skuCountUpdate';

const BASE_URL = 'https://apie.snapwrite.ca/storeData';
const UPDATE_VAL_URL = `${BASE_URL}/user-preferences-updateVal`;
const APPAREL_FORM_URL = `${BASE_URL}/apparel-form`;

export const createNewFormAndGetLink = async (uid, userName) => {
	let response 	
	try {
		response = await axios.post(APPAREL_FORM_URL, {
			parentObjectID: 'temp',
			userID: uid,
			userName: userName,
		});
		return `/NewView/${response.data.data._id}`;
	} catch (err) {
		throw new Error(err);
	}
};


