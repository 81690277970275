import React, { useState } from "react";
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react'; 

const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
});

export function BlogOutline(props) {
    /* **************************Tags area************************** */
    const userID = props.user;
    const userName = props.userName;
    const blogOutline = props.blogOutline;
    const setBlogOutline = props.setBlogOutline;    
    const blogTitle = props.blogTitle;
    const setBlogTitle = props.setBlogTitle;
    const blogIntro = props.blogIntro;
    const blogParaOne = props.blogParaOne;
    const blogParaTwo = props.blogParaTwo
    const blogParaThree= props.blogParaThree
    const blogParaFour = props.blogParaFour
    const blogParaFive = props.blogParaFive
    const blogConclusion = props.blogConclusion
    

    /* ************************************************************* */
    
    const reset = () => {
        setBlogOutline("");
    };
    let blogOutlines = blogOutline.split('•')        
    const blogDraft = blogOutline !==undefined && blogTitle !==undefined ? 
                        `<h2 align="center">${blogTitle}</h2>                        
                        <p>${blogIntro}</p>
                        <b>${blogOutlines[1]?blogOutlines[1]:""}</b>
                        <p>${blogParaOne}</p>
                        <b>${blogOutlines[2]?blogOutlines[2]:""}</b>
                        <p>${blogParaTwo}</p>
                        <b>${blogOutlines[3]?blogOutlines[3]:""}</b>
                        <p>${blogParaThree}</p>
                        <b>${blogOutlines[4]?blogOutlines[4]:""}</b>
                        <p>${blogParaFour}</p>
                        <b>${blogOutlines[5]?blogOutlines[5]:""}</b>
                        <p>${blogParaFive}</p>                        
                        <p>${blogConclusion}</p>` 
                        : "";

    async function generateBlogOutline(tags, setProdDescription) {
        try {
            setProdDescription("Loading...");
            const res = await backendClient.post("/spec-description", {
                tags: tags,
                user: props.user,
            });
            setProdDescription(res.data);
        } catch (e) {
            setProdDescription("Some error occured");
            console.error(e);
        }
    }

    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function (event) {
        if (!event.target.matches(".dropbtn")) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains("show")) {
                    openDropdown.classList.remove("show");
                }
            }
        }
    };

    const updateBlog = (blog) =>{
        fetch(`https://apie.snapwrite.ca/storeData/${props.imageOrIdea}-blog-autosave`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: '6286a49463f9080deb01410c',
        completeBlog: blog
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });
    }
    
    const handleEditorChange = (e) => {
        props.setCompleteBlog(e.target.getContent())
        updateBlog(e.target.getContent())
      }


    return (
                
                    <div className="tools">
                        <div className="blog-outline">
                            <div className="card-spec">
                                <div className="card-header"> Blog Outline </div>
                                <div className="card-body appTag ">
                                    {/* **************************Tags area************************** */}
                                    <div className="appTag spec-body">
                                        <div className="tags-input-spec">  
                                            <Editor
                                            apiKey="82j56vlrq0vcq5dwuxndbyxjaedf8bi4pd7murrybxtiy666"
                                            initialValue={props.completeBlog !== "" ? props.completeBlog : blogDraft }
                                            init={{
                                            
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image', 
                                                'charmap print preview anchor help',
                                                'searchreplace visualblocks code',
                                                'insertdatetime media table paste wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist outdent indent | help'
                                            }}
                                            onChange={(event) => handleEditorChange(event)}
                                        />
                                            
                                        </div>
                                    </div>                                                            
                                </div>
                            </div>
                            {/* ************************************************************* */}
                        </div>                    
                    </div>               
    );
}
export default BlogOutline;
