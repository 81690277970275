import PropTypes from 'prop-types';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import updateColumnValue from '../../utils/updateAttribute';
import { useState } from 'react';
import { SWTableImage } from '../../../../../components/branded-elements/Elements';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const TableImageData = ({
	name = '',
	index,
	images,
	attr,
	mm,
	template,
	id,
	setImages,
	singleProductModelFilter,
}) => {
	const inputName = `${name || ''}heroImage${index}`;

	const delBtnStyle = {
		display: 'none',
	};
	const delBtnHovrStyle = {
		display: 'block',
		position: 'absolute',
		top: '4px',
		right: '0',
		backgroundColor: 'none',
	};

	const [activeDelBtnStyle, setActiveDelBtnStyle] = useState(delBtnStyle);

	const uploadImage = async (e) => {
		try {
			const response = await fetch(
				'https://apie.snapwrite.ca/storeData/s3Url'
			);
			if (!response.ok) throw new Error('Failed to fetch S3 URL');
			const { url } = await response.json();

			const uploadResponse = await fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'image/jpeg',
				},
				body: e.target.files[0],
			});

			if (!uploadResponse.ok) throw new Error('Failed to upload image');

			const updatedImages = [...images];
			updatedImages[index] = url.split('?')[0];
			setImages(updatedImages);


			const attrValue = mm ? attr : 'images';

			updateColumnValue(
				singleProductModelFilter,
				template,
				attrValue,
				updatedImages[index],
				id,
				index,
				attrValue
			);
		} catch (err) {
			console.error(err);
		}
	};

	if (!images) return <p>error</p>;
	const currentImageUrl =
		attr === 'sideImages' ? images[index][0] : images[index];
	return (
		<>
			{currentImageUrl === '' ? (
				<td className={name}>
					<div style={{ width: 120 }}>
						<label htmlFor={inputName} className='pc'>
							<div className='upload-image-btn'>
								<FileUploadIcon color='black' />
								<span style={{ textAlign: 'center' }}>
									{name ? `${name} Image` : 'Main Image'}
								</span>
							</div>
						</label>
						<input
							type='file'
							name={inputName}
							id={inputName}
							className='upload-image-btn drop-zone__input'
							accept='image/*'
							onChange={uploadImage}
						/>
					</div>
				</td>
			) : (
				<div className='relative'
				onMouseEnter={() => {
					setActiveDelBtnStyle(delBtnHovrStyle);
				}}
				onMouseLeave={() => {
					setActiveDelBtnStyle(delBtnStyle);
				}}
				
				>
					<SWTableImage
						src={images?.[index]}
						alt='product'
						height={'15rem'}
						width={'10rem'}
						draggable={false}
						className='hover:h-72 transition-all duration-300 ease-in-out'
					/>
					<IconButton
						disableRipple={true}
						sx={activeDelBtnStyle}
						onClick={() => {
							const updatedImages = [...images]
							updatedImages[index] = ''
							setImages(updatedImages)
							const templateValue = mm ? template : 'preLovedKids'
							const attrValue = mm ? attr : 'images'
							updateColumnValue(
								singleProductModelFilter,
								templateValue,
								attrValue,
								updatedImages[index],
								id,
								index,
								attrValue
							)
						}}
					>
						<Close />
					</IconButton>
				</div>
			)}
		</>
	);
};

TableImageData.propTypes = {
	singleProductModelFilter: PropTypes.object.isRequired,
	name: PropTypes.string,
	index: PropTypes.number.isRequired,
	images: PropTypes.array.isRequired,
	attr: PropTypes.string.isRequired,
	mm: PropTypes.bool,
	template: PropTypes.string,
	id: PropTypes.string.isRequired,
	setImages: PropTypes.func.isRequired,
};

export default TableImageData;

/*

	Comented the button to avoid interference with single product labeling model workflow as it relies on image url to be present in the database  
					 <button
							style={activeDelBtnStyle}
							onClick={(e) => {
								const {
									images,
									index,
									template,
									primaryImage,
									attr,
									id,
								} = props;
								const updatedImgArr = images;
								updatedImgArr[props.index] = '';
								props.setS3Images(updatedImgArr);

								setActiveDelBtnStyle(delBtnStyle);
								updateColumnValue(
									primaryImage,
									template,
									'images',
									'',
									id,
									index,
									attr
								);
							}}
							onMouseOver={() => {
								setActiveDelBtnStyle(delBtnHovrStyle);
							}}
							onMouseOut={() => {
								setActiveDelBtnStyle(delBtnStyle);
							}}
						>
							X
						</button> 

*/
