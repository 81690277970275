const placket = [
    "Slit",
    "Vent",
    "Insert",
    "Panel",
    "Seam",
    "Inset",
    "Flap",
    "Gash",
    "Slash",
    "Split",
    "Slot",
    "Aperture",
    "placket"
  ];

  export const closurePlacketPresence = [placket]