import React, { useCallback, useContext, useEffect, useState } from 'react';
import logo1 from '../../assets/logo.png';
import { useHistory } from 'react-router';
import { getAuth, signOut } from '@firebase/auth';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';

import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import axios from 'axios';
import App, { OrganizationContext } from '../App';
import { Button, Input } from '@mui/material';
import { useMenu } from './MenuContext';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppBar } from '@mui/material';
import { useParams } from 'react-router-dom';

function stringToColor(string) {
	let hash = 0;
	let i;
	if (!string) return '#dddddd';

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function stringAvatar(name) {
	return {
		sx: {
			bgcolor: stringToColor(name),
			width: 32,
			height: 32,
			fontSize: 16,
		},
		children: name
			?.split(' ')
			.slice(0, 2)
			.reduce((a, b) => a + b[0].toUpperCase(), ''),
	};
}

export const Header = (props) => {
	const {reset} = props

	const { organization, setOrganization } = useContext(OrganizationContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { isMenuOpen, openMenu, closeMenu } = useMenu();

	const history = useHistory();
	const {id} = useParams();
	const preference = localStorage.getItem('preference');
	/**
	 * Perform logout action via Magic.
	 */
	const logout = () => {
		signOut(auth)
			.then(() => {
				setOrganization(null);
				localStorage.removeItem('token');
				localStorage.removeItem('preference');
				localStorage.removeItem('accountType');
				history.push('/');
			})
			.catch((e) => alert(e.message));
	};
	const auth = getAuth();
	const updateProjectName = (value) => {
		if (props.name !== 'labels') {
			props.setProjectName(value);
		} else {
			props.updateProjectName(value);
		}
	};

	const handleSettings = () => {
		history.push('/settings');
	};
	
	const isMM = organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1' || organization?.id === 'kDQArRBVOdXiTcGZT8PieKXqsUm2' || (auth.currentUser && auth.currentUser.email ==='carmela@manteaux.com')
	
	
	;
	const currentPath = history.location.pathname;

	const newProject = () => {
		let route;

		if (currentPath.includes('mm-coats/h3')) {
			route = '/mm-coats/h3/new';
		} else if (currentPath.includes('mm-sports/h3')) {
			route = '/mm-sports/h3/new';
		} else if (currentPath.includes('mm-coats')) {
			route = '/mm-coats/new';
		} else if (currentPath.includes('mm-sports')) {
			route = '/mm-sports/new';
		} else if (currentPath.includes('projects')) {
			route = '/mm-coats/new';
		}

		reset();
		history.push(route);
	};
	
	return (
		<AppBar
			position='static'
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				backgroundColor: '#fff',
				color: '#333333',
				padding: '0 20px',
				boxSizing: 'border-box',
			}}
		>
			<div className='flex items-center gap-10 py-2'>
				<div
					className='header-logo d-flex flex-row bd-highlight mb-2 mt-1'
					onClick={() =>
						history.push(isMM ? '/mm-coats/new' : '/bulk-shopify')
					}
				>
					<div className='p-1 bd-highlight'>
						<img src={logo1} alt='Found ' />
					</div>
					<div className='p-2 bd-highlight  snap-text mr-3'>
						SnapWrite AI
					</div>
				</div>
				<div className='w-10'>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={openMenu}
						edge='start'
						sx={{ ...(isMenuOpen && { display: 'none' }) }}
					>
						<MenuIcon />
					</IconButton>
					{isMenuOpen && (
						<IconButton onClick={closeMenu}>
							<ChevronLeftIcon />
						</IconButton>
					)}
				</div>
				{props.projectName !== undefined ? (
					<div>
						<Input
							placeholder='Project Name'
							value={props.projectName}
							onChange={(event) => {
								updateProjectName(event.target.value);
							}}
							data-hj-allow
						/>
					</div>
				) : null}
			</div>

			<React.Fragment>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						gap: 1,
						paddingRight: 6,
					}}
				>
					{props.reset === undefined
						? props.hideButton === undefined && (
								<Link
									to={isMM ? '/mm-coats/new' : '/'}
									className='generateMoreButton'
								>
									New Project
								</Link>
						  )
						: props.hideButton === undefined && (
								<Button
									className='resetSession'
									onClick={newProject
									}
								>
									New Project
								</Button>
						  )}

					{(props.hideAccount === undefined ||
						!props.hideAccount) && (
						<Tooltip title='Account settings'>
							<IconButton
								onClick={handleClick}
								size='small'
								sx={{ ml: 2 }}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									{...stringAvatar(
										auth?.currentUser?.displayName
									)}
								></Avatar>
							</IconButton>
						</Tooltip>
					)}
				</Box>
				<Menu
					anchorEl={anchorEl}
					id='account-menu'
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					PaperProps={{
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&:before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{
						horizontal: 'right',
						vertical: 'top',
					}}
					anchorOrigin={{
						horizontal: 'right',
						vertical: 'bottom',
					}}
				>
					<MenuItem onClick={handleSettings}>
						<ListItemIcon>
							<Settings fontSize='small' />
						</ListItemIcon>
						Settings
					</MenuItem>
					<MenuItem onClick={logout}>
						<ListItemIcon>
							<Logout fontSize='small' />
						</ListItemIcon>
						Logout
					</MenuItem>
					<MenuItem
						onClick={() => {
							axios
								.put(
									'https://apie.snapwrite.ca/storeData/user-preferences-updateVal',
									{
										name: 'accountType',
										val: 'seller',
										userID: auth.currentUser.uid,
									}
								)
								.then((res) => {
									console.log(res.data.data);
								})
								.catch((err) => console.error(err));
							localStorage.setItem('accountType', 'seller');
							history.push('/forms');
						}}
					>
						I am a Seller
					</MenuItem>
					<MenuItem
						onClick={() => {
							axios
								.put(
									'https://apie.snapwrite.ca/storeData/user-preferences-updateVal',
									{
										name: 'accountType',
										val: 'reseller',
										userID: auth.currentUser.uid,
									}
								)
								.then((res) => {
									console.log(res.data.data);
								})
								.catch((err) => console.error(err));
							localStorage.setItem('accountType', 'reseller');
							history.push(
								preference === 'vintage'
									? '/vintage'
									: '/bulk-shopify'
							);
						}}
					>
						I am a Reseller
					</MenuItem>
				</Menu>
			</React.Fragment>
		</AppBar>
	);
};
