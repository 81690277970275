import { useState, useEffect, useRef } from "react";
import VendorTable from "./VendorTable";
import CustomizedTable from "../../../products/bulk/shopify/customizedTable"
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress, IconButton, TableCell, Tooltip, InputAdornment } from "@mui/material";
import axios from "axios";
import RefreshIcon from '@mui/icons-material/Refresh';


export const RowColumn = ({noTD, noSave, userID, setAutocompleteOptions, autocompleteOptions, numeric, textBoxHeight, textBoxWidth, conversion, otherName, otherLabel, otherAttr, setOtherLength, selectOptions, colorOption, setParentVal, prop, index, type, style, name, attr, label, store, noNotes, disabled, select, updateValParent, setParentState, parentState}) => {
	const [val, setVal] = useState('');
	const [valChanged, setValChanged] = useState(false)	
	
	useEffect(() => { if (prop[name] && prop[name].length === 0) return; colorOption !== undefined ? setVal(prop[name] ? prop[name][index][colorOption] : "") : otherName ? setVal([prop[name][index], prop[otherName][index]]) : setVal(prop[name] ? prop[name][index] : ""); }, [prop[name]]);
	const[isReGenerateButtonVisible, setIsReGenerateButtonVisible] = useState(false) // To be used Later to make sure the generate button is not visible all the time
	const[descriptionRegenerating, setDescriptionRegenerating] = useState(false)
	const [isInputActive, setIsInputActive] = useState(false)

	const backendClient = axios.create({
		baseURL: "https://apif.snapwrite.ca/",
	  });

	const defaultStyle = {
		ta: {
			backgroundColor: "transparent",
			border: "none",
			resize: "none",
		},
		notes: {
			background: "transparent",
			border: "none",
			resize: "none",
		}
	}

	function handleChange(event){
		setVal(event.target.value)
		prop.updateValIndividual(
			name,
			event.target.value,
			index,
			attr ? attr : name,
			colorOption !== undefined ? colorOption : undefined
			
		)
	}


	const regenerateDescription = async () => {
		setDescriptionRegenerating(true);								
		let endpoint=""
		if(name==="descriptionEN") {
			endpoint = "/mm-description-regenerate-eng"
		} else if (name==="descriptionFR") {
			endpoint = "/mm-description-regenerate-french"
		}

		const result = await backendClient.post(endpoint, {
			data: val,          
		}).catch((err) => {        
		prop.setBackendAlertMessage({
			...prop.backendAlertMessage,
			display: true,
			message: `Error Occurred ${err.message}`
		})			
		});
		
		if(result.data) {

			setVal(result.data.trim())
			prop.updateValIndividual(
				name,
				result.data,
				index,
				attr ? attr : name,
				colorOption !== undefined ? colorOption : undefined
				
			)		  		  
		}
		setDescriptionRegenerating(false)
		
	  }
  
	function handleLinkedChange(event, arrIndex) {
		event.target.value = event.target.value.replace(/[^\d.]/g, "")
		setVal(prev => {
			const copy = [...prev]
			copy[arrIndex] = event.target.value
			if (arrIndex === 1) {
				const conversionValue = (parseFloat(event.target.value) * (1/conversion)).toFixed(1)
				copy[0] = isNaN(conversionValue) ? "0" : conversionValue
			}
			else {
				const conversionValue = (parseFloat(event.target.value) * (conversion)).toFixed(0)
				copy[1] = isNaN(conversionValue) ? "0" : conversionValue
			}
			return copy
		})

		console.log("first", name, event.target.value, attr)
		// console.log(arrIndex, 
		// 	name, //length,
		// 	attr,
		// 	otherName, //inches
		// 	otherAttr) //lengthInches
		prop.updateValIndividual(
			arrIndex === 0 ? name : otherName,
			isNaN(event.target.value) ? "0" : event.target.value,//value
			index,
			arrIndex === 0 ? (attr ? attr : name) : (otherAttr ? otherAttr : otherName), //key
			colorOption !== undefined ? colorOption : undefined
		)
		console.log("second", otherName, event.target.value, otherAttr, arrIndex, arrIndex === 0 ? (isNaN(event.target.value * conversion) ? "0" : event.target.value * conversion) : (isNaN(event.target.value * (1/conversion)) ? "0" : event.target.value * (1/conversion)))
		prop.updateValIndividual(
			arrIndex === 0 ? otherName : name,
			arrIndex === 0 ? (isNaN(Math.floor(parseFloat(event.target.value) * conversion)) ? "0" : (parseFloat(event.target.value) * conversion).toFixed(0)) : (isNaN(Math.floor(parseFloat(event.target.value) * (1/conversion)))) ? "0" :(parseFloat(event.target.value) * (1/conversion)).toFixed(1),
			index,
			arrIndex === 0 ? (otherAttr ? otherAttr : otherName) : (attr ? attr : name),
			colorOption !== undefined ? colorOption : undefined
		)
	}

	const ta = 
		<textarea
			
			disabled={disabled}
			style={{
				...defaultStyle[type],
				...style,
				height: textBoxHeight ? textBoxHeight : "",
				width: textBoxWidth ? textBoxWidth : ""
			}}
			
			value={val}
			onChange={(event) => {
				handleChange(event)
				}
			}
		/>

	if (type === "ta") {
		if (typeof colorOption === "number") {
			return (
				<TableCell style={{border:"none"}}>
				{ta}
				</TableCell>
			)

		} else {
			return (
				<TableCell
				>
				{ta}
				</TableCell>
			)
		}
	}
	else if (type === "muiText") {
		return (
			<div className="relative">
			<TextField
			  label={label}
			  variant="outlined"
			  inputProps={{ maxLength: 80 }}
			  multiline
			  value={val}
			  onChange={handleChange}
			  onFocus={()=> setIsInputActive(true)}
			  onBlur={()=> setIsInputActive(false)}
			  style={{height: textBoxHeight, width: "250px"}}
			/>
			{(val.length > 70 && isInputActive)&& <p className=" absolute right-2 text-xs -bottom-3  text-red-600">{80 - val.length} left</p>
}
			</div>
		  );
	} 
	else if (type === "autocomplete") {
		if (noTD) {
			return (
			<>
				<Autocomplete
                disablePortal
                disableClearable
                freeSolo
                id={`${name}-autocomplete`}
                options={autocompleteOptions}
                value={val}
                sx={{ width: name !== "fit" ? 400 : 200, fontSize: "normal" }}
                size="small"
                onChange={(e, value) => {
					setValChanged(true)
					handleChange({target: {value: value}})
                  }}
				onInputChange={(e, value) => {
					if (valChanged || value !== prop[name][index]) {
						setValChanged(true)
						handleChange({target: {value: value}})
					}
                  }
			}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    sx={{ fontSize: "normal" }}
                    variant = "outlined"
                  />
                )}
              />
			  
			  </>
			  
			)
		}
		return (
			<td>
				<Autocomplete
                disablePortal
                disableClearable
                freeSolo
                id={`${name}-autocomplete`}
                options={autocompleteOptions}
                value={val}
                sx={ name === "brand" || name==="typesTaxoUpdate" ? style : {width:  400, fontSize: "normal"} }
                size="small"
                onChange={(e, value) => {
						setValChanged(true)
						

					if(userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1") {						
						handleChange({target: {value: value}})						
						updateValParent(name,value, index, parentState, setParentState)					
					} else {
					handleChange({target: {value: value}})

					}

                  }}
					onInputChange={(e, value) => {
						if (valChanged || value !== prop[name][index]) {
							setValChanged(true)
							handleChange({ target: { value: value } })
						}
					}
					}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    sx={{ fontSize: "normal" }}
                    variant = "outlined"
                  />
                )}
              />
			  
			</td>
		)
	}
	else if (type === "select") return (
		<TableCell>
			<select
				disabled={disabled}
				value={val}
				onChange={handleChange}
			>
			{ name === "condition" ?
					prop.userID === "b41Z6rL6eJN1VccgZXrUzWbdjGg1" || prop.userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ?
						<>
							<option value="Excellent">Excellent</option>
							<option value="Very good">Very good</option>
							<option value="Like New">Like New</option>
							<option value="New with tags">New with tags</option>
							<option value="New without tags">New without tags</option>
							<option value="Good">Good</option>
							<option value="Play Condition">Play Condition</option>
						</>
					: store === "vintage" ?
						<>
							<option value="Good">Good</option>
							<option value="Like New">Like New</option>
							<option value="Brand New with Tags">Brand New with Tags</option>
							<option value="Distressed">Distressed</option>
						</>
					: (store === "piece" || store === "tiny" || store === "tokki") ?
						<>
							<option value="Excellent Used Condition">Excellent</option>
							<option value="Very Good Used Condition">Very Good</option>
							<option value="Like New">Like New</option>
							<option value="Brand New with Tags">Brand New with Tags</option>
							<option value="New without Tags">New without Tags</option>
							<option value="Good Used Condition">Good</option>
							<option value="Play Condition">Play</option>
							{/* <option value="Fair Used Condition">Fair</option> */}
						</>

						//Applied to First Kiss Vintage
					: store === "loose" && prop.userID === "r8jkmnica3dJXGLyiIFbItEgZvr2" ? 
						<>
							<option value="Excellent Vintage Condition">Excellent Vintage Condition</option>		
							<option value="Good Vintage Condition">Good Vintage Condition</option>
							<option value="Like New">Like New</option>
							<option value="Distressed">Distressed</option>     
						</>
					: store === "bontot" ?
						<>
							<option value="Very Good">Very Good</option>
							<option value="Good">Good</option>
							<option value="Like New">Like New</option>
							<option value="Brand New with Tags">Brand New with Tags</option>
							<option value="New without Tags">New without Tags</option>
						</>
					: store === "royal" ?
						<>
							<option value="Excellent">Excellent</option>
							<option value="Very Good">Very Good</option>
							<option value="Good">Good</option>
							<option value="Play">Play</option>
							<option value="Litte Extras">Litte Extras</option>
							<option value="Brand New with Tags">Brand New With Tags</option>
							<option value="Brand New without Tags">Brand New Without Tags</option>
						</>						
					: store === "loose" ?
					<>
						<option value="Like New">Like New</option>
						<option value="Good">Good</option>
						<option value="Distressed">Distressed</option>                      
					</>
				: null
					

			:	
			name === "size" ?
			<>
				<option value="XS">XS</option>
				<option value="XXS">XXS</option>
				<option value="S">S</option>
				<option value="M">M</option>
				<option value="L">L</option>
				<option value="XL">XL</option>
				<option value="XXL">XXL</option>
			</>
			: name === "temp" ?
			<>
				<option value=""></option>
				<option value="-5">-5 Degrees C</option>
				<option value="-10">-10 Degrees C</option>
				<option value="-15">-15 Degrees C</option>
				<option value="-20">-20 Degrees C</option>
				<option value="-25">-25 Degrees C</option>
				<option value="-30">-30 Degrees C</option>
				<option value="-40">-40 Degrees C</option>
				
			</> : name === "careInstructions" ?
			<>
				<option value="Dry clean only">Dry clean only</option>
				<option value="Machine wash">Machine wash</option>
				<option value="Machine wash cold / Dry flat">Machine wash cold / Dry flat</option>
				<option value="Machine wash cold / Hang to dry">Machine wash cold / Hang to dry</option>
				<option value="Machine wash cold / Tumble dry low">Machine wash cold / Tumble dry low</option>
				<option value="Remove trim before wash / Machine wash cold / Hang to dry">Remove trim before wash / Machine wash cold / Hang to dry</option>
				<option value="Remove trim before wash / Machine wash cold / Tumble dry low">Remove trim before wash / Machine wash cold / Tumble dry low</option>
				<option value="Turn inside out / Machine wash">Turn inside out / Machine wash</option>
				<option value="Turn inside out / Machine wash / Hang to dry">Turn inside out / Machine wash / Hang to dry</option>
				<option value="Turn inside out / Machine wash cold">Turn inside out / Machine wash cold</option>
				<option value="Turn inside out / Machine wash cold / Tumble dry low">Turn inside out / Machine wash cold / Tumble dry low</option>
			</> : name === "modelHeight" ?
			<>
				<option value={"5'7\""}>5'7"</option>
				<option value={"5'8\""}>5'8"</option>
				<option value={"5'9\""}>5'9"</option>
				<option value={"5'10\""}>5'10"</option>
				<option value={"5'11\""}>5'11"</option>
			</> : name === "modelSize" ?
			<>
				<option value={"S"}>S</option>
				<option value={"M"}>M</option>
				<option value={"L"}>L</option>
				<option value={"1X"}>1X</option>
			</>: null }
			</select>
			{
			(!noNotes || 
				((store === "piece" || store === "tiny") && 
					(val.includes("Used Condition") || val.includes("Play"))
				)
			) && 
			<RowColumn disabled={disabled} name={name === "size" ? "sizeNotes" : "conditionDescription"} type="notes" index={index} prop={prop} style={{width: "250px", height: "110px"}}/>
			}
		
	</TableCell>  
	);
	else if (type === "notes") return (
		<>
			<br />
			Notes:
			<textarea
				disabled={disabled}
				name="notes"
				style={{
					...defaultStyle[type],
					...style
				}}
				onKeyDown={(e) => {
					if (e.key === ",") {
						e.preventDefault();
					}
				}}
				onChange={(e) => {
					if (e.currentTarget.value.includes(",")) {
						e.currentTarget.value = e.currentTarget.value.replace(
							/,/g,
							";"
						);
					}
					setVal(e.currentTarget.value);
					prop.updateValIndividual(
						name,
						e.currentTarget.value,
						index,
						attr ? attr : name,
						colorOption !== undefined ? colorOption : undefined
					);
				}}
				value={val}
			/>
		</>

	);
	else if (type === "stackItem") return (
		<>
		<label htmlFor={label}>{label} -&nbsp;</label>
			<input
			disabled={disabled}
			name={label}
			id={label}
				style={{
					background: "transparent",
					border: "none",
					width: "50px",
			}}
			value={val}
			onChange={handleChange}
			/>
			<br />
		</>
	);
	else if (type === "stackItemSelect") return (
		<>
		<div style={{display: "flex", width:"180px", justifyContent: "space-between"}}>
		{`${label}:`}&nbsp;&nbsp;
		<select
			disabled={disabled}
			value={val}
			onChange={handleChange}
		>
			{selectOptions.map((option, index) => {
				return <option key={index} value={option.value}>{option.label}</option>
			})}
		</select>
		</div>
		</>
	);
	else if (type === "propertyItem") {
		if (select) {
			return (
				<div className="input-row">
					<label htmlFor={name}>{label ? label : name} -&nbsp;</label>
					<div className="textarea-wrapper">
					<select
						disabled={disabled}
						value={val}
						onChange={handleChange}
					>
						{select.map(d => <option value={d}>{d}</option>)}

					</select>
					</div>
				</div>
			)
		}
		return (
			<div className="input-row">
				<label htmlFor={name}>{label ? label : name} -&nbsp;</label>
				<div className="textarea-wrapper">
				<textarea
					disabled={disabled}
					className="attribute-textarea"
					style={{minWidth: "200px", boxSizing:"content-box"}}
				name={name}
				id={name}
				value={numeric ? (isNaN(val) ? "" : val) : val}
				onChange={handleChange}
				/>     
				</div>
			</div>
		);
	}
	else if (type === "linkedPropertyItem") {
		return (
		<>
			<div className="input-row">
				<label htmlFor={name}>{label ? label : name} -&nbsp;</label>
				<div className="textarea-wrapper">
				<textarea
					disabled={disabled}
					className="attribute-textarea"
					style={{minWidth: "200px", boxSizing:"content-box"}}
				name={name}
				id={name}
				value={isNaN(val[0]) ? 0 : val[0]}
				onChange={(event) => handleLinkedChange(event, 0)}
				/>     
				</div>
			</div>
			<div className="input-row">
			<label htmlFor={otherName}>{otherLabel ? otherLabel : otherName} -&nbsp;</label>
			<div className="textarea-wrapper">
			<textarea
				disabled={disabled}
				className="attribute-textarea"
				style={{minWidth: "200px", boxSizing:"content-box"}}
			name={otherName}
			id={otherName}
			value={isNaN(val[1]) ? 0 : val[1]}
			onChange={(event) => handleLinkedChange(event, 1)}
			/>     
			</div>
		</div>
		</>
		);
	}
	else if (type === "number") return (
		<td>
			<input
				disabled={disabled}
				type="number"
				value={val}
				style={{
					...defaultStyle["ta"],
					...style
				}}
				onKeyDown={(e) => {				
					if (
						!/^\d$/.test(e.key) && // Not a digit
						e.key !== "Backspace" && // Not the Backspace key
						e.key !== "Delete" && // Not the Delete key
						e.key !== "ArrowLeft" && // Not the Left Arrow key
						e.key !== "ArrowRight" && // Not the Right Arrow key
						e.key !== "Tab" // Not the Tab key
					  ) {
						e.preventDefault(); // Prevent the default behavior for other keys.
					  }
					}}
				onChange={handleChange}
			/>
		</td>

	)
	else if (type === "description") return (
        <TableCell sx={{
			border: "none",
		}}>
            <TextField
			multiline={true}
			rows={8}
            name={`description-${name}`}
				InputProps={{
					endAdornment: (
						<InputAdornment
							sx={{

							}}
							position='end'
						>

							{true && (
								<div className="">
									{descriptionRegenerating && <CircularProgress color="secondary"
									/>}


									{!descriptionRegenerating && (<Tooltip title="Regenerate Description" placement="top">
										<IconButton size="small" aria-label="refresh"
											onClick={() => {
												regenerateDescription()
											}}

										>
											<RefreshIcon color="secondary" sx={{ fontSize: 30 }} />
										</IconButton>
									</Tooltip>)}

								</div>)}


						</InputAdornment>
					),
				}}
            // disabled={loading}
            // placeholder="Click on the update button if you would like a paragraph descrition or you can leave if blank 😄"
            style={{
                // background: index % 2 !== 0 ? "#F3F3F3" : "white",
                border: "none",
                height: "11.5em",
                resize: "none",
                width: "500px",
				padding:"12px",
            }}
			// onFocus={()=> {
			// 	setIsReGenerateButtonVisible(true)
			// }}
			// onBlur={() =>{
			// 	setIsReGenerateButtonVisible(false)
			// }}
            onChange={(e) => {
               
				handleChange(e)                    
            }}
            value={val}
            />
			
			
			
            {/* <button className ='btn' disabled={loading} onClick={()=>{generateDescription(index)}}>Update</button> */}
      </TableCell>
	)
}

const TableList = (props) => {
  const [generatePause, setGeneratePause] = useState(false)
  const {projectData, setProjectData, uploadImage, template} = props


  // Make trash button visible when the table row is being hovered and make row cells the same colour
  // as the hovered row colour
  const handleHover = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "visible";

    const cells = Object.values(tableRow.children);
    // for (let i = 0; i < cells.length; i++) {
    //   let textArea = Object.values(cells[i].children);

    //   for (let j = 0; j < textArea.length; j++) {
    //     if (
    //       textArea[j] != null &&
    //       (textArea[j].nodeName == "TEXTAREA" ||
    //         textArea[j].nodeName == "INPUT")
    //     ) {
    //       // textArea[j].style.backgroundColor = "#E5E5E5";
    //     }
    //   }
    // }
  };

  // Make trash button hidden when the table row is not being hovered and return cells to their original colour
  const handleMouseOut = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "hidden";

    const cells = Object.values(tableRow.children);
    // for (let i = 0; i < cells.length; i++) {
    //   let textArea = Object.values(cells[i].children);

    //   for (let j = 0; j < textArea.length; j++) {
    //     if (
    //       index % 2 === 0 &&
    //       textArea[j] != null &&
    //       (textArea[j].nodeName == "TEXTAREA" ||
    //         textArea[j].nodeName == "INPUT")
    //     ) {
    //       // textArea[j].style.backgroundColor = "white";
    //     } else if (
    //       index % 2 !== 0 &&
    //       textArea[j] != null &&
    //       (textArea[j].nodeName == "TEXTAREA" ||
    //         textArea[j].nodeName == "INPUT")
    //     ) {
    //       // textArea[j].style.backgroundColor = "#F3F3F3";
    //     }
    //   }
    // }
  }; 

  return (props.isVendor ?
	<VendorTable
		prop={props}
	/> 
	:
	<CustomizedTable
	  propsData = {props}
	  generatePause={generatePause}
	  setGeneratePause={setGeneratePause}
	  handleHover={handleHover}
	  handleMouseOut={handleMouseOut} 
	  projectData={projectData}
	  setProjectData={setProjectData}
	  uploadImage={uploadImage}
	  template={template}
	  />
	  )
	  
};

export default TableList;
