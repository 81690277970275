import React, {useContext, useEffect } from "react";
import { useState } from 'react'
import ProjectsGroup from "../Library/projectsGroup";
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import Button from '@mui/material/Button';
import { Table, TableBody, td, TableContainer, TableHead, TableRow, Paper, Tooltip} from '@mui/material';
import { OrganizationContext } from "../App";
import MainSideMenuHeaderContainer from "../containers/MainSideMenuHeaderContainer";
import { Heading1 } from "../branded-elements/Elements";
import AddCustomerForm from "./AddCustomerForm";
import axios from "axios";
import {ArchiveOutlined, UnarchiveOutlined } from "@mui/icons-material";

const BatchAggregator = ({history}) => {
	
		const {organization, setOrganization} = useContext(OrganizationContext)
    const [batches, setBatches] = useState([])
    const [userID, setUserID] = useState()
    const [userName, setUserName] = useState()
    const [showArchived, setShowArchived] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
    

    const [viewType, setViewType] = useState('list');

		const handleArchive = (id, endpoint, isArchived) => {
			isArchived = !isArchived;

			const requestOptions = {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ id, isArchived})
			};

			fetch(`https://apie.snapwrite.ca/storeData/${endpoint}-isArchivedUpdate`, requestOptions)
				.then(response => response.json())
				.catch(error=> console.log(error))
			setBatches(batches?.map(d => (d._id === id) ? ({...d, isArchived: isArchived}) : d))
		}

    useEffect(() => {
      let userID;
      let userName
      
      const auth = getAuth();
      onAuthStateChanged(auth, user => {
        if (user) {
          userID = organization?.id || user.uid;
          userName = user.email;
          setUserID(userID);
          setUserName(userName)
          getData(userID)
        } else {
          console.log('User not authenticated');
        }
      });
    }, []);


	const getData = async (userId) => {
		const url = `https://apie.snapwrite.ca/storeData/apparel-input-batch?userID=${userId}`;
		const response = await fetch(url);
		const data = await response.json();
		const forms = data.data.map(form => ({ ...form, path: 'newTable', endpoint: 'apparel-input-batch' }));
		setBatches(forms); 
	  };

    const handleArchivedToggle = async (archived) => {
      await getData(userID)
      setShowArchived(archived)
    }

    /*
    const createForm = () => {
        fetch('https://apie.snapwrite.ca/storeData/apparel-form', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({                        
            userID: 'test',
            userName: 'testName',                                       
            dateCreated: new Date(),
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            console.log(json.data)
            // setChildObjectID(json.data.id)
            console.log(json.data._id)
            history.push(`/NewView/${json.data._id}`)
            // const generateButton = document.getElementById("createNewForm") 
            // generateButton.click()
          })
          
          .catch((err) => {
            console.log(`Error ${err}`);
          });

        }
      */

      
        const toggleArchivedButtons = () => {
          return (
            <>
            <div style={{marginRight:"21px", 
                         cursor:"pointer",
                         padding:"7px",
                         width:"80px",
                         textAlign:"center",
                         background: (showArchived ? "#c5c5c55c" : "#8884d8"),
                         borderRadius:"10px"
                         
                         }} 
                         onClick={()=> {handleArchivedToggle(false)}}>Active</div>
            <div style={{marginRight:"21px", 
                         cursor:"pointer",
                         padding:"7px",
                         width:"80px",
                         textAlign:"center",
                         background: (showArchived ? "#8884d8" : "#c5c5c55c"),
                         borderRadius:"10px"
                         }} onClick={()=> {handleArchivedToggle(true)}}>Archived</div>
            </>
          )
        }

       

      const handleClick = () => {
        setAlertOpen(true);
      };
    
      const handleClose = () => {
        setAlertOpen(false);
      };
    

  const handleChangeViewType = (event, newViewType) => {
    setViewType(newViewType);
  };

  const searchBatchByName = (searchQuery) => {
    const results = batches.filter(item => item.batchName?.toLowerCase().includes(searchQuery.toLowerCase()));
    return results;
}
	// const toggleButton = 
	// 	<ToggleButtonGroup
	// 		size="small"
  //     value={viewType}
  //     exclusive
  //     onChange={handleChangeViewType}
  //   >
  //     <ToggleButton value="list" aria-label="list">
  //       <ViewListIcon />
  //     </ToggleButton>
  //     <ToggleButton value="card" aria-label="card">
  //       <ViewModuleIcon />
  //     </ToggleButton>
  //   </ToggleButtonGroup>;
	const listView = 
			<TableContainer style={{paddingBottom: 170}} component={Paper}>
					<Table>
							<TableHead>
							<TableRow style = {{backgroundColor: "white", fontWeight: "bold"}}>
									<td className="pl-2">Name</td>
									<td className="pl-2">Phone Number</td>
									<td className="pl-2">Description (Latest Form)</td>
									<td>Last Updated</td>
									<td className="pl-2">Email</td>
									{/* <td>Unexported Forms</td> */}
									<td>Archive</td>

							</TableRow>
							</TableHead>
							<TableBody>
							
							{searchBatchByName(searchQuery).map((batch, i) => {
								if (batch.isArchived !== showArchived) return null
								return (
									<BatchRow key={batch._id} batch={batch} history={history} handleArchive={handleArchive}/>
								)
							})}
									
							</TableBody>
					</Table>
			</TableContainer>;
    
    return(
        <MainSideMenuHeaderContainer
        name={undefined}
        >
              <div style ={{display: "flex", justifyContent: "space-between",alignItems:"center", stretch: "none"}} >          
              <Heading1 title={"Customers"} />
              <button style = {{marginRight: "10%"}} className = "btn" onClick ={handleClick}>Add Customer</button>
            </div>
            <p style = {{marginLeft: "2px", marginTop:"-10px"}}>These are the people you receive items from</p>

			<input
			placeholder="Search"
			className="outline-none focus:outline-none  ml-1 bg-inherit min-w-max mb-8 w-96 p-3 rounded-md"
			onChange={(e) => setSearchQuery(e.target.value)}
			/>
            <br/>
            <div style= {{display: "flex", justifyContent: "space-between"}}>
							<div style={{display: "flex"}}>
								{toggleArchivedButtons()}
							</div>
							{/* {toggleButton} */}
						</div>
						<br /><br />

							{
							viewType === 'list' ? 
								listView
							: 
							viewType === 'card' ?
								<ProjectsGroup archived={showArchived} title='' projects={batches} setProjects={setBatches} /> 
							: null
							}



         {/* Customer Dialogue  */}
         <AddCustomerForm alertOpen={alertOpen} handleClose={handleClose} getData={getData} userID={userID} userName={userName} setAlertOpen={setAlertOpen}/>

        </MainSideMenuHeaderContainer>
    )
}

const updateCustomerData = (name, val, id) => {
fetch(`https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal`, {
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
	body: JSON.stringify({
		id: id,
		val: val,
		name: name,
	}),
})
	.then((response) => response.json())
	.catch((err) => {
		console.log(`Error ${err}`);
	});
};

const BatchRow = ({batch, history, handleArchive}) => {
	const [name, setName] = useState(batch.batchName)
	const [phone, setPhone] = useState(batch.defaultPhoneNumber)
	const [email, setEmail] = useState(batch.defaultEmail)
	const [lastFormDescription, setLastFormDescription] = useState(batch.description)

	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
	};

	const handleNameChange = (event) => {
	setName(event.target.value);
	fetch(`https://apie.snapwrite.ca/storeData/${batch.endpoint}-projectNameUpdate`, {...requestOptions, body: JSON.stringify({ id: batch._id, projectName: event.target.value}) })
		.then(response => response.json())
	}

	const link =`/${batch.path}/${batch._id}`
	return (
		<>
			<TableRow style = {{cursor: "pointer"}} onClick={(e)=> {
				if (e.isPropagationStopped()) return;
				history.push(link)	
			}}>
					<td className="py-2">
						<input 
							className="outline-none border-none p-1 ml-1 bg-inherit min-w-max"
							placeholder="Enter Name"
							onClick={(e)=>e.stopPropagation()}
							value={name}
							onChange={handleNameChange} 
						/>
					</td>

					<td>
						<input 
							className="outline-none border-none p-1 ml-1 bg-inherit min-w-max"
							placeholder="Enter Phone Number"
							onClick={(e)=>e.stopPropagation()}
							value={phone}
							onChange={(e)=>{
								setPhone(e.target.value)
								updateCustomerData("defaultPhoneNumber", e.target.value, batch._id)
							} 
							}
						/>
					</td>
					<td>
					<textarea
						cols="30"
						rows="1"
						className="outline-none border-none p-1 ml-1 bg-inherit min-w-max resize-none disabled:opacity-50"
						placeholder={batch.formObjectID === undefined ? "No Forms Created" : "Description"}
						onClick={(e) => e.stopPropagation()}
						value={lastFormDescription}
						disabled={batch.formObjectID === undefined}
						style={{
							height: `${Math.max(30, lastFormDescription.split('\n').length * 20)}px`, maxHeight: `300px`,
							cursor: batch.formObjectID === undefined ? "not-allowed" : "text",

						}} // Adjust the height dynamically
						onChange={async (e) => {
							setLastFormDescription(e.target.value)
							const url = "https://apie.snapwrite.ca/storeData/apparel-form"
							const body = {
								id: batch.formObjectID,
								val: e.target.value,
								name: "receptionDescription",
							};

							const response = await axios.put(url, body)
							if (response.status !== 200) {
								console.log(`Error ${response.status}`)
							}
						}}
					/>
					</td>
					<td>{new Date(batch.formUpdatedAt).toLocaleDateString('en-CA')}</td> 
					{/* <td style={{color: batch.unexportedCount ? "red": "black"}}>{batch.unexportedCount}</td>  */}

					<td>
						<input 
							className="outline-none border-none p-1 ml-1 bg-inherit min-w-fit"
							placeholder="Enter Email"
							onClick={(e)=>e.stopPropagation()}
							value={email}
							onChange={(e)=> {
								setEmail(e.target.value)
								updateCustomerData("defaultEmail", e.target.value, batch._id)
							}
							}
						/>
					</td> 
					<td>
						<Tooltip title={batch.isArchived ? "Unarchive" : "Archive"}>
						<Button onClick={(e)=>{
							e.stopPropagation();
							handleArchive(batch._id, batch.endpoint, batch.isArchived);
						}}>
							{batch.isArchived ? <UnarchiveOutlined size={5} style={{color: "black"}} /> : 
							<ArchiveOutlined size={5} style={{color: "black"}} />
							}
						</Button>
						</Tooltip>
					</td> 
			</TableRow>   
		</>
	)
}

export default BatchAggregator