import React, { useEffect, useState } from "react";
import { getAuth } from '@firebase/auth';
import {  onAuthStateChanged } from '@firebase/auth';
import MultiImage from "./shopify/featureTable";

export function VintageHandler({history}) {
  /* **************************Tags area************************** */
  let userID ;  
  const auth = getAuth();
  const user = auth.currentUser;
  const [proceed, setProceed] = useState(false)

  if(user!==null){
    userID = auth.currentUser.uid;  
  }

  useEffect(() => {

    auth.onAuthStateChanged(user => {
			const token = localStorage.getItem("token");

			if (!token || !user) {
				history.push("/");
			}else {
                setProceed(true)
            }

    })   
  },[])
  return (                       
<>
    {proceed ?   
        <MultiImage                                                                
        userID={userID}
        template='vintage'
      />:null  }</>
    
  );
}
export default VintageHandler;
