import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export const generalMenuItems = [
	{
		name: 'Projects',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'Active',
				icon: <FolderOpenIcon />,
				link: '/projects',
			},
			{
				name: 'Theme',
				icon: <FolderOpenIcon />,
				link: '/themes',
			},
			{
				name: 'Shared With Me',
				icon: <FolderOpenIcon />,
				link: '/projects-shared',
			},
		],
	},
	{
		name: 'Upload',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M6 10.95L5 11.5C4.76666 11.6333 4.51666 11.6667 4.25 11.6C3.98333 11.5333 3.78333 11.3833 3.65 11.15L1.65 7.65001C1.51666 7.41667 1.48333 7.16667 1.55 6.90001C1.61666 6.63334 1.76666 6.43334 2 6.30001L7.75 3.00001H9.5C9.65 3.00001 9.771 3.04601 9.863 3.13801C9.955 3.23001 10.0007 3.35067 10 3.50001V4.00001C10 4.55001 10.196 5.02101 10.588 5.41301C10.98 5.80501 11.4507 6.00067 12 6.00001C12.55 6.00001 13.021 5.80401 13.413 5.41201C13.805 5.02001 14.0007 4.54934 14 4.00001V3.50001C14 3.35001 14.046 3.22901 14.138 3.13701C14.23 3.04501 14.3507 2.99934 14.5 3.00001H16.25L22 6.30001C22.2333 6.43334 22.3833 6.63334 22.45 6.90001C22.5167 7.16667 22.4833 7.41667 22.35 7.65001L20.35 11.15C20.2167 11.3833 20.0207 11.5293 19.762 11.588C19.5033 11.6467 19.2493 11.609 19 11.475L18 10.975V20C18 20.2833 17.904 20.521 17.712 20.713C17.52 20.905 17.2827 21.0007 17 21H7C6.71666 21 6.479 20.904 6.287 20.712C6.095 20.52 5.99933 20.2827 6 20V10.95Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'Preloved Kids',
				link: '/bulk-shopify',
			},
			{
				name: 'Preloved Adults',
				link: '/preloved-regular',
			},
			{
				name: 'Vintage',
				link: '/vintage',
			},
			{
				name: 'New',
				link: '/new',
			},
		],
	},
	{
		name: 'Vendor',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='20'
				height='21'
				viewBox='0 0 20 21'
				fill='none'
			>
				<path
					d='M12.5 19.425L8.25 15.175L9.65 13.775L12.5 16.625L18.15 10.975L19.55 12.375L12.5 19.425ZM18 9.5H16V4.5H14V7.5H4V4.5H2V18.5H8V20.5H2C1.45 20.5 0.979002 20.304 0.587002 19.912C0.195002 19.52 -0.000664969 19.0493 1.69779e-06 18.5V4.5C1.69779e-06 3.95 0.196002 3.479 0.588002 3.087C0.980002 2.695 1.45067 2.49934 2 2.5H6.175C6.35834 1.91667 6.71667 1.43734 7.25 1.062C7.78334 0.686668 8.36667 0.499335 9 0.500002C9.66667 0.500002 10.2627 0.687668 10.788 1.063C11.3133 1.43834 11.6673 1.91734 11.85 2.5H16C16.55 2.5 17.021 2.696 17.413 3.088C17.805 3.48 18.0007 3.95067 18 4.5V9.5ZM9 4.5C9.28334 4.5 9.521 4.404 9.713 4.212C9.905 4.02 10.0007 3.78267 10 3.5C10 3.21667 9.904 2.979 9.712 2.787C9.52 2.595 9.28267 2.49934 9 2.5C8.71667 2.5 8.479 2.596 8.287 2.788C8.095 2.98 7.99934 3.21733 8 3.5C8 3.78333 8.096 4.021 8.288 4.213C8.48 4.405 8.71734 4.50067 9 4.5Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'Dashboard',
				link: '/vendor-dashboard',
			},
			{
				name: 'Manage',
				link: '/vendor-management',
			},
		],
	},
	{
		name: 'Forms',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M21.1875 12H19.875C19.7719 12 19.6875 12.0844 19.6875 12.1875V19.6875H4.3125V4.3125H11.8125C11.9156 4.3125 12 4.22813 12 4.125V2.8125C12 2.70937 11.9156 2.625 11.8125 2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V12.1875C21.375 12.0844 21.2906 12 21.1875 12Z'
					fill='black'
					fill-opacity='0.6'
				/>
				<path
					d='M8.3414 12.5367L8.29687 15.3234C8.29452 15.532 8.46327 15.7031 8.67187 15.7031H8.68124L11.4469 15.6352C11.4937 15.6328 11.5406 15.6141 11.5734 15.5812L21.3211 5.85469C21.3937 5.78203 21.3937 5.6625 21.3211 5.58984L18.4078 2.67891C18.3703 2.64141 18.3234 2.625 18.2742 2.625C18.225 2.625 18.1781 2.64375 18.1406 2.67891L8.39531 12.4055C8.36145 12.4409 8.3422 12.4877 8.3414 12.5367ZM9.82968 13.0898L18.2742 4.66406L19.3336 5.72109L10.8844 14.1516L9.81328 14.1773L9.82968 13.0898Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'New',
				link: '/NewView',
			},
			{
				name: 'Customers',
				link: '/BatchAggregator',
			},
			{
				name: 'Vendors',
				link: '/vendor',
			},
		],
	},
	// {
	// 	name: 'Inventory Sources',
	// 	icon: (
	// 		<svg
	// 			xmlns='http://www.w3.org/2000/svg'
	// 			width='24'
	// 			height='24'
	// 			viewBox='0 0 24 24'
	// 			fill='none'
	// 		>
	// 			<path
	// 				d='M15.5 19.925L11.25 15.675L12.65 14.275L15.5 17.125L21.15 11.475L22.55 12.875L15.5 19.925ZM21 10H19V5H17V8H7V5H5V19H11V21H5C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H9.175C9.35834 2.41667 9.71667 1.93734 10.25 1.562C10.7833 1.18667 11.3667 0.999335 12 1C12.6667 1 13.2627 1.18767 13.788 1.563C14.3133 1.93834 14.6673 2.41734 14.85 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V10ZM12 5C12.2833 5 12.521 4.904 12.713 4.712C12.905 4.52 13.0007 4.28267 13 4C13 3.71667 12.904 3.479 12.712 3.287C12.52 3.095 12.2827 2.99934 12 3C11.7167 3 11.479 3.096 11.287 3.288C11.095 3.48 10.9993 3.71733 11 4C11 4.28333 11.096 4.521 11.288 4.713C11.48 4.905 11.7173 5.00067 12 5Z'
	// 				fill='black'
	// 				fill-opacity='0.6'
	// 			/>
	// 		</svg>
	// 	),
	// 	link: '/projects',
	// },
	{
		name: 'Analytics',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M16 11.78L20.24 4.45L21.97 5.45L16.74 14.5L10.23 10.75L5.46 19H22V21H2V3H4V17.54L9.5 8L16 11.78Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'Items',
				link: '/analytics',
			},
			{
				name: 'Brands',
				link: '/brand-aggregator',
			},
		],
	},
];
