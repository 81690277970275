import axios from "axios";

const backendClient = axios.create({
	baseURL: 'https://apif.snapwrite.ca/',
});
const getResponse = async(images, userID, setBackendAlertMessage, backendAlertMessage) => {
    const responseArray = [];
    const defaultReturn = 
        {
            "additionalInfo": "",
            "age": [],
            "belt": [],
            "closure": [],
            "color": [],
            "cut": [],
            "description": "",
            "design": [],
            "fastening": [],
            "fasteningType": [],
            "gender": [],
            "heelHeight": [],
            "heelShape": [],
            "height": [],
            "hood": [],
            "item": [],
            "layers": [],
            "length": [],
            "lining": [],
            "material": [],
            "neckline": [],
            "pattern": [],
            "rise": [],
            "skirt": [],
            "sleeves": [],
            "style": [],
            "tags": [],
            "toe": [],
            "type": [],
            "types": []        
    }
    const createEmptyResponse = (image) => {
        const array = []
        // console.log(image)
        image.forEach((img) => {            
            const copyDefault = {...defaultReturn, "image": img}
            array.push(copyDefault)
        });
        // console.log(array)
        return array
    }
    const promises = images.map(async (element) => {
        const response = await backendClient
                                .post('/clothingfeatures', {
                                    imgSrc: element[0],
                                    user: userID,
                                })
                                .catch((err) => {

                                    if (setBackendAlertMessage) {
                                        // Handle errors here
                                        setBackendAlertMessage({
                                            ...backendAlertMessage,
                                            display: true,
                                            message: err.message + ". Due to which data for some images will be blank, you can fill it manually or try again", 
                                        });
                                    }

                                    return {data: createEmptyResponse(element[0])};
                                });
        // console.log(response)
        responseArray.push(...response.data);
        // console.log(responseArray)
    });
                
    await Promise.all(promises);
    // console.log(responseArray)
    return responseArray;
}

export default getResponse