import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Header } from '../navigation/header';
import SideMenu from '../sideMenu';
import ProjectsGroup from '../Library/projectsGroup';
import { getAuth } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { formattedThemeData } from '../../utils/utilFunctions';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import MainSideMenuHeaderContainer from '../containers/MainSideMenuHeaderContainer';
import { Heading1 } from '../branded-elements/Elements';

const API_URL = 'https://apie.snapwrite.ca/storeData/get-themes-items/';

const TabChip = ({ totalCount }) => {
	return (
		<p className='mt-2 ml-[2px] mr-4 bg-slate-100 text-sm rounded-full w-8 h-8 text-center flex items-center justify-center text-slate-600 '>
			{totalCount}
		</p>
	);
};

const ProjectsByTheme = (props) => {
	const firebaseAuth = getAuth();
	const navigationHistory = useHistory();
	const [currentUserID, setCurrentUserID] = useState(null);
	const currentViewName = 'Theme View';
	const [projectsByTheme, setProjectsByTheme] = useState([]);
	const [tabValue, setTabValue] = useState(
		localStorage.getItem('themeTabValue') || '1'
	);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
		localStorage.setItem('themeTabValue', newValue);
	};

	// Check user authentication
	useEffect(() => {
		const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
			const token = localStorage.getItem('token');

			if (!token || !user) {
				navigationHistory.push('/');
			} else {
				setCurrentUserID(user.uid);
			}
		});
	}, [firebaseAuth, navigationHistory]);

	// Fetch theme data when user ID changes
	useEffect(() => {
		if (currentUserID !== null) {
			fetchThemeData(currentUserID);
		}
	}, [currentUserID]);

	// Fetch theme data from API
	const fetchThemeData = async (userID) => {
		try {
			const userThemeData = await axios
				.get(`${API_URL}${userID}`)
				.then((res) => {
					let themeData = [];
					for (const project of res.data.data) {
						themeData.push(formattedThemeData(project));
					}
					setProjectsByTheme(themeData);
				});
		} catch (error) {
			console.error('Error fetching theme data:', error);
		}
	};

	// Get unique theme titles
	const uniqueThemeTitles = useMemo(() => {
		const titles = new Set(projectsByTheme.map((project) => project.name));
		return [...titles];
	}, [projectsByTheme]);

	const activeProjects = useMemo(() => {
		return projectsByTheme
			.filter((project) => project.isProcessed === false)
			.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
	}, [projectsByTheme]);

	return (
		<MainSideMenuHeaderContainer
		name={undefined}
		>
			<Heading1 title={"Themes"} />
						<TabContext value={tabValue}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: 'divider',
								}}
							>
								<TabList
									onChange={handleChange}
									aria-label='Tabs'
								>
									<Tab
										className='outline-none focus:outline-none'
										label={`Active Projects`}
										value='1'
									/>
									<TabChip
										totalCount={activeProjects.length}
									/>
									<Tab
										className='outline-none focus:outline-none'
										label='Exported Projects'
										value='2'
									/>
									<TabChip
										totalCount={
											projectsByTheme.filter(
												(individualProject) =>
													individualProject.isProcessed ===
													true
											).length
										}
									/>
								</TabList>
							</Box>

							{/* Active Projects  */}
							<TabPanel value='1'>
								<div>
									{activeProjects.length > 0 ? (
										<ProjectsGroup
											projects={activeProjects}
											setProjects={setProjectsByTheme}
										/>
									) : (
										<div className='text-center text-3xl text-gray-500 mt-16'>
											No active projects
										</div>
									)}
								</div>
							</TabPanel>
							<TabPanel value='2'>
								{uniqueThemeTitles.map((themeTitle, index) => {
									const filteredProjectsByTheme =
										projectsByTheme.filter(
											(project) =>
												project.name === themeTitle &&
												project.isProcessed === true
										);

									return (
										<div key={index}>
											{filteredProjectsByTheme.length >
												0 && (
												<div className='mb-5'>
													<h4 className='capitalize'>
														{themeTitle}
													</h4>
													<ProjectsGroup
														projects={
															filteredProjectsByTheme
														}
														setProjects={
															setProjectsByTheme
														}
													/>
												</div>
											)}
										</div>
									);
								})}
							</TabPanel>
						</TabContext>



		</MainSideMenuHeaderContainer>
	);
};

export default ProjectsByTheme;
