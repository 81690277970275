import { useRef, useEffect, useState } from "react";
import axios from "axios";


import Row from "./tableRowBags";


const BagTable = (props) =>{
  const [generatePause, setGeneratePause] = useState(false)

  const prop = props
  const handleHover = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "visible";

    const cells = Object.values(tableRow.children);
    for (let i = 0; i < cells.length; i++) {
      let textArea = Object.values(cells[i].children);

      for (let j = 0; j < textArea.length; j++) {
        if (
          textArea[j] != null &&
          (textArea[j].nodeName == "TEXTAREA" ||
            textArea[j].nodeName == "INPUT")
        ) {
          textArea[j].style.backgroundColor = "#E5E5E5";
        }
      }
    }
  };

  // Make trash button hidden when the table row is not being hovered and return cells to their original colour
  const handleMouseOut = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "hidden";

    const cells = Object.values(tableRow.children);
    for (let i = 0; i < cells.length; i++) {
      let textArea = Object.values(cells[i].children);

      for (let j = 0; j < textArea.length; j++) {
        if (
          index % 2 === 0 &&
          textArea[j] != null &&
          (textArea[j].nodeName == "TEXTAREA" ||
            textArea[j].nodeName == "INPUT")
        ) {
          textArea[j].style.backgroundColor = "white";
        } else if (
          index % 2 !== 0 &&
          textArea[j] != null &&
          (textArea[j].nodeName == "TEXTAREA" ||
            textArea[j].nodeName == "INPUT")
        ) {
          textArea[j].style.backgroundColor = "#F3F3F3";
        }
      }
    }
  };



useEffect(() => {
  if (prop.itemGenericType && prop.itemGenericType.length>0) { 
    // Taxonomy(prop.itemGenericType.slice());
  }
  if(prop.types && prop.types.length>0 ){
    let change = true;
    prop.itemName.forEach(element => {
      if(element!==""){
        change = false
      }
    });
    if(change){
      prop.setItemName(prop.types)
    }
  }
}, []);

    return (
        <table>
          <thead style={{ borderBottom: "1px solid" }}>
            <tr className="tableheader">
              {/* Item", "Product Type", "Gender", "Color", "Additional Information" */}
              {/* Item, Item Type, Brand, Size, Colours, Condition, Additional Info */}
              <th className="tablecol1"></th>
              <th className="imgcol">Item</th>
              <th>Brand</th>
              <th>Model</th>
              <th>Pattern/Materials</th>
              <th>Color(s)</th>
              <th>Condition</th>
              <th>Gender</th>              

              {/* <th>Price</th> */}
              {/* {prop.material && prop.material.length>0 ? <th>Attributes</th>:null} */}
              <th>Description</th>
            </tr>
          </thead>
          <tbody id="rows">

            {prop.s3Images.map((key, index) => {
              return prop.s3Images[index] !== undefined ? (
                <Row prop={props}
								key={index}
								index={index}
								handleHover={handleHover}
      							handleMouseOut={handleMouseOut}
                    generatePause={generatePause}
                  setGeneratePause={setGeneratePause}
	  
	  />

              ) : null;
            })}
          </tbody>
        </table>
      );
          }

export default BagTable;