import React, { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import axios from "axios";
import { SideMenu } from "../../components/sideMenu";
import { Header } from "../../components/navigation/header";
import {
  wordCount,
  characterCount,
  sentenceCount,
} from "../../components/wordCountArea";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

export function DescriptionFromSpecs(props) {

  const [noOfWordsGenerated, setNumberOfWordsGenerated] = useState();    
  const [projectName, setProjectName] = useState("");
  const [tags, setTags] = useState("");
  const [objectID, setObjectID] = useState("");
  const [prodDescription, setProdDescription] = useState([
    "Your product description will appear in about 5 seconds",
    "Your product description will appear in about 5 seconds",
  ]);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [generate, setGenerate] = useState("Generate");

  const generateButtonClick = props.generateButtonClick;
  const setGenerateButtonClick = props.setGenerateButtonClick;

  
  useEffect(()=>{    
    //communicate with the backend!
    fetch(`https://apie.snapwrite.ca/storeData/word-count?userID=${props.user}`)
    .then(response=>response.json())
    .then(response=>{
        setNumberOfWordsGenerated(response.data)
    })    
    .catch(err=>{
        console.log(`Error ${err}`)
    })
},[generate])


  useEffect(() => {
    const projectName = JSON.parse(localStorage.getItem("specToDescProjectName"));
    const tagsData = JSON.parse(localStorage.getItem("specs"));
    const prodDescriptions = JSON.parse(
      localStorage.getItem("specDescriptions")
    );
    const reGenerate = JSON.parse(localStorage.getItem("reGenerate"));
    const objectID = JSON.parse(localStorage.getItem("specDescriptionObjectID"));
    if (tagsData !== null && tags === "") {
      setProjectName(projectName)
      setTags(tagsData);
      setProdDescription(prodDescriptions);
      setGenerate(reGenerate);
      setObjectID(objectID)
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("specToDescProjectName", JSON.stringify(projectName));
    localStorage.setItem("specs", JSON.stringify(tags));
    localStorage.setItem("specDescriptions", JSON.stringify(prodDescription));
    localStorage.setItem("reGenerate", JSON.stringify(generate));
    localStorage.setItem("specDescriptionObjectID", JSON.stringify(objectID));
  }, [projectName, tags, prodDescription, generate, objectID]);

  const reset = () => {
    setProjectName("")
    if(noOfWordsGenerated>2500){
      setProdDescription([
        "Please Contact Us at contact@snapwrite.ca to increase limit",
        "Please Contact Us at contact@snapwrite.ca to increase limit",
      ])  
    }
    else{
      setProdDescription([
        "Your product description will appear in about 5 seconds",
        "Your product description will appear in about 5 seconds",
      ]); 
    }
    setTags("");
    setSelectedDescription("");
    setGenerate("Generate");
    setObjectID("")
  };

  async function genDescriptionSpecs(tags) {
    setProdDescription(["Loading...", "Loading..."]);
    try {
      setGenerateButtonClick(generateButtonClick + 1);
      setProdDescription(["Loading...", "Loading..."]);

      const res = await backendClient.post("/spec-description", {
        tags: tags,
        user: props.user,
      });
      let description = res.data;
      if (res.data[0] === "\n") {
        description = description.slice(1);
      }
      genDescriptionSpecs2(tags, description);
    } catch (e) {
      setProdDescription(["Please try again", "Please try again"]);

      console.error(e);
    }
  }

  async function genDescriptionSpecs2(tags, description1) {
    try {
      setGenerateButtonClick(generateButtonClick + 1);

      const res = await backendClient.post("/spec-description", {
        tags: tags,
        user: props.user,
      });
      let description = res.data;
      if (res.data[0] === "\n") {
        description = description.slice(1);
      }
      setProdDescription([description1, description]);

      storeDescriptions(description1, description, tags);
    } catch (e) {
      setProdDescription(["Please try again", "Please try again"]);

      console.error(e);
    }
  }

  async function reGenerateDescription(tags) {
    try {
      setProdDescription([...prodDescription, "Loading..."]);
      const res = await backendClient.post("/spec-description", {
        tags: tags,
        user: props.user,
      });
      let description = res.data;
      if (res.data[0] === "\n") {
        description = description.slice(1);
      }

      updateStoredDescriptions(description);
      setProdDescription([...prodDescription, description]);
    } catch (e) {
      console.error(e);
    }
  }

  function storeDescriptions(description1, description2, specs) {
    fetch(`https://apie.snapwrite.ca/storeData/spec-description`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: props.user,
        userName: props.userName,
        projectName: projectName,
        generatedDescriptions: [description1, description2],
        wordCount: wordCount(description1) + wordCount(description2),
        specifications: specs,
      }),
    })
      .then((response) => response.json())
      .then((response) => setObjectID(response.data._id))
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }

  function updateStoredDescriptions(reGeneratedDescription) {
    fetch(`https://apie.snapwrite.ca/storeData/spec-description`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: objectID,
        generatedDescriptions: [...prodDescription, reGeneratedDescription],
      }),
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }


  const handleChange = (event) => {
    let newTags;
    newTags = event.target.value;
    setTags(newTags);
  };
  const updateDescription = (index, event) => {
    const prodDescriptionArray = prodDescription.slice();
    // console.log("altextArray = " + altTextArray[index])
    prodDescriptionArray[index] = event.target.value;

    setProdDescription(prodDescriptionArray);
  };
  return (
    <div className="App">
       {/* {console.log("noOfWordsGenerated = " + noOfWordsGenerated)} */}
      <Header reset={reset} setProjectName={setProjectName} projectName={projectName} />
      {/* <hr width="100%;" /> */}
      {/* ******************************the <main> section****************************** */}
      <div className="interface">
        <SideMenu />
        {/* The grey section where the user can upload image, edit tags and get the description */}
        <div className="playGround">
          {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
          <div className="tools">
            <div className="drop-and-tags">
              <div className="card-spec">
                <div className="card-header"> Specifications </div>
                <div className="card-body appTagSpec ">
                  {/* **************************Tags area************************** */}
                  <div className="appTag spec-body">
                    <div className="tags-input-spec">
                      <textarea
                        name="tagTypeArea"
                        type="text"
                        value={tags}
                        onChange={(event) => handleChange(event)}
                        maxLength="550"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="tagsNumberDesc">
                      Add Specifications here
                    </div>
                    <button
                      className="btn btn-primary1"
                      disabled={(generateButtonClick > 50) || noOfWordsGenerated>2500 || prodDescription.length>4 }
                      onClick={() => {
                        if (generate === "Generate") {
                          genDescriptionSpecs(tags);
                          setGenerate("Re-Generate");
                        } else if (generate === "Re-Generate") {
                          reGenerateDescription(tags);
                        }
                      }}
                    >
                      {generate}
                    </button>
                  </div>
                </div>
              </div>
              {/* ************************************************************* */}
            </div>
            <div className="descriptionSection">
              {prodDescription.map((description, index) => {
                return (
                  <div
                    className={`card card1 card2 ${
                      description === selectedDescription
                        ? "selectedHighlight"
                        : null
                    }`}
                  >
                    <div className="card-header ">
                      Product Description {index + 1}
                    </div>
                    <div className="card-body desc-from-image">
                      {description !==
                      "Your product description will appear in about 5 seconds" ? (
                        <textarea
                          name="blogOutline"
                          type="text"
                          value={description}
                          onChange={(event) => {
                            updateDescription(index, event);
                          }}
                          style={{minHeight:"21px", height: `${((characterCount(description)/61)*23)+(description.includes('\n') ? 20 : 0)}px`}}
                          data-hj-allow
                        />
                      ) : (
                        description
                      )}
                    </div>
                    <div className="card-footer-alt">
                      <div className="wordCountArea">
                        <div className="p-2 bd-highlight">
                          Count (Character / Word / Sentence) :{" "}
                          {characterCount(description)} /{" "}
                          {wordCount(description)} /{" "}
                          {sentenceCount(description)}
                        </div>
                        <div
                          className="copyButtonIcon"
                          color="grey"
                          onClick={() => setSelectedDescription(description)}
                        >
                          select
                        </div>
                        <div
                          className="copyButtonIcon"
                          color="grey"
                          onClick={() => {
                            copy(description);
                          }}
                        >
                          copy
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DescriptionFromSpecs;
