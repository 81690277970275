import React, {useContext, useState, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import { Header } from "../components/navigation/header";
import SideMenu from "../components/sideMenu";
import Paper from '@mui/material/Paper';
import { styled, createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ThemeProvider } from "@emotion/react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import ProjectsGroup from "../components/Library/projectsGroup";
import { Autocomplete, TextField } from "@mui/material";
import { OrganizationContext } from "../components/App";
import MainSideMenuHeaderContainer from "../components/containers/MainSideMenuHeaderContainer";
import { Heading1 } from "../components/branded-elements/Elements";

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#e2fd00',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#e2fd00',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#f9fafa", //theme.palette.action.hover,
  },
	'&:hover': {
		cursor: "pointer"
	},
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SearchBar = ({vendors, setFilterVendor}) => {
	const navigate = useHistory();

	return (
		<Autocomplete
			disablePortal
			disableClearable
			id="brand-autocomplete"
			options={Object.keys(vendors)}
			defaultValue={"Filter by Vendor"}
			// getOptionLabel={option => option.displayName}
			sx={{ width: 300, fontSize: "normal", paddingBottom: 2}}
			size="small"
			// getOptionSelected={(option, value) => option.label === value.label}
			// isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
			onChange={(e, value) => {
				navigate.push({
					search: `?v=${value.split(" ").join("-")}`
					})
				}
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Filter by Vendor"
					sx={{ fontSize: "normal" }}
				/>
			)}
		/>
	);
}

const endpoints = ['labeling-clothes-categorization', 'regular-preLoved-apparel', 'vintage-apparel', 'new-apparel'];
const paths = ['clothing-sort-view', 'preloved-regular-view', 'vintage-apparel-view', 'new-apparel-view'];

export const FilterVendor = (props) => {
	const { organization, setOrganization } = useContext(OrganizationContext);
	const [vendors, setVendors] = useState({});
	const filterVendor = new URLSearchParams(props.location.search).get('v');

	const [sortedClothingLabels, setSortedClothingLabels] = useState([]);
	const [regularPreLovedApparel, setRegularPreLovedApparel] = useState([]);
	const [vintageApparel, setVintageApparel] = useState([]);
	const [newApparel, setNewApparel] = useState([]);

	let userID;
	const auth = getAuth();
	const user = auth.currentUser;

	if (user !== null) {
		userID = auth.currentUser.email;
	}

	function handleChangeVendor() {
		if (Object.keys(vendors).length === 0 || !filterVendor) return;
		setSortedClothingLabels(
			vendors[filterVendor]['labeling-clothes-categorization']
		);
		setRegularPreLovedApparel(
			vendors[filterVendor]['regular-preLoved-apparel']
		);
		setVintageApparel(vendors[filterVendor]['vintage-apparel']);
		setNewApparel(vendors[filterVendor]['new-apparel']);
	}

	useEffect(handleChangeVendor, [vendors, filterVendor]);

	useEffect(() => {
		// Load all users and project data during start
		if (!organization?.id) return;

		const json = [{ uid: organization?.id }];
		Promise.all(
			json.map((d) => {
				// For each user
				return Promise.all(
					endpoints.map((endpoint) => {
						return fetch(
							`https://apie.snapwrite.ca/storeData/${endpoint}?userID=${d.uid}`
						)
							.then((response) => response.json())
							.then((json) => json.data);
					})
				);
			})
		).then((data) => {
			let newVendors = {};
			data[0].forEach((endpoint, index) => {
				endpoint.forEach((project) => {
					project['vendor'].forEach((vendor) => {
						try {
							vendor = vendor.split(' ').join('-');
						} catch (error) {
							console.error(error);
							return;
						}
						if (!newVendors.hasOwnProperty(vendor)) {
							let newVendorObj = {};
							endpoints.forEach((ep) => {
								newVendorObj[ep] = [];
							});
							newVendors[vendor] = newVendorObj;
						}
						newVendors[vendor][endpoints[index]].push({
							...project,
							endpoint,
							path: paths[index],
						});
					});
				});
			});
			setVendors(newVendors);
		});
	}, [organization]);

	const { caller } = useState('projectsView');

	const display = () => {
		return (
			<div className="min-h-[71vh]">
				{userID === 'hello@shopcherry.ca' ||
				userID === 'classiquegrails@gmail.com' ||
				userID === 'impeccablevintage@gmail.com' ||
				userID === 'cherrypickdummy@snapwritetest.ca' ? (
					<>
						<ProjectsGroup
							title='Vintage Apparel'
							projects={vintageApparel}
							setProjects={setVintageApparel}
						/>
						<ProjectsGroup
							title='Clothing - Kids'
							projects={sortedClothingLabels}
							setProjects={setSortedClothingLabels}
						/>
						<ProjectsGroup
							title='Pre Loved (Regular)'
							projects={regularPreLovedApparel}
							setProjects={setRegularPreLovedApparel}
						/>
						<ProjectsGroup
							title='New Apparel'
							projects={newApparel}
							setProjects={setNewApparel}
						/>
					</>
				) : (
					<>
						<ProjectsGroup
							title='Clothing - Kids'
							projects={sortedClothingLabels}
							setProjects={setSortedClothingLabels}
						/>
						<ProjectsGroup
							title='Pre Loved (Regular)'
							projects={regularPreLovedApparel}
							setProjects={setRegularPreLovedApparel}
						/>
						<ProjectsGroup
							title='Vintage Apparel'
							projects={vintageApparel}
							setProjects={setVintageApparel}
						/>
						<ProjectsGroup
							title='New Apparel'
							projects={newApparel}
							setProjects={setNewApparel}
						/>
					</>
				)}

				{/* {(userID==="athiya.rastogi@gmail.com" ||userID==="aryamanrastogi01@gmail.com" )?<>
            <ProjectsGroup title="Categorized Labels" projects={sortedLabels} setProjects={setSortedLabels}/>
            <ProjectsGroup title="Product Description from Images" projects={imageDescriptions} setProjects={setImageDescriptions}/> 
          </>
          :null}
         */}

				{/* Archived project  */}
				<ProjectsGroup
					title='Clothing'
					archived
					projects={sortedClothingLabels}
					setProjects={setSortedClothingLabels}
				/>
				<ProjectsGroup
					title='Pre Loved (Regular)'
					archived
					projects={regularPreLovedApparel}
					setProjects={setRegularPreLovedApparel}
				/>
				<ProjectsGroup
					title='Vintage Apparel'
					archived
					projects={vintageApparel}
					setProjects={setVintageApparel}
				/>
				<ProjectsGroup
					title='New Apparel'
					archived
					projects={newApparel}
					setProjects={setNewApparel}
				/>
				{/* {(userID==="athiya.rastogi@gmail.com" ||userID==="aryamanrastogi01@gmail.com" )?<>
            <ProjectsGroup title="Categorized Labels" archived projects={sortedLabels} setProjects={setSortedLabels}/>
            <ProjectsGroup title="Product Description from Images" archived projects={imageDescriptions} setProjects={setImageDescriptions}/>
          </>
          :null} */}
			</div>
		);
	};

	return (
		<MainSideMenuHeaderContainer
		name={undefined}
		>
			{/* The grey section where the user can upload image, edit tags and get the description */}
			<div
				className='playGround playGroundProjects'
				style={{ paddingRight: 50 }}
			>
				<div style={{ position: 'relative' }}>
					<Heading1 title={"Vendors"} />
					<br />
					<SearchBar vendors={vendors} />
					<br />
					{display()}
				</div>
			</div>
		</MainSideMenuHeaderContainer>
	);
}
