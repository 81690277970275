import {
  Autocomplete,
  Chip,
  Grid,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import React from "react";

const ListingCustomization = ({
  userID,
  titleTemplate,
  setTitleTemplate,
  titlePreview,
  descriptionTemplate,
  setDescriptionTemplate,
  descriptionPreview,
  metaData,
  setMetaData,
}) => {
  const titleVariables = [
    { title: "Item Name" },
    { title: "Brand" },
    { title: "Era" },
    { title: "Color" },
    { title: "Size" },
    { title: "Type" },
    { title: "Gender" },
  ];

  const titleVariablesArray = [
    "Item Name",
    "Brand",
    "Era",
    "Color",
    "Size",
    "Type",
    "Gender",
  ];

  const descriptionVariables = [
    { title: "Brand" },
    { title: "Era" },
    { title: "Color" },
    { title: "Size" },
    { title: "Type" },
    // {title: "Description" },
    { title: "Description SEO" },
    { title: "Condition" },
    { title: "Gender" },
    { title: "Measurements" },
  ];

  const descriptionVariablesArray = [
    "Brand",
    "Era",
    "Color",
    "Size",
    "Type",
    // "Description",
    "Description SEO",
    "Condition",
    "Gender",
    "Measurements",
  ];

  const metadataOptions = [
    "Item Name",
    "Brand",
    "Era",
    "Color",
    "Size",
    "Type",
    "Gender",
  ];

  const saveTitle = () => {
    const arr = [];

    titleTemplate.forEach((val) => {
      const obj = { value: val };
      if (titleVariablesArray.includes(val)) {
        obj["type"] = "variable";
      } else {
        obj["type"] = "manual";
      }
      arr.push(obj);
    });

    console.log(arr);
    axios
      .put("https://apie.snapwrite.ca/storeData/csv-title", {
        titleBlocks: arr,
        userID: userID,
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  const saveDescription = () => {
    const arr = [];

    descriptionTemplate.forEach((val) => {
      const obj = { value: val };
      if (descriptionVariablesArray.includes(val)) {
        obj["type"] = "variable";
      } else {
        obj["type"] = "manual";
      }
      arr.push(obj);
    });
    axios
      .put("https://apie.snapwrite.ca/storeData/csv-title", {
        descriptionBlocks: arr,
        userID: userID,
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  const handleMetaDataInput = (newValue, index, key) => {
    setMetaData((prevMetaData) => {
      return prevMetaData.map((item, i) => {
        if (i === index) {
          return { ...item, [key]: newValue };
        }
        return item;
      });
    });
  };

  const saveMetadata = () => {
    const body = {
      userID: userID,
      name: "metadata",
      val: metaData,
    };
    axios
      .put(
        "https://apie.snapwrite.ca/storeData/user-preferences-updateVal",
        body
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className=" text-lg my-2"> Custom Title</h2>
      <Grid container spacing={5} sx={{ paddingTop: "10px" }}>
        <Grid item xs={6}>
          <Stack>
            <Autocomplete
              multiple
              id="tags-filled"
              value={titleTemplate}
              isOptionEqualToValue={() => false}
              options={titleVariables.map((option) => option.title)}
              freeSolo
              onChange={(event, list, reason, detail) => {
                setTitleTemplate(list);
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Title Template" />
              )}
            />
          </Stack>
          <Button
            variant="contained"
            sx={{
              marginTop: 3,
              fontSize: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.38)",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": { color: "white", boxShadow: "none" },
            }}
            onClick={() => saveTitle()}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Title Preview"
            variant="outlined"
            fullWidth
            value={titlePreview}
          />
        </Grid>
        <Grid item xs={12}>
          <div className=" h-[0.5px] bg-slate-400"></div>
        </Grid>

        <Grid item xs={6}>
          <h2 className=" text-lg mt-2 mb-4">Custom Description</h2>

          <Stack>
            <Autocomplete
              multiple
              id="tags-filled"
              value={descriptionTemplate}
              isOptionEqualToValue={() => false}
              options={descriptionVariables.map((option) => option.title)}
              freeSolo
              onChange={(event, list, reason, detail) => {
                setDescriptionTemplate(list);
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Description Template" />
              )}
            />
          </Stack>
          <Button
            variant="contained"
            color="primary"
            onClick={saveDescription}
            sx={{
              marginTop: 3,
              fontSize: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.38)",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": { color: "white", boxShadow: "none" },
            }}
          >
            Save
          </Button>
        </Grid>

        <Grid item xs={6}>
          <TextField
            multiline
            id="outlined-basic"
            label="Description Preview"
            variant="outlined"
            sx={{ marginTop: 7.5 }}
            fullWidth
            value={descriptionPreview}
          />
        </Grid>
        <Grid item xs={12}>
          <div className=" h-[0.5px] bg-slate-400"></div>
        </Grid>

        <Grid item xs={12}>
          <h2 className=" text-lg mt-2">Custom Metadata</h2>
        </Grid>

        {metaData.map((metadata, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Metadata Value"
                variant="outlined"
                sx={{ marginTop: 0 }}
                fullWidth
                value={metadata.value}
                onChange={(e) => {
                  handleMetaDataInput(e.target.value, index, "value");
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                sx={{ marginTop: 0 }}
                id="tags-filled"
                value={metaData[index].name}
                options={metadataOptions}
                // freeSolo
                onChange={(event, value) => {
                  handleMetaDataInput(value, index, "name");
                }}
                onBlur={(e) =>
                  handleMetaDataInput(e.target.value, index, "name")
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select Metadata Field" />
                )}
              />
            </Grid>
          </React.Fragment>
        ))}
        <Grid xs={6} sx={{ marginLeft: 5, marginTop: 3 }}>
          {" "}
          <Button
            variant="contained"
            onClick={saveMetadata}
            sx={{
              fontSize: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.38)",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": { color: "white", boxShadow: "none" },
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ListingCustomization;
