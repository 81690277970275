const lapel = [
    "Notch",
    "Flap",
    "Revers",
    "Flare",
    "Peak",
    "Fold",
    "Wing",
    "Label",
    "lapel"
  ];
  
  const shirt = [
    "Dress",
    "Oxford",
    "Formal",
    "Elegant",
    "Classy",
    "Sophisticated",
    "Refined",
    "Upscale",
    "Chic",
    "Dapper",
    "Posh",
    "Regal",
    "Luxurious",
    "shirt"
  ];
  
  const large = [
    "Big",
    "Oversized",
    "Bulky",
    "Broad",
    "Wide",
    "Oversized",
    "Generous",
    "Expansive",
    "Voluminous",
    "Substantial",
    "Ample",
    "Sizeable",
    "Roomy",
    "Massive",
    "Extensive",
    "Enlarged",
    "Spacious",
    "Considerable",
    "Grand",
    "large"
  ];
  
  const notch = [
    "Indent",
    "Groove",
    "Cut",
    "Nick",
    "Slit",
    "Kerf",
    "Incision",
    "Cleft",
    "notch"
  ];
  
  const high = [
    "Tall",
    "Elevated",
    "Raised",
    "Stiff",
    "Standing",
    "Upstanding",
    "Towering",
    "Lofty",
    "high"
  ];
  
  const vneck = [
    "Plunge",
    "Deep",
    "Low-cut",
    "Decollete",
    "Diagonal",
    "Cutout",
    "Angular",
    "Scoop",
    "Reveal",
    "V neck",
    "v-neck"
  ];
  
  const crewneck = [
    "Round",
    "U-neck",
    "U neck",
    "Circular",
    "Jewel",
    "O-neck",
    "crewneck",
    "crew neck",
    "crew"
  ];

  export const collarType = [lapel, shirt, large, notch, high, vneck, crewneck]