import React, { useState, useEffect } from 'react'
import { useParams } from "react-router";
import { getAuth } from '@firebase/auth';
import MultiStep from '../../blogs/StepIndicator';
import Blog from '../../../products/blogs/blogImageToTitle'
import BlogTitleToOutline from '../../../products/blogs/blogTitleToOuline';
import BlogOutline from '../../../products/blogs/blogOutline';
import { Header } from '../../navigation/header'
import SideMenu from '../../sideMenu'

// custom styles
const prevStyle = { background: '#33c3f0' }
const nextStyle = { background: '#33c3f0' }
    
const ImageToBlogDataView = ({history}) => {

    const {id} = useParams();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token) {
            history.push('/')
        }
    },[])

    const auth = getAuth();
    const user = auth.currentUser;
    let userID;
    let userName;

    
    if (user !== null) {
      userID= user.uid;
        user.providerData.forEach((profile) => {            
            userID = user.uid;
            userName = profile.displayName
        });
      }

    const [objectID, setObjectID] = useState("")        
    const [projectName, setProjectName] = useState("");


    const [blogTitles, setBlogTitles] = useState(
        ["Blog title will appear in about 10 seconds"]
    );

    const [blogTitle, setBlogTitle] = useState("Title will be here in a few seconds");

    const [blogOutline, setBlogOutline] = useState("Your blog outline will appear in about 5 seconds");

    const [image, setImage] = useState("");
    const [suggestedTags, setSuggestedTags] = useState([""]);
    const [tags, setTags] = useState([]);
    const [activeState, setActiveState] = useState(2)

    const [blogIntro, setBlogIntro] = useState("Blog's introduction will appear here");
    const [blogParaOne, setBlogParaOne] = useState("Blog's paragraph one will appear here");
    const [blogParaTwo, setBlogParaTwo] = useState("Blog's paragraph two will appear here");
    const [blogParaThree, setBlogParaThree] = useState("Blog's paragraph three will appear here");
    const [blogParaFour, setBlogParaFour] = useState("Blog's paragraph four will appear here");
    const [blogParaFive, setBlogParaFive] = useState("Blog's paragraph five will appear here");
    const [blogConclusion, setBlogConclusion] = useState("Blog's conclusion will appear here");
    
    const [completeBlog, setCompleteBlog] = useState("")
        

    
    useEffect(()=>{        
        //communicate with the backend!
        fetch(`https://apie.snapwrite.ca/storeData/image-blog/${id}`)
        .then(response=>response.json())
        .then(json=>{
            //We updated the data returned from the Backed with the resort state
            setObjectID(json.data._id)
            setProjectName(json.data.projectName);
            setImage(json.data.imageUploaded);
            setSuggestedTags(json.data.suggestedTags);
            setTags(json.data.tagsUsed);
            setBlogTitles(json.data.generatedBlogTitles);
            setBlogTitle(json.data.blogTitleUsed);
            setBlogOutline(json.data.blogOutlineUsed);
            setBlogIntro(json.data.blogIntro);
            setBlogParaOne(json.data.blogPara1);
            setBlogParaTwo(json.data.blogPara2);
            setBlogParaThree(json.data.blogPara3);
            setBlogParaFour(json.data.blogPara4);
            setBlogParaFive(json.data.blogPara5);
            setBlogConclusion(json.data.blogConclusion);
            setCompleteBlog(json.data.completeBlog);
        })
        .catch(err=>{
            console.log(`Error ${err}`)
        })
    },[])
  


    const [titleGenerateClick, setTitleGenerateClick] =useState(0);
    const [outlineGenerateClick, setOutlineGenerateClick] =useState(0);
    const [paragraphGenerateClick, setParagraphGenerateClick] =useState(0);
    const [conclusionGenerateClick, setConclusionGenerateClick] = useState(50);

    const MINUTE_MS = 60000;

    useEffect(() => {
      const interval = setInterval(() => {
        setTitleGenerateClick(0);
        setOutlineGenerateClick(0);
        setParagraphGenerateClick(0);        
        setConclusionGenerateClick(0);
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
  
    const steps = [
        { 
            component: <Blog      
                        noOfWordsGenerated={20001}                   
                        blogTitles={blogTitles}
                        setBlogTitles={setBlogTitles}
                        blogTitle={blogTitle}
                        setBlogTitle={setBlogTitle}
                        blogOutline={blogOutline}
                        setBlogOutline={setBlogOutline}
                        image={image}
                        setImage={setImage}
                        suggestedTags={suggestedTags}
                        setSuggestedTags={setSuggestedTags}
                        tags={tags}
                        setTags={setTags}
                        setTitleGenerateClick={setTitleGenerateClick}
                        titleGenerateClick = {titleGenerateClick}
                        outlineGenerateClick={outlineGenerateClick}
                        setOutlineGenerateClick={setOutlineGenerateClick}
                        user={userID} userName={userName}
                        activeState = {activeState}
                        setActiveState = {setActiveState}
                       /> 
        },
        { component: <BlogTitleToOutline
                        setObjectID={setObjectID}
                        noOfWordsGenerated={20001}
                        projectName={projectName}
                      blogTitle={blogTitle}
                      setBlogTitle={setBlogTitle}
                      blogOutline={blogOutline}
                      setBlogOutline={setBlogOutline}
                      setBlogIntro={setBlogIntro}
                      setBlogParaOne={setBlogParaOne}
                      setBlogParaTwo={setBlogParaTwo}
                      setBlogParaThree={setBlogParaThree}
                      setBlogParaFour={setBlogParaFour}
                      setBlogParaFive={setBlogParaFive}
                      setBlogConclusion={setBlogConclusion}  
                      blogIntro={blogIntro}
                      blogParaOne={blogParaOne}
                      blogParaTwo={blogParaTwo}
                      blogParaThree={blogParaThree}
                      blogParaFour={blogParaFour}
                      blogParaFive={blogParaFive}
                      blogConclusion={blogConclusion}                    
                      paragraphGenerateClick={paragraphGenerateClick}
                      setParagraphGenerateClick={setParagraphGenerateClick}                      
                      conclusionGenerateClick={conclusionGenerateClick}
                      setConclusionGenerateClick={setConclusionGenerateClick}
                      activeState = {activeState}
                      imageOrIdea="image"
                      setActiveState = {setActiveState}
                      user={userID} userName={userName}
                      suggestedTags={suggestedTags}
                      tags={tags}
                      blogTitles={blogTitles}
                      image={image}
                     /> 
        },
        { component: <BlogOutline
                        imageOrIdea="image"
                        objectID={objectID}
                        completeBlog={completeBlog}
                        setCompleteBlog={setCompleteBlog}
                      blogTitle={blogTitle}
                      setBlogTitle={setBlogTitle}
                      blogOutline={blogOutline}
                      setBlogOutline={setBlogOutline} 
                      user={userID} userName={userName} 
                      blogIntro={blogIntro}
                      blogParaOne={blogParaOne}
                      blogParaTwo={blogParaTwo}
                      blogParaThree={blogParaThree}
                      blogParaFour={blogParaFour}
                      blogParaFive={blogParaFive}
                      blogConclusion={blogConclusion}
                      />  },    
      ]  

return(
  <div className="App">
            <Header  setProjectName={setProjectName} projectName={projectName}/>            
            {/* <hr width="100%;" /> */}
            {/* ******************************the <main> section****************************** */}
            <div className="interface">
                <SideMenu />
                {/* The grey section where the user can upload image, edit tags and get the description */}
                <div className="playGround"> 
                <MultiStep
                activeStep={activeState}
                setActiveStep={setActiveState}
                steps={steps} 
                prevStyle={prevStyle} 
                nextStyle={nextStyle} />
            </div>
        </div>
    </div>
)
}
export default ImageToBlogDataView;