import React, { useEffect, useState, useContext } from 'react';
import { getAuth } from '@firebase/auth';
import { useHistory, useParams } from 'react-router-dom';
import MainSideMenuHeaderContainer from '../components/containers/MainSideMenuHeaderContainer';
import {
	Heading1,
	PrimaryButton,
} from '../components/branded-elements/Elements';
import { Add, Close, Upload } from '@mui/icons-material';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { OrganizationContext } from '../components/App';
import DragAndDropGallery from '../components/dragAndDropGallery';

const LOCALHOST = 'http://localhost:3500';
const PRODUCTION = 'https://apie.snapwrite.ca';
const rootEndpoint = PRODUCTION;

const containerStyle = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fxill, 200px)',
	gap: 30,
	justifyContent: 'center',
	marginTop: 20,
	marginBottom: 80,
};

function MagentoProjectHubMM() {
	const auth = getAuth();
	const history = useHistory();
	const { styleCode } = useParams();
	const [projectData, setProjectData] = useState(null);
	const [isUploading, setIsUploading] = useState({
		index: null,
		status: false,
	});
	const { organization } = useContext(OrganizationContext);
	const { id: userID } = organization || { id: null };

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			const token = localStorage.getItem('token');

			if (!token || !user) {
				history.push('/');
			}
		}, []);
	}, []);

	const extractColorDescription = (name) => {
		const regex = /\(([^)]+)\)$/;
		const match = name?.match(regex);
		return match?.[1]?.split(' ').slice(-2).join(' ') || null;
	};

	function addDefaultVariant(projectArray) {
		const firstProject = projectArray[0];

		if (!firstProject.variants) {
			firstProject.variants = {};
		}

		if (!firstProject.variants.Default) {
			const DEFAULT = {
				images: [],
			};
			firstProject.variants.Default = DEFAULT;
		}
		// Ensure default variant is first
		const defaultVariant = firstProject.variants.Default;
		delete firstProject.variants.Default;
		firstProject.variants = {
			Default: defaultVariant,
			...firstProject.variants,
		};

		return [firstProject];
	}

	useEffect(() => {
		const fetchProjectData = async () => {
			if(userID && styleCode) {
				try {
					const response = await axios.get(
						`${rootEndpoint}/mm/photos/${styleCode}/${userID}`
					);
					const projectData = response.data.data;
					if (projectData) {
						const modifiedProjectData = addDefaultVariant(projectData);
						setProjectData([...modifiedProjectData]);
					}
				} catch (error) {
					console.error('Error fetching project data:', error);
				}
			}			
		};

		fetchProjectData();
	}, [styleCode, userID]);

	const handleImageUpload = async (event, product, color, idx) => {
		setIsUploading({ index: idx, status: true });

		if (!event || !event.target || !event.target.files) {
			console.log('No event or files selected');
			return;
		}

		const body = {
			color: color,
			images: projectData[0].variants[color].images || [],
			_id: projectData[0]._id,
		};

		const files = Array.from(event.target.files);
		try {
			for (const file of files) {
				const { name = '' } = file;

				const response = await fetch(
					'https://apie.snapwrite.ca/storeData/s3Url'
				);
				if (!response.ok) {
					throw new Error(
						`Failed to fetch S3 URL, status ${response.status}`
					);
				}

				const { url } = await response.json();
				if (!url) {
					throw new Error('Failed to get S3 URL');
				}

				const options = {
					method: 'PUT',
					headers: {
						'Content-Type': 'image/jpeg',
					},
					body: file,
				};

				const uploadResponse = await fetch(url, options);
				if (!uploadResponse.ok) {
					throw new Error(
						`Failed to upload image, status ${uploadResponse.status}`
					);
				}

				if (idx !== undefined) {
					body.images[idx] = url.split('?')[0];
				} else {
					body.images.push(url.split('?')[0]);
				}
			}

			const response = await axios.put(
				`${rootEndpoint}/mm/photos/`,
				body
			);

			setIsUploading({ index: null, status: false });

			setProjectData((prevData) => {
				const newData = prevData ? [...prevData] : [];
				newData[0].variants[color].images = body.images;
				return newData;
			});
		} catch (err) {
			setIsUploading({ index: null, status: false });
			console.error('Error uploading image:', err);
		}
	};

	const handleImageDelete = async (color, idx) => {
		if (!projectData) {
			console.error('No project data');
			return;
		}

		const newData = [...projectData];
		const variant = newData[0].variants[color];
		if (!variant) {
			console.error(`No variant for color ${color}`);
			return;
		}

		const images = variant.images || [];
		if (idx >= images.length) {
			console.error(
				`Invalid index ${idx} for images of length ${images.length}`
			);
			return;
		}

		images[idx] = '';

		const body = {
			color,
			images,
			_id: newData[0]._id,
		};

		try {
			const response = await axios.put(
				`${rootEndpoint}/mm/photos/`,
				body
			);
			if (response.status === 200) {
				setProjectData(newData);
			}
		} catch (err) {
			console.error('Error deleting image:', err);
		}
	};

	const sendDataToMagento = async () => {
		await axios
			.post(`${rootEndpoint}/mm/send-photos/`, {
				sku: `${styleCode}`,
				userID: userID,
			})
			.then(() => alert('done'));
	};

	if (!projectData) return null;

	return (
		<MainSideMenuHeaderContainer>
			<div style={{ display: 'flex' }}>
				<Heading1 title={styleCode} />
				<PrimaryButton
					title={'Sync'}
					className='ml-4'
					onClick={() => {
						sendDataToMagento();
					}}
				/>
			</div>
			<br/>
			<h2>You can now drag and drop to upload and rearrange images</h2>		
			<section className='flex flex-col gap-2 mt-6'>
				{Object.keys(projectData[0].variants).map(
					(color, colorIndex) => (
						<div key={colorIndex}>
							<h3>{color.toUpperCase()}</h3>
							<div className='flex mt-3 mb-3 gap-5 flex-wrap'>
								{projectData[0].variants[color].images &&
									<DragAndDropGallery 
										backendReqBody={{color: color, _id: projectData[0]._id, 
														 images: projectData[0].variants[color].images}} 
										backendImageArrKeyName={"images"}
										backendEndpoint={`${rootEndpoint}/mm/photos/`}
										images={projectData[0].variants[color].images}
										rowIndex={colorIndex} />
									}
							</div>
						</div>
					)
				)}
			</section>
		</MainSideMenuHeaderContainer>
	);
}

export default MagentoProjectHubMM;
