import { useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Provider } from "@shopify/app-bridge-react";
// import { Banner, Layout, Page } from "@shopify/polaris";

/**
 * A component to configure App Bridge.
 * @desc A thin wrapper around AppBridgeProvider that provides the following capabilities:
 *
 * 1. Ensures that navigating inside the app updates the host URL.
 * 2. Configures the App Bridge Provider, which unlocks functionality provided by the host.
 *
 * See: https://shopify.dev/apps/tools/app-bridge/getting-started/using-react
 */
export function AppBridgeProvider({ children }) {
  const location = useLocation();
  const navigate = useHistory();
  const history = useMemo(
    () => ({
      replace: (path) => {
        navigate.push(path, { replace: true });
      },
    }),
    [navigate]
  );

  const routerConfig = useMemo(
    () => ({ history, location }),
    [history, location]
  );

  // The host may be present initially, but later removed by navigation.
  // By caching this in state, we ensure that the host is never lost.
  // During the lifecycle of an app, these values should never be updated anyway.
  // Using state in this way is preferable to useMemo.
  // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
  const [appBridgeConfig] = useState(() => {
    const host = new URLSearchParams(location.search).get("host");

    return {
      host,
      apiKey: "2fb5a2077c221e29f63d9ad8ac00df36",
      forceRedirect: false,
    };
  });

  // if (!process.env.SHOPIFY_API_KEY || !appBridgeConfig.host) {
  //   const bannerProps = !process.env.SHOPIFY_API_KEY
  //     ? {
  //         title: "Missing Shopify API Key",
  //         children: (
  //           <>
  //             Your app is running without the SHOPIFY_API_KEY environment
  //             variable. Please ensure that it is set when running or building
  //             your React app.
  //           </>
  //         ),
  //       }
  //     : {
  //         title: "Missing host query argument",
  //         children: (
  //           <>
  //             Your app can only load if the URL has a <b>host</b> argument.
  //             Please ensure that it is set, or access your app using the
  //             Partners Dashboard <b>Test your app</b> feature
  //           </>
  //         ),
  //       };


	// let text ='';
	// console.log(process.env.SHOPIFY_API_KEY)
  // if (!process.env.SHOPIFY_API_KEY || !appBridgeConfig.host) {
  //   const bannerProps = !process.env.SHOPIFY_API_KEY
	// 	? text = `
  //             Your app is running without the SHOPIFY_API_KEY environment
  //             variable. Please ensure that it is set when running or building
  //             your React app.
	// 	`
	// 	: text = `
  //             Your app can only load if the URL has a <b>host</b> argument.
  //             Please ensure that it is set, or access your app using the
  //             Partners Dashboard <b>Test your app</b> feature
  //           </>
	// 	`
	// 	console.log(text)
  //   return (
	// 		<h1>{text}</h1>
  //   );
  // }

	if (!appBridgeConfig.host) {
		return (
			<>
				{children}
			</>
		)
	}

  return (
    // <Provider config={appBridgeConfig} router={routerConfig}>
    <Provider config={appBridgeConfig}>
      {children}
    </Provider>
  );
}