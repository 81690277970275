import React, { useEffect, useState } from "react";
import SortedClothingLabelsPage from '../shopifyClothings.js'
import { getAuth, signInWithRedirect, signInWithPopup, getRedirectResult, GoogleAuthProvider } from "@firebase/auth";
import { useParams } from "react-router";
import axios from 'axios';
import { Header } from "../../../navigation/header.js";
import SideMenu from "../../../sideMenu.js";


export function VendorView(props, {history}) {
	const template = new URLSearchParams(props.location.search).get("t")
	const filterVendor = new URLSearchParams(props.location.search).get("v")


  const auth = getAuth();
  const user = auth.currentUser;

  const [signedIn, setSignedIn] = useState(false)
  // const [proceed, setProceed] = useState(false)
  const [userID, setUserID] = useState()
  const { id } = useParams()
	const formLink = `/vendor-view/${id}?t=${template}&v=${filterVendor}`;
  localStorage.setItem("accountType", "seller")

	useEffect(() => {
    const auth = getAuth();
    const token = localStorage.getItem("token");
  
    const handleGoogle = async () => {
      const provider = new GoogleAuthProvider();
      try {
        signInWithRedirect(auth, provider);
      } catch (error) {
        console.error(error);
        localStorage.removeItem("token");
      }
    };
  
    const handleRedirectResult = async () => {
      const result = await getRedirectResult(auth);
      if (!result && !token) {
        handleGoogle();
      }
      else {
        if (!token) {
          localStorage.setItem("token", result.user.accessToken)
        }
        setSignedIn(true)
        setUserID(auth.currentUser.uid);
        console.log(auth.currentUser.uid)
        axios.put("https://apie.snapwrite.ca/storeData/user-preferences-add-form", 
					{ 
						userID: auth.currentUser.uid, 
						email: auth.currentUser.email, 
						displayName: auth.currentUser.displayName, 
						form: formLink
					}
				)
        .then(res => {
          console.log(res)
        })
        .catch(err => console.error(err))
        
      }
    };
  
    handleRedirectResult();
  }, []);
  



  return (               
    <div className="newview">
      <Header hideButton/>
      {/** showPopup && <GoogleSignUpButton setShowPopup={setShowPopup} /> */}
      <div style={{ display: "flex"}}>
        <SideMenu />
    {signedIn ?
    <SortedClothingLabelsPage
      id={id}
      userID={userID}
      template={template}
			isVendor={true}
			filterVendor={filterVendor}
  /> :null
    }
    </div>              
    </div>              
                                                     
  );
}
export default VendorView;
