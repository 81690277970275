import React, { useState } from "react";
import copy from "copy-to-clipboard";
import axios from "axios";
import { wordCount } from "../../components/wordCountArea";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

export function BlogTitleToOutline(props) {
  const userID = props.user;
  const userName = props.userName;
  const blogTitle = props.blogTitle;
  const setBlogTitle = props.setBlogTitle;
  const blogOutline = props.blogOutline;
  const setBlogOutline = props.setBlogOutline;
  const setBlogIntro = props.setBlogIntro;
  const setBlogParaOne = props.setBlogParaOne;
  const setBlogParaTwo = props.setBlogParaTwo;
  const setBlogParaThree = props.setBlogParaThree;
  const setBlogParaFour = props.setBlogParaFour;
  const setBlogParaFive = props.setBlogParaFive;
  const setBlogConclusion = props.setBlogConclusion;
  const paragraphGenerateClick = props.paragraphGenerateClick;
  const setParagraphGenerateClick = props.setParagraphGenerateClick;
  const conclusionGenerateClick = props.conclusionGenerateClick;
  const setConclusionGenerateClick = props.setConclusionGenerateClick;
  const setActiveState = props.setActiveState;
  const imageOrIdea=props.imageOrIdea;
  
  async function generateBlog(blogTitle, blogOutline) {
    try {
      if(props.noOfWordsGenerated>250000){
        setBlogIntro("contact us to increase limit");      
      }
      else{

        setParagraphGenerateClick(paragraphGenerateClick+1)
        setActiveState(2)
        const outline = blogOutline.split('•')
        // console.log(outline)
        setBlogIntro("Loading...");      
        // console.log(blogTitle)
        const res = await backendClient.post("/blog-intro", {
          title: blogTitle,        
          user: props.user
        });            
        setBlogIntro(res.data);      
        generateBlogPara1(outline, res.data);
        // generateBlogConclusion(blogTitle);
      }
    } catch (e) {
      setBlogIntro("");
      console.error(e);
    }
  }

  async function generateBlogPara1(blogOutline, intro) {
    try {      
      setParagraphGenerateClick(paragraphGenerateClick+1)
      setBlogParaOne("Loading...");      
      const res = await backendClient.post("/blog-para-one", {
        title: blogOutline[1],        
        user: props.user
      });      
      setBlogParaOne(res.data);  
      generateBlogPara2(blogOutline, intro,res.data);
    } catch (e) {
      setBlogParaOne("");
      console.error(e);
    }
  }
  async function generateBlogPara2(blogOutline, intro, para1) {
    try {      
      setParagraphGenerateClick(paragraphGenerateClick+1)
      setBlogParaTwo("Loading...");      
      const res = await backendClient.post("/blog-para-two", {
        title: blogOutline[2],        
        user: props.user
      });      
      setBlogParaTwo(res.data);   
      generateBlogPara3(blogOutline, intro, para1, res.data);
    } catch (e) {
      setBlogParaTwo("");
      console.error(e);
    }
  }
  async function generateBlogPara3(blogOutline, intro, para1, para2) {
    try {    
      setParagraphGenerateClick(paragraphGenerateClick+1)
      setBlogParaThree("Loading...");      
      const res = await backendClient.post("/blog-para-three", {
        title: blogOutline[3],        
        user: props.user
      });      
      setBlogParaThree(res.data);    
      generateBlogPara4(blogOutline, intro, para1, para2, res.data);
    } catch (e) {
      setBlogParaThree("");
      console.error(e);
    }
  }
  async function generateBlogPara4(blogOutline, intro, para1, para2, para3) {
    try {      
      setParagraphGenerateClick(paragraphGenerateClick+1)
      setBlogParaFour("Loading...");      
      const res = await backendClient.post("/blog-para-four", {
        title: blogOutline[4],        
        user: props.user
      });      
      setBlogParaFour(res.data);
      if(!blogOutline[5]){            
        setBlogParaFive("");
        generateBlogConclusion(blogTitle, intro, para1, para2, para3, res.data, "");
      }
      else{
        generateBlogPara5(blogOutline, intro, para1, para2, para3, res.data);
      }
    } catch (e) {
      setBlogParaFour("");
      console.error(e);
    }
  }
  async function generateBlogPara5(blogOutline, intro, para1, para2, para3, para4) {
    try {      
      setParagraphGenerateClick(paragraphGenerateClick+1)
      setBlogParaFive("Loading...");      
      const res = await backendClient.post("/blog-para-five", {
        title: blogOutline[5],        
        user: props.user
      });      
      setBlogParaFive(res.data);
      generateBlogConclusion(blogTitle, intro, para1, para2, para3, para4, res.data);
    } catch (e) {
      setBlogParaFive("");
      console.error(e);
    }
  }
  
  async function generateBlogConclusion(title, intro, para1, para2, para3, para4, para5) {
    try {      
      setConclusionGenerateClick(conclusionGenerateClick+1);
      setBlogConclusion("Loading...");      
      const res = await backendClient.post("/blog-conclusion", {
        title: title,        
        user: props.user
      });      
      setBlogConclusion(res.data);
      storeBlogData(intro, para1, para2, para3, para4, para5, res.data);
    } catch (e) {
      setBlogConclusion("");
      console.error(e);
    }
  }
  
  const storeBlogData = (intro, para1, para2, para3, para4, para5, conclusion) => {
    if(imageOrIdea==="image"){      
      // console.log(`description1: ${description1}\ndescription2: ${description2}\ndescription3: ${description3}`)
      fetch(`https://apie.snapwrite.ca/storeData/image-blog`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              "userID": userID,
              "userName": userName,
              "projectName": props.projectName,
              "imageUploaded": props.image,
              "suggestedTags": props.suggestedTags,
              "tagsUsed": props.tags,
              "generatedBlogTitles": props.blogTitles,
              "blogTitleUsed": blogTitle,
              "generatedBlogOutline": blogOutline,
              "blogOutlineUsed": blogOutline,
              "blogIntro": intro,
              "blogPara1": para1,
              "blogPara2": para2,
              "blogPara3": para3,
              "blogPara4": para4,
              "blogPara5": para5,
              "blogConclusion": conclusion,
              "wordCount": wordCount(intro) + wordCount(para1) + wordCount(para2) + wordCount(para3) + wordCount(para4) + wordCount(para5) + wordCount(conclusion)
          }),
      })
          .then((response) => response.json())
          .then(response=> props.setObjectID(response.data._id))
          .catch((err) => {
              console.log(`Error ${err}`);
          });
    }
    else{
      fetch(`https://apie.snapwrite.ca/storeData/idea-blog`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              "userID": userID,
              "userName": userName,
              "idea": props.blogIdea,
              "generatedBlogTitles": props.blogTitles,
              "blogTitleUsed": blogTitle,
              "generatedBlogOutline": blogOutline,
              "blogOutlineUsed": blogOutline,
              "blogIntro": intro,
              "blogPara1": para1,
              "blogPara2": para2,
              "blogPara3": para3,
              "blogPara4": para4,
              "blogPara5": para5,
              "blogConclusion": conclusion,
              "wordCount": wordCount(intro) + wordCount(para1) + wordCount(para2) + wordCount(para3) + wordCount(para4) + wordCount(para5) + wordCount(conclusion)
          }),
      })
          .then((response) => response.json())
          .then(response=> props.setObjectID(response.data._id))
          .catch((err) => {
              console.log(`Error ${err}`);              
          });
    }
  }
  
  const reset=()=>{
    setBlogOutline("Your blog outline will appear in about 5 seconds");
    setBlogTitle("")
  }
 

// Close the dropdown menu if the user clicks outside of it
window.onclick = function(event) {
  if (!event.target.matches('.dropbtn')) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}
    return (
          <div className="tools">
            
              <div className="blog-outline">
                            <div className="card-spec">
                                <div className="card-header"> Blog Outline </div>
                                <div className="card-body appTag ">
                                    {/* **************************Tags area************************** */}
                                    <div className="appTag spec-body">
                                        <div className="tags-input-spec">                                                                                        
                                            <textarea
                                            name="tagTypeArea"
                                            type="text"
                                            value={blogOutline}
                                            maxLength="900"
                                            onChange={(event) => setBlogOutline(event.target.value)} 
                                            data-hj-allow
                                            />   
                                            <button
                                             className="btn btn-primary1"
                                             onClick={()=> generateBlog(blogTitle, blogOutline)}
                                             disabled={paragraphGenerateClick>50 || props.noOfWordsGenerated>250000}
                                            >
                                        Proceed
                                    </button>                                                                                                         
                                        </div>
                                        
                                    </div>   
                                                                                         
                                </div>
                               
                            </div>
                            {/* ************************************************************* */}
                        </div>                    
                         
          </div>
  );
}
export default BlogTitleToOutline;
