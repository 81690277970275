import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const options = ['Move to Archive'];

const ITEM_HEIGHT = 48;

export default function MoreVertMenu({
	path,
	id,
	isArchived,
	setArchives,
	endpoint,
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ id, isArchived: isArchived ? 'false' : 'true' }),
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleArchive = async (event) => {
		try {
			if(endpoint === 'vintage-apparel-shared'){
				endpoint = "vintage-apparel"
			}
			const response = await fetch(
				`https://apie.snapwrite.ca/storeData/${endpoint}-isArchivedUpdate`,
				requestOptions
			);
			const data = await response.json();
			setAnchorEl(null);
			setArchives(id);
		} catch (error) {
			console.error('Error while archiving:', error);
		}
	};
	  
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label='more'
				id='long-button'
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup='true'
				onClick={handleClick}
			>
				<MoreVertIcon sx={{ fontSize: '24px' }} />
			</IconButton>
			<Menu
				dense
				id='long-menu'
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: isArchived ? '23ch' : '18ch',
					},
				}}
			>
				{options.map((option) => (
					<MenuItem
						key={option}
						selected={option === 'Pyxis'}
						onClick={handleArchive}
					>
						{`${isArchived ? 'Remove from' : 'Move to'} Archive`}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
