import React, {useState} from 'react';
import {getAuth, onAuthStateChanged} from '@firebase/auth'
import { useHistory } from 'react-router-dom';
import 'react-router-dom';

const Success = ({ location }) => {

	const history = useHistory();
	const searchParams = new URLSearchParams(location.search).toString()
	const [status, setStatus] = useState("Success")
	// const auth = getAuth()
	
	// ngrok on Sushant's computer
	// window.location.replace(`https://ec2c-2405-201-1-99d6-6167-874-b0b5-8d06.ngrok-free.app/shopify/auth?${searchParams}`) // Backend URL
	window.location.replace(`https://apie.snapwrite.ca/shopify/auth?${searchParams}`)

	// fetch("https://apie.snapwrite.ca?"+searchParams)
	// console.log("https://apie.snapwrite.ca?" +searchParams)
	// alert("hi")

// 	onAuthStateChanged(auth, async (user) => {
//   if (user) {
//     // User logged in already or has just logged in.
// 		try {
// 			const shop_token = searchParams.get("shop_token");
// 			localStorage.setItem("shop_token" + user.uid, shop_token);
// 			try {
// 				fetch("https://apie.snapwrite.ca/shopify/create", {
// 					method: "POST",
// 					headers: {
// 						"Content-Type": "application/json"
// 					},
// 					body: JSON.stringify({userID: user.uid, shop_token})
// 				})
// 			} catch (error) {
// 				console.log("Developer Debugging: " + error);
// 			}
// 			history.push("/projects")
// 		} catch (error) {
// 			setStatus("Login not successful");
// 		}
//   } else {
// 			setStatus("Login not successful");
//     // User not logged in or has just logged out.
//   }
// });


	return (
		<h1>{status}</h1>
	)
}

export default Success;
