const front = [
    "Forepart",
    "Facade",
    "Exterior",
    "Anterior",
    "Face",
    "Fore",
    "Forward",
    "Visage",
    "Forefront",
    "Foreface",
    "Frontage",
    "front"
  ];

const side = [
    "Flank",
    "Edge",
    "Border",
    "Periphery",
    "Margin",
    "Wing",
    "Borderline",
    "Boundary",
    "Hem",
    "Brim",
    "Rim",
    "Verge",
    "side"
  ];
  
  const chest = [
    "Rib",
    "Upper",
    "Front",
    "chest",
    "breast"
  ];
  
  const none = [
    "Zero",
    "Lack",
    "Absence",
    "Without",
    "No",
    "Non",
    "none"
  ];

  export const pocketLocation = [front, side, chest, none]
