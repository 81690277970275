import React, { useRef, useState, useEffect, PureComponent } from "react"
import { Header } from "./navigation/header"
import SideMenu from "./sideMenu"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled, createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ThemeProvider } from "@emotion/react";
import { DateRangePicker } from 'react-date-range';
import { format, addDays, subDays, differenceInDays, isSameMonth, subMonths, eachDayOfInterval } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const blockedIDs = [
	"JFAt0oYNxmSAQ5ZpOmp0tWHPVKr1",
	"kniIJeElNSNFjczAh5WM4zPaRA93",
	"t4gFVUQejYQYBY6dhwOp2mkYMTe2",
	"xHaYXalwv8X8RpBLZju0PaQlWW23",
	"NpGB6zItW1OcpizZJbD88VsfKlf2",
	"1BILFjc4JNM0LDmmOUfdr2rRfNH3",
	"HtbDQe2tbTbT4iBfWolcppmNoKB3",
	"xqWcP58dezY1MGd8J249dDairz52",
	"7e2jk8rKF4bIHH6VG7XkaXwJDfy1",
	"9g3hm4TNYoYEpr7fNYbq5SEnyxE3",
	"ZFoq2NIsDmZgVTW4g88O1B8JA822",
	"PoMQ2ajEgAUmqDr4zuN0jsd62Bm2",
	"ER94ozEpmsYrHRI7zQyyiYBXDQK2",
	"18Q6tREHEzaKaqQDOE5qXSSEDp93",
	"VKxbpCdrc3RsY1s9yMenv7XL9jZ2",
	"vC48AynZx6RtkCu0K5czc6rAAxa2",
	"krPfCPh78qca1v4WyUjgncnvxs62",
	"0wt9yNqZMxZen1NfDw1BH3QbjSM2",
	"S4nF6oUynvUfkVQjTIcyqyCPZQg2",
	"1eMplQPlHHhX9lnwX8NTcKghui22",
	"80saF2wZqdRUhagajkE83wam8Js2"
  ];

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#e2fd00',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#e2fd00',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


function dateFormatter(date) {
	// console.log("DATEEEEE: ", format(new Date(date), "dd/MMM"))
	try {

		return format(new Date(date), "dd MMM")
	} catch(err) {
		return "error"
	}
}

function getTotalWords(val) {
	let numberWords = 0;
	if (!val) return 0;
	val.types?.map((val, index)=>{    
		if(val){
			numberWords+=val.split(' ').length;
		}
	})
	val.itemGenericType?.map((val, index, key)=>{                    
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.itemName?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.brand?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.size?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.condition?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.description?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.price?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	
	val.fileNames?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.colors?.flatMap(val => val).forEach((element) => {
        if (element) {
            numberWords += element.split(" ").length;
        }
    });
	val.gender?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	val.additionalInfo?.map((val, index, key)=>{
		if(val){
			numberWords+=val.split(' ').length
		}
	})
	return numberWords;
}


function CustomTooltip({active, payload, label, dataName}){
	if (active && payload && payload.length) {
		console.log(payload)
		return (
			<div className="custom-tooltip" style={{background: "white", border: "1px solid #ddd", padding: 10}}>
        <p className="label" style={{padding: 2, margin: 0}}>{`${format(new Date(label), 'MMM dd, yyyy')}`}</p>
        <p className="label" style={{padding: 2, margin: 0}}>{`${dataName} : ${payload[0].value}`}</p>
      </div>
		)
	}
	return null;
}

const Chart = ({title, data, datakey, dataName}) => {
    return (
			<>
			<p style={{padding: "10px 10px 0px 15px", fontWeight: 600}}>{title}</p>
			<hr></hr>
		<ResponsiveContainer width="99%" height={300}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={dateFormatter}/>
          <YAxis />
          <Tooltip content={<CustomTooltip dataName={title}/>} />
          <Area type="monotone" dataKey={datakey} strokeWidth={2} stroke="#e4fd00" fill="#fcffe4" />
        </AreaChart>
      </ResponsiveContainer>
			</>
    );
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#f9fafa", //theme.palette.action.hover,
  },
  '&:nth-of-type(odd)': {
		pointerEvents: "none",
  },
  // hide last border
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CustomizedTables = ({data}) => {
	const NUM_MONTHS = 5;
	let headers = [];
	for (let i = 0; i < NUM_MONTHS; i++) {
		headers.push(format(subMonths(new Date(), NUM_MONTHS - 1 - i), 'LLLL'));
	}
  return (
		<ThemeProvider theme={theme}>
			<TableContainer component={Paper} sx={{mt: 7, border: 1, borderColor: theme => theme.palette.primary.main}}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow sx={{"& th": {fontSize: 16, fontWeight: 500}}}>
							<StyledTableCell variant="head">Users</StyledTableCell>
							{headers.map((header, index) => 
								<StyledTableCell key={`${header}-${index}`} align="right">{header}</StyledTableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<StyledTableRow key={row.uid}>
								<StyledTableCell component="th" scope="row">
									{row.name}
								</StyledTableCell>
								{headers.map((d, i) => 
									<StyledTableCell align="right">{row.numMonthlyImages[NUM_MONTHS - 1 - i]}</StyledTableCell>
								)}
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</ThemeProvider>
  );
}

const endpoints = ['labeling-clothes-categorization', 'labeling-categorization', 'regular-preLoved-apparel', 'vintage-apparel', 'new-apparel']


export const Matric = () => {   
		const [users, setUsers] = useState([]) // list of user id's from firebase
		const [usersData, setUsersData] = useState([]) // list of users and their projects
		const [selectedUser, setSelectedUser] = useState({displayName: "All Users", uid: "All Users"}); // current user
	
		const [imageGraph, setImageGraph] = useState([]) // image graph data
		const [userGraph, setUserGraph] = useState([]) // user graph data

		const [openDate, setOpenDate] = useState(false) // Open the date picker popup
		const [sortedUserData, setSortedUserData] = useState([]); // Table data

		const ref = useRef(null);
		const NUM_MONTHS = 5;

		const [dateRange, setDateRange] = useState([ // current date ranges
			{
				startDate: subDays(new Date(), 7),
				endDate: new Date(),
				key: 'selection'
			},
			// {
			// 	startDate: subDays(new Date(), 60),
			// 	endDate: new Date(),
			// 	key: 'selection'
			// },
		]);

		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
					setOpenDate(false);
			}
    };

		const { startDate, endDate } = dateRange[0];
		function handleChangeDateOrUser() {
			const daysBetween = differenceInDays(endDate, startDate)
			let imagesChartData = [];
			const datesBetween = eachDayOfInterval({start: startDate, end: endDate})
			imagesChartData = datesBetween.map(date => {return {date, numImages: 0, numUsers: 0}})
			if (selectedUser.uid === "All Users") {
				for (const [key, value] of Object.entries(usersData)) {
					// value.projects.forEach()
					value.projects.forEach((project)=> {
						const projectDate = new Date(project.dateCreated);
						if (startDate <= projectDate && projectDate <= endDate){
							if(project.images){
								imagesChartData[differenceInDays(projectDate, startDate)].numImages += project.images.length;
							}
						}
					})
				}
				setImageGraph(imagesChartData)
				return;
			}
			usersData[selectedUser.uid].projects.forEach(project => {
				const projectDate = new Date(project.dateCreated)
				projectDate.setHours(0, 0, 0, 0)
				if (startDate <= projectDate && projectDate <= endDate){
					if(project.images){
						imagesChartData[differenceInDays(projectDate, startDate)].numImages += project.images.length;
					}
				}
			})
			setImageGraph(imagesChartData)
	}

		useEffect(handleChangeDateOrUser, [dateRange, selectedUser, usersData])

		const today = new Date()
		const daysBehind = 1000; // controls maximum length of history in days
		useEffect(() => { // Load all users and project data during start
			document.addEventListener('click', handleClickOutside, true);
			fetch("https://apie.snapwrite.ca/storeData/users")
				.then((response) => response.json())
				.then((json) => {

					json = json.filter(currUser=> !blockedIDs.includes(currUser.uid)); // filter test users

					// loadUserCountData stores number of users, index represents num days behind today
					// e.i. index 0 = 1000 days before, index 1000 = today
					const loadUserCountData = eachDayOfInterval({start: subDays(today, daysBehind), end: today}).map(date => { return {date, numUsers: 0} })
					json.forEach((user, index) => loadUserCountData[1000 - (differenceInDays(today, new Date(user.metadata.creationTime)))].numUsers++) // do calculations
					for (let i = 1; i <= daysBehind; i++) {
						loadUserCountData[i].numUsers += loadUserCountData[i - 1].numUsers;
					}
					setUserGraph(loadUserCountData);

					Promise.all(json.map(d => {  // For each user
						return Promise.all(endpoints.map(endpoint => {
							return fetch(`https://apie.snapwrite.ca/storeData/${endpoint}?userID=${d.uid}`)
							.then(response => response.json())
							.then(json => json.data)
						}))}))
						.then((data) => {
							const sortedUserData = []; // Data for the table of rankings						
							const loadUserData = {};
							let allTotalImages = 0 // total images over all time and users
							let allTotalWords = 0 // " " words ""
							data.forEach((user, i) => { // for each user, get totla images, words etc.
								let totalImages = 0;
								let totalWords = 0;
								// let imagesThisMonth = 0;
								// let imagesLastMonth = 0;
								let numMonthlyImages = Array(NUM_MONTHS).fill(0);
								let projects = [];
								user.forEach(endpoint => { 
									projects = projects.concat(endpoint)
									endpoint.forEach(project => {										
										if(project.images){
											totalImages += project.images.length ?? 0;
										}
										totalWords += getTotalWords(project);
										for (let i = 0; i < NUM_MONTHS; i++) {
											if (isSameMonth(subMonths(today, i), new Date(project.dateCreated))) {
												numMonthlyImages[i] += project?.images?.length ?? 0;
												break;
											}

										}
									})
								})
								loadUserData[json[i].uid] = {projects, totalImages, totalWords};
								allTotalImages += totalImages;
								allTotalWords += totalWords;
								// sortedUserData.push({name: json[i].displayName, imagesThisMonth, imagesLastMonth});
								sortedUserData.push({name: json[i].displayName, numMonthlyImages, uid: json[i].uid});
							})
							loadUserData["All Users"] = {projects: [], totalImages: allTotalImages, totalWords: allTotalWords}
							setUsersData(loadUserData);
							// loadUserData maps user's label (display name) to their num images & words & projects
							sortedUserData.sort((a, b) => {
								for (
									let month = 0;
									month < NUM_MONTHS;
									month++
								) {
									if (
										b.numMonthlyImages[month] !==
										a.numMonthlyImages[month]
									) {
										return (
											b.numMonthlyImages[month] -
											a.numMonthlyImages[month]
										);
									}
								}
								if (a.name && b.name) {
									return a.name.localeCompare(b.name);
								}
								return 0;
							});

							setSortedUserData(sortedUserData);
							// sortedUserData is an array of objects that maps name (display name) to their num images in past 5 months

							setUsers([{label: "All Users", uid: "All Users",}, ...(sortedUserData.map(user => { return { label: user.name, uid: user.uid }}))])
							// console.log(users)
							// Users is an array of objects that maps label (display name) to their firebase uid 

					})

				})
			return () => {

					document.removeEventListener('click', handleClickOutside, true);
			};

		}, [])

		const {caller} = useState("projectsView")
		
	return(
		<div className="App ProjectsInterface">
      <Header caller={caller}/>
      <div className="interface projectInterface" style={{paddingLeft: 30}}>
        {/* The grey section where the user can upload image, edit tags and get the description */}
        <div className="playGround playGroundProjects" style={{ paddingRight: 50}}>
					<div style={{position: "relative"}}>
						<p className="projectHeading">
							Internal Analytics
						</p>
						<Stack direction="row" spacing={2}>
							<Stack>
								<p style={{fontWeight: 600}}>User</p>
								{/* <ComboBox /> */}
								<Autocomplete
									disablePortal
									disableClearable
									id="combo-box-demo"
									options={users}
									defaultValue={users[0]}
									// getOptionLabel={option => option.displayName}
									sx={{ width: 300, fontSize: "normal"}}
									size="small"
									// getOptionSelected={(option, value) => option.label === value.label}
									// isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
									onChange={(e, value) => setSelectedUser(value)}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.uid}>
												{option.label}
											</li>
										)
									}}
									renderInput={(params) => <TextField {...params} label="Type a username" sx={{fontSize: "normal"}}/>}
								/>
							</Stack>
							<Stack style={{position: "relative"}} >
								<p style={{fontWeight: 600}}>Date</p>
								<div
									ref={ref}
									onFocus={()=>setOpenDate(true)}
								>
								<TextField sx={{width: '28ch'}} size="small" value={format(startDate, "MMM dd, yyy") + ' - ' + format(endDate, "MMM dd, yyyy")} />
								{openDate && 
								<div
									style={{position: 'absolute', top: 90, zIndex: 100, border: "1px solid black"}}
								>
								<DateRangePicker 
									onChange={item => {setDateRange([item.selection])}}
									showSelectionPreview={true}
									moveRangeOnFirstSelection={false}
									months={2}
									ranges={dateRange}
									direction="horizontal"
									maxDate={new Date()}
								/>

								</div>
								}

								</div>

							</Stack>
						</Stack>

						<Grid container spacing={5} sx={{ mt: 3}}>
							<Grid item xs="auto">
								<Stack spacing={3}>
									<div className="bulletPointCard">
										<p style={{fontWeight: 400, fontSize: 16}}>User Count</p>
										<p style={{fontSize: 20, color: "#1b2559"}}><strong>{users.length}</strong></p>
									</div>
									<div className="bulletPointCard">
										<p style={{fontWeight: 400, fontSize: 16}}>Images labelled</p>
										<p style={{fontSize: 20, color: "#1b2559"}}><strong>{usersData[selectedUser.uid]?.totalImages}</strong></p>
									</div>
									<div className="bulletPointCard">
										<p style={{fontWeight: 400, fontSize: 16}}>Total Words</p>
										<p style={{fontSize: 20, color: "#1b2559"}}><strong>{usersData[selectedUser.uid]?.totalWords}</strong></p>
									</div>
								</Stack>
							</Grid>
							<Grid item xs>
								<Paper elevation={6} sx={{boxShadow: 6}}>
									<Chart title="Images" data={imageGraph} datakey="numImages"/>
								</Paper>
							</Grid>
							<Grid item xs>
								<Paper elevation={6}>
									<Chart title="Users" data={userGraph.slice(daysBehind - differenceInDays(today, dateRange[0].startDate), daysBehind - differenceInDays(today, dateRange[0].endDate) + 1)} datakey="numUsers" />
								</Paper>
							</Grid>
						</Grid>
								<CustomizedTables data={sortedUserData} />
                <br />
               

					</div>
        </div>
      </div>
    </div>
                    );
}