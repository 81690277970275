const lace = [
    "Ornate",
    "Fishnet",
    "lace"
  ];
  
  const logo = [
    "Branded",
    "Emblem",
    "Label",
    "logo"
  ];
  
  const melange = [
    "cotton",
    "melange"
  ];
  
  const patterned = [
    "Print",
    "printed",
    "pattern",
    "geometric",
    "abstract",
    "patterned"
  ];
  
  const picture = [
    "Photo",
    "printed",
    "custom",
    "portrait",
    "picture"
  ];
  
  const plain = [
    "Classic",
    "blank",
    "basic",
    "canvas",
    "casual",
    "Simple",
    "Clean",
    "Subdued",
    "Modest",
    "Understated",
    "plain"
  ];
  
  const shiny = [
    "Sparkly",
    "metallic",
    "glitter",
    "party",
    "pearly",
    "glittery",
    "sparkle",
    "shiny"
  ];
  
  const text = [
    "letter",
    "letters",
    "writing",
    "words",
    "Typography",
    "message",
    "expressions",
    "script",
    "text"
  ];

  const synonymsArray = [lace, logo, melange, patterned, picture, plain, shiny, text]

export const getDesignSynonyms = (design) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => design.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
return toReturn;
}