import React, { useEffect, useRef, useState } from 'react';

function AddCustomerForm({
	alertOpen,
	handleClose,
	getData,
	userID,
	userName,
	setAlertOpen,
}) {

	const [inputValue, setInputValue] = useState('');
	const [newCustomerPhone, setNewCustomerPhone] = useState('');
	const [newCustomerEmail, setNewCustomerEmail] = useState('');
	const [newCustomerAddress, setNewCustomerAddress] = useState('');
	const inputRef = useRef(null);

	useEffect(() => {
		if (alertOpen) {
			// Focus on the input field when the modal opens
			inputRef.current.focus();
		}
	}, [alertOpen]);


	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const createBatch = () => {
		fetch('https://apie.snapwrite.ca/storeData/apparel-input-batch', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userID: userID,
				userName: userName,
				isArchived: false,
				batchName: inputValue,
				defaultEmail: newCustomerEmail,
				defaultPhoneNumber: newCustomerPhone,
				defaultAddress: newCustomerAddress,
				dateCreated: new Date(),
			}),
		})
			.then((response) => response.json())
			.then((json) => {
				getData(userID);
				setInputValue('');
				setNewCustomerEmail('');
				setNewCustomerPhone('');
				setNewCustomerAddress('');
			})

			.catch((err) => {
				console.log(`Error ${err}`);
			});
	};

	const customerInput = {
		lineHeight: '23px',
		padding: '12.5px 14px',
		letterSpacing: '0.16px',
		width: '90%',
		marginLeft: '5%',
		outline: 'none',
	};

	return (
		<>
			{alertOpen ? (
				<>
					<div style={{zIndex: 35000}} className='justify-center items-center flex overflow-x-hidden overflow-y-auto z-50 fixed inset-0 outline-none focus:outline-none'>
						<div className='relative w-auto my-6 mx-auto max-w-3xl'>
							{/*content*/}
							<div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
								{/*header*/}
								<div className='flex items-start justify-between px-5 pt-5 pb-0'>
									<h3 className='text-3xl font-semibold'>
										Add Customer
									</h3>
									<button
										className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
										onClick={() => handleClose()}
									>
										<span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
											×
										</span>
									</button>
								</div>
								{/*body*/}
								<div className='relative px-6 pb-6 flex-auto'>
									<p className='mb-4 text-blueGray-500 text-lg leading-relaxed'>
										<label className='w-96 ml-4 mt-4 text-lg'>
											Customer Name
										</label>

										<input
											style={customerInput}
											label='Customer Name'
											type='text'
											value={inputValue}
											onChange={handleInputChange}
											ref={inputRef}
										/>

										<label className='w-96 ml-4 mt-4 text-lg'>
											Customer Phone
										</label>

										<input
											style={customerInput}
											label='Customer Phone'
											type='text'
											value={newCustomerPhone}
											onChange={(e) =>
												setNewCustomerPhone(
													e.target.value
												)
											}
										/>

										<label className='w-96 ml-4 mt-4 text-lg'>
											Customer Email
										</label>

										<input
											style={customerInput}
											label='Customer Email'
											type='text'
											value={newCustomerEmail}
											onChange={(e) =>
												setNewCustomerEmail(
													e.target.value
												)
											}
										/>

										<label className='w-96 ml-4 mt-4 text-lg'>
											Customer Address
										</label>

										<input
											style={customerInput}
											label='Customer Address'
											className='mb-3'
											type='text'
											value={newCustomerAddress}
											onChange={(e) =>
												setNewCustomerAddress(
													e.target.value
												)
											}
										/>
									</p>
								</div>
								{/*footer*/}
								<div className='flex items-center justify-end p-6 rounded-b'>
									<button className='text-blueGray-500 background-transparent border-none font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1' onClick={handleClose}>
										Cancel
									</button>
									<button
									className='bg-[#E8FB0A] text-black font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg border-none outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
										onClick={() => {
											createBatch();
											setAlertOpen(false);
										}}
									>
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
				</>
			) : null}
		</>
	);
}

export default AddCustomerForm;
