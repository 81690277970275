import {
  Grid,
  Box,
  TextField,
  Autocomplete,
  Button,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import Textarea from "@mui/joy/Textarea";
import axios from "axios";

const FormCustomization = ({
  emailTemplate,
  setEmailTemplate,
  customEmailCredentials,
  userID,
  setOpenEmailSuccessSnackBar,
  setOpenEmailFailSnackBar,
  setCustomEmailCredentials,
  subjectTemplate,
  setSubjectTemplate,
  setOpenTemplateSuccessSnackBar,
  setOpenTemplateFailSnackBar,
  toggle,
}) => {
  // Email Template OpCredentials
  const emailAutoCompleteOptions = [
    { label: "Customer Name", displayValue: `{{customerName}}` },
    { label: "Organization Name", displayValue: `{{organizationName}}` },
    { label: "User Display Name", displayValue: `{{userDisplayName}}` },
    { label: "Cash Offer", displayValue: `{{cashOffer}}` },
    { label: "Store Credit", displayValue: `{{storeCredit}}` },
    { label: "Link to Seller View", displayValue: `{{sellerViewLink}}` },
  ];
  const [clickPositions, setClickPositions] = useState("");
  const [emailProvider, setEmailProvider] = useState("gmail");

  const addPlaceHolder = (autocompleteOption) => () => {
    if (clickPositions) {
      let orignalString = emailTemplate;
      let firsthalf = orignalString.slice(0, clickPositions);
      let secondHalf = orignalString.slice(clickPositions);
      let finalString = firsthalf + autocompleteOption + secondHalf;
      setEmailTemplate(finalString);
    }
  };

  const updateEmailAndAppPassword = async () => {
    let password = customEmailCredentials.password;
    const body = {
      userId: userID,
      section: "emailAndAppPassword",
      email: customEmailCredentials.email,
      appPassword: password,
      provider: emailProvider,
    };
    fetch(
      "https://apie.snapwrite.ca/storeData/send-email/create-or-update-custom-email-credentials",
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }
    )
      .then((res) => {
        setOpenEmailSuccessSnackBar(true);
      })
      .catch((err) => {
        setOpenEmailFailSnackBar(true);
        console.log(err);
      });
  };

  return (
    <>
      <h2 className=" text-lg my-2">Sender Email </h2>
      <Grid container spacing={5} sx={{ paddingTop: "10px" }}>
        <Grid item>
          <Box
            sx={{ color: "text.disabled" }}
            style={{ fontSize: 14, marginBottom: 30 }}
          >
            Customize your email sender information. If this is not set, emails
            will be sent using contact@snapwrite.ca.
          </Box>
          <Grid className=" flex gap-6 xl:gap-20">
            <Autocomplete
              disablePortal
              disableClearable
              id="email-provider"
              options={["gmail", "outlook"]}
              defaultValue={emailProvider}
              sx={{ width: 200 }}
              onChange={(e, value) => setEmailProvider(value)}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Your Email Provider" />
              )}
            />
            <TextField
              value={customEmailCredentials.email}
              sx={{ width: 300 }}
              required
              autoComplete="off"
              id="email"
              label="Email ID"
              type="email"
              onChange={(e) => {
                setCustomEmailCredentials({
                  ...customEmailCredentials,
                  email: e.target.value,
                });
              }}
            />
            <TextField
              sx={{ width: 300 }}
              value={customEmailCredentials.password}
              required
              autoComplete="off"
              id="password"
              label="App Password - Generated in Gmail Account"
              type="password"
              onChange={(e) => {
                setCustomEmailCredentials({
                  ...customEmailCredentials,
                  password: e.target.value,
                });
              }}
            />
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: 5, marginRight: 2 }}
            onClick={toggle}
          >
            Generate App Password
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 5, textTransform: "none" }}
            disabled={!(customEmailCredentials.email && customEmailCredentials)}
            onClick={updateEmailAndAppPassword}
          >
            Change Email
          </Button>
        </Grid>
      </Grid>
      <div className=" h-[0.5px] bg-slate-400 my-12"></div>
      <h2 className=" text-lg mt-2 mb-3">Email Template </h2>
      <Box
        sx={{ color: "text.disabled" }}
        style={{ fontSize: 14, marginBottom: 5 }}
      >
        Create a customized email template and utilize your personal Gmail
        address for communicating with Inventory Customers.
      </Box>

      <Grid container spacing={5} sx={{ paddingTop: "10px" }}>
        <Grid item xs={10}>
          {/* Subject  */}
          <TextField
            id="subject"
            fullWidth
            value={subjectTemplate}
            sx={{ marginTop: 2, marginBottom: 2 }}
            placeholder="Subject"
            minRows={1}
            onChange={(e) => setSubjectTemplate(e.target.value)}
          />

          {/* Email Body  */}
          <Textarea
            sx={{ backgroundColor: "#ffff" }}
            placeholder="Type here…"
            value={emailTemplate}
            minRows={8}
            onChange={(e) => {
              setEmailTemplate(e.target.value);
              setClickPositions(e.target.selectionStart);
            }}
            onClick={(e) => setClickPositions(e.target.selectionStart)}
            startDecorator={
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-evenly",
                }}
              >
                {Object.keys(emailAutoCompleteOptions).map(
                  (placeholder, index) => (
                    <IconButton
                      sx={{
                        borderRadius: 10,
                        padding: 1,
                        backgroundColor: "#",
                        fontSize: 12,
                        "&:hover": {
                          backgroundColor: "#e1fc00",
                        },
                      }}
                      disabled={!clickPositions === undefined}
                      key={index}
                      variant="outlined"
                      color="neutral"
                      onClick={addPlaceHolder(
                        emailAutoCompleteOptions[index].displayValue
                      )}
                    >
                      {emailAutoCompleteOptions[index].label}
                    </IconButton>
                  )
                )}
              </Box>
            }
            endDecorator={
              <Box
                sx={{
                  display: "flex",
                  gap: "var(--Textarea-paddingBlock)",
                  pt: "var(--Textarea-paddingBlock)",
                  borderTop: "1px solid",
                  borderColor: "divider",
                  flex: "auto",
                }}
              >
                <span className="ml-2 my-2 text-sm">
                  The offer link is automatically appended at the end if not
                  selected.
                </span>
              </Box>
            }
          />
          <Button
            variant="contained"
            sx={{
              marginTop: 3,
              fontSize: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.38)",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": { color: "white", boxShadow: "none" },
            }}
            onClick={() => {
              console.log(subjectTemplate);
              const templateBody = {
                subjectTemplate: subjectTemplate,
                emailTemplate: emailTemplate,
                userID: userID,
              };
              axios
                .put(
                  "https://apie.snapwrite.ca/storeData/send-email/create-or-update-template",
                  templateBody
                )
                .then((res) => {
                  setOpenTemplateSuccessSnackBar(true);
                })
                .catch((err) => {
                  setOpenTemplateFailSnackBar(true);
                  console.log(err);
                });
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FormCustomization;
