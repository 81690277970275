import { useRef, useEffect, useState } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import updateColumnValue from '../utils/updateAttribute';

const SupplementaryImageColumn = (props) => {
	const {singleProductModelFilter} = props

	const disabled = props.disabled;
	let prop = props.prop;
	let rowIndex = props.index;
	const uploadedImages = prop.supplementaryImages[rowIndex];
	const [expand, setExpand] = useState(false);
	const ref = useRef();
	const nullRef = useRef();

	const [supplementaryImages, setSupplementaryImages] = useState([]);

	useEffect(() => {
		if (prop.supplementaryImages.length === 0) return;
		setSupplementaryImages(prop.supplementaryImages[rowIndex]);
	}, [prop.supplementaryImages]);

	async function uploadImage(e) {
		const addedImages = [];
		for (
			let i = 0;
			i < e.target.files.length && supplementaryImages.length + i < 6;
			i++
		) {
			const { url } = await fetch(
				'https://apie.snapwrite.ca/storeData/s3Url'
			).then((res) => res.json());

			await fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'image/jpeg',
				},
				body: e.target.files[i],
			}).catch((err) => console.error(err));
			addedImages.push(url.split('?')[0]);
		}
		let images = [...supplementaryImages, ...addedImages];
		setSupplementaryImages(images);
		updateColumnValue(
			singleProductModelFilter,
			prop.template,
			'supplementaryImages',
			images,
			prop.objectID,
			rowIndex,
			'supplementaryImages'
		);
		// updateColumnValue(singleProductModelFilter, prop.template, "supplementaryImages", images, prop.objectID, rowIndex, "supplementaryImages")
	}

	const handleClickOutside = (event) => {
		if (nullRef.current && nullRef.current.contains(event.target)) return;
		if (ref.current && !ref.current.contains(event.target)) {
			setExpand(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return (
		<td style={{ position: 'relative', zIndex: 1 }}>
			{/* Upload Images */}
			<div
				className='uploaded-images-wrapper'
				onClick={() => setExpand(!expand)}
				ref={ref}
			>
				{supplementaryImages.length === 1 ? (
					<img
						className='uploaded-images'
						style={{
							left: 0,
							right: 0,
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
						src={supplementaryImages[0]}
						alt='upload'
					/>
				) : (
					supplementaryImages.map((img, index) => {
						const length = supplementaryImages.length;
						return (
							<img
								style={{
									left: 25 + (40 * index) / (length - 1),
									top: 15 + (40 * index) / (length - 1),
									zIndex: 100 - index,
								}}
								className='uploaded-images'
								src={img}
								alt='upload'
							/>
						);
					})
				)}
			</div>

			<div ref={nullRef}>
				{expand &&
					supplementaryImages.map((img, index) => {
						return (
							<>
								{!disabled && (
									<button
										className='clickable no-style-button'
										onClick={() => {
											setSupplementaryImages(
												supplementaryImages.filter(
													(d, ind) => ind !== index
												)
											);
											updateColumnValue(
												singleProductModelFilter,
												prop.template,
												'supplementaryImages',
												supplementaryImages.filter(
													(d, ind) => ind !== index
												),
												prop.objectID,
												rowIndex,
												'supplementaryImages'
											);
										}}
										style={{
											lineHeight: 0,
											position: 'absolute',
											fontSize: 20,
											fontStyle: 'bold',
											top: 20,
											left: 345 + 180 * index,
											zIndex: 100,
										}}
									>
										x
									</button>
								)}
								<img
									style={{
										left: 200 + 180 * index,
										zIndex: 50,
									}}
									className='expanded-uploaded-images'
									src={img}
									alt='upload'
								/>
							</>
						);
					})}
			</div>

			<div style={{ width: 140 }}>
				<label
					style={{
						display:
							supplementaryImages.length >= 6 ? 'none' : null,
						marginTop: supplementaryImages.length ? 200 : 0,
					}}
					htmlFor={'myFile' + rowIndex}
					className='pc'
				>
					{supplementaryImages.length === 0 ? (
						<div className='upload-image-btn'>
							<FileUploadIcon color='black' />
							<span style={{ textAlign: 'center' }}>
								{' '}
								PNG, JPEG files only
							</span>
						</div>
					) : (
						<div
							className='upload-image-btn'
							style={{ padding: 5 }}
						>
							<FileUploadIcon color='black' />
						</div>
					)}
				</label>
				<input
					disabled={disabled}
					multiple
					type='file'
					name={'myFile' + rowIndex}
					id={'myFile' + rowIndex}
					className='upload-image-btn drop-zone__input'
					accept='image/*'
					onChange={uploadImage}
				/>
			</div>
		</td>
	);
};

export default SupplementaryImageColumn;
