import TagSize from "../../utils/sizeTagger";
import axios from "axios";
import { getTypeSynonyms } from "../../utils/synonyms/synonyms";

import { getNecklineSynonyms } from "../../utils/synonyms/necklineSynonyms";
import { getDesignSynonyms } from "../../utils/synonyms/designSynonyms";
import { getPatternSynonyms } from "../../utils/synonyms/patternSynonyms";


const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

const oldCreateCSV = async (template, s3Images, itemNamex, sizex, sizeNotesx,brandx, skux, userID, typex, taxonomyTypex, colorx, conditionx, pricex, genderx, imageFileNamex, additionalInfox, costPerItemx, itemWeightx, genericTypex, itemDescriptionx, vendorPx, retailPricex, tagsx, conditionDescriptionx, supplementaryImagesx, materialx, erax, madeInx,lengthMeasurex, sleeveInSeamx, pitToPitMeasurex, shoulderMeasurex, waistMeasurex, hipMeasurex,  bottomInSeamx, riseMeasurex, thighMeasurex, legOpeningx,  sleevesx,
  stylex,
  closurex,
  fastening_typex,
  necklinex,
  cutx,
  hoodx,
  beltx,
  designx,
  liningx,
  layersx,
  patternx,
  skirtx,
  risex,
  heelHeightx,
  heelShapex,
  heightx,
  toex,
	isImport=false) => {
    let itemName = itemNamex
    let size = sizex
    let sizeNotes = sizeNotesx
    let brand = brandx
    let sku = skux
    let types = typex
    let taxonomyTypes = taxonomyTypex
    let colors = colorx
    let condition = conditionx
    let price = pricex
    let genders = genderx
    let imageFileName = imageFileNamex
    let additionalInfos = additionalInfox
    let costPerItem = costPerItemx
    let itemWeight = itemWeightx
    let genericType = genericTypex
    let itemDescription = itemDescriptionx
    let vendorP = vendorPx
    let retailPrice = retailPricex
    let conditionDescription = conditionDescriptionx
    let supplementaryImages = supplementaryImagesx
    let era = erax
    let madeIn = madeInx
    let sleeveM = sleeveInSeamx
    let lengthM = lengthMeasurex
    let pitToPitM = pitToPitMeasurex
    let shoulderM = shoulderMeasurex
    let waistM = waistMeasurex
    let hipM = hipMeasurex
    let bottomInSeam = bottomInSeamx
    let riseMeasure = riseMeasurex
    let thighMeasure = thighMeasurex
    let legOpening = legOpeningx
    let material = materialx   
    let sleeves = sleevesx
    let style = stylex
    let closure = closurex
    let fastening_type = fastening_typex
    let neckline= necklinex
    let cut = cutx
    let hood = hoodx
    let belt = beltx
    let design = designx
    let lining = liningx
    let layers = layersx
    let pattern = patternx
    let skirt = skirtx
    let rise = risex
    let heelHeight = heelHeightx
    let heelShape = heelShapex
    let height = heightx
    let toe = toex
      if(itemName.length===0 || !itemName) {
        itemName = Array(s3Images.length).fill("")
      }
      if(era.length===0 || !era) {
        era = Array(s3Images.length).fill("")
      }
      if(madeIn.length===0 || !madeIn) {
        madeIn = Array(s3Images.length).fill("")
      }
      if(material.length===0 || !material) {
        material = Array(s3Images.length).fill("")
      }
      //Measurements
      if(waistM.length===0 || !waistM) {
        waistM = Array(s3Images.length).fill("")
      }
      if(sleeveM.length===0 || !sleeveM) {
        sleeveM = Array(s3Images.length).fill("")
      }
      if(pitToPitM.length===0 || !pitToPitM) {
        pitToPitM = Array(s3Images.length).fill("")
      }
      if(hipM.length===0 || !hipM) {
        hipM = Array(s3Images.length).fill("")
      }
      if(lengthM.length===0 || !lengthM) {
        lengthM = Array(s3Images.length).fill("")
      }
      if(shoulderM.length===0 || !shoulderM){
        shoulderM = Array(s3Images.length).fill("")      
      }
      if(size.length===0 || !size) {
        size = Array(s3Images.length).fill("")
      }
      if(sizeNotes.length===0 || !sizeNotes) {
        sizeNotes = Array(s3Images.length).fill("")
      }
      if(brand.length===0 || !brand){
        brand = Array(s3Images.length).fill("")
      }
      if(sku.length===0 || !sku){
        sku = Array(s3Images.length).fill("")
      }
      if(types.length===0 || !types){
        types = Array(s3Images.length).fill("")
      }
      if(taxonomyTypes.length===0 || !taxonomyTypes){
        taxonomyTypes = Array(s3Images.length).fill("")
      }
      if(colors.length===0 || !colors){
        colors = Array(s3Images.length).fill("")
      }
      if(condition.length===0 || !condition){
        condition = Array(s3Images.length).fill("")
      }
      if(price.length===0 || !price) {
        price = Array(s3Images.length).fill("")
      }
      if(genders.length===0 || !genders) {
        genders = Array(s3Images.length).fill("")
      }
      if(imageFileName.length === 0 || !imageFileName) {
        imageFileName = Array(s3Images.length).fill("")
      }
      if(additionalInfos.length === 0 || !additionalInfos){
        additionalInfos = Array(s3Images.length).fill("")
      }
      if(costPerItem.length === 0 || !costPerItem){
        costPerItem = Array(s3Images.length).fill("")
      }
      if(itemWeight.length === 0 || !itemName) {
        itemWeight = Array(s3Images.length).fill("")
      }
      if(genericType.length === 0 || !genericType) {
        genericType = Array(s3Images.length).fill("")
      }
      if(itemDescription.length === 0 || !itemDescription){
        itemDescription = Array(s3Images.length).fill("")
      }
      if(vendorP.length === 0 || !vendorP){
        vendorP = Array(s3Images.length).fill("")
      }
      if(retailPrice.length === 0 || !retailPrice){
        retailPrice = Array(s3Images.length).fill("")
      }
      if(conditionDescription.length === 0 || !conditionDescription){
        conditionDescription = Array(s3Images.length).fill("")
      }
      if(supplementaryImages.length === 0 || !supplementaryImages){
        supplementaryImages = Array(s3Images.length).fill([])
      }
      if(sleeves.length===0 || !sleeves) {
        sleeves = Array(s3Images.length).fill([])
      }
      if(style.length === 0 || !style) {
        style = Array(s3Images.length).fill([])
      }
      if(closure.length === 0 || !closure){
        closure = Array(s3Images.length).fill([])
      }
      if(fastening_type.length===0 || !fastening_type){
        fastening_type = Array(s3Images.length).fill([])
      }
      if(neckline.length === 0 || !neckline) {
        neckline = Array(s3Images.length).fill([])
      }
      if(cut.length === 0 || !cut) {
        cut = Array(s3Images.length).fill([])
      }
      if(hood.length === 0 || !hood) {
        hood = Array(s3Images.length).fill([])
      }
      if(belt.length === 0 || !belt) {
        belt = Array(s3Images.length).fill([])
      }
      if(design.length === 0 || !design) {
        design = Array(s3Images.length).fill([])
      }
      if(lining.length === 0 || !lining) {
        lining = Array(s3Images.length).fill([])
      }
      if(layers.length === 0 || !layers) {
        layers = Array(s3Images.length).fill([])
      }
      if(pattern.length === 0 || !pattern) {
        pattern = Array(s3Images.length).fill([])
      }
      if(skirt.length === 0 || !skirt) {
        skirt = Array(s3Images.length).fill([])
      }
      if(rise.length === 0 || !rise) {
        rise = Array(s3Images.length).fill([])
      }
      if(heelHeight.length === 0 || !heelHeight) {
        heelHeight = Array(s3Images.length).fill([])
      }
      if(heelShape.length === 0 || !heelShape) {
        heelShape = Array(s3Images.length).fill([])
      }
      if(height.length === 0 || !height) {
        height = Array(s3Images.length).fill([])
      }
      if(!toe || toe.length === 0) {
        toe = Array(s3Images.length).fill([])
      }

    let csv = [];
    // let csv = csvRowsTemp;
    let i = 0;

    for (i = 0; i < s3Images.length; i++) {
      const lines = itemDescription[i].split("\n");
      let result = '';
      for(let im = 0; im < lines.length; im++){
        if(lines[im]!==""){
          let para=lines[im];
          if (lines[im].includes(`"`)) {
            para = lines[im].replace(
              /"/g,
              `'`
            );
          }
          result += "<p>" + para + "</p>";
        }
      }
      let handle =  `${itemName[i]}_${colors[i]}_${brand[i]}_${types[i]}_${size[i]}_${imageFileName[i]!==undefined && imageFileName[i]!==""?imageFileName[i]: Math.random().toFixed(3)*100}` 
      let tags = `New Arrivals, ${brand[i]}, ${types[i]}, ${size[i]},${colors[i]},${additionalInfos[i]}, ${taxonomyTypes[i]}`;
      let collection = ``;
      let title = `${brand[i]} ${itemName[i]}${size[i]? ` (${size[i]})`:''}`;
      let description = `<meta charset='UTF-8'>${itemDescription[i] && itemDescription[i]!== ''? `<p class='p1'><span class='s1'>${`${result!==""?result:itemDescription[i]}`}</span></p>` : '' }<p class='p1'><span class='s1'>Colour: ${colors[i]}</span></p><p class='p1'><span class='s1'>Size: ${size[i]}</span></p>${template!=='new-clothes'?`<p class='p1'><span class='s1'>Condition: ${condition[i]}</span>${conditionDescription[i] && conditionDescription[i]!==''? `. <span>${conditionDescription[i]}</span>`:''}</p>`:''}`;
      let option1Title =`Title`
      let option1val = `Default Title`
      let option2Title = ``
      let Option2val =``
      let imageAlt=`${title}`
      let weight = 0
      let weightUnit = ``
      let vendor = `${brand[i]}`
      if(vendorP[i]!==''){
        vendor = vendorP[i]
        title =  `${brand[i]} ${itemName[i]}${size[i]? ` // ${size[i]}`:''}`;
      }
      let customProductType = `${types[i]}`
      let status = `draft`
      let taxable = `TRUE`
      let genderTag = [genders[i]];   
      let isAgeBaby = false;         
      if(genders[i] && genders[i].toLocaleLowerCase().includes('gender neutral')){
        genderTag = ['Boys', 'Girls']
      }
      else if(genders[i] && !genders[i].toLocaleLowerCase().includes('gender neutral')){
        if(/\s/g.test(genders[i])){
          genderTag = genders[i].split(/\s+/) 
        }
      }
      
      if (userID==="CXNDhrE6FfNRdXNm6nUQlmck29f2" || userID==="xHaYXalwv8X8RpBLZju0PaQlWW23"){ //Piece by Piece Kids
        vendor = vendorP[i]
        title = `${brand[i]} ${itemName[i]} (${size[i]})`
        description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>Brand: ${brand[i]}</span></p><p class='p1'><span class='s1'>Size: ${size[i]}</span></p><p class='p1'><span class='s1'>Condition: ${condition[i]}${(condition[i]==="Very Good Used Condition" || condition[i]==="Good Used Condition" || condition[i]==="Play Condition")? `. ${conditionDescription[i]}`:""}</span></p>`
        if(size[i].toLowerCase().includes('m') || size[i].toLowerCase().includes('t')){
          isAgeBaby = true
        }
        else if (size[i].includes("-")){          
          let upperLimit = Number(size[i].split('-')[1])
          if(upperLimit > 10){
            isAgeBaby = true
          }
        }
        if (itemName[i]!==undefined && itemName[i]!=="") {
          if (itemName[i].toLowerCase().includes("new ") || itemName[i].toLowerCase().includes("new! ")) {
            tags = `${size[i]}, New, ${brand[i]}, ${types[i]}, ${condition[i].toLowerCase().includes(' new')? 'new' :""}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
          }
          else {
            if(condition[i].toLowerCase()!=='like new' && (condition[i].toLowerCase().includes(' new') || condition[i].toLowerCase()==='new without tags')) {
              tags = `${size[i]}, ${brand[i]}, ${types[i]}, new, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
            }
            else {
              tags = `${size[i]}, ${brand[i]}, ${types[i]}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
            }
          }
        }
        else {
          tags = `${size[i]}, ${brand[i]}, ${types[i]}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
        }        
                
        if(size[i]) {
          option1Title = `Size`;
          option1val = ` ${size[i]}`
        }   
        collection='5%'           
      }
      else if(userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || userID==='t4gFVUQejYQYBY6dhwOp2mkYMTe2' ||  userID==='WbahZZa5HhfWAa6SyJLYnl31kMG3' || template === 'vintage' || template=== 'pre-loved-regular'){ //Loose Fade
        customProductType = taxonomyTypes[i]
        let sizeTag=[]
        if(size[i].toLowerCase().includes("s")){
          sizeTag.push("small")
        }
        if(size[i].toLowerCase().includes("m")){
          sizeTag.push("medium")
        }
        if(size[i].toLowerCase().includes("l")){
          sizeTag.push("large")
        }
        if(size[i].toLowerCase().includes("xl")){
          sizeTag.push("extra large", "xtra large")
        }
        if(size[i].toLowerCase().includes("xxl")){
          sizeTag.push("extra extra large", "xtra xtra large")
        }
        tags = `New Arrivals, online, ${sizeTag}, ${brand[i]}, ${condition[i]}, ${itemName[i]}, ${era[i]}, vintage, preloved, pre-loved, ${colors[i]}, ${size[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${sleeves[i]}, ${style[i]}, ${closure[i]}, ${fastening_type[i]}, ${neckline[i]}, ${cut[i]}, ${hood[i]}, ${belt[i]}, ${design[i]}, ${lining[i]}, ${layers[i]}, ${pattern[i]}, ${skirt[i]}, ${rise[i]}, ${heelHeight[i]}, ${heelShape[i]}, ${height[i]}, ${toe[i]}, hype, streetwear`
        title = `${era[i]!==''?`${era[i]} `:""}${brand[i]!==''?`${brand[i]} `:''}${itemName[i]!==''?`${itemName[i]} `:''}`;
        description = `
        ${result}
        ${madeIn[i]!==""?`<p><b>Made In:</b> ${madeIn[i]}</p>`:''}
        ${era[i]?`<p><b>Era:</b> ${era[i]}</p>`:''}
        ${conditionDescription[i]===""?  `<p><b>Condition:</b> ${condition[i]}</p>`:  `<p><b>Condition:</b> ${condition[i]}</p><p>&nbsp;&nbsp;&nbsp;&nbsp;• ${conditionDescription[i]}</p>`}
        <p><b>Color:</b> ${colors[i]}</p>
        <p><b>Size:</b> ${size[i]}${sizeNotes[i]!==""?`. ${sizeNotes[i]}`: ""}</p>
        <p><b>Measurements:</b></p>
        ${waistM[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i]}</p>
        `: ""}
        ${hipM[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i]}</p>
        `: ""}
        ${bottomInSeam[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i]}</p>
        `: ""}
        ${riseMeasure[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i]}</p>
        `: ""}
        ${thighMeasure[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i]}</p>
        `: ""}
        ${legOpening[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i]}</p>
        `: ""}
        ${pitToPitM[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i]}</p>
        `: ""}
        ${shoulderM[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i]}</p>
        `: ""}
        ${sleeveM[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i]}</p>
        `: ""} 
        ${lengthM[i]!=="" ? `
        <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i]}</p>
        `: ""}
        ${material[i] && material[i]!==''? `
        <p><b>Fabric:</b> ${material[i].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</p>
        `: ""}
        <br />
        <p>
          Sizes for vintage garments may not match the modern sizing convention. Please
          see measurements to ensure a proper fit.
        </p>
        `
        if (userID === '1eMplQPlHHhX9lnwX8NTcKghui22' || userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1') { //Cherry Pick Vintage
          if (
            brand[i] === "Nike" ||
            brand[i] === "Harley Davidson" ||
            brand[i] === "Orange County Choppers" ||
            brand[i] === "Russell" ||
            brand[i] === "Bape" ||
            brand[i] === "Stussy" ||
            brand[i] === "Carhartt" ||
            brand[i] === "Diesel" ||
            brand[i] === "Stone Island" ||
            brand[i] === "The North Face" ||
            brand[i] === "Patagonia" ||
            brand[i] === "Levi"            
          ) {
              tags = tags + ', Branded'
          }
          title = `${era[i]!==''?`${era[i]} `:""}${brand[i]!==''?`${brand[i]} `:''}${itemName[i]!==''?`${itemName[i]} `: `${taxonomyTypes[i]} `} (${size[i]})`;
          description = `          
          ${madeIn[i]!==""?`<p><b>Made In:</b> ${madeIn[i]}</p>`:''}
          ${era[i]?`<p><b>Era:</b> ${era[i]}</p>`:''}
          ${conditionDescription[i]===""?  `<p><b>Condition:</b> ${condition[i]}</p>`:  `<p><b>Condition:</b> ${condition[i]}</p><p>&nbsp;&nbsp;&nbsp;&nbsp;• ${conditionDescription[i]}</p>`}
          <p><b>Color:</b> ${colors[i]}</p>
          <p><b>Size:</b> ${size[i]}${sizeNotes[i]!==""?`. ${sizeNotes[i]}`: ""}</p>
          <p><b>Measurements:</b></p>
          ${waistM[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i]}</p>
          `: ""}
          ${hipM[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i]}</p>
          `: ""}
          ${bottomInSeam[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i]}</p>
          `: ""}
          ${riseMeasure[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i]}</p>
          `: ""}
          ${thighMeasure[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i]}</p>
          `: ""}
          ${legOpening[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i]}</p>
          `: ""}
          ${pitToPitM[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i]}</p>
          `: ""}
          ${shoulderM[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i]}</p>
          `: ""}
          ${sleeveM[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i]}</p>
          `: ""} 
          ${lengthM[i]!=="" ? `
          <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i]}</p>
          `: ""}
          `
          weightUnit = `g`
          // console.log(taxonomyTypes)
          if (taxonomyTypes[i]==='T-shirt') {
            weight = 100
          } else if (taxonomyTypes[i]==='Sweatshirt') {
            weight = 340
          } else if (taxonomyTypes[i]==='Longsleeve') {
            weight = 340
          } else if (taxonomyTypes[i]==='Outerwear') {
            weight = 540
          } else if (taxonomyTypes[i]==='Pants') {
            weight = 340
          } 
          if(size[i]) {
            option1Title = `Size`;
            option1val = ` ${size[i]}`
          }            
          
        }
        
      }
      else if(userID==="LePrix"){ 
        customProductType = taxonomyTypes[i]
        tags = `New Arrivals, ${brand[i]}, ${condition[i]}, ${itemName[i]}, ${era[i]}, vintage, preloved, pre-loved, ${colors[i]}, ${size[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${sleeves[i]}, ${style[i]}, ${closure[i]}, ${fastening_type[i]}, ${neckline[i]}, ${cut[i]}, ${hood[i]}, ${belt[i]}, ${design[i]}, ${lining[i]}, ${layers[i]}, ${pattern[i]}, ${skirt[i]}, ${rise[i]}, ${heelHeight[i]}, ${heelShape[i]}, ${height[i]}, ${toe[i]}`
        title = `${brand[i]} ${itemName[i]} ${taxonomyTypes[i]}`;
        description = `
        ${result}
        ${era[i]?`<p><b>Era:</b> ${era[i]}</p>`:''}
        ${conditionDescription[i]===""?  `<p><b>Condition:</b> ${condition[i]}</p>`:  `<p><b>Condition:</b> ${condition[i]}</p><p>&nbsp;&nbsp;&nbsp;&nbsp;• ${conditionDescription[i]}</p>`}
        <p><b>Color:</b> ${colors[i]}</p>
        <p><b>Size:</b> ${size[i]}</p>
       
        `
        
        
      }
      else if(userID==="zjkiN1cq9Eb4oHsrA923OoJ9Sxz1" || userID==="IMe1VYnWOQgQuER8JQHSI0iKNre2"){ //Tokki kids
        tags = `New, New Arrivals, ${brand[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${size[i]}`
        title = `${itemName[i]} // ${size[i]}`;
        weight = 1
        weightUnit = `lb`
        customProductType = taxonomyTypes[i]

        const replaced = size[i].match(/\d+/);
          let num;
          if (replaced !== null) {            
            num = replaced[0];
          }          
        if(size[i].toLocaleLowerCase().includes('m')) {                  
          if(num<6){
            collection = `0M - 6M`
            tags=tags+', 0M - 6M'
            isAgeBaby=true 
          }
          else if(num<12){
            collection=`6M - 12M`
            tags=tags+', 6M - 12M'
            isAgeBaby=true 
          }
          else if(num<24 || num===24){
            collection=`12M - 24M`
            tags=tags+', 12M - 24M'
            isAgeBaby=true 
          }
        }
        else if(size[i].toLocaleLowerCase().includes('y')){
          if(num<4 || num === 4){
            collection=`2Y - 4Y`
            tags=tags+', 2Y - 4Y'
            isAgeBaby=true 
          }
          else if(num<10 || num===10){
            collection=`5Y - 10Y`
            tags=tags+', 5Y - 10Y'
          }
        }
        status = "active"
      }     
      else if(userID==="5JAT5Rp2ZVP4KEeMPse9KAeDKTQ2"){ // Ware Kids

        tags = `New, New Arrivals, ${brand[i]}, ${types[i]}, ${colors[i]}, ${title.toLocaleLowerCase().includes("vintage")?"vintage":""}, ${taxonomyTypes[i]}`
        if(size[i]) {
          option1Title = `Size`;
          option1val = ` ${size[i]}`
        }            
        weight = itemWeight[i]
        taxable = `FALSE`
        isAgeBaby=true
      }
      else if(userID==="L1mPOQ2Ju8dAUs1pz37vbag93sl2"){ // Beeja May Test
       title = `${brand[i]} - ${types[i]} (${size[i]})`
       option1Title = `Size`;
       option1val = ` ${size[i]}`
       option2Title=`condition`
       Option2val=`${condition[i]}`
       imageAlt = `${title} - Beeja May`
       tags = `Brand_${brand[i]}, Gender_${genders[i]}, Size_${size[i]}, Product Type_${types[i]}, ${colors[i]}, Condition_${condition[i]}, Price_${price[i]}, Baby ${genders[i]}, Baby ${genders[i]}${types[i]}, New Arrival, All Year, Kids, jackets & outerwear`
       weight =0;
      }     
      else if (userID==="HDlknVR9NpWxhs9WWlfvnwAQq4b2" || userID==="ZFoq2NIsDmZgVTW4g88O1B8JA822"){ // Bon Tot
        title = `${brand[i]} ${itemName[i]} size ${size[i]}`
        description = `<meta charset='UTF-8'>
                        <p class='p1'>
                          <span class='s1'>Brand: ${brand[i]}</span>
                        </p>
                        <p class='p1'>
                          <span class='s1'>Size: ${size[i]}</span>
                        </p>
                        <p class='p1'>
                          <span class='s1'>Condition: ${condition[i]}. ${conditionDescription[i]}</span>
                        </p>
                        <h4>About Bon Voyage:</h4>
                        <p>Bon Voyage is our brand new Buy Back marketplace - a curated collection of the best quality second hand baby and kids clothing available. This will include vintage childrenswear alongside Bon Tot pieces that we will literally Buy Back from you! We'll also buy other brands that you might own, as long as they meet our criteria in terms of where and how they were manufactured. This is an ethical second hand children's clothing marketplace.</p>
                        <p>At Bon Tot we've always stood for sustainable production, at a fair price, and this means kids clothing that is made to last - saving you money in the long run. Now, we're going one step further and offering to buy back your pieces once your kid has grown out of them. We will either back back for cash, or you can make a little extra if you choose store credit.</p>
                        <p>We're trying to make a truly sustainable circular system, focused on affordable, ethical, well made stylish kids clothes.</p>
                        <p>You can <strong>READ MORE <a href='https://www.bon-tot.com/blogs/news/buyback-second-hand-kids-clothing' title='second hand kids clothing near me - Bon Tot Edinburgh'>HERE</a></strong></p>`
        // customProductType = `${genericType[i].substring(genericType[i].indexOf('/') + 1)}`
        // if(customProductType.toLocaleLowerCase()==="upper"){
        //   customProductType = "tops"
        // }
        vendor = `Bon Voyage`        
        let sizeTag = [];
        if(size[i].toLocaleLowerCase().includes("m") && size[i].match(/\d+/)){
          if(["3M","6M", "3 months", "6 months", "0-3 months", "0-6 months", "3-6 months", "9M", "12M", "9 months", "12 months", "6-9 months", "9-12 months", "6-12 months"].includes(size[i].toLowerCase())){
            sizeTag = ["0-12 months"]
            isAgeBaby=true
          }   
          else if(Number(size[i].match(/\d+/)[0])>11){
            sizeTag = ["1-3 years"]
            isAgeBaby=true
          }                
        }        
        else if(size[i].toLocaleLowerCase().includes("y")){          

          const replaced = size[i].match(/\d+/g); 
          
          let num=[];
          if (replaced !== null && replaced.length > 1) {
            num.push(Number(replaced[0]));
            num.push(Number(replaced[1]));
            console.log(num)
            if (num[1] < 4) {
              sizeTag = ["1-3 years"];
              isAgeBaby=true
            } else if (num[0] > 3 && num[1] < 7) {
              sizeTag = ["4-6 years"];
            } else if (num[0] < 4 && num[1] < 7) {
              sizeTag = ["1-3 years", "4-6 years"];
            } else if (num[0] > 6 && num[1] < 10) {
              sizeTag = ["7-9 years"];
            } else if (num[0] < 6 && num[1] < 10 && num[1]>6) {
              sizeTag = ["4-6 years", "7-9 years"];
            } else if (num[0] > 9 && num[1] < 12) {
              sizeTag = ["10-11 years"];
            } else if (num[0] < 9 && num[1] < 12 && num[1]>9) {
              sizeTag = ["7-9 years", "10-11 years"];
            }else if (num[0] < 11 && num[1] < 15) {
              sizeTag = ["12-13 years"];
            } else if (num[0] < 11 && num[1] < 15 && num[1]>11) {
              sizeTag = ["10-11 years", "12-13 years"];
            } else if (num[1]>13) {
              vendor = `Bon Voyage Adult`
            }
          } else if (replaced !== null && replaced.length === 1) {
            num = Number(replaced[0]);
            if(num<4){
              sizeTag="1-3 years"
              isAgeBaby=true
            }                    
            else if(num>3 && num <7){
              sizeTag="4-6 years"
            }
            else if(num>6 && num<10){
              sizeTag="7-9 years"
            }
            else if(num>9 && num<12){
              sizeTag="10-11 years"
            }
            else if(num>11 && num<15){
              sizeTag = "12-13 years"
              if(num>13){
                vendor = `Bon Voyage Adult`
              }
            }
            else if(num>14){
              sizeTag=size[i]
              vendor = `Bon Voyage Adult`
            }
          }
        }                    
        else if(["XS","S","M", "L", "XL", "XXL"].includes(size[i].toUpperCase())){
          vendor = `Bon Voyage Adult`
          sizeTag=size[i]
        } else {
          sizeTag=size[i]
        }
        tags = `${sizeTag}, buyback, ${brand[i]}, ${types[i]}, ${additionalInfos[i]}, ${customProductType}, ${colors[i]}${condition[i].toLowerCase().includes("brand new")?', NWT':', preloved, pre-loved'}, ${taxonomyTypes[i]}`
        weight = 0
        imageAlt = `${title} - Bon Tot`
        weightUnit = `kg`
        taxable = `FALSE`
      }
      else if(userID==="Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1"){ // Royal City Littles
        title = `${itemName[i]} (${size[i]})`
        description=`<strong data-mce-fragment='1'>Brand: </strong>${brand[i]} 
                     <div data-mce-fragment='1'>
                      <strong data-mce-fragment='1'>Size: </strong>${size[i]}
                     </div>
                     <div data-mce-fragment='1'>
                       <strong data-mce-fragment='1'>Details: </strong>${additionalInfos[i]}
                     </div>      
                     <div data-mce-fragment='1'>
                       <strong data-mce-fragment='1'>Condition: </strong>${condition[i]}
                     </div>
                     <div data-mce-fragment='1'>
                     <meta charset='UTF-8'>
                     <section class='shopify-section section' id='shopify-section-template--15415710974149__product-details' data-mce-fragment='1'>
                     <div class='product-details page-width section-template--15415710974149__product-details-padding' id='ProductDetails' data-mce-fragment='1'>
                     <div class='grid' data-mce-fragment='1'>
                     <div class='grid__item grid__item--three-quarter' data-mce-fragment='1'>
                     <div class='product__description rte' data-mce-fragment='1'>
                     <div data-mce-fragment='1'>
                     <strong data-mce-fragment='1'>RCL Buy Back Program? YES.</strong> When you're done with this item, and don't have someone to hand it down to, we'd be happy to re-purchase it from you and add to our preloved section of our shop. </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </section>
                     </div>`      
      let sizeTag = "";
      if(size[i].toLocaleLowerCase().includes("m")){
        isAgeBaby=true
        const replaced = size[i].match(/\d+/g); 
        console.log(replaced)
        let num=[];

        if (replaced !== null) {
          num.push(Number(replaced[0])); 
          num.push(Number(replaced[1])); 
        }
        if(num[1]<3 || num[1]===3){
          sizeTag='0-3M'
        }
        else if(num[0]===3 || num[1] ===6 || (num[0]>3 && num[1]<6)){
          sizeTag='3-6M'
        }
        else if(num[0]>5 && num[1]<13){
          if(num[1]>9){
            sizeTag='6-12M'
          }
          else if(num[1]<9 ||num[1]===9){
            sizeTag='6-9M, 6-12M'
          }  
          else if(num[1]<13 && num[1]>8){
            sizeTag='9-12M'
          }
        }
        else if(num[0]>11 && num[1]<25){          
          sizeTag='12-24M'
          if(num[1]<19){
            sizeTag='12-18M, 12-24M'
          }
          else if(num[0]>17){
            sizeTag='18-24M'
          }
        }      
        else if(num===18){
          sizeTag='18-24M'
        }
      }        
      else if(size[i].toLocaleLowerCase().includes("t")){
        isAgeBaby=true
        const replaced = size[i].match(/\d+/); 
        let num;
        if (replaced !== null) {
          num = Number(replaced[0]); 
        }        
        if(num===2){
          sizeTag="2T"
        }                    
        else if(num===3){
          sizeTag="3T"
        }        
      }                    
      else if(size[i].toLocaleLowerCase().includes("y")){          
        const replaced = size[i].match(/\d+/); 
        let num;
        if (replaced !== null) {
          num = Number(replaced[0]); 
        }        
        sizeTag = `${num}Y`
      }
      tags=`${sizeTag}, ${size[i]}, ${colors[i]}, ${brand[i]}, preloved, ${condition[i]}, ${customProductType}, ${taxonomyTypes[i]}`
      }
      else if(userID==="AgVEaDRk3tVtnfqAKiMYWLlRICG2"){ // Tiny Human Gear
        title = `${brand[i]}, ${size[i]}`
        description=`<meta charset='UTF-8'><p class='p1'><span class='s1'>${itemDescription[i]}</span></p><p class='p1'><span class='s1'>Colour: ${colors[i]}</span></p><p class='p1'><span class='s1'>Condition: ${condition[i]}${(condition[i]==="Very Good Used Condition" || condition[i]==="Good Used Condition" || condition[i]==="Play Condition")? `${conditionDescription[i]!==""? `. ${conditionDescription[i]}` :""}`:""}</span></p>`;
        customProductType = taxonomyTypes[i]
        // customProductType = `${genericType[i].substring(genericType[i].indexOf('/') + 1)}`
        // if(customProductType.toLocaleLowerCase()==="upper"){
        //   customProductType = "tops"
        // }
        let sizeTag = "";
        let num =[];
        const replaced = size[i].match(/\d+/g);

        if(size[i].toLocaleLowerCase().includes("m") && size[i].slice(-1).toLocaleLowerCase()==='m'){

          isAgeBaby=true
          if (replaced !== null && replaced.length > 1) {
            num.push(Number(replaced[0]));
            num.push(Number(replaced[1]));

            if (num[1] < 6 || num[1] === 6) {
              sizeTag = ["0-6 months"];
            } else if (num[0] > 5 && num[1] < 13) {
              sizeTag = ["6-12 months"];
            } else if (num[0] < 6 && num[1] < 13) {
              sizeTag = ["0-6 months", "6-12 months"];
            } else if (num[0] > 11 && num[1] < 19) {
              sizeTag = ["12-18 months"];
            } else if (num[0] < 12 && num[1] < 19) {
              sizeTag = ["6-12 months", "12-18 months"];
            } else if (num[0] > 17 && num[1] < 25) {
              sizeTag = ["18-24 months"];
            } else if (num[0] < 18 && num[1] < 25) {
              sizeTag = ["12-18 months", "18-24 months"];
            }
          } else if (replaced !== null && replaced.length === 1) {
            num = Number(replaced[0]);
            if (num < 6) {
              sizeTag = ["0-6 months"];
            } else if (num === 6) {
              sizeTag = ["0-6 months", "6-12 months"];
            } else if (num < 12) {
              sizeTag = ["6-12 Months"];
            } else if (num === 12) {
              sizeTag = ["6-12 Months", "12-18 Months"];
            } else if (num > 12 && num < 19) {
              sizeTag = ["12-18 Months"];
            } else if (num === 18) {
              sizeTag = ["12-18 Months", "18-24 months"];
            } else if (num > 18 && num < 25) {
              sizeTag = ["18-24 months"];
            } 
          }
        }        
        else if(["2", "2 years", '2*'].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("12")){
          isAgeBaby=true
          sizeTag="2 years"
        }
        else if(["3", "3 years", "3*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("13")){
          isAgeBaby=true
          sizeTag="3 years"
        }
        else if(["4", "4 years", "4*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("14")){
          sizeTag = "4 years"
        }
        else if(["5", "4/5", "5/6", "5*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("15")){
          sizeTag = "5 years"
        }
        else if(["6", "7", "6/7", "5/6", "7/8", "6*", "7*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("16") && !size[i].includes("17")){
          sizeTag = "6/7 years"
        }
        else if(["8", "9", "7/8", "8/9", "9/10", "8*", "9*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("18") && !size[i].includes("19")){
          sizeTag = "8/9 years"
        }
        else if(["10", "11", "12", "10/11", "11/12", "12/13", "12/14", "10/12", "10*", "11*", "12*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear))){
          sizeTag = "10-12 years"
        }
        else if(["13", "14", "15", "16", "12/13", "12/14", "14/16", "16*", "13*", "14*", "15*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear))){
          sizeTag = "13-16 years"
        }
        else if(size[i].toLowerCase().includes("women")){
          sizeTag = "Women's"
        }
        tags=`${condition[i]==="New With Tags"?'new, ':""}${size[i]}, ${colors[i]}, ${brand[i]}, ${size[i].toLowerCase().includes("women")?"":"5%"}, ${condition[i]}, ${customProductType}, ${sizeTag}, ${itemName[i]}, ${taxonomyTypes[i]}`
        collection = `${size[i].toLowerCase().includes("women")?"":"5%"}`
        if(size[i]) {
          option1Title = `Size`;
          option1val = ` ${size[i]}`
        }       
        weight = 0.1
      }
      else if(userID==="VIa289fKOLZVRQTAEuueJXwvA0y2"){ //Pea Shoots Consignment        
        let sizeTags = size[i]?size[i]:"";
        if (sizeTags.toLowerCase().match(/t$/)) {
          sizeTags = sizeTags.toUpperCase();
        }
        else {          
          sizeTags = sizeTags.toLocaleLowerCase();
        }
        const sizeTitle = sizeTags.toLocaleLowerCase().includes('size') ? sizeTags : `size ${sizeTags}`
        vendor = vendorP.replace(/[^a-zA-Z]+/, "");
        title = `${vendorP} - ${brand[i]} ${colors[i].toLocaleLowerCase()} ${itemName[i].toLocaleLowerCase()}, ${sizeTitle}, ${condition[i].toLocaleLowerCase()}`;
        description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>Size: ${sizeTags}</span></p>`;
        let sizeTag = [""];
        const replaced = size[i].match(/\d+/g);
        let num = [];

        //Footwear Logic
        if(genericType[i].toLowerCase().includes("footwear") || 
           itemName[i].toLocaleLowerCase().includes("footwear") || 
           itemName[i].toLocaleLowerCase().includes("shoes") ||
           types[i].toLocaleLowerCase().includes("footwear")|| 
           types[i].toLocaleLowerCase().includes("slipper")||
           types[i].toLocaleLowerCase().includes("boot")) {
            if (size[i].toLocaleLowerCase().includes("m")) {
              if (replaced !== null && replaced.length > 1) {
                num.push(Number(replaced[0]));
                num.push(Number(replaced[1]));

                  if (num[0] < 3) {
                    sizeTag = ["Size 1 (Baby)"];
                    if(num[1]>3 && num[1]<7){
                        sizeTag= ["Size 1 (Baby)", 'Size 2 (Baby)']
                    }
                    else if(num[1]>6 && num[1]<10){
                      sizeTag= ['Size 1 (Baby)',  'Size 2 (Baby)', 'Size 3 (Baby)']
                    }
                    else if(num[1]>10 && num[1]<13){
                      sizeTag= ['Size 1 (Baby)',  'Size 2 (Baby)', 'Size 3 (Baby)', 'Size 4 (Baby)']                    
                    }
                } else if (num[0] < 6) {
                    sizeTag = ["Size 2 (Baby)"];                  
                    if(num[1]>6 && num[1]<10){
                      sizeTag= ['Size 2 (Baby)', 'Size 3 (Baby)']
                    }
                    else if(num[1]>10 && num[1]<13){
                      sizeTag= ['Size 2 (Baby)', 'Size 3 (Baby)', 'Size 4 (Baby)']                    
                    }
                } else if (num[0] < 9) {
                    sizeTag = ["Size 3 (Baby)"];                                    
                    if(num[1]>10 && num[1]<13){
                      sizeTag= ['Size 3 (Baby)', 'Size 4 (Baby)']                    
                    }
                } else if (num[0] < 12){
                  sizeTag= ["Size 4 (Baby)"]
                  if(num[1]>12 && num[1]<19){
                  sizeTag= ["Size 4 (Baby)", "Size 5"]
                  }
                }
                else if (num[0] < 18){
                  sizeTag = ["Size 5"]
                  if(num[1]>18 && num[1]<25){
                    sizeTag = ["Size 5", "Size 6"]
                  }                  
                }
                else if (num[0] < 24){
                  sizeTag=["Size 6"]
                }
              } else if (replaced !== null && replaced.length === 1) {
                  num = Number(replaced[0]);

                  if (num < 3) {
                    sizeTag = ["Size 1 (Baby)"];
                  } else if (num === 3) {
                    sizeTag = ["Size 1 (Baby)", 'Size 2 (Baby)'];
                  } else if (num > 3 && num < 6) {
                    sizeTag = ["Size 2 (Baby)"];
                  } else if (num === 6) {
                    sizeTag = ["Size 2 (Baby)", 'Size 3 (Baby)'];
                  } else if (num > 6 && num < 9) {
                    sizeTag = ["Size 3 (Baby)"];
                  } else if (num === 9) {
                    sizeTag = ["Size 3 (Baby)", "Size 4 (Baby)"];
                  } else if (num > 9 && num < 12) {
                    sizeTag = ["Size 4 (Baby)"];
                  } else if (num === 12) {
                    sizeTag = ["Size 4 (Baby)", "Size 5"];
                  } else if (num > 12 && num < 18) {
                    sizeTag = ["Size 5"];
                  } else if (num === 18) {
                    sizeTag = ["Size 5", "Size 6"];
                  } else if (num > 18 && num < 25) {
                    sizeTag = ["Size 6"];
                  }
              }
            } else if (size[i].toLowerCase().includes('youth')){
              if (replaced !== null && replaced.length === 1){
                num =Number(replaced[0]);
                if(num > 5 || num === 5){
                  sizeTag=["Youth Size 5+"]
                }
              }
            }
            sizeTag= [`${size[i]}`]
          } else {
            if (size[i].toLocaleLowerCase().includes("m")) {
              if (replaced !== null && replaced.length > 1) {
                num.push(Number(replaced[0]));
                num.push(Number(replaced[1]));
        
                if (num[1] < 6 || num[1] === 6) {
                  sizeTag = ["0-6 Months"];
                } else if ((num[0] > 6 && num[1] < 12) || num[1] === 12) {
                  sizeTag = ["6-12 Months"];
                } else if ((num[0] > 12 && num[1] < 18) || num[1] === 18) {
                  sizeTag = ["12-18 Months"];
                } else if (num[0] > 18 && num[1] < 25) {
                  sizeTag = ["18-24 Months"];
                }
              } else if (replaced !== null && replaced.length === 1) {
                num = Number(replaced[0]);
        
                if (num < 6) {
                  sizeTag = ["0-6 Months"];
                } else if (num === 6) {
                  sizeTag = ["0-6 Months", "6-12 Months"];
                } else if (num > 6 && num < 12) {
                  sizeTag = ["6-12 Months"];
                } else if (num === 12) {
                  sizeTag = ["6-12 Months", "12-18 Months"];
                } else if (num > 11 && num < 18) {
                  sizeTag = ["12-18 Months"];
                } else if (num === 18) {
                  sizeTag = ["12-18 Months", "18-24 Months"];
                } else if (num > 18 && num < 25) {
                  sizeTag = ["18-24M"];
                }
              }
              isAgeBaby=true
            } else if (size[i].toLocaleLowerCase().includes("t")) {
              sizeTag = [`${sizeTags}`];
              isAgeBaby=true
              if(replaced && replaced.length>1){
                num = []
                num.push(Number(replaced[0]));
                num.push(Number(replaced[1]));
                sizeTag = [`${num[0]}T`, `${num[1]}T`]
              }
            } else {
              sizeTag=[`${size[i]}`]
              if(replaced && replaced.length>1){
                num = []
                num.push(Number(replaced[0]));
                num.push(Number(replaced[1]));
                if(num[0]>9 && num[1]<13){
                  sizeTag=["10-12+"]
                }
                else{
                  sizeTag = [`${num[0]}`, `${num[1]===8 || num[1]===9 ?'8/9':num[1]}`]
                }
              }
              else if (size[i]===9 || size[i]===8){
                sizeTag=["8/9"]
              }
              else if (size[i]>9){
                sizeTag=["10-12+"]
              }
            }
          }
        // Tags Schema
        // {gender size type}
        // {gender size}
        // {gender type}
        // {gender}
        // console.log(sizeTag)       
        let genderSizeType = `${genders[i]} ${sizeTags} ${types[i]}`;
        let genderSize = `${genders[i]} ${sizeTags}`;
        let genderType = `${genders[i]} ${types[i]}`;              
                 
          if (genderTag.length !== 0) {
            genderSizeType = [];
            genderSize = [];
            genderType = [];
            const genderTypeSizeSorter = (gender, size, type) =>{
              if(type.toLocaleLowerCase().includes("footwear")){
                let typeTag = "Footwear"
                return `${gender} ${typeTag} ${size}`
              }
              return `${gender} ${size} ${type}`
            }
            
            // eslint-disable-next-line no-loop-func
            genderTag.map((key, index) => {              
              genderType.push(`${genderTag[index]} ${types[i]}`);
              sizeTag.forEach((tag)=>{
                if (!/[a-zA-Z]/g.test(size[i])) {
                  genderSizeType.push(`${genderTag[index]} Size ${tag} ${types[i]}`);
                  genderSize.push(`${genderTag[index]} Size ${tag}`);                
                } else {
                  genderSizeType.push(genderTypeSizeSorter(genderTag[index], tag, types[i]));
                  genderSize.push(`${genderTag[index]} ${tag}`);   
                }
              })
            });
            genderSizeType = genderSizeType.join(", ");
            genderSize = genderSize.join(", ");
            genderType = genderType.join(", ");
            genderTag = genderTag.join(", ");
            if (
              genderTag.toLowerCase().includes("boys") &&
              genderTag.toLowerCase().includes("girls")
            ) {
              genderTag += ", gender neutral";
            }
          } 
        
        tags = `${genderTag}, ${genderSizeType}, ${genderSize}, ${genderType}, ${
          size[i]
        }, ${sizeTag !== size[i] ? sizeTag : ""},${colors[i]}`;        
      }
      else if (userID==="s8G1GJrXSraO8ySUsMeGY61sJRk1"){ // Aster and Luna
        title=`${brand[i]} - ${itemName[i]} (${size[i]})${condition[i].toLocaleLowerCase().includes('play') ? ' *play' : ''}`
        description=`<meta charset='UTF-8'><p class='p1'><span class='s1'>Condition: ${condition[i]}</span></p><p class='p1'><span class='s1'>Colour: ${colors[i]}</span></p><p class='p1'><span class='s1'>Description: ${itemDescription[i]}</span></p>${retailPrice[i]!=="" && retailPrice[i]!==undefined ? `<p class='p1'><span class='s1'>Retail Price: ${retailPrice[i]}</span></p>`:""}`
        tags = `${TagSize(size[i])}, ${size[i]}, ${brand[i]}, ${condition[i]}, ${customProductType}, ${genders[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
      }
      else if (template==='preLovedKids') {
        tags = `${TagSize(size[i])}, ${size[i]}, ${brand[i]}, ${condition[i]}, ${customProductType}, ${genders[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
        customProductType = taxonomyTypes[i]
      } 
      if (userID==='cKIubugLu0R3lv64AaQX37Qg0xz1'){
        collection = collection + 'all stocks'
      }
      // else if(userID==="UDpMYI7qc5dDFLCf0jEwZpJhLF52")//small and kind
      // {
      //   title = 
      // }
      // console.log(types[i])
      // console.log(genericType[i])
      // console.log(taxonomyTypes[i])
      // console.log(csv)

      // console.log(i, ": ", taxonomyTypes[i], itemName[i])

        
      const typeSynonyms = getTypeSynonyms(taxonomyTypes[i])
      const nameSynonyms = getTypeSynonyms(itemName[i])
      const designSynonyms = getDesignSynonyms(design[i])
      const necklineSynonyms = getNecklineSynonyms(neckline[i])
      const patternSynonyms = getPatternSynonyms(pattern[i])

      tags += ", " + typeSynonyms.join(", ")
      tags += ", " + nameSynonyms.join(", ")
      tags += ", " + designSynonyms.join(", ")
      tags += ", " + necklineSynonyms.join(", ")
      tags += ", " + patternSynonyms.join(", ")

      // console.log(itemName[i])
      // console.log(taxonomyTypes[i])
      // console.log(design[i])
      // console.log(neckline[i])
      // console.log(pattern[i])
      // console.log(tags)

      // color + type
      // design + type
      // genedr + type
      // age group + type
      // brand + tshirt
      // if vintage:
      // "vintage tshirts"

      tags += `, ${colors[i]} ${taxonomyTypes[i]}`
      tags += `, ${design[i]} ${taxonomyTypes[i]}`
      // tags += `, ${genders[i]} ${taxonomyTypes[i]}`
      tags += `, ${isAgeBaby ? "baby" : "adult"} ${taxonomyTypes[i]}`
      tags += `, ${brand[i]} ${taxonomyTypes[i]}`
      tags += `, ${template} ${taxonomyTypes[i]}`
      
      csv.push([
        `${handle}`, //Handle
        `${title}`, //Title
        // `<p>Type: ${types[i]}<br /> Gender: ${genders[i]} <br /> Color: ${colors[i]} <br /> AdditionalInfo: ${additionalInfos[i]}</p>`, //Body (HTML)
        `${description}`,
        `${vendor}`, //Vendor
        `Apparel & Accessories${types[i] ? 
                                ((types[i].toLocaleLowerCase().includes("footwear")|| 
                                  types[i].toLocaleLowerCase().includes("slipper")||
                                  types[i].toLocaleLowerCase().includes("boot")) ? 
                                ` > Shoes`
                                :
                                ` > Clothing${size[i] ? 
                                      (isAgeBaby ?
                                        ` > Baby & Toddler Clothing${taxonomyTypes[i]!=="" ? 
                                            ( 
                                              taxonomyTypes[i].toLocaleLowerCase().includes("top") ? ` > Baby & Toddler Tops` 
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("bottom") ? ` > Baby & Toddler Bottoms`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("outerwear") ? ` > Baby & Toddler Outerwear`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("sleepwear") ? ` > Baby & Toddler Sleepwear`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("swimwear") ? ` > Baby & Toddler Swimwear`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("dress") ? ` > Baby & Toddler Dresses`
                                              :""
                                            )
                                            :""
                                          }`
                                        : 
                                        ""
                                      )
                                    :
                                    ""
                                  }`
                                )
                                :
                                ""
                              } `, //Standardized Product Type
        customProductType, //Custom Product Type
        `${tags}`, //Tags
        "TRUE", //Published 
        `${option1Title}`, //Option1 Name        
        `${option1val}`, //Option1 Value
        `${option2Title}`, //Option2 Name
        `${Option2val}`, //Option2 Value
        "", //Option3 Name
        "", //Option3 Value
        `${sku[i]}`, //Variant SKU
        weight, //Variant Grams
        "shopify", //Variant Inventory Tracker
        1, //Variant Inventory Qty 
        "deny", //Variant Inventory Policy 
        "manual", //Variant Fulfillment Service 
        price[i], //Variant Price///////////////////////// 
        "", //Variant Compare At Price 
        "TRUE", //Variant Requires Shipping 
        `${taxable}`, //Variant Taxable 
        "#0000", //Variant Barcode////////////////////
        s3Images[i], //Image Src 
        1, //Image Position 
        `${imageAlt}`, //Image Alt Text 
        "FALSE", //Gift Card 
        title, //SEO Title
        // `This ${types[i]} from ${brand[i]} is a perfect match if you are looking for something cozy for your little one. Item is of ${condition[i]} condition and will fit your ${size[i]} olds. Few key details ${additionalInfos[i]}. This ${types[i]} is available in ${colors[i]}`, //SEO Description
        `${(!itemDescription[i] || itemDescription[i]==="") && template !== 'vintage' ? `The ${types[i]} from ${brand[i]} is a perfect match if you are looking for something special for your little one. Item is of ${condition[i]} condition and will fit your ${size[i]} olds. This ${types[i]} is available in ${colors[i]}.`: itemDescription[i]}`, //SEO Description
        `Apparel & Accessories${types[i] ? 
                                ((types[i].toLocaleLowerCase().includes("footwear")|| 
                                  types[i].toLocaleLowerCase().includes("slipper")||
                                  types[i].toLocaleLowerCase().includes("boot")) ? 
                                ` > Shoes`
                                :
                                ` > Clothing${size[i] ? 
                                      (isAgeBaby ?
                                        ` > Baby & Toddler Clothing${taxonomyTypes[i]!=="" ? 
                                            ( 
                                              taxonomyTypes[i].toLocaleLowerCase().includes("top") ? ` > Baby & Toddler Tops` 
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("bottom") ? ` > Baby & Toddler Bottoms`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("outerwear") ? ` > Baby & Toddler Outerwear`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("sleepwear") ? ` > Baby & Toddler Sleepwear`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("swimwear") ? ` > Baby & Toddler Swimwear`
                                              :
                                              taxonomyTypes[i].toLocaleLowerCase().includes("dress") ? ` > Baby & Toddler Dresses`
                                              :""
                                            )
                                            :""
                                          }`
                                        : 
                                        ""
                                      )
                                    :
                                    ""
                                  }`
                                )
          :
          ""
        } `, //Google Shopping / Google Product Category
        genders[i], //Google Shopping / Gender
        `${userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || userID==='t4gFVUQejYQYBY6dhwOp2mkYMTe2' || userID==='WbahZZa5HhfWAa6SyJLYnl31kMG3' || template === 'vintage' ?"Adult":"kids"}`, //Google Shopping / Age Group
        "", //Google Shopping / MPN
        "", //Google Shopping / AdWords Grouping
        "", //Google Shopping / AdWords Labels
        condition[i], //Google Shopping / Condition
        "", //Google Shopping / Custom Product
        "", //Google Shopping / Custom Label 0
        "", //Google Shopping / Custom Label 1
        "", //Google Shopping / Custom Label 2
        "", //Google Shopping / Custom Label 3
        "", //Google Shopping / Custom Label 4
        s3Images[i], //Variant Image
        `${weightUnit}`, //Variant Weight Unit
        "", //Variant Tax Code
        costPerItem[i], //Cost per item	////////////////// 
        price[i], //Price / International 
        "", //Compare At Price / International
        `${status}`, //Status
        `${collection}`,
				isImport
      ]);
      // if(supplementaryImages[i] && supplementaryImages[i].length>0  Array.isArray(supplementaryImages[i])) {
      if(supplementaryImages[i] && supplementaryImages[i].length>0) {
        supplementaryImages[i].forEach((imageLink, index)=>{
          csv.push([
            `${handle}`, //Handle
            ``, ``, ``, ``, ``, ``, "", ``, ``, ``, ``, "", '', "", "", "", "", "", "", ``, "", "", ``, "", 
            imageLink,
            [index+2], //Image Position
            ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, ``, "", ``, ``, "", ``, ``, false
          ]);
        })
        }
    }    
    let csvData = []
    csvData = await createSEOcsv(csv)
    
		console.log(csvData)
    return csvData;
    
  };

  const createSEOcsv = async (csvData) => {
    let returnData = csvData
    try {      
      const res = await backendClient.post("/csv", {
        args: csvData,
        user: "userID"
      });            
      returnData = res.data
      returnData.unshift([
        "Handle",
        "Title",
        "Body (HTML)",
        "Vendor",
        "Standardized Product Type",
        "Custom Product Type",
        "Tags",
        "Published",
        "Option1 Name",
        "Option1 Value",
        "Option2 Name",
        "Option2 Value",
        "Option3 Name",
        "Option3 Value",
        "Variant SKU",
        "Variant Grams",
        "Variant Inventory Tracker",
        "Variant Inventory Qty",
        "Variant Inventory Policy",
        "Variant Fulfillment Service",
        "Variant Price",
        "Variant Compare At Price",
        "Variant Requires Shipping",
        "Variant Taxable",
        "Variant Barcode",
        "Image Src",
        "Image Position",
        "Image Alt Text",
        "Gift Card",
        "SEO Title",
        "SEO Description",
        "Google Shopping / Google Product Category",
        "Google Shopping / Gender",
        "Google Shopping / Age Group",
        "Google Shopping / MPN",
        "Google Shopping / AdWords Grouping",
        "Google Shopping / AdWords Labels",
        "Google Shopping / Condition",
        "Google Shopping / Custom Product",
        "Google Shopping / Custom Label 0",
        "Google Shopping / Custom Label 1",
        "Google Shopping / Custom Label 2",
        "Google Shopping / Custom Label 3",
        "Google Shopping / Custom Label 4",
        "Variant Image",
        "Variant Weight Unit",
        "Variant Tax Code",
        "Cost per item",
        "Price / International",
        "Compare At Price / International",
        "Status",
        "Collection"
      ]);
      
      return returnData
    } catch (e) {
      console.log("Please try using a different image");
      console.error(e);
      return []
    }
  }

  export default oldCreateCSV;