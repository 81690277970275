const real = [
    "Genuine",
    "Authentic",
    "Natural",
    "Actual",
    "Authentic",
    "Legitimate",
    "True",
    "Non-Synthetic",
    'real'
  ];

  export const hoodFurType = [real]