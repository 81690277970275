import React from 'react'
import SideMenu from '../components/sideMenu'
import { Header } from '../components/navigation/header'

const SellerHome = () => {
  return (
    <div>
        <Header hideButton/>
        <SideMenu />
    </div>
  )
}

export default SellerHome