const knit = [
    "Weave",
    "Stitch",
    "Entwine",
    "Braid",
    "knit"
  ];
  
  const adjustable = [
    "Adaptable",
    "Flexible",
    "Customizable",
    "Modifiable",
    "Variable",
    "Versatile",
    "Changeable",
    "Alterable",
    "Tailorable",
    "adjustable"
  ];
  
  const open = [
    "Loose",
    "Unsecured",
    "Unbuttoned",
    "Unrestricted",
    "Unencumbered",
    "Unrestrained",
    "Free",
    "Unhindered",
    "open"
  ];
  
  const elastic = [
    "Stretchy",
    "Flexible",
    "Resilient",
    "Bouncy",
    "Pliable",
    "Springy",
    "elastic"
  ];

  export const sleeveCuffs = [knit, adjustable, open, elastic]