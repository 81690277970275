const full = [
    "Whole",
    "Complete",
    "Full",
    "Entire",
    "Total",
    "Absolute",
    "Comprehensive",
    "Exhaustive",
    "Thorough",
    "Perfect",
    "Sufficient",
    "Inclusive",
    "Undivided",
    "All-encompassing",
    "full"
  ];
  
  const partial = [
    "Incomplete",
    "Limited",
    "Restricted",
    "Selective",
    "Segmented",
    "Partway",
    "Fractional",
    "Unfinished",
    "partial"
  ];
  
  const none = [
    "Zero",
    "Lack",
    "Absence",
    "Without",
    "No",
    "Non",
    "Missing",
    "none"
  ];

  export const liningCoverage = [full, partial, none]