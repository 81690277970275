const fall = [
    "Autumn",
    "September",
    "October",
    "November",
    "Cool",
    "Crisp",
    "fall"
  ];
  
  const winter = [
    "December",
    "January",
    "February",
    "Cold",
    "Frosty",
    "Frigid",
    "winter"
  ];
  
  const spring = [
    "March",
    "April",
    "May",
    "Springtime",
    "Warm",
    "winter"
  ];
  
  const summer = [
    "June",
    "July",
    "August",
    "Summertime",
    "Hot",
    "summer"
  ];

  const synonymsArray = [fall, winter, spring, summer]

export const getSeasonSynonyms = (season) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => season.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 