const { default: axios } = require('axios');

export const updateProjectStatusAsExported = async (projectType, projectId) => {
	const localhost = 'http://localhost:3500/storeData';
	const snapwrite = 'https://apie.snapwrite.ca/storeData';
	const baseEndpoint = snapwrite;
	let specificEndpoint;

	const endpoints = {
		vintage: `${baseEndpoint}/vintage-apparel-projectStatusUpdate`,
		preLovedKids: `${baseEndpoint}/labeling-clothes-projectStatusUpdate`,
		'new-clothes': `${baseEndpoint}/new-apparel-projectStatusUpdate`,
		'pre-loved-regular': `${baseEndpoint}/regular-preLoved-projectStatusUpdate`,
		themes: `${baseEndpoint}/update-theme-project-status`,
	};

	specificEndpoint = endpoints[projectType] || baseEndpoint;

	const data =
		projectType === 'themes'
			? { id: projectId, isProcessed: true }
			: { id: projectId, projectCSVGenerated: true };

	await axios
		.put(specificEndpoint, data)
		.catch((error) => console.log(error));
};
// Function to format Theme Data to old data structure
export const formattedThemeData = (productsByTheme) => {
	const oldDataStructure = {};

	const notArrayVal = ['__v', 'userID', 'createdAt', 'organizationID'];

	for (const key in productsByTheme) {
		if (key !== 'items') {
			oldDataStructure[key] = productsByTheme[key];
		} else if (productsByTheme.hasOwnProperty('items')) {
			const projectItems = productsByTheme['items'];
			projectItems.forEach((item) => {
				for (const itemKey in item) {
					if (item.hasOwnProperty(itemKey)) {
						if (itemKey === '_id') {
							if (oldDataStructure.hasOwnProperty('itemsID')) {
								oldDataStructure.itemsID.push(item[itemKey]);
							} else {
								oldDataStructure.itemsID = [item[itemKey]];
							}
						} else if (notArrayVal.includes(itemKey)) {
							oldDataStructure[itemKey] = item[itemKey];
						} else {
							if (oldDataStructure.hasOwnProperty(itemKey)) {
								oldDataStructure[itemKey].push(item[itemKey]);
							} else {
								oldDataStructure[itemKey] = [item[itemKey]];
							}
						}
					}
				}
			});
		}
	}

	return oldDataStructure;
};

export function transformDataByItem(originalData) {
	const formattedData = [];
	if (originalData && originalData.images) {
		const length = originalData.images.length;
		for (let i = 0; i < length; i++) {
			const obj = {};
			for (const key in originalData) {
				if (Array.isArray(originalData[key])) {
					obj[key] = originalData[key][i];
				} else {
					obj[key] = originalData[key];
				}
				obj['index'] = i;
			}
			formattedData.push(obj);
		}
	}
	return formattedData;
}