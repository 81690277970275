import React,{useEffect, useState} from 'react';
import { getAuth } from '@firebase/auth';
import GetData from '../projectLibrary/getData';
import ProductCard from '../projectLibrary/projectCard';


const ImageDescriptionData = () => {

  const [specDecriptions, setSpecDescriptions] = useState(["image descriptions"])  

  
const  [lastIndex, setLastIndex] = useState(4)
GetData(setSpecDescriptions, 'image-description')


      const items =
        specDecriptions.slice(0,lastIndex).map((description)=>
        <ProductCard id={description._id} name={description.projectName} path="image-description" date={description.dateCreated} image={description.imageUploaded}/>)
        
    return(
      <>
        <p className='projectsSubHeader'>Standard</p>        
      <div  className='listings' style={specDecriptions.length === 2 ? {justifyContent:"space-evenly"}: {justifyContent:"space-between"}}>
        {items}
        </div>
        {
          specDecriptions.length > 4 && specDecriptions.length > lastIndex ?
          <div className="showMoreButton">
        <button className="showMore" onClick={()=>{ setLastIndex(lastIndex+4)}}>Show more</button>
        </div>:null
        }
        <br />
        
        </>
    )
}

export default ImageDescriptionData