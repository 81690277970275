import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import { Header } from "../../../../../components/navigation/header";
import SideMenu from "../../../../../components/sideMenu";
import { createManteauxCSV } from "./createManteauxCSV";
import { createMagentoCSV } from "./createMagentoCSV";
import updateColumnValue from "../../utils/updateAttribute";
import TagTable from "./tagTable";
import { OrganizationContext } from "../../../../../components/App";
import { saveAs } from 'file-saver';
import MmTables from "./mmTables";
import deleteItems from "../../utils/deleteItems";
import { Button } from "@mui/material";
import { DeleteOutlineTwoTone } from "@mui/icons-material";

import PlusIcon from "../../../../../assets/PlusIcon.png"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ImagesUploadContainer from "../../tableComponents/ImagesUploadContainer";
import MainSideMenuHeaderContainer from "../../../../../components/containers/MainSideMenuHeaderContainer";



const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});




export const TagUploads = (props) => {

  const endpoint = props.collection === "mm-coats" ? "mm-table" : props.collection

	const {organization, setOrganization} = useContext(OrganizationContext)

  const [isLoading, setLoading] = useState(false);
  const [waitingText, setWaitingText] = useState("Loading...");
  const [csvDownload, SetCsvDownload] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [objectID, setObjectID] = useState("");
  const [imageFileName, setImageFileName] = useState([]);
  const [season , setSeason] = useState(null)

  //Images
  const [s3Images, setS3Images] = useState([]);
  const [sideImages, setSideImages] = useState([]);
  const [backImages, setBackImages] = useState([]);
  const [itemImages, setItemImages] = useState([]);
  const [supplementaryImages, setSupplementaryImages] = useState([]);

  const [notes, setNotes] = useState([])

//Titles and Dedscriptions
  const [titleEN, setTitleEN] = useState([])
  const [titleFR, setTitleFR] = useState([])
  const [descriptionEN, setDescriptionEN] = useState([]);
  const [descriptionFR, setDescriptionFR] = useState([]);

  const [sku, setSku] = useState([]);

  const [temp, setTemp] = useState([]);
  const [modelHeight, setModelHeight] = useState([]);
  const [modelSize, setModelSize] = useState([]);
  const [supplierCode, setSupplierCode] = useState([]);
  const [supplierName, setSupplierName] = useState([]);
  const [brand, setBrand] = useState([]);
  const [styleCode, setStyleCode] = useState([]);
  const [itemClass, setItemClass] = useState([]);
  const [webCode, setWebCode] = useState([]);
  const [colorEN, setColorEN] = useState([]);
  const [colorFR, setColorFR] = useState([]);
  const [careInstructions, setCareInstructions] = useState([])
  const [technicalDetails, setTechnicalDetails] = useState([])
  
  //R1
  const [retail1Description, setRetail1Description] = useState([]);
  const [length, setLength] = useState([]);
  const [inches, setInches] = useState([]);
  
  //Attributes
  const [closure, setClosure] = useState([])
  const [hood, setHood] = useState([])
  const [sleeve, setSleeve] = useState([])
  const [sleeveLength, setSleeveLength] = useState([])
  const [fabric, setFabric] = useState([])
  const [pocket, setPocket] = useState([])
  const [drawstring, setDrawstring] = useState([])
  const [collar, setCollar] = useState([])
  const [lining, setLining] = useState([])
  const [fit, setFit] = useState([])
  const [interiorPockets, setInteriorPockets] = useState([])
  const [neckline, setNeckline] =  useState([])
  const [pattern, setPattern] = useState([])
  const [skirt, setSkirt] = useState([])
  const [exteriorMaterial, setExteriorMaterial] = useState([])
  const [liningMaterial, setLiningMaterial] = useState([])

  
  const [closureFR, setClosureFR] = useState([])
  const [hoodFR, setHoodFR] = useState([])
  const [sleeveFR, setSleeveFR] = useState([])

  const userID =  organization?.id || props.userID;
  const templateTemp = "preLovedKids";
  const { urlID } = useParams()
  const history = useHistory()
  const currentPath = history.location.pathname
  const isURLIdUploadPage = ["new", "h3", ""].includes(urlID);

   //Error Handling for Backend Client
   const [backendAlertMessage, setBackendAlertMessage] = useState({
    display: false,
    message: "",
  });
  const [checkedItemsIndicesArray, setCheckedItemsIndicesArray] = useState([]);

  const seasonNewProject = useMemo(() => {
    if (currentPath === '/mm-coats/new') {
      return 'p4-coats';
    } else if (currentPath === '/mm-sports/new') {
      return 'p4-sports';
    } else if (currentPath === '/mm-coats/h3/new') {
      return 'h3-coats';
    } else if (currentPath === '/mm-sports/h3/new') {
      return 'h3-sports';
    }
  }, [currentPath]);


  const getStoredObjectID = () => {
    const seasonMap = {
        '/mm-coats/new': 'p4-coats',
        '/mm-sports/new': 'p4-sports',
        '/mm-coats/h3/new': 'h3-coats',
        '/mm-sports/h3/new': 'h3-sports'
    };

    const season = seasonMap[currentPath] || '';
    const storedKey = `objectIDManteauxManteaux-${season}-${endpoint}-${userID}`;
    return JSON.parse(localStorage.getItem(storedKey));
};

const getSeason = () => {
  const seasonMap = {
      '/mm-coats/new': 'P4',
      '/mm-sports/new': 'P4',
      '/mm-coats/h3/new': 'H3',
      '/mm-sports/h3/new': 'H3'
  };
  const season = seasonMap[currentPath] || '';
  return season;
}



useEffect(() => {
  const storedObjectID = getStoredObjectID();
  
  let objectIdToSet = null;
  
  if (isURLIdUploadPage && storedObjectID) {
    objectIdToSet = storedObjectID;
  } else if (isURLIdUploadPage) {
    setS3Images([]); // Avoid tables from rendering before reset() is called
    objectIdToSet = null;
  } else {
    objectIdToSet = urlID;
  }
  setObjectID(objectIdToSet);
}, [currentPath]);


useEffect(() => {
	const { lastPath } = history.location.state || {};

	const handleBackButton = () => {
		if (lastPath) {
			history.push(lastPath, { lastProjectCardID: objectID });
		}
	};

	history.listen((location, action) => {
		if (action === 'POP') {
			handleBackButton();
		}
	});
}, [history, objectID]);




  useEffect(() => {
    if (objectID && objectID !== "" && objectID !== "new") {
      // console.log("objectID", objectID)
      setLoading(true);
      fetch(`https://apie.snapwrite.ca/storeData/${endpoint}/${objectID}`)
        .then((response) => response.json())
        .then((json) => {
          // console.log(json.data)
          setS3Images(json.data.s3Images);
          setTitleEN(json.data.titleEN)
          setTitleFR(json.data.titleFR)
          // console.log(json.data.careInstructions)
          setCareInstructions(json.data.careInstructions)
          setTemp(json.data.temperature)
          // console.log("HEIGHT: ", json.data.modelHeight.map((str) => str.split('').reverse().join('').replace("'", '"', 1).split('').reverse().join('')))
          setModelHeight(json.data.modelHeight.map((str) => str.replace(/'$/,'"')))
          setModelSize(json.data.modelSize)
          setItemImages(json.data.itemImages)
          setSideImages(json.data.sideImages)
          setProjectName(json.data.projectName || "")
          
          setBackImages(json.data.backImages)
          setSupplierCode(json.data.supplierCode);
          setSupplierName(json.data.supplierName);
          setSku(json.data.sku);
          setFit(json.data.fit || Array(json.data.s3Images.length).fill("Regular Fit"))
          setPattern(json.data.pattern || Array(json.data.s3Images.length).fill(""))
          setSkirt(json.data.skirt || Array(json.data.s3Images.length).fill(""))
          setNeckline(json.data.neckline || Array(json.data.s3Images.length).fill(""))
          setInteriorPockets(json.data.interiorPockets || Array(json.data.s3Images.length).fill(""))
          setLining(json.data.lining || Array(json.data.s3Images.length).fill("No Lining"))
          setTechnicalDetails(json.data.technicalDetails || Array(json.data.s3Images.length).fill("") )
          setNotes(json.data.notes)
          setClosure(json.data.closure)
          setHood(json.data.hood)
          setExteriorMaterial(json.data.exteriorMaterial || Array(json.data.s3Images.length).fill(""))
          setLiningMaterial(json.data.liningMaterial || Array(json.data.s3Images.length).fill(""))
          setSleeve(json.data.sleeve)
          setSleeveLength(json.data.sleeveLength || Array(json.data.s3Images.length).fill(""))
          setItemClass(json.data.itemClass);
          setWebCode(json.data.web_class_code)
          setPocket(json.data.pocket || Array(json.data.s3Images.length).fill(""))
          setDrawstring(json.data.drawstring || Array(json.data.s3Images.length).fill(""))
          setCollar(json.data.collar || Array(json.data.s3Images.length).fill(""))


          setStyleCode(json.data.styleCode);
          setBrand(json.data.brand);
          setRetail1Description(json.data.retail1Description);
          setColorEN(json.data.colorEN);
          setColorFR(json.data.colorFR);
          setDescriptionEN(json.data.descriptionEN);
          setDescriptionFR(json.data.descriptionFR);
          setLength(json.data.length)
          // console.log(json.data.length)
          setInches(json.data.lengthInches)
          setFabric(json.data.fabric) 
          setSeason(json.data.season)
          setLoading(false);
        })
        .catch((err) => {
          setWaitingText(
            'Please click on the "New" button in the top right corner'
          );
          console.log(`Error ${err}`);
        });
    }

    if (objectID === "new" || objectID === null ) {
      // console.log("new", objectID)
      reset()
    }
  }, [objectID, csvDownload]);


  function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const uploadImage = async (e, str) => {
    e.preventDefault();
    // setLoading(true);
    let file = e.target.files;
    if (str === "drop") {
      file = e.dataTransfer.files;
    }
    //for aws images link
    const imageURL = [];
    let fileArray = [];
    


    for (let fileIdx in file) {
      if(isNumeric(fileIdx)) {
              if (!isNumeric(fileIdx)) break;
              let newFile = file[fileIdx];
              let newCleanFile = { name: newFile.name.split(".")[0], newFile }; // remove .png, .webp
              fileArray.push(newCleanFile);
      }
     
    }

    fileArray.sort((fileA, fileB) => {
      // cluster same names together
      const codesA = fileA.name.split(/[-_]/)
      const codesB = fileB.name.split(/[-_]/)

      const noCodesA = codesA.slice(1).join('').replace(/^\d+/, '')
      const noCodesB = codesB.slice(1).join('').replace(/^\d+/, '')

      const lastNumberA = (noCodesA.match(/\d/g) || ["0"]).pop();
      const lastNumberB = (noCodesB.match(/\d/g) || ["0"]).pop();

      const nameA = codesA[0] + codesA[1] + lastNumberA
      const nameB = codesB[0] + codesB[1] + lastNumberB
      

      
      if (nameA > nameB) return 1;
      else if (nameA < nameB) return -1;
      return 0;
    });

    sortTagAndClothingImages(fileArray)
    .then(async (sortedImageArrays) => {
      if(sortedImageArrays === "imageNameError"){
        // setLoading(false);
        return
      }

      const imageArrays = {
        tagImages: sortedImageArrays[0],
        frontImages: sortedImageArrays[1],
        backImages: sortedImageArrays[2],
        sideImages: sortedImageArrays[3]
      }

      if (sortedImageArrays[0].length === 0) {
        alert("Rows could not be uploaded. Please ensure you are uploading a front, back, or side view with each item")
      }
      else {
        let requestData = []
        console.log(sortedImageArrays)
        if (sortedImageArrays[1].length > 2) {          
          let breakPoint = 0
          let startIndex = 0
          let copyImageURL = sortedImageArrays[1]
          while (copyImageURL.length > 0) {
            console.log("copyImageURL length: ", copyImageURL.length)            
            // console.log("copyImageURL: ", copyImageURL)
            if (copyImageURL.length > 1) {
                breakPoint+=2
                console.log("breakPoint: ", breakPoint)
                requestData.push(
                  {
                    tagImages: sortedImageArrays[0].slice(startIndex, breakPoint),
                    frontImages: sortedImageArrays[1].slice(startIndex, breakPoint),
                    backImages: sortedImageArrays[2].slice(startIndex, breakPoint),
                    sideImages: sortedImageArrays[3].slice(startIndex, breakPoint),
                    style_code: sortedImageArrays[5].slice(startIndex, breakPoint),
                    supplier_code: sortedImageArrays[4].slice(startIndex, breakPoint),
                  }
                  )
                copyImageURL = copyImageURL.slice(2)
                startIndex += 2
                console.log("copyImageURL: ", copyImageURL)
            } else {
              requestData.push({
                  tagImages: sortedImageArrays[0].slice(-1),
                  frontImages: sortedImageArrays[1].slice(-1),
                  backImages: sortedImageArrays[2].slice(-1),
                  sideImages: sortedImageArrays[3].slice(-1),
                  style_code: sortedImageArrays[5].slice(-1),
                  supplier_code: sortedImageArrays[4].slice(-1),
                })
                copyImageURL = []
            }
          }
        } else {
          requestData.push({
            tagImages: sortedImageArrays[0],
            frontImages: sortedImageArrays[1],
            backImages: sortedImageArrays[2],
            sideImages: sortedImageArrays[3],
            style_code: sortedImageArrays[5],
            supplier_code: sortedImageArrays[4],
          })
        }
        const result = []
        // const defaultReturn = {
        //     "supplier_code": "",
        //     "supplier_name": "",
        //     "brand_name": "",
        //     "style_code": "",
        //     "color_options": [""],
        //     "style_name": "",
        //     "class_number": "",
        //     "length_inches": "",
        //     "length_cm": "",
        //     "web_category": {
        //         "department": "",
        //         "className": "",
        //         "category": "",
        //         "category_code": "",
        //         "fit": ""
        //     },
        //     "text_data": "{\"title\": {\"en\": \"\", \"fr\": \"\"}, \"description\": {\"en\":\"\", \"fr\":\"\"}}",
        //     "closure": "",
        //     "drawstring": "",
        //     "sleeve_cuffs": "",        
        //     "pockets":"",
        //     "hood": "",
        //     "collor": "",
        //     "neckline": "",
        //     "sleeves": "",
        //     "pattern": "",
        //     "skirt": "",
        //     "technicalDetails": "",
    
        // }
        const promises = requestData.map(async (obj) => {
          const response = await backendClient
                                  .post('/mm', {
                                      ...obj,
                                      user: userID,
                                      collection: props.collection,
                                      season: season || seasonNewProject
                                  })
                                  .catch((err) => {
                                      console.log(err) 
                                  });
          // console.log(response)
          // console.log(response.data)


          result.push(...response.data);
          // console.log(result)
      });
        await Promise.all(promises);

        if (sortedImageArrays[6].length > 0) {
          alert(`Items ${sortedImageArrays[6]} were missing either a front, back, or side view, and could not be uploaded`)
        }

        await setMMStates(result, sortedImageArrays[0], sortedImageArrays[1], sortedImageArrays[2], sortedImageArrays[3]);
      setLoading(false);

        if(invalidStyleCodeList.length > 0){
          setBackendAlertMessage({
            ...backendAlertMessage,
            display: true,
            message: `The following style codes could not be uploaded as they are not present in the ${getSeason()} season database: ${invalidStyleCodeList.join(", ")}`,
          });
          invalidStyleCodeList.length = 0;
        }
      }
    })

    
  };

  const updateValIndividual = (name, event, index, key, secondIndex) => {
    const singleProductModelFilter = {userID, sku: sku[index]};
    // console.log("function", secondIndex)
    // console.log("updating")
    const newVal = event;
    if (isNaN(event) && (name!== "modelHeight" || key!=="modelHeight")) {
      if (event.includes(`"`)) {
        event = event.replace(/"/g, `'`);
      }
    }
    if (newVal !== null && newVal !== undefined && objectID !== "" && objectID !== undefined) {
      updateColumnValue(singleProductModelFilter,props.collection, name, event, objectID, index, key, false, secondIndex);
    }
  };

  
  const sortTagAndClothingImages = async (fileArray) => {
    
		let tagURLs = []; // files of tag images
    let frontImageURLs = []; 
    let sideImageURLs = [];
    let backImageURLs = [];
    
    let lastFileName = ["", ""]
    let currentFileName = ""
    let rowCount = 0

    let rowImageCount = 0

    let styleCodes = []
    let supplierCodes = []
    const removedItems = []
    let lastItemSkipped = false


    for (let index = 0; index < fileArray.length; index++) { // for each image file
      
      currentFileName = fileArray[index].name.split(/ /); // remove _1, _2, _3
      const codes = fileArray[index].name.split(/[-_]/)
      const nextCodes = fileArray[index + 1] ? fileArray[index + 1].name.split(/[-_]/) : ["", ""]
      const imageSuppCode = codes[0]
      let imageStyleCode = codes[1]
      if(imageStyleCode==="MF30" || imageStyleCode==="SAF"){
        imageStyleCode +="-" + codes[2]         
      }

      
      if ((imageSuppCode + imageStyleCode) !== (lastFileName[0] + lastFileName[1])) {
        // console.log(removedItems)
        if(rowCount > 0 && !lastItemSkipped){
          if(sideImageURLs.length<rowCount - removedItems.length){

            sideImageURLs.push("")
          }
          if(backImageURLs.length<rowCount - removedItems.length){
            backImageURLs.push("")
          }
          if(frontImageURLs.length<rowCount - removedItems.length){
            frontImageURLs.push("")
          }
          if(tagURLs.length<rowCount - removedItems.length){
            tagURLs.push("")
          }
        }
        rowCount++
        rowImageCount=0
        const regex = /^([^-]+)-([A-Za-z\d]+)/;
        const matches = regex.exec(currentFileName[0]);
        if (matches) {
          setLoading(true)
          const vendor = matches[1].toLocaleUpperCase();
          let styleNumber = matches[2].toLocaleUpperCase();
          if(styleNumber==="MF30" || styleNumber==="SAF"){
            styleNumber +="-" + codes[2]         
          }
          supplierCodes.push(vendor);
          styleCodes.push(styleNumber);
        } else {
          alert(`Filename: ${currentFileName[0]} doesn't match the expected pattern. Please try Again`);
          return "imageNameError"
        }
      }

      if (!currentFileName[0].includes("tag")) {
        rowImageCount++
      } 
      lastFileName = [imageSuppCode, imageStyleCode]

      const { url } = await fetch(
        "https://apie.snapwrite.ca/storeData/s3Url"
      ).then((res) => res.json());
      // // console.log(url)
      // let blobData = new Blob([new Uint8Array(imageFile)], {type: 'image/jpeg'})
      // post the image direclty to the s3 bucket
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
        },
        body: fileArray[index].newFile,
      }).catch((err) => console.error(err));

      let currentURL = url.split("?")[0];
      
      lastItemSkipped = false
      if (currentFileName[0].includes("tag")) {
        if (rowImageCount === 0 && ((imageSuppCode + imageStyleCode) !== (nextCodes[0] + nextCodes[1]))) {
          removedItems.push(`${imageSuppCode}-${imageStyleCode}`)
          styleCodes.pop()
          supplierCodes.pop()
          lastItemSkipped = true
          continue
        }
        else {
          tagURLs.push(currentURL)
        }
      } 
      else {
        if (rowImageCount===1) {
          frontImageURLs.push(currentURL)
        }
  
        if (rowImageCount === 2) {

          sideImageURLs.push(currentURL)
        }
      
        if (rowImageCount === 3) {

          backImageURLs.push(currentURL)
        } 
      }
     
      if((fileArray.length - 1) === index && !lastItemSkipped) {
        if(sideImageURLs.length<rowCount - removedItems.length){
          sideImageURLs.push("")
        }
        if(backImageURLs.length<rowCount - removedItems.length){
          backImageURLs.push("")
        }
        if(frontImageURLs.length<rowCount - removedItems.length){
          frontImageURLs.push("")
        }
        if(tagURLs.length<rowCount - removedItems.length){
          tagURLs.push("")
        }
      }

      // console.log(frontImageURLs, backImageURLs, sideImageURLs, tagURLs)
    }

    return [tagURLs, frontImageURLs, backImageURLs, sideImageURLs, supplierCodes, styleCodes, removedItems] 
  }

  const reset = () => {
    localStorage.removeItem(`objectIDManteauxManteaux-${seasonNewProject}-${endpoint}-${userID}`);    
    setProjectName("");

    //Images
    setS3Images([]);
    setSupplementaryImages([]);
    setImageFileName([]);
    setBackImages([])
    setSideImages([])
    setItemImages([])

    //R1
    setLength([])
    setInches([])

    // Manual or Drop Downs
    setCareInstructions([])
    setNotes([])
    setModelHeight([]);
    setModelSize([]);
    setTemp([]);

    // Supplier
    setBrand([]);
    
    setSku([]);
    setFit([])
    setSkirt([])
    setPattern([])
    setNeckline([])
    setInteriorPockets([])
    setLining([])
    setTechnicalDetails([])
    setPocket([])
    setDrawstring([])
    setCollar([])
    setExteriorMaterial([])
    setLiningMaterial([])

    //Attributes
    setClosure([]);
    setHood([])
    setSleeve([])
    setSleeveLength([])
    setClosureFR([]);
    setHoodFR([])
    setSleeveFR([])
    setFabric([])


    //Titles and descriptions
    setTitleEN([]);
    setTitleFR([]);

    
    setObjectID("")
    setWaitingText("")
    setLoading(false);
    season && setSeason(null)
  };

  
  const invalidStyleCodeList = [];

  const setMMStates = async (json, tagImages, clothingImages, backImagesArr, sideImagesArr) => {
  const isAllImageUploadedWithInvalidStyleCode = json.every((item) => item.isStyleValid === false)

  if(isAllImageUploadedWithInvalidStyleCode){
    setBackendAlertMessage({
      ...backendAlertMessage,
      display: true,
      message: `The following style codes could not be uploaded as they are not present in the ${getSeason()} season database: ${json.map((item) => item.style_code).join(", ")}`,
    });
    return
  }

    const frontImagesCopy = [];
    const tagImagesCopy = []
    const backImagesCopy = []
    const sideImagesCopy = []
    let supplierNames = [];
    let supplierCodes = []
    let styleCodes = []
    let skus = [];

    let drawstrings = []
    let notesArr = []
    let modelHeights = [];
    let modelSizes = [];
    let temps = []    
    let itemClasses = [];
    let webCodes = []
    let brands = [];
    let retail1Descriptions = [];
    let colorENs = [];
    let colorFRs = [];
    let descriptionENs = [];
    let descriptionFRs = [];
    let titleEn = [];
    let titleFr = [];
    let closures =[];
    let closuresFR = [];
    let sleeve_cuffsFR = [];
    let hoodsFR = [];
    let careInstruction = []
    let technicalDetail = []
    let exteriorMaterials = []
    let liningMaterials = []
    let sleeve_cuffs=[];
    let sleeveLengths=[]
    let hoods = []
    let collar = [];
    let lining = [];
    let pocket = [];
    let fastening_type = []
    let fits = []
    let skirts =[]
    let patterns = []
    let necklines = []
    let interiorPockets = []
    let fabric_composition = []
    let lengths = []
    let lengthsInches = []

    const veganBrands = ["SAKI", "NVLT", "BERNARDO", "NORTHSIDE", "POINT ZERO", "BIG CHILL", "LOOP", "SNOBOLL", "ALPINE", "OXYGEN", "DETAILS", "SEBBY"]


    json.forEach((item, index) => {
      // Check if Style Code is Present in the Season DB
      console.log(!item.isStyleValid)
      if(!item.isStyleValid){
        invalidStyleCodeList.push(item.style_code)
        return
      }
      
        const lang1 = JSON.parse(JSON.stringify(item.text_data))

        const lang = JSON.parse(lang1)
        
        // const language_object =  JSON.parse(JSON.stringify(item.text_data))


        //Images
        // imagesCopy.push(images[index]);
        frontImagesCopy.push(item.frontImage);
        tagImagesCopy.push(item.tagImage);
        backImagesCopy.push(item.backImage);
        sideImagesCopy.push(item.sideImage);

        //supplier data
        supplierNames.push(item.supplier_name);
        supplierCodes.push(item.supplier_code)
        brands.push(item.brand_name);


        //style Data
        styleCodes.push(item.style_code);

        //Class Data / Web Categories
        itemClasses.push(item.class_number);
        webCodes.push(item.web_category.category_code);
        fits.push(item.web_category.fit)
        patterns.push(item.pattern || "")
        skirts.push(item.skirt || "")
        necklines.push(item.neckline || "")

        // titles and descriptions
        if(item.brand_name && veganBrands.includes(item.brand_name) && 
           (item.web_category.department !== "Wool" && item.web_category.department !== "Duvet" && item.web_category.department!=="Laine / Wool")) 
          {          
            titleEn.push("Vegan " + lang.title.en.trim())
            titleFr.push("Végan " + lang.title.fr.trim())          
          }        
        else {
          titleEn.push(lang.title.en.trim())
          titleFr.push(lang.title.fr.trim())
        }
        descriptionENs.push(lang.description.en.trim());
        descriptionFRs.push(lang.description.fr.trim());
       
        // R1
        retail1Descriptions.push(item.style_name);
        // lengths.push(Math.floor(parseFloat(item.length_cm)).toString())
        let length_cm_validity = item.length_cm && item.length_cm!=="" ? item.length_cm.toFixed(1) : ""
        lengths.push(length_cm_validity)
        lengthsInches.push(item.length_inches)

        //Colors
        colorENs.push(item.color_options);
        colorFRs.push(item.color_options);

        

        //Attributes
        if (props.collection==="mm-coats") {
          lining.push(item.lining)
          pocket.push(item.pockets)
          sleeve_cuffsFR.push(item.sleeve_cuffs)
          collar.push(item.collor)
          hoods.push(item.hood)
          hoodsFR.push(item.hood)

        } else {
          lining.push("")
          pocket.push("")
          sleeve_cuffsFR.push("")
          collar.push("")
          hoods.push("")
          hoodsFR.push("")

        }
        closures.push(item.closure)
        sleeveLengths.push(item.sleeves || "")
        if(item.web_category.department === "Wool" && item.web_category.department === "Laine / Wool") {
          careInstruction.push("Dry clean only")
        } else {
          careInstruction.push("")
        }
        technicalDetail.push(item.technicalDetails || "")
        exteriorMaterials.push("")
        liningMaterials.push("")
        hoods.push(item.hood)
        sleeve_cuffs.push(item.sleeve_cuffs)
        closuresFR.push(item.closure)
        fabric_composition.push(item.fabric_composition)
        drawstrings.push(item.drawstring);
    });

    const body = {
      userID: userID,
      titleEN: titleEn,
      titleFR: titleFr,
      careInstructions: careInstruction,
      temperature: Array(clothingImages.length).fill("-5"),
      modelHeight: Array(clothingImages.length).fill("5'7\""),
      modelSize: Array(clothingImages.length).fill("S"),
      itemImages: tagImagesCopy, 
      sideImages: sideImagesCopy.flat().map((item) => [item]),
      backImages: backImagesCopy,
      s3Images: frontImagesCopy,
      supplierCode: supplierCodes,
      supplierName: supplierNames,
      sku: Array(clothingImages.length).fill(""),
      fit: fits,
      skirt: skirts,
      pattern: patterns,
      neckline: necklines,
      interiorPockets: Array(clothingImages.length).fill(""),
      lining: lining,
      technicalDetails: technicalDetail,
      pocket: pocket,
      collar: collar,
      drawstring: drawstrings,
      notes: Array(clothingImages.length).fill(""),
      closure: closures,
      hood: hoods,
      exteriorMaterial: exteriorMaterials,
      liningMaterial: liningMaterials,
      sleeve: sleeve_cuffs,
      sleeveLength: sleeveLengths,
      itemClass: itemClasses,
      web_class_code: webCodes,
      styleCode: styleCodes,
      brand: brands,
      fabric: fabric_composition,
      retail1Description: retail1Descriptions,
      colorEN: colorENs,
      colorFR: colorFRs,
      descriptionEN: descriptionENs,
      descriptionFR: descriptionFRs,
      length: lengths,
      lengthInches: lengthsInches,
      season:season || seasonNewProject
    }

    // Images 
    if (imagesHaventBeenUploaded()) {
      // console.log(tagImages, sideImagesArr, backImagesArr, clothingImages)
      // console.log("Images haven't been uploaded")
      // console.log(tagImages, sideImagesArr, backImagesArr, clothingImages)
      setItemImages(tagImagesCopy)
      setSideImages(sideImagesCopy)
      setBackImages(backImagesCopy)
      setS3Images(frontImagesCopy);

      //Titles and descriptions

      setTitleEN(titleEn)
      setTitleFR(titleFr)
      setDescriptionEN(descriptionENs);
      setDescriptionFR(descriptionFRs);

      // Manual or Drop Downs
      setCareInstructions(careInstruction)
      setTemp(Array(clothingImages.length).fill(""))
      setModelHeight(Array(clothingImages.length).fill("5'7\""))
      setModelSize(Array(clothingImages.length).fill("S"))
      setNotes(Array(clothingImages.length).fill(""))
      setTechnicalDetails(technicalDetail)

      // Attributes
      setClosure(closures)
      setHood(hoods)
      setLiningMaterial(liningMaterials)
      setExteriorMaterial(exteriorMaterials)
      setSleeve(sleeve_cuffs)
      setSleeveLength(sleeveLengths)
      setClosureFR(closuresFR)
      setHoodFR(hoodsFR)
      setSleeveFR(sleeve_cuffsFR)
      setFabric(fabric_composition)
      setInteriorPockets(Array(clothingImages.length).fill(""))
      setNeckline(necklines)
      setPattern(patterns)
      setSkirt(skirts)
      setFit(fits)
      
      //Supplier
      setSupplierName(supplierNames);
      setSupplierCode(supplierCodes);
      setBrand(brands);


      //Style
      setStyleCode(styleCodes);
      
      setSku(skus);
      setLining(lining)


      //Class
      setItemClass(itemClasses);
      setWebCode(webCodes)
      setRetail1Description(retail1Descriptions);
      setColorEN(colorENs);
      setColorFR(colorFRs);
      
      // R1 
      setLength(lengths)
      setInches(lengthsInches)
      setPocket(pocket)
      setDrawstring(drawstrings)
      setCollar(collar)

      const localhost = "http://localhost:3500"
      const snapwrite = "https://apie.snapwrite.ca"
      await axios.post(`${snapwrite}/storeData/${endpoint}`, body)
      .then(res => {
        console.log(res.data.data._id)
        setObjectID(res.data.data._id)

      const season = res.data.data.season

      // Constructing a unique key for the localStorage
      const localStorageKey = `objectIDManteauxManteaux-${season}-${endpoint}-${userID}`;
        // Converting the object to a JSON string before saving it to localStorage
        localStorage.setItem(localStorageKey, JSON.stringify(res.data.data._id));

        history.push(`/${props.collection}/${res.data.data._id}`)

      })
    }
    else {
      // $push wasn't working with the 2d arrays, change body value to include previous state and use $set instead
      const allSideImages = [...sideImages, ...sideImagesCopy].flat()

      body.sideImages = allSideImages.map((item) => [item]);
      body.colorFR = [...colorFR, ...colorFRs];
      body.colorEN = [...colorEN, ...colorENs];

      await axios
        .put(
          `https://apie.snapwrite.ca/storeData/${endpoint}-appendRows/${objectID}`,
          body
        )
        .then((res) => {
          const updatedData = res.data.data;

          setItemImages(updatedData.itemImages);
          setSideImages(updatedData.sideImages);
          setBackImages(updatedData.backImages);
          setS3Images(updatedData.s3Images);

          setTitleEN(updatedData.titleEN);
          setTitleFR(updatedData.titleFR);
          setDescriptionEN(updatedData.descriptionEN);
          setDescriptionFR(updatedData.descriptionFR);

          setCareInstructions(updatedData.careInstructions);
          setTemp(updatedData.temperature);
          setModelHeight(updatedData.modelHeight.map((str) => str.replace(/'$/,'"')));
          setModelSize(updatedData.modelSize);
          setNotes(updatedData.notes);
          setTechnicalDetails(updatedData.technicalDetails);

          setClosure(updatedData.closure);
          setHood(updatedData.hood);
          setLiningMaterial(updatedData.liningMaterial);
          setExteriorMaterial(updatedData.exteriorMaterial);
          setSleeve(updatedData.sleeve);
          setSleeveLength(updatedData.sleeveLength);
          setClosureFR(updatedData.closureFR);
          setHoodFR(updatedData.hoodFR);
          setSleeveFR(updatedData.sleeveFR);
          setFabric(updatedData.fabric);

          setInteriorPockets(updatedData.interiorPockets);
          setFit(updatedData.fit);
          setPattern(updatedData.pattern);
          setSkirt(updatedData.skirt);
          setNeckline(updatedData.neckline);

          setSupplierName(updatedData.supplierName);
          setSupplierCode(updatedData.supplierCode);
          setBrand(updatedData.brand);

          setStyleCode(updatedData.styleCode);

          setSku(updatedData.sku);
          setLining(updatedData.lining);

          setItemClass(updatedData.itemClass);
          setWebCode(updatedData.web_class_code);
          setRetail1Description(updatedData.retail1Description);
          setColorEN(updatedData.colorEN);
          setColorFR(updatedData.colorFR);

          setLength(updatedData.length);
          setInches(updatedData.lengthInches);
          setPocket(updatedData.pocket);
          setDrawstring(updatedData.drawstring);
          setCollar(updatedData.collar);
        })
        .catch((err) => {
          console.log(err);
        });

    }
    
  };

  const imagesHaventBeenUploaded = () => {
    return (s3Images && itemImages && backImages && sideImages && s3Images.length === 0 && sideImages.length === 0 && backImages.length === 0 && itemImages.length ===0)
  }

  const handleRowSelection = (e, index) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setCheckedItemsIndicesArray(prevArray => [...prevArray, index]);
    } else {
      // Remove the index from the checkedItemsIndicesArray for unselect
      const filteredItemsArray = checkedItemsIndicesArray.filter(
        (selectedIndex) => selectedIndex !== index
      );
      setCheckedItemsIndicesArray(filteredItemsArray);
    }
  }



  const generateCSVData = async (isImport = false, isMagentoCSV = false) => {
    setLoading(true);
    setWaitingText("Enhancing SEO. Please Wait for about a minute");
		const json = await fetch(`https://apie.snapwrite.ca/storeData/${endpoint}/${objectID}`)
			.then((response) => response.json())
    // console.log(organization.id)
    const preferences = await axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`)
    // console.log(preferences)
    const preferenceData = preferences.data.data

    // axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'exteriorMaterialOptions', val: [...new Set([...preferenceData.exteriorMaterialOptions, ...json.data.exteriorMaterial.filter(item => typeof item === 'string')])]})
    // axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'liningMaterialOptions', val: [...new Set([...preferenceData.liningMaterialOptions, ...json.data.liningMaterial.filter(item => typeof item === 'string')])]})
		  // axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'interiorPocketOptions', val: [...new Set([...preferenceData.interiorPocketOptions, ...json.data.interiorPockets.filter(item => typeof item === 'string')])]})
      if (props.collection === "mm-sports") {
		    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'sleeveOptions', val: [...new Set([...preferenceData.sleeveOptions, ...json.data.sleeveLength.filter(item => typeof item === 'string')])]})
		    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'skirtOptions', val: [...new Set([...preferenceData.skirtOptions, ...json.data.skirt.filter(item => typeof item === 'string')])]})
		    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'patternOptions', val: [...new Set([...preferenceData.patternOptions, ...json.data.pattern.filter(item => typeof item === 'string')])]})
        axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'necklineOptions', val: [...new Set([...json.data.neckline.filter(item => typeof item === 'string'), ...preferenceData.necklineOptions])]})
        
      }
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'sleeveCuffsOptions', val: [...new Set([...preferenceData.sleeveCuffsOptions, ...json.data.sleeve.filter(item => typeof item === 'string')])]})
      

		  axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'fitOptions', val: [...new Set([...preferenceData.fitOptions, ...json.data.fit.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'closureOptions', val: [...new Set([...json.data.closure.filter(item => typeof item === 'string'), ...preferenceData.closureOptions])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'hoodOptions', val: [...new Set([...preferenceData.hoodOptions, ...json.data.hood.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'drawstringOptions', val: [...new Set([...preferenceData.drawstringOptions, ...json.data.drawstring.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'pocketOptions', val: [...new Set([...preferenceData.pocketOptions, ...json.data.pocket.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'liningOptions', val: [...new Set([...preferenceData.liningOptions, ...json.data.lining.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'collarOptions', val: [...new Set([...preferenceData.collarOptions, ...json.data.collar.filter(item => typeof item === 'string')])]})
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'fabricOptions', val: [...new Set([...preferenceData.fabricOptions, ...json.data.fabric.filter(item => typeof item === 'string')])]})
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'careInstructionsOptions', val: [...new Set([...preferenceData.careInstructionsOptions, ...json.data.careInstructions.filter(item => typeof item === 'string')])]})
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'technicalDetailsOptions', val: [...new Set([...preferenceData.technicalDetailsOptions, ...json.data.technicalDetails.filter(item => typeof item === 'string')])]})

		const data = isMagentoCSV ? 
    await createMagentoCSV(
      season,
      props.collection,
			json.data.s3Images,
			json.data.itemImages,
			json.data.sideImages[0],
			json.data.backImages,
			json.data.supplierCode,
			json.data.supplierName,
			json.data.brand,
			json.data.styleCode,
			json.data.temperature,
			json.data.web_class_code,
			json.data.retail1Description,
			json.data.colorEN,
			json.data.colorFR,
			json.data.descriptionEN,
			json.data.descriptionFR,
			json.data.modelHeight.map((str) => str.replace(/'$/,'"')),
			json.data.modelSize,
			json.data.titleEN,
			json.data.titleFR,
			json.data.length,
			json.data.lengthInches,
			json.data.careInstructions,
			json.data.closure,
			json.data.sleeve,
			json.data.fabric,
			json.data.hood,
			json.data.notes,
			json.data.itemClass,
			json.data.technicalDetails || Array(json.data.s3Images.length).fill(""),
			json.data.lining || Array(json.data.s3Images.length).fill("No Lining"),
      json.data.collar,
      json.data.pocket,
      json.data.interiorPockets,
      json.data.neckline,
      json.data.pattern,
      json.data.skirt,
      json.data.fit,
      json.data.sleeveLength,
      json.data.liningMaterial,
      json.data.exteriorMaterial || Array(json.data.s3Images.length).fill("")
		)
    :
    await createManteauxCSV(
      props.collection,
			json.data.s3Images,
			json.data.itemImages,
			json.data.sideImages[0],
			json.data.backImages,
			json.data.supplierCode,
			json.data.supplierName,
			json.data.brand,
			json.data.styleCode,
			json.data.temperature,
			json.data.web_class_code,
			json.data.retail1Description,
			json.data.colorEN,
			json.data.colorFR,
			json.data.descriptionEN,
			json.data.descriptionFR,
			json.data.modelHeight.map((str) => str.replace(/'$/,'"')),
			json.data.modelSize,
			json.data.titleEN,
			json.data.titleFR,
			json.data.length,
			json.data.lengthInches,
			json.data.careInstructions,
			json.data.closure,
			json.data.sleeve,
			json.data.fabric,
			json.data.hood,
			json.data.notes,
			json.data.itemClass,
			json.data.technicalDetails || Array(json.data.s3Images.length).fill(""),
			json.data.lining || Array(json.data.s3Images.length).fill("No Lining"),
      json.data.collar,
      json.data.pocket,
      json.data.interiorPockets,
      json.data.neckline,
      json.data.pattern,
      json.data.skirt,
      json.data.fit,
      json.data.sleeveLength,
      json.data.liningMaterial,
      json.data.exteriorMaterial || Array(json.data.s3Images.length).fill(""),
      season
		)

    // console.log(data)
    SetCsvDownload(data);
    setLoading(false);
    setWaitingText("Loading...");
    return data;
  };

  const handleTxtDownload = async (isImport = false) => {
    setLoading(true);
    setWaitingText("Enhancing SEO. Please Wait for about a minute");
		const json = await fetch(`https://apie.snapwrite.ca/storeData/${endpoint}/${objectID}`)
			.then((response) => response.json())
    // console.log(organization.id)
    const preferences = await axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`)
    // console.log(preferences)
    const preferenceData = preferences.data.data

    if (props.collection === "mm-coats") {
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'exteriorMaterialOptions', val: [...new Set([...preferenceData.exteriorMaterialOptions, ...json.data.exteriorMaterial.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'liningMaterialOptions', val: [...new Set([...preferenceData.liningMaterialOptions, ...json.data.liningMaterial.filter(item => typeof item === 'string')])]})
    }
		  axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'interiorPocketOptions', val: [...new Set([...preferenceData.interiorPocketOptions, ...json.data.interiorPockets.filter(item => typeof item === 'string')])]})
		  // axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'skirtOptions', val: [...new Set([...preferenceData.skirtOptions, ...json.data.skirt.filter(item => typeof item === 'string')])]})
		  // axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'patternOptions', val: [...new Set([...preferenceData.patternOptions, ...json.data.pattern.filter(item => typeof item === 'string')])]})
		  axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'fitOptions', val: [...new Set([...preferenceData.fitOptions, ...json.data.fit.filter(item => typeof item === 'string')])]})
      // axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'necklineOptions', val: [...new Set([...json.data.neckline.filter(item => typeof item === 'string'), ...preferenceData.necklineOptions])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'closureOptions', val: [...new Set([...json.data.closure.filter(item => typeof item === 'string'), ...preferenceData.closureOptions])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'hoodOptions', val: [...new Set([...preferenceData.hoodOptions, ...json.data.hood.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'drawstringOptions', val: [...new Set([...preferenceData.drawstringOptions, ...json.data.drawstring.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'pocketOptions', val: [...new Set([...preferenceData.pocketOptions, ...json.data.pocket.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'liningOptions', val: [...new Set([...preferenceData.liningOptions, ...json.data.lining.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'collarOptions', val: [...new Set([...preferenceData.collarOptions, ...json.data.collar.filter(item => typeof item === 'string')])]})
      axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'sleeveCuffsOptions', val: [...new Set([...preferenceData.sleeveCuffsOptions, ...json.data.sleeve.filter(item => typeof item === 'string')])]})
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'fabricOptions', val: [...new Set([...preferenceData.fabricOptions, ...json.data.fabric.filter(item => typeof item === 'string')])]})
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'careInstructionsOptions', val: [...new Set([...preferenceData.careInstructionsOptions, ...json.data.careInstructions.filter(item => typeof item === 'string')])]})
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'technicalDetailsOptions', val: [...new Set([...preferenceData.technicalDetailsOptions, ...json.data.technicalDetails.filter(item => typeof item === 'string')])]})

		const data = await createManteauxCSV(
      props.collection,
			json.data.s3Images,
			json.data.itemImages,
			json.data.sideImages[0],
			json.data.backImages,
			json.data.supplierCode,
			json.data.supplierName,
			json.data.brand,
			json.data.styleCode,
			json.data.temperature,
			json.data.web_class_code,
			json.data.retail1Description,
			json.data.colorEN,
			json.data.colorFR,
			json.data.descriptionEN,
			json.data.descriptionFR,
			json.data.modelHeight.map((str) => str.replace(/'$/,'"')),
			json.data.modelSize,
			json.data.titleEN,
			json.data.titleFR,
			json.data.length,
			json.data.length.map((element) => Math.floor(element / 2.54)),
			json.data.careInstructions,
			json.data.closure,
			json.data.sleeve,
			json.data.fabric,
			json.data.hood,
			json.data.notes,
			json.data.itemClass,
			json.data.technicalDetails || Array(json.data.s3Images.length).fill(""),
			json.data.lining || Array(json.data.s3Images.length).fill("No Lining"),
      json.data.collar,
      json.data.pocket,
      json.data.interiorPockets,
      json.data.neckline,
      json.data.pattern,
      json.data.skirt,
      json.data.fit,
      json.data.sleeveLength,
      json.data.liningMaterial,
      json.data.exteriorMaterial || Array(json.data.s3Images.length).fill(""),
      season
		)

    // console.log(data)
    SetCsvDownload(data);
    setLoading(false);
    setWaitingText("Loading...");

    // Txt file
  const txtData = data.map(row => row.join('\t')).join('\n')
  const encoder = new TextEncoder(); // This will encode text to UTF-8 by default
  const utf8Data = encoder.encode(txtData);
  const blob = new Blob([utf8Data], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, 'webload.txt');
  
    return data;
  };
  
  const updateProjectName = (event) => {            

    setProjectName(event)
      if(objectID!=="") {        
        fetch(`https://apie.snapwrite.ca/storeData/${endpoint}-projectNameUpdate`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({        
            id: objectID,      
            projectName: event            
          }),
        })
          .then((response) => response.json())      
          .catch((err) => {
            console.log(`Error ${err}`);
          });      
      }
  }

  const DeleteItemsButton = () => {
      return (
        <Button
          variant="contained"
          color="error"
          style={
            checkedItemsIndicesArray.length === 0
              ? {
                  padding: "7px 0px",
                  backgroundColor: "##999799",
                  borderRadius: 10,
                }
              : {
                  padding: "7px 0px",
                  borderRadius: 10,
                  backgroundColor: "#f88b00",
                  border: "none",
                }
          }
          sx={{ boxShadow: 3 }}
          disabled={checkedItemsIndicesArray.length === 0}
          onClick={async () => {
            setLoading(true)
            try {
              const templateProps = { template: props.collection, objectID: objectID } // Expected format by deleteItems function
              const updatedData = await deleteItems(checkedItemsIndicesArray, templateProps, setCheckedItemsIndicesArray);
              if (updatedData) {
                if (updatedData.s3Images.length === 0) {
                  history.push(`/projects`)
                } else {
                  updateDisplayedItems(updatedData)
              setLoading(false)

                }
              } else {
                console.log("Error in DeleteItemsButton")
              }
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <DeleteOutlineTwoTone />
        </Button>
      );
    };

    const updateDisplayedItems = (updatedData) => {
      setItemImages(updatedData.itemImages);
      setSideImages(updatedData.sideImages);
      setBackImages(updatedData.backImages);
      setS3Images(updatedData.s3Images);

      setTitleEN(updatedData.titleEN);
      setTitleFR(updatedData.titleFR);
      setDescriptionEN(updatedData.descriptionEN);
      setDescriptionFR(updatedData.descriptionFR);

      setCareInstructions(updatedData.careInstructions);
      setTemp(updatedData.temperature);
      setModelHeight(updatedData.modelHeight);
      setModelSize(updatedData.modelSize);
      setNotes(updatedData.notes);
      setTechnicalDetails(updatedData.technicalDetails);

      setClosure(updatedData.closure);
      setHood(updatedData.hood);
      setLiningMaterial(updatedData.liningMaterial);
      setExteriorMaterial(updatedData.exteriorMaterial);
      setSleeve(updatedData.sleeve);
      setSleeveLength(updatedData.sleeveLength);
      setClosureFR(updatedData.closureFR);
      setHoodFR(updatedData.hoodFR);
      setSleeveFR(updatedData.sleeveFR);
      setFabric(updatedData.fabric);

      setInteriorPockets(updatedData.interiorPockets);
      setFit(updatedData.fit);
      setPattern(updatedData.pattern);
      setSkirt(updatedData.skirt);
      setNeckline(updatedData.neckline);

      setSupplierName(updatedData.supplierName);
      setSupplierCode(updatedData.supplierCode);
      setBrand(updatedData.brand);

      setStyleCode(updatedData.styleCode);

      setSku(updatedData.sku);
      setLining(updatedData.lining);

      setItemClass(updatedData.itemClass);
      setWebCode(updatedData.web_class_code);
      setRetail1Description(updatedData.retail1Description);
      setColorEN(updatedData.colorEN);
      setColorFR(updatedData.colorFR);

      setLength(updatedData.length);
      setInches(updatedData.lengthInches);
      setPocket(updatedData.pocket);
      setDrawstring(updatedData.drawstring);
      setCollar(updatedData.collar);
    }

  const updateProjectCSVGenerated = async (collection) => {
    let endpoint;
    if (collection === "mm-coats") {
      endpoint = "mm-table"
    } else if (collection === "mm-sports") {
      endpoint = "mm-sports"
    }

    const projectStatusURL = `https://apie.snapwrite.ca/storeData/${endpoint}-projectStatusUpdate`;
    const body = {
      id: objectID,
      projectCSVGenerated: true
    }

    try {
      await axios.put(projectStatusURL, body);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

 

  return (
    <MainSideMenuHeaderContainer
    reset={reset}
    name={"labels"}
    updateProjectName={updateProjectName}
    setProjectName={setProjectName}
    projectName={projectName}
    >
    <div
      onDrop={async (e) => await uploadImage(e, "drop")}
      onDragOver={(e) => e.preventDefault()}
    >
       {/* Error handling when backendClient fails  */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={backendAlertMessage.display}
        onClose={() => {
          setBackendAlertMessage({
            ...backendAlertMessage,
            display: false,
            message: "",
          });
          //If the loading is displayed when error occurs
          isLoading && setLoading(false)
        }}>
        <MuiAlert
          onClose={() => {
            setBackendAlertMessage({
              ...backendAlertMessage,
              display: false,
              message: "",
            });
            //If the loading is displayed when error occurs
            isLoading && setLoading(false)
          }}
          severity="error"
          sx={{ width: '100%' }}
        >
          Error: {backendAlertMessage.message}
        </MuiAlert>
      </Snackbar>      
              { (s3Images && s3Images.length === 0) && !isLoading ? (
                <ImagesUploadContainer uploadImage={uploadImage} template={props.collection} isParentMM = {true}/>
              ) : (
                <div className="tableLoaded">
                  {isLoading ? (
                    <h2 style={{ textAlign: "center" }}>
                      {waitingText}
                      {waitingText ===
                      "Enhancing SEO. Please Wait for about a minute" ? (
                        <>
                          <br />
                          <img
                            src="https://media.tenor.com/fJ2vm3_UhcAAAAAi/google-working.gif"
                            alt="loading..."
                          />
                        </>
                      ) : waitingText !==
                        'Please click on the "New" button in the top right corner' ? (
                        <>
                          {" "}
                          <br />
                          <img
                            src="https://media.tenor.com/qxgH66DCp7EAAAAi/%E3%81%A1%E3%82%87%E3%81%A3%E3%81%A8%E5%BE%85%E3%81%A3%E3%81%A6-%E5%B0%91%E3%81%97%E6%99%82%E9%96%93%E3%82%92%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84.gif"
                            alt="loading..."
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </h2>
                  ) : (
                    <>
                      <div className="action-buttons-ribbon">
                        <div className="action-buttons">
                            <DeleteItemsButton />
                          <button
                            className="btn"
                            onClick={async () => {
                              await generateCSVData();
                              const generateButton =
                                document.getElementById("csvLinkButton");
                              generateButton.click();
                            }}
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            Generate CSV
                          </button>
                          </div>
                        </div>

                      <div id="observation-data">
                      {colorFR && userID &&
                      <>
                        <MmTables
                          season={season}
                          setSeason={setSeason}
                          collection={props.collection}
                          userID={userID}
                          setBackendAlertMessage={setBackendAlertMessage}
                          backendAlertMessage={backendAlertMessage}
                          updateValIndividual={updateValIndividual}
                          objectID={objectID}
                          imageFileName={imageFileName}
                          setImageFileName={setImageFileName}
                          setS3Images={setS3Images}
                          s3Images={s3Images}
                          supplementaryImages={supplementaryImages}
                          setSupplementaryImages={setSupplementaryImages}
                          temp={temp}
                          setTemp={setTemp}
                          modelHeight={modelHeight}
                          setModelHeight={setModelHeight}
                          modelSize={modelSize}
                          setModelSize={setModelSize}
                          titleEN={titleEN}
                          setTitleEN={setTitleEN}
                          titleFR={titleFR}
                          setTitleFR={setTitleFR}
                          technicalDetails={technicalDetails}
                          setTechnicalDetails={setTechnicalDetails}
                          lining={lining}
                          setLining={setLining}
                          interiorPockets={interiorPockets}
                          setInteriorPockets={setInteriorPockets}
                          neckline={neckline}
                          setNeckline={setNeckline}
                          fit={fit}
                          setFit={setFit}
                          pattern={pattern}
                          setPattern={setPattern}
                          skirt={skirt}
                          setSkirt={setSkirt}
                          sku={sku}
                          setSku={setSku}
                          fabric={fabric}
                          setFabric={setFabric}
                          pockt={pocket}
                          drawstring={drawstring}
                          collar={collar}
                          pocket={pocket}
                          setPocket={setPocket}
                          setDrawstring={setDrawstring}
                          setCollar={setCollar}
                          notes={notes}
                          setNotes={setNotes}
                          closure={closure}
                          setClosure={setClosure}
                          liningMaterial={liningMaterial}
                          setLiningMaterial={setLiningMaterial}
                          setExteriorMaterial={setExteriorMaterial}
                          exteriorMaterial={exteriorMaterial}
                          hood={hood}
                          setHood={setHood}
                          sleeve={sleeve}
                          setSleeve={setSleeve}
                          sleeveLength={sleeveLength}
                          setSleeveLength={setSleeveLength}
                          supplierCode={supplierCode}
                          setSupplierCode={setSupplierCode}
                          supplierName={supplierName}
                          setSupplierName={setSupplierName}
                          brand={brand}
                          setBrand={setBrand}
                          styleCode={styleCode}
                          setStyleCode={setStyleCode}
                          itemClass={itemClass}
                          setItemClass={setItemClass}
                          webCode={webCode}
                          setWebCode={setWebCode}
                          retail1Description={retail1Description}
                          setRetail1Description={setRetail1Description}
                          colorEN={colorEN}
                          setColorEN={setColorEN}
                          colorFR={colorFR}
                          setColorFR={setColorFR}
                          descriptionEN={descriptionEN}
                          setDescriptionEN={setDescriptionEN}
                          descriptionFR={descriptionFR}
                          setDescriptionFR={setDescriptionFR}
                          length = {length}
                          setLength = {setLength}
                          inches={inches}
                          setInches={setInches}
                          careInstructions={careInstructions}
                          setCareInstructions={setCareInstructions}
                          itemImages={itemImages}
                          setItemImages={setItemImages}
                          backImages={backImages}
                          setBackImages={setBackImages}
                          sideImages={sideImages}
                          setSideImages={setSideImages}
													template={props.collection}
                          checkedItemsIndicesArray={checkedItemsIndicesArray}
                          handleRowSelection={handleRowSelection}
                        />
                        <label htmlFor="myFile">
                            <div style = {{marginLeft: "15px", marginTop: "15px", display: "flex", justifyContent: "center", alignItems: "center", width: "65px", height: "65px", borderRadius: "15px", border: "dotted 1px grey", cursor: "pointer", boxShadow: "yellow 2px 2px 2px"}}>
                               <img src={PlusIcon} style={{width: "40px", height: "40px"}}/>
                            </div>
                          </label>
                          <input
                            multiple
                            type="file"
                            name="myFile"
                            id="myFile"
                            className="drop-zone__input"
                            accept="image/*"
                            onChange={async (e) => await uploadImage(e, "upload")}
                          />
                          </>
                        }
                      </div>
                      {!isLoading && (
                        <div className="suggestions">
                          <div
                            className="tag-suggestions"
                            style={{ display: "flex", gap: "20px" }}
                          >
                            <button
                              className="btn"
                              style={{ padding: "7px", fontSize: "12px" }}
                              onClick={async () => {
                                const isImport = false;
                                await generateCSVData(isImport);
                                const generateButton =
                                  document.getElementById("csvLinkButton");
                                generateButton.click();
                                await updateProjectCSVGenerated(props.collection)
                              }}
                            >
                              Generate CSV
                            </button>
                            <button
                              className="btn"
                              style={{ padding: "7px", fontSize: "12px" }}
                              onClick={async () => {
                                await generateCSVData(false, true);
                                const generateButton =
                                  document.getElementById("csvLinkButton");
                                generateButton.click();
                              }}
                            >
                              Generate Magento CSV
                            </button>
                            <button
                              className="btn"
                              style={{ padding: "7px", fontSize: "12px" }}
                              onClick={async () => {
                                handleTxtDownload()
                              }}
                            >
                              Generate TXT
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

            <CSVLink
              id="csvLinkButton"
              hidden
              className="btn"
              data={csvDownload}
              filename={`${
                projectName !== "" ? projectName : "Import"
              }_SnapWriteAI.csv`}
            />



    </div>
    </MainSideMenuHeaderContainer>
  );
};
export default TagUploads;
