export const mmMenuItems = [
	{
		name: 'Projects',
		link: '/projects',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'P4',
				link: '/projects',
			},
			{
				name: 'H3',
				link: '/projects/h3',
			},
		],
	},
	{
		name: 'P4',
		link: '/projects',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'Coats & Jackets',
				link: '/mm-coats/new',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
					>
						<path
							d='M6 10.95L5 11.5C4.76666 11.6333 4.51666 11.6667 4.25 11.6C3.98333 11.5333 3.78333 11.3833 3.65 11.15L1.65 7.65001C1.51666 7.41667 1.48333 7.16667 1.55 6.90001C1.61666 6.63334 1.76666 6.43334 2 6.30001L7.75 3.00001H9.5C9.65 3.00001 9.771 3.04601 9.863 3.13801C9.955 3.23001 10.0007 3.35067 10 3.50001V4.00001C10 4.55001 10.196 5.02101 10.588 5.41301C10.98 5.80501 11.4507 6.00067 12 6.00001C12.55 6.00001 13.021 5.80401 13.413 5.41201C13.805 5.02001 14.0007 4.54934 14 4.00001V3.50001C14 3.35001 14.046 3.22901 14.138 3.13701C14.23 3.04501 14.3507 2.99934 14.5 3.00001H16.25L22 6.30001C22.2333 6.43334 22.3833 6.63334 22.45 6.90001C22.5167 7.16667 22.4833 7.41667 22.35 7.65001L20.35 11.15C20.2167 11.3833 20.0207 11.5293 19.762 11.588C19.5033 11.6467 19.2493 11.609 19 11.475L18 10.975V20C18 20.2833 17.904 20.521 17.712 20.713C17.52 20.905 17.2827 21.0007 17 21H7C6.71666 21 6.479 20.904 6.287 20.712C6.095 20.52 5.99933 20.2827 6 20V10.95Z'
							fill='black'
							fill-opacity='0.6'
						/>
					</svg>
				),
			},
			{
				name: 'Sportswear',
				link: '/mm-sports/new',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='none'
						viewBox='0 0 24 24'
					>
						<path
							fill='#000'
							d='M21 7.5H3V21H21V7.5ZM19.5 19.5H4.5V9H19.5V19.5ZM7.5 17.25H9V15H7.5V17.25ZM12 17.25H13.5V15H12V17.25ZM16.5 17.25H18V15H16.5V17.25ZM7.5 12.75H9V10.5H7.5V12.75ZM12 12.75H13.5V10.5H12V12.75ZM16.5 12.75H18V10.5H16.5V12.75Z'
						/>
					</svg>
				),
			},
		],
	},
	{
		name: 'H3',
		link: '/projects',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'Coats & Jackets',
				link: '/mm-coats/h3/new',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
					>
						<path
							d='M6 10.95L5 11.5C4.76666 11.6333 4.51666 11.6667 4.25 11.6C3.98333 11.5333 3.78333 11.3833 3.65 11.15L1.65 7.65001C1.51666 7.41667 1.48333 7.16667 1.55 6.90001C1.61666 6.63334 1.76666 6.43334 2 6.30001L7.75 3.00001H9.5C9.65 3.00001 9.771 3.04601 9.863 3.13801C9.955 3.23001 10.0007 3.35067 10 3.50001V4.00001C10 4.55001 10.196 5.02101 10.588 5.41301C10.98 5.80501 11.4507 6.00067 12 6.00001C12.55 6.00001 13.021 5.80401 13.413 5.41201C13.805 5.02001 14.0007 4.54934 14 4.00001V3.50001C14 3.35001 14.046 3.22901 14.138 3.13701C14.23 3.04501 14.3507 2.99934 14.5 3.00001H16.25L22 6.30001C22.2333 6.43334 22.3833 6.63334 22.45 6.90001C22.5167 7.16667 22.4833 7.41667 22.35 7.65001L20.35 11.15C20.2167 11.3833 20.0207 11.5293 19.762 11.588C19.5033 11.6467 19.2493 11.609 19 11.475L18 10.975V20C18 20.2833 17.904 20.521 17.712 20.713C17.52 20.905 17.2827 21.0007 17 21H7C6.71666 21 6.479 20.904 6.287 20.712C6.095 20.52 5.99933 20.2827 6 20V10.95Z'
							fill='black'
							fill-opacity='0.6'
						/>
					</svg>
				),
			},
			{
				name: 'Sportswear',
				link: '/mm-sports/h3/new',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='none'
						viewBox='0 0 24 24'
					>
						<path
							fill='#000'
							d='M21 7.5H3V21H21V7.5ZM19.5 19.5H4.5V9H19.5V19.5ZM7.5 17.25H9V15H7.5V17.25ZM12 17.25H13.5V15H12V17.25ZM16.5 17.25H18V15H16.5V17.25ZM7.5 12.75H9V10.5H7.5V12.75ZM12 12.75H13.5V10.5H12V12.75ZM16.5 12.75H18V10.5H16.5V12.75Z'
						/>
					</svg>
				),
			},
		],
	},
	{
		name: 'H3 Styles',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
		subItems: [
			{
				name: 'Coats & Jackets',
				link: '/mm-styles/coats',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
					>
						<path
							d='M6 10.95L5 11.5C4.76666 11.6333 4.51666 11.6667 4.25 11.6C3.98333 11.5333 3.78333 11.3833 3.65 11.15L1.65 7.65001C1.51666 7.41667 1.48333 7.16667 1.55 6.90001C1.61666 6.63334 1.76666 6.43334 2 6.30001L7.75 3.00001H9.5C9.65 3.00001 9.771 3.04601 9.863 3.13801C9.955 3.23001 10.0007 3.35067 10 3.50001V4.00001C10 4.55001 10.196 5.02101 10.588 5.41301C10.98 5.80501 11.4507 6.00067 12 6.00001C12.55 6.00001 13.021 5.80401 13.413 5.41201C13.805 5.02001 14.0007 4.54934 14 4.00001V3.50001C14 3.35001 14.046 3.22901 14.138 3.13701C14.23 3.04501 14.3507 2.99934 14.5 3.00001H16.25L22 6.30001C22.2333 6.43334 22.3833 6.63334 22.45 6.90001C22.5167 7.16667 22.4833 7.41667 22.35 7.65001L20.35 11.15C20.2167 11.3833 20.0207 11.5293 19.762 11.588C19.5033 11.6467 19.2493 11.609 19 11.475L18 10.975V20C18 20.2833 17.904 20.521 17.712 20.713C17.52 20.905 17.2827 21.0007 17 21H7C6.71666 21 6.479 20.904 6.287 20.712C6.095 20.52 5.99933 20.2827 6 20V10.95Z'
							fill='black'
							fill-opacity='0.6'
						/>
					</svg>
				),
			},
			{
				name: 'Sportswear',
				link: '/mm-styles/sports',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						fill='none'
						viewBox='0 0 24 24'
					>
						<path
							fill='#000'
							d='M21 7.5H3V21H21V7.5ZM19.5 19.5H4.5V9H19.5V19.5ZM7.5 17.25H9V15H7.5V17.25ZM12 17.25H13.5V15H12V17.25ZM16.5 17.25H18V15H16.5V17.25ZM7.5 12.75H9V10.5H7.5V12.75ZM12 12.75H13.5V10.5H12V12.75ZM16.5 12.75H18V10.5H16.5V12.75Z'
						/>
					</svg>
				),
			},
		],
	},
	{
		name: 'Metadata',
		link: '/mm-metadata',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
	},
	{
		name: 'Photo Galary',
		link: '/mm-magento-project-view',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
	},
];
