import "../css/App.css";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import AltText from "../pages/altText"
import IdeaToBlog from "./blogs/fromIdea";
import ImageToBlog from "./blogs/fromImage";
import Login from '../pages/Login';
import SpecDescription from "../pages/specDescription";
import ForgotPasswordPage from '../pages/forgotPassword';
import BrandAggregatorPage from '../pages/brandAggregator';
import UserBrandAggregatorPage from '../pages/UserBrandAggregator';
import Signup from '../pages/Signup';
import VendorSignup from "../pages/VendorSignup";
import { NonExisting } from "./nonExisting";
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { initializeApp } from "firebase/app";
import SpecData from "./Library/specDescriptionData";
import ImageDescriptionData from "./Library/imageDescriptionsData";
import Projects from "./projectLibrary/projects";
import ProductDescriptionPage from "./Library/imageToDescription/imageDescription";
import TwitterDescriptionPage from "./Library/socialMedia/twitterDescription";
import SpecProductDescriptionPage from "./Library/specTodescription/specToDescriptionDataView";
import ProjectAltText from "./Library/altText/projectAltText";
import IdeaToBlogDataView from "./Library/blogs/ideaToBlogDataView";
import ImageToBlogDataView from "./Library/blogs/imageToBlogDataView";
import UserProfile from "../pages/profile";
import Twitter from "../pages/twitter";
import Features from "../products/bulk/features";
import { VintageHandler } from "../products/bulk/vintageHandler";
import { NewClothingHandler } from "../products/bulk/new-clothing-handler";
import { PreLovedClothingRegular } from "../products/bulk/pre-loved-regular";
import LabelTextCategorization from "../products/bulk/LabelTextCategorization";
import SortedLabels from "./Library/categorization/sortedLabels.js";
import SortedClothingLabelsPage from "./Library/categorization/shopifyClothings";
import { Matric } from "./admin";
import { UserAnalytics } from "../pages/UserAnalytics.js";
import CustomTable from "./table/CustomTable.js"
import NewView from "../components/table/NewView.js"
import NewTable from "../components/table/NewTable.js"
import Bags from "../products/bulk/shopify/stores/Leprix/root.js";
import ApperalDemo from "../products/bulk/shopify/stores/demo/root.js";
import KidsPreLovedApparelView from "./Library/categorization/apparel/kidsPreLovedApparel";
import RegularPrelovedView from "./Library/categorization/apparel/regularPrelovedApparel";
import NewClothingViewHandler from "./Library/categorization/apparel/newApparel";
import VintageApparelView from "./Library/categorization/apparel/vintageApparel";
import BatchAggregator from "./table/batchAggregator";
import MasterTableHandler from "./Library/categorization/apparel/masterTable";
import VendorView from "./Library/categorization/apparel/VendorView";
import Success from "../pages/Success";
import ClothingMasterTable from "./Library/categorization/ClothingMasterTable";
import { FilterVendor } from "../pages/FilterVendor";
import Onboarding from "../pages/onboarding";
import SellerHome from "../pages/sellerHome";
import formSellerView from "./table/formSellerView";
import FormPage from "../pages/formPage";
import CustomTableHandler from "../products/bulk/customTableHandler";
import { AppBridgeProvider } from "./shopify/AppBridgeProvider";
import ExitIframe from "./shopify/ExitIframe";
import { MMCoats } from "../products/bulk/shopify/stores/ManteauxManteaux/tagsAuth.js";
import MMSportsWear from "../products/bulk/shopify/stores/ManteauxManteaux/sportsWear.js";
import Settings from "../pages/settings";
import axios from "axios";
import ProjectsByTheme from "./projectLibrary/ProjectsByTheme.js";
import ProjectByThemesTableView from "./projectLibrary/ProjectByThemesTableView.js";
import { MenuProvider } from "./navigation/MenuContext.js";

import SharedProjects from "./projectLibrary/projectsShared";
import VendorManagement from "./vendor management/VendorManagement.js";
import { VendorDashboard } from "./vendor management/VendorDashboard.js";
import MMStyles from "../products/bulk/shopify/stores/ManteauxManteaux/MMStyles.js";
import MetadataMM from "../products/bulk/shopify/stores/ManteauxManteaux/MetadataMM.js";
import MagentoProjectsViewMM from "../pages/MagentoProjectsViewMM.js";
import MagentoProjectHubMM from "../pages/MagentoProjectHubMM.js";

//download magento Csv to update items in Bulk
// import GetDataMagento from "../scripts/createMagentoCsvScript";
// import GetMagentoMetaData from "../scripts/createMagentoMetaData";


// import DemoMain from "../products/demo/main";
// import SideBar from './sidemenuTry/SIdeBar';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkSNBm1plRVQH3wLanZ3S9iSH8u1jI_O4",
  authDomain: "snapwriteai.firebaseapp.com",
  projectId: "snapwriteai",
  storageBucket: "snapwriteai.appspot.com",
  messagingSenderId: "10628816475",
  appId: "1:10628816475:web:391708244ce1f4b31e297e",
  measurementId: "G-DQS5KWPDZC"
};

initializeApp(firebaseConfig);

export const OrganizationContext = createContext({ 
  organization: null, 
  setOrganization: null,
})

function App() {
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);

  const organizationState = {
    organization,
    setOrganization,
  };


	const auth = getAuth();
	useEffect(()=>{
	onAuthStateChanged(auth, user => {
			setUser(user)
			if (user) {
        // Get user's personal info
			axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${user.uid}`)
			.then(res => {
        if(res.data.data){
          const resData = res.data.data
          let userInfoObj = {
            userID: resData.userID,
            email: resData.email,
            displayName: resData.displayName,
            id: resData.organizationID, // org id
            name: resData.organizationName, // org name
          }
          
          // Get info from the user's organization
          axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${resData.organizationID}`)
          .then(res2 => {
            if (res2.data.data) {
              const orgResData = res.data.data
              userInfoObj = {
                ...userInfoObj,
                sentForms: orgResData.sentForms,
                accountType: orgResData.accountType,
                skuNumber: orgResData.skuNumber,
                exportPreference: orgResData.exportPreference,
                vendorSplitPercentage: orgResData.vendorSplitPercentage,
                metadata: orgResData.metadata,
                tablePreference: orgResData.tablePreference,
                //Dropdown options
                careInstructionsOptions: orgResData.careInstructionsOptions,
                closureOptions: orgResData.closureOptions,
                hoodOptions: orgResData.hoodOptions,
                pocketOptions: orgResData.pocketOptions,
                sleeveCuffsOptions: orgResData.sleeveCuffsOptions,
                collarOptions: orgResData.collarOptions,
                drawstringOptions: orgResData.drawstringOptions,
                liningOptions: orgResData.liningOptions,
                fabricOptions: orgResData.fabricOptions,
                technicalDetailsOptions: orgResData.technicalDetailsOptions,
                exteriorMaterialOptions: orgResData.exteriorMaterialOptions,
                liningMaterialOptions: orgResData.liningMaterialOptions,
                interiorPocketOptions: orgResData.interiorPocketOptions,
                fitOptions: orgResData.fitOptions,
                sleeveOptions: orgResData.sleeveOptions,
                necklineOptions: orgResData.necklineOptions,
                skirtOptions: orgResData.skirtOptions,
                patternOptions: orgResData.patternOptions,
                taxonomyOptions: orgResData.taxonomyOptions
              }
              setOrganization(userInfoObj)
            }
          })

          
        }
			})
		}
	})

  }, [])

  return (

    <OrganizationContext.Provider value={organizationState}>
      <MenuProvider>
        <div>
          <BrowserRouter>
            <AppBridgeProvider>
              <Switch>


                {/* <Route exact path="/col" component={SideBar} /> */}
                <Route exact path="/" component={Login} />
                <Route path="/signup" component={Signup} />
                <Route path="/signup-vendor" component={VendorSignup} />
                <Route path="/onboarding" component={Onboarding} />
                <Route path="/forgot" component={ForgotPasswordPage} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/alt-text" component={AltText} />
                <Route path="/seller-home" component={SellerHome} />
                <Route path="/forms" component={FormPage} />

                {/* <Route path="/spec-description" component={SpecDescription} />                     */}
                <Route path="/idea-blog" component={IdeaToBlog} />
                <Route path="/image-blog" component={ImageToBlog} />
                <Route path="/spec-data" component={SpecData} />
                <Route path="/image-data" component={ImageDescriptionData} />

                {/* MM  */}
                <Route path="/projects/h3" component={Projects} /> 
                <Route path="/projects/p4" component={Projects} />

                <Route path="/projects" component={Projects} />
                <Route path="/projects-shared" component={SharedProjects} />  
                <Route path="/success" component={Success} />
                <Route path="/exitiframe" component={ExitIframe} />
                <Route path="/settings" component={Settings} />

                <Route path="/profile" component={UserProfile}></Route>
                {user &&
                  (user.uid === "S4nF6oUynvUfkVQjTIcyqyCPZQg2" ||
                    user.uid === "NpGB6zItW1OcpizZJbD88VsfKlf2"
                  )
                  ?
                  <Route path="/social" component={Twitter}></Route>
                  :
                  null}
                {/* <Route path="/social" component={Twitter}></Route> */}

                <Route path="/image-description/:id" component={ProductDescriptionPage} />
                <Route path="/image-twitter-description/:id" component={TwitterDescriptionPage} />
                <Route path="/alt-text-view/:id" component={ProjectAltText} />
                <Route path="/spec-description-view/:id" component={SpecProductDescriptionPage} />
                <Route path="/idea-blog-view/:id" component={IdeaToBlogDataView} />
                <Route path="/image-blog-view/:id" component={ImageToBlogDataView} />

                <Route path="/sorting" component={LabelTextCategorization} />
                <Route path="/sorting-view/:id" component={SortedLabels} />

                {/** Shopify */}
                <Route path="/bulk-shopify" component={Features} />

                {/* MM */}
                <Route path="/mm-styles/coats" component={MMStyles} />
                <Route path="/mm-styles/sports" component={MMStyles} />
                <Route path="/mm-coats/:urlID" component={MMCoats} />
                <Route path="/mm-sports/:urlID" component={MMSportsWear} />
                <Route path="/mm-metadata" component={MetadataMM} />
                <Route path="/mm-magento-hub/:styleCode" component={MagentoProjectHubMM} />
                <Route path="/mm-magento-project-view" component={MagentoProjectsViewMM} />


                {/* download magento Csv to update items in Bulk */}
                {/* <Route path="/createMagentoCsv" component={GetDataMagento} /> */}

                {/* <Route path="/createMagentoMetaData" component={GetMagentoMetaData} /> */}



                <Route path="/custom-table" component={CustomTableHandler} />
                <Route path="/clothing-sort-view/:id" component={KidsPreLovedApparelView} />

                <Route path="/preloved-regular" component={PreLovedClothingRegular} />
                <Route path="/preloved-regular-view/:id" component={RegularPrelovedView} />

                <Route path="/vintage" component={VintageHandler} />
                <Route path="/vintage-apparel-view/:id" component={VintageApparelView} />

                <Route path="/new" component={NewClothingHandler} />
                <Route path="/new-apparel-view/:id" component={NewClothingViewHandler} />

                <Route path="/masterTable/:id/:template" component={MasterTableHandler} />

                <Route path="/vendor-view/:id" component={VendorView} />
                <Route path="/vendor-management" component={VendorManagement} />
                <Route path="/vendor-dashboard" component={VendorDashboard} />
                {/* <Route path="/demo" component={DemoMain} /> */}
                <Route path="/athiya-the-analytics-genius" component={Matric} />
                <Route path="/analytics" component={UserAnalytics} />
                <Route path="/brand-aggregator-1729" component={BrandAggregatorPage} />
                <Route path="/brand-aggregator" component={UserBrandAggregatorPage} />
                <Route path="/table" component={CustomTable} />
                <Route path="/NewView/:id" component={NewView} />
                <Route path="/form-seller-view/:id" component={formSellerView} />
                <Route path="/NewTable/:batch" component={NewTable} />
                <Route path="/BatchAggregator" component={BatchAggregator} />
                <Route path="/vendor" component={FilterVendor} />
                <Route path="/clothing-master-table/:template/:id" component={ClothingMasterTable} />
                {/* TODO add Component  */}
                <Route path="/themes" component={ProjectsByTheme} />
                <Route path="/themes-view/:id" component={ProjectByThemesTableView} />
                {user &&
                  (user.uid === "1BILFjc4JNM0LDmmOUfdr2rRfNH3" ||
                    user.uid === "NpGB6zItW1OcpizZJbD88VsfKlf2"
                  )
                  ? <>
                    <Route path='/Bags' component={Bags}></Route>
                    <Route path='/apparel' component={ApperalDemo}></Route>
                  </>
                  :
                  null}

                <Route component={NonExisting} />
              </Switch>
            </AppBridgeProvider>
          </BrowserRouter>
        </div>
      </MenuProvider>

    </OrganizationContext.Provider>
  );
}

export default App;
