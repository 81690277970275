import { pocketLocation } from "./pocketLocation";
import { pocketType } from "./pocketType";

const pocket = [
    "Pouch",
    "Compartment",
    "pocket"
  ];

  const synonymsArray = [pocket, ...pocketLocation, ...pocketType]

export const getPocketSynonyms = (pocket) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => pocket.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 