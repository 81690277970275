//This componnt is a table view for the project by themes

import React, { useEffect, useState } from "react";
import { getAuth } from "@firebase/auth";

import { useParams } from "react-router";
import { useHistory } from "react-router";
import SortedClothingLabelsPage from "../Library/categorization/shopifyClothings";
import axios from "axios";

const ProjectByThemesTableView = () => {
  const history = useHistory();
  const auth = getAuth();
  const user = auth.currentUser;

  const [proceed, setProceed] = useState(false);
  const [userID, setUserID] = useState();
  const { id } = useParams();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      const token = localStorage.getItem("token");
      if (!token || !user) {
        history.push("/");
      } else {
        setUserID(user.uid);
        setProceed(true);
      }
    });
  }, []);

  return (
    <>
      {proceed ? (
        <SortedClothingLabelsPage id={id} userID={userID} template="themes" />
      ) : null}
    </>
  );
};

export default ProjectByThemesTableView;
