import TagSize from "./sizeTagger";
import axios from 'axios'

const createWixCSV = async (template, userID, objectID, isImport=false) => {

  let returnVal = []
    // Regular
    let s3Images = []
    let itemName = []
    let size = []
    let sizeNotes = []
    let brand = []
    let sku = []
    let types = []
    let taxonomyTypes = []
    let colors = []
    let condition = []
    let price = []
    let genders = []
    let imageFileName = []
    let costPerItem = []
    let genericType = []
    let itemDescription = []
    let vendorP = []
    let retailPrice = []
    let conditionDescription = []
    let supplementaryImages = []

    let additionalInfos = []
    let itemWeight = []

    // Vintage Specific
    let era = []
    let madeIn = []
    
    // Measurements
    let sleeveM = []
    let lengthM = []
    let pitToPitM = []
    let shoulderM = []
    let waistM = []
    let hipM = []
    let bottomInSeam = []
    let riseMeasure = []
    let thighMeasure = []
    let legOpening = []


    // Properties

    let material = []
    let sleeves = []
    let style = []
    let closure = []
    let fastening_type = []
    let neckline= []
    let cut = []
    let hood = []
    let belt = []
    let design = []
    let lining = []
    let layers = []
    let pattern = []
    let skirt = []
    let rise = []
    let heelHeight = []
    let heelShape = []
    let height = []
    let toe = []


    let link = `https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`;
    if (template === "vintage") {
      link = `https://apie.snapwrite.ca/storeData/vintage-apparel`;
    } else if (template === "new-clothes") {
      link = `https://apie.snapwrite.ca/storeData/new-apparel`;
    } else if (template === "pre-loved-regular") {
      link = `https://apie.snapwrite.ca/storeData/regular-preLoved-apparel`;
    }

      if(objectID && objectID!=="") {        
        await fetch(`${link}/${objectID}`)
          .then((response) => response.json())
          .then((json) => {
            s3Images = json.data.images;    
            if (json.data.itemName) {
              itemName = json.data.itemName;
            } else {
              itemName = Array(json.data.images.length).fill("");
            }
            if (json.data.size) {
              size = json.data.size;
            } else {
              size = Array(json.data.images.length).fill("");
            }
            if (json.data.sizeNotes) {
              sizeNotes = json.data.sizeNotes;
            } else {
              sizeNotes = Array(json.data.images.length).fill("");
            }
            if (json.data.brand) {
              brand = json.data.brand;
            } else {
              brand = Array(json.data.images.length).fill("");
            }
            if(json.data.sku) {
              sku = json.data.sku;
            } else {
              sku = Array(json.data.images.length).fill("");
            }
            if (json.data.types) {
              types = json.data.types;
            } else {
              types = Array(json.data.images.length).fill("");
            }
            if (json.data.taxonomyUpdatedType) {
              taxonomyTypes = json.data.taxonomyUpdatedType;
            } else {
              taxonomyTypes = Array(json.data.images.length).fill("");
            }
            if (json.data.colors) {
              colors = json.data.colors;
            } else {
              colors = Array(json.data.images.length).fill("");
            }
            if (json.data.condition) {
              condition = json.data.condition;
            } else {
              condition = Array(json.data.images.length).fill("");
            }
            if (json.data.price) {
              price = json.data.price;
            } else {
              price = Array(json.data.images.length).fill("");
            }
            if (json.data.gender) {
              genders = json.data.gender;
            } else {
              genders = Array(json.data.images.length).fill("");
            }
            if (json.data.fileNames) {
              imageFileName = json.data.fileNames;
            } else {
              imageFileName = Array(json.data.images.length).fill("");
            }
            if (json.data.cost) {
              costPerItem = json.data.cost;
            } else {
              costPerItem = Array(json.data.images.length).fill("");
            }
            if (json.data.itemGenericType) {
              genericType = json.data.itemGenericType;
            } else {
              genericType = Array(json.data.images.length).fill("");
            }
            if (json.data.description) {
              itemDescription = json.data.description;
            } else {
              itemDescription = Array(json.data.images.length).fill("");
            }        
            if(json.data.vendor) {
              vendorP = json.data.vendor;
            } else {
              vendorP = Array(json.data.images.length).fill("");
            }
            if (json.data.retailPrice) {
              retailPrice = json.data.retailPrice;
            } else {
              retailPrice = Array(json.data.images.length).fill("");
            }
            if (json.data.conditionDescription) {
              conditionDescription = json.data.conditionDescription;
            } else {
              conditionDescription = Array(json.data.images.length).fill("");
            }
            if (
              json.data.supplementaryImages &&
              json.data.supplementaryImages.length > 0
            ) {
              supplementaryImages = json.data.supplementaryImages;
            } else {
              supplementaryImages = Array(json.data.images.length).fill([]);
            }
            
    
            if (json.data.additionalInfo) {
              additionalInfos = json.data.additionalInfo;
            } else {
              additionalInfos = Array(json.data.images.length).fill("");
            }
            if (json.data.variantGrams) {
              itemWeight = json.data.variantGrams;
            } else {
              itemWeight = Array(json.data.images.length).fill("");
            }
    
            // Vintage
    
            if (json.data.era && json.data.era.length === json.data.images.length) {
              era = json.data.era;
            } else {
              era = Array(json.data.images.length).fill("");
            }
            if (
              json.data.madeIn &&
              json.data.madeIn.length === json.data.images.length
            ) {
              madeIn = json.data.madeIn;
            } else {
              madeIn = Array(json.data.images.length).fill("");
            }       
          
            // Measurements
            
            if (
              json.data.sleeveInSeam &&
              json.data.sleeveInSeam.length === json.data.images.length
            ) {
              sleeveM = json.data.sleeveInSeam;
            } else {
              sleeveM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.lengthMeasure &&
              json.data.lengthMeasure.length === json.data.images.length
            ) {
              lengthM = json.data.lengthMeasure;
            } else {
              lengthM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.pitToPitMeasure &&
              json.data.pitToPitMeasure.length === json.data.images.length
            ) {
              pitToPitM = json.data.pitToPitMeasure;
            } else {
              pitToPitM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.shoulderMeasure &&
              json.data.shoulderMeasure.length === json.data.images.length
            ) {
              shoulderM = json.data.shoulderMeasure;
            } else {
              shoulderM = Array(json.data.images.length).fill("");
            }
    
            if (
              json.data.waistMeasure &&
              json.data.waistMeasure.length === json.data.images.length
            ) {
              waistM = json.data.waistMeasure;
            } else {
              waistM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.hipMeasure &&
              json.data.hipMeasure.length === json.data.images.length
            ) {
              hipM = json.data.hipMeasure;
            } else {
              hipM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.bottomInSeam &&
              json.data.bottomInSeam.length === json.data.images.length
            ) {
              bottomInSeam = json.data.bottomInSeam;
            } else {
              bottomInSeam = Array(json.data.images.length).fill("");
            }
            if (
              json.data.riseMeasure &&
              json.data.riseMeasure.length === json.data.images.length
            ) {
              riseMeasure = json.data.riseMeasure;
            } else {
              riseMeasure = Array(json.data.images.length).fill("");
            }
            if (
              json.data.thighMeasure &&
              json.data.thighMeasure.length === json.data.images.length
            ) {
              thighMeasure = json.data.thighMeasure;
            } else {
              thighMeasure = Array(json.data.images.length).fill("");
            }
            if (
              json.data.legOpening &&
              json.data.legOpening.length === json.data.images.length
            ) {
              legOpening = json.data.legOpening;
            } else {
              legOpening = Array(json.data.images.length).fill("");
            }       
    
            // Attributes
            if (json.data.material) {
              material = json.data.material;
            } else {
              material = Array(json.data.images.length).fill("");
            }
            if (json.data.sleeves) {
              sleeves = json.data.sleeves;
            } else {
              sleeves = Array(json.data.images.length).fill("");
            }
          
            if (json.data.style) {
              style = json.data.style;
            } else {
              style = Array(json.data.images.length).fill("");
            }
            
            if (json.data.closure) {
              closure = json.data.closure;
            } else {
              closure = Array(json.data.images.length).fill("");
            }
            if (json.data.fastening_type) {
              fastening_type = json.data.fastening_type;
            } else {
              fastening_type = Array(json.data.images.length).fill("");
            }
            if (json.data.neckline) {
              neckline = json.data.neckline;
            } else {
              neckline = Array(json.data.images.length).fill("");
            }
            if (json.data.cut) {
              cut = json.data.cut;
            } else {
              cut = Array(json.data.images.length).fill("");
            }
            if (json.data.design) {
              design = json.data.design;
            } else {
              design = Array(json.data.images.length).fill("");
            }
            if (json.data.hood) {
              hood = json.data.hood;
            } else {
              hood = Array(json.data.images.length).fill("");
            }
            
            if (json.data.belt) {
              belt = json.data.belt;
            } else {
              belt = Array(json.data.images.length).fill("");
            }
            if (json.data.lining) {
              lining = json.data.lining;
            } else {
              lining = Array(json.data.images.length).fill("");
            }
            if (json.data.layers) {
              layers = json.data.layers
            } else {
              layers = Array(json.data.images.length).fill("");
            }
            if (json.data.pattern) {
              pattern = json.data.pattern;
            } else {
              pattern = Array(json.data.images.length).fill("");
            }
            if (json.data.skirt) {
              skirt = json.data.skirt;
            } else {
              skirt = Array(json.data.images.length).fill("");
            }
            if (json.data.rise) {
              rise = json.data.rise;
            } else {
              rise = Array(json.data.images.length).fill("");
            }
            if (json.data.heelHeight) {
              heelHeight = json.data.heelHeight;
            } else {
              heelHeight = Array(json.data.images.length).fill("");
            }
            if (json.data.heelShape) {
              heelShape = json.data.heelShape;
            } else {
              heelShape = Array(json.data.images.length).fill("");
            }
            if (json.data.height) {
              height = json.data.height;
            } else {
              height = Array(json.data.images.length).fill("");
            }
            if (json.data.toe) {
              toe = json.data.toe;
            } else {
              toe = Array(json.data.images.length).fill("");
            }
    
            // if (json.data.backImage) {
            //   setBackImage(json.data.backImage);
            // } else {
            //   setBackImage(Array(json.data.images.length).fill(""));
            // }
            
            // if (json.data.projectName) {
            //   setProjectName(json.data.projectName);
            // } else {
            //   setProjectName("");
            // }
    
            // if (json.data.length) {
            //   setLength(json.data.length);
            // } else {
            //   setLength(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.typeObj) {
            //   setTypeObj(json.data.typeObj);
            // } else {
            //   setTypeObj(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.age) {
            //   setAge(json.data.age);
            // } else {
            //   setAge(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.fastening) {
            //   setFastening(json.data.fastening);
            // } else {
            //   setFastening(Array(json.data.images.length).fill(""));
            // }
    
            // if (json.data.tags) {
            //   setTags(json.data.tags);
            // } else {
            //   setTags(json.data.tags);
            // }
    
            
          })
          .then(async ()=>{
            if(itemName.length===0 || !itemName) {
              itemName = Array(s3Images.length).fill("")
            }
            if(era.length===0 || !era) {
              era = Array(s3Images.length).fill("")
            }
            if(madeIn.length===0 || !madeIn) {
              madeIn = Array(s3Images.length).fill("")
            }
            if(material.length===0 || !material) {
              material = Array(s3Images.length).fill("")
            }
            //Measurements
            if(waistM.length===0 || !waistM) {
              waistM = Array(s3Images.length).fill("")
            }
            if(sleeveM.length===0 || !sleeveM) {
              sleeveM = Array(s3Images.length).fill("")
            }
            if(pitToPitM.length===0 || !pitToPitM) {
              pitToPitM = Array(s3Images.length).fill("")
            }
            if(hipM.length===0 || !hipM) {
              hipM = Array(s3Images.length).fill("")
            }
            if(lengthM.length===0 || !lengthM) {
              lengthM = Array(s3Images.length).fill("")
            }
            if(shoulderM.length===0 || !shoulderM){
              shoulderM = Array(s3Images.length).fill("")      
            }
            if(size.length===0 || !size) {
              size = Array(s3Images.length).fill("")
            }
            if(sizeNotes.length===0 || !sizeNotes) {
              sizeNotes = Array(s3Images.length).fill("")
            }
            if(brand.length===0 || !brand){
              brand = Array(s3Images.length).fill("")
            }
            if(sku.length===0 || !sku){
              sku = Array(s3Images.length).fill("")
            }
            if(types.length===0 || !types){
              types = Array(s3Images.length).fill("")
            }
            if(taxonomyTypes.length===0 || !taxonomyTypes){
              taxonomyTypes = Array(s3Images.length).fill("")
            }
            if(colors.length===0 || !colors){
              colors = Array(s3Images.length).fill("")
            }
            if(condition.length===0 || !condition){
              condition = Array(s3Images.length).fill("")
            }
            if(price.length===0 || !price) {
              price = Array(s3Images.length).fill("")
            }
            if(genders.length===0 || !genders) {
              genders = Array(s3Images.length).fill("")
            }
            if(imageFileName.length === 0 || !imageFileName) {
              imageFileName = Array(s3Images.length).fill("")
            }
            if(additionalInfos.length === 0 || !additionalInfos){
              additionalInfos = Array(s3Images.length).fill("")
            }
            if(costPerItem.length === 0 || !costPerItem){
              costPerItem = Array(s3Images.length).fill("")
            }
            if(itemWeight.length === 0 || !itemName) {
              itemWeight = Array(s3Images.length).fill("")
            }
            if(genericType.length === 0 || !genericType) {
              genericType = Array(s3Images.length).fill("")
            }
            if(itemDescription.length === 0 || !itemDescription){
              itemDescription = Array(s3Images.length).fill("")
            }
            if(vendorP.length === 0 || !vendorP){
              vendorP = Array(s3Images.length).fill("")
            }
            if(retailPrice.length === 0 || !retailPrice){
              retailPrice = Array(s3Images.length).fill("")
            }
            if(conditionDescription.length === 0 || !conditionDescription){
              conditionDescription = Array(s3Images.length).fill("")
            }
            if(supplementaryImages.length === 0 || !supplementaryImages){
              supplementaryImages = Array(s3Images.length).fill([])
            }
            if(sleeves.length===0 || !sleeves) {
              sleeves = Array(s3Images.length).fill([])
            }
            if(style.length === 0 || !style) {
              style = Array(s3Images.length).fill([])
            }
            if(closure.length === 0 || !closure){
              closure = Array(s3Images.length).fill([])
            }
            if(fastening_type.length===0 || !fastening_type){
              fastening_type = Array(s3Images.length).fill([])
            }
            if(neckline.length === 0 || !neckline) {
              neckline = Array(s3Images.length).fill([])
            }
            if(cut.length === 0 || !cut) {
              cut = Array(s3Images.length).fill([])
            }
            if(hood.length === 0 || !hood) {
              hood = Array(s3Images.length).fill([])
            }
            if(belt.length === 0 || !belt) {
              belt = Array(s3Images.length).fill([])
            }
            if(design.length === 0 || !design) {
              design = Array(s3Images.length).fill([])
            }
            if(lining.length === 0 || !lining) {
              lining = Array(s3Images.length).fill([])
            }
            if(layers.length === 0 || !layers) {
              layers = Array(s3Images.length).fill([])
            }
            if(pattern.length === 0 || !pattern) {
              pattern = Array(s3Images.length).fill([])
            }
            if(skirt.length === 0 || !skirt) {
              skirt = Array(s3Images.length).fill([])
            }
            if(rise.length === 0 || !rise) {
              rise = Array(s3Images.length).fill([])
            }
            if(heelHeight.length === 0 || !heelHeight) {
              heelHeight = Array(s3Images.length).fill([])
            }
            if(heelShape.length === 0 || !heelShape) {
              heelShape = Array(s3Images.length).fill([])
            }
            if(height.length === 0 || !height) {
              height = Array(s3Images.length).fill([])
            }
            if(!toe || toe.length === 0) {
              toe = Array(s3Images.length).fill([])
            }
  


  const filteredUserBrands = brand.filter(item => typeof item === "string")
  axios.put(`https://apie.snapwrite.ca/storeData/brands`, {brands: filteredUserBrands})

  let csv = [];
  // let csv = csvRowsTemp;
  let i = 0;

  for (i = 0; i < s3Images.length; i++) {
    const lines = itemDescription[i].split("\n");
    let result = "";
    for (let im = 0; im < lines.length; im++) {
      if (lines[im] !== "") {
        let para = lines[im];
        if (lines[im].includes(`"`)) {
          para = lines[im].replace(/"/g, `'`);
        }
        if(para !== "") {
          result += "<br>" + para + "<br>";
        }
        
      }
    }
    let handle = `${itemName[i]}_${colors[i]}_${brand[i]}_${types[i]}_${
      size[i]
    }_${
      imageFileName[i] !== undefined && imageFileName[i] !== ""
        ? (`${imageFileName[i]}_${Math.random().toFixed(3) * 100}`)
        : Math.random().toFixed(3) * 100
    }`;
    let tags = `New Arrivals, ${brand[i]}, ${types[i]}, ${size[i]},${colors[i]},${additionalInfos[i]}, ${taxonomyTypes[i]}`;
    let collection = ``;
    let title = `${brand[i]} ${itemName[i]} - ${size[i]}`;
    let description = `${
      itemDescription[i] && itemDescription[i] !== ""
        ? `${result !== "" ? result : itemDescription[i]}`
        : ""
    } Colour: ${colors[i]} Size: ${size[i]} ${
      template !== "new-clothes"
        ? ` Condition: ${condition[i]} ${
            conditionDescription[i] && conditionDescription[i] !== ""
              ? `. ${conditionDescription[i]}`
              : ""
          }`
        : ""
    }`;
    let option1Title = `Title`;
    let option1val = `Default Title`;
    let option2Title = ``;
    let Option2val = ``;
    let imageAlt = `${title}`;
    let weight = 453.59237;
    let weightUnit = ``;
    let vendor = `${brand[i]}`;
    let customProductType = `${types[i]}`;
    let status = `draft`;
    let taxable = `TRUE`;
    let genderTag = [genders[i]];
    if (
      genders[i] &&
      genders[i].toLocaleLowerCase().includes("gender neutral")
    ) {
      genderTag = ["Boys", "Girls"];
    } else if (
      genders[i] &&
      !genders[i].toLocaleLowerCase().includes("gender neutral")
    ) {
      if (/\s/g.test(genders[i])) {
        genderTag = genders[i].split(/\s+/);
      }
    }

    if (
      userID === "CXNDhrE6FfNRdXNm6nUQlmck29f2" ||
      userID === "xHaYXalwv8X8RpBLZju0PaQlWW23"
    ) {
      //Piece by Piece Kids
      vendor = vendorP[i];
      title = `${brand[i]} ${itemName[i]} (${size[i]})`;
      description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>Brand: ${
        brand[i]
      }</span></p><p class='p1'><span class='s1'>Size: ${
        size[i]
      }</span></p><p class='p1'><span class='s1'>Condition: ${condition[i]}${
        condition[i] === "Very Good Used Condition" ||
        condition[i] === "Good Used Condition" ||
        condition[i] === "Play Condition"
          ? `. ${conditionDescription[i]}`
          : ""
      }</span></p>`;

      if (itemName[i] !== undefined && itemName[i] !== "") {
        if (
          itemName[i].toLowerCase().includes("new ") ||
          itemName[i].toLowerCase().includes("new! ")
        ) {
          tags = `${size[i]}, New, ${brand[i]}, ${types[i]}, ${
            condition[i].toLowerCase().includes(" new") ? "new" : ""
          }, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`;
        } else {
          if (
            condition[i].toLowerCase() !== "like new" &&
            (condition[i].toLowerCase().includes(" new") ||
              condition[i].toLowerCase() === "new without tags")
          ) {
            tags = `${size[i]}, ${brand[i]}, ${types[i]}, new, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`;
          } else {
            tags = `${size[i]}, ${brand[i]}, ${types[i]}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`;
          }
        }
      } else {
        tags = `${size[i]}, ${brand[i]}, ${types[i]}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`;
      }

      if (size[i]) {
        option1Title = `Size`;
        option1val = ` ${size[i]}`;
      }
    } else if (
      userID === "VPQROHGh7pNC9du2JnL4fIopeoh1" ||
      userID === "t4gFVUQejYQYBY6dhwOp2mkYMTe2" ||
      userID === "WbahZZa5HhfWAa6SyJLYnl31kMG3" ||
      template === "vintage" ||
      template === "pre-loved-regular"
    ) {
      //Loose Fade
      customProductType = taxonomyTypes[i];
      let sizeTag = [];
      if (size[i].toLowerCase().includes("s")) {
        sizeTag.push("small");
      }
      if (size[i].toLowerCase().includes("m")) {
        sizeTag.push("medium");
      }
      if (size[i].toLowerCase().includes("l")) {
        sizeTag.push("large");
      }
      if (size[i].toLowerCase().includes("xl")) {
        sizeTag.push("extra large", "xtra large");
      }
      if (size[i].toLowerCase().includes("xxl")) {
        sizeTag.push("extra extra large", "xtra xtra large");
      }
      tags = `New Arrivals, online, ${sizeTag}, ${brand[i]}, ${condition[i]}, ${itemName[i]}, ${era[i]}, vintage, preloved, pre-loved, ${colors[i]}, ${size[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${sleeves[i]}, ${style[i]}, ${closure[i]}, ${fastening_type[i]}, ${neckline[i]}, ${cut[i]}, ${hood[i]}, ${belt[i]}, ${design[i]}, ${lining[i]}, ${layers[i]}, ${pattern[i]}, ${skirt[i]}, ${rise[i]}, ${heelHeight[i]}, ${heelShape[i]}, ${height[i]}, ${toe[i]}`;
      title = (userID==="9BjTXuFQ6TMYrn5cy6dclMyYqDz1" ? `${era[i] ? era[i] : ""} ${brand[i]} ${itemName[i]} - SZ ${size[i]}` : `${era[i] ? era[i] : ""} ${brand[i]} ${itemName[i]}`);
      description = `${result}
        ${madeIn[i].trim()!==""?`Made In:  ${madeIn[i]} `:''}<br>
        ${era[i] && era[i].trim() !== "" ?`  Era:  ${era[i]} `:''}<br>
        ${conditionDescription[i].trim()===""?  `Condition:  ${condition[i]}<br>`:  `Condition:  ${condition[i]} - ${conditionDescription[i]}<br>`}
          Color:  ${colors[i]} <br>
          Size:  ${size[i]}${sizeNotes[i]!==""?`. ${sizeNotes[i]}`: ""} 
          <br><br>${(waistM[i] && waistM[i].trim() !== "" || hipM[i] && hipM[i].trim() !== "" || bottomInSeam[i] && bottomInSeam[i].trim() !== "" || riseMeasure[i] && riseMeasure[i].trim() !== "" || thighMeasure[i] && thighMeasure[i].trim() !== "" || legOpening[i] && legOpening[i].trim() !== ""|| pitToPitM[i] && pitToPitM[i].trim() !== "" || shoulderM[i] && shoulderM[i].trim() !== "" || sleeveM[i] && sleeveM[i].trim() !== "" || lengthM[i] && lengthM[i].trim() !== "") ? "Measurements:<br><br>" : ""} ${waistM[i] !== "" ? `Waist: ${waistM[i]}<br>` : ""} ${hipM[i] !== "" ? `Hip: ${hipM[i]}<br>` : ""} ${bottomInSeam[i] !== "" ? `Bottom Inseam: ${bottomInSeam[i]}<br>` : ""} ${riseMeasure[i] !== "" ? `Rise: ${riseMeasure[i]}<br>` : ""} ${thighMeasure[i] !== "" ? `Thigh: ${thighMeasure[i]}<br>` : ""} ${legOpening[i] !== "" ? `Leg Opening: ${legOpening[i]}<br>` : ""} ${pitToPitM[i] !== "" ? `Pit-to-Pit: ${pitToPitM[i]}<br>` : ""} ${shoulderM[i] !== "" ? `Shoulder: ${shoulderM[i]}<br>` : ""} ${sleeveM[i] !== "" ? `Sleeve In-seam: ${sleeveM[i]}<br>` : ""} ${lengthM[i] !== "" ? `Full Length: ${lengthM[i]}<br>` : ""} 
          <br>
          ${material[i] && material[i] !== '' ? `Fabric: ${material[i].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}<br>` : ""}
          Sizes for vintage garments may not match the modern sizing convention. Please
          see measurements to ensure a proper fit.  
        `
    } else if (userID === "LePrix") {
      //Loose Fade
      customProductType = taxonomyTypes[i];
      tags = `New Arrivals, ${brand[i]}, ${condition[i]}, ${itemName[i]}, ${era[i]}, vintage, preloved, pre-loved, ${colors[i]}, ${size[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${sleeves[i]}, ${style[i]}, ${closure[i]}, ${fastening_type[i]}, ${neckline[i]}, ${cut[i]}, ${hood[i]}, ${belt[i]}, ${design[i]}, ${lining[i]}, ${layers[i]}, ${pattern[i]}, ${skirt[i]}, ${rise[i]}, ${heelHeight[i]}, ${heelShape[i]}, ${height[i]}, ${toe[i]}`;
      title = `${brand[i]} ${itemName[i]} ${taxonomyTypes[i]}`;
      description = `
        ${result}
        ${era[i] ? `Era: ${era[i]}` : ""}
        ${
          conditionDescription[i] === ""
            ? `Condition: ${condition[i]}`
            : `Condition: ${condition[i]} • ${conditionDescription[i]}`
        }
        Color: ${colors[i]}
        Size: ${size[i]}
       
        `;
    } else if (
      userID === "zjkiN1cq9Eb4oHsrA923OoJ9Sxz1" ||
      userID === "IMe1VYnWOQgQuER8JQHSI0iKNre2"
    ) {
      //Tokki kids
      tags = `New, New Arrivals, ${brand[i]}, ${types[i]}, ${taxonomyTypes[i]}`;
      title = `${itemName[i]} // ${size[i]}`;
      const replaced = size[i].match(/\d+/);
      let num;
      if (replaced !== null) {
        num = replaced[0];
      }
      if (size[i].toLocaleLowerCase().includes("m")) {
        if (num < 6) {
          collection = `0M - 6M`;
        } else if (num < 12) {
          collection = `6M - 12M`;
        } else if (num < 24 || num === 24) {
          collection = `12M - 24M`;
        }
      } else if (size[i].toLocaleLowerCase().includes("y")) {
        if (num < 4 || num === 4) {
          collection = `2Y - 4Y`;
        } else if (num < 10 || num === 10) {
          collection = `5Y - 10Y`;
        }
      }
      status = "active";
    } else if (userID === "5JAT5Rp2ZVP4KEeMPse9KAeDKTQ2") {
      // Ware Kids

      tags = `New, New Arrivals, ${brand[i]}, ${types[i]}, ${colors[i]}, ${
        title.toLocaleLowerCase().includes("vintage") ? "vintage" : ""
      }, ${taxonomyTypes[i]}`;
      if (size[i]) {
        option1Title = `Size`;
        option1val = ` ${size[i]}`;
      }
      weight = itemWeight[i];
      taxable = `FALSE`;
    } else if (userID === "L1mPOQ2Ju8dAUs1pz37vbag93sl2") {
      // Beeja May Test
      title = `${brand[i]} - ${types[i]} (${size[i]})`;
      option1Title = `Size`;
      option1val = ` ${size[i]}`;
      option2Title = `condition`;
      Option2val = `${condition[i]}`;
      imageAlt = `${title} - Beeja May`;
      tags = `Brand_${brand[i]}, Gender_${genders[i]}, Size_${size[i]}, Product Type_${types[i]}, ${colors[i]}, Condition_${condition[i]}, Price_${price[i]}, Baby ${genders[i]}, Baby ${genders[i]}${types[i]}, New Arrival, All Year, Kids, jackets & outerwear`;
      weight = 0;
    } else if (
      userID === "HDlknVR9NpWxhs9WWlfvnwAQq4b2" ||
      userID === "ZFoq2NIsDmZgVTW4g88O1B8JA822"
    ) {
      // Bon Tot
      title = `${brand[i]} ${itemName[i]} size ${size[i]}`;
      description = `<meta charset='UTF-8'>
                        <p class='p1'>
                          <span class='s1'>Brand: ${brand[i]}</span>
                        </p>
                        <p class='p1'>
                          <span class='s1'>Size: ${size[i]}</span>
                        </p>
                        <p class='p1'>
                          <span class='s1'>Condition: ${condition[i]}. ${conditionDescription[i]}</span>
                        </p>
                        <h4>About Bon Voyage:</h4>
                        <p>Bon Voyage is our brand new Buy Back marketplace - a curated collection of the best quality second hand baby and kids clothing available. This will include vintage childrenswear alongside Bon Tot pieces that we will literally Buy Back from you! We'll also buy other brands that you might own, as long as they meet our criteria in terms of where and how they were manufactured. This is an ethical second hand children's clothing marketplace.</p>
                        <p>At Bon Tot we've always stood for sustainable production, at a fair price, and this means kids clothing that is made to last - saving you money in the long run. Now, we're going one step further and offering to buy back your pieces once your kid has grown out of them. We will either back back for cash, or you can make a little extra if you choose store credit.</p>
                        <p>We're trying to make a truly sustainable circular system, focused on affordable, ethical, well made stylish kids clothes.</p>
                        <p>You can <strong>READ MORE <a href='https://www.bon-tot.com/blogs/news/buyback-second-hand-kids-clothing' title='second hand kids clothing near me - Bon Tot Edinburgh'>HERE</a></strong></p>`;
      // customProductType = `${genericType[i].substring(genericType[i].indexOf('/') + 1)}`
      // if(customProductType.toLocaleLowerCase()==="upper"){
      //   customProductType = "tops"
      // }
      vendor = `Bon Voyage`;
      let sizeTag = [];
      if (size[i].toLocaleLowerCase().includes("m") && size[i].match(/\d+/)) {
        if (
          [
            "3M",
            "6M",
            "3 months",
            "6 months",
            "0-3 months",
            "0-6 months",
            "3-6 months",
            "9M",
            "12M",
            "9 months",
            "12 months",
            "6-9 months",
            "9-12 months",
            "6-12 months",
          ].includes(size[i].toLowerCase())
        ) {
          sizeTag = ["0-12 months"];
        } else if (Number(size[i].match(/\d+/)[0]) > 11) {
          sizeTag = ["1-3 years"];
        }
      } else if (size[i].toLocaleLowerCase().includes("y")) {
        const replaced = size[i].match(/\d+/g);

        let num = [];
        if (replaced !== null && replaced.length > 1) {
          num.push(Number(replaced[0]));
          num.push(Number(replaced[1]));
          console.log(num);
          if (num[1] < 4) {
            sizeTag = ["1-3 years"];
          } else if (num[0] > 3 && num[1] < 7) {
            sizeTag = ["4-6 years"];
          } else if (num[0] < 4 && num[1] < 7) {
            sizeTag = ["1-3 years", "4-6 years"];
          } else if (num[0] > 6 && num[1] < 10) {
            sizeTag = ["7-9 years"];
          } else if (num[0] < 6 && num[1] < 10 && num[1] > 6) {
            sizeTag = ["4-6 years", "7-9 years"];
          } else if (num[0] > 9 && num[1] < 12) {
            sizeTag = ["10-11 years"];
          } else if (num[0] < 9 && num[1] < 12 && num[1] > 9) {
            sizeTag = ["7-9 years", "10-11 years"];
          } else if (num[0] < 11 && num[1] < 15) {
            sizeTag = ["12-13 years"];
          } else if (num[0] < 11 && num[1] < 15 && num[1] > 11) {
            sizeTag = ["10-11 years", "12-13 years"];
          } else if (num[1] > 13) {
            vendor = `Bon Voyage Adult`;
          }
        } else if (replaced !== null && replaced.length === 1) {
          num = Number(replaced[0]);
          if (num < 4) {
            sizeTag = "1-3 years";
          } else if (num > 3 && num < 7) {
            sizeTag = "4-6 years";
          } else if (num > 6 && num < 10) {
            sizeTag = "7-9 years";
          } else if (num > 9 && num < 12) {
            sizeTag = "10-11 years";
          } else if (num > 11 && num < 15) {
            sizeTag = "12-13 years";
            if (num > 13) {
              vendor = `Bon Voyage Adult`;
            }
          } else if (num > 14) {
            sizeTag = size[i];
            vendor = `Bon Voyage Adult`;
          }
        }
      } else if (
        ["XS", "S", "M", "L", "XL", "XXL"].includes(size[i].toUpperCase())
      ) {
        vendor = `Bon Voyage Adult`;
        sizeTag = size[i];
      } else {
        sizeTag = size[i];
      }
      tags = `${sizeTag}, buyback, ${brand[i]}, ${types[i]}, ${
        additionalInfos[i]
      }, ${customProductType}, ${colors[i]}${
        condition[i].toLowerCase().includes("brand new")
          ? ", NWT"
          : ", preloved, pre-loved"
      }, ${taxonomyTypes[i]}`;
      weight = 0;
      imageAlt = `${title} - Bon Tot`;
      weightUnit = `kg`;
      taxable = `FALSE`;
    } else if (userID === "Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1") {
      // Royal City Littles
      title = `${itemName[i]} (${size[i]})`;
      description = `<strong data-mce-fragment='1'>Brand: </strong>${brand[i]} 
                     <div data-mce-fragment='1'>
                      <strong data-mce-fragment='1'>Size: </strong>${size[i]}
                     </div>
                     <div data-mce-fragment='1'>
                       <strong data-mce-fragment='1'>Details: </strong>${additionalInfos[i]}
                     </div>      
                     <div data-mce-fragment='1'>
                       <strong data-mce-fragment='1'>Condition: </strong>${condition[i]}
                     </div>
                     <div data-mce-fragment='1'>
                     <meta charset='UTF-8'>
                     <section class='shopify-section section' id='shopify-section-template--15415710974149__product-details' data-mce-fragment='1'>
                     <div class='product-details page-width section-template--15415710974149__product-details-padding' id='ProductDetails' data-mce-fragment='1'>
                     <div class='grid' data-mce-fragment='1'>
                     <div class='grid__item grid__item--three-quarter' data-mce-fragment='1'>
                     <div class='product__description rte' data-mce-fragment='1'>
                     <div data-mce-fragment='1'>
                     <strong data-mce-fragment='1'>RCL Buy Back Program? YES.</strong> When you're done with this item, and don't have someone to hand it down to, we'd be happy to re-purchase it from you and add to our preloved section of our shop. </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </section>
                     </div>`;
      let sizeTag = "";
      if (size[i].toLocaleLowerCase().includes("m")) {
        const replaced = size[i].match(/\d+/g);
        console.log(replaced);
        let num = [];

        if (replaced !== null) {
          num.push(Number(replaced[0]));
          num.push(Number(replaced[1]));
        }
        if (num[1] < 3 || num[1] === 3) {
          sizeTag = "0-3M";
        } else if (num[0] === 3 || num[1] === 6 || (num[0] > 3 && num[1] < 6)) {
          sizeTag = "3-6M";
        } else if (num[0] > 5 && num[1] < 13) {
          if (num[1] > 9) {
            sizeTag = "6-12M";
          } else if (num[1] < 9 || num[1] === 9) {
            sizeTag = "6-9M, 6-12M";
          } else if (num[1] < 13 && num[1] > 8) {
            sizeTag = "9-12M";
          }
        } else if (num[0] > 11 && num[1] < 25) {
          sizeTag = "12-24M";
          if (num[1] < 19) {
            sizeTag = "12-18M, 12-24M";
          } else if (num[0] > 17) {
            sizeTag = "18-24M";
          }
        } else if (num === 18) {
          sizeTag = "18-24M";
        }
      } else if (size[i].toLocaleLowerCase().includes("t")) {
        const replaced = size[i].match(/\d+/);
        let num;
        if (replaced !== null) {
          num = Number(replaced[0]);
        }
        if (num === 2) {
          sizeTag = "2T";
        } else if (num === 3) {
          sizeTag = "3T";
        }
      } else if (size[i].toLocaleLowerCase().includes("y")) {
        const replaced = size[i].match(/\d+/);
        let num;
        if (replaced !== null) {
          num = Number(replaced[0]);
        }
        sizeTag = `${num}Y`;
      }
      tags = `${sizeTag}, ${size[i]}, ${colors[i]}, ${brand[i]}, preloved, ${condition[i]}, ${customProductType}, ${taxonomyTypes[i]}`;
    } else if (userID === "AgVEaDRk3tVtnfqAKiMYWLlRICG2") {
      // Tiny Human Gear
      title = `${brand[i]}, ${size[i]}`;
      description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>${
        itemDescription[i]
      }</span></p><p class='p1'><span class='s1'>Colour: ${
        colors[i]
      }</span></p><p class='p1'><span class='s1'>Condition: ${condition[i]}${
        condition[i] === "Very Good Used Condition" ||
        condition[i] === "Good Used Condition" ||
        condition[i] === "Play Condition"
          ? `${
              conditionDescription[i] !== ""
                ? `. ${conditionDescription[i]}`
                : ""
            }`
          : ""
      }</span></p>`;
      customProductType = taxonomyTypes[i];
      // customProductType = `${genericType[i].substring(genericType[i].indexOf('/') + 1)}`
      // if(customProductType.toLocaleLowerCase()==="upper"){
      //   customProductType = "tops"
      // }
      let sizeTag = "";
      let num = [];
      const replaced = size[i].match(/\d+/g);
      if (size[i].toLocaleLowerCase().includes("m")) {
        if (replaced !== null && replaced.length > 1) {
          num.push(Number(replaced[0]));
          num.push(Number(replaced[1]));

          if (num[1] < 6 || num[1] === 6) {
            sizeTag = ["0-6 months"];
          } else if (num[0] > 5 && num[1] < 13) {
            sizeTag = ["6-12 months"];
          } else if (num[0] < 6 && num[1] < 13) {
            sizeTag = ["0-6 months", "6-12 months"];
          } else if (num[0] > 11 && num[1] < 19) {
            sizeTag = ["12-18 months"];
          } else if (num[0] < 12 && num[1] < 19) {
            sizeTag = ["6-12 months", "12-18 months"];
          } else if (num[0] > 17 && num[1] < 25) {
            sizeTag = ["18-24 months"];
          } else if (num[0] < 18 && num[1] < 25) {
            sizeTag = ["12-18 months", "18-24 months"];
          }
        } else if (replaced !== null && replaced.length === 1) {
          num = Number(replaced[0]);
          if (num < 6) {
            sizeTag = ["0-6 months"];
          } else if (num === 6) {
            sizeTag = ["0-6 months", "6-12 months"];
          } else if (num < 12) {
            sizeTag = ["6-12 Months"];
          } else if (num === 12) {
            sizeTag = ["6-12 Months", "12-18 Months"];
          } else if (num > 12 && num < 19) {
            sizeTag = ["12-18 Months"];
          } else if (num === 18) {
            sizeTag = ["12-18 Months", "18-24 months"];
          } else if (num > 18 && num < 25) {
            sizeTag = ["18-24 months"];
          }
        }
      }
      if (["2", "2 years", "2*"].includes(size[i].toLowerCase())) {
        sizeTag = "2 years";
      } else if (["3", "3 years", "3*"].includes(size[i].toLowerCase())) {
        sizeTag = "3 years";
      } else if (["4", "4 years", "4*"].includes(size[i].toLowerCase())) {
        sizeTag = "4 years";
      } else if (["5", "4/5", "5/6", "5*"].includes(size[i])) {
        sizeTag = "5 years";
      } else if (
        ["6", "7", "6/7", "5/6", "7/8", "6*", "7*"].includes(size[i])
      ) {
        sizeTag = "6/7 years";
      } else if (
        ["8", "9", "7/8", "8/9", "9/10", "8*", "9*"].includes(size[i])
      ) {
        sizeTag = "8/9 years";
      } else if (
        [
          "10",
          "11",
          "12",
          "10/11",
          "11/12",
          "12/13",
          "12/14",
          "10/12",
          "10*",
          "11*",
          "12*",
        ].includes(size[i])
      ) {
        sizeTag = "10-12 years";
      } else if (
        [
          "13",
          "14",
          "15",
          "16",
          "12/13",
          "12/14",
          "14/16",
          "16*",
          "13*",
          "14*",
          "15*",
        ].includes(size[i])
      ) {
        sizeTag = "13-16 years";
      } else if (size[i].toLowerCase().includes("women")) {
        sizeTag = "Women's";
      }
      tags = `${condition[i] === "New With Tags" ? "new, " : ""}${size[i]}, ${
        colors[i]
      }, ${brand[i]}, ${size[i].toLowerCase().includes("women") ? "" : "5%"}, ${
        condition[i]
      }, ${customProductType}, ${sizeTag}, ${itemName[i]}, ${taxonomyTypes[i]}`;
      collection = `${size[i].toLowerCase().includes("women") ? "" : "5%"}`;
      if (size[i]) {
        option1Title = `Size`;
        option1val = ` ${size[i]}`;
      }
      weight = 0.1;
    } else if (userID === "VIa289fKOLZVRQTAEuueJXwvA0y2") {
      //Pea Shoots Consignment
      let sizeTags = size[i] ? size[i] : "";
      if (sizeTags.toLowerCase().match(/t$/)) {
        sizeTags = sizeTags.toUpperCase();
      } else {
        sizeTags = sizeTags.toLocaleLowerCase();
      }
      const sizeTitle = sizeTags.toLocaleLowerCase().includes("size")
        ? sizeTags
        : `size ${sizeTags}`;
      vendor = vendorP.replace(/[^a-zA-Z]+/, "");
      title = `${vendorP} - ${brand[i]} ${colors[
        i
      ].toLocaleLowerCase()} ${itemName[
        i
      ].toLocaleLowerCase()}, ${sizeTitle}, ${condition[
        i
      ].toLocaleLowerCase()}`;
      description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>Size: ${sizeTags}</span></p>`;
      let sizeTag = [""];
      const replaced = size[i].match(/\d+/g);
      let num = [];

      //Footwear Logic
      if (
        genericType[i].toLowerCase().includes("footwear") ||
        itemName[i].toLocaleLowerCase().includes("footwear") ||
        itemName[i].toLocaleLowerCase().includes("shoes") ||
        types[i].toLocaleLowerCase().includes("footwear") ||
        types[i].toLocaleLowerCase().includes("slipper") ||
        types[i].toLocaleLowerCase().includes("boot")
      ) {
        if (size[i].toLocaleLowerCase().includes("m")) {
          if (replaced !== null && replaced.length > 1) {
            num.push(Number(replaced[0]));
            num.push(Number(replaced[1]));

            if (num[0] < 3) {
              sizeTag = ["Size 1 (Baby)"];
              if (num[1] > 3 && num[1] < 7) {
                sizeTag = ["Size 1 (Baby)", "Size 2 (Baby)"];
              } else if (num[1] > 6 && num[1] < 10) {
                sizeTag = ["Size 1 (Baby)", "Size 2 (Baby)", "Size 3 (Baby)"];
              } else if (num[1] > 10 && num[1] < 13) {
                sizeTag = [
                  "Size 1 (Baby)",
                  "Size 2 (Baby)",
                  "Size 3 (Baby)",
                  "Size 4 (Baby)",
                ];
              }
            } else if (num[0] < 6) {
              sizeTag = ["Size 2 (Baby)"];
              if (num[1] > 6 && num[1] < 10) {
                sizeTag = ["Size 2 (Baby)", "Size 3 (Baby)"];
              } else if (num[1] > 10 && num[1] < 13) {
                sizeTag = ["Size 2 (Baby)", "Size 3 (Baby)", "Size 4 (Baby)"];
              }
            } else if (num[0] < 9) {
              sizeTag = ["Size 3 (Baby)"];
              if (num[1] > 10 && num[1] < 13) {
                sizeTag = ["Size 3 (Baby)", "Size 4 (Baby)"];
              }
            } else if (num[0] < 12) {
              sizeTag = ["Size 4 (Baby)"];
              if (num[1] > 12 && num[1] < 19) {
                sizeTag = ["Size 4 (Baby)", "Size 5"];
              }
            } else if (num[0] < 18) {
              sizeTag = ["Size 5"];
              if (num[1] > 18 && num[1] < 25) {
                sizeTag = ["Size 5", "Size 6"];
              }
            } else if (num[0] < 24) {
              sizeTag = ["Size 6"];
            }
          } else if (replaced !== null && replaced.length === 1) {
            num = Number(replaced[0]);

            if (num < 3) {
              sizeTag = ["Size 1 (Baby)"];
            } else if (num === 3) {
              sizeTag = ["Size 1 (Baby)", "Size 2 (Baby)"];
            } else if (num > 3 && num < 6) {
              sizeTag = ["Size 2 (Baby)"];
            } else if (num === 6) {
              sizeTag = ["Size 2 (Baby)", "Size 3 (Baby)"];
            } else if (num > 6 && num < 9) {
              sizeTag = ["Size 3 (Baby)"];
            } else if (num === 9) {
              sizeTag = ["Size 3 (Baby)", "Size 4 (Baby)"];
            } else if (num > 9 && num < 12) {
              sizeTag = ["Size 4 (Baby)"];
            } else if (num === 12) {
              sizeTag = ["Size 4 (Baby)", "Size 5"];
            } else if (num > 12 && num < 18) {
              sizeTag = ["Size 5"];
            } else if (num === 18) {
              sizeTag = ["Size 5", "Size 6"];
            } else if (num > 18 && num < 25) {
              sizeTag = ["Size 6"];
            }
          }
        } else if (size[i].toLowerCase().includes("youth")) {
          if (replaced !== null && replaced.length === 1) {
            num = Number(replaced[0]);
            if (num > 5 || num === 5) {
              sizeTag = ["Youth Size 5+"];
            }
          }
        }
        sizeTag = [`${size[i]}`];
      } else {
        if (size[i].toLocaleLowerCase().includes("m")) {
          if (replaced !== null && replaced.length > 1) {
            num.push(Number(replaced[0]));
            num.push(Number(replaced[1]));

            if (num[1] < 6 || num[1] === 6) {
              sizeTag = ["0-6 Months"];
            } else if ((num[0] > 6 && num[1] < 12) || num[1] === 12) {
              sizeTag = ["6-12 Months"];
            } else if ((num[0] > 12 && num[1] < 18) || num[1] === 18) {
              sizeTag = ["12-18 Months"];
            } else if (num[0] > 18 && num[1] < 25) {
              sizeTag = ["18-24 Months"];
            }
          } else if (replaced !== null && replaced.length === 1) {
            num = Number(replaced[0]);

            if (num < 6) {
              sizeTag = ["0-6 Months"];
            } else if (num === 6) {
              sizeTag = ["0-6 Months", "6-12 Months"];
            } else if (num > 6 && num < 12) {
              sizeTag = ["6-12 Months"];
            } else if (num === 12) {
              sizeTag = ["6-12 Months", "12-18 Months"];
            } else if (num > 11 && num < 18) {
              sizeTag = ["12-18 Months"];
            } else if (num === 18) {
              sizeTag = ["12-18 Months", "18-24 Months"];
            } else if (num > 18 && num < 25) {
              sizeTag = ["18-24M"];
            }
          }
        } else if (size[i].toLocaleLowerCase().includes("t")) {
          sizeTag = [`${sizeTags}`];
          if (replaced && replaced.length > 1) {
            num = [];
            num.push(Number(replaced[0]));
            num.push(Number(replaced[1]));
            sizeTag = [`${num[0]}T`, `${num[1]}T`];
          }
        } else {
          sizeTag = [`${size[i]}`];
          if (replaced && replaced.length > 1) {
            num = [];
            num.push(Number(replaced[0]));
            num.push(Number(replaced[1]));
            if (num[0] > 9 && num[1] < 13) {
              sizeTag = ["10-12+"];
            } else {
              sizeTag = [
                `${num[0]}`,
                `${num[1] === 8 || num[1] === 9 ? "8/9" : num[1]}`,
              ];
            }
          } else if (size[i] === 9 || size[i] === 8) {
            sizeTag = ["8/9"];
          } else if (size[i] > 9) {
            sizeTag = ["10-12+"];
          }
        }
      }
      // Tags Schema
      // {gender size type}
      // {gender size}
      // {gender type}
      // {gender}
      // console.log(sizeTag)
      let genderSizeType = `${genders[i]} ${sizeTags} ${types[i]}`;
      let genderSize = `${genders[i]} ${sizeTags}`;
      let genderType = `${genders[i]} ${types[i]}`;

      if (genderTag.length !== 0) {
        genderSizeType = [];
        genderSize = [];
        genderType = [];
        const genderTypeSizeSorter = (gender, size, type) => {
          if (type.toLocaleLowerCase().includes("footwear")) {
            let typeTag = "Footwear";
            return `${gender} ${typeTag} ${size}`;
          }
          return `${gender} ${size} ${type}`;
        };

        // eslint-disable-next-line no-loop-func
        genderTag.map((key, index) => {
          genderType.push(`${genderTag[index]} ${types[i]}`);
          sizeTag.forEach((tag) => {
            if (!/[a-zA-Z]/g.test(size[i])) {
              genderSizeType.push(
                `${genderTag[index]} Size ${tag} ${types[i]}`
              );
              genderSize.push(`${genderTag[index]} Size ${tag}`);
            } else {
              genderSizeType.push(
                genderTypeSizeSorter(genderTag[index], tag, types[i])
              );
              genderSize.push(`${genderTag[index]} ${tag}`);
            }
          });
        });
        genderSizeType = genderSizeType.join(", ");
        genderSize = genderSize.join(", ");
        genderType = genderType.join(", ");
        genderTag = genderTag.join(", ");
        if (
          genderTag.toLowerCase().includes("boys") &&
          genderTag.toLowerCase().includes("girls")
        ) {
          genderTag += ", gender neutral";
        }
      }

      tags = `${genderTag}, ${genderSizeType}, ${genderSize}, ${genderType}, ${
        size[i]
      }, ${sizeTag !== size[i] ? sizeTag : ""},${colors[i]}`;
    } else if (userID === "s8G1GJrXSraO8ySUsMeGY61sJRk1") {
      // Aster and Luna
      title = `${brand[i]} - ${itemName[i]} (${size[i]})${
        condition[i].toLocaleLowerCase().includes("play") ? " *play" : ""
      }`;
      description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>Condition: ${
        condition[i]
      }</span></p><p class='p1'><span class='s1'>Colour: ${
        colors[i]
      }</span></p><p class='p1'><span class='s1'>Description: ${
        itemDescription[i]
      }</span></p>${
        retailPrice[i] !== "" && retailPrice[i] !== undefined
          ? `<p class='p1'><span class='s1'>Retail Price: ${retailPrice[i]}</span></p>`
          : ""
      }`;
      tags = `${TagSize(size[i])}, ${size[i]}, ${brand[i]}, ${
        condition[i]
      }, ${customProductType}, ${genders[i]}, ${additionalInfos[i]}, ${
        taxonomyTypes[i]
      }`;
    } else if (template === "preLovedKids") {
      tags = `${TagSize(size[i])}, ${size[i]}, ${brand[i]}, ${
        condition[i]
      }, ${customProductType}, ${genders[i]}, ${additionalInfos[i]}, ${
        taxonomyTypes[i]
      }`;
      customProductType = taxonomyTypes[i];
    }
    // else if(userID==="UDpMYI7qc5dDFLCf0jEwZpJhLF52")//small and kind
    // {
    //   title =
    // }
    let imagesString = s3Images[i] + ";";
    supplementaryImages[i].forEach((image) => {
      imagesString += image + ";";
    });


    returnVal.push({
      handleId: handle.replace(/\n/g, "_"),
      fieldType: "Product",
      name: title.replace(/\n/g, ""),
      description: `"${description.trim().replace(/^\n+|<br\s*\/?>$/g, "")}"`,
      productImageUrl: imagesString,
      collection: collection,
      sku: sku[i].replace(/\n/g, " "),
      ribbon: "",
      price: price[i] === "" ? 0 : price[i],
      surcharge: "",
      visible: "TRUE",
      discountMode: "",
      discountValue: "",
      inventory: 1,
      weight: "",
      cost: costPerItem[i],
      productOptionName1: "",
      productOptionType1: "",
      productOptionDescription1: "",
      productOptionName2: "",
      productOptionType2: "",
      productOptionDescription2: "",
      productOptionName3: "",
      productOptionType3: "",
      productOptionDescription3: "",
      productOptionName4: "",
      productOptionType4: "",
      productOptionDescription4: "",
      productOptionName5: "",
      productOptionType5: "",
      productOptionDescription5: "",
      productOptionName6: "",
      productOptionType6: "",
      productOptionDescription6: "",
      additionalInfoTitle1: "",
      additionalInfoDescription1: "",
      additionalInfoTitle2: "",
      additionalInfoDescription2: "",
      additionalInfoTitle3: "",
      additionalInfoDescription3: "",
      additionalInfoTitle4: "",
      additionalInfoDescription4: "",
      additionalInfoTitle5: "",
      additionalInfoDescription5: "",
      additionalInfoTitle6: "",
      additionalInfoDescription6: "",
      customTextField1: "", // Allow users to add text to their order I.E. name on a shirt
      customTextCharLimit1: "",
      customTextMandatory1: "",
      brand: brand[i].replace(/\n/g, " "),
    });
  }
  })
  .catch((err) => {
    console.log(`Error ${err}`);
  });
  }

  // console.log(csv)
  return returnVal;
  // SetCsvDownload(csv);
};

export default createWixCSV;

