const TaxonomyUpdate = (userID, genereicType, type, template, sleeveLength) => {

  // const dresses = [
  //   "blazer dresses",
  //   "blouse dresses",
  //   "bodycon dresses",
  //   "fit & flare dresses",
  //   "sheath dresses",
  //   "slip dresses",
  //   "casual dresses",
  // ];
  // const pants = [
  //   "Pants",
  //   "beach pants",
  //   "cargo",
  //   "culottes",
  //   "suit trousers",
  //   "sweatpants",
  // ];

  // const shorts = ["hotpants", "swim shorts"];

  // const skirts = [
  //   "a-line skirts",
  //   "balloon skirts",
  //   "beach skirts",
  //   "mermaid skirts",
  //   "pencil & straight skirts",
  //   "pleated skirts",
  //   "tiered skirts",
  //   "tutu skirts",
  //   "wrap skirts",
  // ];

  // const tees = ["t-shirts", "vests", "polo-shirts"];

  // const tops = ["blouses", "shell tops", "tunics", "tops"];
  // const sweaters = ["sweaters", "cardigans"];

  // const lightWeightJacket = ["windstoppers & softshells"];


  // if (uid === "CXNDhrE6FfNRdXNm6nUQlmck29f2" || uid === "xHaYXalwv8X8RpBLZju0PaQlWW23") {
  //   // console.log("updateTxaonomy type " + type)
  //   let typeArray = type;
  //   // console.log(typeArray[index][0])
  //   if (tees.includes(type[index])) {
  //     typeArray[index] = "Tees";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (tops.includes(type[index])) {
  //     typeArray[index] = "Tops";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (sweaters.includes(type[index])) {
  //     typeArray[index] = "Sweaters";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (skirts.includes(type[index])) {
  //     typeArray[index] = "Skirts";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (shorts.includes(type[index])) {
  //     typeArray[index] = "Shorts";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (pants.includes(type[index])) {
  //     typeArray[index] = "Pants";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (dresses.includes(type[index])) {
  //     typeArray[index] = "Dresses";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (lightWeightJacket.includes(type[index])) {
  //     typeArray[index] = "Light weight jackets";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   } else if (type[index] === "sweatshirts") {
  //     typeArray[index] = "Sweatshirts";
  //     propsData.setTypesTaxoUpdate(typeArray);
  //   }
  // }
    
  if (userID === "9BjTXuFQ6TMYrn5cy6dclMyYqDz1") { //yyz
    if (genereicType.toLowerCase().includes("upper")) {   
      if (type.toLowerCase()==='t-shirts') {
        return 'T-shirts'
      } else if (type.toLowerCase().includes("sweatshirt") || type.toLowerCase().includes("sweaters")) {
        return 'Sweaters'
      } else return ''
    } else if (type.toLowerCase()==='blouses') {
      return 'Tops'
    } else if (genereicType.toLowerCase().includes("pants")) {
        return "Bottoms";        
    } else if (genereicType.toLowerCase().includes("jackets")) {
        return 'Outerwear'
    } else if (genereicType.toLowerCase().includes("hats")) {
      return "Accessories";        
    } else if (genereicType.toLowerCase().includes("accessories")) {
        return "Accessories";        
    } else if (genereicType.toLowerCase().includes("jewellery")) {
      return "Accessories";        
  } else if (genereicType.toLowerCase().includes("dresses")) {
      if (type.toLowerCase() === "slip dress") {
        return "Slips"
      }
      else {
        return "Dresses"; 
      }
             
  } else return ''
  }
  if(userID==="HDlknVR9NpWxhs9WWlfvnwAQq4b2" || userID==="ZFoq2NIsDmZgVTW4g88O1B8JA822"){
        if (genereicType.toLowerCase().includes("upper")) {                  
            return "tops";                
        } else if (genereicType.toLowerCase().includes("pants") && !type.toLowerCase().includes("shorts")) {        
            return "bottoms";        
        } else if (type && type.toLowerCase().includes("shorts")) {            
            return "shorts";        
        }
        else if (genereicType.toLowerCase().includes("jackets")) {
            return "outerwear";                
        } else if ((genereicType.toLowerCase().includes("baby") ||
                    genereicType.toLowerCase().includes("dungarees"))  
        ) {
            return "rompers";        
        } else if (genereicType.toLowerCase().includes("footwear") ) {
            return "shoes";        
        } else if (genereicType.toLowerCase().includes("dresses") ||
                    genereicType.toLowerCase().includes("skirts")) {
            return "skirts/dresses";        
        } else if (genereicType.toLowerCase().includes("accessories")) {
            return "accessories";        
        }
        else{
            return "" ;
        }        
    } else if(userID==="5APyYorrgoZ8uKOcxp5YMz3J0zq1" || userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1") { //curated cubs      
        if (genereicType.toLowerCase().includes("upper")) {             
           if (type.toLowerCase().includes("sweatshirt") || type.toLowerCase().includes("sweaters")) {
            return 'Sweaters'
          } else {
            return 'Tops'
          }
        } else if (genereicType.toLowerCase().includes("pants") && !type.toLowerCase().includes("shorts")) {        
            return "Pants";        
        } else if (type && type.toLowerCase().includes("shorts")) {            
            return "Shorts";        
        } else if (type && (type.toLowerCase().includes("suit") || type.toLowerCase().includes("tuxedos"))) {            
            return "Blazers + Suits";        
        } else if (genereicType.toLowerCase().includes("jackets")) {
            return "Outerwear";                
        } else if ((genereicType.toLowerCase().includes("baby") ||
                    genereicType.toLowerCase().includes("dungarees"))) {
            return "Rompers";        
        } else if (genereicType.toLowerCase().includes("footwear") ) {
            return "Footwear";        
        } else if (genereicType.toLowerCase().includes("dresses")) {
            return "Dresses";
        } else if (genereicType.toLowerCase().includes("skirts")) {
            return "Skirts";
        } else if (genereicType.toLowerCase().includes("accessories")) {
            return "Accessories";        
        } else if (genereicType.toLowerCase().includes("hats")) {
          return "Hats";        
        } else if (genereicType.toLowerCase().includes("underwear")) {
          return "Swim";
        } else {
            return "" ;
        }        
    } else if(userID==="CXNDhrE6FfNRdXNm6nUQlmck29f2" || userID === "xHaYXalwv8X8RpBLZju0PaQlWW23") {
        if (genereicType.toLowerCase().includes("upper")) {                  
            return "Tops";                
        } else if (genereicType.toLowerCase().includes("pants") && !type.toLowerCase().includes("shorts")) {        
            return "Bottoms";        
        } else if (type && type.toLowerCase().includes("shorts")) {            
            return "Shorts";        
        } else if (genereicType.toLowerCase().includes("underwear")) {
            return "Swimwear";        
        } else if (genereicType.toLowerCase().includes("jackets")) {
            return "Outerwear";                
        } else if (genereicType.toLowerCase().includes("dungarees")) {
            return "Onepiece";        
        } else if (genereicType.toLowerCase().includes("footwear") ) {
            return "Shoes";        
        }  else if (genereicType.toLowerCase().includes("skirts")) {
            return "Skirts";
        } else if (genereicType.toLowerCase().includes("dresses")) {
            return "Dresses";        
        } else if (genereicType.toLowerCase().includes("accessories")) {
            return "Accessories";        
        } else if (type==="Romper suits and overalls"){
            return "Sleepwear";
        } else if (type==="Bodysuits"){
            return "Bodysuit";
        } else {
            return ""
        }
    } else if (userID==="s8G1GJrXSraO8ySUsMeGY61sJRk1") {  // Aster and Luna
        console.log(genereicType)
        console.log(type)
        if (genereicType.toLowerCase().includes("upper")) {                  
            return "tops";                
        } else if (!genereicType.toLowerCase().includes("underwear") && genereicType.toLowerCase().includes("pants")) {
            return "bottoms";        
        } else if (genereicType.toLowerCase().includes("underwear")) {
            return "swimwear";        
        } else if (genereicType.toLowerCase().includes("jackets")) {
            return "outerwear";                
        } else if (genereicType.toLowerCase().includes("dungarees")) {
            return "onepiece";        
        } else if (genereicType.toLowerCase().includes("footwear") ) {
            return "shoes";        
        } else if (genereicType.toLowerCase().includes("dresses")) {
            return "dresses";        
        } else if (genereicType.toLowerCase().includes("accessories")) {
            return "accessories";        
        } else if (type==="Romper suits and overalls"){
            return "sleepwear";
        } else if (type==="Bodysuits"){
            return "onesie";
        } else {
            return ""
        }
    } else if (userID==="zjkiN1cq9Eb4oHsrA923OoJ9Sxz1" || userID==="IMe1VYnWOQgQuER8JQHSI0iKNre2" || userID==="NpGB6zItW1OcpizZJbD88VsfKlf2" || userID==="1BILFjc4JNM0LDmmOUfdr2rRfNH3" || userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || userID==="t4gFVUQejYQYBY6dhwOp2mkYMTe2" || userID==='WbahZZa5HhfWAa6SyJLYnl31kMG3'){
        if (genereicType.toLowerCase().includes("upper")) {
            return "Tops";
          } else if (
            genereicType.toLowerCase().includes("pants") ||
            genereicType.toLowerCase().includes("skirts")
          ) {
            return "Bottoms";
          } else if (genereicType.toLowerCase().includes("jackets")) {
            return "Outerwear";
          } else if (
            genereicType.toLowerCase().includes("baby") ||
            genereicType.toLowerCase().includes("dungarees")
          ) {
            return "Rompers";
          } else if (genereicType.toLowerCase().includes("footwear")) {
            return "Footwear";
          } else if (genereicType.toLowerCase().includes("dresses")) {
            return "Dresses";
          } else if (genereicType.toLowerCase().includes("underwear")) {
            return "Swimwear";
          } else if (genereicType.toLowerCase().includes("footwear") ) {
            return "Footwear";        
          } else if (genereicType.toLowerCase().includes("nightwear") ) {
            return "Sleepwear";        
          } else {
            return "";
          }          
    } else if (userID==="AgVEaDRk3tVtnfqAKiMYWLlRICG2" || userID==="NpGB6zItW1OcpizZJbD88VsfKlf2" || userID==="1BILFjc4JNM0LDmmOUfdr2rRfNH3"){
        if (genereicType.toLowerCase().includes("upper")) {
            return "Tops";
          } else if (
            genereicType.toLowerCase().includes("pants") ||
            genereicType.toLowerCase().includes("skirts")
          ) {
            return "Bottoms";
          } else if (genereicType.toLowerCase().includes("jackets")) {
            return "Outerwear";
          } else if (
            genereicType.toLowerCase().includes("baby") ||
            genereicType.toLowerCase().includes("dungarees")
          ) {
            return "Rompers";
          } else if (genereicType.toLowerCase().includes("footwear")) {
            return "Footwear";
          } else if (genereicType.toLowerCase().includes("dresses")) {
            return "Dresses";
          } else if (genereicType.toLowerCase().includes("underwear")) {
            return "Swimwear";
          } else if (genereicType.toLowerCase().includes("nightwear") ) {
            return "Sleepwear";        
          } else {
            return "";
          }          
    } else if (userID==='1eMplQPlHHhX9lnwX8NTcKghui22' || userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1') {
        if (genereicType.toLowerCase().includes("upper")) {   
          //  console.log(type)
          if (type.toLowerCase()==='t-shirts' && !sleeveLength[0].includes("long")) {
            return 'T-shirt'
          } else if (type.toLowerCase().includes("sweatshirt")) {
            return 'Sweatshirt'
          } else if (type.toLowerCase()==='t-shirts' && sleeveLength[0].includes("long")) {
            return 'Longsleeve'
          } else return ''
        } else if (genereicType.toLowerCase().includes("pants")) {
            return "Pants";        
        } else if (genereicType.toLowerCase().includes("jackets")) {
            return "Outerwear";                
        } else if (genereicType.toLowerCase().includes("accessories")) {
            return "Accessories";        
        } else return ''
    } else if (userID === 'FYLY9osdEkU7QtLsTgtmitcmqAD2' || userID === '80saF2wZqdRUhagajkE83wam8Js2') {
      if (genereicType.toLowerCase().includes("upper")) {   
        if (type.toLowerCase()==='t-shirts' && !sleeveLength[0].includes("long")) {
          return 'T-shirts'
        } else if (type.toLowerCase().includes("sweatshirt")) {
          return 'Sweaters & Sweatshirts'
        } else if (type.toLowerCase()==='t-shirts' && sleeveLength[0].includes("long")) {
          return 'Jerseys'
        } else return ''
      } else if (genereicType.toLowerCase().includes("pants")) {
          return "Pants";        
      } else if (genereicType.toLowerCase().includes("jackets")) {
          if (type.toLowerCase().includes("windstoppers")) {
            return 'Windbreaker'
          } else {
            return "Jackets and Coats";                
          }
      } else if (genereicType.toLowerCase().includes("hats")) {
        return "Hats";        
      } else if (genereicType.toLowerCase().includes("accessories")) {
          return "Accessories";        
      } else return ''
    } else if (userID === "Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3") { //lil cubs      
      if (genereicType.toLowerCase().includes("upper")) {   
        if (type.toLowerCase()==='t-shirts' || type.toLowerCase()==='shirts') {
            return 'Tops & Tees'
        } else if (type.toLowerCase().includes("sweatshirt") || type.toLowerCase().includes("cardigan") ||  type.toLowerCase().includes("sweater")) {
            return 'Sweatshirts, Sweaters & Cardigans'
        }
          else return 'Tops & Tees' 
      } 
        else if (!genereicType.toLowerCase().includes("underwear") && genereicType.toLowerCase().includes("pants")) {
          return "Bottoms";        
      } else if (genereicType.toLowerCase().includes("underwear")) {
          return "Swimwear";        
      } else if (genereicType.toLowerCase().includes("jackets")) {
          return "Outerwear";                
      } else if (genereicType.toLowerCase().includes("dungarees")) {
          return "Onesies";        
      } else if (genereicType.toLowerCase().includes("footwear") ) {
          return "Footwear";        
      } else if (genereicType.toLowerCase().includes("dresses") || genereicType.toLowerCase().includes("skirts")) {
          return "Dresses & Skirts";        
      } else if (genereicType.toLowerCase().includes("accessories")) {
          return "Accessories";        
      } else if (type==="Romper suits and overalls"){
          return "Rompers & Jumpsuits";
      } else if (type==="Bodysuits"){
          return "Onesies";
      } else {
          return ""
      }
    }
    else if (template==='preLovedKids') {
      if (genereicType.toLowerCase().includes("upper")) {                  
          return "Tops";                
      } else if (!genereicType.toLowerCase().includes("underwear") && genereicType.toLowerCase().includes("pants")) {
          return "Bottoms";        
      } else if (genereicType.toLowerCase().includes("underwear")) {
          return "Swimwear";        
      } else if (genereicType.toLowerCase().includes("jackets")) {
          return "Outerwear";                
      } else if (genereicType.toLowerCase().includes("dungarees")) {
          return "Onepiece";        
      } else if (genereicType.toLowerCase().includes("footwear") ) {
          return "Shoes";        
      } else if (genereicType.toLowerCase().includes("dresses")) {
          return "Dresses";        
      } else if (genereicType.toLowerCase().includes("accessories")) {
          return "Accessories";        
      } else if (type==="Romper suits and overalls"){
          return "sleepwear";
      } else if (type==="Bodysuits"){
          return "Bodysuit";
      } else {
          return ""
      }
  } else if (template==='vintage' || template==='pre-loved-regular' || template === 'new-clothes'){
    if (genereicType.toLowerCase().includes("upper")) {
        return "Tops";
      } else if (
        genereicType.toLowerCase().includes("pants") ||
        genereicType.toLowerCase().includes("skirts")
      ) {
        return "Bottoms";
      } else if (genereicType.toLowerCase().includes("jackets")) {
        return "Outerwear";
      } else if (
        genereicType.toLowerCase().includes("baby") ||
        genereicType.toLowerCase().includes("dungarees")
      ) {
        return "Rompers";
      } else if (genereicType.toLowerCase().includes("footwear")) {
        return "Footwear";
      } else if (genereicType.toLowerCase().includes("dresses")) {
        return "Dresses";
      } else if (genereicType.toLowerCase().includes("underwear")) {
        return "Swimwear";
      } else if (genereicType.toLowerCase().includes("footwear") ) {
        return "Footwear";        
      } else if (genereicType.toLowerCase().includes("nightwear") ) {
        return "Sleepwear";        
      } else {
        return "";
      }          
}  
  else {
        return "" 
    }                               
}

export default TaxonomyUpdate;

//ACCESSORIES + HATS
// BLAZERS + SUITS
// COSTUMES
// DRESSES + SKIRTS
// OUTERWEAR
// PANTS
// PJS + SLEEP SACKS
// ROMPERS
// SETS
// SHORTS
// SWEATERS
// SWIM
// TOPS