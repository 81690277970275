import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { CSVLink, CSVDownload } from 'react-csv';

import createcsv from './utils/generateCsv';
import TableList from './tableList';
import TaxonomyUpdate from './taxonomyUpdate';
import ItemNameFiller from './utils/itemNameAutoFill';
import storeData from './utils/storeData';
import createWixCSV from './utils/generateWixCSV';
import { saveAs } from 'file-saver';
import { ImportButton } from '../../../components/Library/categorization/shopifyClothings';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { OrganizationContext } from '../../../components/App';


import updateColumnValue from './utils/updateAttribute';
import renameItem from './utils/renameItem';
import ImagesUploadContainer from './tableComponents/ImagesUploadContainer';
import MainSideMenuHeaderContainer from '../../../components/containers/MainSideMenuHeaderContainer';
import stateArrayDBassignment from './utils/stateDBassignment';

// SKU
import updateSKUstart from './utils/skuCreator/skuCountUpdate';
import generateSKU from './utils/skuCreator/skuGenerate';


// reqeust splitting
import splitArray from './utils/requestSplitting/splitImages';
import getResponse from './utils/requestSplitting/parallelRequests';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const backendClient = axios.create({
	baseURL: 'https://apif.snapwrite.ca/',
});

export const MultiImage = (props) => {
	const [template, setTemplate] = useState(props.template);
	const [projectName, setProjectName] = useState('');
	const [isLoading, setLoading] = useState(true);
	const [waitingText, setWaitingText] = useState('Loading...');
	const [csvDownload, SetCsvDownload] = useState([]);

	const [objectID, setObjectID] = useState('');
	const [s3Images, setS3Images] = useState([]);
	const [backImage, setBackImage] = useState([]);
	const [supplementaryImages, setSupplementaryImages] = useState([]);
	const [imageFileName, setImageFileName] = useState([]);
	const [itemGenericType, setItemGenericType] = useState([]);
	const [itemName, setItemName] = useState([]);
	const [types, setType] = useState([]);
	const [typesTaxoUpdate, setTypesTaxoUpdate] = useState([]);
	const [brand, setBrand] = useState([]);
	const [sku, setSku] = useState([]);
	const [vendor, setVendor] = useState([]);
	const [description, setDescription] = useState([]);
	const [conditionDescription, setConditionDescription] = useState([]);
	const [size, setSize] = useState([]);
	const [sizeNotes, setSizeNotes] = useState([]);
	const [condition, setCondition] = useState([]);
	const [costPerItem, setCostPerItem] = useState([]);
	const [price, setPrice] = useState([]);
	const [retailPrice, setRetailPrice] = useState([]);
	const [genders, setGender] = useState([]);
	const [colors, setColor] = useState([]);
	const [additionalInfos, setAddtionaInfo] = useState([]);
	const [variantGrams, setVariantGrams] = useState([]);
	const [concerns, setConcerns] = useState([])

	const { organization, setOrganization } = useContext(OrganizationContext);

	/* Properties */
	const [tags, setTags] = useState([]);

	const [material, setMaterial] = useState([]);
	const [length, setLength] = useState([]);
	const [style, setStyle] = useState([]);
	const [age, setAge] = useState([]);
	const [design, setDesign] = useState([]);
	const [closure, setClosure] = useState([]);
	const [fastening, setFastening] = useState([]);
	const [fastening_type, setFasteningType] = useState([]);
	const [belt, setBelt] = useState([]);
	const [cut, setCut] = useState([]);
	const [neckline, setNeckline] = useState([]);
	const [sleeves, setSleeves] = useState([]);
	const [hood, setHood] = useState([]);
	const [typeObj, setTypeObj] = useState([]);
	const [lining, setLining] = useState([]);
	const [layers, setLayers] = useState([]);
	const [pattern, setPattern] = useState([]);
	const [skirt, setSkirt] = useState([]);
	const [rise, setRise] = useState([]);
	const [heelHeight, setHeelHeight] = useState([]);
	const [heelShape, setHeelShape] = useState([]);
	const [height, setHeight] = useState([]);
	const [toe, setToe] = useState([]);
	const [era, setEra] = useState([]);
	const [madeIn, setMadeIn] = useState([]);
	//Measurements
	const [waistMeasure, setWaistMeasure] = useState([]);
	const [hipMeasure, setHipMeasure] = useState([]);
	const [bottomInSeam, setBottomInSeam] = useState([]);
	const [riseMeasure, setRiseMeasure] = useState([]);
	const [thighMeasure, setThighMeasure] = useState([]);
	const [legOpening, setLegOpening] = useState([]);
	const [lengthMeasure, setLengthMeasure] = useState([]);
	const [sleeveInSeam, setsleeveInSeam] = useState([]);
	const [pitToPitMeasure, setPitToPitMeasure] = useState([]);
	const [shoulderMeasure, setShoulderMeasure] = useState([]);
	//footwear
	const [heelHeightMeasure, setHeelHeightMeasure] = useState([]);
	const [soleMeasure, setSoleMeasure] = useState([])
	const [inSoleMeasure, setInSoleMeasure] = useState([])
	const [shoeWidth, setShoeWidth] = useState([])


	const [sharedWith, setSharedWith] = useState("")
	const [accountType, setAccountType] = useState("reseller")
	const [offerAcceptStatus, setOfferAcceptStatus] = useState("pending")
	const [SKUStart, setSKUStart] = useState()


	// Theme
	const [theme, setTheme] = useState([]);
	const [isItemsDeleted, setIsItemsDeleted] = useState(false);
	//Error Handling for Backend Client
	const [backendAlertMessage, setBackendAlertMessage] = useState({
		display: false,
		message: '',
	});

	const [projectData, setProjectData] = useState([]);

	//Snackbar
	const [success, setSuccess] = useState(false);
	const [fail, setFail] = useState(false);

	//Table Columns
	// const [columns, setColumns] = useState()

	//Brands
	const [fetchedBrands, setFetchedBrands] = useState();

	// User's Preffered ItemTypes
	const [preferredItemTypes, setPreferredItemTypes] = useState();

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSuccess(false);
		setFail(false);
	};


	const userID = organization?.id || props.userID;

  useEffect(() => {
    const objectid = JSON.parse(localStorage.getItem(`objectIDShopify-${props.template+'-'+userID}`))
    if(objectid!==null){
      setObjectID(objectid)
      if(objectid===""){
        setLoading(false);
      }      
    }
    else {
      setLoading(false);
    }	

  }, [userID]);

  const snapwrite = "https://apie.snapwrite.ca/storeData"
  const localHost = "http://localhost:3500/storeData"
  const BASE_URL = snapwrite

  

	let link = `${BASE_URL}/labeling-clothes-categorization`;
	if (props.template === 'vintage') {
		link = `${BASE_URL}/vintage-apparel`;
	} else if (props.template === 'new-clothes') {
		link = `${BASE_URL}/new-apparel`;
	} else if (props.template === 'pre-loved-regular') {
		link = `${BASE_URL}/regular-preLoved-apparel`;
	}

	const fetchBrands = async () => {
		const brands = await axios.get(
			`${BASE_URL}/brands`
		);
		setFetchedBrands(brands.data.data.brands);
	};

	const fetchItemTypes = async () => {
		const itemTypes = await axios.get(
			`https://apie.snapwrite.ca/storeData/user-preferences-taxonomy/${userID}`
		);
		setPreferredItemTypes(itemTypes.data.data);
	};

	useEffect(() => {
		if (objectID && objectID !== '') {
			setLoading(true);
			// fetchColumns()
			fetchItemTypes();
			fetchBrands();
			fetch(`${link}/${objectID}`)
				.then((response) => response.json())
				.then((json) => {
        if (json.data.sharedWith) {
          setSharedWith(json.data.sharedWith)
        }
					setProjectData(json.data);

					setS3Images(json.data.images);
					if (json.data.backImage) {
						setBackImage(json.data.backImage);
					} else {
						setBackImage(Array(json.data.images.length).fill(''));
					}
					if (
						json.data.supplementaryImages &&
						json.data.supplementaryImages.length > 0
					) {
						setSupplementaryImages(json.data.supplementaryImages);
					} else {
						setSupplementaryImages(
							Array(json.data.images.length).fill([])
						);
					}
					if (json.data.projectName) {
						setProjectName(json.data.projectName);
					} else {
						setProjectName('');
					}
					if (json.data.fileNames) {
						setImageFileName(json.data.fileNames);
					} else {
						setImageFileName(
							Array(json.data.images.length).fill('')
						);
					}
					if (json.data.itemName) {
						setItemName(json.data.itemName);
					} else {
						setItemName(Array(json.data.images.length).fill(''));
					}
					if (json.data.itemGenericType) {
						setItemGenericType(json.data.itemGenericType);
					} else {
						setItemGenericType(
							Array(json.data.images.length).fill('')
						);
					}
					if (json.data.taxonomyUpdatedType) {
						setTypesTaxoUpdate(json.data.taxonomyUpdatedType);
					} else {
						setTypesTaxoUpdate(
							Array(json.data.images.length).fill('')
						);
					}
					if (json.data.brand) {
						setBrand(json.data.brand);
					} else {
						setBrand(Array(json.data.images.length).fill(''));
					}
					if (json.data.sku) {
						setSku(json.data.sku);
					} else {
						setSku(Array(json.data.images.length).fill(''));
					}
					if (json.data.vendor) {
						setVendor(json.data.vendor);
					} else {
						setVendor(Array(json.data.images.length).fill(''));
					}
					if (json.data.types) {
						setType(json.data.types);
					} else {
						setType(Array(json.data.images.length).fill(''));
					}
					if (json.data.gender) {
						setGender(json.data.gender);
					} else {
						setGender(Array(json.data.images.length).fill(''));
					}
					if (json.data.size) {
						setSize(json.data.size);
					} else {
						setSize(Array(json.data.images.length).fill(''));
					}
					if (json.data.sizeNotes) {
						setSizeNotes(json.data.sizeNotes);
					} else {
						setSizeNotes(Array(json.data.images.length).fill(''));
					}
					if (json.data.condition) {
						setCondition(json.data.condition);
					} else {
						setCondition(Array(json.data.images.length).fill(''));
					}
					if (json.data.conditionDescription) {
						setConditionDescription(json.data.conditionDescription);
					} else {
						setConditionDescription(
							Array(json.data.images.length).fill('')
						);
					}
					if (json.data.description) {
						setDescription(json.data.description);
					} else {
						setDescription(Array(json.data.images.length).fill(''));
					}
					if (json.data.colors) {
						setColor(json.data.colors);
					} else {
						setColor(Array(json.data.images.length).fill(''));
					}
					if (json.data.cost ) {						
						setCostPerItem(json.data.cost);
					} else {
						setCostPerItem(Array(json.data.images.length).fill(''));
					}
					if (json.data.price) {						
						setPrice(json.data.price);
					} else {
						setPrice(Array(json.data.images.length).fill(''));
					}
					if (json.data.retailPrice) {
						setRetailPrice(json.data.retailPrice);
					} else {
						setRetailPrice(Array(json.data.images.length).fill(''));
					}
					if (json.data.additionalInfo) {
						setAddtionaInfo(json.data.additionalInfo);
					} else {
						setAddtionaInfo(
							Array(json.data.images.length).fill('')
						);
					}
					if (json.data.variantGrams) {
						setVariantGrams(json.data.variantGrams);
					} else {
						setVariantGrams(
							Array(json.data.images.length).fill('')
						);
					}
					if (json.data.concerns) {
						console.log(json.data.concerns)
						setConcerns(json.data.concerns);
					} else {
						setConcerns(
							Array(json.data.images.length).fill('')
						);
					}

					// Attributes
					if (json.data.material) {
						setMaterial(json.data.material);
					} else {
						setMaterial(Array(json.data.images.length).fill(''));
					}
					if (json.data.sleeves) {
						setSleeves(json.data.sleeves);
					} else {
						setSleeves(Array(json.data.images.length).fill(''));
					}
					if (json.data.length) {
						setLength(json.data.length);
					} else {
						setLength(Array(json.data.images.length).fill(''));
					}
					if (json.data.style) {
						setStyle(json.data.style);
					} else {
						setStyle(Array(json.data.images.length).fill(''));
					}
					if (json.data.age) {
						setAge(json.data.age);
					} else {
						setAge(Array(json.data.images.length).fill(''));
					}
					if (json.data.closure) {
						setClosure(json.data.closure);
					} else {
						setClosure(Array(json.data.images.length).fill(''));
					}
					if (json.data.fastening) {
						setFastening(json.data.fastening);
					} else {
						setFastening(Array(json.data.images.length).fill(''));
					}
					if (json.data.fastening_type) {
						setFasteningType(json.data.fastening_type);
					} else {
						setFasteningType(
							Array(json.data.images.length).fill('')
						);
					}
					if (json.data.neckline) {
						setNeckline(json.data.neckline);
					} else {
						setNeckline(Array(json.data.images.length).fill(''));
					}
					if (json.data.cut) {
						setCut(json.data.cut);
					} else {
						setCut(Array(json.data.images.length).fill(''));
					}
					if (json.data.design) {
						setDesign(json.data.design);
					} else {
						setDesign(Array(json.data.images.length).fill(''));
					}
					if (json.data.hood) {
						setHood(json.data.hood);
					} else {
						setHood(Array(json.data.images.length).fill(''));
					}
					if (json.data.typeObj) {
						setTypeObj(json.data.typeObj);
					} else {
						setTypeObj(Array(json.data.images.length).fill(''));
					}
					if (json.data.belt) {
						setBelt(json.data.belt);
					} else {
						setBelt(Array(json.data.images.length).fill(''));
					}
					if (json.data.lining) {
						setLining(json.data.lining);
					} else {
						setLining(Array(json.data.images.length).fill(''));
					}
					if (json.data.layers) {
						setLayers(json.data.layers);
					} else {
						setLayers(Array(json.data.images.length).fill(''));
					}
					if (json.data.pattern) {
						setPattern(json.data.pattern);
					} else {
						setPattern(Array(json.data.images.length).fill(''));
					}
					if (json.data.skirt) {
						setSkirt(json.data.skirt);
					} else {
						setSkirt(Array(json.data.images.length).fill(''));
					}
					if (json.data.rise) {
						setRise(json.data.rise);
					} else {
						setRise(Array(json.data.images.length).fill(''));
					}
					if (json.data.heelHeight) {
						setHeelHeight(json.data.heelHeight);
					} else {
						setHeelHeight(Array(json.data.images.length).fill(''));
					}
					if (json.data.heelShape) {
						setHeelShape(json.data.heelShape);
					} else {
						setHeelShape(Array(json.data.images.length).fill(''));
					}
					if (json.data.height) {
						setHeight(json.data.height);
					} else {
						setHeight(Array(json.data.images.length).fill(''));
					}
					if (json.data.toe) {
						setToe(json.data.toe);
					} else {
						setToe(Array(json.data.images.length).fill(''));
					}
					if (json.data.tags) {
						setTags(json.data.tags);
					} else {
						setTags(Array(json.data.images.length).fill([]));
					}

					// Vintage

					if (
						json.data.era &&
						json.data.era.length === json.data.images.length
					) {
						setEra(json.data.era);
					} else {
						setEra(Array(json.data.images.length).fill(''));
					}

					if (
						json.data.madeIn &&
						json.data.madeIn.length === json.data.images.length
					) {
						setMadeIn(json.data.madeIn);
					} else {
						setMadeIn(Array(json.data.images.length).fill(''));
					}

					// Measurements

					if (
						json.data.legOpening &&
						json.data.legOpening.length === json.data.images.length
					) {
						setLegOpening(json.data.legOpening);
					} else {
						setLegOpening(Array(json.data.images.length).fill(''));
					}
					if (
						json.data.riseMeasure &&
						json.data.riseMeasure.length === json.data.images.length
					) {
						setRiseMeasure(json.data.riseMeasure);
					} else {
						setRiseMeasure(Array(json.data.images.length).fill(''));
					}
					if (
						json.data.bottomInSeam &&
						json.data.bottomInSeam.length ===
						json.data.images.length
					) {
						setBottomInSeam(json.data.bottomInSeam);
					} else {
						setBottomInSeam(
							Array(json.data.images.length).fill('')
						);
					}
					if (
						json.data.thighMeasure &&
						json.data.thighMeasure.length ===
						json.data.images.length
					) {
						setThighMeasure(json.data.thighMeasure);
					} else {
						setThighMeasure(
							Array(json.data.images.length).fill('')
						);
					}
					if (
						json.data.lengthMeasure &&
						json.data.lengthMeasure.length ===
						json.data.images.length
					) {
						setLengthMeasure(json.data.lengthMeasure);
					} else {
						setLengthMeasure(
							Array(json.data.images.length).fill('')
						);
					}
					if (
						json.data.sleeveInSeam &&
						json.data.sleeveInSeam.length ===
						json.data.images.length
					) {
						setsleeveInSeam(json.data.sleeveInSeam);
					} else {
						setsleeveInSeam(
							Array(json.data.images.length).fill('')
						);
					}
					if (
						json.data.pitToPitMeasure &&
						json.data.pitToPitMeasure.length ===
						json.data.images.length
					) {
						setPitToPitMeasure(json.data.pitToPitMeasure);
					} else {
						setPitToPitMeasure(
							Array(json.data.images.length).fill('')
						);
					}
					if (
						json.data.shoulderMeasure &&
						json.data.shoulderMeasure.length ===
						json.data.images.length
					) {
						setShoulderMeasure(json.data.shoulderMeasure);
					} else {
						setShoulderMeasure(
							Array(json.data.images.length).fill('')
						);
					}
					if (
						json.data.waistMeasure &&
						json.data.waistMeasure.length ===
						json.data.images.length
					) {
						setWaistMeasure(json.data.waistMeasure);
					} else {
						setWaistMeasure(
							Array(json.data.images.length).fill('')
						);
					}
					if (
						json.data.hipMeasure &&
						json.data.hipMeasure.length === json.data.images.length
					) {
						setHipMeasure(json.data.hipMeasure);
					} else {
						setHipMeasure(Array(json.data.images.length).fill(''));
					}
					if (json.data.theme) {
						setTheme(json.data.theme);
					}
					// shoes measurement
					setHeelHeightMeasure(stateArrayDBassignment(json.data.heelHeightMeasure, json.data.images.length))
					setSoleMeasure(stateArrayDBassignment(json.data.soleMeasure, json.data.images.length))
					setInSoleMeasure(stateArrayDBassignment(json.data.inSoleMeasure, json.data.images.length))
					setShoeWidth(stateArrayDBassignment(json.data.shoeWidth, json.data.images.length))				
					
					setLoading(false);
				})
				.catch((err) => {
					setWaitingText(
						'Please click on the "New" button in the top right corner'
					);
					console.log(`Error ${err}`);
				});
		}
	}, [objectID, csvDownload]);

	// const fetchColumns = async () => {
	//   const columns = await axios.get(`https://apie.snapwrite.ca/storedata/table-column?userID=${userID}&template=${props.template}`)
	//   setColumns(columns.data.data)
	// }

	useEffect(() => {
		localStorage.setItem(
			`objectIDShopify-${props.template + '-' + userID}`,
			JSON.stringify(objectID)
		);
	}, [userID, objectID]);

const getInitialData = async () => {
	if (objectID === '') {
		const initializedData = {
			sku: [],
			images: [],
			backImage: [],
			supplementaryImages: [],
			types: [],
			itemGenericType: [],
			taxonomyUpdatedType: [],
			itemName: [],
			brand: [],
			vendor: [],
			size: [],
			sizeNotes: [],
			condition: [],
			conditionDescription: [],
			description: [],
			cost: [],
			price: [],
			retailPrice: [],
			fileNames: [],
			colors: [],
			gender: [],
			additionalInfo: [],
			variantGrams: [],
			material: [],
			sleeves: [],
			length: [],
			style: [],
			age: [],
			closure: [],
			fastening: [],
			fastening_type: [],
			neckline: [],
			cut: [],
			pocket: [],
			hood: [],
			typeObj: [],
			belt: [],
			design: [],
			lining: [],
			layers: [],
			pattern: [],
			skirt: [],
			rise: [],
			heelHeight: [],
			heelShape: [],
			height: [],
			toe: [],
			isArchived: false,
			tags: [],
			era: [],
			madeIn: [],
			pitToPitMeasure: [],
			shoulderMeasure: [],
			sleeveInSeam: [],
			lengthMeasure: [],
			waistMeasure: [],
			hipMeasure: [],
			bottomInSeam: [],
			riseMeasure: [],
			thighMeasure: [],
			legOpening: [],
			heelHeightMeasure: [],
			soleMeasure: [],
			inSoleMeasure: [],
			shoeWidth: [],
			theme: [],
			concerns: [],
			projectCSVGenerated: false,
			isFormGenerated: false,
			isProductFeedGenerated: false,
		};
		return initializedData;
	} else {
		let link = `https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`;
		if (props.template === 'vintage') {
			link = `https://apie.snapwrite.ca/storeData/vintage-apparel`;
		} else if (props.template === 'new-clothes') {
			link = `https://apie.snapwrite.ca/storeData/new-apparel`;
		} else if (props.template === 'pre-loved-regular') {
			link = `https://apie.snapwrite.ca/storeData/regular-preLoved-apparel`;
		}
		const data = await fetch(`${link}/${objectID}`)
			.then((response) => response.json())
			.then((json) => {
				return json.data;
			})
			.catch((err) => {
				console.log(`Error ${err}`);
			});
		return data;
	}
};
	

	const projectBodyRef = useRef({});

	function updateProperty(key, value, index) {
	// Initialize key if it doesn't exist
		if (!projectBodyRef.current.hasOwnProperty(key)) {
			if (objectID === '') {
				projectBodyRef.current[key] = [];
			} else {
				return; // Don't update if objectID exists and key doesn't exist
			}
		}

		if(key === "colors" && template === "new-clothes"){
			projectBodyRef.current[key][index] = [value || ""];

		}
		else if (value && value.length > 0 && value !== '') {
			projectBodyRef.current[key][index] = value;
		} else if (key === 'supplementaryImages') {
			projectBodyRef.current[key][index] = [];
		} else if ((userID === "lUrOR5b9i9TYSNJ4zEfGRTkoPqs1" || userID === "WbahZZa5HhfWAa6SyJLYnl31kMG3") && key === "vendor") {
			projectBodyRef.current[key][index] = 'LE NINETY';
		} else {
			projectBodyRef.current[key][index] = '';
		}

	}
	  


	async function getSuggestions(name, imageURL, supplementaryImagesURL, res, skuCounter, index) {
		try {
			let filename = name.substring(0, name.lastIndexOf('.')) || name;
			if (
				userID === '1eMplQPlHHhX9lnwX8NTcKghui22' ||
				userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1'
			) {
				filename = filename.split(/_| |-/)[0];
			}
			updateProperty('fileNames', filename, index);
			updateProperty('itemName', res.data.itemName, index); 
			updateProperty('description', res.data.description, index);
			updateProperty('conditionDescription', res.data.conditionDescription, index);
			updateProperty('concerns', res.data.concerns, index);
			updateProperty('era', res.data.era, index);
			updateProperty('madeIn', res.data.madeIn, index);
			updateProperty('lengthMeasure', res.data.lengthMeasure, index);
			updateProperty('sku', res.data.sku, index);
			updateProperty('images', res.data.image, index);
			updateProperty('backImage', res.data.backImage, index);
			updateProperty('supplementaryImages', supplementaryImagesURL, index);
			updateProperty('types', res.data.types, index);
			updateProperty('itemGenericType', res.data.itemGenericType, index);
			updateProperty('taxonomyUpdatedType', res.data.taxonomyUpdatedType, index);
			updateProperty('brand', res.data.brand, index);
			updateProperty('vendor', res.data.vendor, index);
			updateProperty('size', res.data.size, index);
			updateProperty('sizeNotes', res.data.sizeNotes, index);
			updateProperty('condition', res.data.condition, index);
			updateProperty('colors', res.data.colors, index);
			updateProperty('gender', res.data.gender, index);
			updateProperty('additionalInfo', res.data.additionalInfo, index);
			updateProperty('variantGrams', res.data.variantGrams, index);
			updateProperty('material', res.data.material, index);
			updateProperty('sleeves', res.data.sleeves, index);
			updateProperty('length', res.data.length, index);
			updateProperty('style', res.data.style, index);
			updateProperty('age', res.data.age, index);
			updateProperty('closure', res.data.closure, index);
			updateProperty('fastening', res.data.fastening, index);
			updateProperty('fastening_type', res.data.fastening_type, index);
			updateProperty('neckline', res.data.neckline, index);
			updateProperty('cut', res.data.cut, index);
			updateProperty('pocket', res.data.pocket, index);
			updateProperty('hood', res.data.hood, index);
			updateProperty('typeObj', res.data.typeObj, index);
			updateProperty('belt', res.data.belt, index);
			updateProperty('design', res.data.design, index);
			updateProperty('lining', res.data.lining, index);
			updateProperty('layers', res.data.layers, index);
			updateProperty('pattern', res.data.pattern, index);
			updateProperty('skirt', res.data.skirt, index);
			updateProperty('rise', res.data.rise, index);
			updateProperty('heelHeight', res.data.heelHeight, index);
			updateProperty('heelShape', res.data.heelShape, index);
			updateProperty('height', res.data.height, index);
			updateProperty('toe', res.data.toe, index);
			updateProperty('tags', res.data.tags, index);
			updateProperty('waistMeasure', res.data.waistMeasure, index);
			updateProperty('hipMeasure', res.data.hipMeasure, index);
			updateProperty('bottomInSeam', res.data.bottomInSeam, index);
			updateProperty('riseMeasure', res.data.riseMeasure, index);
			updateProperty('thighMeasure', res.data.thighMeasure, index);
			updateProperty('legOpening', res.data.legOpening, index);
			updateProperty('sleeveInSeam', res.data.sleeveInSeam, index);
			updateProperty('pitToPitMeasure', res.data.pitToPitMeasure, index);
			updateProperty('shoulderMeasure', res.data.shoulderMeasure, index);
			updateProperty('heelHeightMeasure', res.data.heelHeightMeasure, index);
			updateProperty('soleMeasure', res.data.soleMeasure, index);
			updateProperty('inSoleMeasure', res.data.inSoleMeasure, index);
			updateProperty('shoeWidth', res.data.shoeWidth, index);
			updateProperty('cost', res.data.cost, index);


			const firstLetterCapital = (str) => {
				return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
					letter.toUpperCase()
				);
			};

			if (res.data.item.toString().replace(/,/g, ' ')) {
				let typeVal = firstLetterCapital(
					res.data.item.toString().replace(/,/g, ' ')
				);
				updateProperty('itemGenericType', typeVal, index);
			}

			//Product type

			if (res.data.type.length === 0) {
				if (res.data.item.toString().replace(/,/g, ' ')) {
					let typeVal = firstLetterCapital(
						res.data.item.toString().replace(/,/g, ' ')
					);
					if (typeVal.includes('/')) {
						typeVal = typeVal.substring(typeVal.indexOf('/') + 1);
					}
					updateProperty('types', typeVal, index);

					if (userID === '9BjTXuFQ6TMYrn5cy6dclMyYqDz1') {
						//yyz
						// console.log(res.data.hood)
						const nameToPush =(
							firstLetterCapital(
								((res.data.brand && res.data.brand + ' ') ||
									'') +
								renameItem(
									userID,
									typeVal,
									res.data.hood,
									res.data.design,
									res.data.sleeves
								)
							)
						);
						updateProperty('itemName', nameToPush, index);
					} else {
						const nameToPush = ItemNameFiller(userID, typeVal);
						updateProperty('itemName', nameToPush, index);
					}
				}
			} else {
				const typesToPush = firstLetterCapital(
						res.data.type.toString().replace(/,/g, ' ')
				);
				updateProperty('types', typesToPush, index);

				if (userID === '9BjTXuFQ6TMYrn5cy6dclMyYqDz1') {
					//yyz id
					const nameToPush = 
						firstLetterCapital(
							(res.data.color[0] ? res.data.color[0] : '') +
							' ' +
							((res.data.brand && res.data.brand + ' ') ||
								'') +
							renameItem(
								userID,
								res.data.type.toString().replace(/,/g, ' '),
								res.data.hood,
								res.data.design,
								res.data.sleeves
							)
						)

					updateProperty('itemName', nameToPush, index);
				} else {
					const nameToPush = 
						ItemNameFiller(
							userID,
							firstLetterCapital(
								res.data.type.toString().replace(/,/g, ' ')
							)
						)
					updateProperty('itemName', nameToPush, index);
				}
			}

			const TaxonomyTypeToPush = TaxonomyUpdate(
					userID,
					res.data.item.toString(),
					res.data.type.toString(),
					props.template,
					res.data.sleeves
				)
				updateProperty('taxonomyUpdatedType', TaxonomyTypeToPush, index);
			updateProperty('brand', res.data.brand, index);

			const usersWithCustomSkus = []
				// Do not generate SKU for these usersWithCustomSkus
			if (
				!usersWithCustomSkus.includes(userID)
			) {
			const generatedSKU = await generateSKU(userID);
			updateProperty('sku', generatedSKU, index);
			} 

			//Product Vendor
			let vendors = vendor;
			if (false
				// userID === '1eMplQPlHHhX9lnwX8NTcKghui22' ||
				// userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1'
			) {
				if (/^CC\d/.test(filename)) {
					vendors.push('Coels Capsule');
				} else if (/^CPV\d/.test(filename)) {
					vendors.push('Cherry Pick Vintage');
				} else if (/^TRV\d/.test(filename)) {
					vendors.push('The Room Vintage');
				} else if (/^ST\d/.test(filename)) {
					vendors.push('SkyThrifts');
				} else if (/^BLT\d/.test(filename)) {
					vendors.push('Baldylox Thrifts');
				} else {
					vendors.push('');
				}
			} else {
				updateProperty('vendor', res.data.vendor, index);
			}

			// Condition

			let conditionToPush;

			if (
				userID === 'CXNDhrE6FfNRdXNm6nUQlmck29f2' ||
				userID === 'xHaYXalwv8X8RpBLZju0PaQlWW23' ||
				userID === 'zjkiN1cq9Eb4oHsrA923OoJ9Sxz1' ||
				userID === 'AgVEaDRk3tVtnfqAKiMYWLlRICG2'
			) {
				//Piece By Piece Kids and Tokki and tiny human gear
				conditionToPush = 'Excellent Used Condition';
			} else if (
				userID === 'HDlknVR9NpWxhs9WWlfvnwAQq4b2' ||
				userID === 'ZFoq2NIsDmZgVTW4g88O1B8JA822' ||
				props.template === 'pre-loved-regular'
			) {
				conditionToPush = 'Very Good';
			} else if (userID === 'VIa289fKOLZVRQTAEuueJXwvA0y2') {
				conditionToPush = 'Good condition';
			} else if (props.template === 'new-clothes') {
				conditionToPush = '';
			} else if (props.template === 'vintage') {
				if (
					userID === '1eMplQPlHHhX9lnwX8NTcKghui22' ||
					userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1'
				) {
					conditionToPush = 'Good';
				} else if (userID === 'r8jkmnica3dJXGLyiIFbItEgZvr2') {
					conditionToPush = 'Excellent Vintage Condition';
				} else {
					conditionToPush = 'Like New';
				}
			} else if (userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1") {
				conditionToPush = 'Excellent'
			} else {
				conditionToPush = 'Excellent Used Condition';
			}
			updateProperty('condition', conditionToPush, index);


			//Product Price
			updateProperty('price', res.data.price, index);

			// Retail Price
			updateProperty('retailPrice', res.data.retailPrice, index);

			//Product Size
			let sizeToPush;
			if (
				userID === 'VPQROHGh7pNC9du2JnL4fIopeoh1' ||
				userID === 't4gFVUQejYQYBY6dhwOp2mkYMTe2' ||
				userID === 'WbahZZa5HhfWAa6SyJLYnl31kMG3' ||
				props.template === 'vintage' ||
				props.template === 'new-clothes' ||
				props.template === 'pre-loved-regular'
			) {
				//Loose Fade
				sizeToPush = 'M';
			} else {
				sizeToPush = '';
			}
			updateProperty('size', sizeToPush, index);

			// Size Notes
			updateProperty('sizeNotes', res.data.sizeNotes, index);

			//Product Gender
			let gendersToPush;

			if (
				userID === 'VPQROHGh7pNC9du2JnL4fIopeoh1' ||
				userID === 't4gFVUQejYQYBY6dhwOp2mkYMTe2' ||
				userID === 'WbahZZa5HhfWAa6SyJLYnl31kMG3' ||
				props.template === 'vintage' ||
				props.template === 'new-clothes' ||
				props.template === 'pre-loved-regular'
			) {
				if (res.data.gender.includes('Boys')) {
					gendersToPush = 'Men';
				} else if (res.data.gender.includes('Girls')) {
					gendersToPush = 'Women';
				}
			} else {
				gendersToPush = 
					firstLetterCapital(
						res.data.gender.toString().replace(/,/g, ' ')
					)
			}

			updateProperty('gender', gendersToPush, index);

			//Product Color
			let colorToPush;
			colorToPush = firstLetterCapital(res.data.color.toString().replace(/,/g, ' '))
			updateProperty('colors', colorToPush, index);

			// Additional Information like material etc.
			updateProperty('additionalInfo', res.data.additionalInfo, index);


			if (res.data.material) {
				updateProperty('material', res.data.material.join(', '), index);
			} else {
				updateProperty('material', res.data.material, index);


			}


			if (res.data.length) {
				updateProperty('length', res.data.length.join(', '), index);
			} else {
				updateProperty('length', res.data.length, index);
			}


			if (res.data.style) {
				updateProperty('style', res.data.style.join(', '), index);
			} else {
				updateProperty('style', res.data.style, index);
			}


			if (res.data.age) {
				updateProperty('age', res.data.age.join(', '), index);
			} else {
				updateProperty('age', res.data.age, index);
			}


			if (res.data.design) {
				updateProperty('design', res.data.design.join(', '), index);
			} else {
				updateProperty('design', res.data.design, index);
			}


			if (res.data.closure) {
				updateProperty('closure', res.data.closure.join(', '), index);
			} else {
				updateProperty('closure', res.data.closure, index);
			}


			if (res.data.fastening) {
				updateProperty('fastening', res.data.fastening.join(', '), index);
			} else {
				updateProperty('fastening', res.data.fastening, index);
			}


			if (res.data.fasteningType) {
				updateProperty('fastening_type', res.data.fasteningType.join(', '), index);
			} else {
				updateProperty('fastening_type', res.data.fasteningType, index);
			}


			if (res.data.belt) {
				updateProperty('belt', res.data.belt.join(', '), index);
			} else {
				updateProperty('belt', res.data.belt, index);
			}


			if (res.data.cut) {
				updateProperty('cut', res.data.cut.join(', '), index);
			} else {
				updateProperty('cut', res.data.cut, index);
			}


			if (res.data.neckline) {
				updateProperty('neckline', res.data.neckline.join(', '), index);
			} else {
				updateProperty('neckline', res.data.neckline, index);
			}


			if (res.data.sleeves) {
				updateProperty('sleeves', res.data.sleeves.join(', '), index);
			} else {
				updateProperty('sleeves', res.data.sleeves, index);
			}


			if (res.data.hood) {
				updateProperty('hood', res.data.hood.join(', '), index);
			} else {
				updateProperty('hood', res.data.hood, index);
			}


			if (res.data.lining) {
				updateProperty('lining', res.data.lining.join(', '), index);
			} else {
				updateProperty('lining', res.data.lining, index);
			}


			if (res.data.layers) {
				updateProperty('layers', res.data.layers.join(', '), index);
			} else {
				updateProperty('layers', res.data.layers, index);
			}


			if (res.data.pattern) {
				updateProperty('pattern', res.data.pattern.join(', '), index);
			} else {
				updateProperty('pattern', res.data.pattern, index);
			}

			if (res.data.skirt) {
				updateProperty('skirt', res.data.skirt.join(', '), index);
			} else {
				updateProperty('skirt', res.data.skirt, index);
			}


			if (res.data.rise) {
				updateProperty('rise', res.data.rise.join(', '), index);
			} else {
				updateProperty('rise', res.data.rise, index);
			}


			if (res.data.heelHeight) {
				updateProperty('heelHeight', res.data.heelHeight.join(', '), index);
			} else {
				updateProperty('heelHeight', res.data.heelHeight, index);
			}


			if (res.data.heelShape) {
				updateProperty('heelShape', res.data.heelShape.join(', '), index);
			} else {
				updateProperty('heelShape', res.data.heelShape, index);
			}


			if (res.data.height) {
				updateProperty('height', res.data.height.join(', '), index);
			} else {
				updateProperty('height', res.data.height, index);
			}


			if (res.data.toe) {
				updateProperty('toe', res.data.toe.join(', '), index);
			} else {
				updateProperty('toe', res.data.toe, index);
			}


			if (res.data.types) {
				updateProperty('typeObj', res.data.types.join(', '), index);
			} else {
				updateProperty('typeObj', res.data.types, index);
			}

			//Variant Grams
			updateProperty('variantGrams', res.data.variantGrams, index);
			updateProperty('tags', res.data.tags, index);



		} catch (e) {
			console.log('Please try using a different image');
			console.error(e);
		}
	}

	function isNumeric(str) {
		if (typeof str != 'string') return false; // we only process strings!
		return (
			!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
			!isNaN(parseFloat(str))
		); // ...and ensure strings of whitespace fail
	}

	// console.log(imageFiles)
	const uploadImage = async (e, str) => {
		e.preventDefault();
		setLoading(true);
		let skuCounter = SKUStart
		
		// console.log(skuCounter)
		let file = e.target.files;
		if (str === 'drop') {
			file = e.dataTransfer.files;
		}
		//for aws images link
		const imageURL = [];
		const supplementaryImagesURL = [];
		let fileArray = [];
		for (let fileIdx in file) {
			if (!isNumeric(fileIdx)) break;
			let newFile = file[fileIdx];
			let newCleanFile = { name: newFile.name.split('.')[0], newFile }; // remove .png, .webp
			fileArray.push(newCleanFile);
		}
		fileArray.sort((fileA, fileB) => {
			// cluster same names together
			if (fileA.name > fileB.name) return 1;
			else if (fileA.name < fileB.name) return -1;
			return 0;
		});
		let clusterIdx = -1;
		let lastFileName = '';
		let heroFiles = []; // files of hero images

		let isSupplementary = false;
		if (
			userID === '1eMplQPlHHhX9lnwX8NTcKghui22' ||
			userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1'
		) {
			for (let index = 0; index < fileArray.length; index++) {
				// for each image file
				let currentFileName = fileArray[index].name.split(/_| |-/)[0]; // remove _1, _2, _3
				if (currentFileName !== lastFileName) {
					// find clustered images
					// hero image
					lastFileName = currentFileName;
					clusterIdx++;
					isSupplementary = false;
					heroFiles.push(fileArray[index].newFile);
				} else {
					//supplementary image
					isSupplementary = true;
				}
				const { url } = await fetch(
					'https://apie.snapwrite.ca/storeData/s3Url'
				).then((res) => res.json());
				// console.log(url)
				// let blobData = new Blob([new Uint8Array(imageFile)], {type: 'image/jpeg'})
				// post the image direclty to the s3 bucket
				await fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'image/jpeg',
					},
					body: fileArray[index].newFile,
				}).catch((err) => console.error(err));
				let currentURL = url.split('?')[0];
				if (isSupplementary) {
					supplementaryImagesURL[clusterIdx].push(currentURL);
				} else {
					supplementaryImagesURL.push([]);
					imageURL.push(currentURL);
				}
			}

			const result = await getResponse(splitArray(imageURL), userID, setBackendAlertMessage, backendAlertMessage)

				projectBodyRef.current = await getInitialData();

				const lastProjectLength = projectBodyRef.current.images.length;


			for (let i = 0; i < heroFiles.length; i++) {
				// skuCounter++
				await getSuggestions(
					heroFiles[i].name,
					imageURL[i],
					supplementaryImagesURL[i],
					{ data: result[i] },
					skuCounter,
					objectID.length > 0 && lastProjectLength> 0 ? lastProjectLength + i : i // index
				);
			}

		} else {
			for (let index = 0; index < file.length; index++) {
				const imageFile = file[index];

				// get secure url from our server
				const { url } = await fetch(
					'https://apie.snapwrite.ca/storeData/s3Url'
				).then((res) => res.json());
				// console.log(url)
				// let blobData = new Blob([new Uint8Array(imageFile)], {type: 'image/jpeg'})
				// post the image direclty to the s3 bucket
				await fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'image/jpeg',
					},
					body: imageFile,
				}).catch((err) => console.error(err));
				imageURL.push(url.split('?')[0]);
			}

			const result = await getResponse(splitArray(imageURL), userID, setBackendAlertMessage, backendAlertMessage)
			if (!result) {
				return;
			}

			projectBodyRef.current = await getInitialData();
			const lastProjectLength = projectBodyRef.current.images.length;


			// Updates the projectBodyRef.current with the response from the backend
			for (let index = 0; index < file.length; index++) {
				skuCounter++
				await getSuggestions(file[index].name, imageURL[index], [], {
					data: result[index],
				}, skuCounter, 
				objectID.length > 0 && lastProjectLength > 0  ? lastProjectLength + index : index // index
				);
			}

		}


		// Update States for the new project and existing project
		setProjectData(projectBodyRef.current);
		setConcerns(projectBodyRef.current.concerns);
		setS3Images(projectBodyRef.current.images);
		setBackImage(projectBodyRef.current.backImage);
		setSupplementaryImages(projectBodyRef.current.supplementaryImages);
		setType(projectBodyRef.current.types);
		setItemGenericType(projectBodyRef.current.itemGenericType);
		setTypesTaxoUpdate(projectBodyRef.current.taxonomyUpdatedType);
		setItemName(projectBodyRef.current.itemName);
		setBrand(projectBodyRef.current.brand);
		setSku(projectBodyRef.current.sku);
		setVendor(projectBodyRef.current.vendor);
		setSize(projectBodyRef.current.size);
		setSizeNotes(projectBodyRef.current.sizeNotes);
		setCostPerItem(projectBodyRef.current.cost);
		setPrice(projectBodyRef.current.price);
		setRetailPrice(projectBodyRef.current.retailPrice);
		setCondition(projectBodyRef.current.condition);
		setConditionDescription(projectBodyRef.current.conditionDescription);
		setDescription(projectBodyRef.current.description);
		setImageFileName(projectBodyRef.current.fileNames);
		setColor(projectBodyRef.current.colors);
		setGender(projectBodyRef.current.gender);
		setVariantGrams(projectBodyRef.current.variantGrams);
		setTags(projectBodyRef.current.tags);
		setMaterial(projectBodyRef.current.material);
		setSleeves(projectBodyRef.current.sleeves);
		setStyle(projectBodyRef.current.style);
		setLength(projectBodyRef.current.length);
		setAge(projectBodyRef.current.age);
		setClosure(projectBodyRef.current.closure);
		setFastening(projectBodyRef.current.fastening);
		setFasteningType(projectBodyRef.current.fastening_type);
		setNeckline(projectBodyRef.current.neckline);
		setCut(projectBodyRef.current.cut);
		setHood(projectBodyRef.current.hood);
		setTypeObj(projectBodyRef.current.typeObj);
		setBelt(projectBodyRef.current.belt);
		setDesign(projectBodyRef.current.design);
		setLining(projectBodyRef.current.lining);
		setLayers(projectBodyRef.current.layers);
		setPattern(projectBodyRef.current.pattern);
		setSkirt(projectBodyRef.current.skirt);
		setRise(projectBodyRef.current.rise);
		setHeelHeight(projectBodyRef.current.heelHeight);
		setHeelShape(projectBodyRef.current.heelShape);
		setHeight(projectBodyRef.current.height);
		setToe(projectBodyRef.current.toe);
		setEra(projectBodyRef.current.era);
		setMadeIn(projectBodyRef.current.madeIn);
		setLengthMeasure(projectBodyRef.current.lengthMeasure);
		setsleeveInSeam(projectBodyRef.current.sleeveInSeam);
		setPitToPitMeasure(projectBodyRef.current.pitToPitMeasure);
		setShoulderMeasure(projectBodyRef.current.shoulderMeasure);
		setWaistMeasure(projectBodyRef.current.waistMeasure);
		setHipMeasure(projectBodyRef.current.hipMeasure);
		setBottomInSeam(projectBodyRef.current.bottomInSeam);
		setRiseMeasure(projectBodyRef.current.riseMeasure);
		setThighMeasure(projectBodyRef.current.thighMeasure);
		setLegOpening(projectBodyRef.current.legOpening);
		setAddtionaInfo(projectBodyRef.current.additionalInfo);
		setHeelHeightMeasure(projectBodyRef.current.heelHeightMeasure);
		setSoleMeasure(projectBodyRef.current.soleMeasure);
		setInSoleMeasure(projectBodyRef.current.inSoleMeasure);


		setLoading(false);
		console.log(skuCounter)
		setSKUStart(skuCounter)
//		updateSKUstart(skuCounter, userID)
		if (objectID === '') {
			
				// Create New Project in DB
			storeData(
				projectBodyRef.current.concerns,
				props.template,
				userID,
				projectName,
				projectBodyRef.current.images,
				projectBodyRef.current.backImage,
				projectBodyRef.current.supplementaryImages,
				projectBodyRef.current.types,
				projectBodyRef.current.itemGenericType,
				projectBodyRef.current.taxonomyUpdatedType,
				projectBodyRef.current.itemName,
				projectBodyRef.current.brand,
				projectBodyRef.current.sku,
				projectBodyRef.current.vendor,
				projectBodyRef.current.size,
				projectBodyRef.current.sizeNotes,
				projectBodyRef.current.cost,
				projectBodyRef.current.price,
				projectBodyRef.current.retailPrice,
				projectBodyRef.current.condition,
				projectBodyRef.current.conditionDescription,
				projectBodyRef.current.description,
				projectBodyRef.current.fileNames,
				projectBodyRef.current.colors,
				projectBodyRef.current.gender,
				projectBodyRef.current.variantGrams,
				projectBodyRef.current.tags,
				projectBodyRef.current.material,
				projectBodyRef.current.sleeves,
				projectBodyRef.current.style,
				projectBodyRef.current.length,
				projectBodyRef.current.age,
				projectBodyRef.current.closure,
				projectBodyRef.current.fastening,
				projectBodyRef.current.fastening_type,
				projectBodyRef.current.neckline,
				projectBodyRef.current.cut,
				projectBodyRef.current.hood,
				projectBodyRef.current.typeObj,
				projectBodyRef.current.belt,
				projectBodyRef.current.design,
				projectBodyRef.current.lining,
				projectBodyRef.current.layers,
				projectBodyRef.current.pattern,
				projectBodyRef.current.skirt,
				projectBodyRef.current.rise,
				projectBodyRef.current.heelHeight,
				projectBodyRef.current.heelShape,
				projectBodyRef.current.height,
				projectBodyRef.current.toe,
				projectBodyRef.current.era,
				projectBodyRef.current.madeIn,
				projectBodyRef.current.lengthMeasure,
				projectBodyRef.current.sleeveInSeam,
				projectBodyRef.current.pitToPitMeasure,
				projectBodyRef.current.shoulderMeasure,
				projectBodyRef.current.waistMeasure,
				projectBodyRef.current.hipMeasure,
				projectBodyRef.current.bottomInSeam,
				projectBodyRef.current.riseMeasure,
				projectBodyRef.current.thighMeasure,
				projectBodyRef.current.legOpening,
				projectBodyRef.current.additionalInfo,
				projectBodyRef.current.heelHeightMeasure,
				projectBodyRef.current.soleMeasure,
				projectBodyRef.current.inSoleMeasure,
				setObjectID,
			);


		} else if (objectID && objectID !== '') {
			const getNewlyAddedElements = (
				element,
				typeOfArray = '1dArray'
			) => {
				if (element && typeOfArray !== '2dArray') {
					return element.slice(-file.length);
				} else if (element && typeOfArray === '2dArray') {
					return element;
				}
				if (typeOfArray === '2dArray') {
					return Array(element.length).fill([]);
				} else {
					return Array(file.length).fill('');
				}
			};

			// Update the existing items.

			const body = {
				images: getNewlyAddedElements(projectBodyRef.current.images),
				backImage: getNewlyAddedElements(projectBodyRef.current.backImage),
				supplementaryImages: getNewlyAddedElements(
					projectBodyRef.current.supplementaryImages,
					'2dArray'
				),
				types: getNewlyAddedElements(projectBodyRef.current.types),
				itemGenericType: getNewlyAddedElements(projectBodyRef.current.itemGenericType),
				taxonomyUpdatedType: getNewlyAddedElements(projectBodyRef.current.taxonomyUpdatedType),
				itemName: getNewlyAddedElements(projectBodyRef.current.itemName),
				brand: getNewlyAddedElements(projectBodyRef.current.brand),
				sku: getNewlyAddedElements(projectBodyRef.current.sku),
				vendor: getNewlyAddedElements(projectBodyRef.current.vendor),
				size: getNewlyAddedElements(projectBodyRef.current.size),
				sizeNotes: getNewlyAddedElements(projectBodyRef.current.sizeNotes),
				cost: getNewlyAddedElements(projectBodyRef.current.cost),
				price: getNewlyAddedElements(projectBodyRef.current.price),
				retailPrice: getNewlyAddedElements(projectBodyRef.current.retailPrice),
				condition: getNewlyAddedElements(projectBodyRef.current.condition),
				conditionDescription:
					getNewlyAddedElements(projectBodyRef.current.conditionDescription),
				description: getNewlyAddedElements(projectBodyRef.current.description),
				fileNames: getNewlyAddedElements(projectBodyRef.current.fileNames),
				colors: template === "new-clothes"? getNewlyAddedElements(projectBodyRef.current.colors, '2dArray') : getNewlyAddedElements(projectBodyRef.current.colors),
				gender: getNewlyAddedElements(projectBodyRef.current.gender),
				variantGrams: getNewlyAddedElements(projectBodyRef.current.variantGrams),
				tags: getNewlyAddedElements(projectBodyRef.current.tags, '2dArray'),
				material: getNewlyAddedElements(projectBodyRef.current.material),
				sleeves: getNewlyAddedElements(projectBodyRef.current.sleeves),
				style: getNewlyAddedElements(projectBodyRef.current.style),
				length: getNewlyAddedElements(projectBodyRef.current.length),
				age: getNewlyAddedElements(projectBodyRef.current.age),
				closure: getNewlyAddedElements(projectBodyRef.current.closure),
				fastening: getNewlyAddedElements(projectBodyRef.current.fastening),
				fastening_type: getNewlyAddedElements(projectBodyRef.current.fastening_type),
				neckline: getNewlyAddedElements(projectBodyRef.current.neckline),
				cut: getNewlyAddedElements(projectBodyRef.current.cut),
				hood: getNewlyAddedElements(projectBodyRef.current.hood),
				typeObj: getNewlyAddedElements(projectBodyRef.current.typeObj),
				belt: getNewlyAddedElements(projectBodyRef.current.belt),
				design: getNewlyAddedElements(projectBodyRef.current.design),
				lining: getNewlyAddedElements(projectBodyRef.current.lining),
				layers: getNewlyAddedElements(projectBodyRef.current.layers),
				pattern: getNewlyAddedElements(projectBodyRef.current.pattern),
				skirt: getNewlyAddedElements(projectBodyRef.current.skirt),
				rise: getNewlyAddedElements(projectBodyRef.current.rise),
				heelHeight: getNewlyAddedElements(projectBodyRef.current.heelHeight),
				heelShape: getNewlyAddedElements(projectBodyRef.current.heelShape),
				height: getNewlyAddedElements(projectBodyRef.current.height),
				toe: getNewlyAddedElements(projectBodyRef.current.toe),
				era: getNewlyAddedElements(projectBodyRef.current.era),
				madeIn: getNewlyAddedElements(projectBodyRef.current.madeIn),
				//Measurements
				pitToPitMeasure: getNewlyAddedElements(projectBodyRef.current.pitToPitMeasure),
				shoulderMeasure: getNewlyAddedElements(projectBodyRef.current.shoulderMeasure),
				sleeveInSeam: getNewlyAddedElements(projectBodyRef.current.sleeveInSeam),
				lengthMeasure: getNewlyAddedElements(projectBodyRef.current.lengthMeasure),
				waistMeasure: getNewlyAddedElements(projectBodyRef.current.waistMeasure),
				hipMeasure: getNewlyAddedElements(projectBodyRef.current.hipMeasure),
				bottomInSeam: getNewlyAddedElements(projectBodyRef.current.bottomInSeam),
				riseMeasure: getNewlyAddedElements(projectBodyRef.current.riseMea),
				thighMeasure: getNewlyAddedElements(projectBodyRef.current.thighMeasure),
				legOpening: getNewlyAddedElements(projectBodyRef.current.legOpening),
				additionalInfo: getNewlyAddedElements(projectBodyRef.current.additionalInfo),
				heelHeightMeasure: getNewlyAddedElements(projectBodyRef.current.heelHeightMeasure),
				soleMeasure: getNewlyAddedElements(projectBodyRef.current.soleMeasure),
				inSoleMeasure: getNewlyAddedElements(projectBodyRef.current.inSoleMeasure),
				shoeWidth: getNewlyAddedElements(projectBodyRef.current.shoeWidth),
				concerns: getNewlyAddedElements(projectBodyRef.current.concerns),
			};

			const localHost = 'http://localhost:3500';
			const snapwriteAPI = 'https://apie.snapwrite.ca';
			const currentEndPoint = snapwriteAPI;

			let link = `${currentEndPoint}/storeData/bulk-labeling-appendRows/${objectID}`;
			if (props.template === 'vintage') {
				link = `${currentEndPoint}/storeData/vintage-apparel-appendRows/${objectID}`;
			} else if (props.template === 'new-clothes') {
				link = `${currentEndPoint}/storeData/new-apparel-appendRows/${objectID}`;
			} else if (props.template === 'pre-loved-regular') {
				link = `${currentEndPoint}/storeData/regular-preLoved-apparel-appendRows/${objectID}`;
			}
			// Update the existing items.
			axios
				.put(link, body)
				.then((response) => { })
				.catch((error) => {
					// Handle errors
					console.error(error);
				});

			const singleProductLabelEndpoint =
				'https://apie.snapwrite.ca/storeData/single-product';
			const bodyWithProjectID = {
				...body,
				userID: userID,
				projectID: objectID,
				storeName: [organization?.name],
				organizationID: [organization?.id],
			};

			axios
				.post(singleProductLabelEndpoint, bodyWithProjectID)
				.then((res) => console.log(res))
				.catch((err) => console.log(err));
		}

		projectBodyRef.current = {} // reset the projectBodyRef.current
	};

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};
	const updateVal = (name, event, index, val, setVal) => {
		const singleProductModelFilter = {userID, sku: sku[index]}

    const newVal = val.slice();
    
    newVal[index] = event;
    setVal(newVal);	
    if(newVal.length>0 && objectID!==""){      
      updateColumnValue(singleProductModelFilter, props.template, name, newVal, objectID)
    }
  }

	const updateValIndividual = (name, event, index, key) => {
		const singleProductModelFilter = {userID, sku: sku[index]}


		const newVal = event;
		if (isNaN(event)) {
			if (event.includes(`"`)) {
				event = event.replace(/"/g, `'`);
			}
		}
		if (newVal !== null && newVal !== undefined && objectID !== '') {
			updateColumnValue(
				singleProductModelFilter,
				props.template,
				name,
				event,
				objectID,
				index,
				key
			);
		}
	};

	const reset = () => {
		setProjectName('');
		setS3Images([]);
		setBackImage([]);
		setSupplementaryImages([]);
		setImageFileName([]);
		setItemName([]);
		setItemGenericType([]);
		setType([]);
		setTypesTaxoUpdate([]);
		setBrand([]);
		setSku([]);
		setVendor([]);
		setPrice([]);
		setCostPerItem([]);
		setRetailPrice([]);
		setSize([]);
		setSizeNotes([]);
		setCondition([]);
		setDescription([]);
		setConditionDescription([]);
		setGender([]);
		setColor([]);
		setAddtionaInfo([]);
		setVariantGrams([]);
		setLoading(false);
		setTags([]);
		setMaterial([]);
		setLength([]);
		setStyle([]);
		setAge([]);
		setDesign([]);
		setBelt([]);
		setClosure([]);
		setFastening([]);
		setFasteningType([]);
		setSleeves([]);
		setNeckline([]);
		setCut([]);
		setHood([]);
		setTypeObj([]);
		setObjectID('');
		setLining([]);
		setLayers([]);
		setPattern([]);
		setSkirt([]);
		setRise([]);
		setHeelHeight([]);
		setHeelShape([]);
		setHeight([]);
		setToe([]);
		//Vintage
		setEra([]);
		setMadeIn([]);
		setConcerns([])
		//Measurements

		setHipMeasure([]);
		setBottomInSeam([]);
		setRiseMeasure([]);
		setThighMeasure([]);
		setLegOpening([]);
		setPitToPitMeasure([]);
		setShoulderMeasure([]);
		setsleeveInSeam([]);
		setLengthMeasure([]);
		setWaistMeasure([]);
		setHeelHeightMeasure([])
		setSoleMeasure([])
		setInSoleMeasure([])
		setShoeWidth([])
    	setSharedWith("")
	};


	const updateProjectName = (event) => {
		setProjectName(event);
		if (objectID !== '') {
			fetch(`${link}-projectNameUpdate`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					id: objectID,
					projectName: event,
				}),
			})
				.then((response) => response.json())
				.catch((err) => {
					console.log(`Error ${err}`);
				});
		}
	};


	const generateCSVData = async (button, isImport = false) => {
		setLoading(true);
		setWaitingText('Enhancing SEO. Please Wait for about a minute');
		const json = await fetch(`${link}/${objectID}`).then((response) =>
			response.json()
		);
		const preferences = await axios.get(
			`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`
		);
		const preferenceData = preferences.data.data;
		axios.put(
			'https://apie.snapwrite.ca/storeData/user-preferences-updateVal',
			{
				userID: userID,
				name: 'taxonomyOptions',
				val: [
					...new Set([
						...preferenceData.taxonomyOptions,
						...json.data.taxonomyUpdatedType.filter(
							(item) => typeof item === 'string'
						),
					]),
				],
			}
		);

		const data =
			button === 'wixCSV'
				? await createWixCSV(props.template, userID, objectID, isImport)
				: await createcsv(props.template, userID, objectID, isImport);
		SetCsvDownload(data);
		setLoading(false);
		setWaitingText('Loading...');
		return data;
	};

	function downloadCSV(csvAsObj) {
		const headers = Object.keys(csvAsObj[0]);
		const csvRows = [];

		const headerRow = headers.join(',');
		csvRows.push(headerRow);

		for (const item of csvAsObj) {
			const values = headers.map((header) => item[header]);
			const csvRow = values.join(',');
			csvRows.push(csvRow);
		}

		const csvString = csvRows.join('\n');
		const csvData = new Blob([csvString], {
			type: 'text/csv;charset=utf-8;',
		});
		saveAs(
			csvData,
			`${projectName !== '' ? projectName : 'Import'}_SnapWriteAI.csv`
		);
	}

	return (
		<MainSideMenuHeaderContainer
			reset={reset}
			name={"labels"}
			updateProjectName={updateProjectName}
			projectName={projectName}
			setProjectName={setProjectName}
		>
			<div
				className=''
				onDrop={(e) => uploadImage(e, 'drop')}
				onDragOver={(e) => e.preventDefault()}
			>
				{/* Error handling when backendClient fails  */}
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={backendAlertMessage.display}
					autoHideDuration={3000}
					onClose={() => {
						setBackendAlertMessage({
							...backendAlertMessage,
							display: false,
							message: '',
						});
						//If the loading is displayed when error occurs
						isLoading && setLoading(false);
					}}
				>
					<MuiAlert
						onClose={() => {
							setBackendAlertMessage({
								...backendAlertMessage,
								display: false,
								message: '',
							});
							//If the loading is displayed when error occurs
							isLoading && setLoading(false);
						}}
						severity='error'
						sx={{ width: '100%' }}
					>
						Error: {backendAlertMessage.message}
					</MuiAlert>
				</Snackbar>

				<Snackbar
					open={success}
					autoHideDuration={3000}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						severity='success'
						sx={{ width: '100%' }}
					>
						Successful!
					</Alert>
				</Snackbar>
				<Snackbar
					open={fail}
					autoHideDuration={3000}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						severity='error'
						sx={{ width: '100%' }}
					>
						Error
					</Alert>
				</Snackbar>

				{/* ******************************the <main> section****************************** */}

				<div
					id='featureTableWrapper'
				>
					{/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
					<div

					>
						<div

						>
							{/* {s3Images.length === 0 && isLoading !== "Loading..." && objectID==="" ? ( */}
							{s3Images.length === 0 && !isLoading ? (
								<ImagesUploadContainer
									uploadImage={uploadImage}
									template={props.template}
								/>
							) : (
								<div>
									{isLoading || objectID === '' ? (
										<h2 style={{ textAlign: 'center' }}>
											{waitingText}
											{waitingText ===
												'Enhancing SEO. Please Wait for about a minute' ? (
												<>
													<br />
													<img
														src='https://media.tenor.com/fJ2vm3_UhcAAAAAi/google-working.gif'
														alt='loading...'
													/>
												</>
											) : waitingText !==
												'Please click on the "New" button in the top right corner' ? (
												<>
													{' '}
													<br />
													<img
														src='https://media.tenor.com/qxgH66DCp7EAAAAi/%E3%81%A1%E3%82%87%E3%81%A3%E3%81%A8%E5%BE%85%E3%81%A3%E3%81%A6-%E5%B0%91%E3%81%97%E6%99%82%E9%96%93%E3%82%92%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84.gif'
														alt='loading...'
													/>
												</>
											) : (
												<></>
											)}
										</h2>
									) : (
										<>
											<TableList
												// setColumns={setColumns}
												fetchedBrands={
													fetchedBrands
												}
												preferredItemTypes={
													preferredItemTypes
												}
												template={props.template}
												objectID={objectID}
												setImageFileName={
													setImageFileName
												}
												setS3Images={setS3Images}
												updateVal={updateVal}
												updateValIndividual={
													updateValIndividual
												}
												setType={setType}
												s3Images={s3Images}
												supplementaryImages={
													supplementaryImages
												}
												setSupplementaryImages={
													setSupplementaryImages
												}
												backImage={backImage}
												setBackImage={setBackImage}
												imageFileName={
													imageFileName
												}
												itemGenericType={
													itemGenericType
												}
												setItemGenericType={
													setItemGenericType
												}
												types={types}
												typesTaxoUpdate={
													typesTaxoUpdate
												}
												setTypesTaxoUpdate={
													setTypesTaxoUpdate
												}
												brand={brand}
												setBrand={setBrand}
												sku={sku}
												setSku={setSku}
												vendor={vendor}
												setVendor={setVendor}
												condition={condition}
												setCondition={setCondition}
												conditionDescription={
													conditionDescription
												}
												setConditionDescription={
													setConditionDescription
												}
												description={description}
												setDescription={
													setDescription
												}
												itemName={itemName}
												setItemName={setItemName}
												size={size}
												setSize={setSize}
												sizeNotes={sizeNotes}
												setSizeNotes={setSizeNotes}
												price={price}
												setPrice={setPrice}
												retailPrice={retailPrice}
												setRetailPrice={
													setRetailPrice
												}
												costPerItem={costPerItem}
												setCostPerItem={
													setCostPerItem
												}
												genders={genders}
												setGender={setGender}
												colors={colors}
												setColor={setColor}
												additionalInfos={
													additionalInfos
												}
												setAdditionalInfo={
													setAddtionaInfo
												}
												variantGrams={variantGrams}
												setVariantGrams={
													setVariantGrams
												}
												tags={tags}
												setTags={setTags}
												userID={userID}
												material={material}
												setMaterial={setMaterial}
												length={length}
												setLength={setLength}
												style={style}
												setStyle={setStyle}
												age={age}
												setAge={setAge}
												design={design}
												setDesign={setDesign}
												closure={closure}
												setClosure={setClosure}
												fastening={fastening}
												setFastening={setFastening}
												fasteningType={
													fastening_type
												}
												setFasteningType={
													setFasteningType
												}
												belt={belt}
												setBelt={setBelt}
												cut={cut}
												setCut={setCut}
												neckline={neckline}
												setNeckline={setNeckline}
												sleeves={sleeves}
												setSleeves={setSleeves}
												hood={hood}
												setHood={setHood}
												typeObj={typeObj}
												setTypeObj={setTypeObj}
												lining={lining}
												setLining={setLining}
												layers={layers}
												setLayers={setLayers}
												pattern={pattern}
												setPattern={setPattern}
												skirt={skirt}
												setSkirt={setSkirt}
												rise={rise}
												setRise={setRise}
												heelHeight={heelHeight}
												setHeelHeight={
													setHeelHeight
												}
												heelShape={heelShape}
												setHeelShape={setHeelShape}
												height={height}
												setHeight={setHeight}
												toe={toe}
												setToe={setToe}
												// Measurements

												waistMeasure={waistMeasure}
												setWaistMeasure={
													setWaistMeasure
												}
												hipMeasure={hipMeasure}
												setHipMeasure={
													setHipMeasure
												}
												bottomInSeam={bottomInSeam}
												setBottomInSeam={
													setBottomInSeam
												}
												riseMeasure={riseMeasure}
												setRiseMeasure={
													setRiseMeasure
												}
												thighMeasure={thighMeasure}
												setThighMeasure={
													setThighMeasure
												}
												legOpening={legOpening}
												setLegOpening={
													setLegOpening
												}
												sleeveInSeam={sleeveInSeam}
												setsleeveInSeam={
													setsleeveInSeam
												}
												pitToPitMeasure={
													pitToPitMeasure
												}
												setPitToPitMeasure={
													setPitToPitMeasure
												}
												shoulderMeasure={
													shoulderMeasure
												}
												setShoulderMeasure={
													setShoulderMeasure
												}
												lengthMeasure={
													lengthMeasure
												}
												setLengthMeasure={
													setLengthMeasure
												}
												// shoes
												heelHeightMeasure={heelHeightMeasure}
												setHeelHeightMeasure={setHeelHeightMeasure}
												soleMeasure={soleMeasure}
												setSoleMeasure={setSoleMeasure}
												inSoleMeasure={inSoleMeasure}
												setInSoleMeasure={setInSoleMeasure}
												shoeWidth={shoeWidth}
												setShoeWidth={setShoeWidth}
												// Vintage
												era={era}
												setEra={setEra}
												madeIn={madeIn}
												setMadeIn={setMadeIn}
												isLoading={isLoading}
												setLoading={setLoading}
												// Utilized for ExportButton.js
												downloadCSV={downloadCSV}
												generateCSVData={
													generateCSVData
												}
												//Theme Setters
												theme={theme}
												setTheme={setTheme}

												//New Table Component
												projectData={projectData}
												setProjectData={setProjectData}
												uploadImage={uploadImage}
												accountType={accountType}
                          						setAccountType={setAccountType}
												sharedWith={sharedWith}
												setSharedWith={setSharedWith}
												offerAcceptStatus={offerAcceptStatus}
												setOfferAcceptStatus={setOfferAcceptStatus}
												concerns={concerns}
												setConcerns={setConcerns}
											/>

										
											{/* TODO Enable the shopify button  */}
											{/* {!isLoading && ( // hide buttons when loading
												// <div className="suggestions">
												<div
												>
														{window.location !==
															window.parent
																.location && (
															<ImportButton
																{...{
																	csvDownload,
																	projectName,
																	setFail,
																	setSuccess,
																	generateCSVData,
																	userID,
																}}
															/>
														)}
														{userID ===
														'9BjTXuFQ6TMYrn5cy6dclMyYqDz1' ? (
															<button
																style={{
																	padding:
																		'7px',
																	fontSize:
																		'12px',
																}}
																className='btn'
																onClick={async () => {
																	generateCSVData(
																		'wixCSV'
																	).then(
																		(
																			result
																		) => {
																			downloadCSV(
																				result
																			);
																		}
																	);
																}}
															>
																Generate WIX CSV
															</button>
														) : userID ===
														  'NpGB6zItW1OcpizZJbD88VsfKlf2' ? (
															<>
																<button
																	style={{
																		padding:
																			'7px',
																		fontSize:
																			'12px',
																	}}
																	className='btn'
																	onClick={async () => {
																		generateCSVData(
																			'wixCSV'
																		).then(
																			(
																				result
																			) => {
																				downloadCSV(
																					result
																				);
																			}
																		);
																	}}
																>
																	Generate WIX
																	CSV
																</button>
																<button
																	className='btn'
																	onClick={async () => {
																		await generateCSVData(
																			'shopifyCSV'
																		);
																		const generateButton =
																			document.getElementById(
																				'csvLinkButton'
																			);
																		generateButton.click();
																	}}
																	style={{
																		padding:
																			'7px',
																		fontSize:
																			'12px',
																	}}
																>
																	Shopify CSV
																</button>
															</>
														) : (
															<button
																className='btn'
																style={{
																	padding:
																		'7px',
																	fontSize:
																		'12px',
																}}
																onClick={async () => {
																	const isImport = false;
																	await generateCSVData(
																		'shopifyCSV',
																		isImport
																	);
																	const generateButton =
																		document.getElementById(
																			'csvLinkButton'
																		);
																	generateButton.click();
																}}
															>
																Shopify CSV
															</button>
														)}

												</div>
											)} */}
											{/* <div className="backToTop" id="backToTop">
                        <a href="#tableLoaded">
                          <img src={upArrow} />
                        </a>
                      </div> */}
											{(!isLoading && window.location !== window.parent.location) &&  // hide buttons when loading
												<div style={{ zIndex: 1000, position: "absolute", bottom: 12, left: "47%" }} className="suggestions bg-slate-500">
													<div>
														<span														
														>									
														{
															<ImportButton {...{ csvDownload, projectName, setFail, setSuccess, generateCSVData, userID, size, template, objectID }} />
														}
														
														</span>
													</div>
												</div>
											}
										</>
									)}
								</div>
							)}
						</div>
						<CSVLink
							id='csvLinkButton'
							hidden
							className='btn'
							data={csvDownload}
							// onClick={async ()=> await generateCSVData()}

							filename={`${projectName !== '' ? projectName : 'Import'
								}_SnapWriteAI.csv`}
						/>
					</div>
				</div>
			</div>
		</MainSideMenuHeaderContainer>
	);
};
export default MultiImage;

/* ************************************************************* */
