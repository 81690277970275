import axios from 'axios';

function createEbayCSV(projectData) {
    const headerLine1 = 'Info,Version=1.0.0,Template=fx_category_template_EBAY_US';
    const headerLine2 = '*Action(SiteID=US|Country=US|Currency=USD|Version=1193|CC=UTF-8)';
    const csvHeader = `CustomLabel,*Category,StoreCategory,*Title,Subtitle,Relationship,RelationshipDetails,*ConditionID,*C:Brand,*C:Size,*C:Type,*C:Style,*C:Size Type,*C:Color,*C:Department,*C:Inseam,C:Theme,C:Fit,C:Material,C:Waist Size,C:Features,C:Fabric Type,C:Product Line,C:Leg Style,C:Front Type,C:Accents,C:Occasion,C:Pattern,C:Closure,C:Vintage,C:Country/Region of Manufacture,C:Season,C:Model,C:Handmade,C:Rise,C:Personalize,C:Pocket Type,C:Garment Care,C:California Prop 65 Warning,C:MPN,C:Personalization Instructions,C:Unit Quantity,C:Unit Type,*C:Outer Shell Material,C:Lining Material,C:Insulation Material,C:Performance/Activity,C:Jacket/Coat Length,C:Warmth Weight,C:Collar Style,C:Chest Size,C:Fabric Wash,C:Character,C:Character Family,C:Sleeve Length,C:Number of Pieces,C:Neckline,PicURL,GalleryType,VideoID,*Description,*Format,*Duration,*StartPrice,BuyItNowPrice,BestOfferEnabled,BestOfferAutoAcceptPrice,MinimumBestOfferPrice,*Quantity,ImmediatePayRequired,*Location,ShippingType,ShippingService-1:Option,ShippingService-1:Cost,ShippingService-2:Option,ShippingService-2:Cost,*DispatchTimeMax,PromotionalShippingDiscount,ShippingDiscountProfileID,*ReturnsAcceptedOption,ReturnsWithinOption,RefundOption,ShippingCostPaidByOption,AdditionalDetails,EconomicOperator CompanyName,EconomicOperator AddressLine1,EconomicOperator AddressLine2,EconomicOperator City,EconomicOperator Country,EconomicOperator PostalCode,EconomicOperator StateOrProvince,EconomicOperator Phone,EconomicOperator Email\n`;
    let csvData = `${headerLine1}\n${headerLine2},${csvHeader}`;

    for (let i = 0; i < projectData.sku.length; i++) {
        const CustomLabel = projectData.sku[i];
        const Category = ""; // You need to fill this based on eBay category
        const Title = projectData.itemName[i];
        const Description = projectData.description[i];
        const ConditionID = ""; // You need to fill this based on condition
        const Brand = projectData.brand[i];
        const Size = projectData.size[i];
        const Type = ""; // You need to fill this based on item type
        const Style = projectData.style[i];
        const SizeType = ""; // You need to fill this based on item size type
        const Color = projectData.colors[i];
        const Department = ""; // You need to fill this based on item department
        const Inseam = ""; // You need to fill this based on item inseam
        const duration = "GTC"; // You need to fill this based on item inseam
        // Other fields can be filled similarly based on your projectData

        // Comma-separated values for each field, placeholders used for empty fields
        const csvRow = `Add,*${CustomLabel},${Category},,${Title},,,,,,,,,${ConditionID},${Brand},${Size},${Type},${Style},${SizeType},${Color},${Department},${Inseam},,,,,,,,,,,,,,,,,,,,,,,,,,${Description},,,,$,,,*,,,,,,,\n`;
        csvData += csvRow;
    }

    // Create a Blob object with CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ebay_bulk_upload.csv';

    // Append the <a> element to the body
    document.body.appendChild(a);

    // Simulate a click event to trigger the download
    a.click();

    // Remove the temporary <a> element
    document.body.removeChild(a);
}




export const generateEbayCSV = async (objectID, template) => {
	const snapwrite = 'https://apie.snapwrite.ca/storeData';
	const localHost = 'http://localhost:3500/storeData';
	const BASE_URL = snapwrite;

	let link = `${BASE_URL}/labeling-clothes-categorization`;
	if (template === 'vintage') {
		link = `${BASE_URL}/vintage-apparel`;
	} else if (template === 'new-clothes') {
		link = `${BASE_URL}/new-apparel`;
	} else if (template === 'pre-loved-regular') {
		link = `${BASE_URL}/regular-preLoved-apparel`;
	}

	axios
		.get(`${link}/${objectID}`)
		.then((res) => {
			const projectData = res.data.data;
			createEbayCSV(projectData);
		})
		.catch((err) => console.log(err));
};
