import React, { createContext, useContext, useState } from 'react';

// Create the context
const MenuContext = createContext();

// Create a provider component
export const MenuProvider = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);


    const openMenu = () => setIsMenuOpen(true);
    const closeMenu = () => setIsMenuOpen(false);


    return (
        <MenuContext.Provider value={{ isMenuOpen, openMenu, closeMenu, selectedItem, setSelectedItem }}>
            {children}
        </MenuContext.Provider>
    );
};

// Create a custom hook to use the MenuContext
export const useMenu = () => {
    const context = useContext(MenuContext);
    if (context === undefined) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};