import { hoodAttachmentType } from "./hoodAttachmentType";
import { hoodFurAttachment } from "./hoodFurAttachment";
import { hoodFurType } from "./hoodFurType";

const hood = [
    "Cowl",
    "Covering",
    "Bonnet",
    "Capote",
    "Mantle",
    "Shroud",
    "Cloak",
    "Cowling",
    "Visor",
    "hood"
  ];
  
  const synonymsArray = [hood, ...hoodAttachmentType, ...hoodFurAttachment, ...hoodFurType]

export const getHoodSynonyms = (hood) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => hood.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 