export const themeOptions = {
	palette: {
		type: 'light',
		primary: {
			main: '#30334b',
		},
		secondary: {
			main: '#e8fb0a',
		},
		error: {
			main: '#f63f3c',
		},
		success: {
			main: '#77b143',
		},
	},
	typography: {
		body1: {
			fontSize: 16,
			fontWeight: 400,
		},
		h1: {
			fontSize: 30,
			fontWeight: 700,
			letterSpacing: '0.03em',
		},
		subtitle1: {
			fontSize: 12,
		},
	},
};
