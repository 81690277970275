const adult = [
    "Grown",
    "Mature",
    "Adult"
  ];

const synonymsArray = [adult]

export const getAgeSynonyms = (age) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => age.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
}