import { PrimaryButton } from '../../../../components/branded-elements/Elements';
import { updateProjectStatusAsExported } from '../../../../utils/utilFunctions';

const ExportButton = ({
	userID,
	generateCSVData,
	downloadCSV,
	template,
	projectId,
	exportButtonPreference,
}) => {
	const adminIDs = ['NpGB6zItW1OcpizZJbD88VsfKlf2'];

	return adminIDs.includes(userID) ? (
		<>
			<PrimaryButton
				title='Shopify CSV'
				onClick={async () => {
					await generateCSVData('shopifyCSV');
					const generateButton =
						document.getElementById('csvLinkButton');
					generateButton.click();
					updateProjectStatusAsExported(template, projectId);
				}}
			/>

			<PrimaryButton
				title='Generate WIX CSV'
				onClick={async () => {
					generateCSVData('wixCSV').then((result) => {
						downloadCSV(result);
					});
				}}
			/>
		</>
	) : exportButtonPreference === 'shopify' ? (
		<PrimaryButton title='Shopify CSV'
		onClick={async () => {
				await generateCSVData('shopifyCSV');
				const generateButton = document.getElementById('csvLinkButton');
				generateButton.click();
				updateProjectStatusAsExported(template, projectId);
			}}
		/>

			
		
	) : exportButtonPreference === 'wix' ? (
		<button
			className='btn'
			onClick={async () => {
				generateCSVData('wixCSV').then((result) => {
					downloadCSV(result);
				});
			}}
		>
			Generate WIX CSV
		</button>
	) : null;
};

export default ExportButton;
