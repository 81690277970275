import React from "react";
import PlusIcon from "../../assets/PlusIcon.png";
import "../../css/App.css";
import {useContext, useEffect, useState } from "react";
import { getAuth } from "@firebase/auth";
import { useParams } from "react-router";
import trashCan from "../../assets/trash_can.png";
import BackArrow from '@mui/icons-material/ArrowBack';

import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Alert, CircularProgress, Menu, MenuItem } from "@mui/material";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { OrganizationContext } from "../App.js";
import EmailPreview from "../../pages/EmailPreview.js";


import Handlebars from 'handlebars';
import MainSideMenuHeaderContainer from "../containers/MainSideMenuHeaderContainer.js";
import { Heading1, PrimaryButton } from "../branded-elements/Elements";

// SKU
import generateSKU from "../../products/bulk/shopify/utils/skuCreator/skuGenerate.js";
import useSafePromise from "../../hooks/useSafePromise.js";

const NewView = ({ history, props }) => {
  const [SKUStart, setSKUStart] = useState()
  const { organization, setOrganization } = useContext(OrganizationContext)
  const [customEmailData, setCustomEmailData] = useState({})
  const [isEmailTemplateCustom, setIsEmailTemplateCustom] = useState(false)
  const [isSellerViewLinkPresent, setIsSellerViewLinkPresent] = useState(false)

let userID;
  let userName
  const auth = getAuth();
  const user = auth.currentUser;
  const {id} = useParams()
  
  if (user !== null) {
    userID = organization?.id || auth.currentUser.uid;
    userName = auth.currentUser.displayName;
  }

	useEffect(()=>{
		if (!organization?.id) return;
		fetch(`https://apie.snapwrite.ca/storeData/apparel-input-batch/?userID=${organization?.id}`)
			.then((res) => res.json())
			.then((res2) => {
				setCustomers(res2.data.filter(obj => !obj.isArchived).map(obj => ({ label: obj.batchName || "Not Named", value: obj._id, address: obj.defaultAddress || "", phone: obj.defaultPhoneNumber || "", email: obj.defaultEmail || ""})))
				setCustomerLoading(false)
			})

	}, [organization])
  

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      const token = localStorage.getItem("token");
      if (!token || !user) {        
        history.push("/");
      }
    }); 
    	
  }, [auth]);

  const tx = document.getElementsByClassName("condition-notes");
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute(
      "style",
      "height:" +
        tx[i].scrollHeight +
        "px;overflow-y:hidden; resize:none; border: none; background: transparent; min-height: 30px"
    );
    tx[i].addEventListener("input", OnInput, false);
  }
  function OnInput() {
    this.style.height = 0;
    this.style.height = this.scrollHeight + "px";
  }

  useEffect(() => {
		// Set the body's style to allow scrolling when the component mounts
		document.body.style.overflow = 'auto';
	
		// Clean up the effect by restoring the original overflow style when the component unmounts
		return () => {
		  document.body.style.overflow = 'hidden';
		};
	  }, []);
  

  const [uniqueIdentifier, setUniqueIdentifier] = useState([]);
  const [proceed, setProceed] = useState([]);  
  const [itemName, setItemName] = useState([]);
  const [brand, setBrand] = useState([]);
  const [size, setSize] = useState([]);
  const [condition, setCondition] = useState([]);
  const [conditionNotes, setConditionNotes] = useState([]);
  const [cost, setCost] = useState([]);
  const [price, setPrice] = useState([]);
  const [color, setColor] = useState([])
  const [sku, setSku] = useState([]) // year-cutomerInitials-brandInitials-color three letters-     ex. 23-ar02-br-size-col-1
  const [customerName, setCustomerName] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("")
  const [paymentProcessed, setPaymentProcessed] = useState("");
  const [productReceived, setProductReceived] = useState("");
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [storeCredit, setStoreCredit] = useState(0)
  const [parentID, setParentID] = useState()
  const [formUserID, setFormUserID] = useState()
  const [userIDState, setUserIDState] = useState()
  const [open, setOpen] = useState(false);
  const [showSuccessConfirmation, setShowSuccessConfirmation] = useState(false);
  const [showFailedConfirmation, setShowFailedConfirmation] = useState(false);
  const [cashOffer, setCashOffer] = useState(0);
  const [customerLoading, setCustomerLoading] = useState(true)

  const [sendingEmail, setSendingEmail] = useState(false)


  const [customerEmail, setCustomerEmail] = useState("")
  const [customerAddress, setCustomerAddress] = useState("")


  const [canEdit, setCanEdit] = useState()
  const [customers, setCustomers] = useState([])
  const [isNewCustomer, setIsNewCustomer] = useState(false)
  const [customerEmpty, setCustomerEmpty] = useState(false)
  const [oldForm, setOldForm] = useState(false)
  const [newlyCreated, setNewlyCreated] = useState(false)
  const [isExported, setIsExported] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState("")
  const [defaultPhone, setDefaultPhone] = useState("")
  const [defaultAddress, setDefaultAddress] = useState("")

  const [fetchedBrands, setFetchedBrands] = useState()

  const [valChanged, setValChanged] = useState(false)

  const [customeEmailAlert ,setCustomeEmailAlert] = useState(true)
  const [formName, setFormName] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const { promiseInitiated, promiseResolved } = useSafePromise();
  const [isFormSaved, setIsFormSaved] = useState(false)
  const [isAddingRow, setIsAddingRow] = useState(false)
  

  const fetchBrands = async () => {
    const brands = await axios.get(`https://apie.snapwrite.ca/storeData/brands`)
    setFetchedBrands(brands.data.data.brands)
  }

  const handleChange = (event, val, setVal, index, name) => {

    const newValue = event;

    let temp = val.slice();    
    temp[index] = event;
    setVal(temp);
    updateData(name, newValue, index, true)
  };





  const handleDialogOpen = async () => {
    try {
      const res = await axios.get(`https://apie.snapwrite.ca/storeData/get-custom-email-data?userId=${userID}`);
      const customEmailResponse = res.data.data;
  
      // Used For Email Body and Subject Template 
      const templateToString = (template) => {
        const compiledTemplate = Handlebars.compile(template);
        const finalMessage = compiledTemplate({
          customerName: customerName,
          organizationName: organization.organizationName,
          userDisplayName: organization.displayName,
          cashOffer: `$${cashOffer}`,
          storeCredit: `$${storeCredit}`,
          sellerViewLink: `[SELLER-VIEW-LINK]`
        });
  
        return finalMessage;
      };
  
      if (customEmailResponse?.subjectTemplate) {
        const newSubject = templateToString(customEmailResponse.subjectTemplate);
        customEmailResponse.subjectTemplate = newSubject;
      }
  
      if (customEmailResponse?.bodyTemplate) {
        setIsEmailTemplateCustom(true)
        const newBody = templateToString(customEmailResponse.bodyTemplate);
        customEmailResponse.bodyTemplate = newBody;

        const sellerViewLinkRegex = /\[SELLER-VIEW-LINK\]/;
        setIsSellerViewLinkPresent(newBody.match(sellerViewLinkRegex));

      }
  
      setCustomEmailData(customEmailResponse);
      setOpen(true); 
    } catch (err) {
      console.log(err);
    }
  };
  
  
  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSuccessConfirmationClose = () => {
    setShowSuccessConfirmation(false);
  };

  const handleFailedConfirmationClose = () => {
    setShowFailedConfirmation(false);
  };

  const handleExport = async (exportTemplate) => {
    if (customerName === "") {
      setCustomerEmpty(true)
      return
    }

    handleRowCleanup(true)
    if (!isNewCustomer && !isExported) {
      await axios.put("https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal", {name: "unexportedCount", val: -1, id: parentID})
    }
    updateData("isExported", true)
    history.push(`../masterTable/${id}/${exportTemplate}`)
  }

  let customer;
  
  useEffect(()=>{
    fetchBrands()
    fetch(
      `https://apie.snapwrite.ca/storeData/apparel-form-categorization/${id}`
    )
      .then((response) => response.json())
      .then((json) => {
        setCustomerName(json.data.customerName)
        setNewlyCreated(json.data.newlyCreated)
        setContact(json.data.customerContact)
        setCustomerEmail(json.data.customerEmail)
        setCustomerAddress(json.data.customerAddress)
        setDescription(json.data.receptionDescription)
        setPaymentProcessed(json.data.paymentProcessed)
        setProductReceived(json.data.productReceived)
        setOfferAccepted(json.data.offerAccepted)  
        setTotalCost(json.data.totalCost);        
        setItemName(json.data.itemName)
        setBrand(json.data.brand)
        setSize(json.data.size)
        setCondition(json.data.condition)
        setConditionNotes(json.data.conditionNotes)
        setCost(json.data.cost)
        setPrice(json.data.price)        
        setProceed(json.data.proceed)
        setParentID(json.data.parentObjectID)
        setFormUserID(json.data.userID)
        setStoreCredit(json.data.storeCredit ? json.data.storeCredit : "")
        setCashOffer(json.data.cashOffer ? json.data.cashOffer : "")
        setColor(json.data.color ? json.data.color : Array(json.data.brand.length).fill(""))
        setSku(json.data.sku && json.data.sku.length!==0 ? json.data.sku : Array(json.data.brand.length).fill(""))

        setIsExported(json.data.isExported)
        setFormName(json.data.formName)


        if (json.data.parentObjectID !=="temp") {
          fetch(
            `https://apie.snapwrite.ca/storeData/apparel-input-batch/${json.data.parentObjectID}`
          )
          .then((response) => response.json())
          .then((json2) => {
            if (json.data.customerName === "") {
              setCustomerName(json2.data.batchName)
            }
            else if (json.data.customerName !== json2.data.batchName) {
              setOldForm(true)
            }

            if (!json.data.customerEmail || json.data.customerEmail === "") {
              setCustomerEmail(json2.data.defaultEmail)
              setDefaultEmail(json2.data.defaultEmail)
              updateData("customerEmail", json2.data.defaultEmail)
            }
            if (!json.data.customerAddress || json.data.customerAddress === "") {
              setCustomerAddress(json2.data.defaultAddress)
              setDefaultAddress(json2.data.defaultAddress)
              updateData("customerAddress", json2.data.defaultAddress)
            }
            if (!json.data.customerContact || json.data.customerContact === "") {
              setContact(json2.data.defaultPhoneNumber)
              setDefaultPhone(json2.data.defaultPhoneNumber)
              updateData("customerContact", json2.data.defaultPhoneNumber)
            }
          })
        }
      })
  }, [id, uniqueIdentifier])

  useEffect(() => {
    if (user && formUserID) {
      if(user.uid === formUserID || organization?.id === formUserID) {
        setCanEdit(true)
      }
      else {
        history.push("/")
      }
    }
  }, [user, formUserID, organization])

  const autoCalcCashOffer = () => {
    const total = cost.reduce((acc, curr) => acc + Number(curr), 0)
    setTotalCost(total)
    setCashOffer(total);
    updateData("cashOffer", total);
    if (user && (user.uid==="Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3" || organization?.id === "Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3" || organization?.id === "xqWcP58dezY1MGd8J249dDairz52")) {
      const calcStoreCredit = (total + (total * .2))
      setStoreCredit(calcStoreCredit);
      updateData("storeCredit", calcStoreCredit);
    }
    updateData("totalCost", total);
  }

  // useEffect(() => {
  //   const total = cost.reduce((acc, curr) => acc + Number(curr), 0)
  //   setTotalCost(total)
  //   console.log(total)
  //   console.log(total + (total * .2))
  //   // if (user && (user.uid==="Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3" || organization?.id === "Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3" || organization?.id === "xqWcP58dezY1MGd8J249dDairz52")) {
  //   //   setCashOffer(total);
  //   //   const calcStoreCredit = (total + (total * .2))
  //   //   setStoreCredit(calcStoreCredit);
  //   //   updateData("cashOffer", total);
  //   //   updateData("storeCredit", calcStoreCredit);
  //   // }
  //   // updateData("totalCost", total);
  // }, [cost])
  
  
  const updateBatchData = async (name, val) => {
    fetch(`https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: parentID,
        val: val,
        name: name,
      }),
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  };


  
const updateData = (name, val, index, isInputField) => {
  if(name==="parentObjectID" && val ===`cancelled-${userID}`) {
    axios.put("https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal", {name: "unexportedCount", val: -1, id: parentID})    
  }
  promiseInitiated();
  const production = "https://apie.snapwrite.ca/storeData/apparel-form"
  const development = "http://localhost:3500/storeData/apparel-form"
  const body = {
    id: id,
    val: val,
    name: name,
  };

  if (isInputField) {
    body.isInputField = true
    body.index = index
  }


  fetch(production, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      promiseResolved();
      return response.json();
    })
    .catch((err) => {
      setErrorMessage(`Error updating ${name}: ${err.message}`);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      console.error(`Error updating data: ${err.message}`);
      promiseResolved();
    });
};


const handleAddRow = async () => {
    try {
        setIsAddingRow(true);
        promiseInitiated();
        const response = await axios.put("https://apie.snapwrite.ca/storeData/apparel-form/add-row", {_id: id})

        if (response.status === 200) {
          const updatedData = response.data.data
            setItemName(updatedData.itemName)
            setBrand(updatedData.brand)
            setSize(updatedData.size)
            setCondition(updatedData.condition)
            setConditionNotes(updatedData.conditionNotes)
            setColor(updatedData.color)
            setSku(updatedData.sku)
            setCost(updatedData.cost)
            setPrice(updatedData.price)
            setProceed(updatedData.proceed)
        }
        promiseResolved();
        setIsAddingRow(false);
        
    } catch (error) {
        console.error("Error in handleAddRow:", error);
        promiseResolved();
        setIsAddingRow(false);
    }
};

  const handleRowCleanup = async (isFromMasterTable) => { // form is exported && back button

    // console.log("ran")
    if (customerName === "" && !isFromMasterTable) {
      setCustomerEmpty(true)
      return
    }

    updateData("customerName", customerName)
    
    if (!isNewCustomer && newlyCreated && !isFromMasterTable) {
      // console.log("newly old customert")

      if (defaultEmail === "" || !defaultEmail) {
        updateBatchData("defaultEmail", customerEmail)
      }
      if (defaultAddress === "" || !defaultAddress) {
        updateBatchData("defaultAddress", customerAddress)
      }
      if (defaultPhone === "" || !defaultPhone) {
        updateBatchData("defaultPhoneNumber", contact)
      }
    }
    

    // if (itemName.length > 0) {
    //   let rowsToDelete = []
    //    itemName.forEach((name, i) => {
    //     if(name === "" && brand[i] === "" && size[i] === "" && conditionNotes[i]==="" && cost[i] === "" && price[i] === "" && color[i] === "") {
    //       rowsToDelete.push(i)
    //     }
    //   })
    //   await deleteRows(rowsToDelete)
    // }

    updateData("newlyCreated", false)

    if (isNewCustomer) {
      if (!oldForm) {
        // console.log("not old form")
      await fetch('https://apie.snapwrite.ca/storeData/apparel-input-batch', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({                        
          userID: userID,
          userName: userName,
          isArchived: false,
          dateCreated: new Date(),
          unexportedCount: isFromMasterTable ? 0 : 1,
          batchName: customerName,
          defaultEmail: customerEmail,
          defaultAddress: customerAddress,
          defaultPhoneNumber: contact
        }),
      })
        .then((response) => response.json())
        .then(async (json) => {
          // setChildObjectID(json.data.id)
          await updateData("parentObjectID", json.data._id)
          if (!isFromMasterTable) {
            history.push(`/NewTable/${json.data._id}`)
          }
          // const generateButton = document.getElementById("createNewForm") 
          // generateButton.click()
        })
        
        
        .catch((err) => {
          console.log(`Error ${err}`);
        });
      }
  }

    if (!isFromMasterTable && !isNewCustomer) {
      // console.log("not new customer")
      history.push(`../newTable/${parentID}`)
    } 
  }
  
  const displayFormSavedStatus = () => {
    setIsFormSaved(true);
            setTimeout(() => {
              setIsFormSaved(false);
            }, 3000);
    

  }
const handleFormSave = async (isFromMasterTable) => {
    try {
        promiseInitiated();
        if (!customerName && !isFromMasterTable) {
            setCustomerEmpty(true);
            return;
        }

        setNewlyCreated(false);

        const body = {
            _id: id,
            userName,
            customerName,
            customerContact: contact,
            paymentProcessed,
            productReceived,
            offerAccepted,
            totalCost,
            newlyCreated: false,
            proceed,
            itemName,
            brand,
            size,
            color,
            sku,
            condition,
            conditionNotes,
            price,
            cost,
            cashOffer,
            customerEmail,
            customerAddress,
        };

        const response = await axios.put("https://apie.snapwrite.ca/storeData/apparel-form/bulk-update", body);
        if(response.status === 200) {
            promiseResolved();
            displayFormSavedStatus();
        }
    } catch (error) {
        console.log(`Error: ${error.message}`);
        promiseResolved();
    }
};


  const handleInputChange = (event, value) => {
    // console.log("Changed")
    // console.log(customers)
    if (value && !customers.find(option => option.label === value)) {
      // New value entered
      
      setIsNewCustomer(true)

    }
    else {
    setIsNewCustomer(false)

    }
    if(customerEmpty) {
      setCustomerEmpty(false)
    }
    updateData("customerName", customerName)
    setCustomerName(value)
  };

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: customerEmpty ? 'red' : 'rgba(0, 0, 0, 0.23)', 
            },
          },
        },
      },
    },
  });

  const deleteRows = async (indices) => {
    // Create copies of the existing arrays
    const newItemName = [...itemName];
    const newBrand = [...brand];
    const newColor = [...color];
    const newSku = [...sku];

    const newSize = [...size];
    const newCondition = [...condition];
    const newConditionNotes = [...conditionNotes];
    const newCost = [...cost];
    const newPrice = [...price];
  
    // Sort the indices array in descending order to ensure proper deletion
    const sortedIndices = indices.sort((a, b) => b - a);
  
    // Loop through the sorted indices and delete the corresponding rows
    sortedIndices.forEach((index) => {
      newItemName.splice(index, 1);
      newBrand.splice(index, 1);
      newColor.splice(index, 1);
      newSku.splice(index, 1);
      newSize.splice(index, 1);
      newCondition.splice(index, 1);
      newConditionNotes.splice(index, 1);
      newCost.splice(index, 1);
      newPrice.splice(index, 1);
    });
  
    // Update the state or call updateData with the modified arrays
    setItemName(newItemName);
    updateData("itemName", newItemName);
    setBrand(newBrand);
    updateData("brand", newBrand);
    setColor(newColor);
    updateData("color", newColor);
    setSku(newSku);
    setSKUStart(newSku[newSku.length-1].split('-')[1])
    //await updateSKUstart(newSku[newSku.length-1].split('-')[1], userID)
    updateData("sku", newSku);
    setSize(newSize);
    updateData("size", newSize);
    setCondition(newCondition);
    updateData("condition", newCondition);
    setConditionNotes(newConditionNotes);
    updateData("conditionNotes", newConditionNotes);
    setCost(newCost);
    updateData("cost", newCost);
    setPrice(newPrice);
    updateData("price", newPrice);
  };

  const handleSendEmail = () => {
    handleDialogClose()
    setSendingEmail(true)
    const body = {
      recipient: customerEmail,
      userID: userID,
      organizationName: organization.name,
      displayName: auth.currentUser.displayName,
      cashOffer: cashOffer,
      storeCredit: storeCredit,
      id: id,
      customerName: customerName
    }
    // console.log("file: NewView.js:523 ~ handleSendEmail ~ body:", body)
    // const snapwriteEndpoint = `https://apie.snapwrite.ca/`
    axios.post(`https://apie.snapwrite.ca/storeData/send-email`, body)
    .then((res) => {
      setSendingEmail(false)
      if (res.status === 200) {
        setShowSuccessConfirmation(true)
      }
      else {
        setShowFailedConfirmation(true)
      }
    })
    .catch((e) => {
      console.log(e)
      setSendingEmail(false)
      setShowFailedConfirmation(true)
    })
  }


  const deleteRow = async (index) => {
    // Create copies of the existing arrays
    const newItemName = [...itemName];
    const newBrand = [...brand];
    const newSize = [...size];
    const newCondition = [...condition];
    const newConditionNotes = [...conditionNotes];
    const newColor = [...color]
    const newSKU = [...sku]
    const newCost = [...cost];
    const newPrice = [...price];

    // Delete the row at the specified index
    newItemName.splice(index, 1);
    newBrand.splice(index, 1);
    newSize.splice(index, 1);
    newCondition.splice(index, 1);
    newConditionNotes.splice(index, 1);
    newColor.splice(index, 1)
    newSKU.splice(index, 1)
    // console.log(itemName.length-1, index)
    if(index === itemName.length-1) {
      setSKUStart(SKUStart -1)
      //await updateSKUstart(SKUStart -1, userID)
    }
    newCost.splice(index, 1);
    newPrice.splice(index, 1);

    // Update the state or call updateData with the modified arrays
    setItemName(newItemName);
    updateData("itemName", newItemName);
    setBrand(newBrand);
    updateData("brand", newBrand);
    setSize(newSize);
    updateData("size", newSize);
    setCondition(newCondition);
    updateData("condition", newCondition);
    setConditionNotes(newConditionNotes);
    updateData("conditionNotes", newConditionNotes);
    setColor(newColor)
    updateData("color", newColor)
    setSku(newSKU)
    updateData("sku", newSKU)
    setCost(newCost);
    updateData("cost", newCost);
    setPrice(newPrice);
    updateData("price", newPrice);
  }

  const updateCost = (index, price) => {
    if(userID === "Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1" || userID === "Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3") {  
      let percentage = 0
      if (userID === "Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1") {
        percentage = .35
      }
      else if (userID==="Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3") {
        percentage = .25
      }
      let costCal = (price*percentage)
      costCal = Math.round((costCal + Number.EPSILON) * 100) / 100
      handleChange(
        costCal,
        cost,
        setCost,
        index,
        'cost'
      );
    }
  }
  const updatePrice = (index, cost) => {
    //PBP
    if (userID === "CXNDhrE6FfNRdXNm6nUQlmck29f2" || userID === "xHaYXalwv8X8RpBLZju0PaQlWW23") {
      const priceCal = (cost*2)      
      handleChange(
        priceCal,
        price,
        setPrice,
        index,
        'price'
      );
    }
  }

  return (
    <>

<MainSideMenuHeaderContainer
name={undefined}
>

        {/* <TableList/> */}          
        <Alert style={{display: errorMessage.length > 0 ? "flex" : "none"}} severity="error">{errorMessage}</Alert>


          <div className="flex items-center justify-between" >
            <div className="flex items-center gap-5" >
              <button
                className="z-30 bg-transparent outline-none border-none focus:outline-none cursor-pointer"
                onClick={ () => {
                  handleRowCleanup(false)
                }}
              >
                <BackArrow color="primary" fontSize={"large"} />
              </button>

            <Heading1 title={"Forms"} />
            </div>


            {newlyCreated &&

              <button className="btn" onClick={() => {
                updateData("parentObjectID", `cancelled-${userID}`)
                //updateSKUstart(SKUStart-6, userID)
                history.push(`../batchAggregator`)
              }}  >Cancel Form</button>
            }
          </div>

          <div
            className="table"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(6,1fr)",
              marginTop: "30px",
            }}
          >
            <div
              style={{
                marginLeft: "5%",
                borderRight: "solid lightgrey 2px",
                padding: "0px 20px",
              }}
            >
              {!customerLoading &&
              <ThemeProvider theme={theme}>
              <Autocomplete
                disablePortal
                disableClearable
                freeSolo
                id="customer-name-autocomplete"
                options={customers}
                value={customerName}
                // getOptionLabel={option => option.displayName}
                sx={{ width: 180, fontSize: "normal" }}
                size="small"
                // getOptionSelected={(option, value) => option.label === value.label}
                // isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
                onChange={(e, value) => {
                  updateData("customerName", value.label)
                  updateData("parentObjectID", value.value)
                  updateData("customerEmail", value.email)
                  updateData("customerContact", value.phone)
                  updateData("customerAddress", value.address)
                  setContact(value.phone)
                  setCustomerEmail(value.email)
                  setParentID(value.value)
                  setCustomerAddress(value.address)
                  setIsNewCustomer(false)
                  if(customerEmpty) {
                    setCustomerEmpty(false)
                  }
                  setCustomerName(value.label)}}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer Name"
                    sx={{ fontSize: "normal" }}
                    variant = "outlined"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        outlineColor: customerEmpty ? 'red' : 'black',
                      },
                    }}
                  />
                )}
              />
              </ThemeProvider>}
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Name/Paycode:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  value={formName}
                  onChange={(event) => {
                    setFormName(event.target.value);
                    updateData("formName", event.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Phone:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}
                  value={contact}
                  onChange={(event) => {
                    setContact(event.target.value);
                    updateData("customerContact", event.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Email:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}
                  value={customerEmail}
                  onChange={(event) => {
                    setCustomerEmail(event.target.value);
                    updateData("customerEmail", event.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Address:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}
                  value={customerAddress}
                  onChange={(event) => {
                    setCustomerAddress(event.target.value);
                    updateData("customerAddress", event.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Description:
              </div>
              <div style={{ marginTop: "10px" }}>
                <textarea 
                  readOnly = {!canEdit}               
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                    updateData("receptionDescription", event.target.value);
                  }}
                />
              </div>

              <div style={{ marginTop: "10px", fontWeight: "bold" }}>	
                Items Processed:	
              </div>	
              <div style={{ marginTop: "10px" }}>	
                <select	
                  disabled = {!canEdit}	
                  value={paymentProcessed}	
                  onChange={(event) => {	
                    setPaymentProcessed(event.target.value);	
                    updateData("paymentProcessed", event.target.value);	
                  }}	
                >	
                  <option value={false}>No</option>	
                  <option value={true}>Yes</option>
                </select>
              </div>	
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>	
                Customer Contacted:	
              </div>	
              <div style={{ marginTop: "10px" }}>	
                <select	
                  disabled = {!canEdit}	
                  value={productReceived}	
                  onChange={(event) => {	
                    setProductReceived(event.target.value);	
                    updateData("productReceived", event.target.value);	
                  }}	
                >
                  <option value={false}>No</option>	
                  <option value={true}>Yes</option>	
                </select>
              </div>	
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>	
                Offer Accepted:	
              </div>	
              <div style={{ marginTop: "10px" }}>	
                <select	
                  disabled = {!canEdit}	
                  value={offerAccepted}	
                  onChange={(event) => {	
                    setOfferAccepted(event.target.value);	
                    updateData("offerAccepted", event.target.value);	
                  }}	
                >	
                  <option value={false}>No</option>	
                  <option value={true}>Yes</option>	
                </select>	
              </div>

              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Store Credit Offer:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}               
                  value={storeCredit}
                  onChange={(event) => {
                    setStoreCredit(event.target.value);
                    updateData("storeCredit", event.target.value);
                  }}
                />
              </div> 
              
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Cash Offer:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}
                  value={cashOffer}
                  onChange={(event) => {
                    setCashOffer(event.target.value);
                    updateData("cashOffer", event.target.value);
                  }}
                />
              </div>     
              {canEdit &&         
              <div style={{ textAlign: "center", marginTop: "20px"}}>
                <button style = {{width: "145px"}} className="btn h-10" onClick={() => handleFormSave(false)}>
                  {isFormSaved?  <CheckCircleIcon color="success" fontSize="small"/> : "Save Form"}
                </button>
                <br></br>
                <br></br>
                <button disabled = {sendingEmail} style = {{width: "145px"}} className="btn" onClick={() => {handleDialogOpen()}}>
                  {sendingEmail ? "Sending..." : "Send Email"}
                </button>
            <Dialog open={open} onClose={handleDialogClose} sx={{borderRadius:5}}>
              <DialogTitle className=" mt-2">Email Preview</DialogTitle>
              <DialogContent >
                      <DialogContentText className="">
                        <EmailPreview
                          customEmailData={customEmailData}
                          setCustomEmailData={setCustomEmailData}
                          customerEmail={customerEmail}
                          setCustomerEmail={setCustomerEmail}
                          userID={userID}
                          organizationName={organization?.name}
                          displayName={auth.currentUser.displayName}
                          cashOffer={cashOffer}
                          storeCredit={storeCredit}
                          id={id}
                          customerName={customerName}
                          handleDialogClose={handleDialogClose}
                          setSendingEmail={setSendingEmail}
                          setShowSuccessConfirmation={setShowSuccessConfirmation}
                          setShowFailedConfirmation={setShowFailedConfirmation}
                          isEmailTemplateCustom={isEmailTemplateCustom}
                          isSellerViewLinkPresent={isSellerViewLinkPresent}
                        />
                </DialogContentText>
              </DialogContent>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showSuccessConfirmation} autoHideDuration={3000} onClose={handleSuccessConfirmationClose}>
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSuccessConfirmationClose}
                severity="success"
              >
                Email Sent Successfully!
              </MuiAlert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showFailedConfirmation} autoHideDuration={3000} onClose={handleFailedConfirmationClose}>
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleFailedConfirmationClose}
                severity="error"
              >
                Email Failed to Send
              </MuiAlert>
            </Snackbar>
            
          </div>}
            </div>
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(7, 1fr)",
                  fontWeight: "bold",                  
                }}
              >
                <div className = "bulk-upload-FeatureTable" >
                <table className="mb-24">
                  <thead style={{ borderBottom: "1px solid" }}>
                    <tr className="tableheader">
                      <th style={{ textAlign: "center" }}>Proceed</th>
                      <th>
                        Item Name
                      </th>
                      <th>Brand</th>
                      <th>Size</th>
                      <th>Color</th>
                      <th>Sku</th>
                      <th style={{ textAlign: "center" }}>Condition</th>
                      <th>Cost</th>
                      <th>Price</th>
                      {canEdit && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {itemName.map((key, index) => {
                      return (
                        <tr key={index} className = {!proceed[index] && "row-disabled"} style ={proceed[index] ? {} : {opacity: "0.5"}}>
                          <td style={{ textAlign: "center" }}>
                            <input
                              disabled = {!canEdit}
                              name='proceed'
                              type="checkbox"
                              className="terms"
                              value={proceed[index]}
                              onChange={(event) => {
                                handleChange(
                                  !proceed[index],
                                  proceed,
                                  setProceed,                                  
                                  index,
                                  'proceed'
                                );
                              }}
                              checked={proceed[index]}
                            />
                          </td>

                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              value={itemName[index]}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  itemName,
                                  setItemName,                                  
                                  index,
                                  'itemName'
                                );
                              }}
                            />
                          </td>
                          <td>
                          {fetchedBrands && brand && typeof brand[index] === "string" && <Autocomplete
                            disablePortal
                            disableClearable
                            freeSolo
                            id="care-instructions-autocomplete"
                            options={fetchedBrands}
                            value={brand[index]}
                            sx={{ width: 400, fontSize: "normal" }}
                            size="small"
                            onChange={(e, value) => {
                              handleChange(
                                value,
                                brand,
                                setBrand,                                  
                                index,
                                'brand'
                              );
                              setValChanged(true)
                            }}
                            onInputChange={(e, value) => {
                              if (valChanged || value !== brand[index]) {
                                handleChange(
                                  value,
                                  brand,
                                  setBrand,                                  
                                  index,
                                  'brand'
                                );
                                setValChanged(true)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"brand"}
                                sx={{ fontSize: "normal" }}
                                variant = "outlined"
                              />
                            )}
                          />}
                          </td>
                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              value={size[index]}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  size,
                                  setSize,
                                  index,
                                  'size'
                                );
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              value={color[index]}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  color,
                                  setColor,                                  
                                  index,
                                  'color'
                                );
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              value={sku[index]}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  sku,
                                  setSku,                                  
                                  index,
                                  'sku'
                                );
                              }}
                            />
                          </td>
                          <td
                            style={{
                              gridColumn: "5",
                              gridRow: "1",
                              textAlign: "center",
                            }}
                          >
                            {userID==="5APyYorrgoZ8uKOcxp5YMz3J0zq1" || userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" ? 
                            <select defaultValue={"Excellent"} value={condition[index]} disabled = {!canEdit}
                            onChange={(event) => handleChange(event.target.value, condition, setCondition, index, 'condition')}
                            >                                                      
                              <option value="Excellent">
                                Excellent
                              </option>
                              <option value="Very good">
                                Very good
                              </option>
                              <option value="Like New">Like New</option>
                              <option value="New with tags">
                                New with tags
                              </option>
                              <option value="New without tags">
                                New without tags
                              </option>
                              <option value="Good">Good</option>
                              <option value="Play Condition">Play Condition</option>
                              {/* <option value="Fair Used Condition">Fair</option> */}
                            </select>
                            :
                            <select defaultValue={"Excellent Used Condition"} value={condition[index]} disabled = {!canEdit}
                            onChange={(event) => handleChange(event.target.value, condition, setCondition, index, 'condition')}
                            >
                              
                              <option value="Excellent Used Condition">
                                Excellent
                              </option>
                              <option value="Very Good Used Condition">
                                Very Good
                              </option>
                              <option value="Like New">Like New</option>
                              <option value="Brand New with Tags">
                                Brand New with Tags
                              </option>
                              <option value="New without Tags">
                                New without Tags
                              </option>
                              <option value="Good Used Condition">Good</option>
                              <option value="Play Condition">Play</option>
                              {/* <option value="Fair Used Condition">Fair</option> */}
                            </select>
                          }                            
                            <br />
                            <p>Notes:</p>
                            <textarea
                              readOnly = {!canEdit}
                              name="conditionDescription"
                              className="condition-notes"
                              style={{
                                background: "transparent",
                                border: "none",
                                height: "100%",
                                resize: "none",
                                width: "250px",
                              }}
                              onKeyDown={(e) => {
                                if (e.key === ",") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if (e.currentTarget.value.includes(",")) {
                                  e.currentTarget.value =
                                    e.currentTarget.value.replace(/,/g, ";");
                                }                                
                                  handleChange(
                                    e.target.value,
                                    conditionNotes,
                                    setConditionNotes,                                  
                                    index,
                                    'conditionNotes'
                                  );                                
                                // props.updateVal('conditionDescription', e.currentTarget.value, index, props.conditionDescription, props.setConditionDescription);
                              }}
                              value={conditionNotes[index]}
                            />
                          </td>
                          <td style = {{display: "flex", flexDirection:"column"}}>                            
                            <input
                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                            onWheel={(e) => e.target.blur()}
                              readOnly = {!canEdit}
                              style={{
                                gridColumn: "3",
                                gridRow: "1",
                                textAlign: "center",
                                border: "none",
                                width:"60px",
                                background: "transparent",
                                paddingBottom: "1.7rem",
                              }}
                              value={cost[index]}
                              type="number"
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  cost,
                                  setCost,
                                  index,
                                  'cost'
                                );
                                updatePrice(index, event.target.value)
                              }}
                            />
                                            
                         {index===(itemName.length -1) && <span className="mt-3"><PrimaryButton title="Total" onClick={()=>autoCalcCashOffer()} /> </span>}
                          </td>
                          <td>                            
                            <input
                             className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                             onWheel={(e) => e.target.blur()}
                              readOnly = {!canEdit}
                              style={{                                  
                                textAlign: "center",
                                border: "none",
                                background: "transparent",
                                paddingBottom: "1.7rem",
                                width:"60px",
                              }}
                              value={price[index]}
                              type="number"
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  price,
                                  setPrice,
                                  index,
                                  'price'
                                );
                                updateCost(index, event.target.value)
                              }}
                            />
                          </td>
                          {canEdit &&
                          <td>
                            <img src = {trashCan} onClick = {() => deleteRow(index)} style = {{maxWidth: "20px", height: "20px", cursor: "pointer", marginRight: "10px"}} />
                          </td>}
                          

                          {/* <td><div style={{ gridColumn: "6", gridRow: "1", textAlign: "center" }}><input value={cost[index]} onChange={(event)=>{setCost(event.target.value)}}/></div></td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                  
                </div>
              </div>
            <div className="flex fixed right-10 bottom-10 gap-10" style={{ zIndex: 10 }}>
              <button disabled={isAddingRow} className="btn h-12 w-40 flex items-center justify-center gap-3" onClick={handleAddRow}>
                {isAddingRow ?
                  (
                    <>
                      <CircularProgress color="inherit" size={18} /> Adding...
                    </>
                  ) :
                  (
                    <>
                      <img
                        className="plusicon"
                        style={{ height: "18px", width: "auto", marginRight: 4 }}
                        src={PlusIcon}
                        alt="Plus Icon"
                      />
                      Add a row

                    </>
                  )

                }


              </button>

              <ExportAsProject handleExport={handleExport} />
            </div>
          </div>
              
            </div>
            


      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={customerEmpty} autoHideDuration={3000} onClose={() => {setCustomerEmpty(false)}}>
              <MuiAlert
                elevation={6}
                variant="filled"
                severity="error"
                onClose={() => {setCustomerEmpty(false)}}
              >
                Customer Name Required
              </MuiAlert>
      </Snackbar>
      </MainSideMenuHeaderContainer>
    </>
  );
};

// Export As Project Dropdown Menu Component
function ExportAsProject({handleExport}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePreLoveExport = () => {
    handleClose()
    handleExport("preLovedKids")

  }
  const handleVintageExport = () => {
    handleClose()
    handleExport("vintage")
  }
  const handlePrelovedRegularExport = () => {
    handleClose()
    handleExport("pre-loved-regular")
  }
  return (
		<div>
			<Button
				variant='contained'
        disableElevation
				sx={{
					backgroundColor: '#e1fc00',
					color: '#000000',
					width: '200px',
					height: '40px',
					fontSize: '14px',
					textTransform: 'none',
					':hover': {
						backgroundColor: '#e1fc00',
						color: '#000000',
            outline: 'none'
					},
          ':focus': {
            outline: 'none'
          }
				}}
				id='export-project'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
			>
				Export as Project
			</Button>
			<Menu
				id='Export-as-project'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				a
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<MenuItem sx={{ width: 200 }} onClick={handlePreLoveExport}>
					Preloved Kids
				</MenuItem>
        <MenuItem sx={{ width: 200 }} onClick={handlePrelovedRegularExport}>
					Preloved Regular
				</MenuItem>
				<MenuItem sx={{ width: 200 }} onClick={handleVintageExport}>
					Vintage
				</MenuItem>
			</Menu>
		</div>
  );

}



export default NewView;
