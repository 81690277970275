export const wordCount = (string) =>{
  if(string){
    return string.split(" ").length
  }
}

export const characterCount = (string) =>{
  if (string){
    return string.length;
  }
}

export const sentenceCount = (str) =>{    
      const regex = /[.!?]/;      
    if(str){
    return str.split(regex).length - 1;
  }
}

export const keywordsMatched = (tagsArr, str)=>{
    let i=0;
    if(tagsArr && str){
    tagsArr.forEach(function(keyword){
      if(str.toLowerCase().includes(keyword.toLowerCase())){
        i++;
      }
    })
  }
    return i;
}

export const tagsList = (tagsArr, str)=>{
    let tagOptions=[];    
    if(tagsArr && str){
      tagsArr.forEach(function(keyword){
        if(str.toLowerCase().includes(keyword.toLowerCase())){
          tagOptions.push(keyword);
        }
      })
  }
    if(tagOptions!==undefined){
    return (tagOptions.map((tag)=>
    <option>{tag}</option>
    ));
  }
    else{
      return null;
    }
}

//   export default wordCount ;
//   export default characterCount;
//   export default sentenceCount;
//   export default tagsList;
//   export default keywordsMatched;