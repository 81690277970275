import React, { useContext, useEffect, useState, useMemo } from 'react';
import { getAuth } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import { OrganizationContext } from '../App';
import MainSideMenuHeaderContainer from '../containers/MainSideMenuHeaderContainer';
import { Heading1, PrimaryButton } from '../branded-elements/Elements';
import { MuiThemeProviderComponent } from '../theme/ThemeContext';
import ShareIcon from '@mui/icons-material/Share';
import {
	TextField,
	InputAdornment,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	Avatar,
} from '@mui/material';
import { CheckCircle, Search } from '@mui/icons-material';
import axios from 'axios';
import GetData from '../projectLibrary/getData';

export default function VendorManagement() {
	const auth = getAuth();
	const history = useHistory();
	const organization = useContext(OrganizationContext);
	const [userID, setUserID] = useState('');
	const [vendors, setVendors] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [vintageApparel, setVintageApparel] = useState([]);
	const [sharedWith, setSharedWith] = useState([]);
	const vendorSignupURL = 'https://app.snapwrite.ca/signup-vendor';
	const [isVendorSignUpURLCopied, setIsVendorSignUpURLCopied] =
		useState(false);

	useEffect(() => {
		const checkUserAuthentication = () => {
			// Checking user authentication state
			auth.onAuthStateChanged(async (user) => {
				const token = localStorage.getItem('token');

				if (!token || !user) {
					history.push('/');
				} else {
					setUserID(user.uid);

					try {
						// Fetch data for the authenticated user
						const foundData = await GetData(
							user.uid,
							setVintageApparel,
							'vintage-apparel-shared',
							'vintage-apparel-view'
						);
						const foundSharedProjectsId = foundData.map((item) => {
							return item.userID;
						});
						setSharedWith(foundSharedProjectsId);
					} catch (error) {
						console.error('Error fetching data:', error);
					}
				}
			});
		};

		checkUserAuthentication();
	}, []);

	const getAllVendors = async () => {
		try {
			const endPoint =
				'https://apie.snapwrite.ca/storeData/user-preferences-get-vendors';
			const response = await axios.get(endPoint);

			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error('Failed to fetch vendors');
			}
		} catch (error) {
			console.error('Error fetching vendors:', error.message);
			throw new Error('Unable to retrieve vendors');
		}
	};

	useEffect(() => {
		const updateVendors = async () => {
			const foundVendors = await getAllVendors();
			setVendors(foundVendors);
		};
		updateVendors();
	}, []);

	const updateVendorSplitPercentage = async (
		newValue,
		vendorObjectId,
		index
	) => {
		let value;
		newValue === '' ? (value = 0) : (value = newValue);
		const endpoint =
			'https://apie.snapwrite.ca/storeData/user-preferences-update-vender-split';
		const body = {
			_id: vendorObjectId,
			value: value,
		};

		const updatedVendorsList = vendors.map((vendor) => {
			if (vendor._id === vendorObjectId) {
				return { ...vendor, vendorSplitPercentage: parseInt(newValue) };
			}
			return vendor;
		});

		setVendors(updatedVendorsList);

		try {
			const response = await axios.put(endpoint, body);

			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error('Failed to update vendor split percentage');
			}
		} catch (error) {
			console.error(
				'Error updating vendor split percentage:',
				error.message
			);
			throw new Error('Unable to update vendor split percentage');
		}
	};

	const tableHeader = [
		{
			id: '1',
			name: 'Vendors',
		},
		{
			id: '2',
			name: 'Vendors Split (%)',
		},
		{
			id: '3',
			name: 'Date Added',
		},
		{
			id: '4',
			name: 'Shared Projects',
		},
	];

	const filteredVendors = useMemo(() => {
		if (!searchQuery.trim()) {
			return vendors; // Return original list if searchQuery is empty
		}
	
		const normalizedQuery = searchQuery.trim().toLowerCase();
		return vendors.filter((vendor) => {
			const orgNameMatch = vendor.organizationName
				.toLowerCase()
				.includes(normalizedQuery);
			const displayNameMatch = vendor.displayName
				.toLowerCase()
				.includes(normalizedQuery);
			return orgNameMatch || displayNameMatch;
		});
	}, [searchQuery, vendors]);
	

	function formatDate(dateString) {
		const originalDate = new Date(dateString);
		if (isNaN(originalDate)) {
			return dateString;
		}
		return originalDate.toISOString().split('T')[0];
	}

	const colors = useMemo(() => {
        return vendors.map((vendor) => {
            const hex = parseInt(vendor._id.slice(0, 8), 16); // Extract part of the ID and convert to hex
            return '#' + ('000000' + hex.toString(16)).slice(-6); // Ensure a 6-digit hex value
        });
    }, [vendors]);

	function countUserIDOccurrences(rowUserID, sharedWith) {
		const count = sharedWith.reduce((accumulator, userID) => {
			if (userID === rowUserID) {
				return accumulator + 1;
			}
			return accumulator;
		}, 0);

		return count;
	}

	const copyVendorSignUpURL = async () => {
		try {
			await navigator.clipboard.writeText(vendorSignupURL);
			setIsVendorSignUpURLCopied(true);
			setTimeout(() => {
				setIsVendorSignUpURLCopied(false);
			}, 1000);
		} catch (err) {
			console.error('Unable to copy text', err);
		}
	};
console.log(filteredVendors
	)
	return (
		<MainSideMenuHeaderContainer>
			<MuiThemeProviderComponent>
				<section>
					<Heading1 title={'Vendors'} />
					<div className='flex items-center justify-between'>
						<TextField
							label='Search Vendors'
							placeholder='Name of the Vendor'
							sx={{
								width: '55%',
								minWidth: '200px',
								marginTop: '20px',
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Search />
									</InputAdornment>
								),
							}}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<PrimaryButton
							title={
								<span className='w-32 flex items-center justify-center'>
									{isVendorSignUpURLCopied ? (
										<>
											<span className='mr-2'>Copied</span>
											<CheckCircle fontSize='inherit' />
										</>
									) : (
										<>
											<span className='mr-2'>
												Invite Vendor
											</span>
											<ShareIcon fontSize='inherit' />
										</>
									)}
								</span>
							}
							onClick={copyVendorSignUpURL}
						/>
					</div>

					<Table
						sx={{
							marginTop: '20px',
						}}
					>
						<TableHead>
							<TableRow>
								{tableHeader.map((item) => (
									<TableCell
										align='center'
										key={item.id}
										sx={{
											fontSize: '14px',
											fontStyle: 'normal',
											fontWeight: 800,
											lineHeight: '24px',
											letterSpacing: '0.17px',
										}}
									>
										{item.name}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredVendors.map((row, index) => {
								return (
									<TableRow key={row._id}>
										<TableCell
											align='center'
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 2,
											}}
										>
											<Avatar
												alt={row.displayName}
												style={{
													backgroundColor:
														colors[index],
													width: 24,
													height: 24,
												}}
											/>
											{row.displayName}
										</TableCell>
										<TableCell
											padding='none'
											align='center'
										>
											<TextField
												type='number'
												variant='standard'
												onChange={(e) =>
													updateVendorSplitPercentage(
														e.target.value,
														row._id,
														index
													)
												}
												InputProps={{
													style: {
														textAlign: 'right',
													},
													disableUnderline: true,
												}}
												value={
													row.vendorSplitPercentage
												}
											/>
										</TableCell>
										<TableCell align='center'>
											{formatDate(row.dateCreated)}
										</TableCell>
										<TableCell align='center'>
											{countUserIDOccurrences(
												row.userID,
												sharedWith
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</section>
			</MuiThemeProviderComponent>
		</MainSideMenuHeaderContainer>
	);
}
