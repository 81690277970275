import React, { useEffect, useState } from "react";
import { getAuth } from '@firebase/auth';
import SortedClothingLabelsPage from '../shopifyClothings.js'
import ClothingMasterTable from "../ClothingMasterTable.js";
import {  onAuthStateChanged } from '@firebase/auth';
import { useParams } from "react-router";
import storeData from "../../../../products/bulk/shopify/utils/storeData.js";

export function MasterTableHandler({history}) {
	const auth = getAuth();
	const user = auth.currentUser;

	const [proceed, setProceed] = useState(false);
	const [userID, setUserID] = useState();
	const { id, template } = useParams();	

	const [objectID, setObjectID] = useState('');

	useEffect(() => {
		let userUID = '';
		auth.onAuthStateChanged((user) => {
			const token = localStorage.getItem('token');
			if (!token || !user) {
				history.push('/');
			} else {
				userUID = user.uid;
				setUserID(user.uid);
				setProceed(true);
			}
		});
		const brandMT = [];
		const colorMT = [];
		const skuMT = [];
		const itemNameMT = [];
		const conditionMT = [];
		const conditionNotesMT = [];
		const costMT = [];
		const priceMT = [];
		const sizeMT = [];
		let emptyArray;

		const sortedData = async (forms) => {
			let variable = forms.map((d) => d._id);
			await Promise.all(
				variable.map(async (v) =>
					fetch(
						`https://apie.snapwrite.ca/storeData/apparel-form-categorization/${v}`
					)
						.then((response) => response.json())
						.then((json) => {
							for (let i = 0; i < json.data.proceed.length; i++) {
								if (
									json.data.proceed[i] &&
									json.data.itemName[i] &&
									json.data.itemName[i] !== ''
								) {									
									brandMT.push(json.data.brand[i]);
									colorMT.push(
										json.data.color
											? json.data.color[i]
											: ''
									);
									skuMT.push(
										json.data.sku ? json.data.sku[i] : ''
									);

									itemNameMT.push(json.data.itemName[i]);
									conditionMT.push(json.data.condition[i]);
									conditionNotesMT.push(
										json.data.conditionNotes[i]
									);
									costMT.push(json.data.cost[i]);
									priceMT.push(json.data.price[i]);
									sizeMT.push(json.data.size[i]);
								}
							}
						})
				)
			);			
			emptyArray = Array(brandMT.length).fill('');

			const currentTemplate =  template === "preLovedKids" ? "": template;
			storeData(
				emptyArray, // concerns
				currentTemplate,
				userUID,
				'',
				emptyArray,
				emptyArray,
				Array(brandMT.length).fill([]),
				emptyArray,
				emptyArray,
				emptyArray,
				itemNameMT,
				brandMT,
				skuMT,
				emptyArray,
				sizeMT,
				emptyArray,
				costMT,
				priceMT,
				emptyArray,
				conditionMT,
				conditionNotesMT,
				emptyArray,
				emptyArray,
				colorMT,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				emptyArray,
				setObjectID,
				true // isFormGenerated is true // isFormGenerated: means the project is coming from the forms
			);
		};
		
		fetch(
			`https://apie.snapwrite.ca/storeData/apparel-form-categorization/${id}`
		) // id is here from the params
			.then((response) => response.json())
			.then((json) => {
				sortedData([json.data]);				 
			})
			.catch((err) => {
				console.log(`error ${err}`);
			});
	}, []);
	const navigateToTemplate = () => {
		if (objectID === '') {
			return <h1>Loading...</h1>;
		}

		
	const path = `/clothing-master-table/${template}/${objectID}`;


		history.push(path);
	};

	return (
		<>
			{proceed && navigateToTemplate()}
		</>
	);
}
export default MasterTableHandler;
