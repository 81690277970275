import React, { useContext, useEffect, useState } from 'react';
import MainSideMenuHeaderContainer from '../../../../../components/containers/MainSideMenuHeaderContainer';
import {
	Heading1,
	PrimaryButton,
} from '../../../../../components/branded-elements/Elements';
import {
	Autocomplete,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import { Download } from '@mui/icons-material';
import { Button } from 'reactstrap';

import { saveAs } from "file-saver";
import { OrganizationContext } from '../../../../../components/App';


function MetadataMM() {
	const { organization, setOrganization } = useContext(OrganizationContext);
	const seasonOptions = ['P4', 'H3'];
	const categoryOptions = ['Coats', 'Sportswear'];
	const production = 'https://apie.snapwrite.ca/storeData';
	const localhost = 'http://localhost:3500/storeData';
	const baseURL = production;

	const generateOptions = ['Product Recommendation', 'Meta Titles', 'Both'];
	const [metadataFilter, setMetadataFilter] = useState({
		season: '',
		category: '',
		generate: '',
	});

	const [openProgressDialog, setOpenProgressDialog] = useState(false);
	const [generatedMetadata, setGeneratedMetadata] = useState([]);

	useEffect(() => {
		const response = axios
			.get(`${baseURL}/mm-metadata/pDDieoXBhjgkhiJnILJPw4mpb7v1`)
			.then((res) => setGeneratedMetadata(res.data))
			.catch((err) => console.log(err));
	}, []);

	const handleSeasonChange = (event, value) => {
		setMetadataFilter((prevFilter) => ({
			...prevFilter,
			season: value,
		}));
	};

	const handleCategoryChange = (event, value) => {
		setMetadataFilter((prevFilter) => ({
			...prevFilter,
			category: value,
		}));
	};

	const handleGenerateChange = (event, value) => {
		setMetadataFilter((prevFilter) => ({
			...prevFilter,
			generate: value,
		}));
	};

	const generateProductRecommendation = async () => {
		try {
			let endpoint;
			if (metadataFilter.category === 'Coats') {
				endpoint = `${baseURL}/mm-table/product-recommendation`;
			} else {
				endpoint = `${baseURL}/mm-sports/product-recommendation`;
			}

			const response = await axios.post(endpoint, { metadataFilter });

			// Create a blob from the CSV content
			const blob = new Blob([response.data], { type: 'text/csv' });

			// Create a URL for the blob
			const url = window.URL.createObjectURL(blob);

			// Create a temporary link element
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'relatedProducts_SnapWriteAI.csv');

			// Trigger the download
			document.body.appendChild(link);
			link.click();

			// Clean up
			window.URL.revokeObjectURL(url);
			document.body.removeChild(link);
			setOpenProgressDialog(false);
			setMetadataFilter({ season: '', category: '', generate: '' });
		} catch (error) {
			console.log(error);
		}
	};

	const generateMetaTitles = async () => {
		try {			
			let endpoint;
			if (metadataFilter.category === 'Coats') {
				endpoint = `${baseURL}/mm-table/product-meta-titles`;
			} else {
				endpoint = `${baseURL}/mm-sports/product-meta-titles`;
			}
			
			const response = await axios.post(endpoint, { metadataFilter });
			
			const utf8Encoder = new TextEncoder(); // Create a UTF-8 text encoder
			const utf8BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);

			const csvUint8Array = utf8Encoder.encode(response.data);
			const csvUint8ArrayWithBOM = new Uint8Array([...utf8BOM, ...csvUint8Array]);
			// Create a blob from the CSV content
			const blob = new Blob([csvUint8ArrayWithBOM], { type: 'text/csv;charset=utf-8;' });
			saveAs(
				blob,
				`metaTitles_SnapWriteAI.csv`
			  );				
			setOpenProgressDialog(false);
			setMetadataFilter({ season: '', category: '', generate: '' });
		} catch (error) {
			console.log(error);
		}
	};
	const generateData = async () => {
		if (
			metadataFilter.category &&
			metadataFilter.generate &&
			metadataFilter.season
		) {
			setOpenProgressDialog(true);
		}

		if (metadataFilter.generate === 'Product Recommendation') {
			await generateProductRecommendation();
		} else if (metadataFilter.generate === 'Meta Titles') {
			await generateMetaTitles();
		} else if (metadataFilter.generate === 'Both') {
			await generateProductRecommendation();
			await generateMetaTitles();
		}

		setOpenProgressDialog(false);
	};

	const progressDialogue = () => {
		return (
			<Dialog open={openProgressDialog}>
				<DialogTitle
					sx={{
						textAlign: 'center',
					}}
				>
					Generating {metadataFilter.generate} for{' '}
					{metadataFilter.season} {metadataFilter.category}
				</DialogTitle>
				<div className='w-96 h-40 flex justify-center flex-col items-center gap-5'>
					<p className='text-slate-600  text-center'>
						Please do not refresh or close this page.
					</p>
					<CircularProgress />
				</div>
			</Dialog>
		);
	};

	const generateAndUpload = async () => {
		setOpenProgressDialog(true)
		try {
			if (metadataFilter.category === "Sportswear") {
				if (metadataFilter.generate === "Meta Titles") {
					await axios.put('https://apie.snapwrite.ca/storeData/mm-sports/update-product-meta-titles-on-magento', {metadataFilter, userID: organization?.id})
				}
				else if (metadataFilter.generate === "Product Recommendation") {
					await axios.put('https://apie.snapwrite.ca/storeData/mm-sports/update-product-recommendation-on-magento', {metadataFilter, userID: organization?.id})
				}
			} else if (metadataFilter.category === "Coats") {
				if (metadataFilter.generate === "Meta Titles") {
					await axios.put('https://apie.snapwrite.ca/storeData/mm-table/update-product-meta-titles-on-magento', {metadataFilter, userID: organization?.id})
				}
				else if (metadataFilter.generate === "Product Recommendation") {
					await axios.put('https://apie.snapwrite.ca/storeData/mm-table/update-product-recommendation-on-magento', {metadataFilter, userID: organization?.id})
				}
			}
			setOpenProgressDialog(false)

		} catch (error) {
			alert(error.message)
			setOpenProgressDialog(false)
		}
		
		
	}

	function formatDateToDDMMYYYY(isoDate) {
		const normalDate = new Date(isoDate);

		const day = normalDate.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
		const month = (normalDate.getMonth() + 1).toString().padStart(2, '0'); // Get month (+1 because months are zero-indexed) and pad with leading zero if necessary
		const year = normalDate.getFullYear(); // Get full year

		const hours = normalDate.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if necessary
		const minutes = normalDate.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if necessary
		const seconds = normalDate.getSeconds().toString().padStart(2, '0'); // Get seconds and pad with leading zero if necessary

		return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
	}

	const downloadGeneratedCSV = (csvString, csvFileTitle) => {		
		
		const utf8Encoder = new TextEncoder(); // Create a UTF-8 text encoder
		const utf8BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
		
		// Convert the CSV string to a Uint8Array using UTF-8 encoding
		const csvUint8Array = utf8Encoder.encode(csvString);
		const csvUint8ArrayWithBOM = new Uint8Array([...utf8BOM, ...csvUint8Array]);


		// Create a Blob object from the CSV string
		const blob = new Blob([csvUint8ArrayWithBOM], { type: 'text/csv;charset=utf-8;' });
		saveAs(
			blob,
			`${csvFileTitle}_SnapWriteAI.csv`
		  );
	};

	return (
		<MainSideMenuHeaderContainer>
			<Heading1 title={'Metadata'} />
			<div className='flex justify-between'>
				{generatedMetadata?.length > 0 && (
					<Table
						sx={{
							width: '70%',
							marginRight: '50px',
							overflow: 'scroll',
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell>Title</TableCell>
								<TableCell>Date & Time</TableCell>
								<TableCell>Download</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{generatedMetadata
								.sort(
									(a, b) =>
										new Date(b.createdAt) -
										new Date(a.createdAt)
								)
								.map((metadata, index) => (
									<TableRow
										key={`${index}-${metadata.title}`}
									>
										<TableCell>{metadata.title}</TableCell>
										<TableCell>
											{formatDateToDDMMYYYY(
												metadata.createdAt
											)}
										</TableCell>
										<TableCell>
											<span className='cursor-pointer'>
												<Download
													onClick={() =>
														downloadGeneratedCSV(
															metadata.csvString,
															`${
																metadata.title
															}-${formatDateToDDMMYYYY(
																metadata.createdAt
															)}`
														)
													}
												/>
											</span>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				)}

				<div className='grid gap-5 mr-14 h-32'>
					<h5>Generate</h5>
					<Autocomplete
						value={metadataFilter.season}
						options={seasonOptions}
						sx={{ width: 300 }}
						renderInput={(params) => (
							<TextField {...params} label='Season' />
						)}
						onChange={handleSeasonChange}
					/>
					<Autocomplete
						value={metadataFilter.category}
						options={categoryOptions}
						sx={{ width: 300 }}
						disabled={!metadataFilter.season?.length}
						renderInput={(params) => (
							<TextField {...params} label='Category' />
						)}
						onChange={handleCategoryChange}
					/>
					<Autocomplete
						value={metadataFilter.generate}
						options={generateOptions}
						sx={{ width: 300 }}
						disabled={
							!(
								metadataFilter.season?.length &&
								metadataFilter.category?.length
							)
						}
						renderInput={(params) => (
							<TextField {...params} label='Generate Option' />
						)}
						onChange={handleGenerateChange}
					/>
					<PrimaryButton
						disabled={
							!(
								metadataFilter.category?.length &&
								metadataFilter.generate?.length &&
								metadataFilter.season?.length
							)
						}
						title={'Generate'}
						className='w-32 mt-4'
						onClick={generateData}
					/>
					<PrimaryButton
						disabled={
							!(
								metadataFilter.category?.length &&
								metadataFilter.generate?.length &&
								metadataFilter.season?.length &&
								metadataFilter.season !== "H3" &&
								openProgressDialog !== true
							
							)
						}
						title={'Generate and Upload to Magento'}
						className='w-32 mt-4'
						onClick={generateAndUpload}
						style={{width:"300px"}}
					/>
				</div>
				{progressDialogue()}				
			</div>
		</MainSideMenuHeaderContainer>
	);
}

export default MetadataMM;
