const closure = [
    "Closed",
    "Closing",
    "Sealed",
    "Sealing",
    "fastening",
    "shutting",
    "securing",
    "secured",
    "enclosing",
    "closure",
    "secure",
    "Enclosed",
    "Join",
    "Fastened",
    "Seal",
    "closure"
  ];
  
  const no_closure = [
    "Open",
    "Unsealed",
    "Unenclosed",
    "Unfastened",
    "Unclosed",
    "Opened",
    "Exposed"
  ];

  export const closurePresence = [closure, no_closure]