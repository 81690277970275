import React, { useState } from "react";
import axios from "axios";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

  export const ImageUploadCode =(props)=>{

  // const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  
  const tags = props.tags;
  const setTags = props.setTags;
  const setImage = props.setImage;
  const image = props.image;
  const suggestedTags = props.suggestedTags;
  const setSuggestedTags = props.setSuggestedTags;  
  const setDescriptionData = props.setDescriptionData;  
  const unlimitedUsers = ["NpGB6zItW1OcpizZJbD88VsfKlf2", "1BILFjc4JNM0LDmmOUfdr2rRfNH3"]
  async function getSuggestions(imageSource) {
    if((props.noOfImagesUsed > 1000 || props.noOfImagesUsed === 1000) && !unlimitedUsers.includes(props.userID)){
    setSuggestedTags(["image limit exceeded"])
    }
    else if(props.noOfWordsGenerated>250000 && !unlimitedUsers.includes(props.userID)){    
     setSuggestedTags(["word limit exceeded"])     
    }
    else{
      setSuggestedTags(["Loading..."])
      try {
        const res = await backendClient.post("/suggestion", {
          imgSrc: imageSource
        });      
        const unique = (value, index, self) => {
          return self.indexOf(value) === index
        }
        let features = res.data.replace(/\n/g, ',').split(',').filter(unique)
        features = features.filter((feature)=> /[A-Za-z0-9\s]/.test(feature))
        features = features.filter(feature=> feature!=='MRP')
        features = features.filter(feature=> feature!=='of')
        features = features.filter(feature=> feature!=='all')
        features = features.filter(feature=> feature!=='taxes')
        features = features.filter(feature=> feature!=='of all taxes')
  
        features = features.slice(0,18);
  
        setSuggestedTags(features)
        // console.log(features)
      } catch (e) {
        console.log("Please try using a different image");
        console.error(e);
      }
    }
  }

  const addTags = (tag) => {      
    if(props.setDescriptionData!==undefined){
      let tagsArray = tags;
      tagsArray.append(tag);
      setDescriptionData({...props.descriptionData, tagsArray});
    }
    else{
      setTags([...tags, tag]);
    }
      props.selectedTags([...tags, tag]);
    
  };
  
  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        // setIsUploaded(true);
        let imageSource1 = (e.target.result).split(',');
        
        getSuggestions(imageSource1[1])
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }
  return(
    <>
    <div className="dropPlatform">    
    {image==="" || image=== null? (
        <>
      <label htmlFor="myFile" className="dpc">
        <div className="drop-zone" >
        <span className="drop-zone__prompt">                                        
          Click to upload your image
        </span>                  
      </div></label>
      <input type="file" name="myFile"id="myFile" className="drop-zone__input"
      accept="image/*"  onChange={handleImageChange}/>
      </>
      ):(
        
          <img
          id="uploaded-image"
          src={image}
          draggable={false}
          alt={suggestedTags}
          onClick={() => {
            // setIsUploaded(false);
            // setImage(null);
            // setSuggestedTags([""])
          }}
        />
        
      )}
      
    </div>
    <div className="suggestions">

    <div className="tag-suggestions">
        <ul  className="suggestionTags">
          {suggestedTags[0] === "" ? 
          (<span className="TagSuggestionsLoading">Tag suggestions will appear here</span>):
          suggestedTags[0] === "Loading..." ? (<span className="TagSuggestionsLoading">Loading...</span>):
          suggestedTags[0] === "image limit exceeded" ? (<span className="TagSuggestionsLoading">Please Contact Us at contact@snapwrite.ca to increase image limit</span>):
          suggestedTags[0] === "word limit exceeded" ? (<span className="TagSuggestionsLoading">Please Contact Us at contact@snapwrite.ca to increase limit</span>):
          
          (suggestedTags.map((tag, index) => 
            tag!=="" ? 
          (
            <li key={index} className="suggestion" onClick={()=> addTags(tag)}>
              <span className="tag-title">{tag}</span>
            </li>
          ):
          (<></>)
          ))}
        </ul>      
      </div>

    </div>
    </>
  )
}
export default ImageUploadCode;

  /* ************************************************************* */