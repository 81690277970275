function splitArray(ImageURL) {
		
    const result = [];				
    
    let copyImageURL = ImageURL
            
    let breakPoint = 0
    
    while (copyImageURL.length > 0) {			
        console.log("copyImageURL length: ", copyImageURL.length)
        // console.log("copyImageURL: ", copyImageURL)
        if (copyImageURL.length > 5) { 
            breakPoint=6								
            result.push([copyImageURL.slice(0, breakPoint)])
            copyImageURL = copyImageURL.slice(breakPoint)				
        } else {
            result.push([copyImageURL])
            copyImageURL = []
        }

        // In the future make this work for spliting effectively for atleast 4 images are sent in a request
        // else if (copyImageURL.length === 5 || copyImageURL.length === 4){
        // 	result.push([copyImageURL])
        // 	copyImageURL = []
        // } else {
        // 	result[result.length-1][0].push(...copyImageURL)
        // 	copyImageURL = []				
        // }
    }
    console.log("divided array: ", result)
    return result;
}


export default splitArray