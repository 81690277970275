const minimalist = [
    "Simple",
    "Clean",
    "Understated",
    "Uncluttered",
    "Basic",
    "Sleek",
    "Subdued",
    "Essential",
    "Modest",
    "minimalist"
  ];
  
  const techwear = [
    "Futuristic",
    "Cyber",
    "Functional",
    "Techno",
    "Tech",
    "High-tech",
    "Progressive",
    "techwear"
  ];
  
  const streetwear = [
    "Urban",
    "City",
    "Street",
    "Urban",
    "Hip-hop",
    "Street",
    "streetwear"
  ];
  
  const athleisure = [
    "Sporty",
    "Athletic",
    "Activewear",
    "Workout",
    "Gym",
    "Sportswear",
    "Fitness",
    "Sporty",
    "Active",
    "athleisure"
  ];
  
  const chic = [
    "Elegant",
    "Stylish",
    "Sophisticated",
    "Fashionable",
    "Trendy",
    "Classy",
    "Polished",
    "Sleek",
    "Refined",
    "chic"
  ];
  
  const preppy = [
    "Classic",
    "Collegiate",
    "Traditional",
    "Ivy",
    "Neat",
    "Smart",
    "Refined",
    "Academic",
    "School",
    "preppy"
  ];
  
  const grunge = [
    "Edgy",
    "Alternative",
    "Rebellious",
    "Grungy",
    "Distressed",
    "Punk",
    "Rough",
    "Nonconformist",
    "grunge"
  ];
  
  const vintage_retro = [
    "Classic",
    "Old-fashion",
    "Old Fashion",
    "Nostalgic",
    "Antique",
    "Timeless",
    "Retro",
    "Vintage",
    "Throwback",
    "Old-school",
    "Old School",
  ];
  
  const casual = [
    "Relaxed",
    "Informal",
    "Laid-back",
    "Easygoing",
    "Everyday",
    "Comfortable",
    "Unpretentious",
    "Effortless",
    "Low-key",
    "Low key"
  ];
  
  const business = [
    "Professional",
    "Corporate",
    "Formal",
    "Business",
    "Work",
    "Corporate",
    "Dressy"
  ];
  
  const sportswear = [
    "Athletic",
    "Workout",
    "Active",
    "Sports",
    "Exercise",
    "Fitness",
    "Gym",
    "Sporty",
    "Performance"
  ];

  const synonymsArray = [minimalist, techwear, streetwear, athleisure, chic, preppy, 
                        grunge, vintage_retro, casual, business, sportswear]

export const getDressingStyleSynonyms = (dressingStyle) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => dressingStyle.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
return toReturn;
}