import TagSize from "./sizeTagger";
import axios from "axios";
import {
  getTypeSynonyms
} from './synonyms/synonyms.js';

import { getNecklineSynonyms } from "./synonyms/necklineSynonyms";
import { getDesignSynonyms } from "./synonyms/designSynonyms";
import { getPatternSynonyms } from "./synonyms/patternSynonyms";
import stateArrayDBassignment from "./stateDBassignment";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

// const dummyUserTitlePreference = {
//   userID: "dummy",
//   title: [
//     {
//       itemType: "variable", 
//       value: "era"
//     },
//     {
//       itemType: "manual",
//       value: "//"
//     },
//     {
//       itemType: "variable",
//       value: "brand"
//     },
//     {
//       itemType: "variable",
//       value: "itemName"
//     },
//     {
//       itemType: "manual",
//       value: "-"
//     },
//     {
//       itemType: "variable",
//       value: "size"
//     },
//   ]
// }

const createcsv = async (template, userID, objectID, isImport=false, formattedThemeData) => {

  let returnVal = []
    // Regular
    let s3Images = []
    let itemName = []
    let size = []
    let sizeNotes = []
    let brand = []
    let sku = []
    let types = []
    let taxonomyTypes = []
    let colors = []
    let condition = []
    let price = []
    let genders = []
    let imageFileName = []
    let costPerItem = []
    let genericType = []
    let itemDescription = []
    let vendorP = []
    let retailPrice = []
    let conditionDescription = []
    let supplementaryImages = []

    let additionalInfos = []
    let itemWeight = []

    // Vintage Specific
    let era = []
    let madeIn = []
    
    // Measurements
    let sleeveM = []
    let lengthM = []
    let pitToPitM = []
    let shoulderM = []
    let waistM = []
    let hipM = []
    let bottomInSeam = []
    let riseMeasure = []
    let thighMeasure = []
    let legOpening = []

    // Footwear
    let heelHeightMeasure = []
    let soleMeasure = []
    let inSoleMeasure = []
    let shoeWidth = []

    // Properties

    let material = []
    let sleeves = []
    let style = []
    let closure = []
    let fastening_type = []
    let neckline= []
    let cut = []
    let hood = []
    let belt = []
    let design = []
    let lining = []
    let layers = []
    let pattern = []
    let skirt = []
    let rise = []
    let heelHeight = []
    let heelShape = []
    let height = []
    let toe = []
    let theme = []
    let projectName = []

    const titleData = await axios.get(`https://apie.snapwrite.ca/storeData/csv-title/${userID}`)
    .catch(err => console.error(err))
    console.log(titleData)
		
		const titleBlocks = titleData.data.data ? titleData.data.data.titleBlocks : undefined
		const descriptionBlocks = titleData.data.data ? titleData.data.data.descriptionBlocks : undefined



    let link = `https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`;
    if (template === "vintage") {
      link = `https://apie.snapwrite.ca/storeData/vintage-apparel`;
    } else if (template === "new-clothes") {
      link = `https://apie.snapwrite.ca/storeData/new-apparel`;
    } else if (template === "pre-loved-regular") {
      link = `https://apie.snapwrite.ca/storeData/regular-preLoved-apparel`;
    }else if(template === "themes"){
      link = `https://apie.snapwrite.ca/storeData/get-themes-project-items`
    }
    let json;
      if(objectID && objectID!=="") {
        
        try{    
        if (template !== "themes") {
          //Fetch Data
          json = await fetch(`${link}/${objectID}`).then((response) =>
            response.json()
          );
        } else{
          const response = await axios.get(`${link}/${objectID}`).then((res) => {
            json = {data: formattedThemeData(res.data)}
          })
        }
        
          //  CSV Gen Starts here
          if (json.data.theme) {
				theme = json.data.theme;
			} else {
				theme = Array(json.data.images.length).fill('');
			}

            s3Images = json.data.images;    
            if (json.data.itemName) {
              itemName = json.data.itemName;
            } else {
              itemName = Array(json.data.images.length).fill("");
            }
            if (json.data.size) {
              size = json.data.size;
            } else {
              size = Array(json.data.images.length).fill("");
            }
            if (json.data.sizeNotes) {
              sizeNotes = json.data.sizeNotes;
            } else {
              sizeNotes = Array(json.data.images.length).fill("");
            }
            if (json.data.brand) {
              brand = json.data.brand;
            } else {
              brand = Array(json.data.images.length).fill("");
            }
            if(json.data.sku) {
              sku = json.data.sku;
            } else {
              sku = Array(json.data.images.length).fill("");
            }
            if (json.data.types) {
              types = json.data.types;
            } else {
              types = Array(json.data.images.length).fill("");
            }
            if (json.data.taxonomyUpdatedType) {
              taxonomyTypes = json.data.taxonomyUpdatedType;
            } else {
              taxonomyTypes = Array(json.data.images.length).fill("");
            }
            if (json.data.colors) {
              colors = json.data.colors;
            } else {
              colors = Array(json.data.images.length).fill("");
            }
            if (json.data.condition) {
              condition = json.data.condition;
            } else {
              condition = Array(json.data.images.length).fill("");
            }
            if (json.data.price) {
              price = json.data.price;
            } else {
              price = Array(json.data.images.length).fill("");
            }
            if (json.data.gender) {
              genders = json.data.gender;
            } else {
              genders = Array(json.data.images.length).fill("");
            }
            if (json.data.fileNames) {
              imageFileName = json.data.fileNames;
            } else {
              imageFileName = Array(json.data.images.length).fill("");
            }
            if (json.data.cost) {
              costPerItem = json.data.cost;
            } else {
              costPerItem = Array(json.data.images.length).fill("");
            }
            if (json.data.itemGenericType) {
              genericType = json.data.itemGenericType;
            } else {
              genericType = Array(json.data.images.length).fill("");
            }
            if (json.data.description) {
              itemDescription = json.data.description;
            } else {
              itemDescription = Array(json.data.images.length).fill("");
            }        
            if(json.data.vendor) {
              vendorP = json.data.vendor;
            } else {
              vendorP = Array(json.data.images.length).fill("");
            }
            if (json.data.retailPrice) {
              retailPrice = json.data.retailPrice;
            } else {
              retailPrice = Array(json.data.images.length).fill("");
            }
            if (json.data.conditionDescription) {
              conditionDescription = json.data.conditionDescription;
            } else {
              conditionDescription = Array(json.data.images.length).fill("");
            }
            if (
              json.data.supplementaryImages &&
              json.data.supplementaryImages.length > 0
            ) {
              supplementaryImages = json.data.supplementaryImages;
            } else {
              supplementaryImages = Array(json.data.images.length).fill([]);
            }
            
    
            if (json.data.additionalInfo) {
              additionalInfos = json.data.additionalInfo;
            } else {
              additionalInfos = Array(json.data.images.length).fill("");
            }
            if (json.data.variantGrams) {
              itemWeight = json.data.variantGrams;
            } else {
              itemWeight = Array(json.data.images.length).fill("");
            }
    
            // Vintage
    
            if (json.data.era && json.data.era.length === json.data.images.length) {
              era = json.data.era;
            } else {
              era = Array(json.data.images.length).fill("");
            }
            if (
              json.data.madeIn &&
              json.data.madeIn.length === json.data.images.length
            ) {
              madeIn = json.data.madeIn;
            } else {
              madeIn = Array(json.data.images.length).fill("");
            }       
          
            // Measurements
            
            if (
              json.data.sleeveInSeam &&
              json.data.sleeveInSeam.length === json.data.images.length
            ) {
              sleeveM = json.data.sleeveInSeam;
            } else {
              sleeveM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.lengthMeasure &&
              json.data.lengthMeasure.length === json.data.images.length
            ) {
              lengthM = json.data.lengthMeasure;
            } else {
              lengthM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.pitToPitMeasure &&
              json.data.pitToPitMeasure.length === json.data.images.length
            ) {
              pitToPitM = json.data.pitToPitMeasure;
            } else {
              pitToPitM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.shoulderMeasure &&
              json.data.shoulderMeasure.length === json.data.images.length
            ) {
              shoulderM = json.data.shoulderMeasure;
            } else {
              shoulderM = Array(json.data.images.length).fill("");
            }
    
            if (
              json.data.waistMeasure &&
              json.data.waistMeasure.length === json.data.images.length
            ) {
              waistM = json.data.waistMeasure;
            } else {
              waistM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.hipMeasure &&
              json.data.hipMeasure.length === json.data.images.length
            ) {
              hipM = json.data.hipMeasure;
            } else {
              hipM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.bottomInSeam &&
              json.data.bottomInSeam.length === json.data.images.length
            ) {
              bottomInSeam = json.data.bottomInSeam;
            } else {
              bottomInSeam = Array(json.data.images.length).fill("");
            }
            if (
              json.data.riseMeasure &&
              json.data.riseMeasure.length === json.data.images.length
            ) {
              riseMeasure = json.data.riseMeasure;
            } else {
              riseMeasure = Array(json.data.images.length).fill("");
            }
            if (
              json.data.thighMeasure &&
              json.data.thighMeasure.length === json.data.images.length
            ) {
              thighMeasure = json.data.thighMeasure;
            } else {
              thighMeasure = Array(json.data.images.length).fill("");
            }
            if (
              json.data.legOpening &&
              json.data.legOpening.length === json.data.images.length
            ) {
              legOpening = json.data.legOpening;
            } else {
              legOpening = Array(json.data.images.length).fill("");
            }       
            
            //Footwear
            heelHeightMeasure = stateArrayDBassignment(json.data.heelHeightMeasure, json.data.images.length)            
            soleMeasure = stateArrayDBassignment(json.data.soleMeasure, json.data.images.length)
            inSoleMeasure = stateArrayDBassignment(json.data.inSoleMeasure, json.data.images.length)
            shoeWidth = stateArrayDBassignment(json.data.shoeWidth, json.data.images.length)                                     

            // console.log(heelHeightMeasure)
            // console.log(soleMeasure)
            // console.log(inSoleMeasure)
            // console.log(shoeWidth)

            // Attributes
            if (json.data.material) {
              material = json.data.material;
            } else {
              material = Array(json.data.images.length).fill("");
            }
            if (json.data.sleeves) {
              sleeves = json.data.sleeves;
            } else {
              sleeves = Array(json.data.images.length).fill("");
            }
          
            if (json.data.style) {
              style = json.data.style;
            } else {
              style = Array(json.data.images.length).fill("");
            }
            
            if (json.data.closure) {
              closure = json.data.closure;
            } else {
              closure = Array(json.data.images.length).fill("");
            }
            if (json.data.fastening_type) {
              fastening_type = json.data.fastening_type;
            } else {
              fastening_type = Array(json.data.images.length).fill("");
            }
            if (json.data.neckline) {
              neckline = json.data.neckline;
            } else {
              neckline = Array(json.data.images.length).fill("");
            }
            if (json.data.cut) {
              cut = json.data.cut;
            } else {
              cut = Array(json.data.images.length).fill("");
            }
            if (json.data.design) {
              design = json.data.design;
            } else {
              design = Array(json.data.images.length).fill("");
            }
            if (json.data.hood) {
              hood = json.data.hood;
            } else {
              hood = Array(json.data.images.length).fill("");
            }
            
            if (json.data.belt) {
              belt = json.data.belt;
            } else {
              belt = Array(json.data.images.length).fill("");
            }
            if (json.data.lining) {
              lining = json.data.lining;
            } else {
              lining = Array(json.data.images.length).fill("");
            }
            if (json.data.layers) {
              layers = json.data.layers
            } else {
              layers = Array(json.data.images.length).fill("");
            }
            if (json.data.pattern) {
              pattern = json.data.pattern;
            } else {
              pattern = Array(json.data.images.length).fill("");
            }
            if (json.data.skirt) {
              skirt = json.data.skirt;
            } else {
              skirt = Array(json.data.images.length).fill("");
            }
            if (json.data.rise) {
              rise = json.data.rise;
            } else {
              rise = Array(json.data.images.length).fill("");
            }
            if (json.data.heelHeight) {
              heelHeight = json.data.heelHeight;
            } else {
              heelHeight = Array(json.data.images.length).fill("");
            }
            if (json.data.heelShape) {
              heelShape = json.data.heelShape;
            } else {
              heelShape = Array(json.data.images.length).fill("");
            }
            if (json.data.height) {
              height = json.data.height;
            } else {
              height = Array(json.data.images.length).fill("");
            }
            if (json.data.toe) {
              toe = json.data.toe;
            } else {
              toe = Array(json.data.images.length).fill("");
            }
    
            // if (json.data.backImage) {
            //   setBackImage(json.data.backImage);
            // } else {
            //   setBackImage(Array(json.data.images.length).fill(""));
            // }
            
            if (json.data.projectName) {
              projectName = json.data.projectName
            } else {
              projectName = "";
            }
    
            // if (json.data.length) {
            //   setLength(json.data.length);
            // } else {
            //   setLength(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.typeObj) {
            //   setTypeObj(json.data.typeObj);
            // } else {
            //   setTypeObj(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.age) {
            //   setAge(json.data.age);
            // } else {
            //   setAge(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.fastening) {
            //   setFastening(json.data.fastening);
            // } else {
            //   setFastening(Array(json.data.images.length).fill(""));
            // }
    
            // if (json.data.tags) {
            //   setTags(json.data.tags);
            // } else {
            //   setTags(json.data.tags);
            // }
    
            
          
          
            if(itemName.length===0 || !itemName) {
              itemName = Array(s3Images.length).fill("")
            }
            if(era.length===0 || !era) {
              era = Array(s3Images.length).fill("")
            }
            if(madeIn.length===0 || !madeIn) {
              madeIn = Array(s3Images.length).fill("")
            }
            if(material.length===0 || !material) {
              material = Array(s3Images.length).fill("")
            }
            //Measurements
            if(waistM.length===0 || !waistM) {
              waistM = Array(s3Images.length).fill("")
            }
            if(sleeveM.length===0 || !sleeveM) {
              sleeveM = Array(s3Images.length).fill("")
            }
            if(pitToPitM.length===0 || !pitToPitM) {
              pitToPitM = Array(s3Images.length).fill("")
            }
            if(hipM.length===0 || !hipM) {
              hipM = Array(s3Images.length).fill("")
            }
            if(lengthM.length===0 || !lengthM) {
              lengthM = Array(s3Images.length).fill("")
            }
            if(shoulderM.length===0 || !shoulderM){
              shoulderM = Array(s3Images.length).fill("")      
            }
            if(size.length===0 || !size) {
              size = Array(s3Images.length).fill("")
            }
            if(sizeNotes.length===0 || !sizeNotes) {
              sizeNotes = Array(s3Images.length).fill("")
            }
            if(brand.length===0 || !brand){
              brand = Array(s3Images.length).fill("")
            }
            if(sku.length===0 || !sku){
              sku = Array(s3Images.length).fill("")
            }
            if(types.length===0 || !types){
              types = Array(s3Images.length).fill("")
            }
            if(taxonomyTypes.length===0 || !taxonomyTypes){
              taxonomyTypes = Array(s3Images.length).fill("")
            }
            if(colors.length===0 || !colors){
              colors = Array(s3Images.length).fill("")
            }
            if(condition.length===0 || !condition){
              condition = Array(s3Images.length).fill("")
            }
            if(price.length===0 || !price) {
              price = Array(s3Images.length).fill("")
            }
            if(genders.length===0 || !genders) {
              genders = Array(s3Images.length).fill("")
            }
            if(imageFileName.length === 0 || !imageFileName) {
              imageFileName = Array(s3Images.length).fill("")
            }
            if(additionalInfos.length === 0 || !additionalInfos){
              additionalInfos = Array(s3Images.length).fill("")
            }
            if(costPerItem.length === 0 || !costPerItem){
              costPerItem = Array(s3Images.length).fill("")
            }
            if(itemWeight.length === 0 || !itemName) {
              itemWeight = Array(s3Images.length).fill("")
            }
            if(genericType.length === 0 || !genericType) {
              genericType = Array(s3Images.length).fill("")
            }
            if(itemDescription.length === 0 || !itemDescription){
              itemDescription = Array(s3Images.length).fill("")
            }
            if(vendorP.length === 0 || !vendorP){
              vendorP = Array(s3Images.length).fill("")
            }
            if(retailPrice.length === 0 || !retailPrice){
              retailPrice = Array(s3Images.length).fill("")
            }
            if(conditionDescription.length === 0 || !conditionDescription){
              conditionDescription = Array(s3Images.length).fill("")
            }
            if(supplementaryImages.length === 0 || !supplementaryImages){
              supplementaryImages = Array(s3Images.length).fill([])
            }
            if(sleeves.length===0 || !sleeves) {
              sleeves = Array(s3Images.length).fill([])
            }
            if(style.length === 0 || !style) {
              style = Array(s3Images.length).fill([])
            }
            if(closure.length === 0 || !closure){
              closure = Array(s3Images.length).fill([])
            }
            if(fastening_type.length===0 || !fastening_type){
              fastening_type = Array(s3Images.length).fill([])
            }
            if(neckline.length === 0 || !neckline) {
              neckline = Array(s3Images.length).fill([])
            }
            if(cut.length === 0 || !cut) {
              cut = Array(s3Images.length).fill([])
            }
            if(hood.length === 0 || !hood) {
              hood = Array(s3Images.length).fill([])
            }
            if(belt.length === 0 || !belt) {
              belt = Array(s3Images.length).fill([])
            }
            if(design.length === 0 || !design) {
              design = Array(s3Images.length).fill([])
            }
            if(lining.length === 0 || !lining) {
              lining = Array(s3Images.length).fill([])
            }
            if(layers.length === 0 || !layers) {
              layers = Array(s3Images.length).fill([])
            }
            if(pattern.length === 0 || !pattern) {
              pattern = Array(s3Images.length).fill([])
            }
            if(skirt.length === 0 || !skirt) {
              skirt = Array(s3Images.length).fill([])
            }
            if(rise.length === 0 || !rise) {
              rise = Array(s3Images.length).fill([])
            }
            if(heelHeight.length === 0 || !heelHeight) {
              heelHeight = Array(s3Images.length).fill([])
            }
            if(heelShape.length === 0 || !heelShape) {
              heelShape = Array(s3Images.length).fill([])
            }
            if(height.length === 0 || !height) {
              height = Array(s3Images.length).fill([])
            }
            if(!toe || toe.length === 0) {
              toe = Array(s3Images.length).fill([])
            }

          const getVariantRow = (option1Title, option2Title, option3Title, userID, sku, itemName, handle, colorVariant, imageLink, index, costPerItem, weight, taxable, price) => {            
            let variantRow;
            let currentSku="";
            let currentWeightUnit = ""
            let currentWeight = ""
            let currentInvetoryTracker = ""
            let currentInventoryQuantity = ""
            let currentInventoryPolicy = ""
            let currentFulfillment = ""
            let currentShipping = ""
            let currentPrice = ""
            let currentCost = ""
            let currentTax = ""
            let currentImagePosition = imageLink !== "" ? index+2 : ""
            
            if (colorVariant !== "") {
              currentSku = `${sku}-${colorVariant}`
              currentWeightUnit = "kg"
              currentWeight = weight
              currentInvetoryTracker = "shopify"
              currentInventoryQuantity = 1
              currentInventoryPolicy = "deny"
              currentFulfillment = "manual"
              currentShipping = "TRUE"
              currentPrice = price
              currentCost = costPerItem
              currentTax = taxable
            }            
            //0	shopify	1	deny	manual	0		TRUE	TRUE
            if(option1Title==="Color") {

              variantRow = [
                `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku + '-' + itemName.replace(/\s+/g, '-'): handle}`, //Handle
                ``, ``, ``, ``, ``, ``, "",
                ``,
                `${colorVariant}`,
                ``,``, "", '', `${currentSku}`, currentWeight, `${currentInvetoryTracker}`, currentInventoryQuantity, `${currentInventoryPolicy}`, `${currentFulfillment}`, `${currentPrice}`, "", `${currentShipping}`, `${currentTax}`, "", 
                imageLink,
                currentImagePosition, //Image Position
                ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, `${currentWeightUnit}`, "", `${currentCost}`, ``, "", ``, ``, false
              ]              
            } else if (option2Title === "Color") {                

                variantRow = [
                    `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku + '-' + itemName.replace(/\s+/g, '-'): handle}`, //Handle
                    ``, ``, ``, ``, ``, ``, "", ``, ``,
                    ``, 
                    `${colorVariant}`,
                    "", '', `${currentSku}`, currentWeight, `${currentInvetoryTracker}`, currentInventoryQuantity, `${currentInventoryPolicy}`, `${currentFulfillment}`, `${currentPrice}`, "", `${currentShipping}`, `${currentTax}`, "", 
                    imageLink,
                    currentImagePosition, //Image Position
                    ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, `${currentWeightUnit}`, "", `${currentCost}`, ``, "", ``, ``, false
                  ]
            } else if (option3Title === "Color") {                

                variantRow = [
                  `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku + '-' + itemName.replace(/\s+/g, '-'): handle}`, //Handle
                  ``, ``, ``, ``, ``, ``, "", ``, ``, ``, ``, 
                  "",
                  `${colorVariant}`,
                  `${currentSku}`, currentWeight, `${currentInvetoryTracker}`, currentInventoryQuantity, `${currentInventoryPolicy}`, `${currentFulfillment}`, `${currentPrice}`, "", `${currentShipping}`, `${currentTax}`, "", 
                  imageLink,
                  currentImagePosition, //Image Position
                  ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, `${currentWeightUnit}`, "", `${currentCost}`, ``, "", ``, ``, false
                ]
            }
            return variantRow
          }


          const filteredUserBrands = brand.filter(item => typeof item === "string")
          axios.put(`https://apie.snapwrite.ca/storeData/brands`, {brands: filteredUserBrands})
          let csv = [];
          // let csv = csvRowsTemp;
          let i = 0;
      
          for (i = 0; i < s3Images.length; i++) {
            let lines = ''
            if(itemDescription[i]){
              lines = itemDescription[i].split("\n");
            }
            let result = '';
            for(let im = 0; im < lines.length; im++){
              if(lines[im]!==""){
                let para=lines[im];
                if (lines[im].includes(`"`)) {
                  para = lines[im].replace(
                    /"/g,
                    `'`
                  );
                }
                result += "<p>" + para + "</p>";
              }
            }

            let userDesc = ""
          
            let handle =  `${itemName[i]}_${colors[i]}_${brand[i]}_${types[i]}_${size[i]}_${imageFileName[i]!==undefined && imageFileName[i]!==""?imageFileName[i]: Math.random().toFixed(3)*100}`             
            let tags = `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? "" : "New Arrivals"}, ${brand[i]}, ${types[i]}, ${size[i]},${colors[i]},${additionalInfos[i]}, ${taxonomyTypes[i]}`;
            let collection = ``;
            let title = `${brand[i]} ${itemName[i]}${size[i]? ` (${size[i]})`:''}`;
            let description = `<meta charset='UTF-8'>${itemDescription[i] && itemDescription[i]!== ''? `<p class='p1'><span class='s1'>${`${result!==""?result:itemDescription[i]}`}</span></p>` : '' }${colors[i]!=="" ? `<p class='p1'><span class='s1'>Colour: ${colors[i]}</span></p>` : ""}${size[i]!=="" ? `<p class='p1'><span class='s1'>Size: ${size[i]}</span></p>` : ""}${template!=='new-clothes'?`<p class='p1'><span class='s1'>Condition: ${condition[i] === "" || !condition[i] ? "Excellent Used Condition" : condition[i]}</span>${conditionDescription[i] && conditionDescription[i]!==''? `. <span>${conditionDescription[i]}</span>`:''}</p>`:''} `;
            let option1Title =`Title`
            let option1val = `Default Title`
            let option2Title = ``
            let option2val =``
            let option3Title = ``
            let option3val =``          
            let imageAlt=`${title}`
            let weight = 0
            let weightUnit = ``
            let vendor = `${brand[i]}`
            if(vendorP[i]!==''){
              vendor = vendorP[i]
              title =  `${brand[i]} ${itemName[i]}${size[i]? ` // ${size[i]}`:''}`;
            }
            let customProductType = `${types[i]}`
            let status = `draft`
            let taxable = `TRUE`
            let genderTag = [genders[i]];   
            let isAgeBaby = false;         
            if(genders[i] && genders[i].toLocaleLowerCase().includes('gender neutral')){
              genderTag = ['Boys', 'Girls']
            }
            else if(genders[i] && !genders[i].toLocaleLowerCase().includes('gender neutral')){
              if(/\s/g.test(genders[i])){
                genderTag = genders[i].split(/\s+/) 
              }
            }
            
            if (userID==="CXNDhrE6FfNRdXNm6nUQlmck29f2" || userID==="xHaYXalwv8X8RpBLZju0PaQlWW23"){ //Piece by Piece Kids
              vendor = vendorP[i]
              title = `${brand[i]} ${itemName[i]} (${size[i]})`
              description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>Brand: ${brand[i]}</span></p><p class='p1'><span class='s1'>Size: ${size[i]}</span></p><p class='p1'><span class='s1'>Condition: ${condition[i]}${(condition[i]==="Very Good Used Condition" || condition[i]==="Good Used Condition" || condition[i]==="Play Condition")? `. ${conditionDescription[i]}`:""}</span></p>`
              if(size[i].toLowerCase().includes('m') || size[i].toLowerCase().includes('t')){
                isAgeBaby = true
              }
              else if (size[i].includes("-")){          
                let upperLimit = Number(size[i].split('-')[1])
                if(upperLimit > 10){
                  isAgeBaby = true
                }
              }
              if (itemName[i]!==undefined && itemName[i]!=="") {
                if (itemName[i].toLowerCase().includes("new ") || itemName[i].toLowerCase().includes("new! ")) {
                  tags = `${size[i]}, New, ${brand[i]}, ${types[i]}, ${condition[i].toLowerCase().includes(' new')? 'new' :""}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
                }
                else {
                  if(condition[i].toLowerCase()!=='like new' && (condition[i].toLowerCase().includes(' new') || condition[i].toLowerCase()==='new without tags')) {
                    tags = `${size[i]}, ${brand[i]}, ${types[i]}, new, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
                  }
                  else {
                    tags = `${size[i]}, ${brand[i]}, ${types[i]}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
                  }
                }
              }
              else {
                tags = `${size[i]}, ${brand[i]}, ${types[i]}, ${colors[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
              }        
                      
              if(size[i]) {
                option1Title = `Size`;
                option1val = ` ${size[i]}`
              }   
              collection='5%'           
            }
            else if(userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || userID==='t4gFVUQejYQYBY6dhwOp2mkYMTe2' ||  userID==='WbahZZa5HhfWAa6SyJLYnl31kMG3' || template === 'vintage' || template=== 'pre-loved-regular'){ //Loose Fade
              customProductType = taxonomyTypes[i]
              let sizeTag=[]
              if(size[i].toLowerCase().includes("s")){
                sizeTag.push("small")
              }
              if(size[i].toLowerCase().includes("m")){
                sizeTag.push("medium")
              }
              if(size[i].toLowerCase().includes("l")){
                sizeTag.push("large")
              }
              if(size[i].toLowerCase().includes("xl")){
                sizeTag.push("extra large", "xtra large")
              }
              if(size[i].toLowerCase().includes("xxl")){
                sizeTag.push("extra extra large", "xtra xtra large")
              }
              tags = `New Arrivals, online, ${sizeTag}, ${brand[i]}, ${condition[i]}, ${itemName[i]}, ${era[i]}, vintage, preloved, pre-loved, ${colors[i]}, ${size[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${sleeves[i]}, ${style[i]}, ${closure[i]}, ${fastening_type[i]}, ${neckline[i]}, ${cut[i]}, ${hood[i]}, ${belt[i]}, ${design[i]}, ${lining[i]}, ${layers[i]}, ${pattern[i]}, ${skirt[i]}, ${rise[i]}, ${heelHeight[i]}, ${heelShape[i]}, ${height[i]}, ${toe[i]}, hype, streetwear, ${projectName}`
              title = `${era[i]!==''?`${era[i]} `:""}${brand[i]!==''?`${brand[i]} `:''}${itemName[i]!==''?`${itemName[i]} `:''}`;
              description = `
              ${result}
              ${madeIn[i]!==""?`<p><b>Made In:</b> ${madeIn[i]}</p>`:''}
              ${era[i]?`<p><b>Era:</b> ${era[i]}</p>`:''}
              ${conditionDescription[i]===""?  `<p><b>Condition:</b> ${condition[i]}</p>`:  `<p><b>Condition:</b> ${condition[i]}</p><p>&nbsp;&nbsp;&nbsp;&nbsp;• ${conditionDescription[i]}</p>`}
              <p><b>Color:</b> ${colors[i]}</p>
              <p><b>Size:</b> ${size[i]}${sizeNotes[i]!==""?`. ${sizeNotes[i].replace(/"/g, '""')}`: ""}</p>
              ${waistM[i]!=="" || hipM[i]!=="" || bottomInSeam[i]!=="" || riseMeasure[i]!=="" || thighMeasure[i]!=="" || legOpening[i]!=="" || pitToPitM[i]!=="" || lengthM[i]!=="" || shoulderM[i]!=="" || sleeveM[i]!=="" || heelHeightMeasure[i] !== "" || soleMeasure[i] !== "" || inSoleMeasure[i] !== "" || shoeWidth[i] !== "" ?
              `<p><b>Measurements:</b></p>
              ${waistM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${hipM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${bottomInSeam[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i].replace(/"/g, '""')}</p>
              `: ""}
              ${riseMeasure[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i].replace(/"/g, '""')}</p>
              `: ""}
              ${thighMeasure[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i].replace(/"/g, '""')}</p>
              `: ""}
              ${legOpening[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i].replace(/"/g, '""')}</p>
              `: ""}
              ${pitToPitM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${shoulderM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${sleeveM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i].replace(/"/g, '""')}</p>
              `: ""} 
              ${lengthM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${heelHeightMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Heel Height: ${heelHeightMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
              ${soleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sole: ${soleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
              ${inSoleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Insole: ${inSoleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
              ${shoeWidth[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Width: ${shoeWidth[i].replace(/"/g, '""')}</p>
                `: ""}
              `:''} 
              ${material[i] && material[i]!==''? `
              <p><b>Fabric:</b> ${material[i].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</p>
              `: ""}
              <br />
              <p>
                Sizes for vintage garments may not match the modern sizing convention. Please
                see measurements to ensure a proper fit.
              </p>
              `
              if (userID === '1eMplQPlHHhX9lnwX8NTcKghui22' || userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1') { //Cherry Pick Vintage
                if (
                  brand[i] === "Nike" ||
                  brand[i] === "Harley Davidson" ||
                  brand[i] === "Orange County Choppers" ||
                  brand[i] === "Russell" ||
                  brand[i] === "Bape" ||
                  brand[i] === "Stussy" ||
                  brand[i] === "Carhartt" ||
                  brand[i] === "Diesel" ||
                  brand[i] === "Stone Island" ||
                  brand[i] === "The North Face" ||
                  brand[i] === "Patagonia" ||
                  brand[i] === "Levi"            
                ) {
                    tags = tags + ', Branded'
                }
                title = `${era[i]!==''?`${era[i]} `:""}${brand[i]!==''?`${brand[i]} `:''}${itemName[i]!==''?`${itemName[i]} ${taxonomyTypes[i]}`: `${taxonomyTypes[i]} `} (${size[i]})`;
                description = `
                <p><b>Size:</b> ${size[i]}${sizeNotes[i]!==""?`. ${sizeNotes[i].replace(/"/g, '""')}`: ""}</p>
                ${waistM[i]!=="" || hipM[i]!=="" || bottomInSeam[i]!=="" || riseMeasure[i]!=="" || thighMeasure[i]!=="" || legOpening[i]!=="" || pitToPitM[i]!=="" || lengthM[i]!=="" || shoulderM[i]!=="" || sleeveM[i]!=="" || heelHeightMeasure[i]!=="" || soleMeasure[i] !== "" || inSoleMeasure[i] !== "" || shoeWidth[i] !== ""  ?`<p><b>Measurements:</b></p>`:''}
                ${waistM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${hipM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${bottomInSeam[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i].replace(/"/g, '""')}</p>
                `: ""}
                ${riseMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${thighMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${legOpening[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i].replace(/"/g, '""')}</p>
                `: ""}
                ${pitToPitM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoulderM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${sleeveM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i].replace(/"/g, '""')}</p>
                `: ""} 
                ${lengthM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${heelHeightMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Heel Height: ${heelHeightMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${soleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sole: ${soleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
               ${inSoleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Insole: ${inSoleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoeWidth[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Width: ${shoeWidth[i].replace(/"/g, '""')}</p>
                `: ""}
                `
                weightUnit = `g`
                // console.log(taxonomyTypes)
                if (taxonomyTypes[i]==='T-shirt') {
                  weight = 100
                } else if (taxonomyTypes[i]==='Sweatshirt') {
                  weight = 340
                } else if (taxonomyTypes[i]==='Longsleeve') {
                  weight = 340
                } else if (taxonomyTypes[i]==='Outerwear') {
                  weight = 540
                } else if (taxonomyTypes[i]==='Pants') {
                  weight = 340
                } 
                if(size[i]) {
                  option1Title = `Size`;
                  option1val = ` ${size[i]}`
                }            
                
              }
              


            }
            else if(userID==="LePrix"){ 
              customProductType = taxonomyTypes[i]
              tags = `New Arrivals, ${brand[i]}, ${condition[i]}, ${itemName[i]}, ${era[i]}, vintage, preloved, pre-loved, ${colors[i]}, ${size[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${sleeves[i]}, ${style[i]}, ${closure[i]}, ${fastening_type[i]}, ${neckline[i]}, ${cut[i]}, ${hood[i]}, ${belt[i]}, ${design[i]}, ${lining[i]}, ${layers[i]}, ${pattern[i]}, ${skirt[i]}, ${rise[i]}, ${heelHeight[i]}, ${heelShape[i]}, ${height[i]}, ${toe[i]}`
              title = `${brand[i]} ${itemName[i]} ${taxonomyTypes[i]}`;
              description = `
              ${result}
              ${era[i]?`<p><b>Era:</b> ${era[i]}</p>`:''}
              ${conditionDescription[i]===""?  `<p><b>Condition:</b> ${condition[i]}</p>`:  `<p><b>Condition:</b> ${condition[i]}</p><p>&nbsp;&nbsp;&nbsp;&nbsp;• ${conditionDescription[i]}</p>`}
              <p><b>Color:</b> ${colors[i]}</p>
              <p><b>Size:</b> ${size[i]}</p>
             
              `
              
              
            }
            else if(userID==="zjkiN1cq9Eb4oHsrA923OoJ9Sxz1" || userID==="IMe1VYnWOQgQuER8JQHSI0iKNre2"){ //Tokki kids
              tags = `New, New Arrivals, ${brand[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${size[i]}`
              title = `${itemName[i]}${size[i]!==""? ` // ${size[i]}`: ""}`;
              weight = 1
              weightUnit = `lb`
              customProductType = taxonomyTypes[i]
      
              const replaced = size[i].match(/\d+/);
                let num;
                if (replaced !== null) {            
                  num = replaced[0];
                }
              if (size[i].toLocaleLowerCase().includes("adult")) {
                collection=`adult`
                tags=tags+', adult'
                isAgeBaby = false
              }          
              else if(size[i].toLocaleLowerCase().includes('m')) {                  
                if(num<6){
                  collection = `0M - 6M`
                  tags=tags+', 0M - 6M'
                  isAgeBaby=true 
                }
                else if(num<12){
                  collection=`6M - 12M`
                  tags=tags+', 6M - 12M'
                  isAgeBaby=true 
                }
                else if(num<24 || num===24){
                  collection=`12M - 24M`
                  tags=tags+', 12M - 24M'
                  isAgeBaby=true 
                }
              }
              else if(size[i].toLocaleLowerCase().includes('y')){
                if(num<4 || num === 4){
                  collection=`2Y - 4Y`
                  tags=tags+', 2Y - 4Y'
                  isAgeBaby=true 
                }
                else if(num<10 || num===10){
                  collection=`5Y - 10Y`
                  tags=tags+', 5Y - 10Y'
                }
              }
              status = "active"
            }     
            else if(userID==="5JAT5Rp2ZVP4KEeMPse9KAeDKTQ2"){ // Ware Kids
      
              tags = `New, New Arrivals, ${brand[i]}, ${types[i]}, ${colors[i]}, ${title.toLocaleLowerCase().includes("vintage")?"vintage":""}, ${taxonomyTypes[i]}`
              if(size[i]) {
                option1Title = `Size`;
                option1val = ` ${size[i]}`
              }            
              weight = itemWeight[i]
              taxable = `FALSE`
              isAgeBaby=true
            }
            else if(userID==="L1mPOQ2Ju8dAUs1pz37vbag93sl2"){ // Beeja May Test
             title = `${brand[i]} - ${types[i]} (${size[i]})`
             option1Title = `Size`;
             option1val = ` ${size[i]}`
             option2Title=`condition`
             option2val=`${condition[i]}`
             imageAlt = `${title} - Beeja May`
             tags = `Brand_${brand[i]}, Gender_${genders[i]}, Size_${size[i]}, Product Type_${types[i]}, ${colors[i]}, Condition_${condition[i]}, Price_${price[i]}, Baby ${genders[i]}, Baby ${genders[i]}${types[i]}, New Arrival, All Year, Kids, jackets & outerwear`
             weight =0;
            }     
            else if (userID==="HDlknVR9NpWxhs9WWlfvnwAQq4b2" || userID==="ZFoq2NIsDmZgVTW4g88O1B8JA822"){ // Bon Tot
              title = `${brand[i]} ${itemName[i]} size ${size[i]}`
              description = `<meta charset='UTF-8'>
                              <p class='p1'>
                                <span class='s1'>Brand: ${brand[i]}</span>
                              </p>
                              <p class='p1'>
                                <span class='s1'>Size: ${size[i]}</span>
                              </p>
                              <p class='p1'>
                                <span class='s1'>Condition: ${condition[i]}. ${conditionDescription[i]}</span>
                              </p>
                              <h4>About Bon Voyage:</h4>
                              <p>Bon Voyage is our brand new Buy Back marketplace - a curated collection of the best quality second hand baby and kids clothing available. This will include vintage childrenswear alongside Bon Tot pieces that we will literally Buy Back from you! We'll also buy other brands that you might own, as long as they meet our criteria in terms of where and how they were manufactured. This is an ethical second hand children's clothing marketplace.</p>
                              <p>At Bon Tot we've always stood for sustainable production, at a fair price, and this means kids clothing that is made to last - saving you money in the long run. Now, we're going one step further and offering to buy back your pieces once your kid has grown out of them. We will either back back for cash, or you can make a little extra if you choose store credit.</p>
                              <p>We're trying to make a truly sustainable circular system, focused on affordable, ethical, well made stylish kids clothes.</p>
                              <p>You can <strong>READ MORE <a href='https://www.bon-tot.com/blogs/news/buyback-second-hand-kids-clothing' title='second hand kids clothing near me - Bon Tot Edinburgh'>HERE</a></strong></p>`
              // customProductType = `${genericType[i].substring(genericType[i].indexOf('/') + 1)}`
              // if(customProductType.toLocaleLowerCase()==="upper"){
              //   customProductType = "tops"
              // }
              vendor = `Bon Voyage`        
              let sizeTag = [];
              if(size[i].toLocaleLowerCase().includes("m") && size[i].match(/\d+/)){
                if(["3M","6M", "3 months", "6 months", "0-3 months", "0-6 months", "3-6 months", "9M", "12M", "9 months", "12 months", "6-9 months", "9-12 months", "6-12 months"].includes(size[i].toLowerCase())){
                  sizeTag = ["0-12 months"]
                  isAgeBaby=true
                }   
                else if(Number(size[i].match(/\d+/)[0])>11){
                  sizeTag = ["1-3 years"]
                  isAgeBaby=true
                }                
              }        
              else if(size[i].toLocaleLowerCase().includes("y")){          
      
                const replaced = size[i].match(/\d+/g); 
                
                let num=[];
                if (replaced !== null && replaced.length > 1) {
                  num.push(Number(replaced[0]));
                  num.push(Number(replaced[1]));
                  console.log(num)
                  if (num[1] < 4) {
                    sizeTag = ["1-3 years"];
                    isAgeBaby=true
                  } else if (num[0] > 3 && num[1] < 7) {
                    sizeTag = ["4-6 years"];
                  } else if (num[0] < 4 && num[1] < 7) {
                    sizeTag = ["1-3 years", "4-6 years"];
                  } else if (num[0] > 6 && num[1] < 10) {
                    sizeTag = ["7-9 years"];
                  } else if (num[0] < 6 && num[1] < 10 && num[1]>6) {
                    sizeTag = ["4-6 years", "7-9 years"];
                  } else if (num[0] > 9 && num[1] < 12) {
                    sizeTag = ["10-11 years"];
                  } else if (num[0] < 9 && num[1] < 12 && num[1]>9) {
                    sizeTag = ["7-9 years", "10-11 years"];
                  }else if (num[0] < 11 && num[1] < 15) {
                    sizeTag = ["12-13 years"];
                  } else if (num[0] < 11 && num[1] < 15 && num[1]>11) {
                    sizeTag = ["10-11 years", "12-13 years"];
                  } else if (num[1]>13) {
                    vendor = `Bon Voyage Adult`
                  }
                } else if (replaced !== null && replaced.length === 1) {
                  num = Number(replaced[0]);
                  if(num<4){
                    sizeTag="1-3 years"
                    isAgeBaby=true
                  }                    
                  else if(num>3 && num <7){
                    sizeTag="4-6 years"
                  }
                  else if(num>6 && num<10){
                    sizeTag="7-9 years"
                  }
                  else if(num>9 && num<12){
                    sizeTag="10-11 years"
                  }
                  else if(num>11 && num<15){
                    sizeTag = "12-13 years"
                    if(num>13){
                      vendor = `Bon Voyage Adult`
                    }
                  }
                  else if(num>14){
                    sizeTag=size[i]
                    vendor = `Bon Voyage Adult`
                  }
                }
              }                    
              else if(["XS","S","M", "L", "XL", "XXL"].includes(size[i].toUpperCase())){
                vendor = `Bon Voyage Adult`
                sizeTag=size[i]
              } else {
                sizeTag=size[i]
              }
              tags = `${sizeTag}, buyback, ${brand[i]}, ${types[i]}, ${additionalInfos[i]}, ${customProductType}, ${colors[i]}${condition[i].toLowerCase().includes("brand new")?', NWT':', preloved, pre-loved'}, ${taxonomyTypes[i]}`
              weight = 0
              imageAlt = `${title} - Bon Tot`
              weightUnit = `kg`
              taxable = `FALSE`
            }
            else if(userID==="Fm1YF5TFtpNc8n1Ob7sxmE2bXgo1"){ // Royal City Littles
              title = `${itemName[i]} (${size[i]})`
              customProductType = taxonomyTypes[i]
              description=`
                           <strong data-mce-fragment='1'>Brand: </strong>${brand[i]} 
                           <div data-mce-fragment='1'>
                            <strong data-mce-fragment='1'>Size: </strong>${size[i]}
                           </div>
                           <div data-mce-fragment='1'>
                             <strong data-mce-fragment='1'>Details: </strong>${additionalInfos[i]}
                           </div>      
                           <div data-mce-fragment='1'>
                             <strong data-mce-fragment='1'>Condition: </strong>${condition[i]}
                           </div>
                           <div data-mce-fragment='1'>
                           <meta charset='UTF-8'>
                           <section class='shopify-section section' id='shopify-section-template--15415710974149__product-details' data-mce-fragment='1'>
                           <div class='product-details page-width section-template--15415710974149__product-details-padding' id='ProductDetails' data-mce-fragment='1'>
                           <div class='grid' data-mce-fragment='1'>
                           <div class='grid__item grid__item--three-quarter' data-mce-fragment='1'>
                           <div class='product__description rte' data-mce-fragment='1'>
                           <div data-mce-fragment='1'>
                           <strong data-mce-fragment='1'>RCL Buy Back Program? YES.</strong> When you're done with this item, and don't have someone to hand it down to, we'd be happy to re-purchase it from you and add to our preloved section of our shop. </div>
                           </div>
                           </div>
                           </div>
                           </div>
                           </section>
                           </div>`      
            let sizeTag = "";
            if(size[i].toLocaleLowerCase().includes("m")){
              isAgeBaby=true
              const replaced = size[i].match(/\d+/g); 
              console.log(replaced)
              let num=[];
      
              if (replaced !== null) {
                num.push(Number(replaced[0])); 
                num.push(Number(replaced[1])); 
              }
              if(num[1]<3 || num[1]===3){
                sizeTag='0-3M, baby'
              }
              else if(num[0]===3 || num[1] ===6 || (num[0]>3 && num[1]<6)){
                sizeTag='3-6M, baby'
              }
              else if(num[0]>5 && num[1]<13){
                if(num[1]>9){
                  sizeTag='6-12M, baby'
                }
                else if(num[1]<9 ||num[1]===9){
                  sizeTag='6-9M, 6-12M, baby'
                }  
                else if(num[1]<13 && num[1]>8){
                  sizeTag='9-12M, baby'
                }
              }
              else if(num[0]>11 && num[1]<25){          
                sizeTag='12-24M, toddler'
                if(num[1]<19){
                  sizeTag='12-18M, 12-24M, toddler'
                }
                else if(num[0]>17){
                  sizeTag='18-24M, toddler'
                }
              }      
              else if(num===18){
                sizeTag='18-24M, toddler'
              }
            }        
            else if(size[i].toLocaleLowerCase().includes("t")){
              isAgeBaby=true
              const replaced = size[i].match(/\d+/); 
              let num;
              if (replaced !== null) {
                num = Number(replaced[0]); 
              }        
              if(num===2){
                sizeTag="2T, toddler"
              }                    
              else if(num===3){
                sizeTag="3T, toddler"
              }
              else if(num===4) {
                sizeTag = "4T, kid"
              }        
            }                    
            else if(size[i].toLocaleLowerCase().includes("y")){          
              const replaced = size[i].match(/\d+/); 
              let num;
              if (replaced !== null) {
                num = Number(replaced[0]); 
              }        
              sizeTag = `${num}Y`
              if (num && num < 8) {
                sizeTag+=", kid"
              }
              else if (num && num > 7 && num < 15) {
                sizeTag+=", big kid"
              }
            }
            tags=`${sizeTag}, 5%, ${size[i]}, ${colors[i]}, ${brand[i]}, ${condition[i] && !condition[i].toLocaleLowerCase().includes("new with") ? "preloved, preloved clothing" : ""}, All Products, ${condition[i]}, ${customProductType}, ${taxonomyTypes[i]}`
            }
            else if(userID==="AgVEaDRk3tVtnfqAKiMYWLlRICG2"){ // Tiny Human Gear
              title = `${brand[i]}, ${size[i]}`
              description=`<meta charset='UTF-8'><p class='p1'><span class='s1'>${itemDescription[i]}</span></p><p class='p1'><span class='s1'>Colour: ${colors[i]}</span></p><p class='p1'><span class='s1'>Condition: ${condition[i]}${(condition[i]==="Very Good Used Condition" || condition[i]==="Good Used Condition" || condition[i]==="Play Condition")? `${conditionDescription[i]!==""? `. ${conditionDescription[i]}` :""}`:""}</span></p>`;
              customProductType = taxonomyTypes[i]
              // customProductType = `${genericType[i].substring(genericType[i].indexOf('/') + 1)}`
              // if(customProductType.toLocaleLowerCase()==="upper"){
              //   customProductType = "tops"
              // }
              let sizeTag = "";
              let num =[];
              const replaced = size[i].match(/\d+/g);
      
              if(size[i].toLocaleLowerCase().includes("m") && size[i].slice(-1).toLocaleLowerCase()==='m'){
      
                isAgeBaby=true
                if (replaced !== null && replaced.length > 1) {
                  num.push(Number(replaced[0]));
                  num.push(Number(replaced[1]));
      
                  if (num[1] < 6 || num[1] === 6) {
                    sizeTag = ["0-6 months"];
                  } else if (num[0] > 5 && num[1] < 13) {
                    sizeTag = ["6-12 months"];
                  } else if (num[0] < 6 && num[1] < 13) {
                    sizeTag = ["0-6 months", "6-12 months"];
                  } else if (num[0] > 11 && num[1] < 19) {
                    sizeTag = ["12-18 months"];
                  } else if (num[0] < 12 && num[1] < 19) {
                    sizeTag = ["6-12 months", "12-18 months"];
                  } else if (num[0] > 17 && num[1] < 25) {
                    sizeTag = ["18-24 months"];
                  } else if (num[0] < 18 && num[1] < 25) {
                    sizeTag = ["12-18 months", "18-24 months"];
                  }
                } else if (replaced !== null && replaced.length === 1) {
                  num = Number(replaced[0]);
                  if (num < 6) {
                    sizeTag = ["0-6 months"];
                  } else if (num === 6) {
                    sizeTag = ["0-6 months", "6-12 months"];
                  } else if (num < 12) {
                    sizeTag = ["6-12 Months"];
                  } else if (num === 12) {
                    sizeTag = ["6-12 Months", "12-18 Months"];
                  } else if (num > 12 && num < 19) {
                    sizeTag = ["12-18 Months"];
                  } else if (num === 18) {
                    sizeTag = ["12-18 Months", "18-24 months"];
                  } else if (num > 18 && num < 25) {
                    sizeTag = ["18-24 months"];
                  } 
                }
              }        
              else if(["2", "2 years", '2*'].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("12")){
                isAgeBaby=true
                sizeTag="2 years"
              }
              else if(["3", "3 years", "3*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("13")){
                isAgeBaby=true
                sizeTag="3 years"
              }
              else if(["4", "4 years", "4*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("14")){
                sizeTag = "4 years"
              }
              else if(["5", "4/5", "5/6", "5*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("15")){
                sizeTag = "5 years"
              }
              else if(["6", "7", "6/7", "5/6", "7/8", "6*", "7*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("16") && !size[i].includes("17")){
                sizeTag = "6/7 years"
              }
              else if(["8", "9", "7/8", "8/9", "9/10", "8*", "9*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("18") && !size[i].includes("19")){
                sizeTag = "8/9 years"
              }
              else if(["10", "11", "12", "10/11", "11/12", "12/13", "12/14", "10/12", "10*", "11*", "12*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear))){
                sizeTag = "10-12 years"
              }
              else if(["13", "14", "15", "16", "12/13", "12/14", "14/16", "16*", "13*", "14*", "15*"].some(sizeYear => size[i].toLowerCase().includes(sizeYear))){
                sizeTag = "13-16 years"
              }
              else if(size[i].toLowerCase().includes("women")){
                sizeTag = "Women's"
              }
              tags=`${condition[i]==="New With Tags"?'new, ':""}${size[i]}, ${colors[i]}, ${brand[i]}, ${size[i].toLowerCase().includes("women")?"":"5%"}, ${condition[i]}, ${customProductType}, ${sizeTag}, ${itemName[i]}, ${taxonomyTypes[i]}`
              collection = `${size[i].toLowerCase().includes("women")?"":"5%"}`
              if(size[i]) {
                option1Title = `Size`;
                option1val = ` ${size[i]}`
              }       
              weight = 0.1
            } else if (userID==="Vyv12RwlhQP02Cr0Kaf4PPc3ZxE3") { //Lil Cubs              
              let sizeTag = size[i];
              let num =[];
              const replaced = size[i].match(/\d+/g);              
              if((size[i].toLocaleLowerCase().includes("m") && size[i].slice(-1).toLocaleLowerCase()==='m') || size[i].toLocaleLowerCase().includes("newborn")) {
                isAgeBaby=true
                if (replaced !== null && replaced.length > 1) {
                  num.push(Number(replaced[0]));
                  num.push(Number(replaced[1]));
                                   
                  if(num[1]<3 || num[1]===3){
                    sizeTag='Newborn - 3 mths'
                  } else if (num[0]<3 && (num[1] === 6 || num[1]<6)) {
                    sizeTag = 'Newborn - 3 mths, 3-6 mths'
                  } else if((num[0] === 3 || num[0]>3) && (num[1]<6 || num[1] === 6)){
                    sizeTag='3-6 mths'
                  } else if (num[0] > 5 && num[1] < 13) {
                        sizeTag = "6-12 mths";
                  } else if (num[0] < 6 && num[1] < 13) {
                    sizeTag = "3-6 mths, 6-12 mths";
                  } else if (num[0] > 11 && num[1] < 19) {
                    sizeTag = "12-18 mths";
                  } else if (num[0] < 12 && num[1] < 19) {
                    sizeTag = "6-12 mths, 12-18 mths";
                  } else if (num[0] > 17 && num[1] < 25) {
                    sizeTag = "18-24 mths";
                  } else if (num[0] < 18 && num[1] < 25) {
                    sizeTag = "12-18 mths, 18-24 mths";
                  } 
                } else if (replaced !== null && replaced.length === 1) {
                    num = Number(replaced[0]);
                    if (num < 3) {
                      sizeTag = "Newborn - 3 mths";
                    } else if (num === 3) {
                      sizeTag = "Newborn - 3 mths, 3-6 mths"
                    } else if (num < 6) {
                      sizeTag = "3-6 mths";
                    } else if (num === 6) {
                      sizeTag = "0-6 months, 6-12 months";
                    } else if (num < 12) {
                      sizeTag = "6-12 mths";
                    } else if (num === 12) {
                      sizeTag = "6-12 mths, 12-18 mths";
                    } else if (num === 18) {
                      sizeTag = "12-18 mths, 18-24 mths";
                    } else if (num > 12 && num < 19) {
                      sizeTag = "12-18 mths";
                    }else if (num === 24) {
                      sizeTag = "18-24 mths, 2T";
                    } else if (num > 18 && num < 25) {
                      sizeTag = ["18-24 mths"];
                    }                  
                  }
                } else if(size[i].toLocaleLowerCase().includes("t")) {
                  isAgeBaby=true   
                  if (replaced !== null && replaced.length > 1) {
                    num.push(Number(replaced[0]));
                    num.push(Number(replaced[1]));
                    if(num[0]===2 && num[1]===3){
                      sizeTag="2T, 3T"
                    } else if(num===3 && num[1]===4){
                      sizeTag="3T, 4T"
                    } else if(num[1]===4 && num[1]===5) {
                      sizeTag = "4T, 5T"
                    } 
                  } else if (replaced !== null && replaced.length === 1) {
                    if (replaced !== null) {
                      num = Number(replaced[0]); 
                    }        
                    if(num===2){
                      sizeTag="2T"
                    } else if(num===3){
                      sizeTag="3T"
                    } else if(num===4) {
                      sizeTag = "4T"
                    } else if (num===5) {
                      sizeTag = "5T"
                    }
                  }                                 
                } 
                collection = taxonomyTypes[i]              
            } else if(userID==="VIa289fKOLZVRQTAEuueJXwvA0y2"){ //Pea Shoots Consignment        
              let sizeTags = size[i]?size[i]:"";
              if (sizeTags.toLowerCase().match(/t$/)) {
                sizeTags = sizeTags.toUpperCase();
              }
              else {          
                sizeTags = sizeTags.toLocaleLowerCase();
              }
              const sizeTitle = sizeTags.toLocaleLowerCase().includes('size') ? sizeTags : `size ${sizeTags}`
              vendor = vendorP.replace(/[^a-zA-Z]+/, "");
              title = `${vendorP} - ${brand[i]} ${colors[i].toLocaleLowerCase()} ${itemName[i].toLocaleLowerCase()}, ${sizeTitle}, ${condition[i].toLocaleLowerCase()}`;
              description = `<meta charset='UTF-8'><p class='p1'><span class='s1'>Size: ${sizeTags}</span></p>`;
              let sizeTag = [""];
              const replaced = size[i].match(/\d+/g);
              let num = [];
      
              //Footwear Logic
              if(genericType[i].toLowerCase().includes("footwear") || 
                 itemName[i].toLocaleLowerCase().includes("footwear") || 
                 itemName[i].toLocaleLowerCase().includes("shoes") ||
                 types[i].toLocaleLowerCase().includes("footwear")|| 
                 types[i].toLocaleLowerCase().includes("slipper")||
                 types[i].toLocaleLowerCase().includes("boot")) {
                  if (size[i].toLocaleLowerCase().includes("m")) {
                    if (replaced !== null && replaced.length > 1) {
                      num.push(Number(replaced[0]));
                      num.push(Number(replaced[1]));
      
                        if (num[0] < 3) {
                          sizeTag = ["Size 1 (Baby)"];
                          if(num[1]>3 && num[1]<7){
                              sizeTag= ["Size 1 (Baby)", 'Size 2 (Baby)']
                          }
                          else if(num[1]>6 && num[1]<10){
                            sizeTag= ['Size 1 (Baby)',  'Size 2 (Baby)', 'Size 3 (Baby)']
                          }
                          else if(num[1]>10 && num[1]<13){
                            sizeTag= ['Size 1 (Baby)',  'Size 2 (Baby)', 'Size 3 (Baby)', 'Size 4 (Baby)']                    
                          }
                      } else if (num[0] < 6) {
                          sizeTag = ["Size 2 (Baby)"];                  
                          if(num[1]>6 && num[1]<10){
                            sizeTag= ['Size 2 (Baby)', 'Size 3 (Baby)']
                          }
                          else if(num[1]>10 && num[1]<13){
                            sizeTag= ['Size 2 (Baby)', 'Size 3 (Baby)', 'Size 4 (Baby)']                    
                          }
                      } else if (num[0] < 9) {
                          sizeTag = ["Size 3 (Baby)"];                                    
                          if(num[1]>10 && num[1]<13){
                            sizeTag= ['Size 3 (Baby)', 'Size 4 (Baby)']                    
                          }
                      } else if (num[0] < 12){
                        sizeTag= ["Size 4 (Baby)"]
                        if(num[1]>12 && num[1]<19){
                        sizeTag= ["Size 4 (Baby)", "Size 5"]
                        }
                      }
                      else if (num[0] < 18){
                        sizeTag = ["Size 5"]
                        if(num[1]>18 && num[1]<25){
                          sizeTag = ["Size 5", "Size 6"]
                        }                  
                      }
                      else if (num[0] < 24){
                        sizeTag=["Size 6"]
                      }
                    } else if (replaced !== null && replaced.length === 1) {
                        num = Number(replaced[0]);
      
                        if (num < 3) {
                          sizeTag = ["Size 1 (Baby)"];
                        } else if (num === 3) {
                          sizeTag = ["Size 1 (Baby)", 'Size 2 (Baby)'];
                        } else if (num > 3 && num < 6) {
                          sizeTag = ["Size 2 (Baby)"];
                        } else if (num === 6) {
                          sizeTag = ["Size 2 (Baby)", 'Size 3 (Baby)'];
                        } else if (num > 6 && num < 9) {
                          sizeTag = ["Size 3 (Baby)"];
                        } else if (num === 9) {
                          sizeTag = ["Size 3 (Baby)", "Size 4 (Baby)"];
                        } else if (num > 9 && num < 12) {
                          sizeTag = ["Size 4 (Baby)"];
                        } else if (num === 12) {
                          sizeTag = ["Size 4 (Baby)", "Size 5"];
                        } else if (num > 12 && num < 18) {
                          sizeTag = ["Size 5"];
                        } else if (num === 18) {
                          sizeTag = ["Size 5", "Size 6"];
                        } else if (num > 18 && num < 25) {
                          sizeTag = ["Size 6"];
                        }
                    }
                  } else if (size[i].toLowerCase().includes('youth')){
                    if (replaced !== null && replaced.length === 1){
                      num =Number(replaced[0]);
                      if(num > 5 || num === 5){
                        sizeTag=["Youth Size 5+"]
                      }
                    }
                  }
                  sizeTag= [`${size[i]}`]
                } else {
                  if (size[i].toLocaleLowerCase().includes("m")) {
                    if (replaced !== null && replaced.length > 1) {
                      num.push(Number(replaced[0]));
                      num.push(Number(replaced[1]));
              
                      if (num[1] < 6 || num[1] === 6) {
                        sizeTag = ["0-6 Months"];
                      } else if ((num[0] > 6 && num[1] < 12) || num[1] === 12) {
                        sizeTag = ["6-12 Months"];
                      } else if ((num[0] > 12 && num[1] < 18) || num[1] === 18) {
                        sizeTag = ["12-18 Months"];
                      } else if (num[0] > 18 && num[1] < 25) {
                        sizeTag = ["18-24 Months"];
                      }
                    } else if (replaced !== null && replaced.length === 1) {
                      num = Number(replaced[0]);
              
                      if (num < 6) {
                        sizeTag = ["0-6 Months"];
                      } else if (num === 6) {
                        sizeTag = ["0-6 Months", "6-12 Months"];
                      } else if (num > 6 && num < 12) {
                        sizeTag = ["6-12 Months"];
                      } else if (num === 12) {
                        sizeTag = ["6-12 Months", "12-18 Months"];
                      } else if (num > 11 && num < 18) {
                        sizeTag = ["12-18 Months"];
                      } else if (num === 18) {
                        sizeTag = ["12-18 Months", "18-24 Months"];
                      } else if (num > 18 && num < 25) {
                        sizeTag = ["18-24M"];
                      }
                    }
                    isAgeBaby=true
                  } else if (size[i].toLocaleLowerCase().includes("t")) {
                    sizeTag = [`${sizeTags}`];
                    isAgeBaby=true
                    if(replaced && replaced.length>1){
                      num = []
                      num.push(Number(replaced[0]));
                      num.push(Number(replaced[1]));
                      sizeTag = [`${num[0]}T`, `${num[1]}T`]
                    }
                  } else {
                    sizeTag=[`${size[i]}`]
                    if(replaced && replaced.length>1){
                      num = []
                      num.push(Number(replaced[0]));
                      num.push(Number(replaced[1]));
                      if(num[0]>9 && num[1]<13){
                        sizeTag=["10-12+"]
                      }
                      else{
                        sizeTag = [`${num[0]}`, `${num[1]===8 || num[1]===9 ?'8/9':num[1]}`]
                      }
                    }
                    else if (size[i]===9 || size[i]===8){
                      sizeTag=["8/9"]
                    }
                    else if (size[i]>9){
                      sizeTag=["10-12+"]
                    }
                  }
                }
              // Tags Schema
              // {gender size type}
              // {gender size}
              // {gender type}
              // {gender}
              // console.log(sizeTag)       
              let genderSizeType = `${genders[i]} ${sizeTags} ${types[i]}`;
              let genderSize = `${genders[i]} ${sizeTags}`;
              let genderType = `${genders[i]} ${types[i]}`;              
                       
                if (genderTag.length !== 0) {
                  genderSizeType = [];
                  genderSize = [];
                  genderType = [];
                  const genderTypeSizeSorter = (gender, size, type) =>{
                    if(type.toLocaleLowerCase().includes("footwear")){
                      let typeTag = "Footwear"
                      return `${gender} ${typeTag} ${size}`
                    }
                    return `${gender} ${size} ${type}`
                  }
                  
                  // eslint-disable-next-line no-loop-func
                  genderTag.map((key, index) => {              
                    genderType.push(`${genderTag[index]} ${types[i]}`);
                    sizeTag.forEach((tag)=>{
                      if (!/[a-zA-Z]/g.test(size[i])) {
                        genderSizeType.push(`${genderTag[index]} Size ${tag} ${types[i]}`);
                        genderSize.push(`${genderTag[index]} Size ${tag}`);                
                      } else {
                        genderSizeType.push(genderTypeSizeSorter(genderTag[index], tag, types[i]));
                        genderSize.push(`${genderTag[index]} ${tag}`);   
                      }
                    })
                  });
                  genderSizeType = genderSizeType.join(", ");
                  genderSize = genderSize.join(", ");
                  genderType = genderType.join(", ");
                  genderTag = genderTag.join(", ");
                  if (
                    genderTag.toLowerCase().includes("boys") &&
                    genderTag.toLowerCase().includes("girls")
                  ) {
                    genderTag += ", gender neutral";
                  }
                } 
              
              tags = `${genderTag}, ${genderSizeType}, ${genderSize}, ${genderType}, ${
                size[i]
              }, ${sizeTag !== size[i] ? sizeTag : ""},${colors[i]}`;        
            }
            else if (userID==="s8G1GJrXSraO8ySUsMeGY61sJRk1"){ // Aster and Luna
              title=`${brand[i]} - ${itemName[i]} (${size[i]})${condition[i].toLocaleLowerCase().includes('play') ? ' *play' : ''}`
              description=`<meta charset='UTF-8'><p class='p1'><span class='s1'>Condition: ${condition[i]}${`${conditionDescription[i]!==""? `. ${conditionDescription[i]}` :""}`} </span></p><p class='p1'><span class='s1'>Colour: ${colors[i]}</span></p>${itemDescription[i]!==""?`<p class='p1'><span class='s1'>Description: ${itemDescription[i]}</span></p>`:""}${retailPrice[i]!=="" && retailPrice[i]!==undefined ? `<p class='p1'><span class='s1'>Retail Price: ${retailPrice[i]}</span></p>`:""}`
              tags = `${TagSize(size[i])}, ${size[i]}, ${brand[i]}, ${condition[i]}, ${customProductType}, ${genders[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
              if(size[i]) {
                option1Title = `Size`;
                option1val = ` ${size[i]}`
              }   
            }
            else if (userID==="5APyYorrgoZ8uKOcxp5YMz3J0zq1" || userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1") { // Curated Cubs
              if(taxonomyTypes[i] && taxonomyTypes[i].toLowerCase()!=="toys" || taxonomyTypes[i].toLowerCase()!=="costumes" || !taxonomyTypes[i].toLowerCase().includes("maternity") || taxonomyTypes[i].toLowerCase()!== "gear") {
                isAgeBaby = true
              }
              if (brand[i] === "") {
                vendor = "Various"
              }
              customProductType = taxonomyTypes[i]
              if(taxonomyTypes[i]==="Dresses" || taxonomyTypes[i]==="Skirts") {
                customProductType = "Dresses + Skirts"
              } else if (taxonomyTypes[i] === "Accessories" || taxonomyTypes[i] === "Hats") {
                customProductType = "Accessories"
              }
              imageAlt = `${title[i]} - Curated Cubs`
              // customProductType = `${genericType[i].substring(genericType[i].indexOf('/') + 1)}`
              // if(customProductType.toLocaleLowerCase()==="upper"){
              //   customProductType = "tops"
              // }
              let sizeTag = "";
              let num =[];
              const replaced = size[i].match(/\d+/g);
      
              if(size[i].toLocaleLowerCase().includes("mos")) {
                isAgeBaby=true
                if (replaced !== null && replaced.length > 1) {
                  num.push(Number(replaced[0]));
                  num.push(Number(replaced[1]));
      
                  if (num[1] < 6 || num[1] === 6) {
                    sizeTag = "0-6 months, 0-6mos";
                  } else if (num[0] > 5 && num[1] < 13) {
                    sizeTag = "6-12 months, 6-12mos";
                  } else if (num[0] < 6 && num[1] < 13) {
                    sizeTag = "0-6 months, 6-12 months, 0-6mos, 6-12mos";
                  } else if (num[0] > 11 && num[1] < 19) {
                    sizeTag = "12-18 months, 12-18mos";
                  } else if (num[0] < 12 && num[1] < 19) {
                    sizeTag = "6-12 months, 12-18 months, 6-12mos, 12-18mos";
                  } else if (num[0] > 17 && num[1] < 25) {
                    sizeTag = "18-24 months, 18-24mos";
                  } else if (num[0] < 18 && num[1] < 25) {
                    sizeTag = "12-18 months, 18-24 months, 12-18mos, 18-24mos";
                  }
                } else if (replaced !== null && replaced.length === 1) {
                  num = Number(replaced[0]);
                  if (num < 6) {
                    sizeTag = "0-6 months, 0-6mos";
                    if(num===3) {
                      sizeTag +=", 0-3mos, 3-6mos"
                    } else if(num<4 && num>0) {
                      sizeTag +=", 0-3mos, 0-3 months"
                    } else if (num<6 && num>3) {
                      sizeTag +=", 3-6mos, 3-6 months"
                    }
                  } else if (num === 6) {
                    sizeTag = "0-6 months, 6-12 months, 0-6mos, 6-12mos";
                  } else if (num < 12) {
                    sizeTag = "6-12 Months, 6-12mos";
                    if(num===9) {
                      sizeTag+=", 6-9mos, 9-12mos"
                    } else if(num<10 && num>6) {
                      sizeTag +=", 9-12mos"
                    } else if (num<12 && num>9) {
                      sizeTag +=", 9-12mos"
                    }
                  } else if (num === 12) {
                    sizeTag = "6-12 Months, 12-18 Months, 6-12mos, 12-18mos";
                  } else if (num > 12 && num < 19) {
                    sizeTag = "12-18 Months, 12-18mos";
                  } else if (num === 18) {
                    sizeTag = "12-18 Months, 18-24 months, 12-18mos, 18-24mos";
                  } else if (num > 18 && num < 25) {
                    sizeTag = "18-24 months, 18-24mos";
                  } 
                }
              }        
              else if(["2 years", '2T'].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("12")){
                isAgeBaby=true
                sizeTag="2T, 2 years"
              }
              else if(["3 years", "3T"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("13")){
                isAgeBaby=true
                sizeTag="3 years, 3T"
              }
              else if(["4 years", "4T", "4-5T"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("14")){
                sizeTag = "4 years, 4T, 5 years, 5T"
              }
              else if(["5T", "5-6T"].some(sizeYear => size[i].toLowerCase().includes(sizeYear)) && !size[i].includes("15")){
                sizeTag = "5 years, 5T, 6T, 6 years"
              }
              else if(size[i].toLocaleLowerCase().includes("t")) {
                if (replaced !== null && replaced.length > 1) {
                  num.push(Number(replaced[0]));
                  num.push(Number(replaced[1]));
                  let firstNum=num[0];
                  for(firstNum=num[0]; firstNum<num[1]+1; firstNum++) {                                      
                    if(firstNum>num[0]) {
                      sizeTag+=","
                    }
                    if(firstNum>5) {
                      sizeTag += `${firstNum}Y`                    
                    } else {
                      sizeTag += `${firstNum}T`                    
                    }
                    console.log(sizeTag)
                  }                  
                } else if (replaced !== null && replaced.length === 1){
                  num[0] = Number(replaced[0]);
                  sizeTag = `${num[0]}T`
                }
              }
              else if(size[i].toLocaleLowerCase().includes("y")) {                
                if (replaced !== null && replaced.length > 1) {
                  num.push(Number(replaced[0]));
                  num.push(Number(replaced[1]));
                  let firstNum=num[0];
                  for(firstNum=num[0]; firstNum<num[1]+1; firstNum++) {                                      
                    if(firstNum>num[0]) {
                      sizeTag+=","
                    }
                    sizeTag += `${firstNum}Y`
                    console.log(sizeTag)
                  }        
                } else if (replaced !== null && replaced.length === 1){
                  num[0] = Number(replaced[0]);
                }
                  if (num[0] === 6) {
                    sizeTag = "6Y, 6 years, 6 youth";
                  } else if (num[0]=== 7) {
                    sizeTag = "7Y, 7 years, 7 youth";

                  } else if (num[0] === 8) {
                    sizeTag = "8Y, 8 years, 8 youth";

                  } else if (num[0] === 9) {
                    sizeTag = "9Y, 9 years, 9 youth";

                  } else if (num[0] === 10) {
                    sizeTag = "10Y, 10 years, 10 youth";

                  } else if (num[0] === 11) {
                    sizeTag = "11Y, 11 years, 11 youth";

                  } else if (num[0] === 12) {
                    sizeTag = "12Y, 12 years, 12 youth";
                  }
                }
              tags+= `, _badge_${brand[i]}, vendor_${vendor}, ${sizeTag}, ${size[i]}, ${brand[i]}, ${customProductType}, ${genders[i]}, ${taxonomyTypes[i]} ${condition[i] && condition[i].toLocaleLowerCase()==="brand new with tags"? ", NWT": ""}`
            }
            else if (template==='preLovedKids') {
              tags = `${TagSize(size[i])}, ${size[i]}, ${brand[i]}, ${condition[i]}, ${customProductType}, ${genders[i]}, ${additionalInfos[i]}, ${taxonomyTypes[i]}`
              customProductType = taxonomyTypes[i]
            } 
            if (userID==='cKIubugLu0R3lv64AaQX37Qg0xz1'){
              collection = collection + 'all stocks'
            }
            // else if(userID==="UDpMYI7qc5dDFLCf0jEwZpJhLF52")//small and kind
            // {
            //   title = 
            // }
            // console.log(types[i])
            // console.log(genericType[i])
            // console.log(taxonomyTypes[i])
            // console.log(csv)
      
            // console.log(i, ": ", taxonomyTypes[i], itemName[i])
      
              
            const typeSynonyms = getTypeSynonyms(taxonomyTypes[i])
            const nameSynonyms = getTypeSynonyms(itemName[i])
            const designSynonyms = getDesignSynonyms(design[i])
            const necklineSynonyms = getNecklineSynonyms(neckline[i])
            const patternSynonyms = getPatternSynonyms(pattern[i])
      
            tags += ", " + typeSynonyms.join(", ")
            tags += ", " + nameSynonyms.join(", ")
            tags += ", " + designSynonyms.join(", ")
            tags += ", " + necklineSynonyms.join(", ")
            tags += ", " + patternSynonyms.join(", ")
                  
            let currentSku = sku[i]
            if (template === "new-clothes") {
              if (Array.isArray(colors[i])) {
                if (option1Title === "Title" ||option1Title === "" ) {
                  option1Title = "Color"
                  option1val = `${colors[i][0]}`
                } else if (option2Title === "") {
                  option2Title = "Color"
                  option2val = `${colors[i][0]}`
                } else if (option3Title === "") {
                  option3Title = "Color"
                  option3val = `${colors[i][0]}`
                }                
                currentSku = `${sku[i]}-${colors[i][0]}`              
              }
            }
            // console.log(itemName[i])
            // console.log(taxonomyTypes[i])
            // console.log(design[i])
            // console.log(neckline[i])
            // console.log(pattern[i])
            // console.log(tags)
      
            // color + type
            // design + type
            // genedr + type
            // age group + type
            // brand + tshirt
            // if vintage:
            // "vintage tshirts"
      
            tags += `, ${colors[i]} ${taxonomyTypes[i]}`
            tags += `, ${design[i]} ${taxonomyTypes[i]}`
            // tags += `, ${genders[i]} ${taxonomyTypes[i]}`
            tags += `, ${isAgeBaby ? "baby" : "adult"} ${taxonomyTypes[i]}`
            tags += `, ${brand[i]} ${taxonomyTypes[i]}`
            tags += `, ${template} ${taxonomyTypes[i]}`
            tags += `, ${theme[i]}`

            let newTitle = ""

            const getProductCategory = () => {
              if (userID === "b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1") {
                
                const notBabyClothing = ["costumes", "maternity + nursing", "toys", "gear"]
                
                if(!notBabyClothing.includes(taxonomyTypes[i].toLocaleLowerCase())) {
                    return "Apparel & Accessories > Clothing > Baby & Toddler Clothing"
                } else if (taxonomyTypes[i].toLocaleLowerCase().includes("costumes")) {
                    return "Apparel & Accessories > Costumes & Accessories > Costumes"
                } else if (taxonomyTypes[i].toLocaleLowerCase().includes("maternity + nursing")) {
                    return "Apparel & Accessories > Clothing"
                } else if (taxonomyTypes[i].toLocaleLowerCase().includes("toys")) {
                    return "Toys & Games"
                } else if (taxonomyTypes[i].toLocaleLowerCase().includes("gear")) {
                    return "Baby & Toddler"
                }
              } else if (userID==="zjkiN1cq9Eb4oHsrA923OoJ9Sxz1" && taxonomyTypes[i].toLocaleLowerCase().includes("tokki market")) {
                  return "Baby & Toddler"
              }
              return `Apparel & Accessories${taxonomyTypes[i] ? 
                ((taxonomyTypes[i].toLocaleLowerCase().includes("footwear")|| 
                  taxonomyTypes[i].toLocaleLowerCase().includes("slipper")||
                  taxonomyTypes[i].toLocaleLowerCase().includes("boot")) ? 
                    ` > Shoes`
                :
                ` > Clothing${size[i] ? 
                      (isAgeBaby ?
                        ` > Baby & Toddler Clothing${taxonomyTypes[i]!=="" ? 
                            ( 
                              taxonomyTypes[i].toLocaleLowerCase().includes("top") ? ` > Baby & Toddler Tops` 
                              :
                              taxonomyTypes[i].toLocaleLowerCase().includes("bottom") ? ` > Baby & Toddler Bottoms`
                              :
                              taxonomyTypes[i].toLocaleLowerCase().includes("outerwear") ? ` > Baby & Toddler Outerwear`
                              :
                              taxonomyTypes[i].toLocaleLowerCase().includes("sleepwear") ? ` > Baby & Toddler Sleepwear`
                              :
                              taxonomyTypes[i].toLocaleLowerCase().includes("swimwear") ? ` > Baby & Toddler Swimwear`
                              :
                              taxonomyTypes[i].toLocaleLowerCase().includes("dress") ? ` > Baby & Toddler Dresses`
                              :""
                            )
                            :""
                          }`
                        : 
                        ""
                      )
                    :
                    ""
                  }`
                )
                :
                ""
              }`
            }
            
            csv.push([
              `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku[i] + '-' + itemName[i].replace(/\s+/g, '-'): handle}`, //Handle
              `${title}`, //Title
              // `<p>Type: ${types[i]}<br /> Gender: ${genders[i]} <br /> Color: ${colors[i]} <br /> AdditionalInfo: ${additionalInfos[i]}</p>`, //Body (HTML)
              `${description}`,
              `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? brand[i]: vendor }`, //Vendor
              `${await getProductCategory()}`, //Standardized Product Type
              customProductType, //Custom Product Type
              `${tags.replace('undefined','')}`, //Tags
              "TRUE", //Published 
              `${option1Title}`, //Option1 Name        
              `${option1val}`, //Option1 Value
              `${option2Title}`, //Option2 Name
              `${option2val}`, //Option2 Value
              `${option3Title}`, //Option3 Name
              `${option3val}`, //Option3 Value
              `${currentSku}`, //Variant SKU
              weight, //Variant Grams
              "shopify", //Variant Inventory Tracker
              1, //Variant Inventory Qty 
              "deny", //Variant Inventory Policy 
              "manual", //Variant Fulfillment Service 
              price[i], //Variant Price///////////////////////// 
              "", //Variant Compare At Price 
              "TRUE", //Variant Requires Shipping 
              `${taxable}`, //Variant Taxable 
              `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? vendor: "#0000" }`, //Barcode
              // "#0000", //Variant Barcode////////////////////
              s3Images[i], //Image Src 
              1, //Image Position 
              `${imageAlt}`, //Image Alt Text 
              "FALSE", //Gift Card 
              title, //SEO Title
              // `This ${types[i]} from ${brand[i]} is a perfect match if you are looking for something cozy for your little one. Item is of ${condition[i]} condition and will fit your ${size[i]} olds. Few key details ${additionalInfos[i]}. This ${types[i]} is available in ${colors[i]}`, //SEO Description
              `${(!itemDescription[i] || itemDescription[i]==="") && template !== 'vintage' ? `The ${types[i]} from ${brand[i]} is a perfect match if you are looking for something special for your little one. Item is of ${condition[i]} condition and will fit your ${size[i]} olds. This ${types[i]} is available in ${colors[i]}.`: `${itemDescription[i].replace(/"/g, '""')}`}`, //SEO Description
              `${await getProductCategory()}`, //Google Shopping / Google Product Category
              genders[i], //Google Shopping / Gender
              `${userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || userID==='t4gFVUQejYQYBY6dhwOp2mkYMTe2' || userID==='WbahZZa5HhfWAa6SyJLYnl31kMG3' || template === 'vintage' ?"Adult":"kids"}`, //Google Shopping / Age Group
              "", //Google Shopping / MPN
              "", //Google Shopping / AdWords Grouping
              "", //Google Shopping / AdWords Labels
              condition[i], //Google Shopping / Condition
              "", //Google Shopping / Custom Product
              "", //Google Shopping / Custom Label 0
              "", //Google Shopping / Custom Label 1
              "", //Google Shopping / Custom Label 2
              "", //Google Shopping / Custom Label 3
              "", //Google Shopping / Custom Label 4
              template === "new-clothes" && Array.isArray(colors[i]) ? "" : s3Images[i], //Variant Image
              `${weightUnit}`, //Variant Weight Unit
              "", //Variant Tax Code
              costPerItem[i], //Cost per item	////////////////// 
              price[i], //Price / International 
              "", //Compare At Price / International
              `${status}`, //Status
              `${collection}`,
              isImport
            ]);
						let n = csv.length;
						
						const mp = {
							"Item Name": itemName[i],
              "Brand": brand[i],
              "Era": era[i],
              "Size": size[i],
							"Color": colors[i],
							"Condition": `${condition[i]}${conditionDescription[i] && conditionDescription[i]!==''? `<span>. ${conditionDescription[i].charAt(0).toUpperCase() + conditionDescription[i].slice(1)}</span>`:''}`,
							"Type": customProductType,
							"Description": description,
							"Description SEO": result,
							"Gender": genders[i],
							"Measurements": `
                ${waistM[i]!=="" || hipM[i]!=="" || bottomInSeam[i]!=="" || riseMeasure[i]!=="" || thighMeasure[i]!=="" || legOpening[i]!=="" || pitToPitM[i]!=="" || lengthM[i]!=="" || shoulderM[i]!=="" || sleeveM[i]!=="" || heelHeightMeasure[i] !== "" || soleMeasure[i] !== "" || inSoleMeasure[i] !== "" || shoeWidth[i] !== "" ?`<p><b>Measurements:</b></p>`:''}
                ${waistM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${hipM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${bottomInSeam[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i].replace(/"/g, '""')}</p>
                `: ""}
                ${riseMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${thighMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${legOpening[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i].replace(/"/g, '""')}</p>
                `: ""}
                ${pitToPitM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoulderM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${sleeveM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i].replace(/"/g, '""')}</p>
                `: ""} 
                ${lengthM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${heelHeightMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Heel Height: ${heelHeightMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${soleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sole: ${soleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
               ${inSoleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Insole: ${inSoleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoeWidth[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Width: ${shoeWidth[i].replace(/"/g, '""')}</p>
                `: ""}
                `

            }

            function cleanTemplate(str) {
				str = str.trim(); // Trim leading and trailing whitespace
				if (str.charAt(str.length - 1) === '|') {
					return str.slice(0, -1); // Remove the last character
				} else if (
					str.charAt(str.length - 1) === ')' &&
					str.charAt(str.length - 3) === '(' &&
         ( str.charAt(str.length - 2) === ' ' || str.charAt(str.length - 2) === '')
				) {
					return str.slice(0, -3);
				} else {
					return str; 
				}
			}
          

            if (titleBlocks && titleBlocks.length > 0) {
              for (let j = 0; j < titleBlocks.length; j++) {
                const value = titleBlocks[j].value
                if (titleBlocks[j].type === "variable") {
                  if (!Object.hasOwn(mp, value)) continue;
                  if (!mp[value]) continue;
                  newTitle += mp[value]
                }
                else {
                  newTitle += value
                }
                if (j < titleBlocks.length - 1) newTitle += ' ';
              }
               newTitle = cleanTemplate(newTitle)
              csv[n - 1][1] =  newTitle
              csv[n - 1][29] = newTitle
            }
            

            
            if (descriptionBlocks && descriptionBlocks.length > 0) {
              for (let j = 0; j < descriptionBlocks.length; j++) {
                let value = descriptionBlocks[j].value
                if (descriptionBlocks[j].type === "variable") {
                  // if (!Object.hasOwn(mp, value)) continue;
                  // console.log(value)
                  if (!mp[value]) continue;
                  if (value === "Measurements") {
                    userDesc += mp[value];
                    continue;
                  } else if (value.toLowerCase() === "size") {
                    userDesc += `<p class='p1'><span class='s1'>${value}: ${size[i]}${sizeNotes[i] && sizeNotes[i] !== '' ? `<span>. ${sizeNotes[i].charAt(0).toUpperCase().replace(/"/g, '""') + sizeNotes[i].slice(1).replace(/"/g, '""')}</span>`:''}</span></p>`
                    continue;
                  } else if (value.toLowerCase() === "description seo") {
                    userDesc += `${mp[value]}`
                    continue;
                  }
                  let showHeader = (value !== "Description" && value !== "Description SEO" && value !== "Measurements" && value.toLowerCase()!=="size")
                  userDesc += `<p class='p1'><span class='s1'>${showHeader ? (value + ": ") : ''}${mp[value]}</span></p>`	
                  // console.log(`${value}: ${userDesc}`)
                }
                else {
                  userDesc += `<p class='p1'><span class='s1'>${value}</span></p>`	
                }
              }
              csv[n-1][2] = userDesc
            }
            // if(supplementaryImages[i] && supplementaryImages[i].length>0  Array.isArray(supplementaryImages[i])) {
            if (template === "new-clothes" && Array.isArray(colors[i]) && supplementaryImages[i] && supplementaryImages[i].length>0 && colors[i].length - 1 > supplementaryImages[i].length) {              
              colors[i].shift()
              colors[i].forEach((colorVariant, index) => {
                let suppImageVal = ""
                if (index < supplementaryImages[i].length) {
                  suppImageVal = supplementaryImages[i][index]
                }
                if(suppImageVal!=="" || colorVariant!=="") {
                  csv.push(getVariantRow(option1Title, option2Title, option3Title, userID, sku[i], itemName[i], handle, colorVariant, suppImageVal, index, costPerItem[i], weight, taxable, price[i]));                               
                }
              })
            } else if(supplementaryImages[i] && supplementaryImages[i].length>0) {
                supplementaryImages[i].forEach((imageLink, index) => {               
                  if (template === "new-clothes" && Array.isArray(colors[i]) && colors[i].length > 1) {
                    let colorVal = colors[i].length > index + 1 ? colors[i][index+1] : ""
                    csv.push(getVariantRow(option1Title, option2Title, option3Title, userID, sku[i], itemName[i], handle, colorVal, imageLink, index, costPerItem[i], weight, taxable, price[i]));                    
                  } else {
                      csv.push([
                        `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku[i] + '-' + itemName[i].replace(/\s+/g, '-'): handle}`, //Handle
                        ``, ``, ``, ``, ``, ``, "", ``, ``, ``, ``, "", '', "", "", "", "", "", "", ``, "", "", ``, "", 
                        imageLink,
                        [index+2], //Image Position
                        ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, ``, "", ``, ``, "", ``, ``, false
                      ]);
                  }                
              })
            } else if (template === "new-clothes" && Array.isArray(colors[i]) && colors[i].length > 1 ) {
              colors[i].shift()              
              // console.log(colors[i])
                colors[i].forEach((colorVariant, index) => {
                  if (colorVariant!=="") {
                    csv.push(getVariantRow(option1Title, option2Title, option3Title, userID, sku[i], itemName[i], handle, colorVariant, '', index, costPerItem[i], weight, taxable, price[i]));                                                          
                  }
                })
            }
          }    

          let csvData = []
          csvData = await createSEOcsv(csv)
          returnVal = csvData;

        if(isImport){
          returnVal.push(size)
        }
          
          
    
        
        return returnVal
      } catch(err){
        console.log(err)
      }
  }; 
}
  const createSEOcsv = async (csvData) => {
    let returnData = csvData
    try {      
      const res = await backendClient.post("/csv", {
        args: csvData,
        user: "userID"
      });            
      returnData = res.data
      returnData.unshift([
        "Handle",
        "Title",
        "Body (HTML)",
        "Vendor",
        "Standardized Product Type",
        "Custom Product Type",
        "Tags",
        "Published",
        "Option1 Name",
        "Option1 Value",
        "Option2 Name",
        "Option2 Value",
        "Option3 Name",
        "Option3 Value",
        "Variant SKU",
        "Variant Grams",
        "Variant Inventory Tracker",
        "Variant Inventory Qty",
        "Variant Inventory Policy",
        "Variant Fulfillment Service",
        "Variant Price",
        "Variant Compare At Price",
        "Variant Requires Shipping",
        "Variant Taxable",
        "Variant Barcode",
        "Image Src",
        "Image Position",
        "Image Alt Text",
        "Gift Card",
        "SEO Title",
        "SEO Description",
        "Google Shopping / Google Product Category",
        "Google Shopping / Gender",
        "Google Shopping / Age Group",
        "Google Shopping / MPN",
        "Google Shopping / AdWords Grouping",
        "Google Shopping / AdWords Labels",
        "Google Shopping / Condition",
        "Google Shopping / Custom Product",
        "Google Shopping / Custom Label 0",
        "Google Shopping / Custom Label 1",
        "Google Shopping / Custom Label 2",
        "Google Shopping / Custom Label 3",
        "Google Shopping / Custom Label 4",
        "Variant Image",
        "Variant Weight Unit",
        "Variant Tax Code",
        "Cost per item",
        "Price / International",
        "Compare At Price / International",
        "Status",
        "Collection",
      ]);
      
      return returnData
    } catch (e) {
      console.log("Please try using a different image");
      console.error(e);
      return []
    }
  }

  export default createcsv;
