import { getAuth } from '@firebase/auth';
import React, { useEffect, useState } from 'react';
import MainProduct from "../products/descriptions/mainProduct";

const Dashboard = ({ history }) => {
  
  const auth = getAuth();
  const user = auth.currentUser;
    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token || !user) {
            history.push('/')
        }
    },[])
    const [generateButtonClick, setGenerateButtonClick] = useState(0)
    let userID;
    let userName;

    const MINUTE_MS = 60000;

    useEffect(() => {
      const interval = setInterval(() => {
        setGenerateButtonClick(0);
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    
    if (user !== null) {
      userID= user.uid;
        user.providerData.forEach((profile) => {            
            userID = user.uid;
            userName = profile.displayName
          // console.log("  Provider-specific UID: " + profile.uid);
          // console.log("  Name: " + profile.displayName);
          // console.log("  Email: " + profile.email);          
          // console.log("  Photo URL: " + profile.photoURL);
        });
      }
      // console.log("  id: " + userID);

    return (
       <MainProduct  user={userID} userName={userName} setGenerateButtonClick={setGenerateButtonClick} generateButtonClick={generateButtonClick} />
    )
}

export default Dashboard;
