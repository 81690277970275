import axios from "axios";

const updateColumnValue = async (
  singleProductModelFilter,
  template,
  state,
  event,
  id,
  index,
  key,
  deleteRow,
  secondIndex
) => {
  const snapWriteAPI = "https://apie.snapwrite.ca";
  const localHost = "http://localhost:3500";
  const currentApi = snapWriteAPI;
  let link = `${currentApi}/storeData/labeling-clothes-categorization-updateVal`;
  if (template === "vintage") {
    link = `${currentApi}/storeData/vintage-apparel-updateVal`;
  } else if (template === "new-clothes") {
    link = `${currentApi}/storeData/new-apparel-updateVal`;
  } else if (template === "pre-loved-regular") {
    link = `${currentApi}/storeData/regular-preLoved-apparel-updateVal`;
  } else if (template === "mm-coats") {
    link = `${currentApi}/storeData/mm-table`;
  } else if (template === "mm-sports") {
    link = `${currentApi}/storeData/mm-sports`;
  }

  const body = {
    id: id,
    val: event,
    name: state,
    index: index,
    key: key,
    deleteRow: deleteRow,
    secondIndex: secondIndex,
  };

  const regularModelRequest = fetch(link, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(`Error ${err}`);
      throw err;
    });
    
  let singleItemModelRequest;
  if (singleProductModelFilter) {
    const singleItemBody = {
      singleProductModelFilter: singleProductModelFilter,
      name: key,
      value: event,
      id: id,
    };
    singleItemModelRequest = axios
      .put(
        `${currentApi}/storeData/single-product-update-by-SKU`,
        singleItemBody
      )
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  try {
    const [regularModelResponse, singleItemModelResponse] = await Promise.all([
      regularModelRequest,
      singleItemModelRequest,
    ]);
 return {regularModelResponse, singleItemModelResponse}
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle errors if needed
  }
};

export default updateColumnValue;
