import React, { useEffect } from "react";
import logo1 from "../assets/logo.png";
import notFound from "../assets/404.png";
import { getAuth } from '@firebase/auth';

export const NonExisting = ({history}) =>{
  const auth = getAuth();
    const user = auth.currentUser;
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token || !user) {
        history.push('/')
    }
  },[])
    return(
        <>
        <header>
        <nav className="navbar navbar-expand-sm bg-light">
          <div className="d-flex flex-row bd-highlight mb-2 mt-1">
            <div className="p-1 bd-highlight">
              <img src={logo1} alt="Found " />
            </div>
            <div className="p-2 bd-highlight  snap-text">SnapWrite AI</div>
          </div>                              
        </nav>
      </header>
      
        <center><img src={notFound} alt="404 not found" /><br /><p>Page not found! Looks like the content on this page had been deleted or moved</p></center>
        <button>back to login</button>
        </>
    )
} 