import React, { useState } from 'react';
import {
	Button,
	Typography,
	Autocomplete,
	TextField,
	InputAdornment,
	Box,
	Fab,
	Skeleton,
	Modal,
} from '@mui/material';
import { MuiThemeProviderComponent } from '../theme/ThemeContext';

// Buttons

export const PrimaryButton = ({ title, ...props }) => (
	<>
		<MuiThemeProviderComponent>
			<Button
				variant='contained'
				{...props}
				sx={{
					borderRadius: 'var(--borderRadius, 4px)',
					background: '#E8FB0A',
					color: '#000',
					fontSize: '15px',
					fontWeight: 500,
					lineHeight: '26px' /* 173.333% */,
					letterSpacing: '0.46px',
					textTransform: 'capitalize',
					'&:hover': {
						background: '#a2af07 ',
					},
				}}
			>
				{title}
			</Button>
		</MuiThemeProviderComponent>
	</>
);

export const SecondaryButton = ({ title, ...props }) => (
	<Button variant='outlined' {...props}>
		{title}
	</Button>
);

export const PrimaryIconButton = ({ icon, ...props }) => (
	<MuiThemeProviderComponent>
		<Button
			variant='contained'
			{...props}
			sx={{
				display: 'flex',
				width: '55px',
				height: '38px',
				borderRadius: 'var(--borderRadius, 4px)',
				padding: 'var(--1, 8px) 22px',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 'var(--none, 0px)',
			}}
		>
			{icon}
		</Button>
	</MuiThemeProviderComponent>
);

export const FloatingButton = ({ icon, title, ...props }) => (
	<MuiThemeProviderComponent>
		<Fab
			{...props}
			variant='extended'
			size='medium'
			color='secondary'
			enterDelay
			sx={{
				position: 'fixed',
				bottom: '5%',
				right: '2%',
				zIndex: '1000',
			}}
		>
			{icon}
			{title}
		</Fab>
	</MuiThemeProviderComponent>
);

// Headings

export const Heading1 = ({ title }) => (
	<Typography
		sx={{
			color: '#333',
			fontSize: '30px',
			fontStyle: 'normal',
			fontWeight: '500',
			lineHeight: '123.5%',
			letterSpacing: '0.25px',
			paddingBottom: '16px',
		}}
		variant='h1'
	>
		{title}
	</Typography>
);

// Dropdowns
export const SWDropdown = ({
	label,
	id,
	list,
	disabled,
	onChange,
	onInputChange,
	buttonTitle,
	buttonOnClick,
}) => (

		<Autocomplete
			id={id}
			disablePortal
			disableClearable
			freeSolo
			size='small'
			onChange={onChange}
			onInputChange={onInputChange}
			disabled={disabled}
			options={list}
			renderInput={(params) => (
				<TextField
					sx={{
						width: ' 184px',
						height: '42px',
					}}
					{...params}
					label={label}
					variant='outlined'
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<InputAdornment position='end'>
								<Button
									onClick={buttonOnClick}
									disabled={disabled}
									disableElevation
									variant='contained'
									color='secondary'
									sx={{
										marginRight: '-8px',
										height: '40px',
									}}
								>
									{buttonTitle}
								</Button>
							</InputAdornment>
						),
					}}
				/>
			)}
		/>

);

// Inputs

export const SWTableInput = ({ title, value, onChange, min,type, id, defaultValue }) => {
	return (

			<div className='flex flex-col'
			>
				<label className='mb-1' htmlFor={id}>
					<p
						style={{
							color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 400,
							letterSpacing: '0.15px',
							paddingBottom: 0,
							backgroundColor: "inherit"
						}}
					>
						{title}
					</p>
				</label>
				<input
					style={{
						color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
						fontStyle: 'normal',
						fontWeight: 400,
						letterSpacing: '0.15px',
						outline: 'none',
						border: "none",
						marginTop: '-10px',
						marginBottom: '10px',
						backgroundColor: "inherit",
					}}
					id={id}
					defaultValue={defaultValue ? defaultValue : value}
					value={value}
					placeholder='-'
					onChange={onChange}
					min={min}
					type={type}
					onWheel={(e) => e.target.blur()} 

				/>
			</div>

	);
};

export const SWTextarea = ({ title, value, onChange, rows, id, defaultValue }) => {
	return (

			<div className='flex flex-col'
			>
				<label className='mb-1' htmlFor={id}>
					<p
						style={{
							color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 400,
							letterSpacing: '0.15px',
							paddingBottom: 0,
							backgroundColor: "inherit"
						}}
					>
						{title}
					</p>
				</label>
				<textarea
					style={{
						color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
						fontStyle: 'normal',
						fontWeight: 400,
						letterSpacing: '0.15px',
						outline: 'none',
						border: "none",
						marginTop: '-10px',
						marginBottom: '10px',
						backgroundColor: "inherit",
						resize: "none"
					}}
					id={id}
					defaultValue={defaultValue ? defaultValue : value}
					value={value}
					rows={rows}
					placeholder='-'
					onChange={onChange}
				/>
			</div>

	);
};


export const SWDescriptionInput = ({ title, id, value, onChange, min, ...props }) => {
	return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<label className='mb-1' htmlFor={id}>
					<Typography
						sx={{
							color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 400,
							letterSpacing: '0.15px',
							paddingBottom: 0,
						}}
					>
						{title}
					</Typography>
				</label>
				<TextField
					size='small'
					variant='standard'
					InputProps={{
						disableUnderline: true,
					}}
					sx={{
						color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
						fontStyle: 'normal',
						fontWeight: 400,
						letterSpacing: '0.15px',
					}}
					id={id}
					value={value}
					placeholder='-'
					onChange={onChange}
					{...props}
				/>
			</Box>
	);
};

// Memoized to fix the lag
// export const SWDescriptionInput = React.memo(DescriptionInput, (prev, next) => {
// 	return (
// 		prev.value === next.value &&
// 		prev.id === next.id &&
// 		prev.title === next.title
// 	);
// });


const TableAutocomplete = ({ title, id, value, onChange, list, onInputChange }) => {
	const [isFocused, setIsFocused] = useState(false);
	const [open, setOpen] = useState(false);

	return (

			<Box
				sx={{
					display: 'block',
				}}
			>
				<label className='mb-1' htmlFor={id}>
					<Typography
						sx={{
							color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 400,
							letterSpacing: '0.15px',
							// paddingBottom: 'px',
						}}
					>
						{title}
					</Typography>
				</label>
				<Autocomplete
					id={id}
					disableClearable
					freeSolo
					size='small'
					onChange={onChange}
					onInputChange={onInputChange}
					options={list}
					value={value}
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					renderInput={(params) => (
						<TextField
							id={id}
							variant='standard'
							sx={{
								color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
								fontStyle: 'normal',
								fontWeight: 400,
								letterSpacing: '0.15px',
								background: isFocused ? 'white' : 'inherit',
								paddingLeft: isFocused ? '4px' : '0.5px',
							}}
							{...params}
							InputProps={{
								...params.InputProps,
								disableUnderline: true,
								onFocus: () => {
									setIsFocused(true);
									setOpen(true);
								},
								onBlur: () => {
									setIsFocused(false);
									setOpen(false);
								},
							}}
							placeholder='-'
							inputProps={{
								...params.inputProps,
								onFocus: () => {
									setIsFocused(true);
								},
								onBlur: () => {
									setIsFocused(false);
								},
							}}
						/>
					)}
				/>
			</Box>

	);
};

// Memoized to fix the lag
export const SWTableAutocomplete = React.memo(
	TableAutocomplete,
	(prev, next) => {
		return (
			prev.value === next.value &&
			prev.id === next.id &&
			prev.title === next.title
		);
	}
);
// Images
export const SWTableImage = ({ src, height, width, ...props }) => {
	const [loading, setLoading] = useState(true);

	return (
		<>
			{loading && (
				<Skeleton variant='rectangular' height={height} width={width} />
			)}
			<img
				src={src}
				alt='product'
				loading='lazy'
				onLoad={() => setLoading(false)}
				style={{
					height: height,
					width: width,
					objectFit: 'contain',
					visibility: loading ? 'hidden' : 'visible',
				}}
				{...props}
			/>
		</>
	);
};

// Modal

export const SWModal = ({
	title,
	open,
	handleClose,
	acceptButtonTitle,
	acceptButtonOnClickFunction,
	rejectButtonTitle,
	children,
}) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			style={{
				position: 'absolute',
				marginLeft: 'auto',
				marginTop: 'auto',
				marginRight: 'auto',
				marginBottom: 'auto',
				maxWidth: 'max-content',
				maxHeight: 'fit-content',
			}}
		>
			<div className='h-full w-full bg-white shadow-2xl p-4'>
				<Typography id='modal-modal-title' variant='h6' component='h2'>
					{title}
				</Typography>
				<Typography id='modal-modal-description' sx={{ mt: 2 }}>
					{children}
				</Typography>
				<div className='flex justify-center gap-5 items-center'>
					<Button
						onClick={acceptButtonOnClickFunction}
						color='success'
						variant='contained'
					>
						{acceptButtonTitle}
					</Button>
					<Button
						onClick={handleClose}
						color='error'
						variant='contained'
					>
						{rejectButtonTitle}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
