import { useState, useEffect, Fragment, useMemo, useContext, useRef } from 'react';
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_ShowHideColumnsButton,
} from 'material-react-table';
import { Backdrop, Box, Modal } from '@mui/material';
import { transformDataByItem } from '../../../../utils/utilFunctions';
import { MuiThemeProviderComponent } from '../../../../components/theme/ThemeContext';
import {
	PrimaryButton,
	SWTableAutocomplete,
	SWTableInput,
	SWTextarea,
	SWDescriptionInput,
	FloatingButton
} from '../../../../components/branded-elements/Elements';
import TableImageData from './table-cell-components/TableImageData';
import TableSupplementaryImageData from './table-cell-components/TableSupplementaryImageData';
import updateColumnValue from '../utils/updateAttribute';
import axios from 'axios';
import { InputAdornment } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { OrganizationContext } from '../../../../components/App';
import getProductCategoryForPricing from '../utils/pricePrediction/predictPrice';
import adjustedConditionForPrice from '../utils/pricePrediction/itemConditionAdjustor';
import ListingPreview from '../../pdpPreview/ListingPreview';

// Options for the condition dropdown
function getOptionsValues(store, userID, template) {
	if (
		userID === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1' ||
		userID === '5APyYorrgoZ8uKOcxp5YMz3J0zq1'
	) {
		return [
			'Excellent',
			'Very good',
			'Like New',
			'New with tags',
			'New without tags',
			'Good',
			'Play Condition',
		];
	} else if (template === 'vintage' && userID !== 'r8jkmnica3dJXGLyiIFbItEgZvr2') {
		return ['Good', 'Like New', 'Brand New with Tags', 'Distressed'];
	} else if (store === 'piece' || store === 'tiny' || store === 'tokki') {
		return [
			'Excellent Used Condition',
			'Very Good Used Condition',
			'Like New',
			'Brand New with Tags',
			'New without Tags',
			'Good Used Condition',
			'Play Condition',
			// "Fair Used Condition"
		];
	} else if (userID === 'r8jkmnica3dJXGLyiIFbItEgZvr2') {
		return [
			'Excellent Vintage Condition',
			'Good Vintage Condition',
			'Like New',
			'Distressed',
		];
	} else if (store === 'bontot') {
		return [
			'Very Good',
			'Good',
			'Like New',
			'Brand New with Tags',
			'New without Tags',
		];
	} else if (store === 'royal') {
		return [
			'Excellent',
			'Very Good',
			'Good',
			'Play',
			'Litte Extras',
			'Brand New with Tags',
			'Brand New without Tags',
		];
	} else if (store === 'loose') {
		return ['Like New', 'Good', 'Distressed'];
	} else {
		return [
			'Excellent Used Condition',
			'Very Good Used Condition',
			'Like New',
			'Brand New with Tags',
			'New without Tags',
			'Good Used Condition',
			'Play Condition',
			// "Fair Used Condition"
		];
	}
}

var sizes = ["XS", "XXS", "S", "M", "L", "XL", "XXL"];

// Utilized for description
const backendClient = axios.create({
	baseURL: 'https://apif.snapwrite.ca/',
});

/****************** Table View ****************/

const TableView = ({
	projectData,
	brandAutocompleteOptions,
	taxonomyAutocompleteOptions,
	template,
	ActionToolbar,
	setIsActionItemsDisabled,
	setCheckedItemsIndicesArray,
	setSelectedProducts,
	sharedWith,
	propData,
}) => {
	const { organization } = useContext(OrganizationContext);
	const name = organization?.name
	const id = organization?.id
	const conditionOptions = getOptionsValues(name, id, template);

	//Utilized for rendering the table
	const transformedData = transformDataByItem(projectData);
	// State for each input field
	const userId = projectData.userId || projectData.userID;
	const store = projectData.store;
	const projectId = projectData._id;
	const [rowSelection, setRowSelection] = useState([]);
	const [itemName, setItemName] = useState(projectData.itemName);	
	const [taxonomyUpdatedType, setTaxonomyUpdatedType] = useState(
		projectData.taxonomyUpdatedType
	);

	const [gender, setGender] = useState(projectData.gender);
	const [openPreviewModal, setOpenPreviewModal] = useState(null);

	const getColors = () => {
		if (template === "new-clothes") {
			return projectData.colors.map(color => Array.isArray(color) ? color : [color]);
		} else {
			return projectData.colors || Array(projectData.images.length).fill('');
		}
	};
	
	const [colors, setColors] = useState(getColors());
	const [vendor, setVendor] = useState(projectData.vendor);
	const [sku, setSku] = useState(projectData.sku);
	const [size, setSize] = useState(projectData.size);
	const [sizeNotes, setSizeNotes] = useState(projectData.sizeNotes);
	const [conditionDescription, setConditionDescription] = useState(
		projectData.conditionDescription
	);
	const [pitToPitMeasure, setPitToPitMeasure] = useState(
		projectData.pitToPitMeasure
	);
	const [shoulderMeasure, setShoulderMeasure] = useState(
		projectData.shoulderMeasure
	);
	const [lengthMeasure, setLengthMeasure] = useState(
		projectData.lengthMeasure
	);
	// const [cost, setCost] = useState(projectData.cost);
	// const [price, setPrice] = useState(projectData.price);
	const [material, setMaterial] = useState(projectData.material);
	const [design, setDesign] = useState(projectData.design);
	const [fastening, setFastening] = useState(projectData.fastening);
	const [style, setStyle] = useState(projectData.style);
	const [description, setDescription] = useState(projectData.description);
	const [bottomInSeam, setBottomInSeam] = useState(projectData.bottomInSeam);
	const [brand, setBrand] = useState(projectData.brand);
	const [condition, setCondition] = useState(projectData.condition);
	const [images, setImages] = useState(projectData.images);
	const [supplementaryImages, setSupplementaryImages] = useState(
		projectData.supplementaryImages
	);
	const [waistMeasure, setWaistMeasure] = useState(projectData.waistMeasure);
	const [riseMeasure, setRiseMeasure] = useState(projectData.riseMeasure);
	const [thighMeasure, setThighMeasure] = useState(projectData.thighMeasure);
	const [legOpening, setLegOpening] = useState(projectData.legOpening);
	const [hipMeasure, setHipMeasure] = useState(projectData.hipMeasure);
	const [price, setPrice] = useState(projectData.price || Array(projectData.images.length).fill(''));
	const [costPerItem, setCostPerItem] = useState(projectData.cost || Array(projectData.images.length).fill(''));

	// Clients with custom functionalities
	const leNinety = ["lUrOR5b9i9TYSNJ4zEfGRTkoPqs1", "WbahZZa5HhfWAa6SyJLYnl31kMG3"].includes(userId)


	const sizeRef = useRef(projectData.size)

	let tableLength = projectData.images.length;

	const [heelHeightMeasure, setHeelHeightMeasure] = useState(
		'heelHeightMeasure' in projectData
			? projectData.heelHeightMeasure
			: Array(tableLength).fill('')
	);
	const [soleMeasure, setSoleMeasure] = useState(
		'soleMeasure' in projectData
			? projectData.soleMeasure
			: Array(tableLength).fill('')
	);
	const [inSoleMeasure, setInSoleMeasure] = useState(
		'inSoleMeasure' in projectData
			? projectData.inSoleMeasure
			: Array(tableLength).fill('')
	);
	const [shoeWidth, setShoeWidth] = useState(
		'shoeWidth' in projectData
			? projectData.shoeWidth
			: Array(tableLength).fill('')
	);

	const [itemLength, setItemLength] = useState(projectData.length);
	const [era, setEra] = useState(projectData.era);
	const [types, setTypes] = useState(projectData.types);
	const [neckline, setNeckline] = useState(projectData.neckline);
	const [sleeves, setSleeves] = useState(projectData.sleeves);
	const [cut, setCut] = useState(projectData.cut);
	const [lining, setLining] = useState(projectData.lining);
	const [layers, setLayers] = useState(projectData.layers);
	const [pattern, setPattern] = useState(projectData.pattern);
	const [skirt, setSkirt] = useState(projectData.skirt);
	const [rise, setRise] = useState(projectData.rise);
	const [heelHeight, setHeelHeight] = useState(projectData.heelHeight);
	const [heelShape, setHeelShape] = useState(projectData.heelShape);
	const [height, setHeight] = useState(projectData.height);
	const [toe, setToe] = useState(projectData.toe);
	const [sleeveInSeam, setSleeveInSeam] = useState(projectData.sleeveInSeam);
	const [madeIn, setMadeIn] = useState(projectData.madeIn);
	const [columnVisibility, setColumnVisibility] = useState(() => {
		// Retrieve the value from localStorage if it exists, otherwise use the default values
		const storedVisibility = localStorage.getItem('columnVisibility');
		let visibility = storedVisibility && storedVisibility !== 'undefined'
			? JSON.parse(storedVisibility)
			: {
				itemName: true,
				colors: true,
				length: true,
				cost: true,
				attributes: true,
				description: true,
				concerns: true
			};
		visibility.concerns = sharedWith?.length > 0? true : false;
		return visibility;
	});

	let tempConcerns = [""];
	if (Array.isArray(projectData.images)) {
		tempConcerns = Array(projectData.images.length).fill("");
	}
	if (("concerns" in projectData)) {
		tempConcerns = projectData.concerns
	}
	const [concerns, setConcerns] = useState(tempConcerns)
	// Update localStorage whenever columnVisibility changes
	useEffect(() => {
		localStorage.setItem(
			'columnVisibility',
			JSON.stringify(columnVisibility)
		);
	}, [columnVisibility]);

	const [itemGenericType] = useState(
		projectData.itemGenericType.map((item) => item.toLowerCase())
	);
	// console.log(projectData)
	// const [itemGenericType] = useState(
	// 	Array.isArray(projectData.itemGenericType) 
	// 		? projectData.itemGenericType.map((item) => item.toLowerCase())
	// 		: []
	// );

	const [focusedDescriptionBlockIndex, setFocusedDescriptionBlockIndex] =
		useState(NaN);

	const updateCost = (index, price, vendor) => {
		if (
			userId === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1' ||
			userId === '5APyYorrgoZ8uKOcxp5YMz3J0zq1'
		) {
			let cost = price * 0.25;
			if (vendor.toLowerCase() === 'cm01') {
				cost = price;
			} else if (vendor.includes('*')) {
				cost = price * 0.35;
			}
			cost = Math.round((parseFloat(cost) + Number.EPSILON) * 100) / 100;
			if (cost !== costPerItem[index]) {
				handleBackedUpdate(cost, 'cost', index);
				handleStatesUpdate(cost, 'cost', index);
			}
		} 
	};

	const updatePrice = (index, cost) => {
		// //PBP
		if (
			userId === 'CXNDhrE6FfNRdXNm6nUQlmck29f2' ||
			userId === 'xHaYXalwv8X8RpBLZju0PaQlWW23'
		) {
			const price = cost * 2;
			handleBackedUpdate(price, 'price', index);
			handleStatesUpdate(price, 'price', index);
		}
	};


	const attributeItems = [
		{
			name: 'style',
			label: 'Style',
			value: style,
			categories: [
				'dresses',
				'jackets',
				'pant',
				'upper',
				'skirt',
				'footwear',
				'dress',
				'tops',
				'top',
				'tshirt',
				't-shirt',
				'tee',
				't-shirts',
				'sweatshirts',
				'sweatshirt', 'outerwear'

			],
		},
		{
			name: 'sleeves',
			value: sleeves,
			label: 'Sleeves',
			categories: ['dresses', 'sleepwear', 'overalls', 'upper', 'tops',
			'top',
			'tshirt',
			't-shirt',
			'tee',
			't-shirts',
			'sweatshirts',
			'sweatshirt'],
		},
		{
			name: 'cut',
			value: cut,
			label: 'Cut',
			categories: ['dresses', 'sleepwear', 'overalls', 'upper', 'tops',
			'top',
			'tshirt',
			't-shirt',
			'tee',
			't-shirts',
			'sweatshirts',
			'sweatshirt'],
		},
		{
			name: 'fastening',
			value: fastening,
			label: 'Fastening Type',
			categories: ['dresses', 'jackets', 'overalls', 'upper', 'tops',
			'top',
			'tshirt',
			't-shirt',
			'tee',
			't-shirts',
			'sweatshirts',
			'sweatshirt', 'outerwear'	
		],
		},
		{
			name: 'length',
			value: itemLength,
			label: 'Length',
			categories: ['dresses', 'jackets', 'pants', 'sleepwear', 'skirts', 'outerwear'],
		},
		{
			name: 'skirt',
			value: skirt,
			label: 'Skirt',
			categories: ['dresses'],
		},
		{
			name: 'neckline',
			value: neckline,
			label: 'Neckline',
			categories: ['dresses', 'overalls', 'upper', 'tops',
			'top',
			'tshirt',
			't-shirt',
			'tee',
			't-shirts',
			'sweatshirts',
			'sweatshirt'],
		},
		{
			name: 'design',
			value: design,
			label: 'Design',
			categories: ['footwear', 'outerwear'],
		},
		{
			name: 'heelHeight',
			value: heelHeight,
			label: 'Heel Height',
			categories: ['footwear'],
		},
		{
			name: 'heelShape',
			value: heelShape,
			label: 'Heel Shape',
			categories: ['footwear'],
		},
		{
			name: 'height',
			value: height,
			label: 'Height',
			categories: ['footwear'],
		},
		{
			name: 'material',
			value: material,
			label: 'Material',
			categories: [],
			condition: () => true
		},
		{
			name: 'madeIn',
			value: madeIn,
			label: 'Made In',
			categories: [],
			condition: () =>
				userId === 'VPQROHGh7pNC9du2JnL4fIopeoh1' ||
				userId === 't4gFVUQejYQYBY6dhwOp2mkYMTe2' ||
				userId === 'WbahZZa5HhfWAa6SyJLYnl31kMG3' ||
				template === 'vintage',
		},
	];

	const renderAttributeInputs = (index) => {

		const currentTaxonomy = taxonomyUpdatedType[index]?.toLowerCase() || "";

		return attributeItems.map((attribute, i) => {
			// Check if taxonomyUpdatedType[index] is present in genres

			const shouldRender = attribute.categories.includes(currentTaxonomy) || (attribute.condition && attribute.condition()) || (attribute.name === 'material' && attribute.value[index] !== '');

			// Render the SWTableInput only if shouldRender is true
			return shouldRender ? (
				<SWTableInput
					key={`${attribute.name}-${index}`}
					id={`${attribute.label}-${index}`}
					title={attribute.label}
					defaultValue={attribute.value[index]}
					onChange={(e) => {
						debouncedhandleStatesUpdate(
							e.target.value,
							attribute.name,
							index
						);
						handleBackedUpdate(
							e.target.value,
							attribute.name,
							index
						)

						updateCost(
							index,
							price[index],
							e.target.value
						);
					}}
				/>
			) : null;
		});
	};

	const measurementTypesWithDefaultValue = [
		'Rompers',
		'Swimwear',
		'Sleepwear',
		'Christmas',
	];

	const measurementProperties = [
		{
			type: ['Bottoms', 'Pants'],
			components: [
				{ name: 'waistMeasure', label: 'Waist', value: waistMeasure },
				{ name: 'riseMeasure', label: 'Rise', value: riseMeasure },
				{ name: 'thighMeasure', label: 'Thigh', value: thighMeasure },
				{ name: 'legOpening', label: 'Leg Opening', value: legOpening },
				{ name: 'hipMeasure', label: 'Hips', value: hipMeasure },
				{ name: 'bottomInSeam', label: 'Inseam', value: bottomInSeam },
				{
					name: 'lengthMeasure',
					label: 'Length',
					value: lengthMeasure,
				},
			],
		},
		{
			type: ['footwear', 'Footwear', 'shoes', 'Shoes', 'Shoe', 'shoe', ],
			components: [
				{
					name: 'heelHeightMeasure',
					label: 'Heel Height',
					value: heelHeightMeasure,
				},
				{ name: 'soleMeasure', label: 'Sole', value: soleMeasure },
				{
					name: 'inSoleMeasure',
					label: 'Insole',
					value: inSoleMeasure,
				},
				{
					name: 'shoeWidth',
					label: 'width',
					value: shoeWidth,
				},
			],
		},
		{
			type: [
				'tops',
				'outerwear',
				't-shirt',
				'longsleeve',
				'sweatshirt',
				'sweater',
				...measurementTypesWithDefaultValue,
			],
			components: [
				{
					name: 'pitToPitMeasure',
					label: 'Pit to Pit',
					value: pitToPitMeasure,
				},
				{ name: 'sleeveInSeam', label: 'In Seam', value: sleeveInSeam },
				{
					name: 'shoulderMeasure',
					label: 'Shoulder',
					value: shoulderMeasure,
				},
				{
					name: 'lengthMeasure',
					label: 'Length',
					value: lengthMeasure,
				},
			],
		},
		{
			type: ['Dresses', 'dress'],
			components: [
				{
					name: 'pitToPitMeasure',
					label: 'Pit to Pit',
					value: pitToPitMeasure,
				},
				{ name: 'waistMeasure', label: 'Waist', value: waistMeasure },
				{ name: 'hipMeasure', label: 'Hips', value: hipMeasure },
				{
					name: 'lengthMeasure',
					label: 'Length',
					value: lengthMeasure,
				},
			],
		},{
			type: ['Accessories', 'Accessory', 'accessories', 'Accessory'],
			components: [
				{
					name: 'height',
					label: 'Height',
					value: height,
				},
				{
					name: 'lengthMeasure',
					label: 'Length',
					value: lengthMeasure,
				},
				{
					name: 'shoeWidth',
					label: 'Width',
					value: shoeWidth,
				}
			],
		}
	];

	const handleStatesUpdate = (value, key, index) => {
		// key to determine which state update function to call
		const stateUpdateFunctions = {
			itemName: setItemName,
			taxonomyUpdatedType: setTaxonomyUpdatedType,
			vendor: setVendor,
			sku: setSku,
			gender: setGender,
			colors: setColors,
			size: setSize,
			conditionDescription: setConditionDescription,
			sizeNotes: setSizeNotes,
			pitToPitMeasure: setPitToPitMeasure,
			shoulderMeasure: setShoulderMeasure,
			lengthMeasure: setLengthMeasure,
			cost: setCostPerItem,
			price: setPrice,
			material: setMaterial,
			design: setDesign,
			fastening: setFastening,
			style: setStyle,
			description: setDescription,
			bottomInSeam: setBottomInSeam,
			brand: setBrand,
			condition: setCondition,
			length: setItemLength,
			waistMeasure: setWaistMeasure,
			hipMeasure: setHipMeasure,
			riseMeasure: setRiseMeasure,
			thighMeasure: setThighMeasure,
			legOpening: setLegOpening,
			sleeveInSeam: setSleeveInSeam,
			heelHeight: setHeelHeight,
			itemLength: setItemLength,
			heelHeightMeasure: setHeelHeightMeasure,
			soleMeasure: setSoleMeasure,
			inSoleMeasure: setInSoleMeasure,
			shoeWidth: setShoeWidth,
			era: setEra,
			types: setTypes,
			neckline: setNeckline,
			sleeves: setSleeves,
			cut: setCut,
			lining: setLining,
			layers: setLayers,
			pattern: setPattern,
			skirt: setSkirt,
			rise: setRise,
			heelShape: setHeelShape,
			height: setHeight,
			toe: setToe,
			madeIn: setMadeIn,
			concerns: setConcerns
		};

		// Call the appropriate state update function
		const updateFunction = stateUpdateFunctions[key];
		if (updateFunction) {
			updateFunction((prev) => {
				
				const newState = [...prev];
				newState[index] = value;
				return newState;
			});
			
		} else {
			console.error('No state update function found for key', key);
		}
	};

	const handleBackedUpdate = async (value, key, index) => {
		const singleProductModelFilter = {userID: userId, sku: sku[index]};

		// Update the database
		updateColumnValue(
			singleProductModelFilter, // filter
			template, // template
			key, // state
			value, // event
			projectId, // id
			index, // index
			key // key
		);
	};
	let timer;
	function debouncedhandleStatesUpdate(value, key, index) {
		clearTimeout(timer); 		
		timer = setTimeout(() => {			
			handleStatesUpdate(value, key, index);				
		}, 1000); 	
	}

	// Generate Description Button
	const generateDescription = async (index) => {
		const previousDescription = description;

		let tags = [];

		const setDescriptionTags = () => {
			tags = [
				`Brand: ${brand[index] || ''}`,
				`${itemName[index] || ''}`,
				`Item Type: ${types[index] || ''}`,
				`Era: ${era[index] || ''}`,
				`Color: ${colors[index] || ''}`,
				`Size: ${size[index] || ''}`,
				`Material: ${material[index] || ''}`,
				`Neckline: ${neckline[index] || ''}`,
				`${style[index] || ''}`,
				`${sleeves[index] || ''}`,
				`${cut[index] || ''}`,
				`${fastening[index] || ''}`,
				`${lining[index] || ''}`,
				`${layers[index] || ''}`,
				`${pattern[index] || ''}`,
				`${skirt[index] || ''}`,
				`${rise[index] || ''}`,
				`${design[index] || ''}`,
			];

			if (
				taxonomyUpdatedType[index] &&
				taxonomyUpdatedType[index].toLowerCase().includes('footwear')
			) {
				tags = [
					`${itemGenericType[index] || ''}`,
					`${itemName[index] || ''}`,
					`Brand: ${brand[index] || ''}`,
					`Item Type: ${types[index] || ''}`,
					`Gender: ${gender[index] || ''}`,
					`Color: ${colors[index] || ''}`,
					`Age group or size: ${size[index] || ''}`,
					`${material[index] || ''}`,
					`${style[index] || ''}`,
					`${rise[index] || ''}`,
					`${design[index] || ''}`,
					`${heelHeight[index] || ''}`,
					`${heelShape[index] || ''}`,
					`${height[index] || ''}`,
					`${toe[index] || ''}`,
				];
			}
		};

		try {
			setDescriptionTags();
			setDescription((previousDescription) => {
				const updatedDescription = [...previousDescription];
				updatedDescription[index] = 'Loading...';
				return updatedDescription;
			});

			const endpoint =
				description[index] === ''
					? '/clothingDescription'
					: '/regen_description';
			const requestData =
				description[index] === ''
					? { tags: tags, user: 'VPQROHGh7pNC9du2JnL4fIopeoh1' }
					: { description: description[index] };

			const res = await backendClient.post(endpoint, requestData);

			let result = res?.data || '';
			

			if (result !== '') {
				result = result.includes('"')
					? result.replace(/"/g, "'")
					: result;
				result = result.trim()
				handleBackedUpdate(result, 'description', index);
				handleStatesUpdate(result, 'description', index);
			}
		} catch (err) {
			handleBackedUpdate(previousDescription[index], 'description', index);
			handleStatesUpdate(previousDescription[index], 'description', index);
			console.error(err);
		}
	};

	const predictPrice = async (
		index,
		currentCondition,
		currentBrand,
		currentType,
		currentSize,
		currentItemName,
		currentVendor
	) => {
		let productCategory = 'Other';
		if (currentItemName !== '') {
			//   console.log(currentItemName)
			productCategory = getProductCategoryForPricing(
				currentItemName,
				currentType
			);
		}

		if (
			currentBrand !== '' &&
			currentCondition !== '' &&
			currentItemName !== '' &&
			currentSize !== '' &&
			currentType !== ''
		) {
			try {
				let adjustedCondition =
					adjustedConditionForPrice(currentCondition);
				//condition = ['Excellent', 'Very Good', 'New without Tags', 'Good' 'Play', 'Brand New with Tags']
				let adjustedSize = currentSize.toUpperCase();

				if (
					adjustedSize.includes('MOS') ||
					adjustedSize.includes('MONTHS')
				) {
					if (
						currentSize.includes('0-3') ||
						currentSize.includes('3-6') ||
						currentSize.includes('3-6')
					) {
						adjustedSize = '0-6MOS';
					} else if (
						currentSize.includes('6-9') ||
						currentSize.includes('9-12') ||
						currentSize.includes('6-12')
					) {
						adjustedSize = '6-12MOS';
					} else if (
						currentSize.includes('12') ||
						currentSize.includes('12-18')
					) {
						adjustedSize = '12-18MOS';
					} else if (
						currentSize.includes('18') ||
						currentSize.includes('18-24') ||
						currentSize.includes('24')
					) {
						adjustedSize = '18-24MOS';
					}
				} else if (
					adjustedSize.includes('T') ||
					adjustedSize.includes('YEARS') ||
					adjustedSize.includes('Y') ||
					adjustedSize.includes('YOUTH')
				) {
					if (currentSize.includes('12')) {
						adjustedSize = '12 YOUTH + UP';
					} else if (currentSize.includes('11')) {
						adjustedSize = '11 YOUTH';
					} else if (currentSize.includes('10')) {
						adjustedSize = '10 YOUTH';
					} else if (currentSize.includes('9')) {
						adjustedSize = '9 YOUTH';
					} else if (currentSize.includes('8')) {
						adjustedSize = '8 YOUTH';
					} else if (currentSize.includes('7')) {
						adjustedSize = '7 YOUTH';
					} else if (currentSize.includes('6')) {
						adjustedSize = '6 YOUTH';
					} else if (currentSize.includes('5')) {
						adjustedSize = '5T';
					} else if (currentSize.includes('4')) {
						adjustedSize = '4T';
					} else if (currentSize.includes('3')) {
						adjustedSize = '3T';
					} else if (currentSize.includes('2')) {
						adjustedSize = '2T';
					}
				}

				const predictedData = {
					price: null,
					cost: null,
				};

				let adjustedType = currentType;
				let adjustedProductCategory = productCategory;

				if (currentType === 'Dresses' || currentType === 'Skirts') {
					adjustedType = 'Dresses + Skirts';
				} else if (
					currentType === 'Accessories' ||
					currentType === 'Hats'
				) {
					adjustedType = 'Accessories';
				}

				if (adjustedType === 'Toys') {
					adjustedProductCategory = 'Toy';
				}

				let predictedPrice = await backendClient.post(
					'/get-price-prediction',
					{
						condition: adjustedCondition,
						brand: currentBrand,
						type: adjustedType,
						size: adjustedSize,
						productCategory: adjustedProductCategory,
					}
				);

				if (predictedPrice.data) {
					predictedPrice = parseFloat(predictedPrice.data);
				}

				// send save request
				if (predictedPrice) {

					predictedData.price = Math.round(predictedPrice);
					handleStatesUpdate(
						Math.round(predictedPrice),
						'price',
						index
					);
					handleBackedUpdate(
						Math.round(predictedPrice),
						'price',
						index
					);

					let cost = predictedPrice * 0.25;
					if (currentVendor.toLowerCase() === 'cm01') {
						cost = Math.round(predictedPrice);
					} else if (currentVendor.includes('*')) {
						cost = predictedPrice * 0.35;
					}
					cost =
						Math.round((parseFloat(cost) + Number.EPSILON) * 100) /
						100;
					if (cost !== costPerItem[index]) {
						console.log('cost: ', cost, ' for index: ', index);
						handleStatesUpdate(cost, 'cost', index);
						handleBackedUpdate(cost, 'cost', index);
					}
				}
			} catch (err) {}
		}
	};

	const colorInput = (index) => {
		const addColorInput = (index) => {
			setColors((prevColors) => {
				const newColors = [...prevColors];
				newColors[index] = [...newColors[index], ''];
				return newColors;
			});
		};

		return template === 'new-clothes' ? (
			<div>
				<div className='flex flex-col'>
					<label className='mb-1' htmlFor={index}>
						<p
							style={{
								color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
								fontSize: '12px',
								fontStyle: 'normal',
								fontWeight: 400,
								letterSpacing: '0.15px',
								paddingBottom: 0,
								backgroundColor: 'inherit',
							}}
						>
							Colors
						</p>
					</label>
					{colors[index] &&
						colors[index].map((color, idx) => (
							<input
								key={idx}
								style={{
									color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
									fontStyle: 'normal',
									fontWeight: 400,
									letterSpacing: '0.15px',
									outline: 'none',
									border: 'none',
									marginTop: '-10px',
									marginBottom: '10px',
									backgroundColor: 'inherit',
									paddingBottom: '10px',
								}}
								id={`${id}_${idx}`}
								defaultValue={color}
								placeholder='-'
								onChange={(e) => {
									const colorsArrayCopy = [...colors[index]];
									colorsArrayCopy[idx] = e.target.value;
									debouncedhandleStatesUpdate(
										colorsArrayCopy,
										'colors',
										index
									);
									handleBackedUpdate(
										colorsArrayCopy,
										'colors',
										index
									);
								}}
							/>
						))}
				</div>

				<PrimaryButton
					title='Add Variants'
					size='small'
					variant='outlined'
					style={{ fontSize: '11px', backgroundColor: 'transparent' }}
					onClick={() => addColorInput(index)}
				/>
			</div>
		) : (
			<SWTableInput
				title={'Colors'}
				id={`colors-${index}`}
				defaultValue={colors[index]}
				onChange={(e) => {
					debouncedhandleStatesUpdate(
						e.target.value,
						'colors',
						index
					);
					handleBackedUpdate(e.target.value, 'colors', index);
				}}
			/>
		);
	};
	
	const eraInputField = (index) => {
		const leNinetyEraInputFieldOptions = [,"50s", "60s", "70s","80s","90s","2000s"]
		return leNinety || propData.template === 'vintage' ? (
			<SWTableAutocomplete
				title={'Era'}
				id={`era-${index}`}
				value={era[index]}
				list={leNinetyEraInputFieldOptions}
				onChange={(e, value) => {
					handleStatesUpdate(value, 'era', index);
					handleBackedUpdate(value, 'era', index);
				}}
				onInputChange={(e, value) => {
					handleStatesUpdate(value, 'era', index);
					handleBackedUpdate(value, 'era', index);
				}}
			/>
		) : propData.template === 'vintage' ? (
			<SWTableInput
				title={'Era'}
				id={`era-${index}`}
				defaultValue={era[index]}
				onChange={(e) => {
					debouncedhandleStatesUpdate(e.target.value, 'era', index);
					handleBackedUpdate(e.target.value, 'era', index);
				}}
			/>
		) : null;
	};

	const columns = useMemo(
		() => [
			// Hero Image
			{
				accessorKey: 'images',
				header: 'Images',
				enableColumnOrdering: false,
				enableSorting: false,
				enableHiding: false,
				maxSize: 200,
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					const row = cell.row.original;
					const hasConcerns = row.concerns && row.concerns.trim() !== "";
					return (
						<div style={{ backgroundColor: hasConcerns ? '#ffe69c' : 'inherit' }}>
							<TableImageData
								singleProductModelFilter={{userID: userId, sku: sku[index]}}
								name={'Main'}
								index={index}
								images={images}
								attr={'images'}
								mm={false}
								template={template}
								id={projectId}
								setImages={setImages}
							/>
						</div>
					);
				},
			},

			// Supplementary Images
			{
				accessorKey: 'supplementaryImages',
				header: '',
				enableColumnOrdering: false,
				enableSorting: false,
				enableHiding: false,
				maxSize: 200,
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className='flex flex-col'>
							<div>
								<TableSupplementaryImageData
									disabled={false}
									supplementaryImages={supplementaryImages}
									index={index}
									images={images}
									template={template}
									objectID={projectId}
								/>
							</div>

							<PrimaryButton
								title='Preview'
								onClick={() =>{
									setOpenPreviewModal(parseInt(index))
								
								}}
							/>
							
						</div>
					);
				},
			},

			// Information - itemName, taxonomyUpdatedType, Brand, Vendor, SKU
			{
				accessorKey: 'itemName',
				header: 'Information',
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className='flex flex-col gap-2 w-56'>
							<SWTableInput
								fullWidth
								title={'Product Name'}
								id={`itemName-${index}`}
								defaultValue={itemName[index]}
								onChange={(e) => {
									debouncedhandleStatesUpdate(
										e.target.value,
										'itemName',
										index
									);
									handleBackedUpdate(
										e.target.value,
										'itemName',
										index
									);
									if (userId === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1' ||
										userId === '5APyYorrgoZ8uKOcxp5YMz3J0zq1') {
										predictPrice(
											index,
											condition[index],
											brand[index],
											taxonomyUpdatedType[index],
											size[index],
											e.target.value,
											vendor[index]
										);
									}									
								}}
							/>
							<SWTableAutocomplete
								title={'Brand'}
								id={`brand-${index}`}
								value={brand[index]}
								list={brandAutocompleteOptions}
								onChange={(e, value) => {
									handleStatesUpdate(value, 'brand', index);
									handleBackedUpdate(value, 'brand', index);
									if (userId === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1' ||
										userId === '5APyYorrgoZ8uKOcxp5YMz3J0zq1') {
										predictPrice(
											index,
											condition[index],
											value,
											taxonomyUpdatedType[index],
											size[index] === '' ? sizeRef.current[index] : size[index],
											itemName[index],
											vendor[index]
										);
									}
								}}
								onInputChange={(e, value) => {
									handleStatesUpdate(value, 'brand', index);
									handleBackedUpdate(value, 'brand', index);
								}}
							/>

							<div className='flex flex-col gap-5'>
									<SWTableAutocomplete
										title={'Category'}
										id={`taxonomyUpdatedType-${index}`}
										value={taxonomyUpdatedType[index]}
										list={taxonomyAutocompleteOptions}
										onChange={(e, value) => {
											handleStatesUpdate(
												value,
												'taxonomyUpdatedType',
												index
											);
											handleBackedUpdate(
												value,
												'taxonomyUpdatedType',
												index
											);
											if (userId === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1' ||
												userId === '5APyYorrgoZ8uKOcxp5YMz3J0zq1') {
													predictPrice(
														index,
														condition[index],
														brand[index],
														value,
														size[index],
														itemName[index],
														vendor[index]
													);
												}
										}}
										onInputChange={(e, value) => {
											handleStatesUpdate(
												value,
												'taxonomyUpdatedType',
												index
											);
											handleBackedUpdate(
												value,
												'taxonomyUpdatedType',
												index
											)
										}}
									/>
								<SWTableInput
									title={'Vendor'}
									id={`vendor-${index}`}
									defaultValue={vendor[index]}
									onChange={(e) => {
										debouncedhandleStatesUpdate(
											e.target.value,
											'vendor',
											index
										);
										handleBackedUpdate(
											e.target.value,
											'vendor',
											index
										);

										updateCost(
											index,
											price[index],
											e.target.value
										);
									}}
								/>
							</div>

							<SWTableInput
								fullWidth
								title={'SKU'}
								id={`sku-${index}`}
								defaultValue={sku[index]}
								onChange={(e) =>{
									debouncedhandleStatesUpdate(
										e.target.value,
										'sku',
										index
									)
									handleBackedUpdate(
										e.target.value,
										'sku',
										index
									)}
								}
							/>
						</div>
					);
				},
			},
			{
				accessorKey: 'colors',
				header: 'Details',
				enableColumnOrdering: false,
				enableSorting: false,
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className='flex flex-col gap-2 w-56'>
							<div className='flex gap-5 flex-col'>
								{userId==="19BvrgznH1Se16RnM8aTvvW8LDC3" || userId === "NpGB6zItW1OcpizZJbD88VsfKlf2" ? 
									<SWTableInput
										title={'Gender'}
										id={`${'gender'}-${index}`}
										defaultValue={
											gender[
											index
											]
										}
										onChange={(e) => {
											debouncedhandleStatesUpdate(
												e.target.value,
												'gender',
												index
											);
											handleBackedUpdate(
												e.target.value,
												'gender',
												index
											);
										}}
									/>: null}
								{colorInput(index)}
								
								{/* Era  */}
								{eraInputField(index)}
								
								{template !== 'vintage' && (<SWTableInput
									title={'Size'}
									id={`size-${index}`}
									defaultValue={size[index]}
									onChange={(e) => {
										sizeRef.current[index] = e.target.value;
										debouncedhandleStatesUpdate(
											e.target.value,
											'size',
											index
										);
										handleBackedUpdate(
											e.target.value,
											'size',
											index
										);

										if (userId === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1' ||
											userId === '5APyYorrgoZ8uKOcxp5YMz3J0zq1') {
												predictPrice(
													index,
													condition[index],
													brand[index],
													taxonomyUpdatedType[index],
													e.target.value,
													itemName[index],
													vendor[index]
												);
											}
									}}
								/>)}

								{template === 'vintage' && (
									<SWTableAutocomplete
										title={'Size'}
										value={size[index]}
										list={sizes}
										autoComplete={true}
										fullWidth
										onChange={(e, value) => {
											handleStatesUpdate(
												value,
												'size',
												index
											);
											handleBackedUpdate(
												value,
												'size',
												index
											);
										}}

										onInputChange={(e, value) => {
											handleStatesUpdate(
												value,
												'size',
												index
											);
											handleBackedUpdate(
												value,
												'size',
												index
											);
										}}
									/>)

								}

							</div>
							<div className=''>
								<SWTableAutocomplete
									title={'Condition'}
									id={`condition-${index}`}
									value={condition[index]}
									list={conditionOptions}
									autoComplete={true}
									fullWidth
									onChange={(e, value) => {
										handleStatesUpdate(
											value,
											'condition',
											index
										);
										handleBackedUpdate(
											value,
											'condition',
											index
										);
										if (userId === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1' ||
											userId === '5APyYorrgoZ8uKOcxp5YMz3J0zq1') {
												predictPrice(
													index,
													value,
													brand[index],
													taxonomyUpdatedType[index],
													size[index],
													itemName[index],
													vendor[index]
												);
											}
									}}
									onInputChange={(e, value) => {
										handleStatesUpdate(
											value,
											'condition',
											index
										);
										handleBackedUpdate(
											value,
											'condition',
											index
										);
									}}
								/>
							</div>
							<SWTextarea
								rows={4}
								title={'Size Notes'}
								id={`sizeNotes-${index}`}
								defaultValue={sizeNotes[index]}
								onChange={(e) => {
									debouncedhandleStatesUpdate(
										e.target.value,
										'sizeNotes',
										index
									);
									handleBackedUpdate(
										e.target.value,
										'sizeNotes',
										index
									);
								}}
							/>
							<SWTextarea
								rows={4}
								title={'Condition Notes'}
								id={`conditionDescription-${index}`}
								defaultValue={conditionDescription[index]}
								onChange={(e) => {
									debouncedhandleStatesUpdate(
										e.target.value,
										'conditionDescription',
										index
									);
									handleBackedUpdate(
										e.target.value,
										'conditionDescription',
										index
									);
								}}
							/>
						</div>
					);
				},
			},
			{
				accessorKey: 'length',
				header: 'Measurements',

				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className='flex flex-col'>
							{measurementProperties.map((property, i) => {
								if (
									taxonomyUpdatedType[index] &&
									property.type.some((type) =>
										taxonomyUpdatedType[index]
											.toLowerCase()
											.includes(type.toLowerCase())
									)
								) {
									return property.components.map(
										(component, idx) => {
											return (
												<Fragment key={idx}>
													<SWTableInput
														title={component.label}
														id={`${component.name}-${index}`}
														defaultValue={
															component.value[
																index
															]
														}
														onChange={(e) => {
															debouncedhandleStatesUpdate(
																e.target.value,
																component.name,
																index
															);
															handleBackedUpdate(
																e.target.value,
																component.name,
																index
															);
														}}
													/>
												</Fragment>
											);
										}
									);
								}
								return null;
							})}
						</div>
					);
				},
			},
			{
				accessorKey: 'cost',
				header: 'Costs',
				size: 100,
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className=''>
							<SWTableInput
								title={'Cost'}
								id={`cost-${index}`}
								value={costPerItem[index]}
								min={0}
								type='number'
								onChange={(e) => {
									handleStatesUpdate(
										e.target.value,
										'cost',
										index
									);
									handleBackedUpdate(
										e.target.value,
										'cost',
										index
									);
									updatePrice(index, e.target.value);
								}}
							/>
							<SWTableInput
								id={`price-${index}`}
								title={'Price'}
								value={price[index]}
								min={0}
								type='number'
								onChange={(e) => {
									handleStatesUpdate(
										e.target.value,
										'price',
										index
									);
									handleBackedUpdate(
										e.target.value,
										'price',
										index
									);
									updateCost(
										index,
										e.target.value,
										vendor[index]
									);
								}}
							/>
						</div>
					);
				},
			},
			{
				accessorKey: 'attributes',
				header: 'Attributes',
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className='flex flex-col w-56'>
							{renderAttributeInputs(index)}
						</div>
					);
				},
			},
			{
				accessorKey: 'description',
				header: 'Description',
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className='w-56'>
							<SWDescriptionInput
								fullWidth
								multiline
								rows={8}
								title={'Paragraph Description'}
								id={`description-${index}`}
								value={description[index]}
								InputProps={{
									endAdornment:
										focusedDescriptionBlockIndex ===
											index && (
											<InputAdornment
												sx={{
													position: 'absolute',
													right: '5px',
													bottom: '30px',
												}}
												position='end'
											>
												{
													<PrimaryButton
														disableElevation={true}
														title={
															description[index]
																.length ===
															0 ? (
																'Generate'
															) : (
																<ReplayIcon />
															)
														}
														onMouseDown={(e) => {
															e.preventDefault();
														}}
														onClick={(e) => {
															generateDescription(
																index
															);
														}}
													/>
												}
											</InputAdornment>
										),
								}}
								onChange={(e) => {
									handleStatesUpdate(
										e.target.value,
										'description',
										index
									);
									handleBackedUpdate(
										e.target.value,
										'description',
										index
									);
								}}
								onFocus={() =>
									setFocusedDescriptionBlockIndex(index)
								}
								onBlur={() =>
									setFocusedDescriptionBlockIndex(NaN)
								}
								
							/>
						</div>
					);
				},
			},
			{
				accessorKey: 'concerns',
				header: 'Concerns',
				Cell: ({ cell }) => {
					const { index } = cell.row.original;
					return (
						<div className='w-56'>
							<SWTextarea
								rows={8}
								name={'concerns'}
								title={'Product Concerns'}
								defaultValue={concerns[index]}
								onChange={(e) => {
									debouncedhandleStatesUpdate(
										e.target.value,
										'concerns',
										index
									);
									handleBackedUpdate(
										e.target.value,
										'concerns',
										index
									);
								}}
								onFocus={() =>
									setFocusedDescriptionBlockIndex(index)
								}
								onBlur={() => setFocusedDescriptionBlockIndex(NaN)}
							/>
						</div>
					);
				},
			},
		],
		[
			itemName,
			taxonomyUpdatedType,
			gender,
			colors,
			vendor,
			sku,
			size,
			sizeNotes,
			conditionDescription,
			pitToPitMeasure,
			shoulderMeasure,
			lengthMeasure,
			material,
			design,
			fastening,
			style,
			description,
			bottomInSeam,
			brand,
			condition,
			images,
			supplementaryImages,
			waistMeasure,
			riseMeasure,
			thighMeasure,
			legOpening,
			hipMeasure,
			heelHeightMeasure,
			soleMeasure,
			inSoleMeasure,
			shoeWidth,
			itemLength,
			era,
			types,
			neckline,
			sleeves,
			cut,
			lining,
			layers,
			pattern,
			skirt,
			rise,
			heelHeight,
			heelShape,
			height,
			toe,
			sleeveInSeam,
			madeIn,
			brandAutocompleteOptions,
			conditionOptions,
			focusedDescriptionBlockIndex,
			measurementProperties,
			projectId,
			propData,
			taxonomyAutocompleteOptions,
			template,
			brandAutocompleteOptions
		]
	);

	const table = useMaterialReactTable({
		columns,
		data: transformedData,
		enablePagination: false,
		enableDensityToggle: false,
		enableMultiRowSelection: true,
		onRowSelectionChange: setRowSelection,
		enableBottomToolbar: false,
		enableTopToolbar: false,
		enableColumnActions: false,
		enableColumnOrdering: false,
		enableRowSelection: true,
		
		getRowIndex: (originalRow) => originalRow.index,

		enableSorting: false,
		enableColumnFilters: false,
		enableGlobalFilter: false,

		state: { rowSelection, columnVisibility },
		onColumnVisibilityChange: setColumnVisibility,

		muiTableHeadCellProps: {
			sx: {
				fontWeight: '800',
				fontSize: '16px',
			},
		},
		initialState: {
			columnPinning: { left: ['mrt-row-select', 'images'] },
			// columnVisibility: { }
		},

		muiTableHeadProps: ({ table }) => ({
			sx: {
				'& .MuiTableCell-root': {
					fontWeight: '600',
					fontSize: '18px',
					letterSpacing: '0.17px',
					padding: '16px 10px',
					background: 'white',
					boxShadow: 'none',
				},
			},
		}),
		muiTableBodyRowProps: ({ table }) => ({
			sx: {
				'& .MuiTableCell-root': {
					fontWeight: '400',
					fontSize: '16px',
					letterSpacing: '0.17px',
					padding: '16px 10px',
					background: 'white',
					boxShadow: 'none',
				},
			},
		}),
		muiTableBodyCellProps: ({ table }) => ({
			sx: {
				verticalAlign: 'top',
			},
		}),
	});

	useEffect(() => {
		if (table && table.getSelectedRowModel()) {
			const selectedRows = table.getSelectedRowModel().rows;
			setIsActionItemsDisabled(selectedRows.length === 0);
			const selectedRowsData = selectedRows.map((row) => {
				return {
					index: row.original.index,
					objectID: row.original._id,
					imageUrl: row.original.images,
				};
			});
	
			setCheckedItemsIndicesArray(selectedRowsData.map((row) => row.index));
			setSelectedProducts(selectedRowsData);
		}
	}, [rowSelection, table]);

	

	return (
		<MuiThemeProviderComponent>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					border: 'none',
					boxShadow: 'none',
				}}
			>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer }}
					open={typeof openPreviewModal === "number"}
				>

					<Modal
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "fit-content",
							minWidth: "600px",
							border: "2px solid #000",
							boxShadow: 54,
						}}
						open={ typeof openPreviewModal === "number"}
						style={{ backdropFilter: "blur(100%)" }}
					>
						<div className='overflow-auto w-full h-full bg-white p-4'>
							<ListingPreview 
							price={price[openPreviewModal]}
							objectID={projectId}
							template={template}
							index={openPreviewModal}
							/>
							<FloatingButton title={"Close"} onClick={() => setOpenPreviewModal(null)} />
						</div>
					</Modal>
				</Backdrop>

				<Box>
					<ActionToolbar />
				</Box>

				<Box sx={{ display: 'flex' }}>
					<MRT_ShowHideColumnsButton table={table} />
				</Box>
			</Box>
			<MaterialReactTable table={table} />
		</MuiThemeProviderComponent>
	);
};
export default TableView;
