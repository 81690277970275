import React from "react";
import { Header } from "../navigation/header.js";
import PlusIcon from "../../assets/PlusIcon.png";
import "../../css/App.css";
import TableList from "../../products//bulk/shopify/tableList.js";
import Table from "../../products//bulk/shopify/featureTable.js";
import { useEffect, useState } from "react";
import { ContactSupportOutlined, SaveAlt } from "@mui/icons-material";
import { getAuth, signInWithRedirect, signInWithPopup, getRedirectResult, GoogleAuthProvider } from "@firebase/auth";
import SideMenu from "../sideMenu.js";
import { useParams } from "react-router";
import trashCan from "../../assets/trash_can.png";
import { Link } from "react-router-dom";
import axios from 'axios';

import firebase from 'firebase/app';
import 'firebase/auth';
import GoogleSignUpButton from "../googleSignUpButton.js";



const FormSellerView = ({ history }) => {

  
  const [signedIn, setSignedIn] = useState(false)
  const {id} = useParams()
  localStorage.setItem("accountType", "seller")

  // useEffect(() => {
    // const auth = getAuth();
    // const token = localStorage.getItem("token");
  
    // const handleGoogle = async () => {
    //   const provider = new GoogleAuthProvider();
    //   try {
    //     signInWithRedirect(auth, provider);
    //   } catch (error) {
    //     console.error(error);
    //     localStorage.removeItem("token");
    //   }
    // };
  
    // const handleRedirectResult = async () => {
    //   const result = await getRedirectResult(auth);
    //   if (!result && !token) {
    //     handleGoogle();
    //   }
    //   else {
    //     if (!token) {
    //       localStorage.setItem("token", result.user.accessToken)
    //     }
    //     setSignedIn(true)
    //     console.log(auth.currentUser.uid)
    //     axios.put("https://apie.snapwrite.ca/storeData/user-preferences-add-form", {userID: auth.currentUser.uid, email: auth.currentUser.email, displayName: auth.currentUser.displayName, form: id})
    //     .then(res => {
    //       console.log(res)
    //     })
    //     .catch(err => console.error(err))
        
    //   }
    // };
  
    // handleRedirectResult();
  // }, []);
  


    
    
  


  const tx = document.getElementsByClassName("condition-notes");
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute(
      "style",
      "height:" +
        tx[i].scrollHeight +
        "px;overflow-y:hidden; resize:none; border: none; background: transparent; min-height: 30px"
    );
    tx[i].addEventListener("input", OnInput, false);
  }
  function OnInput() {
    this.style.height = 0;
    this.style.height = this.scrollHeight + "px";
  }

  const [uniqueIdentifier, setUniqueIdentifier] = useState([]);
  const [proceed, setProceed] = useState([]);  
  const [itemName, setItemName] = useState([]);
  const [brand, setBrand] = useState([]);
  const [color, setColor] = useState([])
  const [size, setSize] = useState([]);
  const [condition, setCondition] = useState([]);
  const [conditionNotes, setConditionNotes] = useState([]);
  const [cost, setCost] = useState([]);
  const [price, setPrice] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("")
  const [paymentProcessed, setPaymentProcessed] = useState("");
  const [productReceived, setProductReceived] = useState("");
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [totalCost, setTotalCost] = useState('');
  const [parentID, setParentID] = useState()
  const [storeCredit, setStoreCredit] = useState(0)
  const [formUserID, setFormUserID] = useState()
  const [notSignedIn, setNotSignedIn] = useState(true)

  const [showPopup, setShowPopup] = useState(false)
  const [cashOffer, setCashOffer] = useState(0)

  const [canEdit, setCanEdit] = useState(false)

  

  const handleChange = (event, val, setVal, index, name) => {
    let temp = val.slice();    
    temp[index] = event;
    setVal(temp);
    updateData(name, temp)
  };
  
  useEffect(()=>{

    

    fetch(
      `https://apie.snapwrite.ca/storeData/apparel-form-categorization/${id}`
    )
      .then((response) => response.json())
      .then((json) => {
        setContact(json.data.customerContact)
        setDescription(json.data.receptionDescription)
        setPaymentProcessed(json.data.paymentProcessed)
        setProductReceived(json.data.productReceived)
        setOfferAccepted(json.data.offerAccepted)  
        setTotalCost(json.data.totalCost);        
        setItemName(json.data.itemName)
        setBrand(json.data.brand)
        setColor(json.data.color ? json.data.color : Array(json.data.brand.length).fill(""))
        setSize(json.data.size)
        setCondition(json.data.condition)
        setConditionNotes(json.data.conditionNotes)
        setCost(json.data.cost)
        setPrice(json.data.price)        
        setProceed(json.data.proceed)
        setParentID(json.data.parentObjectID)
        setFormUserID(json.data.userID)
        setStoreCredit(json.data.storeCredit ? json.data.storeCredit : 0)
        setCashOffer(json.data.cashOffer ? json.data.cashOffer : 0)

        fetch("https://apie.snapwrite.ca/storeData/users")
				.then((response) => response.json())
				.then((userJson) => {
                    for (let user of userJson) {
                        if (user.uid === json.data.userID) {
                            setCustomerName(user.displayName)
                            setContact(user.email)
                        }
                    }
                })
      })
  }, [id])
  

  const updateData = (name, val) => {
    fetch(`https://apie.snapwrite.ca/storeData/apparel-form`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        val: val,
        name: name,
      }),
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  };

  

  const handleClick = () => {
    setItemName([...itemName, ""]);
    updateData("itemName", [...itemName, ""])
    setBrand([...brand, ""]);
    updateData("brand", [...brand, ""])
    setColor([...color, ""]);
    updateData("color", [...color, ""])
    setSize([...size, ""]);
    updateData("size", [...size, ""])
    setCondition([...condition, ""]);
    updateData("condition", [...condition, ""])
    setConditionNotes([...conditionNotes, ""]);
    updateData("conditionNotes", [...conditionNotes, ""])
    setCost([...cost, ""]);
    updateData("cost", [...cost, ""])
    setPrice([...price, ""]);
    updateData("price", [...price, ""])
  };

  const handleSave = () => {

  }

  const handleSendEmail = () => {
    axios.post("https://apie.snapwrite.ca/storeData/send-email", {recipient: contact, subject: "Your Order Form", message: `https://app.snapwrite.ca/newView/${id}`})
    .then((res) => res.json())
    .catch(err => console.error(err))
  }

  const deleteRow = (index) => {
    // Create copies of the existing arrays
    const newItemName = [...itemName];
    const newBrand = [...brand];
    const newColor = [...color];
    const newSize = [...size];
    const newCondition = [...condition];
    const newConditionNotes = [...conditionNotes];
    const newCost = [...cost];
    const newPrice = [...price];

    // Delete the row at the specified index
    newItemName.splice(index, 1);
    newBrand.splice(index, 1);
    newColor.splice(index, 1);
    newSize.splice(index, 1);
    newCondition.splice(index, 1);
    newConditionNotes.splice(index, 1);
    newCost.splice(index, 1);
    newPrice.splice(index, 1);

    // Update the state or call updateData with the modified arrays
    setItemName(newItemName);
    updateData("itemName", newItemName);
    setBrand(newBrand);
    updateData("brand", newBrand);
    setColor(newColor);
    updateData("color", newColor);
    setSize(newSize);
    updateData("size", newSize);
    setCondition(newCondition);
    updateData("condition", newCondition);
    setConditionNotes(newConditionNotes);
    updateData("conditionNotes", newConditionNotes);
    setCost(newCost);
    updateData("cost", newCost);
    setPrice(newPrice);
    updateData("price", newPrice);
  }
  return (
    true ? (
    <div className="newview">
      <Header hideButton/>
      {/** showPopup && <GoogleSignUpButton setShowPopup={setShowPopup} /> */}
      <div style={{ display: "flex"}}>
        {/* <SideMenu /> */}
        {/* <TableList/> */}
        <div style={{height: "100vh", overscrollBehavior: "none", display: "flex", flexDirection: "column", alignItems: "center", overflowX: "scroll"}}>
          <div
            className="table"
            style={{
              display: "flex",
              justifyContent: "left",
              marginTop: "30px",
              maxWidth: "100%",
              overflowX: "scroll"
            }}
          >
            <div
              style={{
                borderRight: "solid lightgrey 2px",
                padding: "0px 20px",
                maxWidth: "100%"
              }}
            >
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Resller:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}
                  value={customerName}
                  onChange={(event) => {
                    setCustomerName(event.target.value);
                    updateData("customerName", event.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Contact:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}
                  value={contact}
                  onChange={(event) => {
                    setContact(event.target.value);
                    updateData("customerContact", event.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Description:
              </div>
              <div style={{ marginTop: "10px" }}>
                <textarea 
                  readOnly = {!canEdit}               
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                    updateData("receptionDescription", event.target.value);
                  }}
                />
              </div>

              <div style={{ marginTop: "10px", fontWeight: "bold" }}>	
                Items Processed:	
              </div>	
              <div style={{ marginTop: "10px" }}>	
                <select	
                  disabled = {!canEdit}	
                  value={paymentProcessed}	
                  onChange={(event) => {	
                    setPaymentProcessed(event.target.value);	
                    updateData("paymentProcessed", event.target.value);	
                  }}	
                >
                  <option value={false}>No</option>	
                  <option value={true}>Yes</option>
                </select>	
              </div>	
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>	
                Customer Contacted:	
              </div>	
              <div style={{ marginTop: "10px" }}>	
                <select	
                  disabled = {!canEdit}	
                  value={productReceived}	
                  onChange={(event) => {	
                    setProductReceived(event.target.value);	
                    updateData("productReceived", event.target.value);	
                  }}	
                  >
                    <option value={false}>No</option>	
                    <option value={true}>Yes</option>
                  </select>		
              </div>	
              <div style={{ marginTop: "10px", fontWeight: "bold" }}>	
                Offer Accepted:	
              </div>	
              <div style={{ marginTop: "10px" }}>	
                <select	
                  disabled = {!canEdit}	
                  value={offerAccepted}	
                  onChange={(event) => {	
                    setOfferAccepted(event.target.value);	
                    updateData("offerAccepted", event.target.value);	
                  }}	
                >	
                  <option value={false}>No</option>	
                  <option value={true}>Yes</option>	
                </select>	
              </div>

              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Store Credit Offer:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}               
                  value={"$" + storeCredit}
                  onChange={(event) => {
                    setStoreCredit(event.target.value);
                    updateData("storeCredit", event.target.value);
                  }}
                />
              </div> 

              <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                Cash Offer:
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  readOnly = {!canEdit}
                  value={"$" + cashOffer}
                  
                />
              </div>
              <br />
              <br />    
              {canEdit &&         
              <div style={{ textAlign: "center", marginTop: "20px"}}>
            <button style = {{width: "145px", background: "#f5f6f8", border: "solid 3px #e1fc00"}} className="btn" onClick={handleClick}>
              <img
                className="plusicon"
                style={{ height: "25px", width: "auto" }}
                src={PlusIcon}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;Add a row
            </button>
            <br />
            <br />
            <Link style = {{width: "145px"}} className="btn" to = {`../newTable/${parentID}`}>
              Save Form
            </Link>
            <button style = {{width: "145px", background: "#f5f6f8", border: "solid 3px #e1fc00"}} className="btn" onClick={handleSendEmail}>
              Send Email
            </button>
          </div>}
            </div>
            <div>
              
                <table>
                  <thead style={{ borderBottom: "1px solid" }}>
                    <tr >
                      <th style={{ textAlign: "center" }}>Proceed</th>
                      <th >
                        Item Name
                      </th>
                      <th>Brand</th>
                      <th>Size</th>
                      <th>Color</th>
                      <th style={{ textAlign: "center" }}>Condition</th>
                      <th>Cost</th>
                      {/* <th>Price</th> */}
                      {canEdit && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {itemName.map((key, index) => {
                      return (
                        <tr className={!proceed[index] && "row-disabled"}>
                          <td style={{ textAlign: "center" }}>
                            <input
                              disabled = {!canEdit}
                              name='proceed'
                              type="checkbox"
                              className="terms"
                              value={proceed[index]}
                              onChange={(event) => {
                                handleChange(
                                  !proceed[index],
                                  proceed,
                                  setProceed,                                  
                                  index,
                                  'proceed'
                                );
                              }}
                              checked={proceed[index]}
                            />
                          </td>

                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              value={itemName[index]}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  itemName,
                                  setItemName,                                  
                                  index,
                                  'itemName'
                                );
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              value={brand[index]}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  brand,
                                  setBrand,                                  
                                  index,
                                  'brand'
                                );
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              value={size[index]}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  size,
                                  setSize,
                                  index,
                                  'size'
                                );
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              readOnly = {!canEdit}
                              value={color[index]}
                              style={{
                                resize: "none",
                                border: "none",
                                background: "transparent",
                              }}
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  color,
                                  setColor,                                  
                                  index,
                                  'color'
                                );
                              }}
                            />
                          </td>
                          <td
                            style={{
                              gridColumn: "5",
                              gridRow: "1",
                              textAlign: "center",
                            }}
                          >
                            <input value={condition[index]?.replace(/^\w/, (c) => c.toUpperCase())} readOnly = {!canEdit} />
                            
                            <br />
                            <p>Notes:</p>
                            <textarea
                              readOnly = {!canEdit}
                              name="conditionDescription"
                              className="condition-notes"
                              style={{
                                background: "transparent",
                                border: "none",
                                height: "100%",
                                resize: "none",
                                width: "250px",
                              }}
                              onKeyDown={(e) => {
                                if (e.key === ",") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if (e.currentTarget.value.includes(",")) {
                                  e.currentTarget.value =
                                    e.currentTarget.value.replace(/,/g, ";");
                                }                                
                                  handleChange(
                                    e.target.value,
                                    conditionNotes,
                                    setConditionNotes,                                  
                                    index,
                                    'conditionNotes'
                                  );                                
                                // props.updateVal('conditionDescription', e.currentTarget.value, index, props.conditionDescription, props.setConditionDescription);
                              }}
                              value={conditionNotes[index]}
                            />
                          </td>

                          <td style = {{display: "flex"}}>
                            
                            <input
                              readOnly = {!canEdit}
                              style={{
                                gridColumn: "3",
                                gridRow: "1",
                                textAlign: "center",
                                border: "none",
                                width:"60px",
                                background: "transparent",
                                paddingBottom: "1.7rem",
                              }}
                              value={cost[index]}
                              type="number"
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  cost,
                                  setCost,
                                  index,
                                  'cost'
                                );
                              }}
                            />                            
                          </td>
                          {/* <td>                            
                            <input
                              readOnly = {!canEdit}
                              style={{                                  
                                textAlign: "center",
                                border: "none",
                                background: "transparent",
                                paddingBottom: "1.7rem",
                                width:"60px",
                              }}
                              value={price[index]}
                              type="number"
                              onChange={(event) => {
                                handleChange(
                                  event.target.value,
                                  price,
                                  setPrice,
                                  index,
                                  'price'
                                );
                              }}
                            />
                          </td> */}
                          {canEdit &&
                          <td>
                            <img src = {trashCan} onClick = {() => deleteRow(index)} style = {{maxWidth: "20px", height: "20px", cursor: "pointer", marginRight: "10px"}} />
                          </td>}
                          

                          {/* <td><div style={{ gridColumn: "6", gridRow: "1", textAlign: "center" }}><input value={cost[index]} onChange={(event)=>{setCost(event.target.value)}}/></div></td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
            </div>
            
            
          </div>
          

          
        </div>
      </div>
      
    </div>) : null
  );
};

export default FormSellerView;
