const adjustedConditionForPrice = (currentCondition) => {
    //condition = ['Excellent', 'Very Good', 'New without Tags', 'Good' 'Play', 'Brand New with Tags']										
    if (currentCondition==="Excellent") {
        return "Excellent"
    } else if (currentCondition ==="Very good") {
        return "Very Good"
    } else if (currentCondition === "Like New") {
        return "New without Tags"
    } else if (currentCondition ==="New with tags") {
        return "Brand New with Tags"
    } else if (currentCondition === "New without tags") {
        return "New without Tags"
    } else if (currentCondition === "Good") {
        return "Good"
    } else if (currentCondition === "Play Condition") {
        return "Play"
    } else return currentCondition
}

export default adjustedConditionForPrice