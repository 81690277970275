import React, { useContext, useEffect, useState } from 'react';
import {
	getAuth,
	createUserWithEmailAndPassword,
	updateProfile,
	signInWithEmailAndPassword,
} from 'firebase/auth';
import { Link } from 'react-router-dom';
import '../css/register.css';
import axios from 'axios';
import AuthenticationPageView from './AuthenticationPageView';
import { OrganizationContext } from '../components/App';

const Signup = ({ history }) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const [errorEmail, setErrorEmail] = useState('');
	const [errorPassword, setErrorPassword] = useState('');
	const [checked, setChecked] = useState(false);
	const {organization, setOrganization} = useContext(OrganizationContext)

	useEffect(() => {
		const token = localStorage.getItem('token');

		if (token) {
			history.push('/dashboard');
		}
	}, []);

	useEffect(() => {
		// Set the body's style to allow scrolling when the component mounts
		document.body.style.overflow = 'auto';
	
		// Clean up the effect by restoring the original overflow style when the component unmounts
		return () => {
		  document.body.style.overflow = 'hidden';
		};
	  }, []);

	// Reflio
	let ReflioDomainRoot = 'https://reflio.com';
	const ReflioAPIRoot =
			(ReflioDomainRoot = window.location.href.includes(
				'reflioTestingMode=true'
			)
				? 'http://localhost:3000'
				: ReflioDomainRoot) + '/api/v1',
		rootDomain = window.location.origin,
		queryString = window.location.search,
		urlParams = new URLSearchParams(queryString),
		reflioVerifyParam = urlParams.get('reflioVerify'),
		reflioReferralParam = urlParams.get('via')
			? urlParams.get('via')
			: urlParams.get('ref')
			? urlParams.get('ref')
			: null,
		reflioRetrieveReferralParam = urlParams.get('referral')
			? urlParams.get('referral')
			: null;
	const reflioInnerScript = document.querySelector('script[data-reflio]');

	class rfl {
		details() {
			return {
				companyId: reflioInnerScript.getAttribute('data-reflio')
					? reflioInnerScript.getAttribute('data-reflio')
					: null,
				rootDomain: rootDomain,
				domains: reflioInnerScript.getAttribute('data-domains')
					? reflioInnerScript.getAttribute('data-domains')
					: null,
				hidePopup: !!reflioInnerScript.getAttribute('hidePopup'),
				privacyCompliance:
					!!reflioInnerScript.getAttribute('privacyCompliance'),
				apiUrl: ReflioAPIRoot,
			};
		}
		async checkDomainVerification() {
			return fetch(ReflioAPIRoot + '/verify-company').then(function (e) {
				return e.json();
			});
		}
		async impression(e, o) {
			return (
				(e && o) ||
					console.warn(
						'Reflio: could not track impression. Referral code / companyId not found.'
					),
				fetch(ReflioAPIRoot + '/record-referral', {
					method: 'POST',
					body: JSON.stringify({ referralCode: e, companyId: o }),
				}).then(function (e) {
					return e.json();
				})
			);
		}
		async campaignDetails(e, o) {
			return (
				(e && o) ||
					console.warn(
						'Reflio: could not get campaign details. Referral code / companyId not found.'
					),
				fetch(ReflioAPIRoot + '/campaign-details', {
					method: 'POST',
					body: JSON.stringify({ referralCode: e, companyId: o }),
				}).then(function (e) {
					return e.json();
				})
			);
		}
		checkCookie() {
			var o = 'reflioData=',
				t = decodeURIComponent(document.cookie).split(';');
			for (let i = 0; i < t.length; i++) {
				let e = t[i];
				for (; ' ' == e.charAt(0); ) e = e.substring(1);
				if (0 == e.indexOf(o))
					try {
						return JSON.parse(e.substring(o.length, e.length));
					} catch (e) {
						return { error: !0 };
					}
			}
			return null;
		}

		async signup(e) {
			var o;
			return (
				console.log('Reflio: Running signup function'),
				e && null !== Reflio.checkCookie()
					? ('error' !==
							(o = await fetch(
								ReflioAPIRoot + '/signup-referral',
								{
									method: 'POST',
									body: JSON.stringify({
										referralId:
											Reflio.checkCookie().referral_id,
										cookieDate:
											Reflio.checkCookie().cookie_date,
										email: e,
									}),
								}
							).then(function (e) {
								return e.json();
							}))?.signup_details &&
							console.log('Reflio: Signup function successful'),
					  o)
					: (console.warn('Reflio: Signup could not be tracked.'), !1)
			);
		}
	}
	var Reflio = new rfl();

	const onSignup = () => {
		setLoading(true);
		const auth = getAuth();

		createUserWithEmailAndPassword(auth, email, password)
			.then(async () => {
				Reflio.signup(email);
				updateProfile(auth.currentUser, { displayName: name })
					.then(
						signInWithEmailAndPassword(auth, email, password)
							.then((userCredential) => {
								console.log(userCredential.user.uid);
								axios
									.post(
										'https://apie.snapwrite.ca/storeData/user-preferences',
										{
											userID: userCredential.user.uid,
											email: email,
											displayName: name,
											dateCreated: new Date(),
										}
									)
									.then((res) => setOrganization({id: res.data.data.organizationID, name: res.data.data.organizationName, accountType: res.data.data.accountType}))
									.catch((err) => {
										console.error(err);
									});
								localStorage.setItem(
									'token',
									userCredential._tokenResponse.idToken
								);
								history.push('/onboarding');
							})
							.catch((e) => alert(e.message))
							.finally(() => setLoading(false))
					)
					.catch((e) => alert(e.message));
			})
			.catch((e) => {
				console.log(e.code);
				switch (e.code) {
					case 'auth/invalid-email':
						setErrorEmail('Please check the email');
						break;
					case 'auth/weak-password':
						setErrorPassword('Please try using bigger password');
						break;
					default:
						break;
				}
			})
			.finally(() => setLoading(false));
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			if (event.target.className === 'name' && email === '') {
				document.getElementsByClassName('email')[0].focus();
			} else if (event.target.className === 'email' && password === '') {
				document.getElementsByClassName('password')[0].focus();
			} else {
				document.getElementsByClassName('logInButtton')[0].click();
			}
		}
	};
	return (

		<AuthenticationPageView>
				<div className='mt-8'>
					<h1>Sign up, it's free</h1>
					<br />
					<div className='LoginWithId'>
						<input
							value={name}
							onChange={(e) => setName(e.target.value)}
							name='name'
							type='name'
							placeholder='name'
							className='name'
							tabIndex='1'
							data-hj-allow
							onKeyDown={(event) => {
								handleKeyDown(event);
							}}
						/>
						<span style={{ color: 'red' }}>{errorEmail}</span>
						<input
							value={email}
							type='email'
							name='email'
							className='email'
							required='required'
							placeholder='email'
							onChange={(e) => setEmail(e.target.value)}
							tabIndex='2'
							data-hj-allow
							onKeyDown={(event) => {
								handleKeyDown(event);
							}}
						/>
						<span style={{ color: 'red' }}>{errorPassword}</span>
						<input
							value={password}
							placeholder='password'
							onChange={(e) => setPassword(e.target.value)}
							name='password'
							type='password'
							className='password'
							tabIndex='3'
							onKeyDown={(event) => {
								handleKeyDown(event);
							}}
						/>
						<div className='termsPrivacypolicy'>
							<input
								type='checkbox'
								className='terms'
								onClick={(event) => {
									setChecked(event.target.checked);
								}}
								onKeyDown={(event) => {
									handleKeyDown(event);
								}}
							/>
							<p>
								I agree to the&nbsp;
								<a
									href='https://drive.google.com/file/d/1yoN-XoCHkUJQ3zGqBQz6T1rmCQelXCjg/view'
									target='_blank'
									rel='noreferrer'
								>
									Terms
								</a>{' '}
								and the&nbsp;
								<a
									href='https://drive.google.com/file/d/1lmiBxHQI-Sbix7SrTMp-d5cYR2pQVGbC/view'
									target='_blank'
									rel='noreferrer'
								>
									Privacy Policy
								</a>{' '}
								before signing up.
							</p>
						</div>
						<button
							onClick={onSignup}
							disabled={
								email === '' ||
								name === '' ||
								password === '' ||
								!checked
							}
							className='logInButtton'
							tabIndex='4'
						>
							{loading ? 'Creating user ...' : 'Signup'}
						</button>
					</div>
					<hr className='verticalLine' />
					<p className='backToLogin'>
						Already have an account?&nbsp;
						<Link to='/'>Sign in</Link>
					</p>
					<div className='termsPrivacypolicy'>
						<p>
							By signing up you are agreeing to the&nbsp;
							<a
								href='https://drive.google.com/file/d/1yoN-XoCHkUJQ3zGqBQz6T1rmCQelXCjg/view'
								target='_blank'
								rel='noreferrer'
							>
								Terms
							</a>{' '}
							and the&nbsp;
							<a
								href='https://drive.google.com/file/d/1lmiBxHQI-Sbix7SrTMp-d5cYR2pQVGbC/view'
								target='_blank'
								rel='noreferrer'
							>
								Privacy Policy
							</a>
							.
						</p>
					</div>
				</div>
			</AuthenticationPageView>


	);
};

export default Signup;
