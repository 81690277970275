import React,{useEffect, useState} from 'react';
import { getAuth } from '@firebase/auth';
import ProductCard from '../projectLibrary/projectCard';
import GetData from '../projectLibrary/getData';


const SpecData = () => {

    const auth = getAuth();
    const user = auth.currentUser;
    let userID;
    let userName;
    
    if (user !== null) {
      userID= user.uid;
        user.providerData.forEach((profile) => {            
            userID = user.uid;
            userName = profile.displayName
        });
      }

    const [specDecriptions, setSpecDescriptions] = useState(["spec descriptions"])
    const  [lastIndex, setLastIndex] = useState(4)

    GetData(setSpecDescriptions, 'spec-description')
      const items = 
      specDecriptions.slice(0, lastIndex).map((description)=>
      <ProductCard id={description._id} path="spec-description-view" name={description.projectName} date={description.dateCreated} />)
    return(
      <>
      <p className='projectsSubHeader'>Product descriptions from Specifications</p>

    <div  className='listings' style={specDecriptions.length === 2 ? {justifyContent:"space-evenly"}: {justifyContent:"space-between"}}>
      {items}
      </div>
      {
          specDecriptions.length > 4 && specDecriptions.length > lastIndex ?
          <div className="showMoreButton">
        <button className="showMore" onClick={()=>{ setLastIndex(lastIndex+4)}}>Show more</button>
        </div>
        :null
        }
        <br />
      </>
    )
}

export default SpecData