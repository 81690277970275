import React, { useContext, useEffect, useState, useMemo } from 'react';
import { getAuth } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import MainSideMenuHeaderContainer from '../../../../../components/containers/MainSideMenuHeaderContainer';
import { OrganizationContext } from '../../../../../components/App';
import { Heading1 } from '../../../../../components/branded-elements/Elements';
import {
	Table,
	TableHead,
	TextField,
	InputAdornment,
	TableRow,
	TableCell,
	Button
} from '@mui/material';
import { Search } from '@mui/icons-material';
import axios from 'axios';

const MMStyles = () => {
	const auth = getAuth();
	const history = useHistory();
	const organization = useContext(OrganizationContext);
	const [userID, setUserID] = useState('');
	const [styleData, setStyleData] = useState([]);
	const [styleRecycleData, setStyleRecycleData] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');

	const headingStyle = {
		fontWeight: 'bold',
		fontSize: '20px',
	};	

	const currentLocation = history.location.pathname;

	useEffect(() => {
		if (styleData.length > 0) {
			setStyleData([]);
		}
		const endpoints = {
			'/mm-styles/coats':
				'https://apie.snapwrite.ca/storeData/mm-table?userID=pDDieoXBhjgkhiJnILJPw4mpb7v1',
			'/mm-styles/sports':
				'https://apie.snapwrite.ca/storeData/mm-sports?userID=pDDieoXBhjgkhiJnILJPw4mpb7v1',
		};

		const endpoint = endpoints[currentLocation];

		const checkUserAuthenticationAndUpdateStates = async () => {
			auth.onAuthStateChanged(async (user) => {
				if (!(localStorage.getItem('token') && user)) {
					history.push('/');
				} else {
					setUserID(user.uid);

					try {
						const response = await axios.get(endpoint);
						const h3Styles = response.data.data.filter(
										(item) =>
											!item.isArchived && (
												item.season === '' ||
												item.season === 'h3-coats' ||
												item.season === 'h3-sports' ||
												item.season === undefined
											)
									)
						setStyleRecycleData(h3Styles)						
						const styleCodesAndImages = h3Styles.flatMap(
							(item) =>								
								item.s3Images.map((image, index) => ({
									styleCode: item.styleCode[index],
									image: image,
									project: item._id,																		
								}))							
						);						
						setStyleData(styleCodesAndImages);
					} catch (error) {
						console.error(error);
					}
				}
			});
		};

		checkUserAuthenticationAndUpdateStates();
	}, [auth, history, currentLocation]);

	const filteredStyles = useMemo(() => {
		if (!searchQuery.trim()) {
			return styleData;
		}

		const normalizedQuery = searchQuery.trim().toLowerCase();
		return styleData.filter((style) => {
			const styleCodeMatch = style.styleCode
				.toLowerCase()
				.includes(normalizedQuery);
			return styleCodeMatch;
		});
	}, [searchQuery, styleData]);
	const takeToProject = (id) => {
		history.push(`/mm-${currentLocation.match(/\/([^/]+)\/?$/)[1]}/${id}`)
	}

	const recycleStyle = async (project_id, styleNumber) => {
		
		let project = styleRecycleData.filter((item)=> item._id === project_id)		
		project = project[0]
		const index = project.styleCode.indexOf(styleNumber)	
		const newObj = {};
		for (const key in project) {
			if (key === "projectCSVGenerated" || key === "dateCreated" || key === "sku" || key === "styleCode" || key === "_id" || key === "projectName") {
				
			} else if (Array.isArray(project[key])) {			
				if (project[key][index]){
					newObj[key] = [project[key][index]];
				} else {
					newObj[key] = [''];
				}
			} else {
				newObj[key] = project[key];
			}
			newObj["projectCSVGenerated"] = false
			newObj["sku"] = ['']
			newObj["styleCode"] = ['']
			newObj["projectName"] = `Recycled ${styleNumber}`
			newObj["season"] = `p4-${currentLocation.match(/\/([^/]+)\/?$/)[1]}`

		}		
		if(newObj) {
			const postEndpoints = {
				'/mm-styles/coats':
					'https://apie.snapwrite.ca/storeData/mm-table',
				'/mm-styles/sports':
					'https://apie.snapwrite.ca/storeData/mm-sports',
			};
			await axios.post(`${postEndpoints[currentLocation]}`, newObj).then(res => {					  			
				history.push(`/mm-${currentLocation.match(/\/([^/]+)\/?$/)[1]}/${res.data.data._id}`)
			  })			
		}		
	}


	return (
		<MainSideMenuHeaderContainer>
			<Heading1 title={'H3 Styles'} />
			<TextField
				label='Search Styles'
				placeholder='Search Styles'
				sx={{
					width: '40%',
					minWidth: '200px',
					marginTop: '20px',
				}}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<Search />
						</InputAdornment>
					),
				}}
				onChange={(e) => setSearchQuery(e.target.value)}
			/>
			<Table
				sx={{
					width: '60%',
					minWidth: '200px',
					marginTop: '50px',
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell sx={headingStyle}>Style Code</TableCell>
						<TableCell sx={headingStyle}>Image</TableCell>
						<TableCell sx={headingStyle}></TableCell>
					</TableRow>
				</TableHead>

				<tbody>
					{filteredStyles.map((style, idx) => {						
						return (
							<tr key={`${style.styleCode}-${idx}`} >
								<td className='px-3' onClick={()=>takeToProject(style.project)}>{style.styleCode}</td>
								<td onClick={()=>takeToProject(style.project)}>
									<img
										className='h-32 p-3 w-auto'
										src={style.image}
										alt={style.styleCode}
										loading='lazy'
									/>
								</td>		
								<td className='px-3' >
									<Button
									variant="contained"
									sx={{
									  fontSize: "14px",
									  backgroundColor: "rgba(0, 0, 0, 0.12)",
									  color: "rgba(0, 0, 0, 0.38)",
									  boxShadow: "none",
									  textTransform: "none",
									  "&:hover": { color: "white", boxShadow: "none" },
									}}
									onClick={()=>recycleStyle(style.project, style.styleCode)}
									 >
									Move to P4
									</Button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</MainSideMenuHeaderContainer>
	);
};

export default MMStyles;
