import React, { useState, useEffect } from 'react';
import { getAuth } from '@firebase/auth';
import { Header } from '../components/navigation/header';
import SideMenu from '../components/sideMenu';

const UserProfile = ({history}) => {
  

    const [prompt1, setPrompt1] = useState("")
    const [prompt2, setPrompt2] = useState("")
    const [objectID, setObjectID] = useState("")

    const auth = getAuth();
    const user = auth.currentUser;
    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token ||!user) {
            history.push('/')
        }
    },[])

    let userID;
    let userName;
    let userEmail;
    
    if (user !== null) {
      userID= user.uid;
      // console.log(userID)
        user.providerData.forEach((profile) => {            
            userID = user.uid;
            userName = profile.displayName;
            userEmail = profile.email;

        });
      }

      const profileStyle = {
          /* Profile */
            position: "absolute",
            width: "95px",
            height: "24px",
            left: "296px",
            top: "84px",
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "24px",
            display: "flex",
            alignItems: "center",
            /* Grey 900 */
            color: "#1A1F36",
      }

      const prompt1Style = {
        /* Profile */
          position: "absolute",
          width: "292px",
          height: "28px",
          left: "296px",
          top: "335px",
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "17px",
          lineHeight: "28px",                    
    }

    const prompt2Style = {
      /* Profile */
        position: "absolute",
        width: "292px",
        height: "28px",
        left: "296px",
        top: "580px",
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "17px",
        lineHeight: "28px",                    
  }

  const updateTone =()=>{
    fetch(`https://apie.snapwrite.ca/storeData/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        userName: userName,
        prompt1: prompt1,
        prompt2: prompt2        
      }),
    })
      .then((response) => response.json())
      .then(response=> setObjectID(response.data._id))      
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }

      return (
        <div className="descriptions">
          {/* {console.log("noOfWordsGenerated = " + noOfWordsGenerated)} */}
          <Header  />
          {/* <hr width="100%;" /> */}
          {/* ******************************the <main> section****************************** */}
          <div className="interface">
            <SideMenu />
            
            <div >
              <p style={profileStyle}>Profile</p>
              <div style={{
                position: "absolute",
                height: "1px",
                left: "270px",
                right: '0px',
                top: "121px",
                /* divider */
                background: "#EBEFF2",
                marginLeft: "-15px"
              }}></div>
              <section style={{
                position: "absolute",
                width: "292px",
                height: "84px",
                marginLeft: "18px",
                marginTop: "6.6%",            
                fontFamily: 'Inter',
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "17px",
                lineHeight: "14px",
                color: "#1A1F36",                
              }}>
                <p>{userName}</p>
                <p>{userEmail}</p>
                <p>Basic Plan</p>
              </section>
              <div style={{
                position: "absolute",
                height: "1px",
                left: "269px",
                right: '1px',
                top: "255px",
                /* divider */
                background: "#EBEFF2",
                marginLeft: "-15px"
              }}></div>
              <div style={{
                position: "absolute",
                width: "208px",
                height: "24px",
                left: "296px",
                top: "287px",                
                fontFamily: 'Inter',
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24px",
                display: "flex",
                alignItems: "center",
                
                /* Grey 900 */
                
                color: "#1A1F36"
              }}>
                Adjust Brand Tone
              </div>
              <button style={{
                /* Auto layout */
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px 24px",
                gap: "8px",

                position: "absolute",
                width: "89px",
                height: '40px',
                left: '89%',
                top: "280px",
                fontSize: "14px",

                background: "#E8FB0A",
                borderRadius: "10px",
                border: "none",
              }}>Apply</button>
              <p style={prompt1Style}>Prompt #1</p>
              <textarea 
              style={{
                position: "absolute",
                width: "70%",
                height: "173px",
                left: "296px",
                top: "375px",                
                background: "rgba(227, 232, 238, 0.5)"
              }}
              value={prompt1}
              onChange={(event) => {
                setPrompt1(event.target.value);
              }}
              ></textarea>              
              <p style={prompt2Style}>Prompt #2</p>
              <textarea 
              style={{
                position: "absolute",
                width: "70%",
                height: "173px",
                left: "296px",
                top: "620px",                
                background: "rgba(227, 232, 238, 0.5)"
              }}
              value={prompt2}
              onChange={(event) => {
                setPrompt2(event.target.value);
              }}
              ></textarea>
            </div>
          </div>
        </div>
      );
}
export default UserProfile;