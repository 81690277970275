import React from 'react';
import { Header } from '../navigation/header.js';
import SideMenu from '../sideMenu.js';
import PlusIcon from '../../assets/PlusIcon.png';
// import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import ProjectsGroup from '../Library/projectsGroup.js';
import ProductCard from '../projectLibrary/projectCard.js';
import '../../css/App.css';
import { useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import GetData from '../projectLibrary/getData.js';
import NewView from './NewView.js';
import { create } from '@mui/material/styles/createTransitions.js';
import { useParams } from 'react-router';
import BackArrow from '@mui/icons-material/ArrowBack';
import FormList from './formList.js';
import TextField from '@mui/material/TextField';
import { OrganizationContext } from '../App.js';
import axios from 'axios';
import generateSKU from '../../products/bulk/shopify/utils/skuCreator/skuGenerate.js';
import updateSKUstart from '../../products/bulk/shopify/utils/skuCreator/skuCountUpdate.js';
import MainSideMenuHeaderContainer from '../containers/MainSideMenuHeaderContainer.js';
import { CircularProgress } from '@mui/material';

const NewTable = ({ history }) => {
	const { organization, setOrganization } = useContext(OrganizationContext);
	let userID;
	let userName;
	const auth = getAuth();
	const user = auth.currentUser;
	if (user !== null) {
		userID = organization?.id || auth.currentUser.uid;
		userName = auth.currentUser.email;
	}
	const { batch } = useParams();
	const [objectID, setObjectID] = useState('');
	const [childObjectID, setChildObjectID] = useState('');
	const [clothingForms, setClothingForms] = useState([]);
	const [showArchived, setShowArchived] = useState(false);
	const [customerName, setCustomerName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [showLoader, setShowLoader] = useState(false);

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		updateData('defaultEmail', event.target.value);
	};

	const handlePhoneChange = (event) => {
		setPhone(event.target.value);
		updateData('defaultPhoneNumber', event.target.value);
	};
	// const id = useParams(id)
	useEffect(() => {
		fetchData();
		auth.onAuthStateChanged((user) => {
			const token = localStorage.getItem('token');

			if (!token || !user) {
				history.push('/');
			}
		});
	}, []);

	const fetchData = async () => {
		const response = await fetch(
			`https://apie.snapwrite.ca/storeData/apparel-form?parentID=${batch}`
		).catch((err) => {
			console.log(`error ${err}`);
		});
		const json = await response.json();
		const path = 'newView';
		const endpoint = 'apparel-form';
		await setClothingForms(
			json.data.map((d) => ({ ...d, path, endpoint }))
		);

		const parent = await fetch(
			`https://apie.snapwrite.ca/storeData/apparel-input-batch/${batch}`
		).catch((err) => {
			console.log(`error ${err}`);
		});

		const parentJSON = await parent.json();
		setEmail(parentJSON.data.defaultEmail || '');
		setPhone(parentJSON.data.defaultPhoneNumber || '');
		setCustomerName(parentJSON.data.batchName);
	};

	const updateData = (name, val) => {
		fetch(
			`https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					id: batch,
					val: val,
					name: name,
				}),
			}
		)
			.then((response) => response.json())
			.catch((err) => {
				console.log(`Error ${err}`);
			});
	};

	const handleArchivedToggle = async (archived) => {
		await fetchData();
		setShowArchived(archived);
	};

	function setFormArchives(id) {
		setClothingForms(
			clothingForms?.map((d) =>
				d._id === id ? { ...d, isArchived: !d.isArchived } : d
			)
		);
	}

	const createForm = async () => {
		// console.log("lastSKUcount", lastSKUcount)
		setShowLoader(true);

		// updateSKUstart(lastSKUcount+7, userID)

		fetch('https://apie.snapwrite.ca/storeData/apparel-form', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				parentObjectID: batch,
				userID: userID,
				userName: userName,
			}),
		})
			.then((response) => response.json())
			.then((json) => {
				setShowLoader(false);
				history.push(`/NewView/${json.data._id}`);
			})

			.catch((err) => {
				setShowLoader(false);
				console.log(`Error ${err}`);
			});
	};
	const masterTable = () => {};

	const toggleArchivedButtons = () => {
		return (
			<>
				<div
					style={{
						marginRight: '21px',
						cursor: 'pointer',
						padding: '7px',
						width: '80px',
						textAlign: 'center',
						background: showArchived ? '#c5c5c55c' : '#8884d8',
						borderRadius: '10px',
					}}
					onClick={() => handleArchivedToggle(false)}
				>
					Active
				</div>
				<div
					style={{
						marginRight: '21px',
						cursor: 'pointer',
						padding: '7px',
						width: '80px',
						textAlign: 'center',
						background: showArchived ? '#8884d8' : '#c5c5c55c',
						borderRadius: '10px',
					}}
					onClick={() => handleArchivedToggle(true)}
				>
					Deleted
				</div>
			</>
		);
	};
	return (
		<MainSideMenuHeaderContainer>
			{showLoader && (<div className='w-full h-screen z-50 flex absolute bg-white'>
				<div className='text-center relative top-[30%] left-[40%]'>
				<CircularProgress color='inherit' />
					<h3 className='mt-2'>Loading...</h3>
				</div>
			</div>)}
			<div
				onClick={() => history.push(`../BatchAggregator`)}
				style={{
					cursor: 'pointer',
					width: 'fit-content',
					alignItems: 'center',
					margin: 0,
					padding: 0,
					display: 'flex',
					gap: '7px',
				}}
			>
				<BackArrow color='primary' fontSize={'large'} />
				<h4 style={{ color: '#757de8', margin: 0 }}>Customers</h4>
			</div>
			<br />
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					stretch: 'none',
				}}
			>
				<h1 style={{ margin: 0, padding: 0 }}>
					{'Forms (' + customerName + ')'}
				</h1>
				<button
					style={{ marginRight: '10%' }}
					className='btn'
					onClick={() => createForm()}
				>
					Create Form
				</button>
			</div>
			<br />
			<br />
			<div style={{ display: 'flex' }}>{toggleArchivedButtons()}</div>
			<br></br>
			<FormList
				batch={batch}
				setArchives={setFormArchives}
				forms={clothingForms}
				showArchived={showArchived}
			/>
		</MainSideMenuHeaderContainer>
	);
};

export default NewTable;
