import React from "react";
import { RowColumn } from "../../tableList";
import { TableCell } from "@mui/material";

const AttributesColumn = (props) =>{
		const disabled = props.disabled
		const prop = props.prop;
    const index= props.index;    
			
    const tx = document.getElementsByClassName("attribute-textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
      tx[i].addEventListener("input", OnInput, false);
    }

    function OnInput() {
      this.style.height = 0;
      this.style.height = (this.scrollHeight) + "px";
    }

		

	// console.log(prop.collar)
	// console.log(prop.drawstring)
	// console.log(prop.pocket)
		return (
			<TableCell className="">
				<div style={{width:"230px"}} ></div>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="closure" label="Closure" autocompleteOptions={props.closureOptions}/>
				<br></br>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="hood" label="Hood" autocompleteOptions={props.hoodOptions}/>
				<br></br>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="sleeve" label="Sleeve Cuffs" autocompleteOptions={props.sleeveCuffsOptions}/>
				<br></br>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="collar" label="Collar" autocompleteOptions={props.collarOptions}/>
				<br></br>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="drawstring" label="Drawstring" autocompleteOptions={props.drawstringOptions}/>
				<br></br>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="pocket" label="Pocket" autocompleteOptions={props.pocketOptions}/>
				<br></br>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="lining" label="Lining" autocompleteOptions={props.liningOptions}/>
				<br></br>
				<RowColumn noTD noNotes userID={props.userID} disabled={disabled} type="autocomplete" prop={prop} index={index} name="interiorPockets" label="Interior Pocket" autocompleteOptions={props.interiorPocketOptions}/>



			</TableCell>
		)

}


export default AttributesColumn;
