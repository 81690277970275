import { getAuth } from '@firebase/auth';
import React, { useEffect, useState } from 'react';
import AltTextGenerator from "../products/alt-texts/altTextGeneration";

const AltText = ({ history }) => {
  
  const auth = getAuth();
  const user = auth.currentUser;
    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token ||!user) {
            history.push('/')
        }
    },[])
    const [generateButtonClick, setGenerateButtonClick] = useState(0)
    let userID;
    let userName;

    const MINUTE_MS = 60000;

    useEffect(() => {
      const interval = setInterval(() => {
        setGenerateButtonClick(0);
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    if (user !== null) {
      userID= user.uid;
        user.providerData.forEach((profile) => {            
            userID = user.uid;
            userName = profile.displayName
        });
      }

    return (
       <AltTextGenerator  user={userID} userName={userName} generateButtonClick={generateButtonClick} setGenerateButtonClick={setGenerateButtonClick} />
    )
}

export default AltText;
