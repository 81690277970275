import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import { Header } from "./navigation/header";
import SideMenu from "./sideMenu";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

export const CategorizedTable = (props) => {
  // const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [projectName, setProjectName] = useState("");

  const [rows, setRows] = useState([]);
  const [rowsTemp, setRowsTemp] = useState([]);
  const [csvRows, csvSetRows] = useState([]);
  const [csvRowsTemp, csvSetRowsTemp] = useState([]);
  const [csvDownload, SetCsvDownload] = useState([]);
  const [FrenchCsvDownload, SetFrenchCsvDownload] = useState([]);
  const [EnglishCsvDownload, SetEnglishCsvDownload] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [objectID, setObjectID] = useState("")


  const [s3Images, setS3Images] = useState([]);
  const [imageFileName, setImageFileName] = useState([]);
  const [englishNames, setEnglishName] = useState([]);
  const [frenchNames, setFrenchName] = useState([]);
  const [formats, setFormat] = useState([]);
  const [brands, setBrand] = useState([]);
//   const [additionalInfos, setAddtionaInfo] = useState([]);

  const userID = props.userID;
  // console.log(userID);

  useEffect(() => {
    const objectID =  JSON.parse(
      localStorage.getItem(`sortedLabelObjectID${userID}`)
    );    
    const imageData = JSON.parse(
      localStorage.getItem(`categoryImageLink${userID}`)
    );    
    const englishEntryName = JSON.parse(
      localStorage.getItem(`englishEntryName${userID}`)
    );
    const frenchEntryName = JSON.parse(localStorage.getItem(`frenchEntryName${userID}`));
    const format = JSON.parse(localStorage.getItem(`format${userID}`));
    const brand = JSON.parse(
      localStorage.getItem(`brand${userID}`)
    );
    const categoryRow = JSON.parse(localStorage.getItem(`categoryRows${userID}`));

    if (imageData !== null && s3Images.length === 0) {
      setObjectID(objectID)
      setS3Images(imageData);      
      setEnglishName(englishEntryName);
      setFrenchName(frenchEntryName);
      setFormat(format);
      setBrand(brand);
      setRows(categoryRow);
    }
  }, [userID]);

  useEffect(() => {
    localStorage.setItem(`sortedLabelObjectID${userID}`, JSON.stringify(objectID))
    localStorage.setItem(`categoryImageLink${userID}`, JSON.stringify(s3Images));    
    localStorage.setItem(`englishEntryName${userID}`, JSON.stringify(englishNames));
    localStorage.setItem(`frenchEntryName${userID}`, JSON.stringify(frenchNames));
    localStorage.setItem(`format${userID}`, JSON.stringify(formats));
    localStorage.setItem(
      `brand${userID}`,
      JSON.stringify(brands)
    );
    localStorage.setItem(`categoryRows${userID}`, JSON.stringify(rows));
  }, [
    objectID,
    s3Images,    
    englishNames,
    frenchNames,
    formats,
    brands,
    rows,
    userID,
  ]);

  const createcsv = () => {
    let csv = [];
    // let csv = csvRowsTemp;
    let i = 0;
    for (i = 0; i < englishNames.length; i++) {
        csv.push([
            englishNames[i],
            frenchNames[i],
            formats[i],
            brands[i],
            s3Images[i]
        ])
    }
    csv.unshift([
        "Entry Name (English)",
        "Entry Name (French)",
        "Format",
        "Brand",
        "Image Link"
    ])
    // console.log(csv)
    SetCsvDownload(csv);
    
  };

  const createcsvFrench = () => {
    let csv = [];    
    let i = 0;
    for (i = 0; i < frenchNames.length; i++) {
        csv.push([            
            frenchNames[i],
            formats[i],
            brands[i],
            s3Images[i]
        ])
    }
    csv.unshift([        
        "Entry Name (French)",
        "Format",
        "Brand",
        "Image Link"
    ])    
    SetFrenchCsvDownload(csv);    
  };

  const createcsvEnglish = () => {
    let csv = [];    
    let i = 0;
    for (i = 0; i < englishNames.length; i++) {
        csv.push([            
            englishNames[i],
            formats[i],
            brands[i],
            s3Images[i]
        ])
    }
    csv.unshift([        
        "Entry Name (English)",
        "Format",
        "Brand",
        "Image Link"
    ])    
    SetEnglishCsvDownload(csv);    
  };


  const updateSortedLabelData = (englishNames, frenchNames, formats, brands) =>{
      
    createcsv();
    createcsvEnglish();
    createcsvFrench();

    fetch(`https://apie.snapwrite.ca/storeData/labeling-categorization`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: objectID,
        projectName: projectName,
        images: s3Images,
        englishEntryName: englishNames,
        frenchEnglishName: frenchNames,
        format: formats,
        brand: brands
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });    
    }
  
  async function getSuggestions(string, name, imageURL) {
    try {
      let filename = name.substring(0, name.lastIndexOf(".")) || name;
      const res = await backendClient.post("/epipresto", {
        imgSrc: string.split(",")[1],
        user: props.userID
      });
      console.log(res.data)

      const imageName = imageFileName;
      imageName.push(filename);
      setImageFileName(imageName);
      // console.log(imageFileName)
      //s3 Image link
      const images = s3Images;
      images.push(imageURL);
      setS3Images(images);
      //Product type
      const englishName = englishNames;
      // console.log("types = " + type)
      englishName.push(res.data.entry_name_english.toString().replace(/,/g, " ") || "");
      //Product Gender
      let frenchName = frenchNames;
      frenchName.push(res.data.entry_name_french.toString().replace(/,/g, " ") || "");
      //Product Color
      let format = formats;
      format.push(res.data.format.toString().replace(/,/g, " ") || "");
      //Additional Information like material etc.
      let brand = brands;
      brand.push(
        res.data.brand.toString().replace(/,/g, " ") ||"");
      let array = rowsTemp;
      let csvArray = csvRowsTemp;
      array.push(
        [          
          res.data.entry_name_english.toString().replace(/,/g, " ") || "",
          res.data.entry_name_french.toString().replace(/,/g, " ") || "",
          res.data.format.toString().replace(/,/g, " ") || "",
          res.data.brand.toString().replace(/,/g, " ") ||
            "",
        ]        
      );
      // console.log(array)

      csvArray.push([
      
      ]);
      // console.log(array)
      setEnglishName(englishName);
      setFrenchName(frenchName);
      setFormat(format);
      setBrand(brand);
      csvSetRowsTemp(csvArray);
      setRowsTemp(array);
    } catch (e) {
      console.log("Please try using a different image");
      console.error(e);
    }
  }

  
  // console.log(imageFiles)
  const uploadImage = async (e) => {
    setRows("Loading...");

    const file = e.target.files;
    // console.log(e.target.files[0].name)
    const base64 = [];
    //for aws images link
    const imageURL = [];
    for (let index = 0; index < e.target.files.length; index++) {
      const imageFile = e.target.files[index];

      // get secure url from our server
      const { url } = await fetch("https://apie.snapwrite.ca/storeData/s3Url").then(
        (res) => res.json()
      );
      
      // post the image direclty to the s3 bucket
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
        },
        body: imageFile,
      }).catch((err) => console.error(err));
      imageURL.push(url.split("?")[0]);
      // const imageUrl = url.split('?')[0]
      // console.log(imageURL[index])

      base64.push(await convertBase64(file[index]));
      await getSuggestions(
        base64[index],
        e.target.files[index].name,
        imageURL[index]
      );
    }
    // const base64 = await convertBase64(file);
    // console.log(base64)
    setRows(rowsTemp);
    csvSetRows(csvRowsTemp);
    setImageFiles(base64);    
    createcsv();
    createcsvEnglish();
    createcsvFrench();
    storeData();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };      

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  
  const updateEnglishName = (event, index) => {
    const newName = englishNames.slice();
    newName[index] = event.target.value;
    setEnglishName(newName);    
    updateSortedLabelData(newName, frenchNames, formats, brands)
  };

  const updateFrenchName = (event, index) => {
    const newName = frenchNames.slice();
    newName[index] = event.target.value;
    setFrenchName(newName);
    updateSortedLabelData(englishNames, newName, formats, brands)
  };

  const updateFormat = (event, index) => {
    const newFormat = formats.slice();
    newFormat[index] = event.target.value;
    setFormat(newFormat);
    updateSortedLabelData(englishNames, frenchNames, newFormat, brands)        
  };
  const updateBrand = (event, index) => {
    const brand = brands.slice();
    brand[index] = event.target.value;
    setBrand(brand);
    updateSortedLabelData(englishNames, frenchNames, formats, brand)      
  };
  
  function storeData() {     
    fetch(`https://apie.snapwrite.ca/storeData/labeling-categorization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,       
        projectName: projectName,         
        images: s3Images,
        englishEntryName: englishNames,
        frenchEntryName: frenchNames,
        format: formats,
        brand: brands,
      }),
    })
      .then((response) => response.json())   
      .then(json=>setObjectID(json.data._id))   
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }
  const reset = () =>{
    setProjectName("")
    setS3Images([]);
    setImageFileName([]);    
    setEnglishName([]);
    setFrenchName([]);
    setFormat([]);
    setBrand([]);
    setRows([]);  
    objectID("");   
  }
  const updateProjectName = (event) => {        
    // console.log(event)

    setProjectName(event)
      if(objectID!=="") {        
        fetch(`https://apie.snapwrite.ca/storeData/labeling-categorization-projectNameUpdate`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({        
            id: objectID,      
            projectName: projectName            
          }),
        })
          .then((response) => response.json())      
          .catch((err) => {
            console.log(`Error ${err}`);
          });      
      }
  }

  return (
    <div className="descriptions" style={{background: "white"}}>
      {/* {console.log("noOfWordsGenerated = " + noOfWordsGenerated)} */}
      <Header
        reset={reset}
        name={"labels"}
        updateProjectName={updateProjectName}
        setProjectName={setProjectName}
        projectName={projectName}
      />
      {/* <hr width="100%;" /> */}
      {/* ******************************the <main> section****************************** */}
      <div className="interface" style={{background:"white"}}>
        <SideMenu />
        {/* The grey section where the user can upload image, edit tags and get the description */}
        <div className="playGround" style={{background:"white"}}>
          {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
          <div className="tools">
            <div className="bulk-upload">
              {s3Images.length === 0 && rows !== "Loading..." ? (
                <div className="dropPlatform">
                  <label htmlFor="myFile" className="dpc">
                    <div className="drop-zone">
                      <span className="drop-zone__prompt">
                        Click to upload your image
                      </span>
                    </div>
                  </label>
                  <input
                    multiple
                    type="file"
                    name="myFile"
                    id="myFile"
                    className="drop-zone__input"
                    accept="image/*"
                    onChange={(e) => uploadImage(e)}
                  />
                </div>
              ) : (
                <>
                  {rows === "Loading..." ? (
                    <h2 style={{ textAlign: "center" }}>Loading...</h2>
                  ) : (
                    <div id="observation-data">
                      <table>
                        <thead style={{ borderBottom: "1px solid" }}>
                          <tr>                            
                            <th>Item</th>
                            <th>Entry Name (English)</th>
                            <th>Entry Name (French)</th>
                            <th>Format</th>
                            <th>Brand</th>                            
                          </tr>
                        </thead>
                        <tbody id="rows">
                          {rows.map((key, index) => {
                            return rows[index] !== undefined &&
                              rows[index][1] !== undefined ? (
                              <tr>
                                {/* {console.log(rows)} */}
                                <td style={{ width: "10%" }}>
                                  <img
                                    src={s3Images[index]}
                                    draggable={false}
                                    alt={imageFileName[index]}
                                    style={{ width: "80%" }}
                                  />
                                </td>
                                <td>
                                  <textarea
                                    style={{                                      
                                      backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white",
                                      border: "none",
                                      width: "186px",
                                      resize: "none",
                                    }}
                                    onChange={(event) =>
                                        updateEnglishName(event, index)
                                    }
                                    value={rows[index][1] ? englishNames[index] : null}
                                    // value={rows[index][1]?rows[index][1]:null}
                                  />
                                  {/* {rows[index][1]?rows[index][1]:null} */}
                                </td>
                                <td>
                                  <textarea
                                    style={{
                                      backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white",
                                      border: "none",
                                      width: "186px",
                                      resize:"none"
                                    }}
                                    onChange={(event) =>
                                        updateFrenchName(event, index)
                                    }
                                    value={
                                      rows[index][2] ? frenchNames[index] : null
                                    }
                                  />
                                  {/* {rows[index][2]?rows[index][2]:null} */}
                                </td>
                                <td>
                                  <textarea
                                    style={{
                                      backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white",

                                      border: "none",
                                      width: "102px",
                                      resize: "none",
                                    }}
                                    onChange={(event) =>
                                      updateFormat(event, index)
                                    }
                                    value={
                                      rows[index][3] ? formats[index] : null
                                    }
                                  />
                                  {/* {rows[index][3]?rows[index][3]:null} */}
                                </td>
                                <td>
                                  <textarea
                                    style={{
                                      backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white",

                                      border: "none",
                                      height: "100%",
                                      resize: "none",
                                      width: "240px",
                                    }}
                                    onChange={(event) =>
                                      updateBrand(event, index)
                                    }
                                    value={
                                      rows[index][3]
                                        ? brands[index]
                                        : null
                                    }
                                  />
                                  {/* {rows[index][4]?rows[index][4]:null} */}
                                </td>
                              </tr>
                            ) : null;
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
              <div className="suggestions">
                <div className="tag-suggestions">
                  <ul className="suggestionTags">
                    <div className="downloadCSV" onClick={()=>createcsv()} >
                      <CSVLink className="btn" data={csvDownload}>
                        Generate CSV
                      </CSVLink>
                      </div>
                    <div className="downloadCSV" onClick={()=>createcsvEnglish()} >
                      <CSVLink className="btn" data={EnglishCsvDownload}>
                        Generate English CSV
                      </CSVLink>
                      </div>
                    <div className="downloadCSV">

                      <CSVLink className="btn" data={FrenchCsvDownload} onClick={()=>createcsvEnglish()} >
                        Generate French CSV
                      </CSVLink>
                    </div>
                    {/* <CSVLink data={csvDownload}>Download me</CSVLink> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategorizedTable;

/* ************************************************************* */