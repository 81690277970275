import React, { useState, useEffect } from 'react'
import { FormBackground } from '../components/formsBackground/background'
import { getAuth } from '@firebase/auth';
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import AuthenticationPageView from './AuthenticationPageView';


const Onboarding = () => {

    const history = useHistory()
    const auth = getAuth();
    const user = auth.currentUser;
    const [userID, setUserID] = useState()
    const [vintageSelected, setVintageSelected] = useState()
    const [resellerSelected, setResellerSelected] = useState()
    const [loading, setLoading] = useState(false)
    // const [pageNum, setPageNum] = useState(1)
    const [isShopifySelected, setIsShopifySelected] = useState()


    useEffect(() => {
      auth.onAuthStateChanged(user => {
        const token = localStorage.getItem("token");
        if (!token || !user) {
          history.push("/");
        }else {
          setUserID(user.uid);
              }
      })   
    },[])
    
    useEffect(() => {
      // Set the body's style to allow scrolling when the component mounts
      document.body.style.overflow = 'auto';
  
      // Clean up the effect by restoring the original overflow style when the component unmounts
      return () => {
        document.body.style.overflow = 'hidden';
      };
    }, []); 

    const Question2 = () => {
        return (
                    <div className="mt-8">
                      <div className='flex flex-col gap-5'>
                        <h2 className='text-xl'>What will you be using the app for?</h2>
                        <div style = {{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center"}}>
                            <button style = {{ fontSize:"16px",padding: "15px 20px", width: "80%", boxShadow: (vintageSelected === true && "0px 1px 0px lightgrey")}} onClick = {() => setVintageSelected(true)} className = {vintageSelected === undefined ? "btn-unselected" : (vintageSelected ? "btn" : "btn-unselected")}>Vintage Clothing</button>
                            <button style = {{ fontSize:"16px",padding: "15px 20px", width: "80%", boxShadow: (vintageSelected === false && "0px 1px 0px lightgrey")}} onClick = {() => setVintageSelected(false)} className = {vintageSelected === undefined ? "btn-unselected" : (!vintageSelected ? "btn" : "btn-unselected")}>Pre-Loved Clothing</button>
                            </div>
                        </div>
                        <div className='flex flex-col gap-5 my-16'>
                        <h2 className='text-xl'>Which e-commerce platform are you using?</h2>
                        <div style = {{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center"}}>
                            <button style = {{ fontSize:"16px",padding: "15px 20px", width: "80%", boxShadow: (isShopifySelected === true && "0px 1px 0px lightgrey")}} onClick = {() => setIsShopifySelected(true)} className = {isShopifySelected === undefined ? "btn-unselected" : (isShopifySelected ? "btn" : "btn-unselected")}>Shopify</button>
                            <button style = {{ fontSize:"16px",padding: "15px 20px", width: "80%", boxShadow: (isShopifySelected === false && "0px 1px 0px lightgrey")}} onClick = {() => setIsShopifySelected(false)} className = {isShopifySelected === undefined ? "btn-unselected" : (!isShopifySelected ? "btn" : "btn-unselected")}>Wix</button>
                            </div>
                        </div>
                        <button style = {{ fontSize:"16px",marginLeft: "65px",width:"150px", boxShadow: "0px 1px 0px lightgrey"}} onClick = {handleSubmit} className = "btn" disabled = {(vintageSelected === undefined || isShopifySelected === undefined) || loading} >Submit</button>                        
                    </div>
        )
    }

    /*
    const Question1 = () => {
        return (
            <div className="container">
                    <div className="centeringDivForContainer">
                        <h1>Are you a:</h1>
                        <br />
                        <br />
                        <div style = {{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center"}}>
                            <button style = {{padding: "15px 20px", width: "80%", boxShadow: (resellerSelected === true && "0px 1px 0px lightgrey")}} onClick = {() => setResellerSelected(true)} className = {resellerSelected === undefined ? "btn-unselected" : (resellerSelected ? "btn" : "btn-unselected")}>Reseller</button>
                            <button style = {{padding: "15px 20px", width: "80%", boxShadow: (resellerSelected === false && "0px 1px 0px lightgrey")}} onClick = {() => setResellerSelected(false)} className = {resellerSelected === undefined ? "btn-unselected" : (!resellerSelected ? "btn" : "btn-unselected")}>Seller</button>
                            <br />
                            <br />
                            
                            <button style = {{maxWidth: "60%", boxShadow: "0px 1px 0px lightgrey"}} onClick = {handleSubmit} className = "btn" disabled = {((pageNum === 1 && resellerSelected === undefined) || (pageNum === 2 && resellerSelected === undefined)) || loading} >Submit</button>
                        </div>
                        <br />
                        <br />
                        
                    </div>
                </div>
        )
    }
    */

  

  

  const updatePreference = async (name, val) => {
    try {
      const response = await axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", { name, val, userID });
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);

    const preference = vintageSelected ? "vintage" : "pre-loved";
    const exportPreference = isShopifySelected ? "shopify" : "wix";

    localStorage.setItem("preference", preference);
    localStorage.setItem("accountType", "reseller");

    await Promise.all([
      updatePreference("tablePreference", preference),
      updatePreference("exportPreference", exportPreference)
    ]);

    history.push(vintageSelected ? "/vintage" : "/bulk-shopify");

    setLoading(false);
  };

  return (
<AuthenticationPageView>
                {userID &&
                    // pageNum === 1 ? <Question1 /> : 
                    <Question2 />
                }
</AuthenticationPageView>
  )
}

export default Onboarding