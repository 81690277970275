import React, { useEffect, useState } from 'react';
import { getAuth } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import MainSideMenuHeaderContainer from '../containers/MainSideMenuHeaderContainer';
import { MuiThemeProviderComponent } from '../theme/ThemeContext';
import { Heading1 } from '../branded-elements/Elements';
import {
	Stack,
	styled,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Chip,
} from '@mui/material';
import axios from 'axios';
import { Link } from '@mui/icons-material';

export const VendorDashboard = () => {
	const [shopifyData, setShopifyData] = useState([]);
	const [totalConsignmentSales, setTotalConsignmentSales] = useState(0);
	const [vendorPayout, setVendorPayout] = useState(0);
	const [profit, setProfit] = useState(0);
	const auth = getAuth();

	const history = useHistory();

	const calculateVendorPayout = (sellingPrice, purchasePrice, vendorSplitPercentage) => {
		const payout = (sellingPrice - purchasePrice) * (vendorSplitPercentage / 100);
		return parseFloat(payout.toFixed(2));
	}

	const calculateProfit = (sellingPrice, purchasePrice, vendorSplitPercentage) => {
		const profit = (sellingPrice - purchasePrice) * (1 - (vendorSplitPercentage / 100));
		return parseFloat(profit.toFixed(2));
	}

	useEffect(() => {
		const checkUserAuthentication = () => {
			// Checking user authentication state
			auth.onAuthStateChanged(async (user) => {
				const token = localStorage.getItem('token');

				if (!token || !user) {
					history.push('/');
				} else {
					// function for state updates
					const response = await axios
						.get(
							`https://apie.snapwrite.ca/storeData/single-product-get-shopify-data/${user.uid}`
						)
						.then((res) => res)
						.catch((err) => console.log(err));
					setShopifyData(response.data.data);
					const foundTotalConsignmentSales =
						response.data.data.reduce(
							(acc, curr) =>
								curr.shopifyOrderFinancialStatus === 'paid'
									? acc + curr.shopifyItemPrice
									: acc,
							0
						);
					setTotalConsignmentSales(foundTotalConsignmentSales);

					const foundProfit = response.data.data.reduce(
						(acc, curr) =>
							curr.shopifyOrderFinancialStatus === 'paid'
								? acc +
								  calculateProfit(
										curr.shopifyItemPrice,
										curr.price || 0,
										curr.vendorDetails.vendorSplitPercentage
								  )
								: acc,
						0
					);

					setProfit(foundProfit);
					const foundVendorPayout = (foundTotalConsignmentSales - foundProfit).toFixed(2);
					setVendorPayout(foundVendorPayout);
				}
			});
		};

		checkUserAuthentication();
	}, []);

	

	const MuiItem = styled(Paper)(({ theme, color, bgColor }) => ({
		backgroundColor: bgColor,
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: color,
	}));

	const DashBoardItemsComponent = ({ color, bgColor, value, title }) => {
		return (
			<MuiItem color={color} bgColor={bgColor}>
				<div className='h-[120px] w-[340px] flex flex-col justify-center pt-4'>
					<p className='text-4xl font-medium '>{value}</p>
					<p className='text-sm tracking-wide'>{title}</p>
				</div>
			</MuiItem>
		);
	};

	const dashBoardItems = [
		{
			title: 'Total Consignment Sales',
			value: `$${Number(totalConsignmentSales).toFixed(2) || 0}`,
			color: '#000000',
			bgColor: '#E8FB0A',
		},
		{
			title: 'Vendor Payout',
			value: `$${Number(vendorPayout).toFixed(2) || 0}`,
			color: '#FFFFFF',
			bgColor: '#30334B',
		},
		{
			title: 'Your Profit',
			value: `$${Number(profit).toFixed(2) || 0}`,
			color: '#FFFFFF',
			bgColor: '#2E7D32',
		},
	];

	const tableHeader = [
		'Product',
		'Shopify Order',
		'Product(SKU)',
		'Vendor',
		'Price',
		'Vendor Split',
		'Vendor Payout',
		'Profit',
		'Payment Status',
		'Order Link',
	];

function formatDate(dateString) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0'); 
	const year = date.getFullYear();

	return `${day}-${month}-${year}`;
}
	return (
		<MainSideMenuHeaderContainer>
			<MuiThemeProviderComponent>
				<section>
					<Heading1 title={'Orders'} />
					<Stack
						direction='row'
						justifyContent={'center'}
						spacing={3}
						paddingTop={2}
					>
						{dashBoardItems.map((item, idx) => (
							<DashBoardItemsComponent
								key={idx}
								title={item.title}
								value={item.value}
								color={item.color}
								bgColor={item.bgColor}
							/>
						))}
					</Stack>
					<Table
						sx={{
							marginTop: '50px',
						}}
					>
						<TableHead>
							<TableRow>
								{tableHeader.map((header, idx) => (
									<TableCell
										key={idx}
										sx={{
											fontWeight: '600',
										}}
									>
										{header}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{shopifyData.map((data, i) => (
								<TableRow key={i}>
									<TableCell>
										<img src={data.images} alt='product' className='h-20' />
									</TableCell>

									<TableCell sx={{
										display: 'flex',
										flexDirection: 'column',
									}}>
										<TableCell sx={{outline:"none", border:"none"}}>
											<p className='text-xs text-zinc-500 -mb-[1px]'>Created</p>
											{formatDate(data.shopifyOrderCreatedAt)}
										</TableCell>
										<TableCell sx={{outline:"none", border:"none"}}>
											<p className='text-xs text-zinc-500 -mb-[1px]'>Updated</p>

											{formatDate(data.shopifyOrderUpdatedAt)}
										</TableCell>
									</TableCell>
									<TableCell>{data.sku}</TableCell>
									<TableCell>{data.vendorDetails.displayName}</TableCell>
									<TableCell>{data.shopifyItemPrice}</TableCell>
									<TableCell>
										{data.vendorDetails.vendorSplitPercentage}
									</TableCell>
									<TableCell>{
										calculateVendorPayout(data.shopifyItemPrice, data.price || 0, data.vendorDetails.vendorSplitPercentage)
										}</TableCell>
									<TableCell>
										{calculateProfit(data.shopifyItemPrice, data.price || 0, data.vendorDetails.vendorSplitPercentage)}
									</TableCell>
									<TableCell>
										<Chip
											color={
												data.shopifyOrderFinancialStatus === 'paid'
													? 'success' : (data.shopifyOrderFinancialStatus === 'voided' ||
														data.shopifyOrderFinancialStatus === 'refunded'
													) ? 'error' : 'warning'
											}
											label={data.shopifyOrderFinancialStatus ? data.shopifyOrderFinancialStatus.toUpperCase() : ''} />
									</TableCell>
									<TableCell>
										<a href={data.shopifyOrderStatusURL} target='_blank' rel='noreferrer'><Link /> </a>
										</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</section>
			</MuiThemeProviderComponent>
		</MainSideMenuHeaderContainer>
	);
};
