import updateSKUstart from './skuCountUpdate';

const generateSKU = async (userID) => {
	let previousSKU = 999;

	try {
		const response = await fetch(
			`https://apie.snapwrite.ca/storeData/user-preferences-sku/${userID}`
		);
		const data = await response.json();
		previousSKU = data.data;
	} catch (error) {
		console.error('Error fetching previous SKU:', error);
	}

	const currentSKUcount = previousSKU + 1;
	let sku = '';

	if (
		userID === '5APyYorrgoZ8uKOcxp5YMz3J0zq1' ||
		userID === 'b41Z6rL6eJN1VccgZXrUzWbdjGg1'
	) {
		sku = 'B-' + String(currentSKUcount).padStart(4, '0');
	} else if (
		userID === "lUrOR5b9i9TYSNJ4zEfGRTkoPqs1" ||
		userID === "WbahZZa5HhfWAa6SyJLYnl31kMG3"
	) {
		const date = new Date();
		const year = date.getFullYear().toString().slice(-2);
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = (date.getDate()).toString().padStart(2, '0');
		sku = String(currentSKUcount) + ' N' + day + month + year
	} else {
		const date = new Date();
		const year = date.getFullYear().toString().slice(-2);
		const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
		const month = monthNames[date.getMonth()];		
		const day = date.getDate().toString().padStart(2, '0');
		const hour = date.getHours().toString().padStart(2, '0');
		const minute = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(3, '0');
		sku = `${currentSKUcount}-${day}${month}${year}-${hour}${minute}${seconds}`;
	}

	try {
		await updateSKUstart(currentSKUcount, userID);
	} catch (error) {
		console.error('Error updating SKU start:', error);
	}
  return sku
};

export default generateSKU;
