import { closureLocation } from "./closureLocation";
import { closurePlacketPresence } from "./closurePlacketPresence";
import { closurePresence } from "./closurePresence";
import { closureSymmetry } from "./closureSymmetry";
import { closureType } from "./closureType";

const full = [
    "Whole",
    "Complete",
    "Full",
    "Entire",
    "Total",
    "Absolute",
    "Comprehensive",
    "Exhaustive",
    "Thorough",
    "Perfect",
    "Sufficient",
    "Inclusive",
    "Undivided",
    "All-encompassing",
  ];
  
  const partial = [
    "Incomplete",
    "Limited",
    "Restricted",
    "Selective",
    "Segmented",
    "Partway",
    "Fractional",
    "Unfinished",
    "partial"
  ];
  
  const none = [
    "Zero",
    "Lack",
    "Absence",
    "Without",
    "No",
    "Non",
    "Missing",
    "none"
  ];

  const synonymsArray = [full, partial, none, ...closureLocation, ...closurePlacketPresence, ...closurePresence, ...closureSymmetry, ...closureType]
  

export const getClosureSynonyms = (closure) => {
    console.log(closureLocation)
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => closure.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    console.log(toReturn)
    return toReturn;
} 