import React, { useEffect, useState } from "react";
import { getAuth } from '@firebase/auth';
import MultiImage from "./shopify/featureTable";

import {  onAuthStateChanged } from '@firebase/auth';
export function Features({history}) {
  const auth = getAuth();
  const user = auth.currentUser;
  const [proceed, setProceed] = useState(false)
  const [userID, setUserID] = useState()

  useEffect(() => {
    auth.onAuthStateChanged(user => {
			const token = localStorage.getItem("token");
			if (!token || !user) {
				history.push("/");
			}else {
        setUserID(user.uid);
        setProceed(true)
            }
    })   
  },[])

  return (    <>{proceed?               <MultiImage                                                                
    userID={userID}
    template="preLovedKids"
  />  :null}</>
                                 
  );
}
export default Features;
