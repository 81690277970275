import { useParams } from "react-router";
import { Header } from "../../navigation/header.js";
import SideMenu from "../../sideMenu.js";
import ImageUploadCode from "../../../products/imageUpload.js";
import TagsInput from "../../../products/tagsArea.js";
import axios from "axios";
import copy from "copy-to-clipboard";
import {useEffect,useState} from "react";
import {wordCount, characterCount, sentenceCount, tagsList, keywordsMatched} from "../../wordCountArea.js"

const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
  });
  

const ProductDescriptionPage = ({history}) => {
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
        history.push('/')
    }
},[])
    const {id} = useParams();

    const [descriptionData, setDescriptionData] = useState({
        imageUploaded:"",
        suggestedTags:[''],
        tagsUsed: [""],
        generatedDescriptions:[""],                
    })


    useEffect(()=>{
        // console.log("we are here; id = "+id)
        //communicate with the backend!
        fetch(`https://apie.snapwrite.ca/storeData/image-description/${id}`)
        .then(response=>response.json())
        .then(json=>{
            //We updated the data returned from the Backed with the resort state
            setDescriptionData(json.data);
        })
        .catch(err=>{
            console.log(`Error ${err}`)
        })
    },[])

    const updateStoredDescription = () =>{
      if(id === JSON.parse(localStorage.getItem("imageDescriptionObjectID"))){
        localStorage.setItem("productDescriptions", JSON.stringify(descriptionData.generatedDescriptions))   
      }

      fetch(`https://apie.snapwrite.ca/storeData/image-description`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({        
          id: id,
          generatedDescriptions: descriptionData.generatedDescriptions      
        }),
      })
        .then((response) => response.json())      
        .catch((err) => {
          console.log(`Error ${err}`);
        });    
    }

    async function reGenerateDescription(tags) {      
        try {
          if(descriptionData.generatedDescriptions.length < 3){
              let descriptions = descriptionData.generatedDescriptions
              descriptions.push("Loading...")
            setDescriptionData({...descriptionData, generatedDescriptions : descriptions})
            // console.log("regenerate")
            const res = await backendClient.post("/description", {
              tags: tags,
              user: descriptionData.userID
            });      
            let description = res.data;    
            if(res.data[0]==='\n'){
              description = description.slice(1)
            }
            // console.log(descriptions)
            descriptions[descriptions.length-1] = description
            
            setDescriptionData({...descriptionData, generatedDescriptions : descriptions})
            updateStoredDescription();
            
          }
        } catch (e) {          
          alert("Error Occurred! Try again. If retry does not work contact us :)")          
          console.error(e);
        }
      }

      const updateDescription = (index, event) =>{
        const descriptions = descriptionData.generatedDescriptions;
        descriptions[index] = event.target.value;   
        setDescriptionData({...descriptionData, generatedDescriptions : descriptions})
        updateStoredDescription();
      }
    
    return (
        <div className="descriptions">
        <Header />
        {/* <hr width="100%;" /> */}
        {/* ******************************the <main> section****************************** */}
        <div className=" interface projectInterface">
        <SideMenu />
          {/* The grey section where the user can upload image, edit tags and get the description */}
          <div className="playGround">
            {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
            <div className="tools">
              <div className="drop-and-tags">
                {/* ************The image upload area************** */}
                <ImageUploadCode
                   selectedTags={descriptionData.suggestedTags}
                   setDescriptionData={setDescriptionData}
                   tags={descriptionData.tags}
                   suggestedTags={descriptionData.suggestedTags}
                   image={descriptionData.imageUploaded}
                   descriptionData = {descriptionData}
                />                            
                {/* ************************************************ */}
                <div className="card">
                  <div className="card-header">
                    {" "}
                     Tags {" "}
                  </div>
                  <div className="card-body appTag">
                    {/* **************************Tags area************************** */}
                    <div className="appTag">
                      <TagsInput
                        descriptionData={descriptionData}
                        tags={descriptionData.tagsUsed}
                        setDescriptionData={setDescriptionData}   
                      />
                    </div>
                    <div className="tagsNumberDesc">
                      Add 6-7 tags
                    </div>
                    <button
                      className="btn btn-primary1"
                      disabled={descriptionData.generatedDescriptions.length>3}
                      onClick={() => {
                        // console.log("got clicked");                                  
                        reGenerateDescription(descriptionData.tagsUsed)
                        // genDescription2(tags);
                      }}                     
                    >
                      Re-Generate
                    </button>
                  </div>
                </div>
                {/* ************************************************************* */}
              </div>
              <div className="descriptionSection">
                {/* {console.log(descriptionData.generatedDescriptions)} */}
                {descriptionData.generatedDescriptions.map((description, index) => {
                    // console.log("index = "+ index)
                  return(
                  <div className="card  card1 card2">
                  <div className="card-header ">Product Description {index+1}</div>
                  <div className="card-body desc-from-image">
                    {/* <p> {prodDescription1} </p> */}
                    {/* {prodDescription1!=="Your product description will appear in about 10 seconds" ?  */}
                    <textarea      
                       name="blogOutline"
                       type="text"
                       value={description}                                            
                       onChange = {(event)=>{
                        updateDescription(index, event)
                      }}
                      style={{minHeight:"21px", height: `${((characterCount(description)/61)*23)+(description.includes('\n') ? 20 : 0)}px`}}

                   />  
                   {/* :
                    prodDescription1  } */}
                  </div>
                  <div className="card-footer">
                    <div className="wordCountArea">
                      <div className="p-2 bd-highlight">
                        Count (Character / Word / Sentence) : {characterCount(descriptionData.generatedDescriptions[index])} / {wordCount(descriptionData.generatedDescriptions[index])} / {sentenceCount(descriptionData.generatedDescriptions[index])}
                        <br /> Keywords matched: {keywordsMatched(descriptionData.tagsUsed, descriptionData.generatedDescriptions[index])}/{descriptionData.tagsUsed ? descriptionData.tagsUsed.length : 0}&nbsp;                      
                        <select className="dropdownTags" name="TagsList" id="TagsList">
                          {
                             tagsList(descriptionData.tagsUsed, descriptionData.generatedDescriptions[index])
                          }                        
                        </select>
                      </div>
                      
                      <div className="copyButtonIcon"  color="grey"
                              onClick={()=>copy(description)}>
                            copy
                          </div>
                    </div>
                  </div>
                </div>
                )}
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
        
    )
}


export default ProductDescriptionPage