const drawstring = [
    "cords",
    "ties",
    "strings",
    "pulls",
    "drawcords",
    "drawstring"
  ];
  
  const adjustable = [
    "Flexible",
    "Adaptable",
    "Modifiable",
    "Customizable",
    "Chanable",
    "Versatile",
    "adjustable"
  ];

  const synonymsArray = [drawstring, adjustable]

export const getDrawstringSynonyms = (drawstring) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => drawstring.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 