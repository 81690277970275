import {
	Grid,
	ListItem,
	Stack,
	TextField,
	Tooltip,
	ListItemAvatar,
	Avatar,
	ListItemText,
	Divider,
	Button,
	Typography,
	NativeSelect,
	InputLabel,
} from '@mui/material';
import { stringAvatar } from '../../components/navigation/header';
import React, { useState } from 'react';
import { List } from 'reactstrap';

const AccountAndOrganization = ({
	setOrganization,
	organization,
	organizationName,
	userID,
	setOrganizationID,
	organizationID,
	setOrganizationName,
	organizationMembers,
	exportPreference,
	setExportPreference
}) => {
	const updateOrganizationName = () => {
		// localStorage.setItem("organizationName", organizationName);
		setOrganization({ ...organization, name: organizationName });
		const body = {
			name: 'organizationName',
			val: organizationName,
			userID: userID,
		};
		fetch(
			'https://apie.snapwrite.ca/storeData/user-preferences-updateVal',
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(body),
			}
		);
	};

  const handleExportPreferenceUpdate = (value) => {
    const body = {
      name: 'exportPreference',
      val: value,
      userID: userID,
    };
    fetch(
      'https://apie.snapwrite.ca/storeData/user-preferences-updateVal',
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    );
  }

	return (
		<>
			<h2 className=' text-lg my-3'>Organization Information</h2>
			<Grid container spacing={5} sx={{ paddingTop: '10px' }}>
				<Grid item xs={6}>
					<Tooltip
						title={
							<h3 style={{ fontSize: 12 }}>
								This is the ID of the account that is shared.
								Put the ID of the account whose data that you
								want to see and edit HERE.
							</h3>
						}
					>
						{/* title="This is the ID of the account that is shared. Put the ID of the account whose data that you want to see and edit HERE."> */}

						<Stack>
							<TextField
								disabled
								variant='outlined'
								label='Organization ID'
								fullwidth
								onChange={(e) =>
									setOrganizationID(e.target.value)
								}
								value={organizationID || ''}
							/>
							{/* <Button variant="contained" onClick={()=>updateOrganizationID()}>Save</Button> */}
						</Stack>
					</Tooltip>
				</Grid>
				<Grid item xs={6}>
					<Tooltip
						title={
							<h3 style={{ fontSize: 16 }}>
								This is your account's ID. Share this ID with
								your other accounts so they can all see and edit
								THIS account's data.
							</h3>
						}
					>
						{/* <Tooltip title="This is your account's ID. Share this ID with your other accounts so they can all see and edit THIS account's data."> */}

						<Stack direction='row'>
							<TextField
								disabled
								label='Your ID'
								sx={{ width: '100%' }}
								value={userID}
							/>
							{/* <CopyToClipboardButton text={userID}/> */}
						</Stack>
					</Tooltip>
				</Grid>

				<Grid item xs={6}>
					<Tooltip
						title={
							<h3 style={{ fontSize: 16 }}>
								This is the Name of the account that is shared.
								This name will be used when sending emails
							</h3>
						}
					>
						{/* title="This is the ID of the account that is shared. Put the ID of the account whose data that you want to see and edit HERE."> */}

						<Stack>
							<TextField
								variant='outlined'
								label='Organization Name'
								fullwidth
								onChange={(e) =>
									setOrganizationName(e.target.value)
								}
								value={organizationName || ''}
							/>
						</Stack>
					</Tooltip>

					<Button
						variant='contained'
						sx={{
							marginTop: 3,
							fontSize: '14px',
							backgroundColor: 'rgba(0, 0, 0, 0.12)',
							color: 'rgba(0, 0, 0, 0.38)',
							boxShadow: 'none',
							textTransform: 'none',
							'&:hover': { color: 'white', boxShadow: 'none' },
						}}
						onClick={() => updateOrganizationName()}
					>
						Save
					</Button>
				</Grid>

				{/* Export Preference  */}
				<Grid item xs={6}>
					
						{/* title="This is the ID of the account that is shared. Put the ID of the account whose data that you want to see and edit HERE."> */}

						<Stack>
							<InputLabel
								variant='standard'
								htmlFor='exportPreference'
							>
								E-commerce Export Preference
							</InputLabel>
							<NativeSelect
								value={exportPreference}
								inputProps={{
									name: 'exportPreference',
									id: 'exportPreference',
								}}
                onChange={(e) =>
                 { setExportPreference(e.target.value)
                  handleExportPreferenceUpdate(e.target.value)
                
                }
                }
							>
								<option value={'shopify'}>Shopify</option>
								<option value={'wix'}>Wix</option>
							</NativeSelect>
						</Stack>
				</Grid>
			</Grid>
			<div className=' h-[1px] my-10 bg-slate-200'></div>

			{organization?.id === userID && (
				<div>
					<h2 className=' text-xl my-2'>Members</h2>
					<AlignItemsList users={organizationMembers} />
				</div>
			)}
		</>
	);
};

function AlignItemsList({ users }) {
	return (
		<List
			sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
		>
			{users.map((user) => (
				<>
					<ListItem alignItems='flex-start'>
						<ListItemAvatar>
							<Avatar {...stringAvatar(user.displayName)} />
						</ListItemAvatar>
						<ListItemText
							primary={user.displayName}
							secondary={
								<React.Fragment>
									<Typography
										sx={{ display: 'inline' }}
										component='span'
										variant='body2'
										color='text.primary'
									>
										{user.email}
									</Typography>
								</React.Fragment>
							}
						/>
					</ListItem>
					<Divider variant='inset' />
				</>
			))}
		</List>
	);
}

export default AccountAndOrganization;
