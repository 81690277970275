import React, { createContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { themeOptions } from './theme';

// Create a MUI theme instance.
const muiTheme = createTheme(themeOptions);

// Create a MUI context
const MuiThemeContext = createContext();

// Create a MUI provider
const MuiThemeProviderComponent = ({ children }) => {
	return (
		<ThemeProvider theme={muiTheme}>
			<MuiThemeContext.Provider value={muiTheme}>
				{children}
			</MuiThemeContext.Provider>
		</ThemeProvider>
	);
};

export { MuiThemeContext, MuiThemeProviderComponent };
