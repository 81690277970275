const long = [
    "Full",
    "Extended",
    "Lengthy",
    "long"
  ];
  
  const three_quarter = [
    "¾",
    "3/4",
    "Mid",
    "Moderate",
    "between",
    "three quarter"
  ];
  
  const ruffles = [
    "Butterfly",
    "Wave",
    "Ripple",
    "Ridge",
    "Frilled",
    "ruffle"
  ];
  
  const short = [
    "Tee",
    "Casual",
    "Brief",
    "Petite",
    "Small",
    "Mini",
    "Compact",
    "Miniature",
    "Shortened",
    "short"
  ];
  
  const drop_shoulder_sleeve = [
    "Wide",
    "Raglan",
    "off shoulder",
    "drop shoulder",
    "shoulder"
  ];

  export const sleeveType = [long, three_quarter, ruffles, short, drop_shoulder_sleeve]