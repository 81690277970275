import React, { useState, useEffect } from "react";
import { RowColumn } from "../tableList";

const PropertiesColumn = (props) =>{
		const disabled = props.disabled
		const prop = props.prop;
    const index= props.index;    
			
    const tx = document.getElementsByClassName("attribute-textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
      tx[i].addEventListener("input", OnInput, false);
    }

    function OnInput() {
      this.style.height = 0;
      this.style.height = (this.scrollHeight) + "px";
    }

    return(
        <td>
            <div style={{width:"230px"}} ></div>
            {(props.prop.itemGenericType[index] && (props.prop.itemGenericType[index].toLowerCase().includes('dress') || 
             props.prop.itemGenericType[index].toLowerCase().includes('jackets') ||
             props.prop.itemGenericType[index].toLowerCase().includes('pant') ||
             props.prop.itemGenericType[index].toLowerCase().includes('upper') ||
             props.prop.itemGenericType[index].toLowerCase().includes('skirt')||
             props.prop.itemGenericType[index].toLowerCase().includes('footwear') ))
             ?<>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="style" label="Style"/>
            </>:null
            }         
             {(props.prop.itemGenericType[index] && (props.prop.itemGenericType[index].toLowerCase().includes('dress') || 
             props.prop.itemGenericType[index].toLowerCase().includes('nightwear') ||
             props.prop.itemGenericType[index].toLowerCase().includes('overalls') ||
             props.prop.itemGenericType[index].toLowerCase().includes('upper'))) ?<>                 
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="sleeves" label="Sleeves"/>
            </>:null
            }  
            {(props.prop.itemGenericType[index] && (props.prop.itemGenericType[index].toLowerCase().includes('dress') || 
             props.prop.itemGenericType[index].toLowerCase().includes('nightwear') ||
             props.prop.itemGenericType[index].toLowerCase().includes('overalls') ||
             props.prop.itemGenericType[index].toLowerCase().includes('upper'))) ?<>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="cut" label="Cut"/>
            </>:null
            }        
          
          {(props.prop.itemGenericType[index] && (props.prop.itemGenericType[index].toLowerCase().includes('dress') || 
             props.prop.itemGenericType[index].toLowerCase().includes('jackets') ||
             props.prop.itemGenericType[index].toLowerCase().includes('overalls') ||
             props.prop.itemGenericType[index].toLowerCase().includes('upper')))?<>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="fasteningType" attr="fastening_type" label="Fastening Type"/>
            </>:null
            }          
              {(props.prop.itemGenericType[index] && (props.prop.itemGenericType[index].toLowerCase().includes('dress') || 
             props.prop.itemGenericType[index].toLowerCase().includes('jackets') ||
             props.prop.itemGenericType[index].toLowerCase().includes('pants') ||
             props.prop.itemGenericType[index].toLowerCase().includes('nightwear') ||
             props.prop.itemGenericType[index].toLowerCase().includes('skirts')))?<>
             
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="length" label="Length"/>
            {(props.prop.itemGenericType[index] && props.prop.itemGenericType[index].toLowerCase().includes('dresses')) ?
            <>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="skirt" label="Skirt"/>
            </>
                :null
            }
            </>:null
            }                  
            {(props.prop.itemGenericType[index] && (props.prop.itemGenericType[index].toLowerCase().includes('dresses') || 
             props.prop.itemGenericType[index].toLowerCase().includes('overalls') ||
             props.prop.itemGenericType[index].toLowerCase().includes('upper'))) && (props.prop.neckline[index]) ?<>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="neckline" label="Neckline"/>
            </>:null
            } 
             {(props.prop.itemGenericType[index] && !props.prop.itemGenericType[index].toLowerCase().includes('footwear'))
              ?<>

						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="design" label="Design"/>
            </>:null
            } 
              {(props.prop.itemGenericType[index] && props.prop.itemGenericType[index].toLowerCase().includes('footwear'))
              ?<>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="heelHeight" label="Heel Height"/>
            </>:null
            } 
              {(props.prop.itemGenericType[index] && props.prop.itemGenericType[index].toLowerCase().includes('footwear'))
              ?<>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="heelShape" label="Heel Shape"/>
            </>:null
            } 
             {(props.prop.itemGenericType[index] && props.prop.itemGenericType[index].toLowerCase().includes('footwear'))
              ?<>
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="height" label="Height"/>
            </>:null
            } 
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="material" label="Material"/>
            {props.prop.userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || props.prop.userID==='t4gFVUQejYQYBY6dhwOp2mkYMTe2' || props.prop.userID==='WbahZZa5HhfWAa6SyJLYnl31kMG3' || props.prop.template==='vintage'
              ?<>
            {/* {(props.prop.userID!=='1eMplQPlHHhX9lnwX8NTcKghui22' && props.prop.userID!=='7eXVFZlMcgY7HMKKSkCf4kb7qEt1' && props.prop.userID!=='FYLY9osdEkU7QtLsTgtmitcmqAD2' && props.prop.userID!=='80saF2wZqdRUhagajkE83wam8Js2') ? 
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="era" label="Era"/>
						:
            null} */}
						<RowColumn disabled={disabled} type="propertyItem" prop={prop} index={index} name="madeIn" label="Made In"/>
            </>:null
            } 

            
        </td>
    )
  

}

export default PropertiesColumn;
