import { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Table } from "reactstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Header } from "../components/navigation/header";
import Button from "@mui/material/Button";
// * importing known brand data set

const BrandAggregator = () => {
  const [data, setData] = useState({});
  const [brandMap, setBrandMap] = useState({});
  const [brandCount, setBrandCount] = useState(0);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [barChartData, setBarChartData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [barChartDataSlice, setBarChartDataSlice] = useState([]);
  const [showSellerCard, setShowSellerCard] = useState(false);
  const [clickedBrand, setClickedBrand] = useState("");
  const [clickedSellers, setClickedSellers] = useState("");
  const [userMap, setUserMap] = useState({});
  const [selectedUser, setSelectedUser] = useState();
  const [searchedBrand, setSearchedBrand] = useState();
  const [sellerToBrandMap, setSellerToBrandMap] = useState({});
  const [users, setUsers] = useState([]);
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);
  const [fetchedBrands, setFetchedBrands] = useState([]);

  // fuzzy string identifier using Levenshtien Distance
  const fuzzyMatch = (a, b) => {
    const matrix = [];

    if (a.length > b.length) {
      [a, b] = [b, a];
    }
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (let i = 0; i <= a.length; i++) {
      matrix[0][i] = i;
    }
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        const substitutionCost = b[i - 1] === a[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1, // insert cost
          matrix[i][j - 1] + 1, // deletion cost
          matrix[i - 1][j - 1] + substitutionCost // substitution cost
        );
      }
    }
    return matrix[b.length][a.length];
  };

  // call getBrands to get an array of all known brand names **important
  const fetchBrands = async () => {
    const brands = await axios.get(
        `https://apie.snapwrite.ca/storeData/brands`
    );
    setFetchedBrands(brands.data.data.brands);
  };

  // normalize the brand name, remove "+", "and", "&" to get the lowest distance in fuzzy search algorithm
  function normalizeName(name) {
    let normalizedName = name
      .replace(/\s/g, "") 
      .replace(/[+&]/g, "")
      .replace(/[^\w\s]/gi, "") 
      .replace(/and/gi, "") 
      .toLowerCase(); 

    return normalizedName;
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setScrollButtonVisible(scrollTop > 300); // Show the button when scrolling down beyond a certain threshold
    };
    
    fetchBrands();

    window.addEventListener("scroll", handleScroll);

    const excludedIDs = [
      "JFAt0oYNxmSAQ5ZpOmp0tWHPVKr1",
      "kniIJeElNSNFjczAh5WM4zPaRA93",
      "t4gFVUQejYQYBY6dhwOp2mkYMTe2",
      "xHaYXalwv8X8RpBLZju0PaQlWW23",
      "NpGB6zItW1OcpizZJbD88VsfKlf2",
      "1BILFjc4JNM0LDmmOUfdr2rRfNH3",
      "HtbDQe2tbTbT4iBfWolcppmNoKB3",
      "xqWcP58dezY1MGd8J249dDairz52",
      "7e2jk8rKF4bIHH6VG7XkaXwJDfy1",
      "9g3hm4TNYoYEpr7fNYbq5SEnyxE3",
      "ZFoq2NIsDmZgVTW4g88O1B8JA822",
      "PoMQ2ajEgAUmqDr4zuN0jsd62Bm2",
      "ER94ozEpmsYrHRI7zQyyiYBXDQK2",
      "18Q6tREHEzaKaqQDOE5qXSSEDp93",
      "VKxbpCdrc3RsY1s9yMenv7XL9jZ2",
      "vC48AynZx6RtkCu0K5czc6rAAxa2",
      "krPfCPh78qca1v4WyUjgncnvxs62",
      "0wt9yNqZMxZen1NfDw1BH3QbjSM2",
      "S4nF6oUynvUfkVQjTIcyqyCPZQg2",
      "1eMplQPlHHhX9lnwX8NTcKghui22",
      "80saF2wZqdRUhagajkE83wam8Js2",
    ];

    // fetch users and map uid to display name
    let userMapping = {};
    let users = [];
    axios
      .get("https://apie.snapwrite.ca/storedata/users")
      .then((res) => {
        res.data.forEach((user) => {
          const { uid, displayName } = user;
          if (!excludedIDs.includes(uid)) {
            userMapping[uid] = displayName;
            users.push(uid);
          }
        });
        setUserMap(userMapping);
        setUsers(users);

        const endpoints = [
          "labeling-clothes-categorization",
          "regular-preLoved-apparel",
          "new-apparel",
          "vintage-apparel"
        ];
        let allProducts = [];
        Promise.all(
          users.map((user) =>
            Promise.all(
              endpoints.map((endpoint) =>
                axios
                  .get(
                    `https://apie.snapwrite.ca/storeData/${endpoint}?userID=${user}`
                  )
                  .then((res) => {
                    allProducts = [...allProducts, ...res.data.data];
                  })
              )
            )
          )
        ).then((results) => {
          // Process the combined data here
          let userToBrandMap = {};
          const brandData = allProducts.reduce((acc, obj) => {
            if (excludedIDs.includes(obj.userID)) {
              return acc; // Skip objects uploaded by an excluded user
            }
            if (!obj.brand) {
              return acc; // Skip objects that don't have a brand property
            }
            if (!obj.userID) {
              return acc;
            }
            if (obj === undefined) {
              return acc;
            }
            obj.brand.forEach((brandName, i) => {
              if (brandName) {
                let originalName = brandName
                if (brandName == 'Hershel') {
                  brandName = 'Herschel Supply Co.'
                } else if (brandName == 'Romey Lulu' || brandName == 'Romy Heart Lulu') {
                  brandName = 'Romey Loves Lulu'
                } else if (brandName == 'Hudson’s Bay Company') {
                  brandName = "Hudson's Bay"
                } else if (brandName.includes('Harley')) {
                  brandName = 'Harley Davidson'
                } else if (brandName.includes('YSL')) {
                  brandName = 'Yves Saint Laurent'
                } else if (brandName.includes('Levi')) { 
                  brandName = "Levi's"
                }
                // clean the brand name to be used as a key - remove all spaces and symbols
                let normalizedBrandName = normalizeName(brandName)
                // match unfiltered brand name to a known brand by smallest distance
                let bestMatch = { brand: null, distance: Infinity };
                for (let knownBrand of fetchedBrands) {
                  let normalizedKnown = normalizeName(knownBrand)
                  let distance = fuzzyMatch(normalizedBrandName, normalizedKnown);
                  if (distance < bestMatch.distance || distance == 0) {
                    bestMatch = { brand: knownBrand, distance: distance };
                  }
                }
                // if distance >= 6, look reduce the string to look for only brand name, ex. "Zara Sweater" -> "Zara"
                let found = fetchedBrands.find(knownBrand => brandName.includes(knownBrand));
                if (found && bestMatch.distance >= 6) {
                  let foundBrand = fetchedBrands.find(knownBrand => brandName.includes(knownBrand));
                  let normalizedFoundBrand = normalizeName(foundBrand)
                  for (let knownBrand of fetchedBrands) {
                    let normalizedKnown = normalizeName(knownBrand)
                    let distance = fuzzyMatch(normalizedFoundBrand, normalizedKnown);
                    if (distance < bestMatch.distance || distance == 0) {
                      bestMatch = { brand: knownBrand, distance: distance };
                    }
                  }
                  brandName = bestMatch.brand
                // if brand name does not exist in fetchedBrands, add it to the json file and use original name 
                } else if (!found && bestMatch.distance >= 6) {
                  brandName = originalName
                // if distance is below 6, brand name matches to existing name
                } else {
                  brandName = bestMatch.brand;
                }

                if (!userToBrandMap[obj.userID]) {
                  userToBrandMap[obj.userID] = {};
                }
                if (!userToBrandMap[obj.userID][brandName]) {
                  userToBrandMap[obj.userID][brandName] = 1;
                } else {
                  userToBrandMap[obj.userID][brandName]++;
                }

                if (!acc[brandName]) {
                  // If brand name isn't already in the map, add it with initial values
                  try {
                    acc[brandName] = {
                      count: 1,
                      sellers: {},
                      totalPrice: parseFloat(obj.price[i]) || 0,
                      totalCost: parseFloat(obj.cost[i]) || 0,
                      conditions: [obj.condition[i] || "Unknown"],
                      genders: [obj.gender[i] || "Unknown"],
                      agesTotal: obj.age[i] || 0,
                    };
                  } catch {}
                } else {
                  // Otherwise, update the values for the existing brand name
                  try {
                    const data = acc[brandName];
                    data.count++;
                    data.totalPrice += parseFloat(obj.price[i]) || 0;
                    data.totalCost += parseFloat(obj.cost[i]) || 0;
                    data.agesTotal += obj.age[i] || 0; // Unsure how to effectively use age data
                    data.conditions.push(obj.condition[i] || "Unknown");
                    data.genders.push(obj.gender[i] || "Unknown");

                    if (!data.sellers[obj.userID]) {
                      data.sellers[obj.userID] = 1;
                    } else {
                      data.sellers[obj.userID] += 1;
                    }
                  } catch {}
                }
              }
            });
            return acc;
          }, {});
          setSellerToBrandMap(userToBrandMap);
          setBrandMap(brandData);

          // reformat map to work with recharts
          const chartData = Object.keys(brandData)
            .map((brandName) => ({
              name: brandName,
              value: brandData[brandName].count,
            }))
            .sort((a, b) => b.value - a.value);
          setBarChartData(chartData);
          setBarChartDataSlice(chartData.slice(0, 7));
        });
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  function handleBrandSubmit(e) {
    e.preventDefault();
    if (brandMap[brandInputValue]) {
      setBrandCount(brandMap[brandInputValue].count);
    } else {
      setBrandCount(0);
    }
  }

  function handleClickSellers(brand) {
    setClickedBrand(brand);
    /*
      fetchSellerNames(brandMap[brand].sellers)
      .then((result) => setClickedSellers(result))
      .then(setShowSellerCard(true))
      */
    setClickedSellers(brandMap[brand].sellers);
    setShowSellerCard(true);
  }

  function handleInputChange(event) {
    setBrandInputValue(event.target.value);
  }

  const handleNext = () => {
    if (currentSlide === Math.floor(barChartData.length / 7)) {
      return;
    }
    setCurrentSlide((prevSlide) => prevSlide + 1);
    setBarChartDataSlice(
      barChartData.slice(currentSlide * 7 + 7, currentSlide * 7 + 14)
    );
  };

  const handlePrevious = () => {
    if (currentSlide === 0) {
      return;
    }
    setCurrentSlide((prevSlide) => prevSlide - 1);
    setBarChartDataSlice(
      barChartData.slice(currentSlide * 7 - 7, currentSlide * 7)
    );
  };

  const handleClosePopup = () => {
    setShowSellerCard(false);
  };

  const scrollToRow = (key) => {
    if (key) {
      const rowElement = document.querySelector(`tr[data-key="${key}"]`);
      if (rowElement) {
        setSearchedBrand(key);
        rowElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  return (
    <>
      <Header />
      <Button
        variant="contained"
        color="primary"
        onClick={scrollToTop}
        style={{
          display: scrollButtonVisible ? "block" : "none",
          zIndex: 30,
          position: "fixed",
          top: 10,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        Scroll to Top
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "30px",
            justifyContent: "left",
            width: "100%",
            gap: "30px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            <Autocomplete
              disablePortal
              disableClearable
              id="brand-autocomplete"
              options={Object.keys(brandMap)}
              defaultValue={"Select a Brand"}
              // getOptionLabel={option => option.displayName}
              sx={{ width: 300, fontSize: "normal" }}
              size="small"
              // getOptionSelected={(option, value) => option.label === value.label}
              // isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
              onChange={(e, value) => scrollToRow(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by Brand"
                  sx={{ fontSize: "normal" }}
                />
              )}
            />

            <div
              style={{
                boxShadow: "3px 3px 3px #8884d8",
                border: "solid 2px black",
                padding: 20,
                borderRadius: "10px",
              }}
            >
              <BarChart width={600} height={400} data={barChartDataSlice}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  tickFormatter={(value) => {
                    if (value === "" || value === " ") {
                      return "N/A";
                    }
                    if (value.length > 8) {
                      return value.substring(0, 7) + "...";
                    } else {
                      return value;
                    }
                  }}
                  textAnchor="end"
                  angle={-50}
                  dataKey="name"
                />
                <YAxis />
                <Tooltip />
                <Legend iconType="none" height={40} />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ padding: "3px", cursor: "pointer" }}
                onClick={handlePrevious}
              >
                PREV
              </Button>
              <Button
                style={{ padding: "3px", cursor: "pointer" }}
                onClick={handleNext}
              >
                NEXT
              </Button>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            <Autocomplete
              disablePortal
              disableClearable
              id="user-autocomplete"
              options={Object.entries(userMap)
                .reverse()
                .map(([id, name]) => ({
                  id,
                  name,
                }))}
              defaultValue={"Select a User"}
              getOptionLabel={(option) => {
                return option.name || "Select a User";
              }}
              sx={{ width: 300, fontSize: "normal" }}
              size="small"
              // getOptionSelected={(option, value) => option.label === value.label}
              // isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
              onChange={(e, value) => {
                setSelectedUser(value.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by username"
                  sx={{ fontSize: "normal" }}
                />
              )}
            />

            <SellerTable
              map={sellerToBrandMap[selectedUser]}
              selectedUser={selectedUser}
            />
          </div>
        </div>

        <></>

        <div style={{ width: "100vw" }}>
          <Table>
            <thead>
              <tr>
                <th>Brand Name</th>
                <th>Total Item Count</th>
                <th>Avg Retail Price</th>
                <th>Avg Acquisition Cost</th>
                <th># of Sellers</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(brandMap)
                .sort((a, b) => brandMap[b].count - brandMap[a].count)
                .map((brandName) => {
                  const data = brandMap[brandName];
                  const avgRetailPrice = data.totalPrice / data.count;
                  const avgAcquisitionCost = data.totalCost / data.count;
                  const numSellers = Object.keys(data.sellers).length;

                  return (
                    <tr
                      data-key={brandName}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          brandName === searchedBrand ? "#8884d8" : "",
                      }}
                      key={brandName}
                      onClick={() => handleClickSellers(brandName)}
                    >
                      <td>{brandName != "" ? brandName : "N/A"}</td>
                      <td>{data.count}</td>
                      <td>{avgRetailPrice.toFixed(2)}</td>
                      <td>{avgAcquisitionCost.toFixed(2)}</td>
                      <td>{numSellers}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        {showSellerCard ? (
          <SellerCard
            userMap={userMap}
            handleClosePopup={handleClosePopup}
            brand={clickedBrand}
            sellers={clickedSellers}
          />
        ) : null}
      </div>
    </>
  );
};

const SellerCard = (props) => {
  return (
    <div
      style={{
        boxShadow: "3px 3px 3px #8884d8",
        overflowY: "auto",
        height: "500px",
        width: "300px",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 10,
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#f7f7f7",
        border: "#f7f7f7",
      }}
    >
      <div className="seller-card-heading">
        <h1>{props.brand != "" ? props.brand : "N/A"}</h1>
        <button
          onClick={props.handleClosePopup}
          style={{
            padding: "5px",
            border: "none",
            boxShadow: "1px 1px 1px grey",
            backgroundColor: "#ebebeb",
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          X
        </button>
      </div>
      <div className="seller-card-body">
        {Object.entries(props.sellers)
          .sort((a, b) => b[1] - a[1])
          .map(([key, value]) => (
            <p>{`${props.userMap[key] ?? key}: ${value}`}</p>
          ))}
      </div>
      <p
        onClick={props.handleClosePopup}
        style={{ color: "blue", cursor: "pointer" }}
      >
        close
      </p>
    </div>
  );
};

const SellerTable = (props) => {
  return (
    <div
      style={{
        border: "solid 2px black",
        boxShadow: "3px 3px 3px #8884d8",
        overflowY: "auto",
        width: "40vw",
        height: "445px",
        borderRadius: "10px",
      }}
    >
      <Table>
        <thead>
          <tr>
            <th>Brand</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {props.map ? (
            Object.entries(props.map)
              .sort((a, b) => b[1] - a[1])
              .map(([brand, count]) => (
                <tr key={brand}>
                  <td>{brand.trim() === "" ? "N/A" : brand}</td>
                  <td>{count}</td>
                </tr>
              ))
          ) : (
            <tr key="none">
              {props.selectedUser ? (
                <td>No Labels Found</td>
              ) : (
                <td>No User Selected</td>
              )}
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default BrandAggregator;
