import React, { useState, useEffect } from 'react';

function useSafePromise() {
	const [isPromisePending, setIsPromisePending] = useState(null);

	useEffect(() => {
		const confirmClose = (event) => {
			if (isPromisePending) {
				event.preventDefault();
				event.returnValue = '';
				return 'Are you sure you want to close the window? Changes you made may not be saved.';
			}
		};

		window.addEventListener('beforeunload', confirmClose);

		return () => {
			window.removeEventListener('beforeunload', confirmClose);
		};
	}, [isPromisePending]);

	const promiseInitiated = () => {
		setIsPromisePending(true);
	};

	const promiseResolved = () => {
		setIsPromisePending(false);
	};

	return { promiseInitiated, promiseResolved, isPromisePending };
}

export default useSafePromise;
