import React, { useEffect, useState } from "react";
import { getAuth } from '@firebase/auth';
import SortedClothingLabelsPage from '../shopifyClothings.js'
import {  onAuthStateChanged } from '@firebase/auth';
import { useParams } from "react-router";


export function KidsPreLovedApparelView({history}) {
  const auth = getAuth();
  const user = auth.currentUser;

  const [proceed, setProceed] = useState(false)
  const [userID, setUserID] = useState()
  const { id } = useParams()

  useEffect(() => {
    auth.onAuthStateChanged(user => {
			const token = localStorage.getItem("token");      
			if (!token || !user) {
				history.push("/");
			}else {        
        setUserID(user.uid);
        setProceed(true)
            }
    })   
  },[])

  return (               
    <>
    {proceed?
    <SortedClothingLabelsPage
      id={id}
      userID={userID}
      template='preLovedKids'
  /> :null
    }
    </>              
                                                     
  );
}
export default KidsPreLovedApparelView;
