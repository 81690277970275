import React, { useContext, useEffect, useState } from 'react';
import { getAuth } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import MainSideMenuHeaderContainer from '../components/containers/MainSideMenuHeaderContainer';
import axios from 'axios';
import {
	Heading1,
	PrimaryButton,
} from '../components/branded-elements/Elements';
import ProjectViewCard from '../components/projectLibrary/ProjectViewCard';
import { OrganizationContext } from '../components/App';
import { Modal as MuiModal} from '@mui/material';

const LOCALHOST = "http://localhost:3500"
const PRODUCTION = "https://apie.snapwrite.ca"
const rootEndpoint = PRODUCTION


const containerStyle = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, 380px)',
	gap: 50,
	justifyContent: 'center',
	marginTop: 50,
};

const MagentoProjectsViewMM = () => {
	const auth = getAuth();
	const history = useHistory();
	const [projectByStyleCode, setProjectByStyleCode] = useState([]);

	const [styleCode, setStyleCode] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [importButtonText, setImportButtonText] = useState({text: "Import", disabled: false})
	const [bulkImportButtonText, setBulkImportButtonText] = useState({text: "Bulk Import", disabled: false})
	const {organization} = useContext(OrganizationContext)
	const { id: userID } = organization || { id: null };
	const [openModal, setOpenModal] = useState(true);
	const [rejectedFiles, setRejectedFiles] = useState(localStorage.getItem('rejectedFiles') ? JSON.parse(localStorage.getItem('rejectedFiles')) : []);






	const getAllItems = async (userID) => {
		const allItems = await axios.get(`${rootEndpoint}/mm/photos-by-user/${userID}`);
		const sortedItems = [...allItems.data.data.reverse()];

		setProjectByStyleCode(sortedItems);
	};
	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			const token = localStorage.getItem('token');

			if (!token || !user) {
				history.push('/');
			}
		}, []);

	}, []);

	useEffect(() => {
		if(!userID) return
		getAllItems(userID);
	}, [userID]);

	function extractDetailsFromName(name ) {
		if(name) {
			let fileName = name.split('.')[0]
			// console.log(fileName)
			let regex = /^(\d+)-([a-zA-Z0-9]+)-([a-zA-Z0-9]+)(?:\s*\((\d+)\))?$/;
			if(fileName[fileName.length-1]!==')') {
				regex = /^(\d+)-([a-zA-Z0-9]+)-([a-zA-Z0-9]+)-(\d+)$/
			}
			let match = fileName.match(regex);
			if (match) {
				let [, supplierCode, styleNumber, color, position] = match;
				position = parseInt(position);
				const SKU = supplierCode + "-" + styleNumber
				return {
					sku: SKU,
					color: color,
					position: position,
				};
				
			} else {
				console.log("did not work")
				// Try the alternate format																								
				return {
					sku: null,
					color: null,
					position: null,
				};
			}	
		} else {
			console.log("did not work")																							
			return null;
		}
	}

const handleBulkImport = async (files) => {
	setBulkImportButtonText({
		text: "Importing...",
		disabled: true
	})
	const body = await Promise.all(
		Object.entries(files).map(async ([key, value]) => {
			const { name } = value;
			const details = extractDetailsFromName(name);
			const { sku, color, position } = details;

			if (!sku || !color || !position) {
				return null;
			}

			try {
				const { url } = await fetch(
					"https://apie.snapwrite.ca/storeData/s3Url"
				  ).then((res) => res.json());

				await fetch(url, {
					method: "PUT",
					headers: {
					  "Content-Type": "image/jpeg",
					},
					body: value,
				}).catch((err) => console.error(err));
			
				let currentURL = url.split("?")[0];

				return {
					sku: sku.toLocaleUpperCase(),
					colorCode: color.toLocaleUpperCase(),
					imageURL: currentURL,
					position,
				};
			} catch (error) {
				console.error(`Error during fetch: ${error.message}`);
				return null;
			}
		})
	);

	const validItemsArray = body.filter((item) => item !== null);
	let groupedSkus = validItemsArray.reduce((acc, obj) => {
		if (!acc[obj.sku]) {
		  acc[obj.sku] = [];
		}
		//   obj.position = acc[obj.sku].length + 1 
		  acc[obj.sku].push(obj);
		   
		return acc;
	  }, {});
	  
	const validItems = Object.values(groupedSkus).flatMap(skuGroup => {
		let colors = skuGroup.reduce((acc, obj) => {
		  if (!acc[obj.colorCode]) {
			acc[obj.colorCode] = [];
		  }
		 obj.position = acc[obj.colorCode].length + 1 
		  acc[obj.colorCode].push(obj);
		  return acc;
		}, {});
		return Object.values(colors).flat();
	  });
	  	  
	if (validItems.length > 0) {
		const response = await axios.post(
			`${rootEndpoint}/mm/photos`,
			{ products: validItems, userID }
		);
			if(response.status === 200 || response.status === 408){
				window.location.reload();
			}
		}

	const rejectedFiles = Object.entries(files).reduce((acc, [key, value]) => {
		const { name } = value;
		const details = extractDetailsFromName(name);

		if (!details.sku || !details.color || !details.position) {
			acc.push(name);
		}

		return acc;
	}, []);


	if(rejectedFiles.length > 0 & validItemsArray.length === 0) {
		setRejectedFiles(rejectedFiles);
	} else if(rejectedFiles.length > 0 && validItemsArray.length > 0) {
		// Saved to local storage because of windows.reload()
		localStorage.setItem(`rejectedFiles`, JSON.stringify(rejectedFiles));
	}

	setBulkImportButtonText({
		text: "Bulk Import",
		disabled: false
	})
};

	const createSingleSKU = async () => {
		try {
			setImportButtonText({
				text: "Importing ...",
				disabled: true
			})
			const newSKUResponse = await axios.post(
				`${rootEndpoint}/mm/photos/skuCreate`,
				{ sku: styleCode.toUpperCase(), userID }
			);
			if (!newSKUResponse || !newSKUResponse.data) {
				console.log('Error: No data received from server');
				return;
			}
			const newProject = newSKUResponse.data.data;
			if (!newProject) {
				console.log('Error: No project data received from server');
				return;
			}
			setProjectByStyleCode([newProject, ...projectByStyleCode]);
			setStyleCode('');
			setImportButtonText({
				text: "Import",
				disabled: false
			})
		} catch (error) {
			if (error.request.status === 409) {
				setErrorMessage(`Style: "${styleCode}" already exists.`);
			}
			setImportButtonText({
				text: "Import",
				disabled: false
			})

			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		}
	};
	const sendDataToMagento = async () => {
		await axios.post(`${rootEndpoint}/mm/send-photos-all`).then(()=> alert("done"))

	}

	return (
		<MainSideMenuHeaderContainer>
			<div style={{display:"flex"}}>
			<Heading1 title={'Magento Projects'} />

			{/* <PrimaryButton
								title={'Sync All'}
								className='ml-4 h-10'
								onClick={() => {
									sendDataToMagento();
								}}
							/> */}
							<Modal open={rejectedFiles.length > 0} setOpen={setOpenModal} rejectedFiles={rejectedFiles} setRejectedFiles={setRejectedFiles} />
			</div>

			{(
				<section className='mt-4'>
					{/* Header */}
					<div>
						<div className='flex items-center justify-between'>
							<div>
								<input
									name='add-styles'
									id='add-styles'
									type='text'
									placeholder='Enter the Style Code'
									className='h-10 w-96 p-2 rounded-sm focus:outline-none '
									onChange={(e) => setStyleCode(e.target.value)}
								/>
								<PrimaryButton
									title={importButtonText.text}
									disabled={importButtonText.disabled}
									className='ml-4'
									onClick={() => {
										createSingleSKU();
									}}
								/>
							</div>
							<div>
								<PrimaryButton
									title={bulkImportButtonText.text}
									disabled={bulkImportButtonText.disabled}
									onClick={() => {
										document
											.getElementById('bulk-import')
											.click();
									}}
								/>
								<input
									name='bulk-import'
									id='bulk-import'
									type='file'
									accept="image/*"
									className='hidden'
									onChange={(e) => {
										handleBulkImport(e.target.files);
									}}
									multiple={true}
								/>
							</div>
						</div>
					{errorMessage.length > 0 && <div className='ml-2 mt-2 text-sm text-red-600'>{errorMessage}</div>}	
					</div>

					{/* Main Content  */}
					<main style={containerStyle}>
						{projectByStyleCode.map((project, index) => {
							const primaryImageArray = [];
							const variants = project.variants || {};
							Object.entries(variants).forEach(([key, value]) => {
								primaryImageArray.push(value.images[0] || '');
							});
							return (
								project && (
									<ProjectViewCard
										parentElement='mm-magento-view'
										key={project.sku}
										id={project.sku}
										path={`../mm-magento-hub/${project.sku}`}
										date={project.createdAt}
										name={project.sku || 'Untitled Project'}
										image={primaryImageArray}
										endpoint={project.endpoint}
										projectCSVGenerated={false}
										displayChip={false}
										displayMoreVertMenu={false}
										disableInput={true}
									/>
								)
							);
						})}
					</main>
				</section>
			)}
		</MainSideMenuHeaderContainer>
	);
};

export default MagentoProjectsViewMM;


// --------- MODAL -----------

 function Modal({open, setOpen, rejectedFiles, setRejectedFiles}) {
		const handleClose = () =>{ setOpen(false)
		localStorage.removeItem("rejectedFiles")
		setRejectedFiles([])
		}

		return (
			<div>
				<MuiModal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<div className='w-[600px] absolute top-[30vh] left-[35vw] transform -translate-x-1/2 -translate-y-1/2 bg-white py-4 rounded-md flex flex-col max-h-96'>
						<h6 className='ml-4 my-3 font-bold'>Following files are rejected due to incorrect name format</h6>
						<ol className='mx-3 pb-3 h-[80%] overflow-scroll'>
						{rejectedFiles.map((file) => (
							<li className='ml-5 text-red-500' key={file}>
								{file}
							</li>
						))}
						</ol>
						<PrimaryButton
							title={'Close'}
							className='mx-3 self-end'
							onClick={() => {
								handleClose();
							}}
						/>
					</div>
				</MuiModal>
			</div>
		);
 }