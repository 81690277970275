const updateSortedLabelData = async (
  template,
  userID,
  projectName,
  s3Images,
  backImage,
  supplementaryImages,
  types,
  itemGenericType,
  typesTaxoUpdate,
  itemName,
  brand,
  sku,
  vendor,
  size,
  sizeNotes,
  costPerItem,
  price,
  retailPrice,
  condition,
  conditionDescription,
  description,
  imageFileName,
  colors,
  genders,
  variantGrams,
  tags,
  material,
  sleeves,
  style,
  length,
  age,
  closure,
  fastening,
  fastening_type,
  neckline,
  cut,
  hood,
  typeObj,
  belt,
  design,
  lining,
  layers,
  pattern,
  skirt,
  rise,
  heelHeight,
  heelShape,
  height,
  toe,
  era,
  madeIn,
  lengthMeasure,
  sleeveInSeam,
  pitToPitMeasure,
  shoulderMeasure,
  waistMeasure,
  hipMeasure, 
  bottomInSeam,
  riseMeasure,
  thighMeasure,
  legOpening,
  concerns,
  objectID
) => {
  
    fetch(`https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: objectID,
      projectName: projectName,
      itemGenericType: itemGenericType,
      images: s3Images,
      backImage: backImage,
      supplementaryImages: supplementaryImages,
      types: types,
      taxonomyUpdatedType: typesTaxoUpdate,
      itemName: itemName,
      brand: brand,
      vendor: vendor,
      gender: genders,
      size: size,
      cost: costPerItem,
      price: price,
      retailPrice: retailPrice,
      condition: condition,
      conditionDescription: conditionDescription,
      description: description,
      fileNames: imageFileName,
      colors: colors,
      variantGrams: variantGrams,
      tags: tags,
      material: material,
      sleeves: sleeves,
      style: style,
      length: length,
      age: age,
      closure: closure,
      fastening: fastening,
      fastening_type: fastening_type,
      neckline: neckline,
      cut: cut,
      hood: hood,
      typeObj: typeObj,
      belt: belt,
      design: design,
      lining: lining,
      layers: layers,
      pattern: pattern,
      skirt: skirt,
      rise: rise,
      heelHeight: heelHeight,
      heelShape: heelShape,
      height: height,
      toe: toe,
      era: era,
      //measurements: {
      pitToPitMeasure: pitToPitMeasure,
      shoulderMeasure: shoulderMeasure,
      sleeveInSeam: sleeveInSeam,
      lengthMeasure: lengthMeasure,
      waistMeasure: waistMeasure,
      hipMeasure: hipMeasure,
      bottomInSeam: bottomInSeam,
      riseMeasure: riseMeasure,
      thighMeasure: thighMeasure,
      legOpening: legOpening,
      concerns: concerns,
      //},
    }),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(`Error ${err}`);
    });
};

export default updateSortedLabelData;
