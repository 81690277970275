import React, { useEffect, useState } from 'react';
import {
	getAuth,
	createUserWithEmailAndPassword,
	updateProfile,
	signInWithEmailAndPassword,
} from 'firebase/auth';
import { Link } from 'react-router-dom';
import '../css/register.css';
import { FormBackground } from '../components/formsBackground/background';
import axios from 'axios';
import LoginPageBackground from '../assets/LoginPageBackground.png';
import snapWriteLogo from '../assets/sw-logo.png';

const VendorSignup = ({ history }) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const [errorEmail, setErrorEmail] = useState('');
	const [errorPassword, setErrorPassword] = useState('');
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		const token = localStorage.getItem('token');

		if (token) {
			history.push('/vintage');
		}
	}, []);

	const onSignup = () => {
		setLoading(true);
		const auth = getAuth();
		createUserWithEmailAndPassword(auth, email, password)
			.then(async () => {
				
				updateProfile(auth.currentUser, { displayName: name })
					.then(
						signInWithEmailAndPassword(auth, email, password)
							.then((userCredential) => {
								console.log(userCredential.user.uid);
								axios
									.post(
										'https://apie.snapwrite.ca/storeData/user-preferences',
										{
											userID: userCredential.user.uid,
											email: email,
											displayName: name,
											accountType: "seller",
											tablePreference: "vintage",
											dateCreated: new Date(),
										}
									)
									.then((res) => {localStorage.setItem("accountType", "reseller");localStorage.setItem("preference", "vintage");})
									.catch((err) => {
										
										console.error(err);
									});
								localStorage.setItem(
									'token',
									userCredential._tokenResponse.idToken
								);
								history.push('/vintage');
							})
							.catch((e) => alert(e.message))
							.finally(() => setLoading(false))
					)
					.catch((e) => alert(e.message));
			})
			.catch((e) => {
				console.log(e.code);
				switch (e.code) {
					case 'auth/email-already-in-use':
						setErrorEmail(`Email already registered. Try login`);
						break;
					case 'auth/invalid-email':
						setErrorEmail('Please check the email');
						break;
					case 'auth/weak-password':
						setErrorPassword('Please try using bigger password');
						break;
					default:
						break;
				}
			})
			.finally(() => setLoading(false));
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			if (event.target.className === 'name' && email === '') {
				document.getElementsByClassName('email')[0].focus();
			} else if (event.target.className === 'email' && password === '') {
				document.getElementsByClassName('password')[0].focus();
			} else {
				document.getElementsByClassName('logInButtton')[0].click();
			}
		}
	};
	return (
        <>
         <img
				src={LoginPageBackground}
				width='500px'
				className='backgroundImage'
				alt='backgroundImage'
			/>
         <img src={snapWriteLogo} className="w-[200px] mt-10 -scroll ml-10" alt="snapWriteLogo" />
		<div className='font-["Inter", sans-serif] flex flex-row justify-center lg:justify-between items-center min-h-[70vh] md:mt-14  md:mx-[5%] '>
			<FormBackground />

			<div className='container'>
				<div className='centeringDivForContainer'>
					<h2>Vendor Sign up</h2>
					<br />
					<div className='LoginWithId'>
						<input
							value={name}
							onChange={(e) => setName(e.target.value)}
							name='name'
							type='name'
							placeholder='Your Vendor Name'
							className='name'
							tabIndex='1'
							data-hj-allow
							onKeyDown={(event) => {
								handleKeyDown(event);
							}}
						/>
						<span style={{ color: 'red' }}>{errorEmail}</span>
						<input
							value={email}
							type='email'
							name='email'
							className='email'
							required='required'
							placeholder='Work Email address'
							onChange={(e) => setEmail(e.target.value)}
							tabIndex='2'
							data-hj-allow
							onKeyDown={(event) => {
								handleKeyDown(event);
							}}
						/>
						<span style={{ color: 'red' }}>{errorPassword}</span>
						<input
							value={password}
							placeholder='password'
							onChange={(e) => setPassword(e.target.value)}
							name='password'
							type='password'
							className='password'
							tabIndex='3'
							onKeyDown={(event) => {
								handleKeyDown(event);
							}}
						/>
						<div className='termsPrivacypolicy'>
							<input
								type='checkbox'
								className='terms'
								onClick={(event) => {
									setChecked(event.target.checked);
								}}
								onKeyDown={(event) => {
									handleKeyDown(event);
								}}
							/>
							<p>
								I agree to the&nbsp;
								<a
									href='https://drive.google.com/file/d/1yoN-XoCHkUJQ3zGqBQz6T1rmCQelXCjg/view'
									target='_blank'
									rel='noreferrer'
								>
									Terms
								</a>
								&nbsp;and the&nbsp;
								<a
									href='https://drive.google.com/file/d/1lmiBxHQI-Sbix7SrTMp-d5cYR2pQVGbC/view'
									target='_blank'
									rel='noreferrer'
								>
									Privacy Policy
								</a>				
							</p>
						</div>
						<button
							onClick={onSignup}
							disabled={
								email === '' ||
								name === '' ||
								password === '' ||
								!checked
							}
							className='logInButtton'
							tabIndex='4'
						>
							{loading ? 'Creating user ...' : 'Signup'}
						</button>
					</div>
					<hr className='verticalLine' />
					<p className='backToLogin'>
						Already have an account?&nbsp;
						<Link to='/'>Sign in</Link>
					</p>
					
				</div>
			</div>
		</div>
        </>
	);
};

export default VendorSignup;
