const getProductCategoryForPricing = (item_name, currentType) => {

    const reverseString = (str) => str.split(' ').reverse().join(' ')

    const item_name_lower = item_name.toLowerCase()

    let searchInString = item_name_lower;
    if (!item_name_lower.includes('swim suit')) {
        // Reverse the string if 'swim suit' is not found
        searchInString = reverseString(item_name_lower)
    }

    const patterns = [
      'shirt', 'tshirt', 'sweater', 'pant', 'jogger', 'shorts', 'dress', 'jeans', 'leggings',
      'romper', 'rompers', 'tee', 'skirt', 'sweatshirt', 'pjs', 'cardigan', 'polo', 'windbreaker', 'tank', 'tanks',
      'jersey', 'bodysuit', 'bodysuits', 'sleeper', 'tees', 'joggers', 'pants', 'hat', 'jumper', 'jumpers', 'backpack', 'backpacks',
      'vest', 'blazer', 'suit', 'skort', 'skirtall', 'dresses', 'boots', 'sandals', 'cleats', 'bra', 'bras', 'tank', 'tanks', 'vest', 'swim suit',
      'puffer', 'jacket', 'peacoat', 'coat', 'parka', 'jeggings', 'overalls', 'playsuit', 'short', 'hoodie', 'sweatshirts', 'top', 'tops', 'robe', 'robes',
      'toy', 'toys', 'set', 'blouse', 'hoodie', 'shoes', 'runners', 'bra', 'bras', 'mittens', 'costume', 'socks', 'bib', 'bibs', 'bikini', 'legging',
    ].join('|');
    
    const regex = new RegExp(`\\b(${patterns})(?!\\w)`, 'i')
    const match = searchInString.match(regex)

    if (match) {
      let matched_text = match[0].toLowerCase()
  
      if (searchInString !== item_name_lower) {
        matched_text = reverseString(matched_text)
      }
      const edge_cases = {
          'jeans': 'Jeans', 'jeggings': 'Jeans', 'playsuit': 'Bodysuit', 'sweatshirts': 'Sweatshirt', 'dresses': 'Dress',
          'pants': 'Pant', 'leggings': 'Legging', 'shorts': 'Shorts', 'dress': 'Dress', 'tees': 'Tees', 'booties': 'Boot',
          'tankini ': 'Bikini', 'pullover': 'Sweatshirt', 'nightgown': 'Robe', 'gown': 'Robe', 'khakis': 'Pant', 'cords': 'Pant', 'pinafore': 'Blouse', 'PJ': 'Pj',
          'tunic': 'Shirt', 'trunks': 'Shorts', 'tuxedo': 'Suit', 'pullover': 'Sweatshirt', 'bag': 'Backpack', 'bags': 'Backpack', 'chair': 'Other'
      };

      if (matched_text in edge_cases) {
        return edge_cases[matched_text];
      } else if (matched_text.endsWith('s')) {
        return matched_text.slice(0, -1).charAt(0).toUpperCase() + matched_text.slice(1, -1)
      }
      return matched_text.charAt(0).toUpperCase() + matched_text.slice(1);
    }
      const hats = ["toque", "cap", "headband", "headbands", 'hats']
      const sandals = ["slides", "slippers", 'sandal']
      const blankets = ["towel", "cover", "blanket", "sheet", "blankets", 'poncho']
      const sack = ["sack", "sacks", "sleepers", "sleeper", "pouch"]
      const overalls = ['romperalls', 'shortalls']
      const swimming = ['wetsuit', 'guard', 'swim suit']

      if (hats.some(hat => item_name_lower.includes(hat))) return "Hat"
      if (sandals.some(sandal => item_name_lower.includes(sandal))) return "Sandal"
      if (blankets.some(blanket => item_name_lower.includes(blanket))) return "Blanket"
      if (sack.some(item => item_name_lower.includes(item))) return "Sack"
      if (swimming.some(item => item_name_lower.includes(item))) return 'Swim Suit'
      if (overalls.some(item => item_name_lower.includes(item))) return 'Overalls'
      if (currentType==='Toys') return 'Toy'

      return "Other";
  };

  export default getProductCategoryForPricing