const animalistic = [
    "animal",
    "leopard",
    "cheetah",
    "tiger",
    "wild",
    "fauna",
    "animalistic"
  ];
  
  const camouflage = [
    "Military",
    "army",
    "camo",
    "camouflage"
  ];
  
  const checked = [
    "plaid",
    "check",
    "Tartan",
    "Gingham",
    "Cross",
    "Grid",
    "Square",
    "Quadrille",
    "Patchwork",
    "Overlapping",
    "checked"
  ];
  
  const chevron = [
    "Quilted",
    "angled",
    "chevron"
  ];
  
  const floral = [
    "flower",
    "bloom",
    "blossom",
    "blooming",
    "sunflower",
    "paisley",
    "tropical",
    "daisy",
    "daisy print",
    "hawaii",
    "aloha",
    "beach",
    "rose",
    "floral"
  ];
  
  const geometric = [
    "geo",
    "art",
    "retro",
    "artsy",
    "scribble",
    "geometric"
  ];
  
  const polka_dot = [
    "Dot",
    "Polka",
    "Spot",
    "Circular",
    "Circle",
    "Speckled",
    "Stippled",
    "polka dot"
  ];
  
  const stripe = [
    "Stripe",
    "Line",
    "vertical",
    "horizontal",
    "Band",
    "Streak",
    "Bar"
  ];
  
  const crinkled = [
    "Wrinkled",
    "Creased",
    "Folded",
    "Rumpled",
    "Crumpled",
    "Crinkly",
    "Indented",
    "crinkled"
  ];

  const synonymsArray = [animalistic, camouflage, checked, chevron, floral, geometric, polka_dot, stripe, crinkled]

export const getPatternSynonyms = (pattern) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => pattern.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 