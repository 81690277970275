import { getAuth } from '@firebase/auth';

const GetData = async (userID, setData, endpoint, path) => {
	const localhost = "http://localhost:3500";
	const production = "https://apie.snapwrite.ca";
	const BASE_URL = production;
	if (userID === undefined) {
		return Promise.resolve();
	}

	try {
		const response = await fetch(
			`${BASE_URL}/storeData/${endpoint}?userID=${userID}`
		);
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		const json = await response.json();
		const data = json.data.reverse().map((d) => ({ ...d, path, endpoint }));
		setData(data);

		return data;
	} catch (error) {
		console.error(`Error fetching data: ${error}`);
		return Promise.reject(error);
	}
};

export default GetData;
