import React, { useState, useEffect } from 'react';
import { getAuth } from '@firebase/auth';
import MultiStep from './StepIndicator' ;
import BlogIdeaToTitle from '../../products/blogs/blogIdeaToTitle'
import BlogTitleToOutline from '../../products/blogs/blogTitleToOuline'
import BlogOutline from '../../products/blogs/blogOutline';
import { Header } from '../navigation/header'
import SideMenu from '../sideMenu'

// custom styles
   
const IdeaToBlog = ({history}) => {

  const auth = getAuth();
  const user = auth.currentUser;
    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token || !user) {
            history.push('/')
        }
    },[])

    let userID;
    let userName;
    
    if (user !== null) {
      userID= user.uid;
      // console.log(userID)
        user.providerData.forEach((profile) => {            
            userID = user.uid;
            userName = profile.displayName
        });
      }

    const [objectID, setObjectID] = useState("")
    
    const [noOfWordsGenerated, setNumberOfWordsGenerated] = useState();

    const [projectName, setProjectName] = useState("");

    const [activeState, setActiveState] = useState(0)

    const [blogIdea, setBlogIdea] = useState("")

    const [blogTitles, setBlogTitles] = useState(
        ["Blog title will appear in about 10 seconds"]
    );

    const [blogTitle, setBlogTitle] = useState("Title will be here in a few seconds");

    const [blogOutline, setBlogOutline] = useState("Your blog outline will appear in about 5 seconds");
    
    const [blogIntro, setBlogIntro] = useState("Blog's introduction will appear here");
    const [blogParaOne, setBlogParaOne] = useState("Blog's paragraph one will appear here");
    const [blogParaTwo, setBlogParaTwo] = useState("Blog's paragraph two will appear here");
    const [blogParaThree, setBlogParaThree] = useState("Blog's paragraph three will appear here");
    const [blogParaFour, setBlogParaFour] = useState("Blog's paragraph four will appear here");
    const [blogParaFive, setBlogParaFive] = useState("Blog's paragraph five will appear here");
    const [blogConclusion, setBlogConclusion] = useState("Blog's conclusion will appear here");
    
    const [titleGenerateClick, setTitleGenerateClick] =useState(0);
    const [outlineGenerateClick, setOutlineGenerateClick] =useState(0);
    const [paragraphGenerateClick, setParagraphGenerateClick] =useState(0);
    const [conclusionGenerateClick, setConclusionGenerateClick] = useState(0);
    
    const [completeBlog, setCompleteBlog] = useState("")
 
    useEffect(()=>{    
      //communicate with the backend!
      fetch(`https://apie.snapwrite.ca/storeData/word-count?userID=${userID}`)
      .then(response=>response.json())
      .then(response=>{
        // console.log(response)
  
          setNumberOfWordsGenerated(response.data)
      })    
      .catch(err=>{
          console.log(`Error ${err}`)
      })
  },[ userID])
  
        
    const MINUTE_MS = 60000;

    useEffect(() => {
      const interval = setInterval(() => {
        setTitleGenerateClick(0);
        setOutlineGenerateClick(0);
        setParagraphGenerateClick(0);        
        setConclusionGenerateClick(0);
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    useEffect(()=>{
      const projectName = JSON.parse(localStorage.getItem("ideaToBlogProjectName"))
      const activeState = JSON.parse(localStorage.getItem("ideaToBlogActiveState"))
      const idea = JSON.parse(localStorage.getItem("idea"))
      const ideaTitles = JSON.parse(localStorage.getItem("ideaTitles"))
      const ideaTitleUsed = JSON.parse(localStorage.getItem("ideaTitleUsed"))
      const ideaOutline = JSON.parse(localStorage.getItem("ideaOutline"))
      const ideaIntroPara = JSON.parse(localStorage.getItem("ideaIntroPara"))
      const ideaPara1 = JSON.parse(localStorage.getItem("ideaPara1"))
      const ideaPara2 = JSON.parse(localStorage.getItem("ideaPara2"))
      const ideaPara3 = JSON.parse(localStorage.getItem("ideaPara3"))
      const ideaPara4 = JSON.parse(localStorage.getItem("ideaPara4"))
      const ideaPara5 = JSON.parse(localStorage.getItem("ideaPara5"))
      const ideaConclusion = JSON.parse(localStorage.getItem("ideaConclusion"))
      const completeBlog = JSON.parse(localStorage.getItem("completeIdeaBlog"))
      const objectID = JSON.parse(localStorage.getItem("ideaBlogObjectID"))
      if(idea!==null && blogIdea===""){
        setProjectName(projectName)
        setActiveState(activeState)
        setBlogIdea(idea)
        setBlogTitles(ideaTitles)
        setBlogTitle(ideaTitleUsed)
        setBlogOutline(ideaOutline)
        setBlogIntro(ideaIntroPara)
        setBlogParaOne(ideaPara1)
        setBlogParaTwo(ideaPara2)
        setBlogParaThree(ideaPara3)
        setBlogParaFour(ideaPara4)
        setBlogParaFive(ideaPara5)
        setBlogConclusion(ideaConclusion)
        setCompleteBlog(completeBlog)
        setObjectID(objectID)
      }
    }, [])
        
    useEffect(() => {
      localStorage.setItem("ideaToBlogProjectName", JSON.stringify(projectName))
      localStorage.setItem("ideaToBlogActiveState", JSON.stringify(activeState))
      localStorage.setItem("idea", JSON.stringify(blogIdea))
      localStorage.setItem("ideaTitles", JSON.stringify(blogTitles))
      localStorage.setItem("ideaTitleUsed", JSON.stringify(blogTitle))
      localStorage.setItem("ideaOutline", JSON.stringify(blogOutline))
      localStorage.setItem("ideaIntroPara", JSON.stringify(blogIntro))
      localStorage.setItem("ideaPara1", JSON.stringify(blogParaOne))
      localStorage.setItem("ideaPara2", JSON.stringify(blogParaTwo))
      localStorage.setItem("ideaPara3", JSON.stringify(blogParaThree))
      localStorage.setItem("ideaPara4", JSON.stringify(blogParaFour))
      localStorage.setItem("ideaPara5", JSON.stringify(blogParaFive))
      localStorage.setItem("ideaConclusion", JSON.stringify(blogConclusion))
      localStorage.setItem("completeIdeaBlog", JSON.stringify(completeBlog))
      localStorage.setItem("ideaBlogObjectID", JSON.stringify(objectID))

    }, [projectName, activeState, blogIdea, blogTitles, blogTitle, blogOutline, blogIntro, blogParaOne, blogParaTwo, blogParaThree, blogParaFour, blogParaFive, blogConclusion, completeBlog, objectID])

    const steps = [
        { 
            component: <BlogIdeaToTitle 
                        noOfWordsGenerated={noOfWordsGenerated}
                        blogIdea={ blogIdea } 
                        setBlogIdea={setBlogIdea}
                        blogTitles={blogTitles}
                        setBlogTitles={setBlogTitles}
                        blogOutline={blogOutline}
                        setBlogTitle={setBlogTitle}
                        blogTitle={blogTitle}
                        setBlogOutline={setBlogOutline}
                        setTitleGenerateClick={setTitleGenerateClick}
                        titleGenerateClick = {titleGenerateClick}
                        outlineGenerateClick={outlineGenerateClick}
                        setOutlineGenerateClick={setOutlineGenerateClick}
                        user={userID} userName={userName}
                        activeState = {activeState}
                        setActiveState = {setActiveState}
                       /> 
        },
        { component: <BlogTitleToOutline
                      setObjectID={setObjectID}
                      noOfWordsGenerated={noOfWordsGenerated}
                      projectName={projectName}
                      blogIdea={blogIdea}
                      blogTitle={blogTitle}
                      setBlogTitle={setBlogTitle}
                      blogOutline={blogOutline}
                      setBlogOutline={setBlogOutline}
                      user={userID} userName={userName}                      
                      setBlogIntro={setBlogIntro}
                      setBlogParaOne={setBlogParaOne}
                      setBlogParaTwo={setBlogParaTwo}
                      setBlogParaThree={setBlogParaThree}
                      setBlogParaFour={setBlogParaFour}
                      setBlogParaFive={setBlogParaFive}
                      setBlogConclusion={setBlogConclusion}
                      blogIntro={blogIntro}
                      blogParaOne={blogParaOne}
                      blogParaTwo={blogParaTwo}
                      blogParaThree={blogParaThree}
                      blogParaFour={blogParaFour}
                      blogParaFive={blogParaFive}
                      blogConclusion={blogConclusion}
                      paragraphGenerateClick={paragraphGenerateClick}
                      setParagraphGenerateClick={setParagraphGenerateClick}                      
                      conclusionGenerateClick={conclusionGenerateClick}
                      setConclusionGenerateClick={setConclusionGenerateClick}
                      activeState = {activeState}
                      setActiveState = {setActiveState}
                      imageOrIdea="idea"
                     /> 
        },
        { component: <BlogOutline
                      imageOrIdea="idea"
                      objectID={objectID}
                      completeBlog={completeBlog}
                      setCompleteBlog={setCompleteBlog}
                      blogTitle={blogTitle}
                      setBlogTitle={setBlogTitle}
                      blogOutline={blogOutline}
                      setBlogOutline={setBlogOutline}  
                      blogIntro={blogIntro}
                      blogParaOne={blogParaOne}
                      blogParaTwo={blogParaTwo}
                      blogParaThree={blogParaThree}
                      blogParaFour={blogParaFour}
                      blogParaFive={blogParaFive}
                      blogConclusion={blogConclusion}
                      activeState = {activeState}
                      setActiveState = {setActiveState}          
                    />  
        },    
      ]  
    
    const reset = () => {
        setObjectID("")
        setCompleteBlog("")
        setProjectName("")
        setBlogTitle("")
        setBlogTitles( ["Blog title will appear in about 10 seconds"] );
        setBlogIdea("")    
        setActiveState(0) 
        setBlogIntro("Blog's introduction will appear here")  
        setBlogParaOne("Blog's paragraph one will appear here")
        setBlogParaTwo("Blog's paragraph two will appear here")
        setBlogParaThree("Blog's paragraph three will appear here")
        setBlogParaFour("Blog's paragraph four will appear here")
        setBlogParaFive("Blog's paragraph five will appear here")
        setBlogConclusion("Blog's conclusion will appear here")
    };
return(
  <div className="App">
            <Header reset={reset}  setProjectName={setProjectName} projectName={projectName}/>            
            {/* <hr width="100%;" /> */}
            {/* ******************************the <main> section****************************** */}
            <div className="interface">
                <SideMenu />
                {/* The grey section where the user can upload image, edit tags and get the description */}
                <div className="playGround"> 
                <MultiStep
                activeStep={activeState}
                setActiveStep={setActiveState}
                steps={steps} 
                 />
            </div>
        </div>
    </div>
)
}
export default IdeaToBlog;