import React, {useState} from 'react'
import {Link} from "react-router-dom"
import MoreVertMenu from './moreVertMenu'
import "../../css/projects.css"
import DoneAllIcon from '@mui/icons-material/DoneAll';
import axios from 'axios';

const ProductCard = (props) => {
			const [title, setTitle] = useState(props.name ? props.name : (props.customerName ? props.customerName : "[Untitled] " + new Date(props.date).toLocaleDateString()));
      const [email, setEmail] = useState(props.email || "")
      const [phone, setPhone] = useState(props.phone || "")
      const linkStyle = {
        textDecoration: "none",
        color: '#1A1F36'
      };


			const requestOptions = {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			};

			const handleNameChange = (event) => {
        setTitle(event.target.value);
        const body = {newValue: {projectTitle: event.target.value}, _id: props.description._id}

        if (props.description.hasOwnProperty("isProcessed")) {
          axios.put('https://apie.snapwrite.ca/storeData/upate-theme-project-data'  , body)
        } else {
          fetch(
            `https://apie.snapwrite.ca/storeData/${props.endpoint}-projectNameUpdate`,
            {
              ...requestOptions,
              body: JSON.stringify({
                id: props.id,
                projectName: event.target.value,
              }),
            }
          ).then((response) => response.json());
        }
      };

      const handleEmailChange = (event) => {
        setEmail(event.target.value);
        updateCustomerData("defaultEmail", event.target.value)
      };
    
      const handlePhoneChange = (event) => {
        setPhone(event.target.value);
        updateCustomerData("defaultPhoneNumber", event.target.value)
      };

    
      const updateCustomerData = (name, val) => {
        fetch(`https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: props.id,
            val: val,
            name: name,
          }),
        })
          .then((response) => response.json())
          .catch((err) => {
            console.log(`Error ${err}`);
          });
      };

      let projectEndpoint;
      if (props.path === "clothing-master-table") {
        projectEndpoint = `/clothing-master-table/${props.id}`;
      } else {
        projectEndpoint = `/${props.path}/${props.id}`;
      }

      const cardBody = () =>{
     
         return (
            <>                
                <div className='projectCardDetails' style={{ display: "flex" }}>
                    <div>
                        Started<br />                        
                        {new Date(props.date).toLocaleDateString()}<br />
                        {props.itemCount === undefined ? "" : ("Items: " + props.itemCount)}
                    </div>
                    
                </div>                
								<div className="projectImgContainer">
									{props.image!== undefined ?  <img className="projectCardImg" src={props.image} alt={props.name}/> : null }
								</div>
            </>
         )         
         
      }
    return (                 
         <div  className="dataCard relative">             
             {/* <Link to = {path()}> 
                <img src={props.image} alt={props.name}/>   
            </Link>  */}
            
							<div className="data_card_body">
								<input className='projectCardTitle' onChange={handleNameChange} value={title}/><br />
                {/* */}
									<Link className="projectCard" to={projectEndpoint} style={linkStyle}>
											{cardBody()}
									</Link>
                  {props.projectCSVGenerated && (<div className='absolute bottom-2 right-3  text-xs flex justify-center items-center text-gray-500'>
                    <DoneAllIcon sx={{fontSize:14, marginRight:"2px",}}  /> Completed
                  </div>)}
                
							</div>
          
          {props.type && props.type === "Customer" && 
          <>
            <div style = {{maxWidth: "90%", display: "flex", marginTop: "5px", gap: "5px"}}>
              <div style={{paddingLeft: "5px", fontSize: "12px"}}>Email: </div>
              <input onChange = {e => handleEmailChange(e)} value = {email} style = {{maxWidth: "80%"}} className='projectCardTitle' />
            </div>
            <div style = {{maxWidth: "90%", display: "flex", marginTop: "5px", gap: "5px"}}>
              <div style={{paddingLeft: "5px", fontSize: "12px"}}>Phone: </div>
              <input value = {phone} onChange = {e => handlePhoneChange(e)} style = {{maxWidth: "80%"}} className='projectCardTitle' />
            </div>
          </>}
          {props.unexportedCount !== undefined && props.unexportedCount > 0 && (<div style={{ color: "red", paddingLeft: "5px", fontSize: "12px"}}>Contains {props.unexportedCount} Unexported Forms!</div>)}
          <MoreVertMenu endpoint={props.endpoint} path={props.path} id={props.id} isArchived={props.isArchived} setArchives={props.setArchives}/>
         </div>
          )
        }

export default ProductCard
