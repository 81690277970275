import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import axios from "axios";
import { ImageUploadCode } from "../imageUpload";
import { TagsInput } from "../tagsArea";
import { SideMenu } from "../../components/sideMenu.js";
import { Header } from "../../components/navigation/header.js";
import {
  wordCount,
  characterCount,
  sentenceCount,
  tagsList,
  keywordsMatched,
} from "../../components/wordCountArea";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

//Send userID, userName, tagsUsed and descriptions generated from mainProduct

export function SentenceAndPointers(props) {
  /* **************************Tags area************************** */
  const userID = props.user;
  const userName = props.userName;
  const generateButtonClick = props.generateButtonClick;
  const setGenerateButtonClick = props.setGenerateButtonClick;
  const selectedTags = (tags) => {
    // console.log(tags);
  };

  /* ************************************************************* */
  const [noOfWordsGenerated, setNumberOfWordsGenerated] = useState();
  const [noOfImagesUsed, setNumberOfImagesUsed] = useState();
  const [wordLimit, setWordLimit] = useState(2500);
  const [imageLimit, setImageLimit] = useState(10);
  const [projectName, setProjectName] = useState("");
  const [image, setImage] = useState("");
  const [suggestedTags, setSuggestedTags] = useState([""]);
  const [tags, setTags] = useState([]);
  const [objectID, setObjectID] = useState("")
  const [prodDescription, setProdDescription] = useState([
    "Your product description will appear in about 10 seconds",
    "Your product description will appear in about 10 seconds",
  ]);
  const [selectedDescription, setSelectedDescription] = useState("")
  const [generate, setGenerate] = useState("Generate");

  const unlimitedUsers = ["NpGB6zItW1OcpizZJbD88VsfKlf2", "1BILFjc4JNM0LDmmOUfdr2rRfNH3"]

  useEffect(()=>{    
    //communicate with the backend!
    fetch(`https://apie.snapwrite.ca/storeData/word-count?userID=${userID}`)
    .then(response=>response.json())
    .then(response=>{
      // console.log(response)

        setNumberOfWordsGenerated(response.data)
        if(userID==="S4nF6oUynvUfkVQjTIcyqyCPZQg2"){
          setWordLimit(22417);
        }

    })    
    .catch(err=>{
        console.log(`Error ${err}`)
    })
},[generate, userID])

useEffect(()=>{    
  //communicate with the backend!
  fetch(`https://apie.snapwrite.ca/storeData/image-count?userID=${userID}`)
  .then(response=>response.json())
  .then(response=>{
    // console.log(response)

    setNumberOfImagesUsed(response.data)
    if(userID==="S4nF6oUynvUfkVQjTIcyqyCPZQg2"){
      setImageLimit(80);
    }

  })    
  .catch(err=>{
      console.log(`Error ${err}`)
  })
},[generate, userID])


useEffect(() => {
  const projectName = JSON.parse(localStorage.getItem(`imageToDescProjectNameSocial${userID}`));
  const imageData = JSON.parse(localStorage.getItem(`imageSocial${userID}`));
  const suggestionData = JSON.parse(localStorage.getItem(`suggestedTagsSocial${userID}`));
  const tagsData = JSON.parse(localStorage.getItem(`tagsSocial${userID}`));
  const prodDescriptions= JSON.parse(localStorage.getItem(`productDescriptionsSocial${userID}`)) 
  const reGenerate = JSON.parse(localStorage.getItem(`reGenerateSocial${userID}`))   
  const objectID = JSON.parse(localStorage.getItem(`imageDescriptionObjectIDSocial${userID}`))   
  if (imageData !== null && image === "") {
    setProjectName(projectName);
    setImage(imageData);
    setSuggestedTags(suggestionData);
    setTags(tagsData);
    setProdDescription(prodDescriptions)      
    setGenerate(reGenerate)
    setObjectID(objectID)
  }
}, []);

useEffect(() => {
  localStorage.setItem(`imageToDescProjectNameSocial${userID}`, JSON.stringify(projectName));
  localStorage.setItem(`imageSocial${userID}`, JSON.stringify(image));
  localStorage.setItem(`suggestedTagsSocial${userID}`, JSON.stringify(suggestedTags));
  localStorage.setItem(`tagsSocial${userID}`, JSON.stringify(tags));
  localStorage.setItem(`productDescriptionsSocial${userID}`, JSON.stringify(prodDescription));
  localStorage.setItem(`reGenerateSocial${userID}`, JSON.stringify(generate));    
  localStorage.setItem(`imageDescriptionObjectIDSocial${userID}`, JSON.stringify(objectID));    
}, [projectName, image, suggestedTags, tags, prodDescription, generate, objectID]);

  async function genDescription1(tags) {
    setProdDescription(["Loading...", "Loading..."]);

    try {
      setGenerateButtonClick(generateButtonClick + 1);
      setProdDescription(["Loading...", "Loading..."]);

      const res = await backendClient.post("/twitter", {
        tags: tags,
        user: "Hello",
      });
      let description = res.data;
      
      if (res.data[0] === "\n") {
        description = description.slice(1);
      }
      
      // setProdDescription(prodDescription.splice(-1))
      // setProdDescription([description])      

      genDescription2(tags, description);
    } catch (e) {
      setProdDescription(["Please try again", "Please try again"]);

      console.error(e);
    }
  }

  const reset = () => {
    setProjectName("")   
    if(noOfWordsGenerated>wordLimit && !unlimitedUsers.includes(userID)){
      setProdDescription([
        "Please Contact Us at contact@snapwrite.ca to increase word limit",
        "Please Contact Us at contact@snapwrite.ca to increase word limit",
      ])  
    }
    else if((noOfImagesUsed > imageLimit || noOfImagesUsed === imageLimit) &&  !unlimitedUsers.includes(userID)){
      setProdDescription([
          "Please Contact Us at contact@snapwrite.ca to increase image limit",
          "Please Contact Us at contact@snapwrite.ca to increase image limit",
        ])
    }
    else{
      setProdDescription([
        "Your product description will appear in about 10 seconds",
        "Your product description will appear in about 10 seconds",
      ])    
    } 
    setObjectID("")
    setTags([]);
    setImage("");
    setSuggestedTags([""]);
    setGenerate("Generate");
  };

  async function genDescription2(tags, description1) {
    try {
      // setProdDescription([...prodDescription, "Loading2"])      

      const res = await backendClient.post("/twitter", {
        tags: tags,
        user: "hello",
      });
      let description = res.data;
      if (res.data[0] === "\n") {
        description = description.slice(1);
      }      
      // let descArray = prodDescription.splice(-1)
      // setProdDescription(descArray)      
      setProdDescription([description1, description]);      

      storeDescriptions(description1, description, tags);
    } catch (e) {
      
      console.error(e);
    }
  }
  async function reGenerateDescription(tags) {
    try {
      setProdDescription([...prodDescription, "Loading..."]);      
      const res = await backendClient.post("/twitter", {
        tags: tags,
        user: "Hello",
      });
      let description = res.data;
      if (res.data[0] === "\n") {
        description = description.slice(1);
      }      
      // setProdDescription(prodDescription.splice(-1))
      updateStoredDescriptions(description)
      setProdDescription([...prodDescription, description]);
      // storeDescriptions(description1, description, tags)
    } catch (e) {
      
      console.error(e);
    }
  }

  
  function updateStoredDescriptions(reGeneratedDescription) {
    // console.log("from store = " + [description1, description2]);    
    fetch(`https://apie.snapwrite.ca/storeData/image-twitter-description`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: objectID,
        generatedDescriptions: [...prodDescription, reGeneratedDescription]        
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }


  function storeDescriptions(description1, description2, tags) {
    // console.log("from store = " + [description1, description2]);    
    fetch(`https://apie.snapwrite.ca/storeData/image-twitter-description`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userID,
        userName: userName,
        projectName: projectName,
        imageUploaded: image,
        suggestedTags: suggestedTags,
        tagsUsed: tags,
        generatedDescriptions: [description1, description2],
        wordCount: wordCount(description1) + wordCount(description2),
      }),
    })
      .then((response) => response.json())
      .then(response=> setObjectID(response.data._id))
      .then(setGenerate("Re-Generate"))
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }

  const updateDescription = (index, event) =>{
    const prodDescriptionArray = prodDescription.slice();
    // console.log("altextArray = " + altTextArray[index]) 
    prodDescriptionArray[index] = event.target.value;
    
    setProdDescription(prodDescriptionArray);

    fetch(`https://apie.snapwrite.ca/storeData/image-twitter-description`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        id: objectID,
        generatedDescriptions: prodDescriptionArray
      }),
    })
      .then((response) => response.json())      
      .catch((err) => {
        console.log(`Error ${err}`);
      });
  }

  const selectDescription = (description) =>{
    setSelectedDescription(description);
  }

  return (
    <div className="descriptions">
      {/* {console.log("noOfWordsGenerated = " + noOfWordsGenerated)} */}
      <Header reset={reset} setProjectName={setProjectName} projectName={projectName} />
      {/* <hr width="100%;" /> */}
      {/* ******************************the <main> section****************************** */}
      <div className="interface">
        <SideMenu />
        {/* The grey section where the user can upload image, edit tags and get the description */}
        <div className="playGround">
          {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
          <div className="tools">
            <div className="drop-and-tags">
              {/* ************The image upload area************** */}
              <ImageUploadCode
                selectedTags={selectedTags}
                setTags={setTags}
                setSuggestedTags={setSuggestedTags}
                setImage={setImage}
                tags={tags}
                suggestedTags={suggestedTags}
                image={image}    
                noOfImagesUsed={noOfImagesUsed}            
                noOfWordsGenerated={noOfWordsGenerated}
                userID={userID}
              />
              {/* ************************************************ */}
              <div className="card">
                <div className="card-header"> Tags </div>
                <div className="card-body appTag">
                  {/* **************************Tags area************************** */}
                  <div className="appTag">
                    <TagsInput
                      selectedTags={selectedTags}
                      tags={tags}
                      setTags={setTags}
                    />
                  </div>
                  <div className="tagsNumberDesc">Add 6-7 tags</div>
                  <button
                    className="btn btn-primary1"
                    onClick={() => {                      
                      if (generate === "Generate") {
                        genDescription1(tags);
                        setGenerate("Re-Generate");
                      } else if (generate === "Re-Generate") {
                        reGenerateDescription(tags);
                      }
                      // genDescription2(tags);
                    }}
                    disabled={(generateButtonClick > 50) || prodDescription.length>3 || prodDescription.length===4 || (noOfWordsGenerated>wordLimit && !unlimitedUsers.includes(userID)) || ((noOfImagesUsed>imageLimit || noOfImagesUsed===imageLimit) && !unlimitedUsers.includes(userID))}
                  >
                    {generate}{generate==="Re-Generate"?(" (" + prodDescription.length + "/4)"):null}
                  </button>
                </div>
              </div>
              {/* ************************************************************* */}
            </div>
            <div className="descriptionSection">
              {prodDescription.map((description, index) => {
                return (
                  <div className={`card  card1 card2 ${description===selectedDescription ? 'selectedHighlight' : null}` } >
                    <div className="card-header ">
                      Product Description {index + 1}
                    </div>
                    <div className="card-body desc-from-image">                      
                     {description!=="Your product description will appear in about 10 seconds" ? 
                      <textarea
                        name="blogOutline"
                        type="text"
                        value={description}
                        onChange={(event) => {
                          updateDescription(index, event);
                        }}
                        style={{minHeight:"21px", height: `${((characterCount(description)/61)*23)+(description.includes('\n') ? 20 : 0)}px`}}
                        data-hj-allow
                      />
                       :
                        description  } 
                    </div>
                    <div className="card-footer">
                      <div className="wordCountArea">
                        <div className="p-2 bd-highlight">
                          Count (Character / Word / Sentence) :{" "}
                          {characterCount(description)} /{" "}
                          {wordCount(description)} /{" "}
                          {sentenceCount(description)}
                          <br /> Keywords matched:{" "}
                          {keywordsMatched(tags, description)}/
                          {tags ? tags.length : 0}&nbsp;
                          <select
                            className="dropdownTags"
                            name="TagsList"
                            id="TagsList"
                          >
                            {tagsList(tags, description)}
                          </select>
                        </div>
                        <div className="copyButtonIcon" color="grey"
                            onClick={()=> setSelectedDescription(description)} >
                          select                          
                        </div>
                        <div className="copyButtonIcon" color="grey"
                            onClick={()=> copy(description)} >
                          copy                          
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SentenceAndPointers;
