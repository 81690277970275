import axios from 'axios'

export const createMagentoCSV = async (
  season,
  collection,
  // images
  tagImages,
  frontImages,
  sideImages,
  backImages,
  //supplier
  supplierCode,
  supplierName,
  brand,
  //style
  styleCode,
  temp,
  //class
  webCode,
  retail1Description,
  colorEN,
  colorFR,
  descriptionEN,
  descriptionFR,
  modelHeight,
  modelSize,
  titleEN,
  titleFR,
  length,
  inches,
  careInstructions,
  closure,
  sleeveCuffs,
  fabricComposition,
  hood,
  notes,
  itemClass,
  technicalDetails,
  lining,
  collar,
  pocket,
  interiorPockets,
  neckline,
  pattern,
  skirt,
  fit,
  sleeveLength,
  liningMaterial,
  exteriorMaterial
) => {  

  const magentoHeading = [
    "sku",
    'store_view_code',
    "meta_title",
    // "meta_keywords",
    "meta_description",
    "related_skus",
    "upsell_skus",
    // "related_position",
    // "crosssell_skus"
  ];
  const csv = [magentoHeading]; 
  const SEOdescriptionPrompt = []
  for (let i = 0; i < tagImages.length; i++) {
    SEOdescriptionPrompt.push(`Buy the ${titleEN[i]}.
    ${temp ? temp[i] : ""},
    ${closure ? closure[i] : ""},
    ${sleeveCuffs ? sleeveCuffs[i] : ""},
    ${hood ? hood[i] : ""},
    ${notes ? notes[i] : ""},
    ${technicalDetails ? technicalDetails[i] : ""},
    ${collar ? collar[i] : ""},
    ${pocket ? pocket[i] : ""},
    ${neckline ? neckline[i] : ""},
    ${pattern ? pattern[i] : ""},
    ${skirt ? skirt[i] : ""},
    ${sleeveLength ? sleeveLength[i] : ""}`)
  }

  const seoDescriptions = await axios.post("https://apif.snapwrite.ca/mm-magento-seo", {seoData: SEOdescriptionPrompt, style: styleCode}).then((res)=> res.data)   
 
  for (let i = 0; i < tagImages.length; i++) {    
  
    const realtedProducts = await axios.post("https://apif.snapwrite.ca/mm-related-products", {sku: `${supplierCode[i]}-${styleCode[i]}`, classNumber: itemClass[i], collection: collection, season: season})
    
    let responseJson = JSON.parse(JSON.parse(JSON.stringify(seoDescriptions[styleCode[i]])))
    let metaTitle_en = responseJson.titleEn
    let metaTitle_fr = responseJson.titleFr
    let metaDescription_en = responseJson.en
    let metaDescription_fr = responseJson.fr

    for (let j = 0; j < 3; j++) {     
     
      //Table Rows
      const currentRow = [
        // sku
        `${supplierCode[i]}-${styleCode[i]}`,


        // store view code
        j === 0 ? "" : j === 1 ? "en" : "fr",
          

        // meta title
        j === 0 ? metaTitle_en : j === 1 ? "" : metaTitle_fr,       
        // meta description
        j === 0 ? metaDescription_en : j === 1 ? "" : metaDescription_fr,
        realtedProducts.data?.similar || "",        
        realtedProducts.data?.upSell || "",        
       
      ];

      csv.push(currentRow);
    }
  }

  return csv;
};
