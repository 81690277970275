import trash from "../../../../../assets/trash_can.png"
import ImageColumn from "../../tableComponents/imageColumn";
import handleDelete from "../../utils/handleRowDelete";
import BagsDescriptionColumn from "./bagsDescColumn";

const Row = (props) => {
  let prop = props.prop;
  let index = props.index;

  return (
    <tr
      style={{ position: "relative" }}
      id={index}
      onMouseOver={(event) => props.handleHover(event)}
      onMouseOut={(event) => props.handleMouseOut(event)}
    >
      <td className = "tablecol1">
        <img
          src={trash}
          id={`trash${index}`}
          alt="delete"
          style={{
            height: "2vh",
            width: "1vw",
            opacity: 0.5,
            visibility: "hidden",
          }}
          onClick={() => {
            handleDelete(index, prop);
          }}
        />
      </td>
      {/* Product Image */}
      <ImageColumn
        singleProductModelFilter={{
          userID: prop.userID,
          sku: prop.sku[index],
        }}
        images={prop.s3Images}
        fileName={prop.imageFileName[index]}
        setS3Images={prop.setS3Images}
        index={index}
        template={prop.template}
        id={prop.objectID}
      />
     
      <td>        
        <textarea
          style={{
            backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white",
            border: "none",
            width: "100px",
            resize: "none",
          }}
          onKeyDown={(e) => {
            if (e.key === ",") {
              e.preventDefault();
            }
          }}
          value={prop.brand[index] ? prop.brand[index] : ""}
          onChange={(event) =>
            prop.updateVal(
              'brand',
              event.target.value,
              index,
              prop.brand,
              prop.setBrand
            )
          }
        />
      </td>
      <td>        
        <textarea
          style={{
            background: index % 2 !== 0 ? "#F3F3F3" : "white",
            border: "none",
            width: "120px",
            resize: "none",
          }}
          onKeyDown={(e) => {
            if (e.key === ",") {
              e.preventDefault();
            }
          }}
          onChange={(event) =>
            prop.updateVal(
              'itemName',
              event.target.value,
              index,
              prop.itemName,
              prop.setItemName
            )
          }
          value={prop.itemName[index]}
        />
      </td>
      <td>        
        <textarea
          style={{
            background: index % 2 !== 0 ? "#F3F3F3" : "white",
            border: "none",
            width: "102px",
            resize: "none",
          }}
          onKeyDown={(e) => {
            if (e.key === ",") {
              e.preventDefault();
            }
          }}
          value={prop.typesTaxoUpdate[index] ? prop.typesTaxoUpdate[index] : ""}
          onChange={(event) =>
            prop.updateVal('typesTaxoUpdate', event.target.value, index, prop.typesTaxoUpdate, prop.setTypesTaxoUpdate)
          }
        />
      
        <textarea
          style={{
            background: index % 2 !== 0 ? "#F3F3F3" : "white",
            border: "none",
            width: "102px",
            resize: "none",
          }}
          onKeyDown={(e) => {
            if (e.key === ",") {
              e.preventDefault();
            }
          }}
          onChange={(event) =>
            prop.updateVal(
              'material',
              event.target.value,
              index,
              prop.material,
              prop.setMaterial
            )
          }
          value={prop.material[index] ? prop.material[index] : ""}
        />
      </td>      
      <td>
        <textarea
          style={{
            background: index % 2 !== 0 ? "#F3F3F3" : "white",
            border: "none",
            width: "102px",
            resize: "none",
          }}
          onKeyDown={(e) => {
            if (e.key === ",") {
              e.preventDefault();
            }
          }}
          onChange={(event) =>
            prop.updateVal(
              'colors',
              event.target.value,
              index,
              prop.colors,
              prop.setColor
            )
          }
          value={prop.colors[index] ? prop.colors[index] : ""}
        />
      </td>
      {/* Condition */}
      <td>
        <select
          value={prop.condition[index]}
          onChange={(event) =>
            prop.updateVal(
              'condition',
              event.target.value,
              index,
              prop.condition,
              prop.setCondition
            )
          }
        >
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Like New">Like New</option>
          <option value="Brand New with Tags">Brand New with Tags</option>
        </select>
      </td>      
      <td>
        <textarea
          style={{
            background: index % 2 !== 0 ? "#F3F3F3" : "white",
            border: "none",
            width: "102px",
            resize: "none",
          }}
          onKeyDown={(e) => {
            if (e.key === ",") {
              e.preventDefault();
            }
          }}
          onChange={(event) =>
            prop.updateVal(
              'genders',
              event.target.value,
              index,
              prop.genders,
              prop.setGender
            )
          }
          value={prop.genders[index] ? prop.genders[index] : ""}
        />
      </td>     
      <BagsDescriptionColumn index={index} prop={prop} generatePause={props.generatePause}
                  setGeneratePause={props.setGeneratePause}/>
    </tr>
  );
};

export default Row;
