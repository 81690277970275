const front = [
    "Forepart",
    "Facade",
    "Exterior",
    "Anterior",
    "Face",
    "Fore",
    "Forward",
    "Visage",
    "Forefront",
    "Foreface",
    "Frontage",
    "front"
  ];
  
  const side = [
    "Flank",
    "Edge",
    "Border",
    "Periphery",
    "Margin",
    "Wing",
    "Borderline",
    "Boundary",
    "Hem",
    "Brim",
    "Rim",
    "Verge",
    "side"
  ];
  
  const back = [
    "Rear",
    "Hind",
    "Reverse",
    "Posterior",
    "Behind",
    "Tail",
    "After",
    "Following",
    "Backside",
    "End",
    "Trailing",
    "Aft",
    "Abaft",
    "Stern",
    "back"
  ];

  export const closureLocation = [front, side, back]