const animalisticSynonyms = [
    "Animal print",
    "animal printed",
    "animal",
    "leopard",
    "leopard print",
    "cheetah",
    "cheetah print",
    "tiger print",
    'animalistic'
  ];
  
  const camouflageSynonyms = [
    "Military",
    "army",
    "camo",
    "camouflage"
  ];
  
  const checkedSynonyms = [
    "Scotch-plaid",
    "buffalo-checked",
    "plaid",
    "checkered",
    "check",
    "checked"
  ];
  
  const chevronSynonyms = [
    "chevron"
  ];
  
  const floralSynonyms = [
    "flower",
    "bloom",
    "blossom",
    "blooming",
    "sunflower",
    "paisley",
    "tropical",
    "daisy",
    "daisy print",
    "hawaiian",
    "aloha",
    "beach",
    "rose",
    "floral"
  ];
  
  const geometricAndAbstractSynonyms = [
    "geo",
    "geo printed",
    "geometric-print",
    "geometric printed",
    "geo-print",
    "geo-printed",
    "art",
    "retro",
    "artsy",
    "scribble",
    "geometric"
  ];
  
  const otherSynonyms = [
    "Landscape",
    "graphic",
    "embroidered",
    "vortex",
    "vintage",
    "other"
  ];
  
  const polkaDotSynonyms = [
    "Dotted",
    "polkadot",
    "polkadots",
    "polka-dots",
    "polka-dot",
    "dot",
    "polka dot"
  ];
  
  const stripeSynonyms = [
    "Striped",
    "pinstripe",
    "vertical-stripe",
    "vertical stripe",
    "stripe"
  ];
  

const synonymsArray = [animalisticSynonyms, camouflageSynonyms, checkedSynonyms, chevronSynonyms, floralSynonyms, geometricAndAbstractSynonyms, otherSynonyms,
                       polkaDotSynonyms, stripeSynonyms]

export const getPatternSynonyms = (pattern) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => pattern.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList]
        }
    }
    return toReturn;
}

