const welt = [
    "Sewn-in",
    "Seam",
    "Padded",
    "Edge",
    "Border",
    "Binding",
    "Piping",
    "Trim",
    "Hem",
    "Fringe",
    "Braid",
    "welt"
  ];
  
  const patch = [
    "Pouch",
    "Attached",
    "Sewn",
    "patch"
  ];
  
  const flap = [
    "Seal",
    "Close",
    "Cover",
    "Fold",
    "Cover",
    "Lid",
    "Overlay",
    "Panel",
    "Tab",
    "Extension",
    "Wing",
    "flap"
  ];
  
  const slant = [
    "Slash",
    "Angled",
    "Slant",
    "Diagonal",
    "slant"
  ];
  
  const hidden = [
    "Concealead",
    "Invisible",
    "Secret",
    "Concealed",
    "Obscured",
    "Covered",
    "Unseen",
    "Veiled",
    "hidden"
  ];

  const zipper = [
    "Zipper",
    "Zippered",
    "zip"
  ];

  export const pocketType = [welt, patch, flap, slant, hidden, zipper]