
import axios from "axios";
import { getTypeSynonyms } from "../shopify/utils/synonyms/synonyms";
import { getDesignSynonyms } from "../shopify/utils/synonyms/designSynonyms";
import { getNecklineSynonyms } from "../shopify/utils/synonyms/necklineSynonyms";
import { getPatternSynonyms } from "../shopify/utils/synonyms/patternSynonyms"
import stateArrayDBassignment from "../shopify/utils/stateDBassignment";


const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});


export const createEbayCSV = async (template, userID, objectID, isImport=false, formattedThemeData) => {

  let returnVal = []
    // Regular
    let s3Images = []
    let itemName = []
    let size = []
    let sizeNotes = []
    let brand = []
    let sku = []
    let types = []
    let taxonomyTypes = []
    let colors = []
    let condition = []
    let price = []
    let genders = []
    let imageFileName = []
    let costPerItem = []
    let genericType = []
    let itemDescription = []
    let vendorP = []
    let retailPrice = []
    let conditionDescription = []
    let supplementaryImages = []

    let additionalInfos = []
    let itemWeight = []

    // Vintage Specific
    let era = []
    let madeIn = []
    
    // Measurements
    let sleeveM = []
    let lengthM = []
    let pitToPitM = []
    let shoulderM = []
    let waistM = []
    let hipM = []
    let bottomInSeam = []
    let riseMeasure = []
    let thighMeasure = []
    let legOpening = []

    // Footwear
    let heelHeightMeasure = []
    let soleMeasure = []
    let inSoleMeasure = []
    let shoeWidth = []

    // Properties

    let material = []
    let sleeves = []
    let style = []
    let closure = []
    let fastening_type = []
    let neckline= []
    let cut = []
    let hood = []
    let belt = []
    let design = []
    let lining = []
    let layers = []
    let pattern = []
    let skirt = []
    let rise = []
    let heelHeight = []
    let heelShape = []
    let height = []
    let toe = []
    let theme = []
    let projectName = []

    const titleData = await axios.get(`https://apie.snapwrite.ca/storeData/csv-title/${userID}`)
    .catch(err => console.error(err))
		
		const titleBlocks = titleData.data.data ? titleData.data.data.titleBlocks : undefined
		const descriptionBlocks = titleData.data.data ? titleData.data.data.descriptionBlocks : undefined



    let link = `https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`;
    if (template === "vintage") {
      link = `https://apie.snapwrite.ca/storeData/vintage-apparel`;
    } else if (template === "new-clothes") {
      link = `https://apie.snapwrite.ca/storeData/new-apparel`;
    } else if (template === "pre-loved-regular") {
      link = `https://apie.snapwrite.ca/storeData/regular-preLoved-apparel`;
    }else if(template === "themes"){
      link = `https://apie.snapwrite.ca/storeData/get-themes-project-items`
    }
    let json;
      if(objectID && objectID!=="") {
        
        try{    
        if (template !== "themes") {
          //Fetch Data
          json = await fetch(`${link}/${objectID}`).then((response) =>
            response.json()
          );
        } else{
          const response = await axios.get(`${link}/${objectID}`).then((res) => {
            json = {data: formattedThemeData(res.data)}
          })
        }
        
          //  CSV Gen Starts here
          if (json.data.theme) {
				theme = json.data.theme;
			} else {
				theme = Array(json.data.images.length).fill('');
			}
            s3Images = json.data.images;    
            if (json.data.itemName) {
              itemName = json.data.itemName;
            } else {
              itemName = Array(json.data.images.length).fill("");
            }
            if (json.data.size) {
              size = json.data.size;
            } else {
              size = Array(json.data.images.length).fill("");
            }
            if (json.data.sizeNotes) {
              sizeNotes = json.data.sizeNotes;
            } else {
              sizeNotes = Array(json.data.images.length).fill("");
            }
            if (json.data.brand) {
              brand = json.data.brand;
            } else {
              brand = Array(json.data.images.length).fill("");
            }
            if(json.data.sku) {
              sku = json.data.sku;
            } else {
              sku = Array(json.data.images.length).fill("");
            }
            if (json.data.types) {
              types = json.data.types;
            } else {
              types = Array(json.data.images.length).fill("");
            }
            if (json.data.taxonomyUpdatedType) {
              taxonomyTypes = json.data.taxonomyUpdatedType;
            } else {
              taxonomyTypes = Array(json.data.images.length).fill("");
            }
            if (json.data.colors) {
              colors = json.data.colors;
            } else {
              colors = Array(json.data.images.length).fill("");
            }
            if (json.data.condition) {
              condition = json.data.condition;
            } else {
              condition = Array(json.data.images.length).fill("");
            }
            if (json.data.price) {
              price = json.data.price;
            } else {
              price = Array(json.data.images.length).fill("");
            }
            if (json.data.gender) {
              genders = json.data.gender;
            } else {
              genders = Array(json.data.images.length).fill("");
            }
            if (json.data.fileNames) {
              imageFileName = json.data.fileNames;
            } else {
              imageFileName = Array(json.data.images.length).fill("");
            }
            if (json.data.cost) {
              costPerItem = json.data.cost;
            } else {
              costPerItem = Array(json.data.images.length).fill("");
            }
            if (json.data.itemGenericType) {
              genericType = json.data.itemGenericType;
            } else {
              genericType = Array(json.data.images.length).fill("");
            }
            if (json.data.description) {
              itemDescription = json.data.description;
            } else {
              itemDescription = Array(json.data.images.length).fill("");
            }        
            if(json.data.vendor) {
              vendorP = json.data.vendor;
            } else {
              vendorP = Array(json.data.images.length).fill("");
            }
            if (json.data.retailPrice) {
              retailPrice = json.data.retailPrice;
            } else {
              retailPrice = Array(json.data.images.length).fill("");
            }
            if (json.data.conditionDescription) {
              conditionDescription = json.data.conditionDescription;
            } else {
              conditionDescription = Array(json.data.images.length).fill("");
            }
            if (
              json.data.supplementaryImages &&
              json.data.supplementaryImages.length > 0
            ) {
              supplementaryImages = json.data.supplementaryImages;
            } else {
              supplementaryImages = Array(json.data.images.length).fill([]);
            }
            
    
            if (json.data.additionalInfo) {
              additionalInfos = json.data.additionalInfo;
            } else {
              additionalInfos = Array(json.data.images.length).fill("");
            }
            if (json.data.variantGrams) {
              itemWeight = json.data.variantGrams;
            } else {
              itemWeight = Array(json.data.images.length).fill("");
            }
    
            // Vintage
    
            if (json.data.era && json.data.era.length === json.data.images.length) {
              era = json.data.era;
            } else {
              era = Array(json.data.images.length).fill("");
            }
            if (
              json.data.madeIn &&
              json.data.madeIn.length === json.data.images.length
            ) {
              madeIn = json.data.madeIn;
            } else {
              madeIn = Array(json.data.images.length).fill("");
            }       
          
            // Measurements
            
            if (
              json.data.sleeveInSeam &&
              json.data.sleeveInSeam.length === json.data.images.length
            ) {
              sleeveM = json.data.sleeveInSeam;
            } else {
              sleeveM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.lengthMeasure &&
              json.data.lengthMeasure.length === json.data.images.length
            ) {
              lengthM = json.data.lengthMeasure;
            } else {
              lengthM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.pitToPitMeasure &&
              json.data.pitToPitMeasure.length === json.data.images.length
            ) {
              pitToPitM = json.data.pitToPitMeasure;
            } else {
              pitToPitM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.shoulderMeasure &&
              json.data.shoulderMeasure.length === json.data.images.length
            ) {
              shoulderM = json.data.shoulderMeasure;
            } else {
              shoulderM = Array(json.data.images.length).fill("");
            }
    
            if (
              json.data.waistMeasure &&
              json.data.waistMeasure.length === json.data.images.length
            ) {
              waistM = json.data.waistMeasure;
            } else {
              waistM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.hipMeasure &&
              json.data.hipMeasure.length === json.data.images.length
            ) {
              hipM = json.data.hipMeasure;
            } else {
              hipM = Array(json.data.images.length).fill("");
            }
            if (
              json.data.bottomInSeam &&
              json.data.bottomInSeam.length === json.data.images.length
            ) {
              bottomInSeam = json.data.bottomInSeam;
            } else {
              bottomInSeam = Array(json.data.images.length).fill("");
            }
            if (
              json.data.riseMeasure &&
              json.data.riseMeasure.length === json.data.images.length
            ) {
              riseMeasure = json.data.riseMeasure;
            } else {
              riseMeasure = Array(json.data.images.length).fill("");
            }
            if (
              json.data.thighMeasure &&
              json.data.thighMeasure.length === json.data.images.length
            ) {
              thighMeasure = json.data.thighMeasure;
            } else {
              thighMeasure = Array(json.data.images.length).fill("");
            }
            if (
              json.data.legOpening &&
              json.data.legOpening.length === json.data.images.length
            ) {
              legOpening = json.data.legOpening;
            } else {
              legOpening = Array(json.data.images.length).fill("");
            }       
            
            //Footwear
            heelHeightMeasure = stateArrayDBassignment(json.data.heelHeightMeasure, json.data.images.length)            
            soleMeasure = stateArrayDBassignment(json.data.soleMeasure, json.data.images.length)
            inSoleMeasure = stateArrayDBassignment(json.data.inSoleMeasure, json.data.images.length)
            shoeWidth = stateArrayDBassignment(json.data.shoeWidth, json.data.images.length)                                     

            // console.log(heelHeightMeasure)
            // console.log(soleMeasure)
            // console.log(inSoleMeasure)
            // console.log(shoeWidth)

            // Attributes
            if (json.data.material) {
              material = json.data.material;
            } else {
              material = Array(json.data.images.length).fill("");
            }
            if (json.data.sleeves) {
              sleeves = json.data.sleeves;
            } else {
              sleeves = Array(json.data.images.length).fill("");
            }
          
            if (json.data.style) {
              style = json.data.style;
            } else {
              style = Array(json.data.images.length).fill("");
            }
            
            if (json.data.closure) {
              closure = json.data.closure;
            } else {
              closure = Array(json.data.images.length).fill("");
            }
            if (json.data.fastening_type) {
              fastening_type = json.data.fastening_type;
            } else {
              fastening_type = Array(json.data.images.length).fill("");
            }
            if (json.data.neckline) {
              neckline = json.data.neckline;
            } else {
              neckline = Array(json.data.images.length).fill("");
            }
            if (json.data.cut) {
              cut = json.data.cut;
            } else {
              cut = Array(json.data.images.length).fill("");
            }
            if (json.data.design) {
              design = json.data.design;
            } else {
              design = Array(json.data.images.length).fill("");
            }
            if (json.data.hood) {
              hood = json.data.hood;
            } else {
              hood = Array(json.data.images.length).fill("");
            }
            
            if (json.data.belt) {
              belt = json.data.belt;
            } else {
              belt = Array(json.data.images.length).fill("");
            }
            if (json.data.lining) {
              lining = json.data.lining;
            } else {
              lining = Array(json.data.images.length).fill("");
            }
            if (json.data.layers) {
              layers = json.data.layers
            } else {
              layers = Array(json.data.images.length).fill("");
            }
            if (json.data.pattern) {
              pattern = json.data.pattern;
            } else {
              pattern = Array(json.data.images.length).fill("");
            }
            if (json.data.skirt) {
              skirt = json.data.skirt;
            } else {
              skirt = Array(json.data.images.length).fill("");
            }
            if (json.data.rise) {
              rise = json.data.rise;
            } else {
              rise = Array(json.data.images.length).fill("");
            }
            if (json.data.heelHeight) {
              heelHeight = json.data.heelHeight;
            } else {
              heelHeight = Array(json.data.images.length).fill("");
            }
            if (json.data.heelShape) {
              heelShape = json.data.heelShape;
            } else {
              heelShape = Array(json.data.images.length).fill("");
            }
            if (json.data.height) {
              height = json.data.height;
            } else {
              height = Array(json.data.images.length).fill("");
            }
            if (json.data.toe) {
              toe = json.data.toe;
            } else {
              toe = Array(json.data.images.length).fill("");
            }
    
            // if (json.data.backImage) {
            //   setBackImage(json.data.backImage);
            // } else {
            //   setBackImage(Array(json.data.images.length).fill(""));
            // }
            
            if (json.data.projectName) {
              projectName = json.data.projectName
            } else {
              projectName = "";
            }
    
            // if (json.data.length) {
            //   setLength(json.data.length);
            // } else {
            //   setLength(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.typeObj) {
            //   setTypeObj(json.data.typeObj);
            // } else {
            //   setTypeObj(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.age) {
            //   setAge(json.data.age);
            // } else {
            //   setAge(Array(json.data.images.length).fill(""));
            // }
            // if (json.data.fastening) {
            //   setFastening(json.data.fastening);
            // } else {
            //   setFastening(Array(json.data.images.length).fill(""));
            // }
    
            // if (json.data.tags) {
            //   setTags(json.data.tags);
            // } else {
            //   setTags(json.data.tags);
            // }
    
            
          
          
            if(itemName.length===0 || !itemName) {
              itemName = Array(s3Images.length).fill("")
            }
            if(era.length===0 || !era) {
              era = Array(s3Images.length).fill("")
            }
            if(madeIn.length===0 || !madeIn) {
              madeIn = Array(s3Images.length).fill("")
            }
            if(material.length===0 || !material) {
              material = Array(s3Images.length).fill("")
            }
            //Measurements
            if(waistM.length===0 || !waistM) {
              waistM = Array(s3Images.length).fill("")
            }
            if(sleeveM.length===0 || !sleeveM) {
              sleeveM = Array(s3Images.length).fill("")
            }
            if(pitToPitM.length===0 || !pitToPitM) {
              pitToPitM = Array(s3Images.length).fill("")
            }
            if(hipM.length===0 || !hipM) {
              hipM = Array(s3Images.length).fill("")
            }
            if(lengthM.length===0 || !lengthM) {
              lengthM = Array(s3Images.length).fill("")
            }
            if(shoulderM.length===0 || !shoulderM){
              shoulderM = Array(s3Images.length).fill("")      
            }
            if(size.length===0 || !size) {
              size = Array(s3Images.length).fill("")
            }
            if(sizeNotes.length===0 || !sizeNotes) {
              sizeNotes = Array(s3Images.length).fill("")
            }
            if(brand.length===0 || !brand){
              brand = Array(s3Images.length).fill("")
            }
            if(sku.length===0 || !sku){
              sku = Array(s3Images.length).fill("")
            }
            if(types.length===0 || !types){
              types = Array(s3Images.length).fill("")
            }
            if(taxonomyTypes.length===0 || !taxonomyTypes){
              taxonomyTypes = Array(s3Images.length).fill("")
            }
            if(colors.length===0 || !colors){
              colors = Array(s3Images.length).fill("")
            }
            if(condition.length===0 || !condition){
              condition = Array(s3Images.length).fill("")
            }
            if(price.length===0 || !price) {
              price = Array(s3Images.length).fill("")
            }
            if(genders.length===0 || !genders) {
              genders = Array(s3Images.length).fill("")
            }
            if(imageFileName.length === 0 || !imageFileName) {
              imageFileName = Array(s3Images.length).fill("")
            }
            if(additionalInfos.length === 0 || !additionalInfos){
              additionalInfos = Array(s3Images.length).fill("")
            }
            if(costPerItem.length === 0 || !costPerItem){
              costPerItem = Array(s3Images.length).fill("")
            }
            if(itemWeight.length === 0 || !itemName) {
              itemWeight = Array(s3Images.length).fill("")
            }
            if(genericType.length === 0 || !genericType) {
              genericType = Array(s3Images.length).fill("")
            }
            if(itemDescription.length === 0 || !itemDescription){
              itemDescription = Array(s3Images.length).fill("")
            }
            if(vendorP.length === 0 || !vendorP){
              vendorP = Array(s3Images.length).fill("")
            }
            if(retailPrice.length === 0 || !retailPrice){
              retailPrice = Array(s3Images.length).fill("")
            }
            if(conditionDescription.length === 0 || !conditionDescription){
              conditionDescription = Array(s3Images.length).fill("")
            }
            if(supplementaryImages.length === 0 || !supplementaryImages){
              supplementaryImages = Array(s3Images.length).fill([])
            }
            if(sleeves.length===0 || !sleeves) {
              sleeves = Array(s3Images.length).fill([])
            }
            if(style.length === 0 || !style) {
              style = Array(s3Images.length).fill([])
            }
            if(closure.length === 0 || !closure){
              closure = Array(s3Images.length).fill([])
            }
            if(fastening_type.length===0 || !fastening_type){
              fastening_type = Array(s3Images.length).fill([])
            }
            if(neckline.length === 0 || !neckline) {
              neckline = Array(s3Images.length).fill([])
            }
            if(cut.length === 0 || !cut) {
              cut = Array(s3Images.length).fill([])
            }
            if(hood.length === 0 || !hood) {
              hood = Array(s3Images.length).fill([])
            }
            if(belt.length === 0 || !belt) {
              belt = Array(s3Images.length).fill([])
            }
            if(design.length === 0 || !design) {
              design = Array(s3Images.length).fill([])
            }
            if(lining.length === 0 || !lining) {
              lining = Array(s3Images.length).fill([])
            }
            if(layers.length === 0 || !layers) {
              layers = Array(s3Images.length).fill([])
            }
            if(pattern.length === 0 || !pattern) {
              pattern = Array(s3Images.length).fill([])
            }
            if(skirt.length === 0 || !skirt) {
              skirt = Array(s3Images.length).fill([])
            }
            if(rise.length === 0 || !rise) {
              rise = Array(s3Images.length).fill([])
            }
            if(heelHeight.length === 0 || !heelHeight) {
              heelHeight = Array(s3Images.length).fill([])
            }
            if(heelShape.length === 0 || !heelShape) {
              heelShape = Array(s3Images.length).fill([])
            }
            if(height.length === 0 || !height) {
              height = Array(s3Images.length).fill([])
            }
            if(!toe || toe.length === 0) {
              toe = Array(s3Images.length).fill([])
            }

          const getVariantRow = (option1Title, option2Title, option3Title, userID, sku, itemName, handle, colorVariant, imageLink, index, costPerItem, weight, taxable, price) => {            
            let variantRow;
            let currentSku="";
            let currentWeightUnit = ""
            let currentWeight = ""
            let currentInvetoryTracker = ""
            let currentInventoryQuantity = ""
            let currentInventoryPolicy = ""
            let currentFulfillment = ""
            let currentShipping = ""
            let currentPrice = ""
            let currentCost = ""
            let currentTax = ""
            let currentImagePosition = imageLink !== "" ? index+2 : ""
            
            if (colorVariant !== "") {
              currentSku = `${sku}-${colorVariant}`
              currentWeightUnit = "kg"
              currentWeight = weight
              currentInvetoryTracker = "shopify"
              currentInventoryQuantity = 1
              currentInventoryPolicy = "deny"
              currentFulfillment = "manual"
              currentShipping = "TRUE"
              currentPrice = price
              currentCost = costPerItem
              currentTax = taxable
            }            
            //0	shopify	1	deny	manual	0		TRUE	TRUE
            if(option1Title==="Color") {

              variantRow = [
                `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku + '-' + itemName.replace(/\s+/g, '-'): handle}`, //Handle
                ``, ``, ``, ``, ``, ``, "",
                ``,
                `${colorVariant}`,
                ``,``, "", '', `${currentSku}`, currentWeight, `${currentInvetoryTracker}`, currentInventoryQuantity, `${currentInventoryPolicy}`, `${currentFulfillment}`, `${currentPrice}`, "", `${currentShipping}`, `${currentTax}`, "", 
                imageLink,
                currentImagePosition, //Image Position
                ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, `${currentWeightUnit}`, "", `${currentCost}`, ``, "", ``, ``, false
              ]              
            } else if (option2Title === "Color") {                

                variantRow = [
                    `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku + '-' + itemName.replace(/\s+/g, '-'): handle}`, //Handle
                    ``, ``, ``, ``, ``, ``, "", ``, ``,
                    ``, 
                    `${colorVariant}`,
                    "", '', `${currentSku}`, currentWeight, `${currentInvetoryTracker}`, currentInventoryQuantity, `${currentInventoryPolicy}`, `${currentFulfillment}`, `${currentPrice}`, "", `${currentShipping}`, `${currentTax}`, "", 
                    imageLink,
                    currentImagePosition, //Image Position
                    ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, `${currentWeightUnit}`, "", `${currentCost}`, ``, "", ``, ``, false
                  ]
            } else if (option3Title === "Color") {                

                variantRow = [
                  `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? sku + '-' + itemName.replace(/\s+/g, '-'): handle}`, //Handle
                  ``, ``, ``, ``, ``, ``, "", ``, ``, ``, ``, 
                  "",
                  `${colorVariant}`,
                  `${currentSku}`, currentWeight, `${currentInvetoryTracker}`, currentInventoryQuantity, `${currentInventoryPolicy}`, `${currentFulfillment}`, `${currentPrice}`, "", `${currentShipping}`, `${currentTax}`, "", 
                  imageLink,
                  currentImagePosition, //Image Position
                  ``, "", ``, ``, ``, ``, "", "", "", "", '', "", "", "", "", "", "", ``, `${currentWeightUnit}`, "", `${currentCost}`, ``, "", ``, ``, false
                ]
            }
            return variantRow
          }


          const filteredUserBrands = brand.filter(item => typeof item === "string")
          axios.put(`https://apie.snapwrite.ca/storeData/brands`, {brands: filteredUserBrands})
          let csv = [];
          // let csv = csvRowsTemp;
          let i = 0;
      
          for (i = 0; i < s3Images.length; i++) {
            let lines = ''
            if(itemDescription[i]){
              lines = itemDescription[i].split("\n");
            }
            let result = '';
            for(let im = 0; im < lines.length; im++){
              if(lines[im]!==""){
                let para=lines[im];
                if (lines[im].includes(`"`)) {
                  para = lines[im].replace(
                    /"/g,
                    `'`
                  );
                }
                result += "<p>" + para + "</p>";
              }
            }

            let userDesc = ""
          
            let handle =  `${itemName[i]}_${colors[i]}_${brand[i]}_${types[i]}_${size[i]}_${imageFileName[i]!==undefined && imageFileName[i]!==""?imageFileName[i]: Math.random().toFixed(3)*100}`             
            let tags = `${userID==="b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1" ? "" : "New Arrivals"}, ${brand[i]}, ${types[i]}, ${size[i]},${colors[i]},${additionalInfos[i]}, ${taxonomyTypes[i]}`;
            let collection = ``;
            let title = `${brand[i]} ${itemName[i]}${size[i]? ` (${size[i]})`:''}`;
            let description = `<meta charset='UTF-8'>${itemDescription[i] && itemDescription[i]!== ''? `<p class='p1'><span class='s1'>${`${result!==""?result:itemDescription[i]}`}</span></p>` : '' }${colors[i]!=="" ? `<p class='p1'><span class='s1'>Colour: ${colors[i]}</span></p>` : ""}${size[i]!=="" ? `<p class='p1'><span class='s1'>Size: ${size[i]}</span></p>` : ""}${template!=='new-clothes'?`<p class='p1'><span class='s1'>Condition: ${condition[i] === "" || !condition[i] ? "Excellent Used Condition" : condition[i]}</span>${conditionDescription[i] && conditionDescription[i]!==''? `. <span>${conditionDescription[i]}</span>`:''}</p>`:''} `;
            let option1Title =`Title`
            let option1val = `Default Title`
            let option2Title = ``
            let option2val =``
            let option3Title = ``
            let option3val =``          
            let imageAlt=`${title}`
            let weight = 0
            let weightUnit = ``
            let vendor = `${brand[i]}`
            if(vendorP[i]!==''){
              vendor = vendorP[i]
              title =  `${brand[i]} ${itemName[i]}${size[i]? ` // ${size[i]}`:''}`;
            }
            let customProductType = `${types[i]}`
            let status = `draft`
            let taxable = `TRUE`
            let genderTag = [genders[i]];   
            let isAgeBaby = false;         
            if(genders[i] && genders[i].toLocaleLowerCase().includes('gender neutral')){
              genderTag = ['Boys', 'Girls']
            }
            else if(genders[i] && !genders[i].toLocaleLowerCase().includes('gender neutral')){
              if(/\s/g.test(genders[i])){
                genderTag = genders[i].split(/\s+/) 
              }
            }
            
           
            if(template === 'vintage' || template=== 'pre-loved-regular'){
              customProductType = taxonomyTypes[i]
              let sizeTag=[]
              if(size[i].toLowerCase().includes("s")){
                sizeTag.push("small")
              }
              if(size[i].toLowerCase().includes("m")){
                sizeTag.push("medium")
              }
              if(size[i].toLowerCase().includes("l")){
                sizeTag.push("large")
              }
              if(size[i].toLowerCase().includes("xl")){
                sizeTag.push("extra large", "xtra large")
              }
              if(size[i].toLowerCase().includes("xxl")){
                sizeTag.push("extra extra large", "xtra xtra large")
              }
              tags = `New Arrivals, online, ${sizeTag}, ${brand[i]}, ${condition[i]}, ${itemName[i]}, ${era[i]}, vintage, preloved, pre-loved, ${colors[i]}, ${size[i]}, ${types[i]}, ${taxonomyTypes[i]}, ${sleeves[i]}, ${style[i]}, ${closure[i]}, ${fastening_type[i]}, ${neckline[i]}, ${cut[i]}, ${hood[i]}, ${belt[i]}, ${design[i]}, ${lining[i]}, ${layers[i]}, ${pattern[i]}, ${skirt[i]}, ${rise[i]}, ${heelHeight[i]}, ${heelShape[i]}, ${height[i]}, ${toe[i]}, hype, streetwear, ${projectName}`
              title = `${era[i]!==''?`${era[i]} `:""}${brand[i]!==''?`${brand[i]} `:''}${itemName[i]!==''?`${itemName[i]} `:''}`;
              description = `
              ${result}
              ${madeIn[i]!==""?`<p><b>Made In:</b> ${madeIn[i]}</p>`:''}
              ${era[i]?`<p><b>Era:</b> ${era[i]}</p>`:''}
              ${conditionDescription[i]===""?  `<p><b>Condition:</b> ${condition[i]}</p>`:  `<p><b>Condition:</b> ${condition[i]}</p><p>&nbsp;&nbsp;&nbsp;&nbsp;• ${conditionDescription[i]}</p>`}
              <p><b>Color:</b> ${colors[i]}</p>
              <p><b>Size:</b> ${size[i]}${sizeNotes[i]!==""?`. ${sizeNotes[i].replace(/"/g, '""')}`: ""}</p>
              ${waistM[i]!=="" || hipM[i]!=="" || bottomInSeam[i]!=="" || riseMeasure[i]!=="" || thighMeasure[i]!=="" || legOpening[i]!=="" || pitToPitM[i]!=="" || lengthM[i]!=="" || shoulderM[i]!=="" || sleeveM[i]!=="" || heelHeightMeasure[i] !== "" || soleMeasure[i] !== "" || inSoleMeasure[i] !== "" || shoeWidth[i] !== "" ?
              `<p><b>Measurements:</b></p>
              ${waistM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${hipM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${bottomInSeam[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i].replace(/"/g, '""')}</p>
              `: ""}
              ${riseMeasure[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i].replace(/"/g, '""')}</p>
              `: ""}
              ${thighMeasure[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i].replace(/"/g, '""')}</p>
              `: ""}
              ${legOpening[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i].replace(/"/g, '""')}</p>
              `: ""}
              ${pitToPitM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${shoulderM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${sleeveM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i].replace(/"/g, '""')}</p>
              `: ""} 
              ${lengthM[i]!=="" ? `
              <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i].replace(/"/g, '""')}</p>
              `: ""}
              ${heelHeightMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Heel Height: ${heelHeightMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
              ${soleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sole: ${soleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
              ${inSoleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Insole: ${inSoleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
              ${shoeWidth[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Width: ${shoeWidth[i].replace(/"/g, '""')}</p>
                `: ""}
              `:''} 
              ${material[i] && material[i]!==''? `
              <p><b>Fabric:</b> ${material[i].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</p>
              `: ""}
              <br />
              <p>
                Sizes for vintage garments may not match the modern sizing convention. Please
                see measurements to ensure a proper fit.
              </p>
              `
              if (userID === '1eMplQPlHHhX9lnwX8NTcKghui22' || userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1') { //Cherry Pick Vintage
                if (
                  brand[i] === "Nike" ||
                  brand[i] === "Harley Davidson" ||
                  brand[i] === "Orange County Choppers" ||
                  brand[i] === "Russell" ||
                  brand[i] === "Bape" ||
                  brand[i] === "Stussy" ||
                  brand[i] === "Carhartt" ||
                  brand[i] === "Diesel" ||
                  brand[i] === "Stone Island" ||
                  brand[i] === "The North Face" ||
                  brand[i] === "Patagonia" ||
                  brand[i] === "Levi"            
                ) {
                    tags = tags + ', Branded'
                }
                title = `${era[i]!==''?`${era[i]} `:""}${brand[i]!==''?`${brand[i]} `:''}${itemName[i]!==''?`${itemName[i]} ${taxonomyTypes[i]}`: `${taxonomyTypes[i]} `} (${size[i]})`;
                description = `
                <p><b>Size:</b> ${size[i]}${sizeNotes[i]!==""?`. ${sizeNotes[i].replace(/"/g, '""')}`: ""}</p>
                ${waistM[i]!=="" || hipM[i]!=="" || bottomInSeam[i]!=="" || riseMeasure[i]!=="" || thighMeasure[i]!=="" || legOpening[i]!=="" || pitToPitM[i]!=="" || lengthM[i]!=="" || shoulderM[i]!=="" || sleeveM[i]!=="" || heelHeightMeasure[i]!=="" || soleMeasure[i] !== "" || inSoleMeasure[i] !== "" || shoeWidth[i] !== ""  ?`<p><b>Measurements:</b></p>`:''}
                ${waistM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${hipM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${bottomInSeam[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i].replace(/"/g, '""')}</p>
                `: ""}
                ${riseMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${thighMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${legOpening[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i].replace(/"/g, '""')}</p>
                `: ""}
                ${pitToPitM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoulderM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${sleeveM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i].replace(/"/g, '""')}</p>
                `: ""} 
                ${lengthM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${heelHeightMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Heel Height: ${heelHeightMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${soleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sole: ${soleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
               ${inSoleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Insole: ${inSoleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoeWidth[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Width: ${shoeWidth[i].replace(/"/g, '""')}</p>
                `: ""}
                `
                weightUnit = `g`
                // console.log(taxonomyTypes)
                if (taxonomyTypes[i]==='T-shirt') {
                  weight = 100
                } else if (taxonomyTypes[i]==='Sweatshirt') {
                  weight = 340
                } else if (taxonomyTypes[i]==='Longsleeve') {
                  weight = 340
                } else if (taxonomyTypes[i]==='Outerwear') {
                  weight = 540
                } else if (taxonomyTypes[i]==='Pants') {
                  weight = 340
                } 
                if(size[i]) {
                  option1Title = `Size`;
                  option1val = ` ${size[i]}`
                }            
                
              }
              


            }            
     
      
              
            const typeSynonyms = getTypeSynonyms(taxonomyTypes[i])
            const nameSynonyms = getTypeSynonyms(itemName[i])
            const designSynonyms = getDesignSynonyms(design[i])
            const necklineSynonyms = getNecklineSynonyms(neckline[i])
            const patternSynonyms = getPatternSynonyms(pattern[i])
      
            tags += ", " + typeSynonyms.join(", ")
            tags += ", " + nameSynonyms.join(", ")
            tags += ", " + designSynonyms.join(", ")
            tags += ", " + necklineSynonyms.join(", ")
            tags += ", " + patternSynonyms.join(", ")
                  
            let currentSku = sku[i]
            if (template === "new-clothes") {
              if (Array.isArray(colors[i])) {
                if (option1Title === "Title" ||option1Title === "" ) {
                  option1Title = "Color"
                  option1val = `${colors[i][0]}`
                } else if (option2Title === "") {
                  option2Title = "Color"
                  option2val = `${colors[i][0]}`
                } else if (option3Title === "") {
                  option3Title = "Color"
                  option3val = `${colors[i][0]}`
                }                
                currentSku = `${sku[i]}-${colors[i][0]}`              
              }
            }
            
      
            tags += `, ${colors[i]} ${taxonomyTypes[i]}`
            tags += `, ${design[i]} ${taxonomyTypes[i]}`
            // tags += `, ${genders[i]} ${taxonomyTypes[i]}`
            tags += `, ${isAgeBaby ? "baby" : "adult"} ${taxonomyTypes[i]}`
            tags += `, ${brand[i]} ${taxonomyTypes[i]}`
            tags += `, ${template} ${taxonomyTypes[i]}`
            tags += `, ${theme[i]}`

            let newTitle = ""

            // const getProductCategory = () => {
            //   if (userID === "b41Z6rL6eJN1VccgZXrUzWbdjGg1" || userID === "5APyYorrgoZ8uKOcxp5YMz3J0zq1") {
                
            //     const notBabyClothing = ["costumes", "maternity + nursing", "toys", "gear"]
                
            //     if(!notBabyClothing.includes(taxonomyTypes[i].toLocaleLowerCase())) {
            //         return "Apparel & Accessories > Clothing > Baby & Toddler Clothing"
            //     } else if (taxonomyTypes[i].toLocaleLowerCase().includes("costumes")) {
            //         return "Apparel & Accessories > Costumes & Accessories > Costumes"
            //     } else if (taxonomyTypes[i].toLocaleLowerCase().includes("maternity + nursing")) {
            //         return "Apparel & Accessories > Clothing"
            //     } else if (taxonomyTypes[i].toLocaleLowerCase().includes("toys")) {
            //         return "Toys & Games"
            //     } else if (taxonomyTypes[i].toLocaleLowerCase().includes("gear")) {
            //         return "Baby & Toddler"
            //     }
            //   } else if (userID==="zjkiN1cq9Eb4oHsrA923OoJ9Sxz1" && taxonomyTypes[i].toLocaleLowerCase().includes("tokki market")) {
            //       return "Baby & Toddler"
            //   }
            //   return `Apparel & Accessories${taxonomyTypes[i] ? 
            //     ((taxonomyTypes[i].toLocaleLowerCase().includes("footwear")|| 
            //       taxonomyTypes[i].toLocaleLowerCase().includes("slipper")||
            //       taxonomyTypes[i].toLocaleLowerCase().includes("boot")) ? 
            //         ` > Shoes`
            //     :
            //     ` > Clothing${size[i] ? 
            //           (isAgeBaby ?
            //             ` > Baby & Toddler Clothing${taxonomyTypes[i]!=="" ? 
            //                 ( 
            //                   taxonomyTypes[i].toLocaleLowerCase().includes("top") ? ` > Baby & Toddler Tops` 
            //                   :
            //                   taxonomyTypes[i].toLocaleLowerCase().includes("bottom") ? ` > Baby & Toddler Bottoms`
            //                   :
            //                   taxonomyTypes[i].toLocaleLowerCase().includes("outerwear") ? ` > Baby & Toddler Outerwear`
            //                   :
            //                   taxonomyTypes[i].toLocaleLowerCase().includes("sleepwear") ? ` > Baby & Toddler Sleepwear`
            //                   :
            //                   taxonomyTypes[i].toLocaleLowerCase().includes("swimwear") ? ` > Baby & Toddler Swimwear`
            //                   :
            //                   taxonomyTypes[i].toLocaleLowerCase().includes("dress") ? ` > Baby & Toddler Dresses`
            //                   :""
            //                 )
            //                 :""
            //               }`
            //             : 
            //             ""
            //           )
            //         :
            //         ""
            //       }`
            //     )
            //     :
            //     ""
            //   }`
            // }
            const conditionIds = {
                "Like New":  2750,
                "Very Good": 4000,
                "Good": 50000,
                "Used": 3000
            }

      function findMatchedCategory(category, gender) {
			const genderMap = {
				men: 'Men',
				male: 'Men',
				women: 'Women',
				female: 'Women',
				ladies: 'Women',
				girl: 'Teens',
				girls: 'Teens',
				boys: 'Teens',
				boy: 'Teens',
				unisex: 'Unisex Adults',
				adults: 'Unisex Adults',
				all: 'Unisex Adults',
			};

			gender = genderMap[gender.toLowerCase()] || gender;

			const categories = [
				{
					Type: 'Tops',
					CategoryID: 15687,
					RequiredFields:
						'Brand; Size; Type; Size Type; Color; Department',
					Gender: 'Men',
				},
				{
					Type: 'Tops',
					CategoryID: 53159,
					RequiredFields:
						'Brand; Size; Type; Size Type; Color; Department',
					Gender: 'Women',
				},
				{
					Type: 'Bottoms',
					CategoryID: 57989,
					RequiredFields:
						'Brand; Size; Type; Style; Size Type; Color; Department; Inseam',
					Gender: 'Men',
				},
				{
					Type: 'Bottoms',
					CategoryID: 11554,
					RequiredFields:
						'Brand; Size; Type; Style; Size Type; Color; Department; Inseam',
					Gender: 'Women',
				},
				{
					Type: 'Outerwear',
					CategoryID: 57988,
					RequiredFields:
						'Brand; Type; Size; Style; Outer Shell Material; Size Type; Department; Color',
					Gender: 'Men',
				},
				{
					Type: 'Outerwear',
					CategoryID: 63862,
					RequiredFields:
						'Brand; Type; Size; Style; Outer Shell Material; Size Type; Department; Color',
					Gender: 'Women',
				},
				{
					Type: 'Footwear',
					CategoryID: 24087,
					RequiredFields:
						'Brand; US Shoe Size; Department; Style; Color; Upper Material; Type',
					Gender: 'Men',
				},
				{
					Type: 'Footwear',
					CategoryID: 53548,
					RequiredFields:
						'Brand; US Shoe Size; Department; Style; Color; Upper Material; Type',
					Gender: 'Women',
				},
				{
					Type: 'Swimwear',
					CategoryID: 15690,
					RequiredFields:
						'Brand; Size Type; Color; Department; Size; Type',
					Gender: 'Men',
				},
				{
					Type: 'Swimwear',
					CategoryID: 63867,
					RequiredFields:
						'Brand; Size Type; Color; Department; Size; Type',
					Gender: 'Women',
				},
				{
					Type: 'Sleepwear',
					CategoryID: 11510,
					RequiredFields:
						'Brand; Size Type; Color; Department; Size; Type',
					Gender: 'Men',
				},
				{
					Type: 'Accessories',
					CategoryID: 179249,
					RequiredFields: '',
					Gender: 'Women',
				},
				{
					Type: 'Rompers',
					CategoryID: 3009,
					RequiredFields:
						'Brand; Size; Type; Style; Size Type; Color; Department; Inseam',
					Gender: 'Women',
				},
				{
					Type: 'Toys',
					CategoryID: 177918,
					RequiredFields: 'Brand',
					Gender: '',
				},
				// Add more categories as needed
			];

			const matchedCategory = categories.find(
				(cat) =>
					cat.Type === category &&
					(cat.Gender === gender || cat.Gender === '')
			);

			if (matchedCategory) {
				console.log('Required Fields:', matchedCategory.RequiredFields);
				return matchedCategory.CategoryID;
			} else {
				const supportedCategories = categories
					.filter((cat) => cat.Gender === gender || cat.Gender === '')
					.map((cat) => `${cat.Type} (${cat.Gender || 'Any Gender'})`)
					.join(', ');
				alert(
					`Provided category: (${category}) and gender (${gender}) is not supported. Supported categories: ${supportedCategories}`
				);
				return;
			}
		}
    
      function getDepartment(input) {
			const genderMap = {
				men: 'Men',
				male: 'Men',
				women: 'Women',
				female: 'Women',
				ladies: 'Women',
				girl: 'Teens',
				girls: 'Teens',
				boys: 'Teens',
				boy: 'Teens',
				unisex: 'Unisex Adults',
				adults: 'Unisex Adults',
				all: 'Unisex Adults',
			};

			const departmentMap = {
				Men: 'Men',
				Women: 'Women',
				Teens: 'Teens',
				'Unisex Adults': 'Unisex Adults',
			};
			const normalizedInput = input.toLowerCase();
			const matchedGender = Object.keys(genderMap).find((key) =>
				normalizedInput.includes(key)
			);
			if (matchedGender) {
				return departmentMap[genderMap[matchedGender]];
			} else {
				return 'Unisex Adults';
			}
		}      
            
            csv.push([
                `Add`, //*Action(SiteID=US|Country=US|Currency=USD|Version=1193|CC=UTF-8)
                ``, // CustomLabel
                `${findMatchedCategory(taxonomyTypes[i], genders[i])}`, // *Category
                ``, // StoreCategory
                `${title}`, // *Title
                ``, // Subtitle
                ``, // Relationship
                ``, // RelationshipDetails
                `${3000}`, // *ConditionID
                `${brand[i]!=="" ? brand[i] : "Unbranded"}`, // *C:Brand
                `${size[i]}`, // *C:Size
                `Button-Up`, // *C:Type
                `${style[i]}`, // *C:Style
                `Regular`, // *C:Size Type  ##Info: This value is a Placeholder. To be updated manually on E-bay. If not possible, we will have to create a new input field in the table view
                `${colors[i]}`, // *C:Color
                `${getDepartment(genders[i])}`, // *C:Department
                `${sleeveM[i]}`, // *C:Inseam
                ``, // C:Theme
                ``, // C:Fit
                `${material[i]}`, // C:Material
                `${waistM[i]}`, // C:Waist Size
                ``, // C:Features
                `${material[i]}`, // C:Fabric Type
                ``, // C:Product Line
                ``, // C:Leg Style
                ``, // C:Front Type
                ``, // C:Accents
                ``, // C:Occasion
                `${pattern[i]}`, // C:Pattern
                `${closure[i]}`, // C:Closure
                `yes`, // C:Vintage
                ``, // C:Country/Region of Manufacture
                ``, // C:Season
                ``, // C:Model
                ``, // C:Handmade
                `${rise[i]}`, // C:Rise
                ``, // C:Personalize
                ``, // C:Pocket Type
                ``, // C:Garment Care
                ``, // C:California Prop 65 Warning
                ``, // C:MPN
                ``, // C:Personalization Instructions
                ``, // C:Unit Quantity
                ``, // C:Unit Type
                `${material[i]}`, // *C:Outer Shell Material
                ``, // C:Lining Material
                ``, // C:Insulation Material
                ``, // C:Performance/Activity
                ``, // C:Jacket/Coat Length
                ``, // C:Warmth Weight
                ``, // C:Collar Style
                ``, // C:Chest Size
                ``, // C:Fabric Wash
                ``, // C:Character
                ``, // C:Character Family
                `${sleeveM[i]}`, // C:Sleeve Length
                ``, // C:Number of Pieces
                `${neckline[i]}`, // C:Neckline
                `${s3Images[i]}${supplementaryImages[i]?.length>0 ? `|${supplementaryImages[i].join('|')}`: ""}`, // PicURL, 
                ``, // GalleryType
                ``, // VideoID
                `${description}`, // *Description
                `FixedPrice`, // *Format
                `GTC`, // *Duration
                `${price[i]}`, // *StartPrice
                ``, // BuyItNowPrice
                ``, // BestOfferEnabled
                ``, // BestOfferAutoAcceptPrice
                ``, // MinimumBestOfferPrice
                `${1}`, // *Quantity
                ``, // ImmediatePayRequired
                `Canada`, // *Location
                ``, // ShippingType
                `CA_PostRegularParcel`, // ShippingService-1:Option #Info: Placeholder. To be updated manually on ebay
                `${12.00}`, // ShippingService-1:Cost #Info: Placeholder. To be updated manually on ebay
                ``, // ShippingService-2:Option
                ``, // ShippingService-2:Cost
                `${2}`, // *DispatchTimeMax #Info: Placeholder. To be updated manually on ebay
                ``, // PromotionalShippingDiscount
                ``, // ShippingDiscountProfileID
                `ReturnsNotAccepted`, // *ReturnsAcceptedOption
                ``, // ReturnsWithinOption
                ``, // RefundOption
                ``, // ShippingCostPaidByOption
                ``, // AdditionalDetails
                ``, // EconomicOperator CompanyName
                ``, // EconomicOperator AddressLine1
                ``, // EconomicOperator AddressLine2
                ``, // EconomicOperator City
                ``, // EconomicOperator Country
                ``, // EconomicOperator PostalCode
                ``, // EconomicOperator StateOrProvince
                ``, // EconomicOperator Phone
                ``, // EconomicOperator Email,
                `FixedPriceItem` // Listing type
                
            ]);
						let n = csv.length;
						
						const mp = {
							"Item Name": itemName[i],
              "Brand": brand[i],
              "Era": era[i],
              "Size": size[i],
							"Color": colors[i],
							"Condition": `${condition[i]}${conditionDescription[i] && conditionDescription[i]!==''? `<span>. ${conditionDescription[i].charAt(0).toUpperCase() + conditionDescription[i].slice(1)}</span>`:''}`,
							"Type": customProductType,
							"Description": description,
							"Description SEO": result,
							"Gender": genders[i],
							"Measurements": `
                ${waistM[i]!=="" || hipM[i]!=="" || bottomInSeam[i]!=="" || riseMeasure[i]!=="" || thighMeasure[i]!=="" || legOpening[i]!=="" || pitToPitM[i]!=="" || lengthM[i]!=="" || shoulderM[i]!=="" || sleeveM[i]!=="" || heelHeightMeasure[i] !== "" || soleMeasure[i] !== "" || inSoleMeasure[i] !== "" || shoeWidth[i] !== "" ?`<p><b>Measurements:</b></p>`:''}
                ${waistM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Waist: ${waistM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${hipM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Hip: ${hipM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${bottomInSeam[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Bottom Inseam: ${bottomInSeam[i].replace(/"/g, '""')}</p>
                `: ""}
                ${riseMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Rise: ${riseMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${thighMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Thigh: ${thighMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${legOpening[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Leg Opening: ${legOpening[i].replace(/"/g, '""')}</p>
                `: ""}
                ${pitToPitM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Pit-to-Pit: ${pitToPitM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoulderM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Shoulder: ${shoulderM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${sleeveM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sleeve In-seam: ${sleeveM[i].replace(/"/g, '""')}</p>
                `: ""} 
                ${lengthM[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Full Length: ${lengthM[i].replace(/"/g, '""')}</p>
                `: ""}
                ${heelHeightMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Heel Height: ${heelHeightMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${soleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Sole: ${soleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
               ${inSoleMeasure[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Insole: ${inSoleMeasure[i].replace(/"/g, '""')}</p>
                `: ""}
                ${shoeWidth[i]!=="" ? `
                <p>&nbsp;&nbsp;&nbsp;&nbsp;• Width: ${shoeWidth[i].replace(/"/g, '""')}</p>
                `: ""}
                `

            }

            function cleanTemplate(str) {
				str = str.trim(); // Trim leading and trailing whitespace
				if (str.charAt(str.length - 1) === '|') {
					return str.slice(0, -1); // Remove the last character
				} else if (
					str.charAt(str.length - 1) === ')' &&
					str.charAt(str.length - 3) === '(' &&
         ( str.charAt(str.length - 2) === ' ' || str.charAt(str.length - 2) === '')
				) {
					return str.slice(0, -3);
				} else {
					return str; 
				}
			}
          

            if (titleBlocks && titleBlocks.length > 0) {
              for (let j = 0; j < titleBlocks.length; j++) {
                const value = titleBlocks[j].value
                if (titleBlocks[j].type === "variable") {
                  if (!Object.hasOwn(mp, value)) continue;
                  if (!mp[value]) continue;
                  newTitle += mp[value]
                }
                else {
                  newTitle += value
                }
                if (j < titleBlocks.length - 1) newTitle += ' ';
              }
               newTitle = cleanTemplate(newTitle)
              csv[n - 1][4] =  newTitle
              // csv[n - 1][29] = newTitle
            }
            

            
            if (descriptionBlocks && descriptionBlocks.length > 0) {
              for (let j = 0; j < descriptionBlocks.length; j++) {
                let value = descriptionBlocks[j].value
                if (descriptionBlocks[j].type === "variable") {
                  // if (!Object.hasOwn(mp, value)) continue;
                  // console.log(value)
                  if (!mp[value]) continue;
                  if (value === "Measurements") {
                    userDesc += mp[value];
                    continue;
                  } else if (value.toLowerCase() === "size") {
                    userDesc += `<p class='p1'><span class='s1'>${value}: ${size[i]}${sizeNotes[i] && sizeNotes[i] !== '' ? `<span>. ${sizeNotes[i].charAt(0).toUpperCase().replace(/"/g, '""') + sizeNotes[i].slice(1).replace(/"/g, '""')}</span>`:''}</span></p>`
                    continue;
                  } else if (value.toLowerCase() === "description seo") {
                    userDesc += `${mp[value]}`
                    continue;
                  }
                  let showHeader = (value !== "Description" && value !== "Description SEO" && value !== "Measurements" && value.toLowerCase()!=="size")
                  userDesc += `<p class='p1'><span class='s1'>${showHeader ? (value + ": ") : ''}${mp[value]}</span></p>`	
                  // console.log(`${value}: ${userDesc}`)
                }
                else {
                  userDesc += `<p class='p1'><span class='s1'>${value}</span></p>`	
                }
              }
              csv[n-1][61] = userDesc
            }
          
            if (template === "new-clothes" && Array.isArray(colors[i]) && colors[i].length > 1 ) {
              colors[i].shift()              
              // console.log(colors[i])
                colors[i].forEach((colorVariant, index) => {
                  if (colorVariant!=="") {
                    csv.push(getVariantRow(option1Title, option2Title, option3Title, userID, sku[i], itemName[i], handle, colorVariant, '', index, costPerItem[i], weight, taxable, price[i]));                                                          
                  }
                })
            }
          }    

          let csvData = []
          csvData = await createSEOcsv(csv)
          returnVal = csvData;

        if(isImport){
          returnVal.push(size)
        }
          
          
    
        
        return returnVal
      } catch(err){
        console.log(err)
      }
  }; 
}
  const createSEOcsv = async (csvData) => {
    let returnData = csvData
    try {      
      csvData.unshift([
                "*Action(SiteID=CA|Country=CA|Currency=CAD|Version=1193|CC=UTF-8)",
                "CustomLabel",
                "*Category",
                "StoreCategory",
                "*Title",
                "Subtitle",
                "Relationship",
                "RelationshipDetails",
                "*ConditionID",
                "*C:Brand",
                "*C:Size",
                "*C:Type",
                "*C:Style",
                "*C:Size Type",
                "*C:Color",
                "*C:Department",
                "*C:Inseam",
                "C:Theme",
                "C:Fit",
                "C:Material",
                "C:Waist Size",
                "C:Features",
                "C:Fabric Type",
                "C:Product Line",
                "C:Leg Style",
                "C:Front Type",
                "C:Accents",
                "C:Occasion",
                "C:Pattern",
                "C:Closure",
                "C:Vintage",
                "C:Country/Region of Manufacture",
                "C:Season",
                "C:Model",
                "C:Handmade",
                "C:Rise",
                "C:Personalize",
                "C:Pocket Type",
                "C:Garment Care",
                "C:California Prop 65 Warning",
                "C:MPN",
                "C:Personalization Instructions",
                "C:Unit Quantity",
                "C:Unit Type",
                "*C:Outer Shell Material",
                "C:Lining Material",
                "C:Insulation Material",
                "C:Performance/Activity",
                "C:Jacket/Coat Length",
                "C:Warmth Weight",
                "C:Collar Style",
                "C:Chest Size",
                "C:Fabric Wash",
                "C:Character",
                "C:Character Family",
                "C:Sleeve Length",
                "C:Number of Pieces",
                "C:Neckline",
                "PicURL",
                "GalleryType",
                "VideoID",
                "*Description",
                "*Format",
                "*Duration",
                "*StartPrice",
                "BuyItNowPrice",
                "BestOfferEnabled",
                "BestOfferAutoAcceptPrice",
                "MinimumBestOfferPrice",
                "*Quantity",
                "ImmediatePayRequired",
                "*Location",
                "ShippingType",
                "ShippingService-1:Option",
                "ShippingService-1:Cost",
                "ShippingService-2:Option",
                "ShippingService-2:Cost",
                "*DispatchTimeMax",
                "PromotionalShippingDiscount",
                "ShippingDiscountProfileID",
                "*ReturnsAcceptedOption",
                "ReturnsWithinOption",
                "RefundOption",
                "ShippingCostPaidByOption",
                "AdditionalDetails",
                "EconomicOperator CompanyName",
                "EconomicOperator AddressLine1",
                "EconomicOperator AddressLine2",
                "EconomicOperator City",
                "EconomicOperator Country",
                "EconomicOperator PostalCode",
                "EconomicOperator StateOrProvince",
                "EconomicOperator Phone",
                "EconomicOperator Email",
                "ListingType" 
            ]);
            
      return csvData
    } catch (e) {
      console.log("Please try using a different image");
      console.error(e);
      return []
    }
  }

  export default createEbayCSV;
