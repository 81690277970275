import axios from 'axios';
let projectID;
const snapwrite = 'https://apie.snapwrite.ca/storeData';
const localhost = 'http://localhost:3500/storeData';
const activeBaseURL = snapwrite

const storeData = async (
	concerns,
	template,
	userID,
	projectName,
	s3Images,
	backImage,
	supplementaryImages,
	types,
	itemGenericType,
	typesTaxoUpdate,
	itemName,
	brand,
	sku,
	vendor,
	size,
	sizeNotes,
	costPerItem,
	price,
	retailPrice,
	condition,
	conditionDescription,
	description,
	imageFileName,
	colors,
	genders,
	variantGrams,
	tags,
	material,
	sleeves,
	style,
	length,
	age,
	closure,
	fastening,
	fastening_type,
	neckline,
	cut,
	hood,
	typeObj,
	belt,
	design,
	lining,
	layers,
	pattern,
	skirt,
	rise,
	heelHeight,
	heelShape,
	height,
	toe,
	era,
	madeIn,
	lengthMeasure,
	sleeveInSeam,
	pitToPitMeasure,
	shoulderMeasure,
	waistMeasure,
	hipMeasure,
	bottomInSeam,
	riseMeasure,
	thighMeasure,
	legOpening,
	heelHeightMeasure,
	soleMeasure,
	inSoleMeasure,
	shoeWidth,
	setObjectID,
	isFormGenerated, // isFormGenerated: means the project is coming from the forms
) => {
	const body = {
		userID: userID,
		projectName: projectName,
		images: s3Images,
		backImage: backImage,
		supplementaryImages: supplementaryImages,
		types: types,
		itemGenericType: itemGenericType,
		taxonomyUpdatedType: typesTaxoUpdate,
		itemName: itemName,
		brand: brand,
		sku: sku,
		vendor: vendor,
		size: size,
		sizeNotes: sizeNotes,
		cost: costPerItem,
		price: price,
		retailPrice: retailPrice,
		condition: condition,
		conditionDescription: conditionDescription,
		description: description,
		fileNames: imageFileName,
		colors: colors,
		gender: genders,
		variantGrams: variantGrams,
		tags: tags,
		material: material,
		sleeves: sleeves,
		style: style,
		length: length,
		age: age,
		closure: closure,
		fastening: fastening,
		fastening_type: fastening_type,
		neckline: neckline,
		cut: cut,
		hood: hood,
		typeObj: typeObj,
		belt: belt,
		design: design,
		lining: lining,
		layers: layers,
		pattern: pattern,
		skirt: skirt,
		rise: rise,
		heelHeight: heelHeight,
		heelShape: heelShape,
		height: height,
		toe: toe,
		era: era,
		madeIn: madeIn,
		//Measurements
		pitToPitMeasure: pitToPitMeasure,
		shoulderMeasure: shoulderMeasure,
		sleeveInSeam: sleeveInSeam,
		lengthMeasure: lengthMeasure,
		waistMeasure: waistMeasure,
		hipMeasure: hipMeasure,
		bottomInSeam: bottomInSeam,
		riseMeasure: riseMeasure,
		thighMeasure: thighMeasure,
		legOpening: legOpening,
		//footwear Measure
		heelHeightMeasure: heelHeightMeasure,
		soleMeasure: soleMeasure,
		inSoleMeasure: inSoleMeasure,
		shoeWidth: shoeWidth,
		// Form Generated
		isFormGenerated: isFormGenerated, // isFormGenerated: means the project is coming from the forms
		concerns: concerns,
		dateCreated: new Date(),
	};

	let link = `${activeBaseURL}/bulk-labeling`;
	if (template === 'vintage') {
		link = `${activeBaseURL}/vintage-apparel`;
	} else if (template === 'new-clothes') {
		link = `${activeBaseURL}/new-apparel`;
	} else if (template === 'pre-loved-regular') {
		link = `${activeBaseURL}/regular-preLoved-apparel`;
	}

	axios
		.post(link, body)
		.then((response) => {
			projectID = response.data.data._id;
			setObjectID(projectID);
		})
		.catch((err) => {
			console.log(`Error ${err}`);
		});

	const userPreferenceData = await axios
		.get(`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`)
		.then((res) => res.data.data)
		.catch((err) => console.log(err));
	const { organizationName, organizationID } = userPreferenceData;

	const singleProductLabelEndpoint =
		`https://apie.snapwrite.ca/storeData/single-product`;
	const bodyWithProjectID = {
		...body,
		projectID: projectID,
		storeName: [organizationName],
		organizationID: [organizationID],
	};

	const response = axios
		.post(singleProductLabelEndpoint, bodyWithProjectID)
		.then((res) => res)
		.catch((err) => console.log(err));

	// fetch(link, {
	//   method: "POST",
	//   headers: {
	//     "Content-Type": "application/json",
	//   },
	//   JSON.stringify(body)
	// })
};

export default storeData;
