import FileUploadIcon from '@mui/icons-material/FileUpload';
import updateColumnValue from '../utils/updateAttribute';
import { useState } from 'react';
import { TableCell } from '@mui/material';

const ImageColumn = (props) => {
	const {singleProductModelFilter} = props
	
	const delBtnStyle = {
		display: 'none',
	};
	const delBtnHovrStyle = {
		display: 'block',
		color: 'black',
		position: 'absolute',
		backgroundColor: 'transparent',
		border: 'none',
		fontSize: '1.2rem',
		top: '10px',
		right: '-85px',
		zIndex: '20',
	};
	const imgHovrStyle = {
		width: '250px',
		position: 'relative',
		zIndex: '10',
	};

	const imgStyle = {
		width: '150px',
	};

	const [activeDelBtnStyle, setActiveDelBtnStyle] = useState(delBtnStyle);
	const [activeImageStyle, setActiveImageStyle] = useState(imgStyle);
	let addedImages 
	if(props.attr === "sideImages"){
		addedImages = props.images
	} else{
		addedImages = props.images.flat()
	}

	
	
	async function uploadImage(e) {
		const { url } = await fetch(
			'https://apie.snapwrite.ca/storeData/s3Url'
		).then((res) => res.json());

		await fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'image/jpeg',
			},
			body: e.target.files[0],
		})
			.then(() => {
				const updatedImages = [...addedImages]; // Create a new array for updating the state
				updatedImages[props.index] = url.split('?')[0]; // Update the specific index
				props.setS3Images(updatedImages);
				if (props.mm) {
					updateColumnValue(
						singleProductModelFilter,
						props.template,
						props.attr,
						updatedImages[0],
						props.id,
						props.index,
						props.attr
					);
				} else {
					updateColumnValue(
						singleProductModelFilter,
						'preLovedKids',
						'images',
						updatedImages,
						props.id
					);
				}
			})
			.catch((err) => console.error(err));
	}
	if (!addedImages) return <p>error</p>;


const currentImageUrl =
props.attr === 'sideImages'
  ? (addedImages[props.index]?.[0] || '')
  : addedImages[props.index];

	return (
		<>
			{currentImageUrl === '' ? (
				<TableCell className={props.name ? props.name : ''} style={{ padding:"10px"}}>
					<div style={{ width: 120 }}>
						<label
							htmlFor={
								(props.name ? props.name : '') +
								'heroImage' +
								props.index
							}
							className='pc'
						>
							<div className='upload-image-btn'>
								<FileUploadIcon color='black' />
								<span style={{ textAlign: 'center' }}>
									{props.name
										? props.name + ' Image'
										: 'Main Image'}
								</span>
							</div>
						</label>
						<input
							type='file'
							name={
								(props.name ? props.name : '') +
								'heroImage' +
								props.index
							}
							id={
								(props.name ? props.name : '') +
								'heroImage' +
								props.index
							}
							className='upload-image-btn drop-zone__input'
							accept='image/*'
							onChange={(e) => uploadImage(e)}
						/>
					</div>
				</TableCell>
			) : (
				<TableCell
					className={props.noScroll ? '' : 'imgcol'}
					style={{ width: '10%' }}
				>
					<div style={{ width: '150px', position: 'relative' }}>
						{/* Product Image */}
						{/* Comented the button to avoid interference with single product labeling model workflow as it relies on image url to be present in the database  */}
						{/* <button style={activeDelBtnStyle}
                onClick={(e) => {
                  const {images, index, template, primaryImage, attr, id} = props
                  const updatedImgArr = images
                  updatedImgArr[props.index] = ""
                  props.setS3Images(updatedImgArr)
                  setActiveImageStyle(imgStyle)
                  setActiveDelBtnStyle(delBtnStyle)
                  updateColumnValue(primaryImage, template, "images","",id, index, attr)
                }}
                onMouseOver={() => {
                  setActiveDelBtnStyle(delBtnHovrStyle)
                  setActiveImageStyle(imgHovrStyle)
                }}
                onMouseOut={() => {
                  setActiveDelBtnStyle(delBtnStyle)
                  setActiveImageStyle(imgStyle)
                }}
              >X</button> */}
						<img
							src={props.images[props.index]}
							draggable={false}
							alt={props.fileName}
							style={activeImageStyle}
							onMouseOver={() => {
								setActiveDelBtnStyle(delBtnHovrStyle);
								setActiveImageStyle(imgHovrStyle);
							}}
							onMouseOut={() => {
								setActiveDelBtnStyle(delBtnStyle);
								setActiveImageStyle(imgStyle);
							}}
						/>
					</div>
				</TableCell>
			)}
		</>
	);
};
export default ImageColumn;
