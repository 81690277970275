import { CarouselIndicators } from "reactstrap";
import axios from 'axios'
import { getAgeSynonyms } from "../../../../Keywords/age/age";
import { getClosureSynonyms } from "../../../../Keywords/closure/closureCoverage";
import { getCollarSynonyms } from "../../../../Keywords/collar/collarPresence";
import { getColorSynonyms } from "../../../../Keywords/color/color";
import { getDesignSynonyms } from "../../../../Keywords/design/design";
import { getDrawstringSynonyms } from "../../../../Keywords/drawstring/drawstring";
import { getDressingStyleSynonyms } from "../../../../Keywords/dressingStyle/dressingStyle";
import { getFitSynonyms } from "../../../../Keywords/fit/fit";
import { getGenderSynonyms } from "../../../../Keywords/gender/gender";
import { getHoodSynonyms } from "../../../../Keywords/hood/hood";
import { getLengthSynonyms } from "../../../../Keywords/length/length";
import { getLiningSynonyms } from "../../../../Keywords/lining/lining";
import { getMaterialSynonyms } from "../../../../Keywords/material/material";
import { getPatternSynonyms } from "../../../../Keywords/pattern/pattern";
import { getPocketSynonyms } from "../../../../Keywords/pocket/pocket";
import { getSeasonSynonyms } from "../../../../Keywords/season/season";
import { getSleeveSynonyms } from "../../../../Keywords/sleeve/sleeve";
import { getStyleSynonyms } from "../../../../Keywords/style/style";

const row1Headings = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "ATTRID0000000001 (en)",
  "ATTRID0000000002 (en)",
  "ATTRID0000000002 (fr)",
  "ATTRID0000000003 (en)",
  "ATTRID0000000003 (fr)",
  "ATTRID0000000004 (en)",
  "ATTRID0000000005 (en)",
  "ATTRID0000000006 (en)",
  "ATTRID0000000007 (en)",
  "ATTRID0000000008 (en)",
  "ATTRID0000000009 (en)",
  "ATTRID0000000010 (en)",
  "ATTRID0000000010 (fr)",
  "ATTRID0000000011 (en)",
  "ATTRID0000000011 (fr)",
  "ATTRID0000000012 (en)",
  "ATTRID0000000012 (fr)",
  "ATTRID0000000013 (en)",
  "ATTRID0000000014 (en)",
  "ATTRID0000000014 (fr)",
  "ATTRID0000000015 (en)",
  "ATTRID0000000015 (fr)",
  "ATTRID0000000016 (en)",
  "ATTRID0000000016 (fr)",
  "ATTRID0000000017 (en)",
  "ATTRID0000000017 (fr)",
  "ATTRID0000000018 (en)",
  "ATTRID0000000019 (en)",
  "ATTRID0000000019 (fr)",
  "ATTRID0000000020 (en)",
  "ATTRID0000000020 (fr)",
  "ATTRID0000000021 (en)",
  "ATTRID0000000021 (fr)",
  "ATTRID0000000022 (en)",
  "ATTRID0000000022 (fr)",
  "ATTRID0000000023 (en)",
  "ATTRID0000000023 (fr)",
  "ATTRID0000000024 (en)",
  "ATTRID0000000024 (fr)",
  "ATTRID0000000025 (en)",
  "ATTRID0000000026 (en)",
  "ATTRID0000000027 (en)",
  "ATTRID0000000028 (en)",
  "ATTRID0000000029 (en)",
  "ATTRID0000000030 (en)",
  "ATTRID0000000031 (en)",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

const row2Headings = [
  "Supplier Code",
  "Supplier Name",
  "Retail-1 Style",
  "Retail-1 Description",
  "Retail-1 Desc2",
  "Color Code",
  "Retail 1 Color Desc",
  "Virtual Style",
  "English Web Color",
  "French Web Color",
  "Primary Color Family",
  "Secondary Color Family",
  "Tertiary Color Family",
  "Allow Pre-order (Y/N)",
  "Pre-order Date (mm/dd/yyyy)",
  "Allow Backorder (Y/N)",
  "In-Store Only (Y/N)",
  "Forced (Y/N)",
  "Site Live Date (mm/dd/yyyy)",
  "Site End Date (mm/dd/yyyy)",
  "Inventory Threshold value",
  "International Shipping (Y/N)",
  "Mix-n-Match (Y/N)",
  "Brand (en)",
  "Web Style Description (en)",
  "Web Style Description (fr)",
  "Why we love this (en)",
  "Why we love this (fr)",
  "Fit (en)",
  "Fit 2 (en)",
  "Fit 3 (en)",
  "Style (en)",
  "Style 2 (en)",
  "Style 3 (en)",
  "Closure Type (en)",
  "Closure Type (fr)",
  "Fabric Composition (en)",
  "Fabric Composition (fr)",
  "Fur Details (en)",
  "Fur Details (fr)",
  "Gender (en)",
  "Hood Details (en)",
  "Hood Details (fr)",
  "Key Features (en)",
  "Key Features (fr)",
  "Knit or Woven (en)",
  "Knit or Woven (fr)",
  "Length (en)",
  "Length (fr)",
  "Made in (en)",
  "Rise (en)",
  "Rise (fr)",
  "Product Care (en)",
  "Product Care (fr)",
  "Miscellaneous 1 (en)",
  "Miscellaneous 1 (fr)",
  "Miscellaneous 2 (en)",
  "Miscellaneous 2 (fr)",
  "Miscellaneous 3 (en)",
  "Miscellaneous 3 (fr)",
  "Miscellaneous 4 (en)",
  "Miscellaneous 4 (fr)",
  "Season (en)",
  "Season 2 (en)",
  "Season 3 (en)",
  "Season 4 (en)",
  "Badge (en)",
  "Badge 2 (en)",
  "Badge 3 (en)",
  "Cross Sell 1",
  "Cross Sell 2",
  "Cross Sell 3",
  "Cross Sell 4",
  "Cross Sell 5",
  "Cross Sell 6",
  "Cross Sell 7",
  "Cross Sell 8",
  "Cross Sell 9",
  "Cross Sell 10",
  "Upsell 1",
  "Upsell 2",
  "Upsell 3",
  "Upsell 4",
  "Upsell 5",
  "Upsell 6",
  "Upsell 7",
  "Upsell 8",
  "Upsell 9",
  "Upsell 10",
  "Primary-Image",
  "Alt-Image-1",
  "Alt-Image-2",
  "Alt-Image-3",
  "Alt-Image-4",
  "Alt-Image-5",
  "Web Categories",
  "swatches images html hex code",
  "Meta Title",
  "Meta Description",
  "Meta Keywords",
];

const row3Headings = [
  "(cannot be blank)",
  "(optional)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(optional)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(optional)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(optional)",
  "(optional)",
  "(cannot be blank)",
  "(cannot be blank if Pre-order is set Y)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(optional)",
  "(cannot be blank) DEF.=3",
  "(cannot be blank)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(cannot be blank)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(cannot be blank)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(cannot be blank)",
  "(optional)",
  "(optional)",
  "(optional)",
  "(optional)",
];

const translateToFrench = async (valueToBeTranslated, listItem="return as list Item") => {  
  let response = !valueToBeTranslated || valueToBeTranslated === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: valueToBeTranslated})
  let frenchValue = ""
  if(listItem==="return as plain txt") {
    frenchValue = !valueToBeTranslated || valueToBeTranslated === "" ? "" : `${response.data.data.text.toLocaleLowerCase()}`    
  } else {
   frenchValue = !valueToBeTranslated || valueToBeTranslated === "" ? "" : `<li>${response.data.data.text}</li>`
  }
  return frenchValue
}

// const sizeTranslations = {
//     "SMALL": "PETITE",
//     "MEDIUM": "MOYENNE",
//     "LARGE": "GRANDE",
//     "1X": "1X"
// }
const sizeTranslations = {
  "S": "P",
  "M": "M",
  "L": "G",
  "1X": "1X",
  "4": "4",
  "6": "6",
  "8": "8",
  "SMALL": "P",
  "MEDIUM": "M",
  "LARGE": "G",
  "that is one size, fits Small (size 6) to Large (size 14)": "unique O/S"
}

const colorCodeMap = {
  "LEO": { "en": "LEOPARD", "fr": "LEOPARD" },
  "B/N": { "en": "WHITE/BLACK", "fr": "BLANC/NOIR" },
  "N/B": { "en": "BLACK/WHITE", "fr": "NOIR/BLANC" },
  "N C": { "en": "BLACK/CREAM", "fr": "NOIR/CREME" },
  "N/G": { "en": "BLACK/GREY", "fr": "NOIR/GRIS" },
  "N/I": { "en": "BLACK/IVORY", "fr": "NOIR/IVOIRE" },
  "BLM": { "en": "WHITE/NAVY", "fr": "BLANC/MARINE" },
  "B M": { "en": "BEIGE/NAVY", "fr": "BEIGE/MARINE" },
  "B-R": { "en": "BEIGE/PINK", "fr": "BEIGE/ROSE" },
  "BB": { "en": "BEIGE/BLUE", "fr": "BEIGE/BLEU" },
  "NOI": { "en": "BLACK", "fr": "NOIR" },
  "R-N": { "en": "RED/BLACK", "fr": "ROUGE/NOIR" },
  "BLE": { "en": "BLUE", "fr": "BLEU" },
  "DEN": { "en": "DENIM", "fr": "DENIM" },
  "ROY": { "en": "ROYAL", "fr": "ROYAL" },
  "OLI": { "en": "OLIVE", "fr": "OLIVE" },
  "VER": { "en": "GREEN", "fr": "VERT" },
  "MAR": { "en": "NAVY", "fr": "MARINE" },
  "KHA": { "en": "KHAKI", "fr": "KHAKI" },
  "TUR": { "en": "TURQUOISE", "fr": "TURQUOISE" },
  "CHA": { "en": "CHARCOAL", "fr": "CHARBON" },
  "BRU": { "en": "BROWN", "fr": "BRUN" },
  "CHO": { "en": "CHOCOLATE", "fr": "CHOCOLAT" },
  "MAU": { "en": "PURPLE", "fr": "MAUVE" },
  "TAU": { "en": "TAUPE", "fr": "TAUPE" },
  "AUB": { "en": "EGGPLANT", "fr": "AUBERGINE" },
  "BOR": { "en": "BORDEAU", "fr": "BORDEAU" },
  "BRO": { "en": "BRONZE", "fr": "BRONZE" },
  "BOU": { "en": "BURGUNDY", "fr": "BOURGOGNE" },
  "VIN": { "en": "WINE", "fr": "VIN" },
  "GRE": { "en": "GREY-BEIGE", "fr": "GREIGE" },
  "GRI": { "en": "GREY", "fr": "GRIS" },
  "OR": { "en": "GOLD", "fr": "OR" },
  "CUI": { "en": "COPPER", "fr": "CUIVRE" },
  "ARG": { "en": "SILVER", "fr": "ARGENT" },
  "BEI": { "en": "BEIGE", "fr": "BEIGE" },
  "SAB": { "en": "SAND", "fr": "SABLE" },
  "TAN": { "en": "TAN", "fr": "TAN" },
  "LIN": { "en": "LINEN", "fr": "LINEN" },
  "CAM": { "en": "CAMEL", "fr": "CAMEL" },
  "PEC": { "en": "PEACH", "fr": "PECHE" },
  "FUS": { "en": "FUSCIA", "fr": "FUSHIA" },
  "FRA": { "en": "RASPBERRY", "fr": "FRAMBOISE" },
  "JAU": { "en": "YELLOW", "fr": "JAUNE" },
  "COR": { "en": "CORAL", "fr": "CORAIL" },
  "MAU": { "en": "LILAC", "fr": "LILAS" },
  "MEN": { "en": "MINT", "fr": "MENTHE" },
  "IVO": { "en": "IVORY", "fr": "IVOIRE" },
  "ROU": { "en": "RED", "fr": "ROUGE" },
  "ROS": { "en": "PINK", "fr": "ROSE" },
  "ORA": { "en": "ORANGE", "fr": "ORANGE" },
  "TAN": { "en": "TANGERINE", "fr": "TANGERINE" },
  "CRE": { "en": "CREAM", "fr": "CREME" },
  "NAT": { "en": "NATURAL", "fr": "NATUREL" },
  // "CHA": { "en": "CHAMPAGNE", "fr": "CHAMPAGNE" },
  "CHM": { "en": "MUSHROOM", "fr": "CHAMPIGNON" },
  "BLA": { "en": "WHITE", "fr": "BLANC" },
  "BLC": { "en": "OFF-WHITE", "fr": "BLANC CASSE" },
  "B R": { "en": "WHITE PINK", "fr": "BLANC ROSE" },
  "BLM": { "en": "WHITE NAVY", "fr": "BLANC MARINE" },
  "B-B": { "en": "WHITE BLUE", "fr": "BLANC BLEU" },
  "R/": {"en": "Rust", "fr": "Rouille"},
  "t*b":{"en": "turquoise/beige", "fr": "turquoise/beige"},
  "AQ": {"en": "Auqa", "fr": "Aqua"},
  "N F": {"en": "NOIR/FUSHIA", "fr": "BLACK/FUSHIA"},
  "G/N": {"en": "GRIS/NOIR", "fr": "DARK GREY"},
  "PIE": {"en": "PIERRE", "fr": "STONE"},
  "COG": {"en": "COGNAC", "fr": "COGNAC"}
};

const careInstructions_fr = {
  "Dry clean only": "Nettoyage à sec uniquement",
  "Machine wash": "Lavage en machine",
  "Machine wash cold / Dry flat": "Lavage en machine à froid / Séchage à plat",
  "Machine wash cold / Hang to dry": "Lavage en machine à froid / Suspendre pour sécher",
  "Machine wash cold / Tumble dry low": "Lavage en machine à froid / Séchage à basse température",
  "Remove trim before wash / Machine wash cold / Hang to dry": "Retirer les garnitures avant le lavage / Lavage en machine à froid / Suspendre pour sécher",
  "Remove trim before wash / Machine wash cold / Tumble dry low": "Retirer les garnitures avant le lavage / Lavage en machine à froid / Séchage à basse température",
  "Turn inside out / Machine wash": "Retourner à l'envers / Lavage en machine",
  "Turn inside out / Machine wash / Hang to dry": "Retourner à l'envers / Lavage en machine / Suspendre pour sécher",
  "Turn inside out / Machine wash cold": "Retourner à l'envers / Lavage en machine à froid",
  "Turn inside out / Machine wash cold / Tumble dry low": "Retourner à l'envers / Lavage en machine à froid / Séchage à basse température"
}

const closure_fr = {
  "Zip front closure with placket": "Fermeture à glissière sur le devant avec patte de boutonnage",
  "Zip front closure": "Fermeture à glissière sur le devant",
  "Button front closure": "Fermeture boutonnée sur le devant",
  "Two-way zip front closure": "Fermeture éclair à double sens sur le devant",
  "double zipper closure with placket": "Double fermeture à glissière avec patte de boutonnage",
  "double zipper closure": "Double fermeture à glissière"
}

const collar_fr = {
  "High Collar": "Col haut",
  "Lapel Notch Collar": "Col cranté à revers", 
  "Large Collar": "Grand col", 
  "Shirt Collar": "Col de chemise"
}

const pocket_fr = {
  "Zip Pockets": "Poches zippées",
  "Welt Pockets": "Poches passepoilées",
  "Flap Pockets": "Poches à rabat"
}

const sleeve_cuffs_fr = {
  "Knit storm cuffs": "Poignets en tricot pour tempête",
  "Open sleeve cuffs": "Poignets de manche ouverts",
  "Adjustable length": "Longueur ajustable",
  "Elastic": "Élastique"
}

const hood_fr = {
  "detachable hood with detachable real fur": "Capuche amovible avec véritable fourrure amovible",
  "detachable hood with detachable faux fur": "Capuche amovible avec fausse fourrure amovible",
  "detachable hood": "Capuche amovible",
  "attached hood": "Capuche attachée"
}

const getBrandSpecificAttributes = (brand, itemClass, technicalDetailsEN, technicalDetailsFR) => {
  let tempRn = ""
  let tempRn_fr = ""
  let coatedRn = technicalDetailsEN
  let coatedRn_fr = technicalDetailsFR
  let logo = ""
  let logo_fr = ""
  if((itemClass<140 && itemClass>129) || (itemClass<540 && itemClass>529)) {
    if((brand==="SAKI" || brand === "POINT ZERO")) {
      tempRn = `<li>Insulated with Polyloft, an advanced ecological insulation that is breathable and hypoallergenic</li>`
      tempRn_fr = `<li>Isolé avec Polyloft, une isolation écologique avancée qui est respirante et hypoallergénique</li>`
      coatedRn = `<li>Coated outer shell for added wind resistance, water repellency and durability</li>`
      coatedRn_fr = `<li>Coquille extérieure enduite pour plus de résistance au vent, d'imperméabilité et de durabilité</li>`
      if (brand === "SAKI") {
        logo = `<li>Logo on sleeve</li>`
        logo_fr = `<li>Logo sur la manche</li>`
      }
    } else if (brand === "BERNARO") {
      tempRn = `<li>Insulated with Eco Plum, an insulation made from recycled materials that is warm, breathable, hypoallergenic and super soft</li>`
      tempRn_fr = `<li>isolé avec Eco Plum, un isolation fabrique à partir de materiaux recyclés qui est chaud, respirant, hypoallergenique et super dOUx</li>`
      coatedRn = `<li>Coated outer shell for added wind resistance, water repellency and durability</li>`
      coatedRn_fr = `<li>Coquille extérieure enduite pour plus de résistance au vent, d'imperméabilité et de durabilité</li>`
    } else if (brand==="LOOP") {
      tempRn = `<li>Insulated with high-performance fibers made from recycled plastic bottles, which create an advanced ecological insulation</li>`
      tempRn_fr = `<li>Isolation avec des fibres haute performance fabriquées à partir de bouteilles en plastique recyclées, ce qui crée une isolation écologique avancée</li>`
    } else if(brand === "STYLA") {
      tempRn = `<li>Insulated with Polyloft, an advanced ecological insulation that is breathable and hypoallergenic</li>`
      tempRn_fr = `<li>Isolé avec Polyloft, une isolation écologique avancée qui est respirante et hypoallergénique</li>`
      coatedRn = `<li>Outer shell has a coating called "Storm Control" which adds extra protection against wind, rain and snow</li>`
      coatedRn_fr = `<li>L'enveloppe extérieure est recouverte d'un revêtement appelé "Storm Control" qui ajoute une protection supplémentaire contre le vent, la pluie et la neige</li>`
    } else if (brand === "FREE COUNTRY") {
      tempRn = `<li>Insulated with fibers made from recycled plastic, which make up an advanced ecological insulation that is breathable and hypoallergenic</li>`
      tempRn_fr = `<li>Isolation avec des fibres fabriquées à partir de plastique recyclé, qui constituent une isolation écologique avancée, respirante et hypoallergénique</li>`
      coatedRn = `<li>Coated outer shell for added wind resistance, water repellency and durability</li>`
      coatedRn_fr = `<li>Coquille extérieure enduite pour plus de résistance au vent, d'imperméabilité et de durabilité</li>`
    } else if (brand === "FROCCELLA"){
        tempRn = `<li>Insulated with Polyloft, an advanced ecological insulation that is breathable and hypoallergenic</li>`
        tempRn_fr = `<li>Isolé avec Polyloft, une isolation écologique avancée qui est respirante et hypoallergénique</li>`
        coatedRn = `<li>Coated outer shell for added wind resistance, water and stain repellency</li>`
        coatedRn_fr = `<li>Coquille extérieure enduite pour une meilleure résistance au vent, à l'eau et aux taches</li>`
    } else if (brand === "BIG CHILL" || brand === "SNOBOLL" || brand === "SOKOS") {
        tempRn = `<li>Insulated with Polyloft, an advanced ecological insulation that is breathable and hypoallergenic</li>`
        tempRn_fr = `<li>Isolé avec Polyloft, une isolation écologique avancée qui est respirante et hypoallergénique</li>`
    } 
  }
  return {
    "tempNotes_en": tempRn,
    "tempNotes_fr": tempRn_fr,
    "coated_en": coatedRn,
    "coated_fr": coatedRn_fr
  }
} 

export const createManteauxCSV = async (
  collection,
  // images
  tagImages,
  frontImages,
  sideImages,
  backImages,
  //supplier
  supplierCode,
  supplierName,
  brand,
  //style
  styleCode,
  temp,
  //class
  webCode,
  retail1Description,
  colorEN,
  colorFR,
  descriptionEN,
  descriptionFR,
  modelHeight,
  modelSize,
  titleEN,
  titleFR,
  length,
  inches,
  careInstructions,
  closure,
  sleeveCuffs,
  fabricComposition,
  hood,
  notes,
  itemClass,
  technicalDetails,
  lining,
  collar,
  pocket,
  interiorPockets,
  neckline,
  pattern, 
  skirt,
  fit,
  sleeveLength,
  liningMaterial,
  exteriorMaterial,
  season
) => {
    const csv = [row1Headings, row2Headings, row3Headings];
    const bulletPoints = []
    const frenchBulletPoints = []
    //console.log(tagImages)

    

      
  for (let i = 0; i < tagImages.length; i++) {

    const gender = itemClass[i] > 499 && itemClass[i] < 600 ? "man": "woman"

    // console.log(notes[i])
    let response = !notes[i] || notes[i].trim() === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: notes[i]})
    const notesFR = !notes[i] || notes[i].trim() === "" ? "" : response.data.data.text


    // response = notes[i] === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: pocket[i]})

    // const pocketFR = notes[i] === "" ? "" : response.data.data.text


    // response = notes[i] === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: collar[i]})

    // const collarFR = notes[i] === "" ? "" : response.data.data.text

    // // response = notes[i] === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: drawstring[i]})

    // // const drawstringFR = notes[i] === "" ? "" : response.data.data.text

    // response = notes[i] === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: fabricComposition[i]})

    // const fabricFR = notes[i] === "" ? "" : response.data.data.text

    // console.log(notesFR)

    const synonyms = [...getAgeSynonyms(titleEN && titleEN[i] ? titleEN[i] : ""), ...getClosureSynonyms(closure && closure[i] ? closure[i] : ""), ...getCollarSynonyms(collar && collar[i] ? collar[i] :  ""), ...getDesignSynonyms(titleEN && titleEN[i] ? titleEN[i] :  ""), ...getDrawstringSynonyms(titleEN && titleEN[i] ? titleEN[i] :  ""), ...getDressingStyleSynonyms(titleEN && titleEN[i] ? titleEN[i] :  ""), ...getFitSynonyms(fit && fit[i] ? fit[i] :  ""), ...getGenderSynonyms(titleEN && titleEN[i] ? titleEN[i] :  ""), ...getHoodSynonyms(hood && hood[i] ? hood[i] :  ""), ...getLengthSynonyms(length && length[i] ? length[i] :  ""), ...getLiningSynonyms(lining && lining[i] ? lining[i] : ""), ...getMaterialSynonyms(exteriorMaterial && exteriorMaterial[i] ? exteriorMaterial[i] : ""), ...getPatternSynonyms(pattern && pattern[i] ? pattern[i] :  ""), ...getPocketSynonyms(pocket && pocket[i] ? pocket[i] :  ""), ...getSeasonSynonyms(titleEN && titleEN[i] ? titleEN[i] :  ""), ...getSleeveSynonyms(sleeveCuffs && sleeveCuffs[i] ? sleeveCuffs[i] :  ""), ...getStyleSynonyms(titleEN && titleEN[i] ? titleEN[i] :  "")]
    
    console.log(synonyms)
    const descEN_1 = `<p>${descriptionEN[i]}</p>`

    const closure_3 = closure[i] && closure[i] !== "" ?`<li>${closure[i]}</li>` : ""
    const collar_7 = collar[i] && collar[i] !== "" ?`<li>${collar[i]}</li>` : ""
    const pocket_10 = pocket[i] && pocket[i] !== "" ? `<li>${pocket[i]}</li>` : ""
    const interiorPockets_11 = interiorPockets[i] && interiorPockets[i] !== "" ?`<li>${interiorPockets[i]}</li>` : ""
    const hood_4 = hood[i] && hood[i] !== "" && hood[i] !== "no hood" ? `<li>${hood[i]}</li>` : ""
    const sleeves_5 = sleeveCuffs[i] && sleeveCuffs[i] !== "" ? `<li>${sleeveCuffs[i]}</li>` : ""
    const tempEN_6 = temp[i] && temp[i] !== "" ? `<li>Engineered to provide warmth up to ${temp[i]}°C with light to moderate activity</li>` :  ""      
    let heightOftheModel = modelHeight[i]
    if(modelHeight && modelHeight[i] && modelHeight[i][1]==='"') {
      heightOftheModel = modelHeight[i].substring(0, 1) + modelHeight[i].substring(1).replace('"', "'");
    }
    console.log(heightOftheModel)
    const modelEN_8 = modelSize[i] && modelSize[i] !== "" && modelHeight[i] && modelHeight[i] !== "" ? `<li>Model shown is ${heightOftheModel.replace(/"/g, '""')} and is wearing a size ${modelSize[i]}</li>` : ""
    const measureEN = inches[i] && inches[i] !== "" && parseFloat(inches[i]) !==  0  && !isNaN(parseFloat(inches[i])) ? `<li>Length: ${inches[i]}"" / ${length[i]} cm</li>` : ""
    const fabric_15 = fabricComposition[i] && fabricComposition[i] !== "" ? `<li>${fabricComposition[i]}</li>` : ""
    const exteriorMaterial_15 = exteriorMaterial && exteriorMaterial[i] && exteriorMaterial[i] !== "" ? `<li>${exteriorMaterial[i]}</li>` : ""
    const liningMaterial_15 = liningMaterial && liningMaterial[i] && liningMaterial[i] !== "" ? `<li>${liningMaterial[i]}</li>` : ""
    const technicalDetailsEN = technicalDetails && technicalDetails[i] && technicalDetails[i] !== "" ? `<li>Coated outer shell for added ${technicalDetails[i]} and durability</li>` : ""
    const liningEN = lining && lining[i] && lining[i] !== "" ? `<li>${lining[i]}</li>` : ""

    const careInstructions_16 = careInstructions[i] && careInstructions[i] !== "" ? `<li>${careInstructions[i]}</li>` : ""

    const productWashLink_en = careInstructions_16.toLocaleLowerCase().includes("wash")?`Maintain that Fresh Coat look with the gentle <a href=""https://www.coatsco.com/en/softwash.html"">Soft Wash detergent</a>` : ""
    const prouctCareLink_en = (itemClass[i]<150 && itemClass[i]>139) ? "" : `<a href=""https://www.coatsco.com/en/womens-accessories/water-stain-guard-fabric-protector-product-care-for-coats.html"">with the MM Coat Protector</a> for lasting water resistance and stain repellency`

    const productWash_sportswear_en = careInstructions_16.toLocaleLowerCase().includes("wash")?`<li>Wash it with our <a href=""https://www.coatsco.com/en/softwash.html"">Soft Wash Detergent</a> to keep it fresh and new</li>` : ""
    const productWash_sportswear_fr = careInstructions_16.toLocaleLowerCase().includes("wash")?`<li>Lavez-le avec notre détergent <a href=""https://www.manteaux.com/fr/douce-lessive.html"">Douce Lessive</a> pour qu'il reste frais et neuf</li>` : ""

    const productWashLink_fr = careInstructions_16.toLocaleLowerCase().includes("wash")?`Conservez l'aspect de la couche fraîche avec le doux <a href=""https://www.manteaux.com/fr/douce-lessive.html"">Douce Lessive</a>` : ""
    const prouctCareLink_fr = (itemClass[i]<150 && itemClass[i]>139) ? "" : `le avec <a href=""https://www.manteaux.com/fr/accessoires-pour-femmes/protecteur-de-tissu-hydrofuge-et-anti-taches-pour-les-manteaux.html"">MM Manteau Protecteur</a> pour une résistance durable à l'eau et aux taches`

    const washAndSpray_en = productWashLink_en==="" && prouctCareLink_en==="" ? "" :(`<li>${productWashLink_en + (productWashLink_en!=="" && prouctCareLink_en!=="" ? " and " : "") + (productWashLink_en==="" ? `Spray ${prouctCareLink_en}` : prouctCareLink_en!=="" ? `spray ${prouctCareLink_en}` : "")}</li>`) 
    const washAndSpray_fr = productWashLink_fr==="" && prouctCareLink_fr==="" ? "" :(`<li>${productWashLink_fr + (productWashLink_fr!=="" && prouctCareLink_fr!=="" ? " et " : "") + (productWashLink_fr===""  ? `Vaporiser ${prouctCareLink_fr}` : prouctCareLink_fr!=="" ? `vaporiser ${prouctCareLink_fr}` : "")}</li>`) 
        
    // sportswear
    const fit_1 = fit && fit[i] ? `<li>${fit[i]}</li>` : ""
    const sleeve_length = sleeveLength && sleeveLength[i] ? `<li>${sleeveLength[i]}</li>` : ""
    const skirt_7 = skirt && skirt[i] ? `<li>${skirt[i]} skirt</li>` : ""
    const neckline_2 = neckline && neckline[i] ? `<li>${neckline[i]} neckline</li>` : ""
    const pattern_6 = pattern && pattern[i] ? `<li>${pattern[i]} pattern</li>` : ""

    
    let careInstructions_16_fr = careInstructions_16
    
    if (Object.keys(careInstructions_fr).includes(careInstructions[i])) {
      careInstructions_16_fr = `<li>${careInstructions_fr[careInstructions[i]]}</li>`
    }
    else {
       careInstructions_16_fr = await translateToFrench(careInstructions[i])
    }

    let closure_3_fr = closure_3

    if (Object.keys(closure_fr).includes(closure[i])) {
      closure_3_fr = `<li>${closure_fr[closure[i]]}</li>`
    }
    else {
      closure_3_fr = await translateToFrench(closure[i])
    }

    let collar_7_fr = collar_7

    if (Object.keys(collar_fr).includes(collar[i])) {
      collar_7_fr = `<li>${collar_fr[collar[i]]}</li>`
    }
    else {
      collar_7_fr = await translateToFrench(collar[i])
    }

    let pocket_10_fr = pocket_10

    if (Object.keys(pocket_fr).includes(pocket[i])) {
      pocket_10_fr = `<li>${pocket_fr[pocket[i]]}</li>`
    }
    else {
      pocket_10_fr = await translateToFrench(pocket[i])
    }

    let sleeve_length_fr = sleeve_length ? await translateToFrench(sleeve_length, "return as plain txt") : ""

    let sleeve_cuffs_5_fr = sleeves_5

    if (Object.keys(sleeve_cuffs_fr).includes(sleeveCuffs[i])) {
      sleeve_cuffs_5_fr = `<li>${sleeve_cuffs_fr[sleeveCuffs[i]]}</li>`
    }
    else {
      sleeve_cuffs_5_fr = await translateToFrench(sleeveCuffs[i])
    }

    let hood_4_fr = hood_4

    if (Object.keys(hood_4_fr).includes(hood[i])) {
      hood_4_fr = `<li>${hood_fr[hood[i]]}</li>`
    } else if (hood[i]==="no hood" || hood[i]===""){
      hood_4_fr=""
    } else {
      hood_4_fr = await translateToFrench(hood[i])
    }

    const fabric_composition_5_fr = fabricComposition ? await translateToFrench(fabricComposition[i]) : ""
    const liningMaterial_15_fr =liningMaterial ? await translateToFrench(liningMaterial[i]) : ""
    const exteriorMaterial_15_fr = exteriorMaterial ? await translateToFrench(exteriorMaterial[i]) : ""

    const technicalDetailsFR = `<li>Coquille extérieure enduite pour plus de ${await translateToFrench(technicalDetails[i], "return as plain txt")} et de durabilité</li>`

    const liningFR = await translateToFrench(lining[i])

    const interiorPocketsFR = await translateToFrench(interiorPockets[i])

    response = !neckline || !neckline[i] || neckline[i].trim() === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: neckline[i]})
    const necklineFR = !neckline || !neckline[i] || neckline[i].trim() === "" ? "" : `<li>Col ${response.data.data.text.toLocaleLowerCase()}</li>`

    response = !pattern || !pattern[i] || pattern[i].trim() === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: pattern[i]})
    const patternFR = !pattern || !pattern[i] || pattern[i].trim() === "" ? "" : `<li>Modèle ${response.data.data.text.toLocaleLowerCase()}</li>`
    
    response = !skirt || !skirt[i] || skirt[i].trim() === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: skirt[i]})
    const skirtFR = !skirt || !skirt[i] || skirt[i].trim() === "" ? "" : `<li>Jupe ${response.data.data.text.toLocaleLowerCase()}</li>`

    response = !fit || !fit[i] || fit[i].trim() === "" ? "" : await axios.post("https://apie.snapwrite.ca/storeData/translate", { message: fit[i]})
    const fitFR = !fit || !fit[i] || fit[i].trim() === "" ? "" : `<li>${response.data.data.text}</li>`

    let notes_17 = ''
    if(notes[i] && notes[i] !== "" && notes[i].trim()!=="") {
      let notes_value = notes[i].trim()
      if(notes_value.includes("\n")) {
        notes_value  = notes_value.split("\n")
        for (let w=0; w<notes_value.length; w++) {   
          if(notes_value[w].trim() !== "") {
            notes_17 = notes_17 + `<li>${notes_value[w].trim()}</li>`
          }
        }               
      }
      else {
        notes_17 = `<li>${notes_value}</li>`
      }      
    }

    let notes_17_fr = ''
    if(notesFR && notesFR !== "" && notesFR.trim()!=="") {
      let notes_value = notesFR.trim()
      if(notes_value.includes("\n")) {
        notes_value  = notes_value.split("\n")
        for (let w=0; w<notes_value.length; w++) {
          if(notes_value[w].trim() !== "") {
            notes_17_fr = notes_17_fr + `<li>${notes_value[w].trim()}</li>`
          }
        }
      }
      else {
        notes_17_fr = `<li>${notes_value}</li>`
      }
    }

    let tempFR_6 = ''
    if(temp[i] && temp[i]!=="") {
      tempFR_6 = `<li>Conçu pour fournir de la chaleur jusqu'à ${temp[i]}°C avec une activité légère à modérée.</li>`
    }
    const modelFR_8 = `<li>La modèle mesure ${heightOftheModel.replace(/"/g, '""')} et porte une taille ${sizeTranslations[modelSize[i]]}</li>`
    const measureFR =  parseFloat(inches[i]) ===  0 || isNaN(inches[i]) || isNaN(parseFloat(inches[i])) ? "":`<li>Longueur: ${inches[i]}"" / ${length[i]} cm</li>`

    const brandSpecificAttributes = getBrandSpecificAttributes(brand[i], itemClass[i], technicalDetailsEN, technicalDetailsFR)
    let tempRn = brandSpecificAttributes["tempNotes_en"]
    let coatedRn = brandSpecificAttributes["coated_en"]
    let logo = ''
    let tempRn_fr = brandSpecificAttributes["tempNotes_fr"]
    let coatedRn_fr = brandSpecificAttributes["coated_fr"]
    let logo_fr = ''

    // getBrandSpecificAttributes(brand[i], itemClass[i], temp[i], tempRn, coatedRn, logo, tempRn_fr, coatedRn_fr, logo_fr)
    let madeIn_en = ""
    let madeIn_fr = ""
    let exclusivity_en = ""
    let exclusivity_fr = ""
    let sizeFit_en = ""
    let sizeFit_fr = ""
    if(brand[i] === "FROCCELLA") {
      madeIn_en = "<li>Made in Italy</li>"
      madeIn_fr = "<li>Fait en Italie</li>"
      sizeFit_en = "<li>One size, fits Small (size 6) to Large (size 14)</li>"
      sizeFit_fr = "<li>Taille unique, de la taille Petite (taille 6) à Grand (taille 14)</li>"
      exclusivity_en = "<li>Exclusively found at Coats Co. / Manteaux Manteaux</li>"
      exclusivity_fr = "<li>En exclusivité chez Manteaux Manteaux / Coats Co</li>"
    } else if (brand[i] === "VAMP" || brand[i] === "DEVIA" || brand[i] === "AMANI COUTURE"){
       madeIn_en = "<li>Made in Canada</li>"
       madeIn_fr = "<li>Fait au Canada</li>"
    }
    let descriptionInEnglish = ""
    descriptionInEnglish = collection === "mm-coats" 
    ? `${descEN_1}<ul>${tempRn + tempEN_6 + coatedRn + closure_3 + collar_7 + hood_4 + sleeves_5 + pocket_10 + interiorPockets_11 + modelEN_8 + measureEN + fabric_15 + exteriorMaterial_15 + liningMaterial_15 + liningEN + logo + notes_17 + careInstructions_16 + washAndSpray_en}</ul>`
    : `${descEN_1}<ul>${fit_1 + neckline_2 + sleeve_length + madeIn_en + exclusivity_en + sizeFit_en + pattern_6 + skirt_7 + modelEN_8 + fabric_15 + technicalDetailsEN + closure_3 + collar_7 + hood_4 + sleeves_5 + pocket_10 + interiorPockets_11 + careInstructions_16 + productWash_sportswear_en}</ul>`
    if(season === "p4-coats") {
      descriptionInEnglish = `${descEN_1}<ul>${technicalDetailsEN + closure_3 + pocket_10 + hood_4 + sleeves_5 + collar_7 + interiorPockets_11 + modelEN_8 +tempEN_6 +liningEN + notes_17 + measureEN + fabric_15 + exteriorMaterial_15 + liningMaterial_15 + careInstructions_16 + washAndSpray_en}</ul>`
    } else if (season === "p4-sports") {
      descriptionInEnglish = `${descEN_1}<ul>${fit_1 + neckline_2 + sleeve_length + madeIn_en + exclusivity_en + sizeFit_en + modelEN_8 + measureEN+ pattern_6 + skirt_7 + technicalDetailsEN + closure_3 + collar_7 + hood_4 + sleeves_5 + pocket_10 + interiorPockets_11 + fabric_15 + careInstructions_16 + productWash_sportswear_en}</ul>`
    }   
   
    descriptionInEnglish = descriptionInEnglish.replace(/^['"]|['"]$/g, '').replace(/"/g, '""')

    // console.log(`<li>${modelHeight[i]}`)
    // console.log(`<li>${modelHeight[i].replace(/"/g, '""')}`)
    // const keyValuePairs = {
    //   "tempRn_fr": tempRn_fr,
    //   "tempFR_6": tempFR_6,
    //   "coatedRn_fr": coatedRn_fr,
    //   "technicalDetailsFR": technicalDetailsFR,
    //   "closure_3_fr": closure_3_fr,
    //   "collar_7_fr": collar_7_fr,
    //   "hood_4_fr": hood_4_fr,
    //   "sleeve_cuffs_5_fr": sleeve_cuffs_5_fr,
    //   "pocket_10_fr": pocket_10_fr,
    //   "interiorPocketsFR": interiorPocketsFR,
    //   "modelFR_8": modelFR_8,
    //   "measureFR": measureFR,
    //   "fabric_composition_5_fr": fabric_composition_5_fr,
    //   "exteriorMaterial_15_fr": exteriorMaterial_15_fr,
    //   "liningMaterial_15_fr": liningMaterial_15_fr,
    //   "liningFR": liningFR,
    //   "logo_fr": logo_fr,
    //   "notes_17_fr": notes_17_fr,
    //   "careInstructions_16_fr": careInstructions_16_fr,
    //   "productWashLink_fr": productWashLink_fr,
    //   "prouctCareLink_fr": prouctCareLink_fr
    // };
    
    // console.log(keyValuePairs);
    let descriptionInFrench = ""
    descriptionInFrench = collection === "mm-coats" 
    ? `<p>${descriptionFR[i]}</p><ul>${tempRn_fr + tempFR_6 + coatedRn_fr + closure_3_fr + collar_7_fr + hood_4_fr + sleeve_cuffs_5_fr + pocket_10_fr + interiorPocketsFR + modelFR_8 + measureFR + fabric_composition_5_fr + exteriorMaterial_15_fr + liningMaterial_15_fr + liningFR + logo_fr + notes_17_fr + careInstructions_16_fr + washAndSpray_fr}</ul>`
    : `<p>${descriptionFR[i]}</p><ul>${fitFR + necklineFR + sleeve_length_fr + madeIn_fr + exclusivity_fr + sizeFit_fr + patternFR + skirtFR + sleeve_cuffs_5_fr + modelFR_8 + fabric_composition_5_fr + careInstructions_16_fr + productWash_sportswear_fr}</ul>`
    if(season === "p4-coats") {
      descriptionInFrench = `<p>${descriptionFR[i]}</p><ul>${technicalDetailsFR + closure_3_fr + pocket_10_fr + hood_4_fr + sleeve_cuffs_5_fr + collar_7_fr + interiorPocketsFR + modelFR_8 +tempFR_6 +fabric_composition_5_fr + exteriorMaterial_15_fr + liningMaterial_15_fr + liningFR + logo_fr + notes_17_fr + fitFR + measureFR + careInstructions_16_fr + washAndSpray_fr}</ul>`
    } else if (season === "p4-sports") {
      descriptionInFrench = `<p>${descriptionFR[i]}</p><ul>${fitFR + necklineFR + sleeve_length_fr + madeIn_fr + exclusivity_fr + sizeFit_fr + modelFR_8 + measureFR + patternFR + skirtFR + sleeve_cuffs_5_fr + fabric_composition_5_fr + careInstructions_16_fr + productWash_sportswear_fr}</ul>`
    }
    descriptionInFrench = descriptionInFrench.replace(/^['"]|['"]$/g, '').replace(/"/g, '""')

    bulletPoints.push(descriptionInEnglish)
    frenchBulletPoints.push(descriptionInFrench)

    const getFormattedDate = () => {
      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 to get correct month
      const day = String(today.getDate()).padStart(2, '0');
      const year = String(today.getFullYear());
    
      return `${month}/${day}/${year}`;
    }


    // console.log(bulletPoints)
    // console.log(colorFR)
    // console.log(i)
    // console.log(colorFR[i])
    for (let j = 0; j < colorFR[i].length; j++) {

    let rowHasFrontImage = frontImages[i] !== "";
    let rowHasBackImage = backImages[i] !== "";
    let rowHasSideImage = sideImages[i] !== "";

    let color_fr = ""
    let color_en = ""

    if (colorCodeMap[colorFR[i][j]] && colorCodeMap[colorFR[i][j]].fr) {
      color_fr = colorCodeMap[colorFR[i][j]].fr
      color_en = colorCodeMap[colorFR[i][j]].en
    }
    
    function stripHtmlTags(str) {
      return str.replace(/<[^>]*>/g, '');
    }
// console.log(colorCodeMap[color].fr)

    const currentRow = [
          `${supplierCode[i]}`, // Supplier Code
          `${supplierName[i]}`, // Supplier Name
          `${styleCode[i]}`, // Retail-1 Style
          `${itemClass[i]} ${retail1Description[i].replace(/"/g, '""')}`, // Retail-1 Description
          "", // Retail-1 Desc2
          `${colorFR[i][j]}`, // Color Code
          `${color_fr}`, // Retail 1 Color Desc
          "", // Virtual Style
          `${color_en}`, // English Web Color
          `${color_fr}`, // French Web Color
          `${colorFR[i][j]}`, // Primary Color Family
          "", // Secondary Color Family
          "", // Tertiary Color Family
          "N", // Allow Pre-order (Y/N)
          "", // Pre-order Date (mm/dd/yyyy)
          "N", // Allow Backorder (Y/N)
          "N", // In-Store Only (Y/N)
          "N", // Forced (Y/N)
          getFormattedDate(), // Site Live Date (mm/dd/yyyy)
          "", // Site End Date (mm/dd/yyyy)
          "3", // Inventory Threshold value
          "N", // International Shipping (Y/N)
          "N", // Mix-n-Match (Y/N)
          `${brand[i]}`, // Brand (en)
          `${titleEN[i]}`, // Web Style Description (en)
          `${titleFR[i]}`, // Web Style Description (fr)
          "", // Why we love this (en)
          "", // Why we love this (fr)
          "", // Fit (en)
          "", // Fit 2 (en)
          "", // Fit 3 (en)
          "", // Style (en)
          "", // Style 2 (en)
          "", // Style 3 (en)
          "", // Closure Type (en)
          "", // Closure Type (fr)
          "", // Fabric Composition (en)
          "", // Fabric Composition (fr)
          "", // Fur Details (en)
          "", // Fur Details (fr)
          "", // Gender (en)
          "", // Hood Details (en)
          "", // Hood Details (fr)
          `${bulletPoints[i]}`, // Key Features (en)
          `${frenchBulletPoints[i]}`, // Key Features (fr)
          "", // Knit or Woven (en)
          "", // Knit or Woven (fr)
          "", // Length (en)
          "", // Length (fr)
          "", // Made in (en)
          "", // Rise (en)
          "", // Rise (fr)
          "", // Product Care (en)
          "", // Product Care (fr)
          "", // Miscellaneous 1 (en)
          "", // Miscellaneous 1 (fr)
          "", // Miscellaneous 2 (en)
          "", // Miscellaneous 2 (fr)
          "", // Miscellaneous 3 (en)
          "", // Miscellaneous 3 (fr)
          "", // Miscellaneous 4 (en)
          "", // Miscellaneous 4 (fr)
          "", // Season (en)
          "", // Season 2 (en)
          "", // Season 3 (en)
          "", // Season 4 (en)
          "", // Badge (en)
          "", // Badge 2 (en)
          "", // Badge 3 (en)
          "", // Cross Sell 1
          "", // Cross Sell 2
          "", // Cross Sell 3
          "", // Cross Sell 4
          "", // Cross Sell 5
          "", // Cross Sell 6
          "", // Cross Sell 7
          "", // Cross Sell 8
          "", // Cross Sell 9
          "", // Cross Sell 10
          "", // Upsell 1
          "", // Upsell 2
          "", // Upsell 3
          "", // Upsell 4
          "", // Upsell 5
          "", // Upsell 6
          "", // Upsell 7
          "", // Upsell 8
          "", // Upsell 9
          "", // Upsell 10
          `${supplierCode[i]}-${styleCode[i]}-${colorFR[i][j]}-1`, // Primary-Image
          `${supplierCode[i]}-${styleCode[i]}-${colorFR[i][j]}-2`, // Alt-Image-1
          `${supplierCode[i]}-${styleCode[i]}-${colorFR[i][j]}-3`, // Alt-Image-2
          `${supplierCode[i]}-${styleCode[i]}-${colorFR[i][j]}-4`, // Alt-Image-3
          `${supplierCode[i]}-${styleCode[i]}-${colorFR[i][j]}-5`, // Alt-Image-4
          `${supplierCode[i]}-${styleCode[i]}-${colorFR[i][j]}-6`, // Alt-Image-5
          `${webCode[i].replace(/,/g, '')}`, // Web Categories
          "#000000", // swatches images html hex code
          "", // Meta Title
          "", // Meta Description
          "", // Meta Keywords
        ];

    csv.push(currentRow);
    }
  }

  return csv;
};
