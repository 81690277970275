import * as React from 'react';
import { Alert, Button, DialogActions, TextField } from '@mui/material';
import { Textarea } from '@mui/joy';
import { useState } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom"


const EmailPreview = ({
  customEmailData,
  setCustomEmailData,
  customerEmail,
  setCustomerEmail,
  userID,
  organizationName,
  displayName,
  cashOffer,
  storeCredit,
  id,
  customerName,
  handleDialogClose,
  setSendingEmail,
  setShowSuccessConfirmation,
  setShowFailedConfirmation,
  isEmailTemplateCustom,
  isSellerViewLinkPresent
}) => {

  const [defaultEmailTemplate, setDefaultEmailTemplate] = useState({
    subject: `${organizationName || displayName} : Your Order Form`,
    message: `Hey ${customerName},\nThis is ${organizationName || displayName}! We finished going through your drop off and for what we selected, we can offer $${cashOffer} cash or $${storeCredit} store credit.\nWe can send you an etransfer or e-gift card if you send us your email address.\nYou can pick up the remainder during our open hours or we can donate to a family in need on your behalf. Have a good day.`,
  })

  const [subjectValue, setSubjectValue] = useState(
    customEmailData?.subjectTemplate || defaultEmailTemplate.subject
  );

  const [bodyValue, setBodyValue] = useState(
    customEmailData?.bodyTemplate || defaultEmailTemplate.message
  );

  const handleSendEmail = () => {
    handleDialogClose()
    setSendingEmail(true)
    const body = {
      recipient: customerEmail,
      userID: userID,
      id: id,
      subject: subjectValue,
      message: bodyValue,
      isEmailTemplateCustom: isEmailTemplateCustom
    }

    axios.post(`https://apie.snapwrite.ca/storeData/send-email`, body)
      .then((res) => {
        setSendingEmail(false)
        if (res.status === 200) {
          setShowSuccessConfirmation(true)
        }
        else {
          setShowFailedConfirmation(true)
        }
      })
      .catch((e) => {
        console.log(e)
        setSendingEmail(false)
        setShowFailedConfirmation(true)
      })
  }

  return (
    <>

      <div className=' my-5 w-[34rem] flex flex-col gap-5'>
        <TextField
          required
          id="outlined-required"
          defaultValue={customEmailData?.email || "contact@snapwrite.ca"}
          size="small"
          label="From"
          sx={{ width: "100%" }}
          disabled
        />
        {/* Recepient  */}
        <TextField
          required
          id="outlined-required"
          defaultValue={customerEmail}
          size="small"
          label="To"
          sx={{ width: "100%" }}
          onChange={(e) => setCustomerEmail(e.target.value)}
        />

        {/* Subject  */}
        <TextField
          required
          id="outlined-required"
          defaultValue={subjectValue}
          size="small"
          label="Subject"
          sx={{ width: "100%" }}
          onChange={(e) => setSubjectValue(e.target.value)}
        />
        <Textarea sx={{ padding: 2 }} aria-label="Email Body" minRows={3} defaultValue={bodyValue}
          onChange={(e) => setBodyValue(e.target.value)}
        />

        {!isSellerViewLinkPresent && (
          <div>
            <Alert severity="info">
              <p className=' pb-1'>Following button will be displayed at the end of the email. To add the button manually, please update the email template in the <Link to={'/settings'}>Settings </Link> page.</p>
              
              <a href={`https://app.snapwrite.ca/form-seller-view/${id}`} target='_blank' rel="noreferrer">
                <Button variant='contained' className=' text-sm' sx={{ backgroundColor: "#e1fc00", color: "black" }}>
                  View Details
                </Button>
              </a>

            </Alert>
           
          </div>)}
      </div>

      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button onClick={() => {
          if (!isSellerViewLinkPresent && isEmailTemplateCustom) {
            setBodyValue(bodyValue => bodyValue + '\n\n[SELLER-VIEW-LINK]')
          }


          handleSendEmail()
        }} autoFocus>
          Send
        </Button>
      </DialogActions>
    </>
  )
}

export default EmailPreview