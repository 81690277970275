import {useContext, useEffect, useState } from "react";
import SideMenu from "../components/sideMenu";
import {getAuth, onAuthStateChanged} from '@firebase/auth'
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Table } from "reactstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Header } from "../components/navigation/header";
import Button from "@mui/material/Button";
import { OrganizationContext } from "../components/App";
import MainSideMenuHeaderContainer from "../components/containers/MainSideMenuHeaderContainer";
import { Heading1 } from "../components/branded-elements/Elements";

const UserBrandAggregator = () => {
	const { organization, setOrganization } = useContext(OrganizationContext);

	const [brandMap, setBrandMap] = useState({});
	const [barChartData, setBarChartData] = useState([]);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [barChartDataSlice, setBarChartDataSlice] = useState([]);
	const [searchedBrand, setSearchedBrand] = useState();
	const [scrollButtonVisible, setScrollButtonVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;
			setScrollButtonVisible(scrollTop > 300); // Show the button when scrolling down beyond a certain threshold
		};

		window.addEventListener('scroll', handleScroll);

		if (!organization?.id) return;

		// fetch users and map uid to display name
		let userMapping = {};
		let users = [organization.id];

		const endpoints = [
			'labeling-clothes-categorization',
			'regular-preLoved-apparel',
			'vintage-apparel',
			'new-apparel',
		];
		let allProducts = [];
		Promise.all(
			users.map((user) =>
				Promise.all(
					endpoints.map((endpoint) =>
						axios
							.get(
								`https://apie.snapwrite.ca/storeData/${endpoint}?userID=${user}`
							)
							.then((res) => {
								allProducts = [
									...allProducts,
									...res.data.data,
								];
							})
					)
				)
			)
		).then((results) => {
			// Process the combined data here
			let userToBrandMap = {};
			const brandData = allProducts.reduce((acc, obj) => {
				if (!obj.brand) {
					return acc; // Skip objects that don't have a brand property
				}
				if (!obj.userID) {
					return acc;
				}
				if (obj === undefined) {
					return acc;
				}
				obj.brand.forEach((brandName, i) => {
					// create a standard for brand names - trim leading/trailing white space, uppercase first letter of each word
					if (!brandName) return;
					brandName = brandName
						.toLowerCase()
						.replace(/\b\w/g, (char) => char.toUpperCase())
						.trim();
					if (!userToBrandMap[obj.userID]) {
						userToBrandMap[obj.userID] = {};
					}
					if (!userToBrandMap[obj.userID][brandName]) {
						userToBrandMap[obj.userID][brandName] = 1;
					} else {
						userToBrandMap[obj.userID][brandName]++;
					}

					if (!acc[brandName]) {
						// If brand name isn't already in the map, add it with initial values
						try {
							acc[brandName] = {
								count: 1,
								totalPrice: parseFloat(obj.price[i]) || 0,
								totalCost: parseFloat(obj.cost[i]) || 0,
								sellers: { [obj.userID]: 1 },
								conditions: [obj.condition[i] || 'Unknown'],
								genders: [obj.gender[i] || 'Unknown'],
								agesTotal: obj.age[i] || 0,
							};
						} catch {}
					} else {
						// Otherwise, update the values for the existing brand name
						try {
							const data = acc[brandName];
							data.count++;
							data.totalPrice += parseFloat(obj.price[i]) || 0;
							data.totalCost += parseFloat(obj.cost[i]) || 0;
							data.agesTotal += obj.age[i] || 0; // Unsure how to effectively use age data
							data.conditions.push(obj.condition[i] || 'Unknown');
							data.genders.push(obj.gender[i] || 'Unknown');

							if (!data.sellers[obj.userID]) {
								data.sellers[obj.userID] = 1;
							} else {
								data.sellers[obj.userID] += 1;
							}
						} catch {}
					}
				});
				return acc;
			}, {});
			setBrandMap(brandData);

			// reformat map to work with recharts
			const chartData = Object.keys(brandData)
				.map((brandName) => ({
					name: brandName,
					value: brandData[brandName].count,
				}))
				.sort((a, b) => b.value - a.value);
			setBarChartData(chartData);
			setBarChartDataSlice(chartData.slice(0, 7));
		});

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [organization]);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Smooth scrolling animation
		});
	};

	const handleNext = () => {
		if (currentSlide === Math.floor(barChartData.length / 7)) {
			return;
		}
		setCurrentSlide((prevSlide) => prevSlide + 1);
		setBarChartDataSlice(
			barChartData.slice(currentSlide * 7 + 7, currentSlide * 7 + 14)
		);
	};

	const handlePrevious = () => {
		if (currentSlide === 0) {
			return;
		}
		setCurrentSlide((prevSlide) => prevSlide - 1);
		setBarChartDataSlice(
			barChartData.slice(currentSlide * 7 - 7, currentSlide * 7)
		);
	};

	const scrollToRow = (key) => {
		if (key) {
			const rowElement = document.querySelector(`tr[data-key="${key}"]`);
			if (rowElement) {
				setSearchedBrand(key);
				rowElement.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	};

	return (
		<>
			{/* The grey section where the user can upload image, edit tags and get the description */}

			<MainSideMenuHeaderContainer
			name={undefined}
			>
				<Heading1 title={"Brands Analytics"} />
				<Button
					variant='contained'
					color='primary'
					onClick={scrollToTop}
					style={{
						display: scrollButtonVisible ? 'block' : 'none',
						zIndex: 30,
						position: 'fixed',
						top: 10,
						left: '50%',
						transform: 'translateX(-50%)',
					}}
				>
					Scroll to Top
				</Button>
				<div
					style={{
						display: 'flex',
						padding: '30px',
						justifyContent: 'left',
						width: '100%',
						gap: '30px',
					}}
				>
					<Autocomplete
						disablePortal
						disableClearable
						id='brand-autocomplete'
						options={Object.keys(brandMap)}
						defaultValue={'Select a Brand'}
						// getOptionLabel={option => option.displayName}
						sx={{ width: 300, fontSize: 'normal' }}
						size='small'
						// getOptionSelected={(option, value) => option.label === value.label}
						// isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
						onChange={(e, value) => scrollToRow(value)}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Search by Brand'
								sx={{ fontSize: 'normal' }}
							/>
						)}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							padding: '30px',
							justifyContent: 'center',
							width: '100%',
							gap: '30px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '30px',
							}}
						>
							<div
								style={{
									boxShadow: '3px 3px 3px #8884d8',
									border: 'solid 2px black',
									padding: 20,
									borderRadius: '10px',
								}}
							>
								<BarChart
									width={600}
									height={400}
									data={barChartDataSlice}
								>
									<CartesianGrid strokeDasharray='3 3' />
									<XAxis
										tickFormatter={(value) => {
											if (value === '' || value === ' ') {
												return 'N/A';
											}
											if (value.length > 8) {
												return (
													value.substring(0, 7) +
													'...'
												);
											} else {
												return value;
											}
										}}
										textAnchor='end'
										angle={-50}
										dataKey='name'
									/>
									<YAxis />
									<Tooltip />
									<Legend iconType='none' height={40} />
									<Bar dataKey='value' fill='#8884d8' />
								</BarChart>
							</div>
							<div
								style={{
									display: 'flex',
									gap: '5px',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Button
									style={{
										padding: '3px',
										cursor: 'pointer',
									}}
									onClick={handlePrevious}
								>
									PREV
								</Button>
								<Button
									style={{
										padding: '3px',
										cursor: 'pointer',
									}}
									onClick={handleNext}
								>
									NEXT
								</Button>
							</div>
						</div>
					</div>

					<></>

					<div style={{ width: '100%' }}>
						<Table>
							<thead>
								<tr>
									<th>Brand Name</th>
									<th>Total Item Count</th>
									<th>Avg Retail Price</th>
									<th>Avg Acquisition Cost</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(brandMap)
									.sort(
										(a, b) =>
											brandMap[b].count -
											brandMap[a].count
									)
									.map((brandName) => {
										const data = brandMap[brandName];
										const avgRetailPrice =
											data.totalPrice / data.count;
										const avgAcquisitionCost =
											data.totalCost / data.count;

										return (
											<tr
												data-key={brandName}
												style={{
													cursor: 'pointer',
													backgroundColor:
														brandName ===
														searchedBrand
															? '#8884d8'
															: '',
												}}
												key={brandName}
											>
												<td>
													{brandName != ''
														? brandName
														: 'N/A'}
												</td>
												<td>{data.count}</td>
												<td>
													{avgRetailPrice.toFixed(2)}
												</td>
												<td>
													{avgAcquisitionCost.toFixed(
														2
													)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</div>
				</div>
			</MainSideMenuHeaderContainer>
		</>
	);
};

export default UserBrandAggregator;
