import { collarType } from "./collarType";

const collar = [
    "Neck",
    "Collared",
    "collar"
  ];

  const synonymsArray = [collar, ...collarType]

export const getCollarSynonyms = (collar) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => collar.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 