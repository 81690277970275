const ItemNameFiller = (userID, itemType) => {

  // As per the old function
  const userIDToAllow = [
    "HDlknVR9NpWxhs9WWlfvnwAQq4b2",
    "ZFoq2NIsDmZgVTW4g88O1B8JA822",
    "s8G1GJrXSraO8ySUsMeGY61sJRk1",
    "NpGB6zItW1OcpizZJbD88VsfKlf2",
    "1BILFjc4JNM0LDmmOUfdr2rRfNH3",
    "AgVEaDRk3tVtnfqAKiMYWLlRICG2",
    "3yipeopDO0WyJGntigywDRUMwmx2",
    "r8jkmnica3dJXGLyiIFbItEgZvr2",
    "b41Z6rL6eJN1VccgZXrUzWbdjGg1", //curated cubs test
    "1eMplQPlHHhX9lnwX8NTcKghui22",
    "7eXVFZlMcgY7HMKKSkCf4kb7qEt1", // CPV
    "5APyYorrgoZ8uKOcxp5YMz3J0zq1", // curated cubs
    "lUrOR5b9i9TYSNJ4zEfGRTkoPqs1",
    "JQCqZOIsoAWyVIE24GSDSi7Lwk92",
    "dc8Kk3xKrkcil7ZorkeDeTX1iIt2"
];

const userIDToIgnore = ["zjkiN1cq9Eb4oHsrA923OoJ9Sxz1", "CXNDhrE6FfNRdXNm6nUQlmck29f2"];

// Allow all users but the ones in the ignore list
    if(!userIDToIgnore.includes(userID))
       {         
        if(itemType && itemType.length>2){
            if(itemType[itemType.length-1]==='s' && itemType[itemType.length-2]==='e') {                
              return itemType.substring(0, itemType.length-2)
            } else if (itemType[itemType.length-1]==='s') {
              return itemType.substring(0, itemType.length-1)
            } else {
              return itemType
            }
          }
        else if (itemType) {
            return itemType
        }
        else {
            return ""
        }
    } else {
        return ""
    }
}

export default ItemNameFiller;