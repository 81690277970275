const asymmetric = [
    "Uneven",
    "Lopsided",
    "Irregular",
    "Unbalanced",
    "Dissimilar",
    "Disproportionate",
    "Askew",
    "Skewed",
    "Unequal",
    "Unsymmetrical",
    "Crooked",
    "Off-center",
    "Misaligned",
    "Nonuniform",
    "asymmetric"
  ];
  
  const symmetric = [
    "Balanced",
    "Proportional",
    "Regular",
    "Equally divided",
    "Uniform",
    "Mirrored",
    "Aligned",
    "Harmonious",
    "Consistent",
    "Identical",
    "Proportionate",
    "Even",
    "Coordinated",
    "Corresponding",
    "symmetric",
    "symmetry"
  ];

  export const closureSymmetry = [asymmetric, symmetric]