import { useParams } from "react-router";
import { Header } from "../../navigation/header.js";
import SideMenu from "../../sideMenu.js";
import { getAuth } from '@firebase/auth';
import { CSVLink } from "react-csv";
import {useEffect,useState} from "react";
  

const SortedLabelsPage = ({history}) => {
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
        history.push('/')
    }
},[]);

const auth = getAuth();
    const user = auth.currentUser;
    let userID;
    
    if (user !== null) {
      userID= user.uid;
      // console.log(userID)           

        user.providerData.forEach((profile) => {            
            userID = user.uid;                     
        });
      }      
    const {id} = useParams();    
    
    const [csvDownload, SetCsvDownload] = useState([]);
    const [FrenchCsvDownload, SetFrenchCsvDownload] = useState([]);
    const [EnglishCsvDownload, SetEnglishCsvDownload] = useState([]);    
    
    const [s3Images, setS3Images] = useState([]);    
    const [englishNames, setEnglishName] = useState([]);
    const [frenchNames, setFrenchName] = useState([]);
    const [formats, setFormat] = useState([]);
    const [brands, setBrand] = useState([]);
    const [projectName, setProjectName] = useState("");

    const [imageFileName, setImageFileName] = useState([]);


    useEffect(()=>{        
        fetch(`https://apie.snapwrite.ca/storeData/labeling-categorization/${id}`)
        .then(response=>response.json())
        .then(json=>{            
            setProjectName(json.data.projectName)
            setS3Images(json.data.images)
            setEnglishName(json.data.englishEntryName);
            setFrenchName(json.data.frenchEntryName);
            setFormat(json.data.format);
            setBrand(json.data.brand)
        })
        .catch(err=>{
            console.log(`Error ${err}`)
        })
    },[])

    
    const createcsv = () => {
      let csv = [];
      // let csv = csvRowsTemp;
      let i = 0;
      for (i = 0; i < englishNames.length; i++) {
          csv.push([
              englishNames[i],
              frenchNames[i],
              formats[i],
              brands[i],
              s3Images[i]
  
          ])
      }
      csv.unshift([
          "Entry Name (English)",
          "Entry Name (French)",
          "Format",
          "Brand",
          "Image Link"
      ])
      // console.log(csv)
      SetCsvDownload(csv);
      
    };
  
    const createcsvFrench = () => {
      let csv = [];    
      let i = 0;
      for (i = 0; i < frenchNames.length; i++) {
          csv.push([            
              frenchNames[i],
              formats[i],
              brands[i],
              s3Images[i]
          ])
      }
      csv.unshift([        
          "Entry Name (French)",
          "Format",
          "Brand",
          "Image Link"
      ])    
      SetFrenchCsvDownload(csv);    
    };
  
    const createcsvEnglish = () => {
      let csv = [];    
      let i = 0;
      for (i = 0; i < englishNames.length; i++) {
          csv.push([            
              englishNames[i],
              formats[i],
              brands[i],
              s3Images[i]
          ])
      }
      csv.unshift([        
          "Entry Name (English)",
          "Format",
          "Brand",
          "Image Link"
      ])    
      SetEnglishCsvDownload(csv);    
    };

    const updateSortedLabelData = (englishNames, frenchNames, formats, brands) =>{
      
      createcsv();
      createcsvEnglish();
      createcsvFrench();

      fetch(`https://apie.snapwrite.ca/storeData/labeling-categorization`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({        
          id: id,
          projectName: projectName,
          images: s3Images,
          englishEntryName: englishNames,
          frenchEnglishName: frenchNames,
          format: formats,
          brand: brands
        }),
      })
        .then((response) => response.json())      
        .catch((err) => {
          console.log(`Error ${err}`);
        });    

      if(id === JSON.parse(localStorage.getItem(`sortedLabelObjectID${userID}`))){        
      localStorage.setItem(`categoryImageLink${userID}`, JSON.stringify(s3Images));    
      localStorage.setItem(`englishEntryName${userID}`, JSON.stringify(englishNames));
      localStorage.setItem(`frenchEntryName${userID}`, JSON.stringify(frenchNames));
      localStorage.setItem(`format${userID}`, JSON.stringify(formats));
      localStorage.setItem(
        `brand${userID}`,
        JSON.stringify(brands)
      );    
      }

      
    }
      // console.log(s3Images)

      const updateEnglishName = (event, index) => {
        const newName = englishNames.slice();
        newName[index] = event.target.value;
        setEnglishName(newName);        
        updateSortedLabelData(newName, frenchNames, formats, brands)
      };
      const updateFrenchName = (event, index) => {
        const newName = frenchNames.slice();
        newName[index] = event.target.value;
        setFrenchName(newName);        
        updateSortedLabelData(englishNames, newName, formats, brands)

        // // console.log(colors)
        // createcsv(csvRowsTemp);
      };
      const updateFormat = (event, index) => {
        const newFormat = formats.slice();
        newFormat[index] = event.target.value;
        setFormat(newFormat);        
        updateSortedLabelData(englishNames, frenchNames, newFormat, brands)

        // createcsv(csvRowsTemp);
      };
      const updateBrand = (event, index) => {
        const brand = brands.slice();
        brand[index] = event.target.value;
        setBrand(brand);        
        updateSortedLabelData(englishNames, frenchNames, formats, brand)

        // createcsv(csvRowsTemp);
      };
      const updateProjectName = (event) => {        
        // console.log(event)
    
        setProjectName(event)
          if(id!=="") {        
            fetch(`https://apie.snapwrite.ca/storeData/labeling-categorization-projectNameUpdate`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({        
                id: id,      
                projectName: projectName            
              }),
            })
              .then((response) => response.json())      
              .catch((err) => {
                console.log(`Error ${err}`);
              });      
          }
      }
    
    
    return (
      <div className="descriptions">
      {/* {console.log("noOfWordsGenerated = " + noOfWordsGenerated)} */}
      <Header        
        updateProjectName={updateProjectName}
        setProjectName={setProjectName}
        projectName={projectName}
        name={"labels"}
      />
      {/* <hr width="100%;" /> */}
      {/* ******************************the <main> section****************************** */}
      <div className="interface">
        <SideMenu />
        {/* The grey section where the user can upload image, edit tags and get the description */}
        <div className="playGround">
          {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
          <div className="tools">
            <div className="bulk-upload">
              
                <>                  
                    <div id="observation-data">
                      <table>
                        <thead style={{ borderBottom: "1px solid" }}>
                          <tr>                            
                            <th>Item</th>
                            <th>Entry Name (English)</th>
                            <th>Entry Name (French)</th>
                            <th>Format</th>
                            <th>Brand</th>                            
                          </tr>
                        </thead>
                        <tbody id="rows">
                          {s3Images.map((key, index) => {
                            return s3Images[index] !== undefined ? (
                              <tr>
                                {/* {console.log(rows)} */}
                                <td style={{ width: "10%" }}>
                                  <img
                                    src={s3Images[index]}
                                    draggable={false}
                                    alt={imageFileName[index]}
                                    style={{ width: "80%" }}
                                  />
                                </td>
                                <td>
                                  <textarea
                                    style={{
                                      background:
                                        index % 2 !== 0 ? "#e6e6e6" : "#f5f6f8",
                                      border: "none",
                                      width: "186px",
                                      resize: "none",
                                    }}
                                    onChange={(event) =>
                                        updateEnglishName(event, index)
                                    }
                                    value={englishNames[index] ? englishNames[index] : null}
                                    // value={rows[index][1]?rows[index][1]:null}
                                  />
                                  {/* {rows[index][1]?rows[index][1]:null} */}
                                </td>
                                <td>
                                  <textarea
                                    style={{
                                      background:
                                        index % 2 !== 0 ? "#e6e6e6" : "#f5f6f8",
                                      border: "none",
                                      width: "186px",
                                      resize:"none"
                                    }}
                                    onChange={(event) =>
                                        updateFrenchName(event, index)
                                    }
                                    value={
                                      frenchNames[index] ? frenchNames[index] : null
                                    }
                                  />
                                  {/* {rows[index][2]?rows[index][2]:null} */}
                                </td>
                                <td>
                                  <textarea
                                    style={{
                                      background:
                                        index % 2 !== 0 ? "#e6e6e6" : "#f5f6f8",
                                      border: "none",
                                      width: "102px",
                                      resize: "none",
                                    }}
                                    onChange={(event) =>
                                      updateFormat(event, index)
                                    }
                                    value={
                                      frenchNames[index] ? formats[index] : null
                                    }
                                  />
                                  {/* {rows[index][3]?rows[index][3]:null} */}
                                </td>
                                <td>
                                  <textarea
                                    style={{
                                      background:
                                        index % 2 !== 0 ? "#e6e6e6" : "#f5f6f8",
                                      border: "none",
                                      height: "100%",
                                      resize: "none",
                                      width: "240px",
                                    }}
                                    onChange={(event) =>
                                      updateBrand(event, index)
                                    }
                                    value={
                                      frenchNames[index]
                                        ? brands[index]
                                        : null
                                    }
                                  />
                                  {/* {rows[index][4]?rows[index][4]:null} */}
                                </td>
                              </tr>
                            ) : null;
                          })}
                        </tbody>
                      </table>
                    </div>                  
                </>              
              <div className="suggestions">
                <div className="tag-suggestions">
                  <ul className="suggestionTags">
                  <div className="downloadCSV" onClick={()=>createcsv()}>
                    <CSVLink className="btn" data={csvDownload}>
                      Generate CSV
                    </CSVLink>
                    </div>
                    <div className="downloadCSV" onClick={()=>createcsvEnglish()}>
                    <CSVLink className="btn" data={EnglishCsvDownload}>
                      Generate English CSV
                    </CSVLink>
                    </div>
                    <div className="downloadCSV" onClick={()=>createcsvFrench()}>
                    <CSVLink className="btn" data={FrenchCsvDownload}>
                      Generate French CSV
                    </CSVLink>
                    </div>
                    {/* <CSVLink data={csvDownload}>Download me</CSVLink> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        
    )
}


export default SortedLabelsPage