import { useState, useEffect, useContext } from "react";
import trash from "../../../../../assets/trash_can.png";
import ImageColumn from "../../tableComponents/imageColumn";
import { handleDeleteIndividual } from "../../utils/handleRowDelete";
import SupplementaryImageColumn from "../../tableComponents/supplementaryImageColumn";
import axios from "axios";
import { RowColumn } from "../../tableList";
import AttributesColumn from "./attributesColumn";
import { OrganizationContext } from "../../../../../components/App";
import { TableBody, TableCell, TableHead, TableRow, Stack, Typography } from "@mui/material";

const TagTable = ({ prop }) => {
  const [deletedRows, setDeletedRows] = useState([]);
  const backendClient = axios.create({
    baseURL: "https://apif.snapwrite.ca/",
  });

  // console.log(props.colorFR)

  const props = prop
  const { handleRowSelection } = props
  const { organization, setOrganization } = useContext(OrganizationContext)

  const userID = organization?.id || props.userID;


  const handleHover = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "visible";

  };

  // Make trash button hidden when the table row is not being hovered and return cells to their original colour
  const handleMouseOut = (event) => {
    const tableRow = event.target.closest("tr");
    const index = tableRow.id;
    const trashButton = tableRow.querySelector(`#trash${index}`);
    trashButton.style.visibility = "hidden";

  };
  // console.log(props.length)
  const modelHeightOptions = [{ value: "5'7\"", label: "5'7\"" }, { value: "5'8\"", label: "5'8\"" }, { value: "5'9\"", label: "5'9\"" }, { value: "5'10\"", label: "5'10\"" }, { value: "5'11\"", label: "5'11\"" }]
  // const modelSizeOptions = [{value: "SMALL", label: "SMALL"}, {value: "MEDIUM", label: "MEDIUM"}, {value: "LARGE", label: "LARGE"}, {value: "4", label: "4"}, {value: "6", label: "6"}, {value: "8", label: "8"}, {value: "1X", label: "1X"}]
  const modelSizeOptions = [{ value: "S", label: "S" }, { value: "M", label: "M" }, { value: "L", label: "L" }, { value: "4", label: "4" }, { value: "6", label: "6" }, { value: "8", label: "8" }, { value: "1X", label: "1X" }]

  const isSeasonP4 = props.season === "p4-coats" || props.season === "p4-sports"

  const dropdownOptions = {}



  const technicalDetailsBullet = (index) => {
    if ((props.itemClass[index] < 140 && props.itemClass[index] > 129) || (props.itemClass[index] < 540 && props.itemClass[index] > 529)) {
      // console.log(props.technicalDetails)
      if (props.technicalDetails[index].toLowerCase() === "water and wind resistant") {
        if ((props.brand[index] === "SAKI" || props.brand[index] === "POINT ZERO")) {
          return `Coated outer shell for added wind resistance, water repellency and durability`
        } else if (props.brand[index] === "BERNARO") {
          return `Coated outer shell for added wind resistance, water repellency and durability`
        }
      } else if (props.technicalDetails[index].toLowerCase() === "protection against wind, rain and snow") {
        if (props.brand[index] === "STYLA") {
          return `Outer shell has a coating called "Storm Control" which adds extra protection against wind, rain and snow`
        }
      } else if (props.technicalDetails[index].toLowerCase() === "wind resistance, Water repellency and durability") {
        if (props.brand[index] === "FREE COUNTRY") {
          return `Coated outer shell for added wind resistance, water repellency and durability`
        }
      } else if (props.technicalDetails[index].toLowerCase() === "wind resistance, water and stain repellency") {
        if (props.brand[index] === "FROCCELLA") {
          return `Coated outer shell for added wind resistance, water and stain repellency`
        }
      }
    }
  }

  const insulationBullet = (index) => {
    if ((props.itemClass[index] < 140 && props.itemClass[index] > 129) || (props.itemClass[index] < 540 && props.itemClass[index] > 529)) {
      if ((props.brand[index] === "SAKI" || props.brand[index] === "POINT ZERO")) {
        return `Insulated with Polyloft, an advanced ecological insulation that is breathable and hypoallergenic`
      } else if (props.brand[index] === "BERNARO") {
        return `Insulated with Eco Plum, an insulation made from recycled materials that is warm, breathable, hypoallergenic and super soft`
      } else if (props.brand[index] === "LOOP") {
        return `Insulated with high-performance fibers made from recycled plastic bottles, which create an advanced ecological insulation`
      } else if (props.brand[index] === "STYLA") {
        return `Insulated with Polyloft, an advanced ecological insulation that is breathable and hypoallergenic`
      } else if (props.brand[index] === "FREE COUNTRY") {
        return `Insulated with fibers made from recycled plastic, which make up an advanced ecological insulation that is breathable and hypoallergenic`
      } else if (props.brand[index] === "FROCCELLA" || props.brand[index] === "BIG CHILL" || props.brand[index] === "SNOBOLL" || props.brand[index] === "SOKOS") {
        return `Insulated with Polyloft, an advanced ecological insulation that is breathable and hypoallergenic`
      }
    }
  }
  const descriptionSubheadStyle = {
    color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',

    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '0.15px',
    paddingLeft: '30px',
    marginBottom: '-20px',

  };

  return (
    organization && 
    <table>
      <TableHead style={{ borderBottom: '1px solid' }}>
        <tr className='tableheader'>
          <th className='tablecol1 p-2'></th>
          <th className='imgcol p-2'>Front View</th>
          <th className='pl-4'>Side View</th>
          <th className='pl-4'>Back View</th>
          <th className='pl-4'>Tag</th>
          <th className='pl-4'>Attributes</th>
          <th className='pl-4'>Titles</th>
            {/* P4 Table Head  */}
            {isSeasonP4 && <th className='pl-4'>Descriptions</th>}
          {!isSeasonP4 && (
            <>
              <th className='pl-4'>Description EN</th>
              <th className='pl-4'>Description FR</th>
            </>
          )}
          <th className='pl-4'>Supplier</th>          
          <th className='pl-4'>Brand</th>
          <th className='pl-4'>Style Code</th>
          <th className='pl-4'>Length</th>
          <th className='pl-4'>Material</th>
          <th className='pl-4'>Temp Rating</th>
          <th className='pl-4'>Model</th>
          <th className='pl-4'>Class</th>
          <th className='pl-4'>Retail 1 Description</th>
          <th className='pl-4'>Color FR</th>
        

          <th className='pl-4'>Notes</th>
        </tr>
      </TableHead>
      <TableBody id='rows'>
        {
          props.s3Images.map((key, index) => {
            const rows = [];
            rows.push(
              props.s3Images[index] !== undefined &&
                !deletedRows.includes(index) ? (
                <TableRow
                  key={props.objectID[index]}
                  id={index}
                  style={{ verticalAlign: 'top',
                height: isSeasonP4 ? '550px': "auto"
                }}
                >
                  <TableCell className='tablecol1'>
                    {/* <img
                    src={trash}
                    id={`trash${index}`}
                    alt="delete"
                    style={{
                      height: "2vh",
                      width: "1vw",
                      opacity: 0.5,
                      visibility: "hidden",
                    }}
                    onClick={() => {
                      let actualIndex = index; // When we delete a row, the # of rows in database decreases, but not in the frontend
                      deletedRows.forEach((d) => {
                        // (to prevent changing props). actualIndex = index in database. index = index in frontend.
                        if (d < index) actualIndex--;
                      });
                      handleDeleteIndividual(actualIndex, props);
                      setDeletedRows([...deletedRows, index]);
                    }}
                  /> */}
                    <input
                      type='checkbox'
                      style={{
                        backgroundColor: '#e3fc00',
                      }}
                      onChange={async (e) => {
                        handleRowSelection(e, index, props)
                      }
                    }
                    />
                  </TableCell>
                  <ImageColumn
                  singleProductModelFilter={{ userID: props.userID, sku: props.sku[index] }}
                    mm
                    attr='s3Images'
                    setParentVal={props.setS3Images}
                    images={props.s3Images}
                    fileName={props.imageFileName[index]}
                    setS3Images={props.setS3Images}
                    index={index}
                    template={props.template}
                    id={props.objectID}
                    primaryImage={props.s3Images[index]}
                  />
                  <ImageColumn
                  singleProductModelFilter={{ userID: props.userID, sku: props.sku[index] }}
                    attr='sideImages'
                    mm
                    setParentVal={props.setSideImages}
                    name={'Side'}
                    noScroll
                    noHover
                    images={props.sideImages}
                    fileName={props.imageFileName[index]}
                    setS3Images={props.setSideImages}
                    primaryImage={props.s3Images[index]}
                    index={index}
                    template={props.template}
                    id={props.objectID}
                  />
                  <ImageColumn
                  singleProductModelFilter={{ userID: props.userID, sku: props.sku[index] }}
                    attr='backImages'
                    mm
                    setParentVal={props.setBackImages}
                    name={'Back'}
                    noScroll
                    noHover
                    images={props.backImages}
                    fileName={props.imageFileName[index]}
                    setS3Images={props.setBackImages}
                    index={index}
                    template={props.template}
                    id={props.objectID}
                    primaryImage={props.s3Images[index]}
                  />
                  <ImageColumn
                  singleProductModelFilter={{ userID: props.userID, sku: props.sku[index] }}
                    attr='itemImages'
                    mm
                    setParentVal={props.setItemImages}
                    name={'Tag'}
                    noScroll
                    noHover
                    images={props.itemImages}
                    fileName={props.imageFileName[index]}
                    setS3Images={props.setItemImages}
                    index={index}
                    template={props.template}
                    id={props.objectID}
                    primaryImage={props.s3Images[index]}
                  />
                   <AttributesColumn
                    closureOptions={organization.closureOptions}
                    hoodOptions={organization.hoodOptions}
                    liningOptions={organization.liningOptions}
                    pocketOptions={organization.pocketOptions}
                    drawstringOptions={organization.drawstringOptions}
                    sleeveCuffsOptions={organization.sleeveCuffsOptions}
                    collarOptions={organization.collarOptions}
                    interiorPocketOptions={organization.interiorPocketOptions}
                    prop={props}
                    index={index}
                    userID={props.userID}
                  />

                  <TableCell>
                    <div style={{paddingLeft:10}}>
                      <RowColumn
                        label='English'
                        name='titleEN'
                        type='muiText'
                        index={index}
                        prop={props}
                      />
                    </div>
                    <br></br>
                    <div style={{paddingLeft:10}}>
                      <RowColumn
                        label='Français'
                        name='titleFR'
                        type='muiText'
                        index={index}
                        prop={props}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    {isSeasonP4 && (
                      <Stack gap={9}>
                        <div>
                          <Typography sx={descriptionSubheadStyle}>English</Typography>
                          <RowColumn
                            name='descriptionEN'
                            type='description'
                            index={index}
                            prop={props}
                          />
                        </div>
                        <div>
                          <Typography sx={descriptionSubheadStyle}>Français</Typography>
                          <RowColumn
                            name='descriptionFR'
                            type='description'
                            index={index}
                            prop={props}
                          />
                        </div>
                      </Stack>
                    )}

                    {!isSeasonP4 && (<>
                      <RowColumn
                        name='descriptionEN'
                        type='description'
                        index={index}
                        prop={props}
                      />
                      <div className=' mt-20 ml-4'>
                        <span className=' block font-semibold'>
                          First insulation bullet that
                          will automatically be added (if
                          any)
                        </span>
                        <ul className='ml-6 mt-2 text-gray-950'>
                          <li>
                            {insulationBullet(index)}
                          </li>
                        </ul>
                      </div>

                    </>)}

                  </TableCell>
                  {!isSeasonP4 && (
                    <RowColumn
                      name='descriptionFR'
                      type='description'
                      index={index}
                      prop={props}
                    />
                  )}
                  <TableCell>
                    <div style={{}}>
                      <RowColumn
                        label='Code'
                        name='supplierCode'
                        type='muiText'
                        index={index}
                        prop={props}
                      />                      
                    </div>
                    <br></br>
                    <div style={{}}>
                      <RowColumn
                        label = 'Name'
                        name='supplierName'
                        type='muiText'
                        index={index}
                        prop={props}
                      />                      
                    </div>
                  </TableCell>                                 
                  <RowColumn
                    name='brand'
                    type='ta'
                    index={index}
                    prop={props}
                  />
                  <RowColumn
                    name='styleCode'
                    type='ta'
                    index={index}
                    prop={props}
                  />
                  <TableCell>
                    <div style={{}}>
                      <RowColumn
                        numeric
                        conversion={0.393701}
                        label='CM'
                        otherLabel='IN'
                        otherName='inches'
                        otherAttr='lengthInches'
                        name='length'
                        type='linkedPropertyItem'
                        index={index}
                        prop={props}
                      />

                      <RowColumn
                        noTD
                        name='fit'
                        userID={props.userID}
                        noNotes
                        noSave
                        label='Fit'
                        type='autocomplete'
                        autocompleteOptions={organization.fitOptions}
                        index={index}
                        prop={props}
                      />
                    </div>
                  </TableCell>
                  {/* Material */}
                  <TableCell>
                    <RowColumn
                      noTD
                      name='careInstructions'
                      label='Care Instructions'
                      userID={props.userID}
                      noNotes
                      type='autocomplete'
                      autocompleteOptions={organization.careInstructionsOptions}
                      index={index}
                      prop={props}
                    />
                    <br></br>
                    <RowColumn
                      noTD
                      name='fabric'
                      userID={props.userID}
                      noNotes
                      noSave
                      label='Fabric Composition'
                      type='autocomplete'
                      autocompleteOptions={organization.fabricOptions}
                      index={index}
                      prop={props}
                    />
                    <br></br>
                    <RowColumn
                      noTD
                      name='technicalDetails'
                      userID={props.userID}
                      noNotes
                      noSave
                      label='Technical Details'
                      type='autocomplete'
                      autocompleteOptions={
                        organization.technicalDetailsOptions
                      }
                      index={index}
                      prop={props}
                    />
                    <br></br>
                    <RowColumn
                      noTD
                      name='exteriorMaterial'
                      userID={props.userID}
                      noNotes
                      noSave
                      label='Exterior Material Details'
                      type='autocomplete'
                      autocompleteOptions={
                        organization.exteriorMaterialOptions
                      }
                      index={index}
                      prop={props}
                    />
                    <br></br>
                    <RowColumn
                      noTD
                      name='liningMaterial'
                      userID={props.userID}
                      noNotes
                      noSave
                      label='Lining Material Details'
                      type='autocomplete'
                      autocompleteOptions={
                        organization.liningMaterialOptions
                      }
                      index={index}
                      prop={props}
                    />
                    <p className=' mt-3'>
                      <span className=' block text-lg font-semibold'>
                        Bullet that will automatically
                        be added (if any)
                      </span>
                      <ul className=' mt-2 text-gray-950'>
                        <li>
                          {technicalDetailsBullet(
                            index
                          )}
                        </li>
                      </ul>
                    </p>
                  </TableCell>
                  <RowColumn
                    name='temp'
                    attr='temperature'
                    noNotes
                    type='select'
                    index={index}
                    prop={props}
                  />
                  <TableCell>
                    <RowColumn
                      name='modelHeight'
                      type='stackItemSelect'
                      label={'Height'}
                      selectOptions={modelHeightOptions}
                      index={index}
                      prop={props}
                    />
                    <br></br>
                    <RowColumn
                      name='modelSize'
                      type='stackItemSelect'
                      label={'Item Size'}
                      selectOptions={modelSizeOptions}
                      index={index}
                      prop={props}
                    />
                  </TableCell>

                  <RowColumn
                    name='webCode'
                    attr='web_class_code'
                    type='ta'
                    index={index}
                    prop={props}
                  />
                  <RowColumn
                    name='retail1Description'
                    type='ta'
                    index={index}
                    prop={props}
                  />
                  {/*<RowColumn name="colorEN" type="ta" index={index} prop={props} />*/}
                  <TableCell>
                    {props.colorFR[index].map(
                      (color, i) => {
                        return (
                          <div
                          key={index + '-' + i}

                          >
                        <RowColumn
                          colorOption={i}
                          name='colorFR'
                          type='ta'
                          index={index}
                          prop={props}
                        />
                        </div>
                      )}
                    )}
                  </TableCell>
                  <RowColumn
                    textBoxHeight='130px'
                    textBoxWidth='300px'
                    name='notes'
                    type='ta'
                    index={index}
                    prop={props}
                  />
                  {/* <RowColumn setParentVal={props.setClosure} name="closure" type="ta" index={index} prop={props} /> */}
                </TableRow>
              ) : null
            );
            return rows;
          })}
      </TableBody>
    </table>
  );
};

export default TagTable;
