import React, {useState } from 'react';
import ProjectViewCard from '../projectLibrary/ProjectViewCard';
import { MuiThemeProviderComponent } from '../theme/ThemeContext';
import { PrimaryButton } from '../branded-elements/Elements';


const ProjectsGroup = ({
	projects = [],
	originalProjects,
	archived = false,
	setProjects,
	handleProjectNameChange,
}) => {
	const [lastIndex, setLastIndex] = useState(localStorage.getItem("lastIndex") || 12);
	const filteredProjects = projects?.filter((d) => d.isArchived === archived);

	const pathMap = {
		'clothing-sort-view': 'clothing-master-table/preLovedKids',
		'preloved-regular-view': 'clothing-master-table/pre-loved-regular',
		'vintage-apparel-view': 'clothing-master-table/vintage',
	};

	const finalPath = (description) => {
		if (description.isFormGenerated && pathMap[description.path]) { // isFormGenerated: means the project is coming from the forms
			return pathMap[description.path];
		}
		return description.path;
	};

	function setArchives(id) {
		setProjects(
			originalProjects?.map((d) =>
				d._id === id ? { ...d, isArchived: !d.isArchived } : d
			)
		);
	}

	let items;
	if (projects.length > 0 && projects[0].path === 'newView') {
		items = projects
			?.filter((d) => d.isArchived === archived)
			.slice(0, lastIndex)
			.map((description, index) => {
				const newPath = finalPath(description, 0);
				return (
					<ProjectViewCard
						handleProjectNameChange={handleProjectNameChange}
						key={description._id}
						customerName={description.customerName}
						id={description._id}
						path={newPath}
						date={description.dateCreated}
						name={
							description.projectName
								? description.projectName
								: description.formName
								? description.formName
								: description.batchName
								? description.batchName
								: null
						}
						image={
							description.path === 'clothing-sort-view' ||
							description.path === 'sorting-view' ||
							description.path === 'preloved-regular-view' ||
							description.path === 'vintage-apparel-view' ||
							description.path === 'new-apparel-view'
								? description.images
									? description.images
									: ''
								: description.imageUploaded
						}
						endpoint={description.endpoint}
						projectCSVGenerated={description.projectCSVGenerated}
						isArchived={archived}
						setArchives={setArchives}
					/>
				);
			});
	} else if (projects.length > 0 && projects[0].path === 'newTable') {
		items = projects
			?.filter((d) => d.isArchived === archived)
			.slice(0, lastIndex)
			.map((description, index) => {
				const newPath = finalPath(description, 0);

				<ProjectViewCard
					handleProjectNameChange={handleProjectNameChange}
					type={'Customer'}
					email={description.defaultEmail}
					phone={description.defaultPhoneNumber}
					unexportedCount={description.unexportedCount}
					key={description._id}
					id={description._id}
					path={newPath}
					date={description.dateCreated}
					name={
						description.projectName
							? description.projectName
							: description.formName
							? description.formName
							: description.batchName
							? description.batchName
							: null
					}
					image={
						description.path === 'clothing-sort-view' ||
						description.path === 'sorting-view' ||
						description.path === 'preloved-regular-view' ||
						description.path === 'vintage-apparel-view' ||
						description.path === 'new-apparel-view'
							? description.images
								? description.images
								: ''
							: description.imageUploaded
					}
					endpoint={description.endpoint}
					projectCSVGenerated={description.projectCSVGenerated}
					isArchived={archived}
					setArchives={setArchives}
				/>;
			});
	} else if (
		projects.length > 0 &&
		(projects[0].path === 'mm-coats' || projects[0].path === 'mm-sports')
	) {
		items = projects
			?.filter((d) => d.isArchived === archived)
			.slice(0, lastIndex)
			.map((description) => (
				<ProjectViewCard
					handleProjectNameChange={handleProjectNameChange}
					key={description._id}
					itemCount={
						description.s3Images ? description.s3Images.length : 0
					}
					id={description._id}
					path={description.path}
					date={description.dateCreated}
					name={
						description.projectName
							? description.projectName
							: description.formName
							? description.formName
							: description.batchName
							? description.batchName
							: null
					}
					image={description.s3Images}
					endpoint={description.endpoint}
					projectCSVGenerated={description.projectCSVGenerated}
					isArchived={archived}
					setArchives={setArchives}
					description={description}
				/>
			));
	} else if (
		projects.length > 0 &&
		projects[0].hasOwnProperty('isProcessed')
	) {
		// Theme View
		// ?.filter(d=>d.isArchived === archived)
		items = projects.slice(0, lastIndex).map((description, index) => {
			return (
				<ProjectViewCard
					handleProjectNameChange={handleProjectNameChange}
					key={description._id}
					id={description._id}
					path={'themes-view'}
					date={description.createdAt}
					name={description.projectTitle}
					image={description.images}
					description={description}
					endpoint={'themes-view'}
					projectCSVGenerated={description.isProcessed}
					isArchived={archived}
					setArchives={setArchives}
					index={index}
					itemCount={description.images.length}
				/>
			);
		});
	} 
	// Archived Items 
	else if(archived){
		items = projects
			?.filter((d) => d.isArchived)
			.slice(0, lastIndex)
			.map((description, index) => {
				const newPath = finalPath(description, 0);
				return (
					<ProjectViewCard
						handleProjectNameChange={handleProjectNameChange}
						key={description._id}
						itemCount={
							description.images ? description.images.length : 0
						}
						id={description._id}
						path={newPath}
						date={description.dateCreated}
						name={
							description.projectName
								? description.projectName
								: description.formName
								? description.formName
								: description.batchName
								? description.batchName
								: null
						}
						image={
							description.path === 'clothing-sort-view' ||
							description.path === 'sorting-view' ||
							description.path === 'preloved-regular-view' ||
							description.path === 'vintage-apparel-view' ||
							description.path === 'new-apparel-view'
								? description.images
									? description.images
									: ''
								: description.imageUploaded
						}
						endpoint={description.endpoint}
						projectCSVGenerated={description.projectCSVGenerated}
						isArchived={archived}
						setArchives={setArchives}
						projectCompleteStatus={description.projectCSVGenerated}
						sharedWith={description.sharedWith}
						userID={description.userID}
					/>
				);
			});

	} else {
		items = projects
			?.filter((d) => !d.isArchived)
			.slice(0, lastIndex)
			.map((description, index) => {
				const newPath = finalPath(description, 0);
				return (
					<ProjectViewCard
						handleProjectNameChange={handleProjectNameChange}
						key={description._id}
						itemCount={
							description.images ? description.images.length : 0
						}
						id={description._id}
						path={newPath}
						date={description.dateCreated}
						name={
							description.projectName
								? description.projectName
								: description.formName
								? description.formName
								: description.batchName
								? description.batchName
								: null
						}
						image={
							description.path === 'clothing-sort-view' ||
							description.path === 'sorting-view' ||
							description.path === 'preloved-regular-view' ||
							description.path === 'vintage-apparel-view' ||
							description.path === 'new-apparel-view'
								? description.images
									? description.images
									: ''
								: description.imageUploaded
						}
						endpoint={description.endpoint}
						projectCSVGenerated={description.projectCSVGenerated}
						isArchived={archived}
						setArchives={setArchives}
						projectCompleteStatus={description.projectCSVGenerated}
						sharedWith={description.sharedWith}
						userID={description.userID}
					/>
				);
			});
	}

	const handleShowMore = () => {
		const indexToSet = lastIndex + 12;
		
		// Update lastIndex state
		setLastIndex((last) => last + 12);
		
		// Save lastIndex to local storage
		localStorage.setItem('lastIndex', indexToSet);
	};
	

	const containerStyle = {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, 380px)',
		gap: 50,
		justifyContent: 'center',
	  };

	return (
		<>
			<MuiThemeProviderComponent>				
					<div style={containerStyle}>
						{items}
					</div>
				{filteredProjects?.length > 12 &&
				filteredProjects?.length > lastIndex ? (
					<div className='pt-20 flex justify-center'>
						<PrimaryButton
							title={'Load More'}
							onClick={handleShowMore}
						/>
					</div>
				) : null}
			</MuiThemeProviderComponent>
		</>
	);
};

export default ProjectsGroup;
