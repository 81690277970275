import { liningCoverage } from "./liningCoverage";

const lining = [
    "Covered",
    "Layered",
    "Inner",
    "Insulated",
    "Interior",
    "Padded",
    "Protected",
    "Shielded",
    "Buffered",
    "lining",
    "lined"
  ];
  
  const no_lining = [
    "Unlined",
    "Bare",
    "Uncovered",
    "Unprotected",
    "Sheer",
    "Light"
  ];

  const synonymsArray = [lining, no_lining, ...liningCoverage]

export const getLiningSynonyms = (lining) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => lining.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 