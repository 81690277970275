const button = [
    "Button",
    "Button-up",
    "Buttoned"
  ];
  
  const zipper = [
    "Zipper",
    "Zippered",
    "zip"
  ];
  
  const clasp = [
    "Hook",
    "Catch",
    "Buckle",
    "Snap",
    "Clip",
    "clasp"
  ];

  export const closureType = [button, zipper, clasp]