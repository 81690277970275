import React, { useContext, useState, Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router-dom';
import { useMenu } from './MenuContext';
import { getAuth } from '@firebase/auth';
import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Collapse,
} from '@mui/material';
import { useLocation } from 'react-router';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { createNewFormAndGetLink } from '../../utils/formsUtilFunctions';
import { OrganizationContext } from '../App';
import { generalMenuItems } from './generalMenuItems';
import { mmMenuItems } from './mmMenuItems';
import { MuiThemeProviderComponent } from '../theme/ThemeContext';
import { vendorMenuItems } from './vendorMenuItems';

const drawerWidth = 256;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: `-${drawerWidth}px`,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	})
);

const defaultMenuItems = [
	{
		name: 'Help Center',
		link: 'https://snapwriteai.notion.site/7887d056a20644ca85d372d2a449bcf8?v=4696b17a87574b799888ce41512dbff8',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M11.95 18C12.3 18 12.596 17.879 12.838 17.637C13.08 17.395 13.2007 17.0993 13.2 16.75C13.2 16.4 13.0793 16.104 12.838 15.862C12.5967 15.62 12.3007 15.4993 11.95 15.5C11.6 15.5 11.3043 15.621 11.063 15.863C10.8217 16.105 10.7007 16.4007 10.7 16.75C10.7 17.1 10.821 17.396 11.063 17.638C11.305 17.88 11.6007 18.0007 11.95 18ZM11.05 14.15H12.9C12.9 13.6 12.9627 13.1667 13.088 12.85C13.2133 12.5333 13.5673 12.1 14.15 11.55C14.5833 11.1167 14.925 10.704 15.175 10.312C15.425 9.92 15.55 9.44933 15.55 8.9C15.55 7.96667 15.2083 7.25 14.525 6.75C13.8417 6.25 13.0333 6 12.1 6C11.15 6 10.379 6.25 9.787 6.75C9.195 7.25 8.78267 7.85 8.55 8.55L10.2 9.2C10.2833 8.9 10.471 8.575 10.763 8.225C11.055 7.875 11.5007 7.7 12.1 7.7C12.6333 7.7 13.0333 7.846 13.3 8.138C13.5667 8.43 13.7 8.75067 13.7 9.1C13.7 9.43333 13.6 9.746 13.4 10.038C13.2 10.33 12.95 10.6007 12.65 10.85C11.9167 11.5 11.4667 11.9917 11.3 12.325C11.1333 12.6583 11.05 13.2667 11.05 14.15ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
	},
	{
		name: 'Newsletters',
		link: '/upload',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M5 21C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H16L21 8V19C21 19.55 20.804 20.021 20.412 20.413C20.02 20.805 19.5493 21.0007 19 21H5ZM7 17H17V15H7V17ZM7 13H17V11H7V13ZM15 9H19L15 5V9ZM7 9H12V7H7V9Z'
					fill='black'
					fill-opacity='0.6'
				/>
			</svg>
		),
	},
];

export default function SideMenu({ children }) {
	const { organization } = useContext(OrganizationContext);
	const [activeItemName, setActiveItemName] = useState([]);
	const userIDsWithVendorWorkFlow = ["EAXX2r4BxVVvwKVZXDcsyrrfsp33", "Rs6zT7PWzAcuaJ59C3WFSZY6e0D2"]

	let userID;
	let userName;
	let uid = organization?.id;
	
	const isMM =
		organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1' ||
		organization?.id === 'kDQArRBVOdXiTcGZT8PieKXqsUm2';
	let menuItems 

	if (isMM) {
		menuItems = mmMenuItems;
	} else if (organization?.accountType === 'seller') {
		menuItems = vendorMenuItems;
	} else if (userIDsWithVendorWorkFlow.includes(uid)) {
		menuItems = generalMenuItems;
	} else {
		menuItems = generalMenuItems.filter((obj) => obj.name !== 'Vendor');
	}
	

	const auth = getAuth();
	const user = auth.currentUser;

	if (user !== null) {
		userID = auth.currentUser.email;
		// userObjectID = 
		userName = auth.currentUser.displayName;
		uid = uid || auth.currentUser.uid;
	}

	const history = useHistory();
	const { isMenuOpen, selectedItem, setSelectedItem } = useMenu();

	// Create a new form and navigate to it
	const handleCreateForm = async () => {
		const link = await createNewFormAndGetLink(uid, userName);
		history.push(link);
	};
	const navigateTo = (link, selectedItemName) => () => {
		setSelectedItem(selectedItemName)
		if (link === '/NewView') {
			handleCreateForm();
			return;
		} else {
			history.push(link);
		}
	};

	// Get the current location to highlight the selected menu item
	const currentLocation = useLocation().pathname;

	const buttonProps = (value, name) => {
	const activeSelection =
		(name === selectedItem && currentLocation.includes(value)) ||
		(currentLocation === '/bulk-shopify' && value === '/bulk-shopify') ||  // Default Selection
		(currentLocation === '/mm-coats/new' && value === '/mm-coats/new'); // Default Selection
		return {
			selected: activeSelection,
		};
	};

	const handleClick = (value) => {
		if (activeItemName.includes(value.name)) {
			setActiveItemName(
				activeItemName.filter((item) => item !== value.name)
			);
		} else {
			setActiveItemName([...activeItemName, value.name]);
		}
	};

	const collapseNestedList = (value) => {
		let collapse = true;

		if (value.subItems) {
			for (let subItem of value.subItems) {
				if (currentLocation.includes(subItem.link)) {
					collapse = false;
					break;
				}
			}
		}

		return !collapse;
	};
	return (
		<>
			{/* Drawer  */}
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<MuiThemeProviderComponent>
					<Drawer
						sx={{
							width: drawerWidth,
							zIndex: (theme) => theme.zIndex.drawer - 1200,
							flexShrink: 0,
							'& .MuiDrawer-paper': {
								width: drawerWidth,
								height: 'calc(100% - 71px)',
								top: '71px',
								paddingTop: '20px',
								boxShadow:
									'0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
								padding:
									'var(--3, 24px) var(--1, 8px) var(--1, 8px) var(--1, 8px)',

								borderRight: 'none',

								boxSizing: 'border-box',
							},
						}}
						variant='persistent'
						anchor='left'
						open={isMenuOpen}
					>
						<List>
							{menuItems.map((item, index) =>
								item.hasOwnProperty('subItems') ? (
									<Fragment key={`MenuItem-${index}`} >
										<ListItemButton
											onClick={() => handleClick(item)}
										>
											<ListItemIcon>
												{item.icon}
											</ListItemIcon>
											<ListItemText primary={item.name} />
											{collapseNestedList(
												item,
												currentLocation
											) ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemButton>
										<Collapse
											in={
												collapseNestedList(
													item,
													currentLocation
												) ||
												activeItemName.includes(
													item.name
												)
											}
											timeout='auto'
											unmountOnExit
										>
											{item.subItems.map(
												(subItem, index) => (
													<List
														key={`${subItem.name}-${index}`}
														component='div'
														disablePadding
													>
														<ListItemButton
															{...buttonProps(
																subItem.link,
																subItem.name
															)}
															onClick={navigateTo(
																subItem.link, subItem.name
															)}
														>
															<ListItemText
																inset
																primary={
																	subItem.name
																}
															/>
														</ListItemButton>
													</List>
												)
											)}
										</Collapse>
									</Fragment>
								) : (
									// Handle the case where there are no subItems
									<ListItem key={item.name} disablePadding>
										<ListItemButton
											{...buttonProps(item.link, item.name)}
											onClick={navigateTo(item.link, item.name)}
										>
											<ListItemIcon>
												{item.icon}
											</ListItemIcon>
											<ListItemText>
												{item.name}
											</ListItemText>
										</ListItemButton>
									</ListItem>
								)
							)}
						</List>

						{/*  Default Menu Items  */}
						<Box sx={{ marginTop: 'auto' }}>
							<Divider />
							<List>
								{defaultMenuItems.map((item) =>
									item.name === 'Help Center' ? (
										<ListItem
											key={item.name}
											disablePadding
										>
											<ListItemButton
												onClick={() =>
													window.open(item.link)
												}
											>
												<ListItemIcon>
													{item.icon}
												</ListItemIcon>
												<ListItemText>
													{item.name}
												</ListItemText>
											</ListItemButton>
										</ListItem>
									) : (
										<ListItem
											key={item.name}
											disablePadding
										>
											<ListItemButton
												onClick={navigateTo(item.link, item.name)}
											>
												<ListItemIcon>
													{item.icon}
												</ListItemIcon>
												<ListItemText>
													{item.name}
												</ListItemText>
											</ListItemButton>
										</ListItem>
									)
								)}
							</List>
						</Box>
					</Drawer>
				</MuiThemeProviderComponent>
				{/* Main Content */}
				<Main
					id='main-section'
					sx={{
						maxHeight: 'calc(100vh - 70px)',
						height: 'calc(100vh - 70px)',
						overflow: 'auto',
					}}
					open={isMenuOpen}
				>
					{children}
				</Main>
			</Box>
		</>
	);
}
