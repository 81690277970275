const stateArrayDBassignment = (arrayReferenced, noOfRows) => {              
    let returnArrayDefault = []
    if (arrayReferenced && arrayReferenced.length === noOfRows) {
      returnArrayDefault = arrayReferenced
    } else if (arrayReferenced.length>0) {
      for (let dataIndex = 0; dataIndex < noOfRows; dataIndex++) {
        if (arrayReferenced.length > dataIndex && arrayReferenced[dataIndex]!==null) {
          returnArrayDefault.push(arrayReferenced[dataIndex])
        }
        else {
          returnArrayDefault.push("")
        }
      }
    } else {
      returnArrayDefault = Array(noOfRows).fill("")
    }
    return returnArrayDefault
  }

export default stateArrayDBassignment