import checkSymbol from '../../../../assets/material-symbols_check.png';
import uploadIcon from '../../../../assets/uil_image-upload.svg';
import snapWriteLogo from '../../../../assets/snapwrite-logo.png';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import checkSymbolGreen from '../../../../assets/material-symbols_check_green.svg';
import xSymbol from '../../../../assets/x_symbol.svg';
import crowdedBgImg from '../../../../assets/instructions/Crowded-BG.jpg';
import foldedItmImg from '../../../../assets/instructions/folded-items.jpg';
import goodExmplImg from '../../../../assets/instructions/good-example.jpeg';
import overExplImg from '../../../../assets/instructions/overexposed.jpeg';
import underExplImg from '../../../../assets/instructions/underexposed.jpg';
import { Heading1 } from '../../../../components/branded-elements/Elements';

const ImagesUploadContainer = ({ uploadImage, template, isParentMM }) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<section className=''>
			{/* Instructions Modal  */}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					id='instructionsModal'
					className='w-[585px] h-[600px] xl:h-[805px] absolute bg-white rounded-3xl border border-gray-200 mx-auto top-0 left-0 right-0 bottom-0 m-auto flex-col justify-start items-center gap-10 inline-flex overflow-auto'
				>
					<div className=' w-7 h-7 relative flex-col justify-start items-start flex' />
					<div
						className=' absolute right-5 top-3 text-gray-500 cursor-pointer'
						onClick={handleClose}
					>
						{' '}
						<CloseIcon />
					</div>

					<div className=' self-stretch flex-col justify-start items-center gap-10 inline-flex'>
						<img
							className=' w-44 h-16'
							src={snapWriteLogo}
							alt='snapwrite logo'
						/>
						<div className=" w-96 text-center text-zinc-800 text-3xl font-bold font-['Inter']">
							Apparel Image Guidelines
						</div>
						<div className=" w-96 text-center text-zinc-800 text-base font-normal font-['Inter']">
							Follow the tips below while uploading images of your
							apparel to get the best results.
						</div>
						<div className=' h-44 p-3 rounded-2xl border border-zinc-800'>
							<div className="justify-start items-center  text-zinc-800 text-base font-normal font-['Inter'] w-56">
								<div className=" text-zinc-800 text-base font-bold font-['Inter'] ">
									The Apparel Must Be
								</div>
								<ul className='mt-3 bull list-none'>
									<li className='my-1'>
										<img
											src={checkSymbol}
											alt='Check Mark'
											className='h-4'
										/>{' '}
										Centered
									</li>
									<li className='my-1'>
										<img
											src={checkSymbol}
											alt='Check Mark'
											className='h-4'
										/>{' '}
										Front-facing
									</li>
									<li className='my-1'>
										<img
											src={checkSymbol}
											alt='Check Mark'
											className='h-4'
										/>{' '}
										A Fashion Item
									</li>
								</ul>
							</div>
						</div>
						<div className='Line1 w-96 h-px border border-zinc-800'></div>
						<div className="GoodExample text-center text-zinc-800 text-base font-bold font-['Inter']">
							Good Example
						</div>
						<div className='Group3 w-72 h-80 relative'>
							<img
								className='Image19 w-72 h-80 left-0 top-0 absolute rounded-3xl'
								src={goodExmplImg}
								alt='Aparell'
							/>
							<img
								src={checkSymbolGreen}
								alt='Check'
								className=' w-11 h-11 left-[220.50px] top-[17px] absolute bg-white rounded-3xl border border-green-300 flex-col justify-start items-start inline-flex'
							/>
						</div>
						<div className="ThisApparelIsCenteredFrontFacingWithVeryLittleBackgroundNoise w-96 text-center text-zinc-800 text-base font-normal font-['Inter']">
							{' '}
							This apparel is centered, front-facing with very
							little background noise.
						</div>
						<div className='Line2 w-96 h-px border border-zinc-800'></div>
						<div className="PleaseAvoid text-center text-zinc-800 text-base font-bold font-['Inter']">
							Please Avoid
						</div>
						<div className='Frame18 justify-start items-start gap-5 inline-flex'>
							<div className='Frame16 flex-col justify-start items-start gap-4 inline-flex'>
								<div className='w-64 h-60 relative'>
									<img
										className='w-64 h-60 left-0 top-0 absolute rounded-3xl'
										src={crowdedBgImg}
										alt='Aparrel'
									/>
									<img
										src={xSymbol}
										alt='Check'
										className=' w-8 h-8 pl-0.5 pr-px pt-0.5 pb-px left-[201px] top-[16px] absolute bg-white rounded-3xl border border-rose-400 justify-center items-center inline-flex'
									/>
								</div>
								<div className="RotatedItem w-64 text-center text-zinc-800 text-base font-normal font-['Inter']">
									Crowded Background
								</div>
							</div>
							<div className='Frame17 flex-col justify-start items-start gap-4 inline-flex'>
								<div className='Group4 w-64 h-60 relative'>
									<img
										className='w-64 h-60 left-0 top-0 absolute rounded-3xl'
										src={foldedItmImg}
										alt='Apparel'
									/>
									<img
										src={xSymbol}
										alt='Check'
										className=' w-8 h-8 pl-0.5 pr-px pt-0.5 pb-px left-[201px] top-[16px] absolute bg-white rounded-3xl border border-rose-400 justify-center items-center inline-flex'
									/>
								</div>
								<div className="RotatedItem w-64 text-center text-zinc-800 text-base font-normal font-['Inter']">
									Folding Items
								</div>
							</div>
						</div>
						<div className='Frame19 justify-start items-start gap-5 inline-flex'>
							<div className='Frame16 flex-col justify-start items-start gap-4 inline-flex'>
								<div className='Group4 w-64 h-60 relative'>
									<img
										className='w-64 h-60 left-0 top-0 absolute rounded-3xl'
										src={overExplImg}
										alt='Apparel'
									/>
									<img
										src={xSymbol}
										alt='Check'
										className=' w-8 h-8 pl-0.5 pr-px pt-0.5 pb-px left-[201px] top-[16px] absolute bg-white rounded-3xl border border-rose-400 justify-center items-center inline-flex'
									/>
								</div>
								<div className="RotatedItem w-64 text-center text-zinc-800 text-base font-normal font-['Inter']">
									{'Overexposed (Too Bright)'}
								</div>
							</div>
							<div className='Frame17 flex-col justify-start items-start gap-4 inline-flex'>
								<div className='Group4 w-64 h-60 relative'>
									<img
										className='w-64 h-60 left-0 top-0 absolute rounded-3xl'
										src={underExplImg}
										alt='Apparel'
									/>
									<img
										src={xSymbol}
										alt='Check'
										className=' w-8 h-8 pl-0.5 pr-px pt-0.5 pb-px left-[201px] top-[16px] absolute bg-white rounded-3xl border border-rose-400 justify-center items-center inline-flex'
									/>
								</div>
								<div className="RotatedItem w-64 text-center text-zinc-800 text-base font-normal font-['Inter']">
									{'Underexposed (Too Dark)'}
								</div>
							</div>
						</div>
						<button
							className=" w-56 h-14 p-2.5 bg-[#e8fb0a] rounded-lg justify-center items-center gap-2.5 inline-flex mb-10 text-zinc-800 text-base font-normal font-['Inter'] border-none cursor-pointer"
							onClick={handleClose}
						>
							I UNDERSTAND
						</button>
					</div>
				</Box>
			</Modal>

			{/* Page Title  */}
			<div className='flex justify-center items-start mx-5'>
				<div className='w-full max-w-7xl'>
					<div className='pl-4'>
					{/* <p className="text-[#B2B8C3] text-sm font-semibold mb-1">Upload Apparel</p> */}
					<Heading1
						title={
							template === 'preLovedKids'
								? 'Pre-loved (Kids)'
								: template === 'pre-loved-regular'
								? 'Pre-loved (Regular)'
								: template === 'vintage'
								? 'Vintage'
								: template === 'new-clothes'
								? 'New'
								: template === 'mm-coats'
								? 'Coats and Jackets'
								: template === 'mm-sports'
								? 'Sports Wear'
								: null
						}
					/>
					</div>
					<div
						style={{
							width: '100%',
							height: '75vh',
							minHeight: 'max-content',
							padding: 43,
							background: 'white',
							boxShadow: '0px 6px 12px rgba(178, 184, 195, 0.20)',
							borderRadius: 30,
							overflow: 'hidden',
							border: '1px #EAECF0 solid',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 25,
							display: 'inline-flex',
						}}
					>
						{/* Upload Instructions  */}
						{!isParentMM && (
							<>
								<div
									style={{
										color: '#333333',
										fontSize: 16,
										fontFamily: 'Inter',
										wordWrap: 'break-word',
									}}
								>
									The Apparel Must Be
								</div>
								<div
									style={{
										paddingLeft: 30,
										paddingRight: 30,
										paddingTop: 16,
										paddingBottom: 16,
										borderRadius: 30,
										border: '1px #333333 solid',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										gap: 10,
										display: 'flex',
									}}
								>
									<div
										style={{
											alignSelf: 'stretch',
											justifyContent: 'center',
											alignItems: 'center',
											gap: 36,
											display: 'inline-flex',
										}}
									>
										<div
											style={{
												justifyContent: 'flex-start',
												alignItems: 'center',
												gap: 10,
												display: 'flex',
											}}
										>
											<div
												style={{
													width: 24,
													height: 24,
													position: 'relative',
													marginRight: 10,
												}}
											>
												<img
													src={checkSymbol}
													alt='Check Symbol'
													style={{
														width: 22,
														height: 22,
														left: 6,
														top: 2,
														position: 'absolute',
													}}
												/>
											</div>
											<div
												style={{
													color: '#333333',
													fontSize: 16,
													fontFamily: 'Inter',
													fontWeight: '400',
													wordWrap: 'break-word',
												}}
											>
												Centered
											</div>
										</div>

										<div
											style={{
												justifyContent: 'flex-start',
												alignItems: 'center',
												gap: 10,
												display: 'flex',
											}}
										>
											<div
												style={{
													width: 24,
													height: 24,
													position: 'relative',
													marginRight: 10,
												}}
											>
												<img
													src={checkSymbol}
													alt='Check Symbol'
													style={{
														width: 22,
														height: 22,
														left: 6,
														top: 2,
														position: 'absolute',
													}}
												/>
											</div>
											<div
												style={{
													color: '#333333',
													fontSize: 16,
													fontFamily: 'Inter',
													fontWeight: '400',
													wordWrap: 'break-word',
												}}
											>
												Front-Facing
											</div>
										</div>

										<div
											style={{
												justifyContent: 'flex-start',
												alignItems: 'center',
												gap: 10,
												display: 'flex',
											}}
										>
											<div
												style={{
													width: 24,
													height: 24,
													position: 'relative',
													marginRight: 10,
												}}
											>
												<img
													src={checkSymbol}
													alt='Check Symbol'
													style={{
														width: 22,
														height: 22,
														left: 6,
														top: 2,
														position: 'absolute',
													}}
												/>
											</div>
											<div
												style={{
													color: '#333333',
													fontSize: 16,
													fontFamily: 'Inter',
													fontWeight: '400',
													wordWrap: 'break-word',
												}}
											>
												Fashion Item
											</div>
										</div>
									</div>
								</div>

								{/* Learn More - Link to Modal  */}
								<Button
									sx={{
										textAlign: 'center',
										color: '#1976D2',
										fontSize: 16,
										fontFamily: 'Inter',
										fontWeight: '400',
										wordWrap: 'break-word',
										textTransform: 'capitalize',
										textDecoration: 'none',
									}}
									onClick={handleOpen}
								>
									Learn More
								</Button>
							</>
						)}

						{/* Image Upload Section  */}
						<label
							className=' bg-[#f5f6f8] cursor-pointer'
							htmlFor='myFile'
							style={{
								alignSelf: 'stretch',
								flex: '1 1 0',
								paddingLeft: 30,
								paddingRight: 30,
								paddingTop: 16,
								paddingBottom: 16,
								borderRadius: 30,
								border: '1px #B2B8C3 dotted',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								gap: 20,
								display: 'flex',
							}}
						>
							<div
								style={{
									width: 132,
									height: 132,
									position: 'relative',
								}}
							>
								<img
									src={uploadIcon}
									alt='Upload Icon'
									style={{
										width: 115.53,
										height: 115.47,
										left: 11,
										top: 5.53,
										position: 'absolute',
									}}
								/>
							</div>
							<div
								style={{
									textAlign: 'center',
									color: '#333333',
									fontSize: 30,
									fontFamily: 'Inter',
									fontWeight: '400',
									wordWrap: 'break-word',
								}}
							>
								Select Images to Upload
							</div>
							<div
								style={{
									textAlign: 'center',
									color: '#B2B8C3',
									fontSize: 16,
									fontFamily: 'Inter',
									fontWeight: '400',
									wordWrap: 'break-word',
								}}
							>
								or Drag and Drop Files
							</div>
						</label>
						<input
							multiple
							type='file'
							name='myFile'
							id='myFile'
							className='drop-zone__input'
							accept='image/*'
							onChange={(e) => uploadImage(e, 'upload')}
						/>
					</div>
				</div>
			</div>
			{/* Upload Container  */}
		</section>
	);
};

export default ImagesUploadContainer;
