import React, {useState} from 'react'
import { Button } from '@mui/material';
import { useHistory } from "react-router";
import folderImg from "../../assets/folder.png"
import axios from 'axios'
import TrashCan from "../../assets/trash_can.png"




const FormList = (props) => {

    const filteredForms = props.forms.filter(form => form.isArchived === props.showArchived).reverse()
    
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * 7;
    const endIndex = startIndex + 7;
    const limitedData = filteredForms.slice(startIndex, endIndex);

    const goToNextPage = () => {
        const nextPage = currentPage + 1;
        const totalPages = Math.ceil(filteredForms.length / 7);
    
        if (nextPage <= totalPages) {
          setCurrentPage(nextPage);
        }
      };
    
      const goToPreviousPage = () => {
        const previousPage = currentPage - 1;
    
        if (previousPage >= 1) {
          setCurrentPage(previousPage);
        }
      };

    const history = useHistory();



    const calcTotalPrice = (prices) => {
        let total = 0
        prices.forEach((price) => {
            if (isNumeric(price)) {
                total += parseFloat(price)
            }
        })
        return total
    }

    function isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
      }

    const handleArchive = (id, endpoint, isArchived, isExported) => {
        let requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, isArchived: isArchived ? "false" : "true" })
            };

        fetch(`https://apie.snapwrite.ca/storeData/${endpoint}-isArchivedUpdate`, requestOptions)
		    .then(response => response.json())
        props.setArchives(id)

        const count = isExported ? 0 : (isArchived ? 1 : -1)

        axios.put("https://apie.snapwrite.ca/storeData/apparel-input-batch-updateVal", {name: "unexportedCount", val: count, id: props.batch})
        
    }

    
  return (
    <section>

        <table className='w-full'>
          <thead>
            <tr className='font-bold text-xl'>
              <td className='py-2'>Form Name</td>
              <td>Description</td>
              <td>Item Count</td>
              <td>Total Value</td>
              <td>Date Created</td>
              <td>Status</td>
              <td></td>
              </tr>
          </thead>
          <tbody className=''>

          {limitedData.length > 0 ? limitedData.map((form) => {
            return (
              <tr key={form._id} style={{ cursor: "pointer", backgroundColor: form.isExported && "#cfffdb", color: form.isExported && "green" }}>                
                <td className='py-4 pl-2' onClick={() => history.push(`../newView/${form._id}`)}>{form.formName}</td>
                <td onClick={() => history.push(`../newView/${form._id}`)}>{form.receptionDescription}</td>
                <td onClick={() => history.push(`../newView/${form._id}`)}>{form.itemName.length}</td>
                <td onClick={() => history.push(`../newView/${form._id}`)}>{"$" + calcTotalPrice(form.price).toFixed(2)}</td>
                <td onClick={() => history.push(`../newView/${form._id}`)}>{new Date(form.dateCreated).toLocaleDateString('en-CA')}</td>
                <td onClick={() => history.push(`../newView/${form._id}`)}>{form.isExported ? "Exported" : "Not Exported"}</td>
                <td onClick={() => handleArchive(form._id, form.endpoint, form.isArchived, form.isExported)}><div style={{ display: "flex", gap: "6px", alignItems: "center" }} ><p style={{ margin: 0 }}>{props.showArchived ? "Restore" : ""}</p><img style={{ width: "1.5rem" }} src={props.showArchived ? folderImg : TrashCan} /></div></td>
              </tr>
            )
          }
        ): <tr>
          <td className='pt-96 text-center text-slate-500 text-xl cursor-auto' colSpan={7}>No Forms Found</td>
          
          </tr>}


          </tbody>
        </table>

    <br />
    {filteredForms.length > 7 && <div style = {{display: "flex", justifyContent: "center"}}>
    <Button onClick = {goToPreviousPage}>Prev</Button>
    <Button onClick = {goToNextPage}>Next</Button>
    </div>}
    <br></br>

    
    </section>
  )
}

export default FormList