import menu from '../assets/store1.png';
import store2 from '../assets/store2.png';
import projects from '../assets/folder.png';
import description from '../assets/description.png';
import altText from '../assets/altText.png';

import cart from '../assets/cart.png';

import { NavLink, Link } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { getAuth } from '@firebase/auth';

import axios from 'axios';
import PlusIcon from '../assets/PlusIcon.png';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, CssBaseline, Drawer } from '@mui/material';
import { OrganizationContext } from './App';
import folderKanban from '../assets/icons/folder-kanban.svg';
import medal from '../assets/icons/medal.svg';
import shirt from '../assets/icons/shirt.svg';
import {createNewFormAndGetLink} from '../utils/formsUtilFunctions';

// MUI Drawer imports
import React from 'react';

// Side Menu Component
export const SideMenu = (props) => {
	const { organization, setOrganization } = useContext(OrganizationContext);
	const [storeName, setStoreName] = useState('');
	const [isHover, setIsHover] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const isMM = organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1';

	const history = useHistory();

	let userID;
	let userName;
	let uid = organization?.id;
	const preference = localStorage.getItem('preference');
	const accountType = localStorage.getItem('accountType');

	const auth = getAuth();
	const user = auth.currentUser;

	if (user !== null) {
		userID = auth.currentUser.email;
		userName = auth.currentUser.displayName;
		uid = uid || auth.currentUser.uid;
	}

	useEffect(() => {
		const store = JSON.parse(localStorage.getItem('store'));
		if (store !== null && storeName === '') {
			setStoreName(store);
		}

		if (props.closed !== undefined && props.closed) {
			onCollapse();
		}
	}, []);

	useEffect(() => {
		localStorage.setItem('store', JSON.stringify(storeName));
	}, [storeName]);

	const onHover = () => {
		setIsHover(true);
	};

	const onHoverOut = () => {
		setIsHover(false);
	};

	function onCollapse() {
		setIsOpen(!isOpen);

		if (
			document.getElementsByClassName('p-2 bd-highlight  snap-text') !==
			undefined
		) {
			const logo = document.getElementsByClassName(
				'p-2 bd-highlight  snap-text'
			);
			if (!isOpen) {
				logo[0].style.visibility = 'visible';
			} else {
				logo[0].style.visibility = 'hidden';
			}
		}
		if (document.getElementsByClassName('projectNameInput').length > 0) {
			const searchField =
				document.getElementsByClassName('projectNameInput');
			if (!isOpen) {
				searchField[0].style.left = '295px';
			} else {
				searchField[0].style.left = '120px';
			}
		}
	}

	const handleCreateForm = async () => {
		const link = await createNewFormAndGetLink(uid, userName);
		history.push(link);
	}


	if (
		userID === 'manteauxmanteauxdemo@snapwrite.ca' ||
		userID === 'carmela@manteaux.com' ||
		isMM
	) {
		return (
			<div className={` menu ${isOpen ? '' : 'collapsedMenu'}`}>
				<div
					className={` container-fluid ${
						!isOpen && isHover
							? 'collapseHover'
							: !isOpen && !isHover
							? 'collapseSidebar'
							: ''
					} `}
				>
					<div className='row'>
						<div className='col-lg-2 mr-2 '>
							<div
								id='sidebar'
								className={`sidebar ${
									!isOpen && isHover
										? 'collapseHover'
										: !isOpen && !isHover
										? 'collapseSidebar'
										: ''
								} `}
							>
								<div
									className='d-flex flex-row bd-highlight mb-3'
									onClick={() => onCollapse()}
								>
									<div className='p-2 bd-highlight mt-3'>
										<img
											src={menu}
											className='ml-3 '
											alt='Found '
											width={'20px'}
										/>
									</div>
								</div>
								<div className='d-flex flex-row bd-highlight mb-3'>
									<div className='p-2 bd-highlight mt-3'>
										<img
											src={folderKanban}
											className='ml-3 '
											alt='Found '
											width='20px'
										/>
									</div>
									<div className='p-2 bd-highlight mt-3'>
										<NavLink
											className='menuOption'
											to='/projects'
											activeClassName='active'
										>
											{isOpen || isHover
												? 'Projects'
												: ''}
										</NavLink>
									</div>
								</div>
								<div className='d-flex flex-row bd-highlight mb-3'>
									<div className='p-2 bd-highlight mt-3'>
										<img
											src={shirt}
											className='ml-3 '
											alt='Found '
											width='20px'
										/>
									</div>
									<div className='p-2 bd-highlight mt-3'>
										<NavLink
											className='menuOption'
											to='/mm-coats/new'
											activeClassName='active'
										>
											{isOpen || isHover
												? 'Coats and Jackets'
												: ''}
										</NavLink>
									</div>
								</div>
								<div className='d-flex flex-row bd-highlight mb-3'>
									<div className='p-2 bd-highlight mt-3'>
										<img
											src={medal}
											className='ml-3 '
											alt='Found '
											width='20px'
										/>
									</div>
									<div className='p-2 bd-highlight mt-3'>
										<NavLink
											className='menuOption'
											to='/mm-sports/new'
											activeClassName='active'
										>
											{isOpen || isHover
												? 'SportsWear Collection'
												: ''}
										</NavLink>
									</div>
								</div>
								{isOpen || isHover ? (
									<div
										className='cornerCredentials'
										style={{
											position: 'fixed',
											bottom: '0px',
											width: '240px',
										}}
									>
										<hr style={{ marginTop: '100px' }} />
										<div style={{ textAlign: 'center' }}>
											<p
												style={{
													fontFamily: 'Inter',
													color: 'grey',
													fontSize: '13px',
												}}
											>
												{userName}
											</p>

											<p
												style={{
													fontFamily: 'Inter',
													color: 'grey',
													fontSize: '13px',
												}}
											>
												{userID}
											</p>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={` menu ${isOpen ? '' : 'collapsedMenu'}`}>
			<div
				className={` container-fluid ${
					!isOpen && isHover
						? 'collapseHover'
						: !isOpen && !isHover
						? 'collapseSidebar'
						: ''
				} `}
			>
				<div className='row'>
					<div className='col-lg-2 mr-2 min-h-[94vh]'>
						<div
							id='sidebar'
							className={`sidebar ${
								!isOpen && isHover
									? 'collapseHover'
									: !isOpen && !isHover
									? 'collapseSidebar'
									: ''
							} `}
						>
							<div
								className='d-flex flex-row bd-highlight mb-3'
								onClick={() => onCollapse()}
							>
								<div className='p-2 bd-highlight mt-3'>
									<img
										src={menu}
										className='ml-3 '
										alt='Found '
										width={'20px'}
									/>
								</div>
							</div>
							{/* menu sub heading */}
							{accountType !== 'seller' ? (
								<div
									className='subheading'
									onMouseOver={() => onHover()}
									onMouseOut={() => onHoverOut()}
								>
									{!isMM && (
										<>
                    <div className="d-flex flex-row bd-highlight mb-3">
                      <div className="p-2 bd-highlight mt-3">
                        <img
                          src={projects}
                          className="ml-3 "
                          alt="Found "
                          width="20px"
                        />
                      </div>
                      <div className="p-2 bd-highlight mt-3">
                        <div className="menuOption" activeClassName="active">{(isOpen || isHover) ? 'Inventory Sources' :'' }</div>
                      </div>
                    </div> 
                     
                  <div className="subSub">
                    <div className="p-2 bd-highlight mt-3" style = {{display: "flex", gap: "0.3rem", alignItems: "center"}}>
                      {(isOpen || isHover) && <Avatar sx={{ width: 13, height: 13}} onClick = {() => history.push("./BatchAggregator")}  />}
                      <NavLink to = "/BatchAggregator" style = {{cursor: "pointer"}} className="menuOption"  activeClassName="active">{(isOpen || isHover) ? 'Customers' :'' }</NavLink>
                      
                    </div>
                  </div>  
                  {/* <div className="subSub">
												<div className="p-2 bd-highlight mt-3 mb-3" style = {{display: "flex", gap: "0.3rem", alignItems: "center"}}>
                          {(isOpen || isHover) && <Avatar sx={{ width: 13, height: 13}} onClick = {() => history.push("./vendor")}  />}
													<NavLink className="menuOption" to="/vendor" activeClassName="active">{(isOpen || isHover) ? 'Vendors' :'' }</NavLink>
												</div>
											</div> */}
                      <div className="subSub">
                    <div className="p-2 bd-highlight mt-3" style = {{display: "flex", gap: "0.3rem", alignItems: "center"}}>
                      {(isOpen || isHover) && <img onClick = {handleCreateForm} src = {PlusIcon} style = {{width: "0.6rem", height: "0.6rem"}} />}
                      <div onClick = {handleCreateForm} style = {{cursor: "pointer"}} className="menuOption"  activeClassName="active">{(isOpen || isHover) ? 'Create Form' :'' }</div>
                    </div>
                  </div>  
										</>
										)}

                    
                    <div className="ml-2 my-10 flex flex-col gap-5">
                      {/* Project  */}
                      <div className="flex gap-5"> 
                        <img
                          src={projects}
                          className="ml-3 "
                          alt="Found "
                          width="20px"
                        />
                        <NavLink className="menuOption" to="/projects" activeClassName="active">{(isOpen || isHover) ? 'Projects' :'' }</NavLink>
                        </div>
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to={`/projects-shared`} activeClassName="active">{(isOpen || isHover) ? 'Shared with me' :'' }</NavLink>
                          </div>
                        </div>    
                        {/* Theme  */}
                        <div className="flex gap-5"> 
                        <img
                          src={projects}
                          className="ml-3 "
                          alt="Found "
                          width="20px"
                        />
                        <NavLink className="menuOption" to="/themes" activeClassName="active">{(isOpen || isHover) ? 'Themes' :'' }</NavLink>
                        </div>
                    </div>
                  
                    
                    
                    
										{!isMM && 

                    <div className="d-flex flex-row bd-highlight mb-3 bulk-menu">
                      <div className="p-2 bd-highlight mt-3">
                        <img
                          src={cart}
                          className="ml-3 "
                          alt="Found "
                          width="21px"
                        />
                      </div>
                      <div className="p-2 bd-highlight mt-3">
                        <div className="menuOption">{(isOpen || isHover) ? 'Apparel' :'' }</div>
                      </div>
                    </div>
										}
                    {isMM ?
											<>
											</>
										:
										 (preference === "vintage" || (userID==='cherrypickdummy@snapwritetest.ca' || userID==='classiquegrails@gmail.com' || userID === 'hello@shopcherry.ca' || userID === 'impeccablevintage@gmail.com')) ?
                      <>
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/vintage" activeClassName="active">{(isOpen || isHover) ? 'Vintage' :'' }</NavLink>
                          </div>
                        </div>  
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/bulk-shopify" activeClassName="active">{(isOpen || isHover) ? 'Pre-loved (kids)' :'' }</NavLink>
                          </div>
                        </div>
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/preloved-regular" activeClassName="active">{(isOpen || isHover) ? 'Pre-loved (regular)' :'' }</NavLink>
                          </div>
                        </div>                        
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/new" activeClassName="active">{(isOpen || isHover) ? 'New' :'' }</NavLink>
                          </div>
                  </div> 
                      </>
                      :
                      <>
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/bulk-shopify" activeClassName="active">{(isOpen || isHover) ? 'Pre-loved (kids)' :'' }</NavLink>
                          </div>
                        </div>
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/preloved-regular" activeClassName="active">{(isOpen || isHover) ? 'Pre-loved (regular)' :'' }</NavLink>
                          </div>
                        </div>
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/vintage" activeClassName="active">{(isOpen || isHover) ? 'Vintage' :'' }</NavLink>
                          </div>
                        </div>   
                        <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/new" activeClassName="active">{(isOpen || isHover) ? 'New' :'' }</NavLink>
                          </div>
                  </div>   
                      </>}
											{!isMM &&
                      <div className="d-flex flex-row bd-highlight mb-3" style={{marginTop: "-12px"}}>
                        <div className="p-2 bd-highlight mt-3">
                          <img
                            src='https://img.icons8.com/ios/50/A3ACB9/combo-chart--v1.png'
                            className="ml-3 "
                            alt="anlytics"
                            width="18px"
                          />
                        </div>
                        <div className="p-2 bd-highlight mt-3">
                          <div className="menuOption">{(isOpen || isHover) ? 'Analytics' :'' }</div>
                        </div>
                      </div>
											}

											{/***** Analytics *****/}
											{!isMM &&
                      <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/analytics" activeClassName="active">{(isOpen || isHover) ? 'Items' :'' }</NavLink>
                          </div>
                      </div>                      
											}
                      {/***** Brand Aggregator *****/}
											{!isMM &&
                      <div className="subSub">
                          <div className="p-2 bd-highlight mt-3">
                            <NavLink className="menuOption" to="/brand-aggregator" activeClassName="active">{(isOpen || isHover) ? 'Brands' :'' }</NavLink>
                          </div>
                      </div>       
											}
                    {!isMM &&
                      (userID==="aryamanrastogi01@gmail.com" || userID ==="athiya.rastogi@gmail.com") ?
                      <>
                      <div className="d-flex flex-row bd-highlight mb-3 productDescMenu">
                      <div className="p-2 bd-highlight mt-3">
                        <img
                          src={description}
                          className="ml-3 "
                          alt="Found "
                          width="20px"
                        />
                      </div>
                      <div className="p-2 bd-highlight mt-3">
                        <Link className="menuOption" to="/dashboard" >{(isOpen || isHover) ? 'Product Description' :'' }</Link>
                      </div>
                    </div>
                    <div className="subSub">
                      <div className="p-2 bd-highlight mt-3">
                        <NavLink className="menuOption" to="/dashboard" activeClassName="active" >{(isOpen || isHover) ? 'Standard' :'' }</NavLink>
                      </div>
                    </div>
                        <div className="d-flex flex-row bd-highlight mb-3 alt-text">
                      <div className="p-2 bd-highlight mt-3">
                        <img
                          src={altText}
                          className="ml-3 "
                          alt="Found "
                          width="20px"
                        />
                      </div>
                       
                      <div className="p-2 bd-highlight mt-3">
                        <div className="menuOption">{(isOpen || isHover) ? 'Demo' :'' }</div>
                      </div>
                    </div> 
                    <div className="subSub">
                      <div className="p-2 bd-highlight mt-3">
                        <NavLink className="menuOption" to="/apparel" activeClassName="active">{(isOpen || isHover) ? 'Apparel' :'' }</NavLink>
                      </div>
                    </div>                                  
                    <div className="subSub">
                      <div className="p-2 bd-highlight mt-3">
                        <NavLink className="menuOption" to="/bags" activeClassName="active">{(isOpen || isHover) ? 'Bags' :'' }</NavLink>
                      </div>
                    </div> 
                     <div className="subSub">
                      <div className="p-2 bd-highlight mt-3">
                        <NavLink className="menuOption" to="/sorting" activeClassName="active">{(isOpen || isHover) ? 'Label Categorization' :'' }</NavLink>
                      </div>
                    </div>    
                      </>: null
                    } 
                                                                       
                  </div> ): 
				  <div style = {{height: "85vh"}}>
                              <div className="d-flex flex-row bd-highlight mb-3">
                                  <div className="p-2 bd-highlight mt-3">
                                    <img
                                      src={projects}
                                      className="ml-3 "
                                      alt="Found "
                                      width="20px"
                                    />
                                  </div>
                                  <div className="p-2 bd-highlight mt-3">
                                    <NavLink className="menuOption" to="/projects" activeClassName="active">{(isOpen || isHover) ? 'Projects' :'' }</NavLink>
                                  </div>
                                </div>
                    </div>}
                    <p className=" mt-72 md:landscape:mt-32 text-center text-sm bg-slate-600 py-2 rounded-3xl mx-10">
                      
                      <a href="https://snapwriteai.notion.site/7887d056a20644ca85d372d2a449bcf8?v=4696b17a87574b799888ce41512dbff8" target="_blank" rel="noreferrer" className="text-white flex items-center justify-center gap-2" > Help Center </a>
                    </p>
                  {/* <FeedbackPopUp/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
    )};

export default SideMenu;

// ************************* MUI Drawer Component *********************
