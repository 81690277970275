import React from 'react';
import { Header } from '../navigation/header';
import SideMenu from '../navigation/SideMenu';

export default function MainSideMenuHeaderContainer({
	children,
	reset,
	name,
	updateProjectName,
	setProjectName,
	projectName,
}) {
	return (
		<section>
			<Header
				reset={reset}
				name={name}
				updateProjectName={updateProjectName}
				setProjectName={setProjectName}
				projectName={projectName}
			/>
			<SideMenu>{children}</SideMenu>
		</section>
	);
}
