const TagSize = (size) => {
    let sizeTag = [""];
    if (size !== undefined) {
      const replaced = size.match(/\d+/g);
      let num = [];
  
      // clothing
      if (size.toLocaleLowerCase().includes("m")) {
        if (replaced !== null && replaced.length > 1) {
          num.push(Number(replaced[0]));
          num.push(Number(replaced[1]));
  
          if (num[1] < 3 || num[1] === 3) {
            sizeTag = ["0-3m"];
          } else if (num[0] > 2 && num[1] < 7) {
            sizeTag = ["3-6m"];
          } else if (num[0] < 3 && num[1] < 7) {
            sizeTag = ["0-3m", "3-6m"];
          } else if (num[0] > 5 && num[1] < 13) {
            sizeTag = ["6-12m"];
          } else if (num[0] < 6 && num[1] < 13) {
            sizeTag = ["3-6m", "6-12m"];
          } else if (num[0] > 11 && num[1] < 25) {
            sizeTag = ["12-24m"];
          } else if (num[0] < 11 && num[1] < 25) {
            sizeTag = ["6-12m", "12-24m"];
          }
        } else if (replaced !== null && replaced.length === 1) {
          num = Number(replaced[0]);
          if (num < 3) {
            sizeTag = ["0-3m"];
          } else if (num === 3 || num < 3) {
            sizeTag = ["0-3m", "0-6m"];
          } else if (num < 6) {
            sizeTag = ["0-6 Months"];
          } else if (num === 6) {
            sizeTag = ["0-6 Months", "6-12 Months"];
          } else if (num > 6 && num < 12) {
            sizeTag = ["6-12 Months"];
          } else if (num === 12) {
            sizeTag = ["6-12 Months", "12-18 Months"];
          } else if (num > 11 && num < 18) {
            sizeTag = ["12-18 Months"];
          } else if (num === 18) {
            sizeTag = ["12-18 Months", "18-24 Months"];
          } else if (num > 18 && num < 25) {
            sizeTag = ["18-24M"];
          }
        }
      } else if (size.toLocaleLowerCase().includes("t")) {
        sizeTag = [`2/3`];
      } else if (size.toLocaleLowerCase().includes("y")) {
        sizeTag = [`${size}`];
        if (replaced && replaced.length > 1) {
          num = [];
          num.push(Number(replaced[0]));
          num.push(Number(replaced[1]));
          if (num[0] < 4) {
            if (num[0] < 2) {
              sizeTag = ["12-24m"];
            } else {
              sizeTag = ["2/3"];
            }
          } else if (num[0] > 3 && num[1] < 6) {
            sizeTag = ["4/5"];
          } else if (num[0] > 5 && num[1] < 8) {
            sizeTag = [`6/7`];
          } else if (num[0] > 7 && num[1] < 11) {
            sizeTag = [`8/10`];
          } else if (num[0] > 10 && num[1] < 14) {
            sizeTag = [`11/14`];
          }
        } else {
          if (size < 4) {
            if (size < 2) {
              sizeTag = ["12-24m"];
            } else {
              sizeTag = ["2/3"];
            }
          } else if (size > 3 && size < 6) {
            sizeTag = ["4/5"];
          } else if (size > 5 && size < 8) {
            sizeTag = ["6/7"];
          } else if (size > 7 && size < 11) {
            sizeTag = ["8/10"];
          } else if (size > 10 && size < 14) {
            sizeTag = ["11/14"];
          }
        }
      }
    }
  
    return sizeTag;
  };
  
  export default TagSize;
  