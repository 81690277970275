import React, { useState, useEffect } from 'react'
import '../../css/customTable.css'
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { FormatTextdirectionLToRTwoTone } from '@mui/icons-material';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Header } from '../navigation/header';
import SideMenu from '../sideMenu';
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Switch from "@mui/material/Switch"



function convertCase(input) {

  if (input === "itemGrams") {
    return "Weight"
  }
  if (input === "costPerItem") {
    return "Cost"
  }

  if (input === "retailPrice") {
    return "Price"
  }

  // Use regular expression to insert space before each uppercase letter
  const result = input.replace(/([A-Z])/g, ' $1');

  // Capitalize the first character
  return result.charAt(0).toUpperCase() + result.slice(1);
}


function CheckBox({data, setData}) {

	const handleCheckboxChange = (name, isChecked) => {
		setData(prevData => ({
		  ...prevData,
		  [name]: isChecked
		}));
	  };

	const toSkip = ["types", "Additional Info", "fileNames", "itemGenericType", "userID", "attributes", "measurements", "_id", "__v", "objectID", "isArchived", "projectName", "taxonomyUpdatedType", "dateCreated", "variantGrams", "itemName", "itemType"]

	
	const attributes = ["layers", "lining", "pattern", "rise", "toe", "style", "era", "madeIn", "skirt", "length", "design", "material","sleeves", "cut", "fasteningType", "neckline", "heelHeight", "heelShape", "height"]
	const measurements = ["waistMeasure", "riseMeasure", "thighMeasure", "legOpening", "hipMeasure", "bottomInSeam", "lengthMeasure", "pitToPitMeasure", "sleeveInSeam", "shoulderMeasure", ""]

	const checkBoxes2 = Object.entries(data).map(([name, value]) => {
		if (toSkip.includes(name) || attributes.includes(name) || measurements.includes(name)) {
			return null;
		}
		return (
		<div key={name}>
      <FormControlLabel style = {{paddingRight: "15px",
                                    border: "solid 1px grey",
                                    alignItems: "center",
                                    boxShadow: "2px 2px 1px lightgrey"}} control={<Checkbox id={name} name={name} checked={value} onChange={(e) => handleCheckboxChange(name, e.target.checked)} />} label={convertCase(name)}/>
		</div>
    )
		})

		const attributeCheckBoxes = Object.entries(data).map(([name, value]) => {
			if (toSkip.includes(name) || !(attributes.includes(name))) {
				return null;
			}
			return (
			<div key={name}>
        <FormControlLabel style = {{paddingRight: "15px",
                                    border: "solid 1px grey",
                                    alignItems: "center",
                                    boxShadow: "2px 2px 1px lightgrey"}} 
                                    control={<Checkbox id={name} 
                                    disabled={!(data.attributes)} 
                                    name={name} checked={value} 
                                    onChange={(e) => handleCheckboxChange(name, e.target.checked)} />} label={convertCase(name)}/>
				</div>)
			})

			const measurementCheckBoxes = Object.entries(data).map(([name, value]) => {
				if (toSkip.includes(name) || !(measurements.includes(name))) {
					return null;
				}
				return (
				<div key={name}>
					        <FormControlLabel style = {{paddingRight: "15px",
                                    border: "solid 1px grey",
                                    alignItems: "center",
                                    boxShadow: "2px 2px 1px lightgrey"}}  control={<Checkbox id={name} disabled={!(data.measurements)} name={name} checked={value} onChange={(e) => handleCheckboxChange(name, e.target.checked)} />} label={convertCase(name)}/>
					</div>)
				})

		const attributesBox = (
      <>
      <div style = {{display: "flex", justifyContent: "center"}}>
				<div style = {{width: "90%", marginBottom: "5px"}} key={"attributes"}>
          <FormControlLabel control={<Switch id = "attributes" name = "attributes" checked = {data.attributes} onChange={(e) => handleCheckboxChange("attributes", e.target.checked)} />} label="Attributes Column" />
			
				
        </div>
      </div>
      </>
      

			)

		const measurementsBox = (
      <>
      <div style = {{display: "flex", justifyContent: "center"}}>
			<div style = {{width: "90%", marginBottom: "5px"}} key={"measurements"}>
        <FormControlLabel  control={<Switch id = "measurements" name = "measurements" checked = {data.measurements} onChange={(e) => handleCheckboxChange("measurements", e.target.checked)} />} label="Measurements Column" />
				
      </div>
      </div>
      </>
			)
	/*
	const checkBoxes = items?.map((item, index) => 
		
		<div key={item}>
			<input 
				id={item} 
				name={item.split(' ').map((d, i) => (i === 0) ? d.toLowerCase() : d.charAt(0).toUpperCase() + d.slice(1)).join('')} // Convert to camelcase
				type="checkbox" 
				tabIndex={7} 
				checked={checked[index]} 
				value={checked[index]}
				onChange={()=>{setChecked(checked.map((d, ind) => ind === index ? !d : d )); }}/>
			<label htmlFor={item + index}>{item}</label> <br />          
		 </div>
		// </div>
		)
	*/

		return (
			<div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>
				<div style = {{display: "flex", width: "90%", flexWrap: "wrap", justifyContent: "left"}}>{checkBoxes2}</div>
        <br />
        <br />
				<div style = {{display: "flex", flexDirection: "column", gap: "10px"}}>
					{attributesBox}
					<div style = {{display: "flex", justifyContent: "center"}}>
						<div style = {{display: "flex", width: "90%", flexWrap: "wrap"}}>
						{attributeCheckBoxes}
						</div>
					</div>
          <br />
        <br />
					
				</div>
				<div style = {{display: "flex", flexDirection: "column", gap: "10px"}}>
					{measurementsBox}
					<div style = {{display: "flex", justifyContent: "center"}}>
						<div style = {{display: "flex", width: "90%", flexWrap: "wrap"}}>
						{measurementCheckBoxes}
						</div>
					</div>
					
				</div>
				
			</div>
		)
}

function CustomTable(){
	const defaultProductTypes = ['Tops', 'Bottoms', 'Outerwear', 'Dresses', 'Rompers'];
	const defaultConditions = ['Good used Condition', 'Excellent Used Condition', 'Play Condition', 'Brand New with Tags', 'New without Tags', 'Like New'];
	const defaultTableColumns = ['Item Name','Item Type','Brand','Size','Gender','Color','Condition','Price','Additional Information','Cost Per Item','Vendor','Retail price','Item Grams','Project Name','Images','Types',
'Type of Taxonomy','Description','Cost','Price','File Name','Date Created','Archived','Era','Material','Neckline','Style','Sleeves','Cut','Fastening Type','Lining','Layers','Pattern','Skirt','Rise','Design',
'Generic Item Type','Variant Grams','Heel Height','Heel Shape','Height','Toe','Measure of Waist','Measure of Rise','Measure of Thigh'];
	const defaultItemNames = ['Filled', 'Blank'];
	const defaultMonthsTags = [[3, 6], [6, 12], [12, 18], [18, 24]];
	const defaultToddlerTags = [[2, 3], [3, 4]];
	const defaultYearsTags = [[2, 3]];
	const defaultShoesTags = [[2, 3], [3, 4]];
	const [userID, setUserID] = useState("");
	const [columnsChecked, setColumnsChecked] = useState(defaultTableColumns.map(d=>false));
	const [data, setData] = useState()

	const history = useHistory()

	// Get user checkboxes
	const fetchChecked = async(user) => {

		const response = await fetch("https://apie.snapwrite.ca/storeData/table-column" + "?" + new URLSearchParams({
			userID: user
		}));

		console.log(response)
		const formatted = await response.json();
		console.log(formatted)
		setData({...formatted.data[0]})
		console.log(formatted.data[0])
		setColumnsChecked(Object.values(formatted.data[0]));
		console.log(formatted.data[0]);
		console.log(Object.values(formatted.data[0]));
	}

	useEffect(() => {
		// Fetches user checkboxes
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserID(user.uid);
				fetchChecked(user.uid);
			}
		})
	  
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault()
    console.log("hi")
		const filteredData = { ...data }
		delete filteredData.userID
		filteredData["userID"] = userID
		delete filteredData._id
		delete filteredData.__v
		console.log(filteredData)
		await axios.put("https://apie.snapwrite.ca/storeData/table-column", filteredData)
		history.push("./custom-table")
	}

	const vintagePreset =
		{
			...data,
            "brand": true,
            "size": true,
            "gender": false,
            "color": true,
            "condition": true,
            "price": true,
            "additionalInfo": false,
            "costPerItem": true,
            "vendor": false,
            "retailPrice": false,
            "itemGrams": false,
            "dateCreated": false,
            "toe": false,
            "bottomInSeam": true,
            "cost": false,
            "cut": true,
            "description": true,
            "design": true,
            "era": true,
            "fasteningType": true,
            "fileNames": false,
            "heelHeight": true,
            "heelShape": true,
            "height": true,
            "hipMeasure": true,
            "images": true,
            "itemGenericType": true,
            "layers": false,
            "legOpening": true,
            "length": true,
            "lengthMeasure": true,
            "lining": false,
            "madeIn": true,
            "material": true,
            "neckline": true,
            "pattern": false,
            "pitToPitMeasure": true,
            "projectName": false,
            "rise": false,
            "riseMeasure": true,
            "shoulderMeasure": true,
            "skirt": true,
            "sleeveInSeam": true,
            "sleeves": true,
            "style": true,
            "supplementaryImages": true,
            "taxonomyUpdatedType": false,
            "thighMeasure": true,
            "types": false,
            "variantGrams": false,
            "waistMeasure": true,
            "attributes": true,
            "measurements": true
		}

		const kidsPreset =
		{
			...data,
            "brand": true,
            "size": true,
            "gender": false,
            "color": true,
            "condition": true,
            "price": true,
            "additionalInfo": false,
            "costPerItem": true,
            "vendor": false,
            "retailPrice": false,
            "itemGrams": false,
            "dateCreated": false,
            "toe": false,
            "bottomInSeam": true,
            "cost": false,
            "cut": true,
            "description": true,
            "design": true,
            "era": false,
            "fasteningType": true,
            "fileNames": false,
            "heelHeight": true,
            "heelShape": true,
            "height": true,
            "hipMeasure": true,
            "images": true,
            "itemGenericType": true,
            "layers": false,
            "legOpening": true,
            "length": true,
            "lengthMeasure": true,
            "lining": false,
            "madeIn": false,
            "material": true,
            "neckline": true,
            "pattern": false,
            "pitToPitMeasure": true,
            "projectName": false,
            "rise": false,
            "riseMeasure": true,
            "shoulderMeasure": true,
            "skirt": true,
            "sleeveInSeam": true,
            "sleeves": true,
            "style": true,
            "supplementaryImages": true,
            "taxonomyUpdatedType": false,
            "thighMeasure": true,
            "types": false,
            "variantGrams": false,
            "waistMeasure": true,
            "attributes": true,
            "measurements": true
		}

		const regularPreset =
		{
			...data,
            "brand": true,
            "size": true,
            "gender": true,
            "color": true,
            "condition": true,
            "price": true,
            "additionalInfo": false,
            "costPerItem": true,
            "vendor": false,
            "retailPrice": false,
            "itemGrams": false,
            "dateCreated": false,
            "toe": false,
            "bottomInSeam": true,
            "cost": false,
            "cut": true,
            "description": true,
            "design": true,
            "era": false,
            "fasteningType": true,
            "fileNames": false,
            "heelHeight": true,
            "heelShape": true,
            "height": true,
            "hipMeasure": true,
            "images": true,
            "itemGenericType": true,
            "layers": false,
            "legOpening": true,
            "length": true,
            "lengthMeasure": true,
            "lining": false,
            "madeIn": false,
            "material": true,
            "neckline": true,
            "pattern": false,
            "pitToPitMeasure": true,
            "projectName": false,
            "rise": false,
            "riseMeasure": true,
            "shoulderMeasure": true,
            "skirt": true,
            "sleeveInSeam": true,
            "sleeves": true,
            "style": true,
            "supplementaryImages": true,
            "taxonomyUpdatedType": false,
            "thighMeasure": true,
            "types": false,
            "variantGrams": false,
            "waistMeasure": true,
            "attributes": true,
            "measurements": true
		}

		const newPreset =
		{
			...data,
            "brand": true,
            "size": true,
            "gender": true,
            "color": true,
            "condition": false,
            "price": true,
            "additionalInfo": false,
            "costPerItem": true,
            "vendor": false,
            "retailPrice": false,
            "itemGrams": false,
            "dateCreated": false,
            "toe": false,
            "bottomInSeam": true,
            "cost": false,
            "cut": true,
            "description": true,
            "design": true,
            "era": false,
            "fasteningType": true,
            "fileNames": false,
            "heelHeight": true,
            "heelShape": true,
            "height": true,
            "hipMeasure": true,
            "images": true,
            "itemGenericType": true,
            "layers": false,
            "legOpening": true,
            "length": true,
            "lengthMeasure": true,
            "lining": false,
            "madeIn": false,
            "material": true,
            "neckline": true,
            "pattern": false,
            "pitToPitMeasure": true,
            "projectName": false,
            "rise": false,
            "riseMeasure": true,
            "shoulderMeasure": true,
            "skirt": true,
            "sleeveInSeam": true,
            "sleeves": true,
            "style": true,
            "supplementaryImages": true,
            "taxonomyUpdatedType": false,
            "thighMeasure": true,
            "types": false,
            "variantGrams": false,
            "waistMeasure": true,
            "attributes": true,
            "measurements": true
		}

		const genericPreset =
		{
			...data,
            "brand": true,
            "size": true,
            "gender": true,
            "color": true,
            "condition": true,
            "price": true,
            "additionalInfo": false,
            "costPerItem": true,
            "vendor": false,
            "retailPrice": false,
            "itemGrams": false,
            "dateCreated": false,
            "toe": false,
            "bottomInSeam": true,
            "cost": false,
            "cut": true,
            "description": true,
            "design": true,
            "era": false,
            "fasteningType": true,
            "fileNames": false,
            "heelHeight": true,
            "heelShape": true,
            "height": true,
            "hipMeasure": true,
            "images": true,
            "itemGenericType": true,
            "layers": false,
            "legOpening": true,
            "length": true,
            "lengthMeasure": true,
            "lining": false,
            "madeIn": false,
            "material": true,
            "neckline": true,
            "pattern": false,
            "pitToPitMeasure": true,
            "projectName": false,
            "rise": false,
            "riseMeasure": true,
            "shoulderMeasure": true,
            "skirt": true,
            "sleeveInSeam": true,
            "sleeves": true,
            "style": true,
            "supplementaryImages": true,
            "taxonomyUpdatedType": false,
            "thighMeasure": true,
            "types": false,
            "variantGrams": false,
            "waistMeasure": true,
            "attributes": true,
            "measurements": true
		}

		const tablePresets = {
			"Vintage": vintagePreset,
			"Pre-loved (kids)": kidsPreset,
			"Pre-loved (regular)": regularPreset,
			"New": newPreset
		}

    return (
      <div style = {{minHeight: "100vh"}}>
        <Header />
		<div style = {{minHeight: "100vh", display: "flex"}}>
		<SideMenu />

		{data ? (<div style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <h1>Customize Table</h1>
		  <Autocomplete
              disablePortal
              disableClearable
              id="table-preset-autocomplete"
              options={["Vintage", "Pre-loved (kids)", "Pre-loved (regular)", "New"]}
              defaultValue={"Select a Preset"}
              // getOptionLabel={option => option.displayName}
              sx={{ width: 300, fontSize: "normal" }}
              size="small"
              // getOptionSelected={(option, value) => option.label === value.label}
              // isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
              onChange={(e, value) => setData(tablePresets[value])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Presets"
                  sx={{ fontSize: "normal" }}
                />
              )}
            />
        <main>
          <section>
			<br />
            <FormGroup  style = {{display: "flex", justifyContent: "center"}}>
            <form id="customTableForm" onSubmit={(e) => handleSubmit(e)} >
              <fieldset style = {{padding: "30px", width: "80%", marginBottom: "30px"}}>
                <div style = {{display: "flex", justifyContent: "center"}}>
                <legend style = {{width: "90%"}}>Main Columns</legend>
                
                </div>
                <br></br>
								{data ? <CheckBox data = {data} setData = {setData} /> : null}
                <br />
              <div style = {{display: "flex", justifyContent: "center"}}>
              <div style = {{width: "92%"}}>
                <button className = "btn" id = "submit-table-changes" type="submit" defaultValue="Save" title="Click to Submit" tabIndex={12} >Save Changes</button>
              </div>
              </div>
              </fieldset>
              </form>
            </FormGroup>
          </section>
        </main>
		</div>) : null}
		</div>
      </div>
    );
  }
;

export default CustomTable;
