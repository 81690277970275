
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import React, {useContext, useEffect, useState, useRef } from "react";
import createWixCSV from "../../../products/bulk/shopify/utils/generateWixCSV.js";
import createcsv from "../../../products/bulk/shopify/utils/generateCsv.js";
import TableList from "../../../products/bulk/shopify/tableList.js";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { getAuth } from "@firebase/auth";
import { useAuthenticatedFetch } from "../../shopify/hooks/useAuthenticatedFetch.js";
import { useHistory } from "react-router";
import { formattedThemeData } from "../../../utils/utilFunctions.js";
import renameItem from "../../../products/bulk/shopify/utils/renameItem.js";
import ItemNameFiller from "../../../products/bulk/shopify/utils/itemNameAutoFill.js";
import TaxonomyUpdate from "../../../products/bulk/shopify/taxonomyUpdate.js";


import updateColumnValue from "../../../products/bulk/shopify/utils/updateAttribute.js";
import { OrganizationContext } from "../../App.js";
import axios from 'axios'
import { updateProjectStatusAsExported } from "../../../utils/utilFunctions.js";
import MainSideMenuHeaderContainer from "../../containers/MainSideMenuHeaderContainer.js";
import { PrimaryButton } from "../../branded-elements/Elements.js";
import getProductCategoryForPricing from "../../../products/bulk/shopify/utils/pricePrediction/predictPrice.js";
import adjustedConditionForPrice from "../../../products/bulk/shopify/utils/pricePrediction/itemConditionAdjustor.js";
import generateSKU from "../../../products/bulk/shopify/utils/skuCreator/skuGenerate.js";
import stateArrayDBassignment from "../../../products/bulk/shopify/utils/stateDBassignment.js";

// Request Splitting:
import splitArray from "../../../products/bulk/shopify/utils/requestSplitting/splitImages.js";
import getResponse from "../../../products/bulk/shopify/utils/requestSplitting/parallelRequests.js";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

export const ImportButton = ({generateCSVData, csvDownload, projectName, setSuccess, setFail, userID, size, template, objectID}) => {
	const fetchFunction = useAuthenticatedFetch();
	return (
    <PrimaryButton title={"Import"}
      data={csvDownload}
      filename={`${projectName !== "" ? projectName : "Import"
        }_SnapWriteAI.csv`}
      onClick={async () => {
        const isImport = true;
        const csvData = await generateCSVData("shopifyCSV", isImport);

        try {
          const body = {
            data: csvData,
            userID: userID,
            size: size
          };

          fetchFunction(`https://apie.snapwrite.ca/shopify/import`, {
            method: "POST",
            mode: "cors",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // Remove the comma here
          }).then((res) => {
            if (res.ok) {
              setSuccess(true);
              updateProjectStatusAsExported(template, objectID);
            }
          }).catch((error) => {
            setFail(true);
            console.log("ERROR", error);
            alert('Import not successful: ' + error);
          });


        } catch (error) {
          console.log("ERROR", error)
          alert('Import not successful: ' + error)
        }
      }

      }
    />
	);
}

const SortedClothingLabelsPage = (props) => {
  const history = useHistory()
  const [template, setTemplate] = useState(props.template)
  const [objectID, setObjectID] = useState(props.id)
	const {organization, setOrganization} = useContext(OrganizationContext)
  const auth = getAuth();

  const userID = organization?.id || props.userID;
  const [projectName, setProjectName] = useState("");
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [waitingText, setWaitingText] = useState("Loading...");
  const [csvDownload, SetCsvDownload] = useState([]);
  const [itemGenericType, setItemGenericType] = useState([]);
  const [s3Images, setS3Images] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [supplementaryImages, setSupplementaryImages] = useState([]);
  const [imageFileName, setImageFileName] = useState([]);
  const [itemName, setItemName] = useState([]);
  const [types, setType] = useState([]);
  const [typesTaxoUpdate, setTypesTaxoUpdate] = useState([]);
  const [brand, setBrand] = useState([]);
  const [sku, setSku] = useState([])
  const [size, setSize] = useState([]);
  const [sizeNotes, setSizeNotes] = useState([]);
  const [condition, setCondition] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [description, setDescription] = useState([]);
  const [conditionDescription, setConditionDescription] = useState([]);
  const [price, setPrice] = useState([]);
  const [costPerItem, setCostPerItem] = useState([]);
  const [retailPrice, setRetailPrice] = useState([]);
  const [genders, setGender] = useState([]);
  const [colors, setColor] = useState([]);
  const [additionalInfos, setAddtionaInfo] = useState([]);
  const [variantGrams, setVariantGrams] = useState([]);
  const [isVendorColSelected, setIsVendorColSelected] = useState(false)
  const [offerAcceptStatus, setOfferAcceptStatus] = useState('pending')
  const [concerns, setConcerns] = useState([])


	// Filter Vendor
	const [filterVendor, setFilterVendor] = useState(props.filterVendor);
	const [email, setEmail] = useState('');
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  /* Properties */
  const [tags, setTags] = useState([]);

  const [material, setMaterial] = useState([]);
  const [length, setLength] = useState([]);
  const [style, setStyle] = useState([]);
  const [age, setAge] = useState([]);
  const [design, setDesign] = useState([]);
  const [closure, setClosure] = useState([]);
  const [fastening, setFastening] = useState([]);
  const [fastening_type, setFasteningType] = useState([]);
  const [belt, setBelt] = useState([]);
  const [cut, setCut] = useState([]);
  const [neckline, setNeckline] = useState([]);
  const [sleeves, setSleeves] = useState([]);
  const [hood, setHood] = useState([]);
  const [typeObj, setTypeObj] = useState([]);
  const [lining, setLining] = useState([]);

  const [layers, setLayers] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [skirt, setSkirt] = useState([]);
  const [rise, setRise] = useState([]);
  const [heelHeight, setHeelHeight] = useState([]);
  const [heelShape, setHeelShape] = useState([]);
  const [height, setHeight] = useState([]);
  const [toe, setToe] = useState([]);

  const [era, setEra] = useState([]);
  const [madeIn, setMadeIn] = useState([]);

  //Measurements
  const [waistMeasure, setWaistMeasure] = useState([]);
  const [hipMeasure, setHipMeasure] = useState([]);
  const [bottomInSeam, setBottomInSeam] = useState([]);
  const [riseMeasure, setRiseMeasure] = useState([]);
  const [thighMeasure, setThighMeasure] = useState([]);
  const [legOpening, setLegOpening] = useState([]);
  const [lengthMeasure, setLengthMeasure] = useState([]);
  const [sleeveInSeam, setsleeveInSeam] = useState([]);
  const [pitToPitMeasure, setPitToPitMeasure] = useState([]);
  const [shoulderMeasure, setShoulderMeasure] = useState([]);
  //shoes
  const [heelHeightMeasure, setHeelHeightMeasure] = useState([]);
	const [soleMeasure, setSoleMeasure] = useState([])
	const [inSoleMeasure, setInSoleMeasure] = useState([])
	const [shoeWidth, setShoeWidth] = useState([])

  const [themeItemsId, setThemeItemsId] = useState([])
  const [sharedWith, setSharedWith] = useState("")
  const [accountType, setAccountType] = useState("reseller")

	const [SKUStart, setSKUStart] = useState()


	// Snackbar
	const [success, setSuccess] = useState(false);
	const [fail, setFail] = useState(false)

  //Table Columns
  const [columns, setColumns] = useState()
  
  //brands
  const [fetchedBrands, setFetchedBrands] = useState()

  // User's Preffered ItemTypes
  const [preferredItemTypes, setPreferredItemTypes] = useState()
  // Rerender the page when Items deleted (useEffect)

  // Theme
  const [theme, setTheme] = useState([])
  const [isItemsDeleted, setIsItemsDeleted] = useState(false) 

  // plus button
  const [backendAlertMessage, setBackendAlertMessage] = useState({
    display: false,
    message: "",
  });
  const fileInputRef = React.useRef();

  const [projectData, setProjectData] = useState([]); // for New Table UI

  const fetchThemeProjectDataAndUpdateStates = async () => {
    const response = await axios
      .get(
        `https://apie.snapwrite.ca/storeData/get-themes-project-items/${props.id}`
      )
      .then((res) => {
        if(res.data){
        const formattedResponse = formattedThemeData(res.data);
        setProjectData(formattedResponse);
        setS3Images(formattedResponse.images);
        setBackImage(formattedResponse.backImage);
        setSupplementaryImages(formattedResponse.supplementaryImages);
        setProjectName(formattedResponse.projectTitle);
        setItemName(formattedResponse.itemName);
        setItemGenericType(formattedResponse.itemGenericType);
        setTypesTaxoUpdate(formattedResponse.taxonomyUpdatedType);
        setBrand(formattedResponse.brand);
        setSku(formattedResponse.sku);
        setVendor(formattedResponse.vendor);
        setType(formattedResponse.types);
        setGender(formattedResponse.gender);
        setSize(formattedResponse.size);
        setSizeNotes(formattedResponse.sizeNotes);
        setCondition(formattedResponse.condition);
        setConditionDescription(formattedResponse.conditionDescription);
        setDescription(formattedResponse.description);
        setColor(formattedResponse.colors);
        setCostPerItem(formattedResponse.cost);
        setPrice(formattedResponse.price);
        setRetailPrice(formattedResponse.retailPrice);
      setAddtionaInfo(formattedResponse.additionalInfo);
        setVariantGrams(formattedResponse.variantGrams);
        setMaterial(formattedResponse.material);
        setSleeves(formattedResponse.sleeves);
        setLength(formattedResponse.length);
        setStyle(formattedResponse.style);
        setAge(formattedResponse.age);
        setClosure(formattedResponse.closure);
        setFastening(formattedResponse.fastening);
        setFasteningType(formattedResponse.fastening_type);
        setNeckline(formattedResponse.neckline);
        setCut(formattedResponse.cut);
        setDesign(formattedResponse.design);
        setHood(formattedResponse.hood);
        setTypeObj(formattedResponse.typeObj);
        setBelt(formattedResponse.belt);
        setLining(formattedResponse.lining);
        setLayers(formattedResponse.layers);
        setPattern(formattedResponse.pattern);
        setSkirt(formattedResponse.skirt);
        setRise(formattedResponse.rise);
        setHeelHeight(formattedResponse.heelHeight);
        setHeelShape(formattedResponse.heelShape);
        setHeight(formattedResponse.height);
        setToe(formattedResponse.toe);
        setTags(formattedResponse.tags);
        setEra(formattedResponse.era);
        setMadeIn(formattedResponse.madeIn);
        setLegOpening(formattedResponse.legOpening);
        setRiseMeasure(formattedResponse.riseMeasure);
        setBottomInSeam(formattedResponse.bottomInSeam);
        setThighMeasure(formattedResponse.thighMeasure);
        setLengthMeasure(formattedResponse.lengthMeasure);
        setsleeveInSeam(formattedResponse.sleeveInSeam);
        setPitToPitMeasure(formattedResponse.pitToPitMeasure);
        setShoulderMeasure(formattedResponse.shoulderMeasure);
        setWaistMeasure(formattedResponse.waistMeasure);
        setHipMeasure(formattedResponse.hipMeasure);
        
      	let tableLength = formattedResponse.images.length

        setHeelHeightMeasure('heelHeightMeasure' in formattedResponse ? formattedResponse.heelHeightMeasure : Array(tableLength).fill(''))
        setSoleMeasure('soleMeasure' in formattedResponse ? formattedResponse.soleMeasure : Array(tableLength).fill(''))
        setInSoleMeasure('inSoleMeasure' in formattedResponse ? formattedResponse.inSoleMeasure : Array(tableLength).fill(''))
        setShoeWidth('shoeWidth' in formattedResponse ? formattedResponse.shoeWidth : Array(tableLength).fill(''))

        setTheme(formattedResponse.name);
        setThemeItemsId(formattedResponse.itemsID)
        setConcerns(formattedResponse.concerns)
        } else{
          history.push("/themes")
        }
      }).catch((err) => console.log(err)).finally(() => { setLoading(false)})
  };

	const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess(false);
		setFail(false)
  };

  const snapwrite ="https://apie.snapwrite.ca/storeData"
  const localHost = "http://localhost:3500/storeData"
  const BASE_URL = snapwrite

  let link = `${BASE_URL}/labeling-clothes-categorization`;
  if (props.template === "vintage") {
    link = `${BASE_URL}/vintage-apparel`;
  } else if (props.template === "new-clothes") {
    link = `${BASE_URL}/new-apparel`;
  } else if (props.template === "pre-loved-regular") {
    link = `${BASE_URL}/regular-preLoved-apparel`;
  } else if(props.template === "themes"){
    link = `${BASE_URL}/get-themes-project-items`
  }

  const fetchBrands = async () => {
    const brands = await axios.get(`https://apie.snapwrite.ca/storeData/brands`)
    setFetchedBrands(brands.data.data.brands)
  }
  const fetchItemTypes = async () => {
    const itemTypes = await axios.get(`https://apie.snapwrite.ca/storeData/user-preferences-taxonomy/${userID}`)    
    setPreferredItemTypes(itemTypes.data.data)
  }
  useEffect(() => {
    setLoading(true)
    fetchColumns()    
    fetchItemTypes()
    fetchBrands()
    
    if(props.template === "themes"){

      fetchThemeProjectDataAndUpdateStates()
    }
    else{
      fetch(`${link}/${props.id}`)
      .then((response) => response.json())
      .then((json) => {
        if(json.data.sharedWith){
          setSharedWith(json.data.sharedWith)
        }
        setProjectData(json.data);
        setS3Images(json.data.images);
        if (json.data.backImage) {
          setBackImage(json.data.backImage);
        } else {
          setBackImage(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.supplementaryImages &&
          json.data.supplementaryImages.length > 0
        ) {
          setSupplementaryImages(json.data.supplementaryImages);
        } else {
          setSupplementaryImages(Array(json.data.images.length).fill([]));
        }
        if (json.data.projectName) {
          setProjectName(json.data.projectName);
        } else {
          setProjectName("");
        }
        if (json.data.fileNames) {
          setImageFileName(json.data.fileNames);
        } else {
          setImageFileName(Array(json.data.images.length).fill(""));
        }
        if (json.data.itemName) {
          setItemName(json.data.itemName);
        } else {
          setItemName(Array(json.data.images.length).fill(""));
        }
        if (json.data.itemGenericType) {
          setItemGenericType(json.data.itemGenericType);
        } else {
          setItemGenericType(Array(json.data.images.length).fill(""));
        }
        if (json.data.taxonomyUpdatedType) {
          setTypesTaxoUpdate(json.data.taxonomyUpdatedType);
        } else {
          setTypesTaxoUpdate(Array(json.data.images.length).fill(""));
        }
        if (json.data.brand) {
          setBrand(json.data.brand);
        } else {
          setBrand(Array(json.data.images.length).fill(""));
        }
        if(json.data.sku) {
          setSku(json.data.sku);
        } else {
          setSku(Array(json.data.images.length).fill(""));
        }            
        if(json.data.vendor) {
          setVendor(json.data.vendor);
        } else {
          setVendor(Array(json.data.images.length).fill(""));
        }
        if (json.data.types) {
          setType(json.data.types);
        } else {
          setType(Array(json.data.images.length).fill(""));
        }
        if (json.data.gender) {
          setGender(json.data.gender);
        } else {
          setGender(Array(json.data.images.length).fill(""));
        }
        if (json.data.size) {
          setSize(json.data.size);
        } else {
          setSize(Array(json.data.images.length).fill(""));
        }
        if (json.data.sizeNotes) {
          setSizeNotes(json.data.sizeNotes);
        } else {
          setSizeNotes(Array(json.data.images.length).fill(""));
        }
        if (json.data.condition) {
          setCondition(json.data.condition);
        } else {
          setCondition(Array(json.data.images.length).fill(""));
        }
        if (json.data.conditionDescription) {
          setConditionDescription(json.data.conditionDescription);
        } else {
          setConditionDescription(Array(json.data.images.length).fill(""));
        }
        if (json.data.description) {
          setDescription(json.data.description);
        } else {
          setDescription(Array(json.data.images.length).fill(""));
        }
        if (json.data.colors) {
          setColor(json.data.colors);
        } else {
          setColor(Array(json.data.images.length).fill(""));
        }
        if (json.data.cost) {
          setCostPerItem(json.data.cost);
        } else {
          setCostPerItem(Array(json.data.images.length).fill(""));
        }
        if (json.data.price) {
          setPrice(json.data.price);
        } else {
          setPrice(Array(json.data.images.length).fill(""));
        }
        if (json.data.retailPrice) {
          setRetailPrice(json.data.retailPrice);
        } else {
          setRetailPrice(Array(json.data.images.length).fill(""));
        }
        if (json.data.additionalInfo) {
          setAddtionaInfo(json.data.additionalInfo);
        } else {
          setAddtionaInfo(Array(json.data.images.length).fill(""));
        }
        if (json.data.variantGrams) {
          setVariantGrams(json.data.variantGrams);
        } else {
          setVariantGrams(Array(json.data.images.length).fill(""));
        }

        // Attributes
        if (json.data.material) {
          setMaterial(json.data.material);
        } else {
          setMaterial(Array(json.data.images.length).fill(""));
        }
        if (json.data.sleeves) {
          setSleeves(json.data.sleeves);
        } else {
          setSleeves(Array(json.data.images.length).fill(""));
        }
        if (json.data.length) {
          setLength(json.data.length);
        } else {
          setLength(Array(json.data.images.length).fill(""));
        }
        if (json.data.style) {
          setStyle(json.data.style);
        } else {
          setStyle(Array(json.data.images.length).fill(""));
        }
        if (json.data.age) {
          setAge(json.data.age);
        } else {
          setAge(Array(json.data.images.length).fill(""));
        }
        if (json.data.closure) {
          setClosure(json.data.closure);
        } else {
          setClosure(Array(json.data.images.length).fill(""));
        }
        if (json.data.fastening) {
          setFastening(json.data.fastening);
        } else {
          setFastening(Array(json.data.images.length).fill(""));
        }
        if (json.data.fastening_type) {
          setFasteningType(json.data.fastening_type);
        } else {
          setFasteningType(Array(json.data.images.length).fill(""));
        }
        if (json.data.neckline) {
          setNeckline(json.data.neckline);
        } else {
          setNeckline(Array(json.data.images.length).fill(""));
        }
        if (json.data.cut) {
          setCut(json.data.cut);
        } else {
          setCut(Array(json.data.images.length).fill(""));
        }
        if (json.data.design) {
          setDesign(json.data.design);
        } else {
          setDesign(Array(json.data.images.length).fill(""));
        }
        if (json.data.hood) {
          setHood(json.data.hood);
        } else {
          setHood(Array(json.data.images.length).fill(""));
        }
        if (json.data.typeObj) {
          setTypeObj(json.data.typeObj);
        } else {
          setTypeObj(Array(json.data.images.length).fill(""));
        }
        if (json.data.belt) {
          setBelt(json.data.belt);
        } else {
          setBelt(Array(json.data.images.length).fill(""));
        }
        if (json.data.lining) {
          setLining(json.data.lining);
        } else {
          setLining(Array(json.data.images.length).fill(""));
        }
        if (json.data.layers) {
          setLayers(json.data.layers);
        } else {
          setLayers(Array(json.data.images.length).fill(""));
        }
        if (json.data.pattern) {
          setPattern(json.data.pattern);
        } else {
          setPattern(Array(json.data.images.length).fill(""));
        }
        if (json.data.skirt) {
          setSkirt(json.data.skirt);
        } else {
          setSkirt(Array(json.data.images.length).fill(""));
        }
        if (json.data.rise) {
          setRise(json.data.rise);
        } else {
          setRise(Array(json.data.images.length).fill(""));
        }
        if (json.data.heelHeight) {
          setHeelHeight(json.data.heelHeight);
        } else {
          setHeelHeight(Array(json.data.images.length).fill(""));
        }
        if (json.data.heelShape) {
          setHeelShape(json.data.heelShape);
        } else {
          setHeelShape(Array(json.data.images.length).fill(""));
        }
        if (json.data.height) {
          setHeight(json.data.height);
        } else {
          setHeight(Array(json.data.images.length).fill(""));
        }
        if (json.data.toe) {
          setToe(json.data.toe);
        } else {
          setToe(Array(json.data.images.length).fill(""));
        }
        if (json.data.tags) {
          setTags(json.data.tags);
        } else {
          setTags(json.data.tags);
        }

        // Vintage

        if (json.data.era && json.data.era.length === json.data.images.length) {
          setEra(json.data.era);
        } else {
          setEra(Array(json.data.images.length).fill(""));
        }
        if (json.data.concerns) {
          setConcerns(json.data.concerns);
        } else {
          setConcerns(
            Array(json.data.images.length).fill('')
          );
        }

        if (
          json.data.madeIn &&
          json.data.madeIn.length === json.data.images.length
        ) {
          setMadeIn(json.data.madeIn);
        } else {
          setMadeIn(Array(json.data.images.length).fill(""));
        }

        // Measurements

        if (
          json.data.legOpening &&
          json.data.legOpening.length === json.data.images.length
        ) {
          setLegOpening(json.data.legOpening);
        } else {
          setLegOpening(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.riseMeasure &&
          json.data.riseMeasure.length === json.data.images.length
        ) {
          setRiseMeasure(json.data.riseMeasure);
        } else {
          setRiseMeasure(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.bottomInSeam &&
          json.data.bottomInSeam.length === json.data.images.length
        ) {
          setBottomInSeam(json.data.bottomInSeam);
        } else {
          setBottomInSeam(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.thighMeasure &&
          json.data.thighMeasure.length === json.data.images.length
        ) {
          setThighMeasure(json.data.thighMeasure);
        } else {
          setThighMeasure(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.lengthMeasure &&
          json.data.lengthMeasure.length === json.data.images.length
        ) {
          setLengthMeasure(json.data.lengthMeasure);
        } else {
          setLengthMeasure(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.sleeveInSeam &&
          json.data.sleeveInSeam.length === json.data.images.length
        ) {
          setsleeveInSeam(json.data.sleeveInSeam);
        } else {
          setsleeveInSeam(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.pitToPitMeasure &&
          json.data.pitToPitMeasure.length === json.data.images.length
        ) {
          setPitToPitMeasure(json.data.pitToPitMeasure);
        } else {
          setPitToPitMeasure(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.shoulderMeasure &&
          json.data.shoulderMeasure.length === json.data.images.length
        ) {
          setShoulderMeasure(json.data.shoulderMeasure);
        } else {
          setShoulderMeasure(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.waistMeasure &&
          json.data.waistMeasure.length === json.data.images.length
        ) {
          setWaistMeasure(json.data.waistMeasure);
        } else {
          setWaistMeasure(Array(json.data.images.length).fill(""));
        }
        if (
          json.data.hipMeasure &&
          json.data.hipMeasure.length === json.data.images.length
        ) {
          setHipMeasure(json.data.hipMeasure);
        } else {
          setHipMeasure(Array(json.data.images.length).fill(""));
        } if(json.data.theme){
          setTheme(json.data.theme)
        }
        // shoes measurement
        setHeelHeightMeasure(stateArrayDBassignment(json.data.heelHeightMeasure, json.data.images.length))
        setSoleMeasure(stateArrayDBassignment(json.data.soleMeasure, json.data.images.length))
        setInSoleMeasure(stateArrayDBassignment(json.data.inSoleMeasure, json.data.images.length))
        setShoeWidth(stateArrayDBassignment(json.data.shoeWidth, json.data.images.length))				        

        if(json.data.offerAcceptStatus){
          setOfferAcceptStatus(json.data.offerAcceptStatus)
        }

      })
      .catch((err) => {
        console.log(`Error ${err}`);
        setWaitingText("Error occurred. Please refresh this page. If this error occurs again please contact: aryamanrastogi01@gmail.com")
      }).finally(() => {
        setLoading(false)
      });
    }
    
  }, [csvDownload, isItemsDeleted]);

  const fetchColumns = async () => {
    const columns = await axios.get(`https://apie.snapwrite.ca/storedata/table-column?userID=${userID}&template=${props.template}`)
    setColumns(columns.data.data)
  }


  const updateVal = (name, event, index, val, setVal) => {
    const singleProductModelFilter = {userID, sku: sku[index]}
    

    const newVal = val.slice();
    if (isNaN(event)) {
      if (event.includes(`"`)) {
        event = event.replace(/"/g, `'`);
      }
    }
    newVal[index] = event;
    setVal(newVal);
    if (newVal.length > 0 && props.id !== "") {
      updateColumnValue(singleProductModelFilter, props.template, name, newVal, props.id);
    }
  };


  const updateValIndividual = (name, event, index, key) => {
    const singleProductModelFilter = {userID, sku: sku[index]}
    

    const newVal = event;
    if (isNaN(event)) {
      if (event.includes(`"`)) {
        event = event.replace(/"/g, `'`);
      }
    }
    if (newVal !== null && newVal !== undefined && props.id !== "") {
      updateColumnValue(singleProductModelFilter, props.template, name, event, props.id, index, key);
    }
  };

  const updateProjectName = (event) => {
    setProjectName(event);
    if (props.id !== "" && props.template !== "themes") {
      fetch(`${link}-projectNameUpdate`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: props.id,
          projectName: event,
        }),
      })
        .then((response) => response.json())
        .catch((err) => {
          console.log(`Error ${err}`);
        });
    } else{
      const endpoint = "https://apie.snapwrite.ca/storeData/upate-theme-project-data"
      const body =  {newValue: {projectTitle: event}, _id: props.id}
      axios.put(endpoint, body)
    }
  };

  const generateCSVData = async (button, isImport=false) => {
    setLoading(true);
    setWaitingText("Enhancing SEO. Please Wait for about a minute");
    let json;
    if (props.template !== "themes") {
      json = await fetch(`${link}/${props.id}`).then((response) =>
        response.json()
      );
    } else{
      const response = await axios.get(`${link}/${props.id}`).then((res) => {
        json = {data: formattedThemeData(res.data)}
      })
    }
    
    const preferences = await axios.get(`https://apie.snapwrite.ca/storeData/user-preferences/${userID}`)    
    const preferenceData = preferences.data.data  
    axios.put("https://apie.snapwrite.ca/storeData/user-preferences-updateVal", {userID: userID, name: 'taxonomyOptions', val: [...new Set([...preferenceData.taxonomyOptions, ...json.data.taxonomyUpdatedType.filter(item => typeof item === 'string')])]})

    let data =
      button === "wixCSV"
        ? await createWixCSV(
          props.template,           
          userID,
          props.id,
          isImport
          )
        : await createcsv(
                  props.template,           
                  userID,
                  props.id,
                  isImport,
                  formattedThemeData
                );

    SetCsvDownload(data);

    setLoading(false);
    setWaitingText("Loading...");
    return data;
  };

  function downloadCSV(csvAsObj) {
    const headers = Object.keys(csvAsObj[0]);
    const csvRows = [];

    const headerRow = headers.join(",");
    csvRows.push(headerRow);

    for (const item of csvAsObj) {
      const values = headers.map((header) => item[header]);
      const csvRow = values.join(",");
      csvRows.push(csvRow);
    }

    const csvString = csvRows.join("\n");
    const csvData = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(
      csvData,
      `${projectName !== "" ? projectName : "Import"}_SnapWriteAI.csv`
    );
  }

	function onlyUnique(value, index, array) {
		return array.indexOf(value) === index;
	}


  const getInitialData = async () => {
    if (objectID === '') {
      const initializedData = {
        sku: [],
        images: [],
        backImage: [],
        supplementaryImages: [],
        types: [],
        itemGenericType: [],
        taxonomyUpdatedType: [],
        itemName: [],
        brand: [],
        vendor: [],
        size: [],
        sizeNotes: [],
        condition: [],
        conditionDescription: [],
        description: [],
        cost: [],
        price: [],
        retailPrice: [],
        fileNames: [],
        colors: [],
        gender: [],
        additionalInfo: [],
        variantGrams: [],
        material: [],
        sleeves: [],
        length: [],
        style: [],
        age: [],
        closure: [],
        fastening: [],
        fastening_type: [],
        neckline: [],
        cut: [],
        pocket: [],
        hood: [],
        typeObj: [],
        belt: [],
        design: [],
        lining: [],
        layers: [],
        pattern: [],
        skirt: [],
        rise: [],
        heelHeight: [],
        heelShape: [],
        height: [],
        toe: [],
        isArchived: false,
        tags: [],
        era: [],
        madeIn: [],
        pitToPitMeasure: [],
        shoulderMeasure: [],
        sleeveInSeam: [],
        lengthMeasure: [],
        waistMeasure: [],
        hipMeasure: [],
        bottomInSeam: [],
        riseMeasure: [],
        thighMeasure: [],
        legOpening: [],
        heelHeightMeasure: [],
        soleMeasure: [],
        inSoleMeasure: [],
        shoeWidth: [],
        theme: [],
        concerns: [],
        projectCSVGenerated: false,
        isFormGenerated: false,
        isProductFeedGenerated: false,
      };
      return initializedData;
    } else {
      let link = `https://apie.snapwrite.ca/storeData/labeling-clothes-categorization`;
      if (props.template === 'vintage') {
        link = `https://apie.snapwrite.ca/storeData/vintage-apparel`;
      } else if (props.template === 'new-clothes') {
        link = `https://apie.snapwrite.ca/storeData/new-apparel`;
      } else if (props.template === 'pre-loved-regular') {
        link = `https://apie.snapwrite.ca/storeData/regular-preLoved-apparel`;
      }
      const data = await fetch(`${link}/${objectID}`)
        .then((response) => response.json())
        .then((json) => {
          return json.data;
        })
        .catch((err) => {
          console.log(`Error ${err}`);
        });
      return data;
    }
  };


	const projectBodyRef = useRef({});

	function updateProperty(key, value, index) {
		// Initialize key if it doesn't exist
		if (!projectBodyRef.current.hasOwnProperty(key)) {
			if (objectID === '') {
				projectBodyRef.current[key] = [];
			} else {
				return; // Don't update if objectID exists and key doesn't exist
			}
		}

		if (value && value.length > 0 && value !== '') {
			projectBodyRef.current[key][index] = value;
		} else if (key === 'supplementaryImages') {
			projectBodyRef.current[key][index] = [];
		} else {
			projectBodyRef.current[key][index] = '';
		}
	}
	  

  // image upload logic for Plus Button
  async function getSuggestions(name, imageURL, supplementaryImagesURL, res, skuCounter, index) {
    try {
      let filename = name.substring(0, name.lastIndexOf(".")) || name;
      if (
        userID === "1eMplQPlHHhX9lnwX8NTcKghui22" ||
        userID === "7eXVFZlMcgY7HMKKSkCf4kb7qEt1"
      ) {
        filename = filename.split(/_| |-/)[0];
      }
      updateProperty('fileNames', filename, index);
      updateProperty('itemName', res.data.itemName, index);
      updateProperty('description', res.data.description, index);
      updateProperty('conditionDescription', res.data.conditionDescription, index);
      updateProperty('concerns', res.data.concerns, index);
      updateProperty('era', res.data.era, index);
      updateProperty('madeIn', res.data.madeIn, index);
      updateProperty('lengthMeasure', res.data.lengthMeasure, index);
      updateProperty('waistMeasure', res.data.waistMeasure, index);
      updateProperty('hipMeasure', res.data.hipMeasure, index);
      updateProperty('bottomInSeam', res.data.bottomInSeam, index);
      updateProperty('riseMeasure', res.data.riseMeasure, index);
      updateProperty('thighMeasure', res.data.thighMeasure, index);
      updateProperty('legOpening', res.data.legOpening, index);
      updateProperty('sleeveInSeam', res.data.sleeveInSeam, index);
      updateProperty('pitToPitMeasure', res.data.pitToPitMeasure, index);
      updateProperty('shoulderMeasure', res.data.shoulderMeasure, index);
      updateProperty('heelHeightMeasure', res.data.heelHeightMeasure, index);
      updateProperty('soleMeasure', res.data.soleMeasure, index);
      updateProperty('inSoleMeasure', res.data.inSoleMeasure, index);
      updateProperty('shoeWidth', res.data.shoeWidth, index);
      updateProperty('images', res.data.image, index);
      updateProperty('backImage', res.data.backImage, index);
      updateProperty('supplementaryImages', res.data.supplementaryImages, index);

      const firstLetterCapital = (str) => {
        return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        );
      };
      //Item Generic Type
      if (res.data.item.toString().replace(/,/g, " ")) {
        let typeVal = firstLetterCapital(
          res.data.item.toString().replace(/,/g, " ")
        );
        updateProperty('itemGenericType', typeVal, index);
      }
      let itemNameToPush;
      let typeValToPush; 

      if (res.data.type.length === 0) {
        if (res.data.item.toString().replace(/,/g, " ")) {
          let typeVal = firstLetterCapital(
            res.data.item.toString().replace(/,/g, " ")
          );
          if (typeVal.includes("/")) {
            typeVal = typeVal.substring(typeVal.indexOf("/") + 1);
          }
          updateProperty('types', typeVal, index);

         

          if (userID === "9BjTXuFQ6TMYrn5cy6dclMyYqDz1") {
            //yyz
            // console.log(res.data.hood)
            itemNameToPush = 
              firstLetterCapital(
                ((res.data.brand && res.data.brand + " ") || "") +
                  renameItem(
                    userID,
                    typeVal,
                    res.data.hood,
                    res.data.design,
                    res.data.sleeves
                  )
              )
            ;
            updateProperty('itemName', itemNameToPush, index);
          } else {
            itemNameToPush = ItemNameFiller(userID, typeVal);
            updateProperty('itemName', itemNameToPush, index);
          }
        } else {
          updateProperty('types', res.data.type, index);
          updateProperty('itemName', res.data.itemName, index);
        }
      } else {

       typeValToPush = firstLetterCapital(res.data.type.toString().replace(/,/g, " "));
        updateProperty('types', typeValToPush, index);
        if (userID === "9BjTXuFQ6TMYrn5cy6dclMyYqDz1") {
          //yyz id
          itemNameToPush = 
            firstLetterCapital(
              (res.data.color[0] ? res.data.color[0] : "") +
                " " +
                ((res.data.brand && res.data.brand + " ") || "") +
                renameItem(
                  userID,
                  res.data.type.toString().replace(/,/g, " "),
                  res.data.hood,
                  res.data.design,
                  res.data.sleeves
                )
            );
            updateProperty('itemName', itemNameToPush, index);
        } else {
          itemNameToPush = 
            ItemNameFiller(
              userID,
              firstLetterCapital(res.data.type.toString().replace(/,/g, " "))
            )
            updateProperty('itemName', itemNameToPush, index);
        }
      }

      

      const TaxonomyTypeToPush = 
        TaxonomyUpdate(
          userID,
          res.data.item.toString(),
          res.data.type.toString(),
          props.template,
          res.data.sleeves
        )
        updateProperty('taxonomyUpdatedType', TaxonomyTypeToPush, index);
        updateProperty('brand', res.data.brand, index);

			if (
				userID === '1eMplQPlHHhX9lnwX8NTcKghui22' ||
				userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1'
			) {
        updateProperty('sku', filename, index);
			} else {
        const geberatedSKU = await generateSKU(userID);
        updateProperty('sku', geberatedSKU, index);
			}

      if (
        userID === "1eMplQPlHHhX9lnwX8NTcKghui22" ||
        userID === "7eXVFZlMcgY7HMKKSkCf4kb7qEt1"
      ) {
        if (/^CC\d/.test(filename)) {
          updateProperty('vendor', "Coels Capsule", index);
        } else if (/^CPV\d/.test(filename)) {
          updateProperty('vendor', "Cherry Pick Vintage", index);
        } else if (/^TRV\d/.test(filename)) {
          updateProperty('vendor', "The Room Vintage", index);
        } else if (/^ST\d/.test(filename)) {
          updateProperty('vendor', "SkyThrifts", index);
        } else if (/^BLT\d/.test(filename)) {
          updateProperty('vendor', "Baldylox Thrifts", index);
        } else {
          updateProperty('vendor', res.data.vendor, index);
        }
      } else {
        updateProperty('vendor', res.data.vendor, index);
      }


      // Condition
      let conditionToPush;
      if (
        userID === "CXNDhrE6FfNRdXNm6nUQlmck29f2" ||
        userID === "xHaYXalwv8X8RpBLZju0PaQlWW23" ||
        userID === "zjkiN1cq9Eb4oHsrA923OoJ9Sxz1" ||
        userID === "AgVEaDRk3tVtnfqAKiMYWLlRICG2"
      ) {
        //Piece By Piece Kids and Tokki and tiny human gear
        conditionToPush = "Excellent Used Condition";
      } else if (
        userID === "HDlknVR9NpWxhs9WWlfvnwAQq4b2" ||
        userID === "ZFoq2NIsDmZgVTW4g88O1B8JA822" ||
        props.template === "pre-loved-regular"
      ) {
        conditionToPush = "Very Good";
      } else if (userID === "VIa289fKOLZVRQTAEuueJXwvA0y2") {
        conditionToPush = "Good condition";
      } else if (props.template === "new-clothes") {
        conditionToPush = "New";
      } else if (props.template === "vintage") {
        if (
          userID === "1eMplQPlHHhX9lnwX8NTcKghui22" ||
          userID === "7eXVFZlMcgY7HMKKSkCf4kb7qEt1"
        ) {
          conditionToPush = "Good";
        } else if (userID === "r8jkmnica3dJXGLyiIFbItEgZvr2") {
          conditionToPush = "Excellent Vintage Condition";
        } else {
          conditionToPush = "Like New";
        }
      } else {
        conditionToPush = "Excellent";
      }

      updateProperty('condition', conditionToPush, index);
      updateProperty('price', res.data.price, index);
      updateProperty('cost', res.data.cost, index);
      updateProperty('retailPrice', res.data.retailPrice, index);


      //Product Size
      let sizeToPush;
      if (
        userID === "VPQROHGh7pNC9du2JnL4fIopeoh1" ||
        userID === "t4gFVUQejYQYBY6dhwOp2mkYMTe2" ||
        userID === "WbahZZa5HhfWAa6SyJLYnl31kMG3" ||
        props.template === "vintage" ||
        props.template === "new-clothes" ||
        props.template === "pre-loved-regular"
      ) {
        //Loose Fade
        sizeToPush = "M";
      } else {
        sizeToPush = "";
      }
      updateProperty('size', sizeToPush, index);
      updateProperty('sizeNotes', res.data.sizeNotes, index);

      //Product Gender
      let genderToPush

      if (
        userID === "VPQROHGh7pNC9du2JnL4fIopeoh1" ||
        userID === "t4gFVUQejYQYBY6dhwOp2mkYMTe2" ||
        userID === "WbahZZa5HhfWAa6SyJLYnl31kMG3" ||
        props.template === "vintage" ||
        props.template === "new-clothes" ||
        props.template === "pre-loved-regular"
      ) {
        if (res.data.gender.includes("Boys")) {
         genderToPush = "Men"
        } else if (res.data.gender.includes("Girls")) {
         genderToPush = "Women"
        }
      } else {
       genderToPush =  
          firstLetterCapital(res.data.gender.toString().replace(/,/g, " "))
      }

      updateProperty('gender', genderToPush, index);

      //Product Color
      const colorToPush = firstLetterCapital(res.data.color.toString().replace(/,/g, " "))
      updateProperty('colors', colorToPush, index);


      // Additional Information like material etc.
      updateProperty('additionalInfo', res.data.additionalInfo, index);

      let materialToPush;
      if (res.data.material) {
        materialToPush = res.data.material.join(", ");
      } else {
        materialToPush = "";
      }
      updateProperty('material', materialToPush, index);
     updateProperty('length', res.data.length, index);

      let styleToPush;
      if (res.data.style) {
        styleToPush = res.data.style.join(", ");
      } else {
        styleToPush = "";
      }
      updateProperty('style', styleToPush, index);

      let ageToPush;;
      if (res.data.age) {
       ageToPush = res.data.age.join(", ");
      } else {
       ageToPush = "";
      }
      updateProperty('age', ageToPush, index);

      let designToPush;
      if (res.data.design) {
        designToPush = res.data.design.join(", ");
      } else {
        designToPush = "";
      }
      updateProperty('design', designToPush, index);

      let closureToPush;
      if (res.data.closure) {
        closureToPush = res.data.closure.join(", ");
      } else {
        closureToPush = "";
      }
      updateProperty('closure', closureToPush, index);

      let fasteningToPush
      if (res.data.fastening) {
        fasteningToPush = res.data.fastening.join(", ");
      } else {
        fasteningToPush = "";
      }
      updateProperty('fastening', fasteningToPush, index);

      let fasteningTypeToPush
      if (res.data.fasteningType) {
        fasteningTypeToPush = res.data.fasteningType.join(", ");
      } else {
        fasteningTypeToPush = "";
      }
      updateProperty('fastening_type', fasteningTypeToPush, index);

      let beltToPush;
      if (res.data.belt) {
        beltToPush = res.data.belt.join(", ");
      } else {
        beltToPush = "";
      }
      updateProperty('belt', beltToPush, index);

      let cutToPush;
      if (res.data.cut) {
        cutToPush = res.data.cut.join(", ");
      } else {
        cutToPush = "";
      }
      updateProperty('cut', cutToPush, index);

      let necklineToPush;
      if (res.data.neckline) {
        necklineToPush = res.data.neckline.join(", ");
      } else {
        necklineToPush = "";
      }
      updateProperty('neckline', necklineToPush, index);

      let sleevesToPush;
      if (res.data.sleeves) {
        sleevesToPush = res.data.sleeves.join(", ");
      } else {
        sleevesToPush = "";
      }
      updateProperty('sleeves', sleevesToPush, index);

      let hoodToPush;
      if (res.data.hood) {
        hoodToPush = res.data.hood.join(", ");
      } else {
        hoodToPush = "";
      }
      updateProperty('hood', hoodToPush, index);

      let liningToPush
      if (res.data.lining) {
        liningToPush = res.data.lining.join(", ");
      } else {
        liningToPush = "";
      }
      updateProperty('lining', liningToPush, index);

      let layerToPush;
      if (res.data.layers) {
        layerToPush = res.data.layers.join(", ");
      } else {
        layerToPush = "";
      }
      updateProperty('layers', layerToPush, index);

      let patternToPush;
      if (res.data.pattern) {
        patternToPush = res.data.pattern.join(", ");
      } else {
        patternToPush = "";
      }
      updateProperty('pattern', patternToPush, index);

      let skirtToPush;
      if (res.data.skirt) {
        skirtToPush = res.data.skirt.join(", ");
      } else {
        skirtToPush = "";
      }
      updateProperty('skirt', skirtToPush, index);

      let risePush;
      if (res.data.rise) {
        risePush = res.data.rise.join(", ");
      } else {
        risePush = "";
      }
      updateProperty('rise', risePush, index);

      let heelHeigthToPush = heelHeight;
      if (res.data.heelHeight) {
        heelHeigthToPush = res.data.heelHeight.join(", ");
      } else {
        heelHeigthToPush = "";
      }
      updateProperty('heelHeight', heelHeigthToPush, index);

      let heelShapeToPush;
      if (res.data.heelShape) {
        heelShapeToPush = res.data.heelShape.join(", ");
      } else {
        heelShapeToPush = "";
      }
      updateProperty('heelShape', heelShapeToPush, index);

      let heightToPush;
      if (res.data.height) {
        heightToPush = res.data.height.join(", ");
      } else {
        heightToPush = "";
      }
      updateProperty('height', heightToPush, index);

      let toeToPush;
      if (res.data.toe) {
        toeToPush = res.data.toe.join(", ");
      } else {
        toeToPush = "";
      }
      updateProperty('toe', toeToPush, index);

      let typeToPush;
      if (res.data.types) {
        typeToPush = res.data.types.join(", ");
      } else {
        typeToPush = "";
      }
      updateProperty('types', typeToPush, index);
      updateProperty('variantGrams', res.data.variantGrams, index);
      updateProperty('tags', res.data.tags, index);

    } catch (e) {
      console.log("Please try using a different image");
      console.error(e);
    }
  }


  function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }
  const uploadImage = async (e, str) => {
    e.preventDefault()
    setLoading(true);
		let skuCounter = SKUStart
		console.log(skuCounter)

    let file = e.target.files;
    if(str==="drop"){
      file = e.dataTransfer.files;
    }          
    //for aws images link
    const imageURL = [];
		const supplementaryImagesURL = [];
		let fileArray = []
		for (let fileIdx in file) {
			if (!isNumeric(fileIdx)) break;
			let newFile = file[fileIdx];
			let newCleanFile= {name: newFile.name.split(".")[0], newFile} // remove .png, .webp 
			fileArray.push(newCleanFile);
		}
		fileArray.sort((fileA, fileB) => { // cluster same names together
			if (fileA.name > fileB.name) return 1;
			else if (fileA.name < fileB.name) return -1;
			return 0;
		})
		let clusterIdx = -1;
		let lastFileName = '';
		let heroFiles = []; // files of hero images

		let isSupplementary = false;
		if (userID === "1eMplQPlHHhX9lnwX8NTcKghui22" || userID === '7eXVFZlMcgY7HMKKSkCf4kb7qEt1') {
			for (let index = 0; index < fileArray.length; index++) { // for each image file
        let currentFileName = fileArray[index].name.split(/_| |-/)[0]; // remove _1, _2, _3
				if (currentFileName !== lastFileName) { // find clustered images
					// hero image
					lastFileName = currentFileName
					clusterIdx++;
					isSupplementary = false;
					heroFiles.push(fileArray[index].newFile);
				} else {
					//supplementary image
					isSupplementary = true;
				}
				const { url } = await fetch(
					"https://apie.snapwrite.ca/storeData/s3Url"
				).then((res) => res.json());
				// post the image direclty to the s3 bucket
				await fetch(url, {
					method: "PUT",
					headers: {
						"Content-Type": "image/jpeg",
					},
					body: fileArray[index].newFile,
				}).catch((err) => console.error(err));
				let currentURL = url.split("?")[0];
				if (isSupplementary) {
					supplementaryImagesURL[clusterIdx].push(currentURL);
				} else {
					supplementaryImagesURL.push([]);
					imageURL.push(currentURL);
				}
			}
      
      const result = await getResponse(splitArray(imageURL), userID, setBackendAlertMessage, backendAlertMessage)
      projectBodyRef.current = await getInitialData();
				const lastProjectLength = projectBodyRef.current.images.length;

			for (let i = 0; i < heroFiles.length; i++) {
				// skuCounter++
				await getSuggestions(
					heroFiles[i].name,
					imageURL[i],
					supplementaryImagesURL[i],
					{data: result[i]},
					skuCounter,
					objectID.length > 0 && lastProjectLength> 0 ? lastProjectLength + i : i // index
				)
				console.log(skuCounter)
			}

		} else {

    for (let index = 0; index < file.length; index++) {
			const imageFile = file[index];

      // get secure url from our server
      const { url } = await fetch(
        "https://apie.snapwrite.ca/storeData/s3Url"
      ).then((res) => res.json());
      // console.log(url)
      // let blobData = new Blob([new Uint8Array(imageFile)], {type: 'image/jpeg'})
      // post the image direclty to the s3 bucket
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
        },
        body: imageFile,
      }).catch((err) => console.error(err));
      imageURL.push(url.split("?")[0]);
		}

		const result = await getResponse(splitArray(imageURL), userID)
      // Inserted here to escape react runtime unhandled error when session (axios.create) fails
      if(!result){
        return
      }
      projectBodyRef.current = await getInitialData();
			const lastProjectLength = projectBodyRef.current.images.length;

    for (let index = 0; index < file.length; index++) {
			skuCounter++
			await getSuggestions(
				file[index].name,
				imageURL[index],
				[],
				{data: result[index]},
        skuCounter,
				objectID.length > 0 && lastProjectLength > 0  ? lastProjectLength + index : index // index
			)
		}
	}

    setProjectData(projectBodyRef.current);
		setConcerns(projectBodyRef.current.concerns);
		setS3Images(projectBodyRef.current.images);
		setBackImage(projectBodyRef.current.backImage);
		setSupplementaryImages(projectBodyRef.current.supplementaryImages);
		setType(projectBodyRef.current.types);
		setItemGenericType(projectBodyRef.current.itemGenericType);
		setTypesTaxoUpdate(projectBodyRef.current.taxonomyUpdatedType);
		setItemName(projectBodyRef.current.itemName);
		setBrand(projectBodyRef.current.brand);
		setSku(projectBodyRef.current.sku);
		setVendor(projectBodyRef.current.vendor);
		setSize(projectBodyRef.current.size);
		setSizeNotes(projectBodyRef.current.sizeNotes);
		setCostPerItem(projectBodyRef.current.cost);
		setPrice(projectBodyRef.current.price);
		setRetailPrice(projectBodyRef.current.retailPrice);
		setCondition(projectBodyRef.current.condition);
		setConditionDescription(projectBodyRef.current.conditionDescription);
		setDescription(projectBodyRef.current.description);
		setImageFileName(projectBodyRef.current.fileNames);
		setColor(projectBodyRef.current.colors);
		setGender(projectBodyRef.current.gender);
		setVariantGrams(projectBodyRef.current.variantGrams);
		setTags(projectBodyRef.current.tags);
		setMaterial(projectBodyRef.current.material);
		setSleeves(projectBodyRef.current.sleeves);
		setStyle(projectBodyRef.current.style);
		setLength(projectBodyRef.current.length);
		setAge(projectBodyRef.current.age);
		setClosure(projectBodyRef.current.closure);
		setFastening(projectBodyRef.current.fastening);
		setFasteningType(projectBodyRef.current.fastening_type);
		setNeckline(projectBodyRef.current.neckline);
		setCut(projectBodyRef.current.cut);
		setHood(projectBodyRef.current.hood);
		setTypeObj(projectBodyRef.current.typeObj);
		setBelt(projectBodyRef.current.belt);
		setDesign(projectBodyRef.current.design);
		setLining(projectBodyRef.current.lining);
		setLayers(projectBodyRef.current.layers);
		setPattern(projectBodyRef.current.pattern);
		setSkirt(projectBodyRef.current.skirt);
		setRise(projectBodyRef.current.rise);
		setHeelHeight(projectBodyRef.current.heelHeight);
		setHeelShape(projectBodyRef.current.heelShape);
		setHeight(projectBodyRef.current.height);
		setToe(projectBodyRef.current.toe);
		setEra(projectBodyRef.current.era);
		setMadeIn(projectBodyRef.current.madeIn);
		setLengthMeasure(projectBodyRef.current.lengthMeasure);
		setsleeveInSeam(projectBodyRef.current.sleeveInSeam);
		setPitToPitMeasure(projectBodyRef.current.pitToPitMeasure);
		setShoulderMeasure(projectBodyRef.current.shoulderMeasure);
		setWaistMeasure(projectBodyRef.current.waistMeasure);
		setHipMeasure(projectBodyRef.current.hipMeasure);
		setBottomInSeam(projectBodyRef.current.bottomInSeam);
		setRiseMeasure(projectBodyRef.current.riseMeasure);
		setThighMeasure(projectBodyRef.current.thighMeasure);
		setLegOpening(projectBodyRef.current.legOpening);
		setAddtionaInfo(projectBodyRef.current.additionalInfo);
		setHeelHeightMeasure(projectBodyRef.current.heelHeightMeasure);
		setSoleMeasure(projectBodyRef.current.soleMeasure);
		setInSoleMeasure(projectBodyRef.current.inSoleMeasure);


    setLoading(false);
		setSKUStart(skuCounter)
	//	updateSKUstart(skuCounter, userID)
    const getNewlyAddedElements = (element, typeOfArray="1dArray") => {
      if (element && typeOfArray!=="2dArray"){
        return element.slice(-file.length)
      } else if (element && typeOfArray==="2dArray") {
        return element
      }
      if (typeOfArray==="2dArray"){
        return Array(element.length).fill([])
      } else {
        return Array(file.length).fill("")
      }
    }  

    const body = {
      images: getNewlyAddedElements(projectBodyRef.current.images),
      backImage: getNewlyAddedElements(projectBodyRef.current.backImage),
      supplementaryImages: getNewlyAddedElements(
        projectBodyRef.current.supplementaryImages,
        '2dArray'
      ),
      types: getNewlyAddedElements(projectBodyRef.current.types),
      itemGenericType: getNewlyAddedElements(projectBodyRef.current.itemGenericType),
      taxonomyUpdatedType: getNewlyAddedElements(projectBodyRef.current.taxonomyUpdatedType),
      itemName: getNewlyAddedElements(projectBodyRef.current.itemName),
      brand: getNewlyAddedElements(projectBodyRef.current.brand),
      sku: getNewlyAddedElements(projectBodyRef.current.sku),
      vendor: getNewlyAddedElements(projectBodyRef.current.vendor),
      size: getNewlyAddedElements(projectBodyRef.current.size),
      sizeNotes: getNewlyAddedElements(projectBodyRef.current.sizeNotes),
      cost: getNewlyAddedElements(projectBodyRef.current.cost),
      price: getNewlyAddedElements(projectBodyRef.current.price),
      retailPrice: getNewlyAddedElements(projectBodyRef.current.retailPrice),
      condition: getNewlyAddedElements(projectBodyRef.current.condition),
      conditionDescription:
        getNewlyAddedElements(projectBodyRef.current.conditionDescription),
      description: getNewlyAddedElements(projectBodyRef.current.description),
      fileNames: getNewlyAddedElements(projectBodyRef.current.fileNames),
      colors: getNewlyAddedElements(projectBodyRef.current.colors),
      gender: getNewlyAddedElements(projectBodyRef.current.gender),
      variantGrams: getNewlyAddedElements(projectBodyRef.current.variantGrams),
      tags: getNewlyAddedElements(projectBodyRef.current.tags, '2dArray'),
      material: getNewlyAddedElements(projectBodyRef.current.material),
      sleeves: getNewlyAddedElements(projectBodyRef.current.sleeves),
      style: getNewlyAddedElements(projectBodyRef.current.style),
      length: getNewlyAddedElements(projectBodyRef.current.length),
      age: getNewlyAddedElements(projectBodyRef.current.age),
      closure: getNewlyAddedElements(projectBodyRef.current.closure),
      fastening: getNewlyAddedElements(projectBodyRef.current.fastening),
      fastening_type: getNewlyAddedElements(projectBodyRef.current.fastening_type),
      neckline: getNewlyAddedElements(projectBodyRef.current.neckline),
      cut: getNewlyAddedElements(projectBodyRef.current.cut),
      hood: getNewlyAddedElements(projectBodyRef.current.hood),
      typeObj: getNewlyAddedElements(projectBodyRef.current.typeObj),
      belt: getNewlyAddedElements(projectBodyRef.current.belt),
      design: getNewlyAddedElements(projectBodyRef.current.design),
      lining: getNewlyAddedElements(projectBodyRef.current.lining),
      layers: getNewlyAddedElements(projectBodyRef.current.layers),
      pattern: getNewlyAddedElements(projectBodyRef.current.pattern),
      skirt: getNewlyAddedElements(projectBodyRef.current.skirt),
      rise: getNewlyAddedElements(projectBodyRef.current.rise),
      heelHeight: getNewlyAddedElements(projectBodyRef.current.heelHeight),
      heelShape: getNewlyAddedElements(projectBodyRef.current.heelShape),
      height: getNewlyAddedElements(projectBodyRef.current.height),
      toe: getNewlyAddedElements(projectBodyRef.current.toe),
      era: getNewlyAddedElements(projectBodyRef.current.era),
      madeIn: getNewlyAddedElements(projectBodyRef.current.madeIn),
      //Measurements
      pitToPitMeasure: getNewlyAddedElements(projectBodyRef.current.pitToPitMeasure),
      shoulderMeasure: getNewlyAddedElements(projectBodyRef.current.shoulderMeasure),
      sleeveInSeam: getNewlyAddedElements(projectBodyRef.current.sleeveInSeam),
      lengthMeasure: getNewlyAddedElements(projectBodyRef.current.lengthMeasure),
      waistMeasure: getNewlyAddedElements(projectBodyRef.current.waistMeasure),
      hipMeasure: getNewlyAddedElements(projectBodyRef.current.hipMeasure),
      bottomInSeam: getNewlyAddedElements(projectBodyRef.current.bottomInSeam),
      riseMeasure: getNewlyAddedElements(projectBodyRef.current.riseMea),
      thighMeasure: getNewlyAddedElements(projectBodyRef.current.thighMeasure),
      legOpening: getNewlyAddedElements(projectBodyRef.current.legOpening),
      additionalInfo: getNewlyAddedElements(projectBodyRef.current.additionalInfo),
      heelHeightMeasure: getNewlyAddedElements(projectBodyRef.current.heelHeightMeasure),
      soleMeasure: getNewlyAddedElements(projectBodyRef.current.soleMeasure),
      inSoleMeasure: getNewlyAddedElements(projectBodyRef.current.inSoleMeasure),
      shoeWidth: getNewlyAddedElements(projectBodyRef.current.shoeWidth),
      concerns: getNewlyAddedElements(projectBodyRef.current.concerns),
    };

    const snapwriteAPI = "https://apie.snapwrite.ca";
    const currentEndPoint = snapwriteAPI;

    let link = `${currentEndPoint}/storeData/bulk-labeling-appendRows/${props.id}`;
    if (props.template === "vintage") {
      link = `${currentEndPoint}/storeData/vintage-apparel-appendRows/${props.id}`;
    } else if (props.template === "new-clothes") {
      link = `${currentEndPoint}/storeData/new-apparel-appendRows/${props.id}`;
    } else if (props.template === "pre-loved-regular") {
      link = `${currentEndPoint}/storeData/regular-preLoved-apparel-appendRows/${props.id}`;
    }
    // Update the existing items.
    axios
      .put(link, body)
      .then((response) => {
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });

    const singleProductLabelEndpoint =
      "https://apie.snapwrite.ca/storeData/single-product";
      const bodyWithProjectID = {
        ...body,
        userID: userID,
        projectID: props.id,
        storeName: [organization?.name],
        organizationID: [organization?.id]
      }
    console.log(body)
    axios.post(singleProductLabelEndpoint, bodyWithProjectID).then((res) => console.log(res)).catch((err) => console.log(err));

		projectBodyRef.current = {} // reset the projectBodyRef.current
  };

 
	const Table = <TableList
                        fetchedBrands={fetchedBrands}
                        preferredItemTypes={preferredItemTypes}
                        template={props.template}
                        setColumns={setColumns}
                        objectID={props.id}
                        setImageFileName={setImageFileName}
                        setS3Images={setS3Images}
                        updateVal={updateVal}
												updateValIndividual={updateValIndividual}
                        setType={setType}
                        s3Images={s3Images}
                        supplementaryImages={supplementaryImages}
                        setSupplementaryImages={setSupplementaryImages}
                        backImage={backImage}
                        setBackImage={setBackImage}
                        imageFileName={imageFileName}
                        itemGenericType={itemGenericType}
                        setItemGenericType={setItemGenericType}
                        types={types}
                        typesTaxoUpdate={typesTaxoUpdate}
                        setTypesTaxoUpdate={setTypesTaxoUpdate}
                        sku = {sku}
                        setSku = {setSku}               
                        brand={brand}
                        setBrand={setBrand}
                        vendor={vendor}
                        setVendor={setVendor}
                        condition={condition}
                        setCondition={setCondition}
                        conditionDescription={conditionDescription}
                        setConditionDescription={setConditionDescription}
                        description={description}
                        setDescription={setDescription}
                        itemName={itemName}
                        setItemName={setItemName}
                        size={size}
                        setSize={setSize}
                        sizeNotes={sizeNotes}
                        setSizeNotes={setSizeNotes}
                        price={price}
                        setPrice={setPrice}
                        retailPrice={retailPrice}
                        setRetailPrice={setRetailPrice}
                        costPerItem={costPerItem}
                        setCostPerItem={setCostPerItem}
                        genders={genders}
                        setGender={setGender}
                        colors={colors}
                        setColor={setColor}
                        additionalInfos={additionalInfos}
                        setAdditionalInfo={setAddtionaInfo}
                        variantGrams={variantGrams}
                        setVariantGrams={setVariantGrams}
                        tags={tags}
                        setTags={setTags}
                        userID={userID}
                        material={material}
                        setMaterial={setMaterial}
                        length={length}
                        setLength={setLength}
                        style={style}
                        setStyle={setStyle}
                        age={age}
                        setAge={setAge}
                        design={design}
                        setDesign={setDesign}
                        closure={closure}
                        setClosure={setClosure}
                        fastening={fastening}
                        setFastening={setFastening}
                        fasteningType={fastening_type}
                        setFasteningType={setFasteningType}
                        belt={belt}
                        setBelt={setBelt}
                        cut={cut}
                        setCut={setCut}
                        neckline={neckline}
                        setNeckline={setNeckline}
                        sleeves={sleeves}
                        setSleeves={setSleeves}
                        hood={hood}
                        setHood={setHood}
                        typeObj={typeObj}
                        setTypeObj={setTypeObj}
                        lining={lining}
                        setLining={setLining}
                        layers={layers}
                        setLayers={setLayers}
                        pattern={pattern}
                        setPattern={setPattern}
                        skirt={skirt}
                        setSkirt={setSkirt}
                        rise={rise}
                        setRise={setRise}
                        heelHeight={heelHeight}
                        setHeelHeight={setHeelHeight}
                        heelShape={heelShape}
                        setHeelShape={setHeelShape}
                        height={height}
                        setHeight={setHeight}
                        toe={toe}
                        setToe={setToe}
                        // Measurements

                        waistMeasure={waistMeasure}
                        setWaistMeasure={setWaistMeasure}
                        hipMeasure={hipMeasure}
                        setHipMeasure={setHipMeasure}
                        bottomInSeam={bottomInSeam}
                        setBottomInSeam={setBottomInSeam}
                        riseMeasure={riseMeasure}
                        setRiseMeasure={setRiseMeasure}
                        thighMeasure={thighMeasure}
                        setThighMeasure={setThighMeasure}
                        legOpening={legOpening}
                        setLegOpening={setLegOpening}
                        sleeveInSeam={sleeveInSeam}
                        setsleeveInSeam={setsleeveInSeam}
                        pitToPitMeasure={pitToPitMeasure}
                        setPitToPitMeasure={setPitToPitMeasure}
                        shoulderMeasure={shoulderMeasure}
                        setShoulderMeasure={setShoulderMeasure}
                        lengthMeasure={lengthMeasure}
                        setLengthMeasure={setLengthMeasure}
                        // shoes
                        heelHeightMeasure={heelHeightMeasure}
                        setHeelHeightMeasure={setHeelHeightMeasure}
                        soleMeasure={soleMeasure}
                        setSoleMeasure={setSoleMeasure}
                        inSoleMeasure={inSoleMeasure}
                        setInSoleMeasure={setInSoleMeasure}
                        shoeWidth={shoeWidth}
                        setShoeWidth={setShoeWidth}

                        concerns={concerns}
                        setConcerns={setConcerns}
                        // Vintage

                        era={era}
                        setEra={setEra}
                        madeIn={madeIn}
                        setMadeIn={setMadeIn}
                        isLoading={isLoading}
                        setLoading={setLoading}

												// Filter
												filterVendor={filterVendor}
												isVendor={props.isVendor}

                        //Items delete setters
                        isItemsDeleted={isItemsDeleted}
                        setIsItemsDeleted={setIsItemsDeleted}
                         // Utilized for ExportButton.js
                         downloadCSV = {downloadCSV}
                         generateCSVData = {generateCSVData}
                         isVendorColSelected={isVendorColSelected}
                         setIsVendorColSelected={setIsVendorColSelected}
                         theme = {theme}
                         themeItemsId = {themeItemsId}
                         sharedWith = {sharedWith}
                         setSharedWith = {setSharedWith}
                         offerAcceptStatus={offerAcceptStatus}
                         setOfferAcceptStatus={setOfferAcceptStatus}
                         accountType={accountType}
                         setAccountType={setAccountType}
                         projectData = {projectData}
                         setProjectData = {setProjectData}
                         uploadImage = {uploadImage}
                      />

	if (props.isVendor) {
		return (
			<div className="descriptions">
				<div className="interface interfaceFeatureTable">
					<div
						className="playGround playgroundFeatureTable"
						style={{ margin: 0 }}
					>
						<div className="ipadView table">
							<div
								className="bulk-upload bulk-upload-FeatureTable"
								style={{ margin: 0, marginTop: 30, width: "100%" }}
							>
								<div id="observation-data">
									{Table}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}


  

  return (
    <>
      <MainSideMenuHeaderContainer
      updateProjectName={updateProjectName}
      setProjectName={setProjectName}
      projectName={props.template === "themes" && projectName === undefined? "Untitled"  :projectName}
      name={"labels"}
      >
			<Snackbar open={success} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Successful!
        </Alert>
      </Snackbar>
			<Snackbar open={fail} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
					Error
        </Alert>
      </Snackbar>
   
         
						{/* {
							// (props.userID === "CXNDhrE6FfNRdXNm6nUQlmck29f2" ||
							// props.userID === "xHaYXalwv8X8RpBLZju0PaQlWW23" || // (Piece by Piece)
							// props.userID === "7eXVFZlMcgY7HMKKSkCf4kb7qEt1" || 
							// props.userID === "1eMplQPlHHhX9lnwX8NTcKghui22" || 
							// props.userID === 'FYLY9osdEkU7QtLsTgtmitcmqAD2' || 
							// props.userID === '80saF2wZqdRUhagajkE83wam8Js2') // (Cherry Pick Vintage)
							// &&
              columns && columns.vendor ? (
							<Stack style={{position: "fixed", top: 115, right:50}} spacing={2} direction="row">
                <Autocomplete
									disablePortal
									disableClearable
									id="brand-autocomplete"
									options={['Show All'].concat(vendor.filter(onlyUnique))}
									defaultValue={"Show All"}
									// getOptionLabel={option => option.displayName}
									sx={{ width: 300, fontSize: "normal", paddingBottom: 2}}
									size="small"
									// getOptionSelected={(option, value) => option.label === value.label}
									// isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
									onChange={(e, value) => setFilterVendor(value)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Filter by Vendor"
											sx={{ fontSize: "normal" }}
										/>
									)}
								/>
								<TextField
									error={email.length !== 0 && !emailRegex.test(email.toLowerCase())}
									id="outlined-error"
									label="Email"
									defaultValue=""
									size="small"
									value={email}
									onChange={e=>setEmail(e.target.value)}
								/>
								<Button style={{backgroundColor: "#E1FC00", color: "black", height: 40}} variant="contained" size="small" onClick={ async ()=>{
									fetch("https://apie.snapwrite.ca/storeData/send-email", {

										method: "POST",
										headers: {
												"Content-Type": "application/json",
										},
										body: JSON.stringify({
											recipient: email,
											subject: `${organization?.name || auth.currentUser.displayName}: Your Order Form`,
											message: `Hey ${filterVendor},\nThis is ${organization?.name || auth.currentUser.displayName}! We finished going through your drop off and you can view the items that we selected here: https://app.snapwrite.ca/vendor-view/${props.id}?t=${props.template}&v=${filterVendor.split(" ").join("-")}`
										})
									}).then(res => {
										if (res.ok) setSuccess(true)
									}).catch(error => {
										setFail(true)
										console.error(error)
									})
								}}
								disabled={typeof filterVendor !== 'string' || filterVendor === "" || filterVendor === "Show All" || !emailRegex.test(email.toLowerCase())}
								>
									Send
								</Button>
							</Stack>) : null
							} */}
              {s3Images.length === 0 && rows !== "Loading..." && !isLoading ? (
                <></>
              ) : (
                <>
                  {isLoading ? (
                    <h2 style={{ textAlign: "center" }}>
                      {waitingText}
                      {waitingText ===
                      "Enhancing SEO. Please Wait for about a minute" ? (
                        <>
                          <br />
                          <img
                            src="https://media.tenor.com/fJ2vm3_UhcAAAAAi/google-working.gif"
                            alt="loading..."
                          />
                        </>
                      ) : (
                        <>
                          <br />
                          <img
                            src="https://media.tenor.com/qxgH66DCp7EAAAAi/%E3%81%A1%E3%82%87%E3%81%A3%E3%81%A8%E5%BE%85%E3%81%A3%E3%81%A6-%E5%B0%91%E3%81%97%E6%99%82%E9%96%93%E3%82%92%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84.gif"
                            alt="loading..."
                          />
                        </>
                      )}
                    </h2>
                  ) : (
                    <>
                      {Table}
                      </>
                  )}
                </>
              )}
          {(!isLoading && window.location !== window.parent.location) &&  // hide buttons when loading
            <div style={{ zIndex: 1000, position: "absolute", bottom: 12, left: "47%" }} className="suggestions bg-slate-500">
              <div>
                <span
                  className="suggestionTags"
                  style={{ display: "flex", gap: "20px" }}
                >                 
                  {<ImportButton {...{ csvDownload, projectName, setFail, setSuccess, generateCSVData, userID, size, template, objectID }} />}                                     
                  </span>
                </div>
              </div>
							}
              <CSVLink
                id="csvLinkButton"
                hidden
                className="btn"
                data={csvDownload}
                // onClick={async ()=> await generateCSVData()}

                filename={`${
                  projectName !== "" ? projectName : "Import"
                }_SnapWriteAI.csv`}
              />	

         
            </MainSideMenuHeaderContainer>
    </>
  );
};

export default SortedClothingLabelsPage;