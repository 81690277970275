import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import { Header } from "../../../../../components/navigation/header";
import SideMenu from "../../../../../components/sideMenu";
import createcsv from "../../utils/generateCsv";
import TableList from "../../tableList";
import ItemNameFiller from "../../utils/itemNameAutoFill";
import TaxonomyUpdate from "../../taxonomyUpdate";
import storeData from "../../utils/storeData";
import updateColumnValue from "../../utils/updateAttribute";
import DemoTable from "./clothingDemoTable";

const backendClient = axios.create({
  baseURL: "https://apif.snapwrite.ca/",
});

export const ApperalDemo = (props) => {
  
    const [isLoading, setLoading] = useState(false);  
    const [csvDownload, SetCsvDownload] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [objectID, setObjectID] = useState("")
    const [s3Images, setS3Images] = useState([]);
    const [backImage, setBackImage] = useState([]);
    const [supplementaryImages, setSupplementaryImages] = useState([]);
    const [imageFileName, setImageFileName] = useState([]);
    const [itemGenericType, setItemGenericType] = useState([]);
    const [itemName, setItemName] = useState([]);
    const [types, setType] = useState([]);
    const [typesTaxoUpdate, setTypesTaxoUpdate] = useState([]);
    const [brand, setBrand] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [description, setDescription] = useState([]);
    const [conditionDescription, setConditionDescription] = useState([]); 
    const [size, setSize] = useState([]);
    const [condition, setCondition] = useState([]);
    const [costPerItem, setCostPerItem] = useState([]);
    const [price, setPrice] = useState([]);
    const [retailPrice, setRetailPrice] = useState([]);
    const [genders, setGender] = useState([]);
    const [colors, setColor] = useState([]);
    const [additionalInfos, setAddtionaInfo] = useState([]);
    const [variantGrams, setVariantGrams] = useState([]);
    
    /* Properties */
    const [tags, setTags] = useState([]);
    
    const [material, setMaterial] =useState([])
    const [length, setLength] = useState([])
    const [style, setStyle] = useState([])
    const [age, setAge] = useState([])
    const [design, setDesign]= useState([])
    const [closure, setClosure] = useState([])
    const [fastening, setFastening] = useState([])
    const [fastening_type, setFasteningType] = useState([])
    const [belt, setBelt] = useState([])
    const [cut, setCut] = useState([])
    const [neckline, setNeckline] = useState([])
    const [sleeves, setSleeves] = useState([])
    const [hood, setHood] = useState([])
    const [typeObj, setTypeObj] = useState([])
    const [lining, setLining] = useState([])
    const [layers, setLayers] = useState([])
    const [pattern, setPattern] = useState([])
    const [skirt, setSkirt] = useState([])
    const [rise, setRise] = useState([])
    const [heelHeight, setHeelHeight] = useState([])
    const [heelShape, setHeelShape] = useState([])
    const [height, setHeight] = useState([])
    const [toe, setToe] = useState([])
    const [era, setEra] = useState([])
    //Measurements
    const [waistMeasure, setWaistMeasure] = useState([])
    const [hipMeasure, setHipMeasure] = useState([])
    const [lengthMeasure, setLengthMeasure] = useState([])
    const [sleeveInSeam, setsleeveInSeam] = useState([])
    const [pitToPitMeasure, setPitToPitMeasure] = useState([])
    const [shoulderMeasure, setShoulderMeasure] = useState([])
  
    const userID = "demo";
  
    useEffect(() => {
      const projectName = JSON.parse(localStorage.getItem(`bulkShopifyProjectNameDemoClothing`))
      const imageData = JSON.parse(
        localStorage.getItem(`bulkImageLinkDemoClothing`)
      );
      const backImageLS = JSON.parse(
        localStorage.getItem(`backImageDemoClothing`)
      );
      const supplementaryImagesLS = JSON.parse(
        localStorage.getItem(`supplementaryImagesDemoClothing}`)
      );
      const imageName = JSON.parse(
        localStorage.getItem(`bulkImageNameDemoClothing`)
      );
      const itemName = JSON.parse(localStorage.getItem(`itemNameDemoClothing`));
      const genericItemType = JSON.parse(localStorage.getItem(`genericItemTypeDemoClothing`))
      // console.log(genericItemType)
      const productType = JSON.parse(
        localStorage.getItem(`productTypesDemoClothing`)
      );
      const productTaxonomy = JSON.parse(localStorage.getItem(`typesTaxoDemoClothing`));
      const brand = JSON.parse(localStorage.getItem(`clothingBrandDemoClothing`));
      const vendor = JSON.parse(localStorage.getItem(`clothingVendorDemoClothing`));
      const size = JSON.parse(localStorage.getItem(`sizeDemoClothing`));
      const condition = JSON.parse(localStorage.getItem(`conditionDemoClothing`))
      const conditionDescription = JSON.parse(localStorage.getItem(`conditionDescriptionDemoClothing`))
      const description = JSON.parse(localStorage.getItem(`descriptionDemoClothing`))
      const gender = JSON.parse(localStorage.getItem(`genderDemoClothing`));
      const color = JSON.parse(localStorage.getItem(`colorDemoClothing`));
      const price = JSON.parse(localStorage.getItem(`priceDemoClothing`));
      const cost = JSON.parse(localStorage.getItem(`costDemoClothing`));
      const retail = JSON.parse(localStorage.getItem(`retailDemoClothing`));
      const additionalInfo = JSON.parse(
        localStorage.getItem(`additionalInfoDemoClothing`)
      );
      const weight = JSON.parse(localStorage.getItem(`itemWeightDemoClothing`))
      const row = JSON.parse(localStorage.getItem(`isLoadingDemoClothing`));
      const tag = JSON.parse(localStorage.getItem(`tagsDemoClothing`));
      const materials = JSON.parse(localStorage.getItem(`materialDemoClothing`));
      const lengths = JSON.parse(localStorage.getItem(`lengthDemoClothing`));
      const styles = JSON.parse(localStorage.getItem(`styleDemoClothing`));
      const ages = JSON.parse(localStorage.getItem(`ageDemoClothing`));
      const designs = JSON.parse(localStorage.getItem(`designDemoClothing`));
      const closures = JSON.parse(localStorage.getItem(`closureDemoClothing`));
      const fastenings = JSON.parse(localStorage.getItem(`fasteningDemoClothing`));
      const fastening_types = JSON.parse(localStorage.getItem(`fastening_typeDemoClothing`));
      const belts = JSON.parse(localStorage.getItem(`beltDemoClothing`));
      const cuts = JSON.parse(localStorage.getItem(`cutDemoClothing`));
      const necklines = JSON.parse(localStorage.getItem(`necklineDemoClothing`));
      const sleevess = JSON.parse(localStorage.getItem(`sleevesDemoClothing`));
      const hoods = JSON.parse(localStorage.getItem(`hoodDemoClothing`));
      const typeObjs = JSON.parse(localStorage.getItem(`typeObjDemoClothing`));
      const objectid = JSON.parse(localStorage.getItem(`objectIDShopifyDemoClothing`))
  
      const liningLS = JSON.parse(localStorage.getItem(`liningDemoClothing`));
      const layersLS = JSON.parse(localStorage.getItem(`layersDemoClothing`));
      const patternLS = JSON.parse(localStorage.getItem(`patternDemoClothing`));
      const skirtLS = JSON.parse(localStorage.getItem(`skirtDemoClothing`));
      const riseLS = JSON.parse(localStorage.getItem(`riseDemoClothing`));
      const heelHeightLS = JSON.parse(localStorage.getItem(`heelHeightDemoClothing`))
      const heelShapeLS = JSON.parse(localStorage.getItem(`heelShapeDemoClothing`))
      const heightLS = JSON.parse(localStorage.getItem(`heightDemoClothing`))
      const toeLS = JSON.parse(localStorage.getItem(`toeDemoClothing`))
      
      //Vintage
      const eraLS = JSON.parse(localStorage.getItem(`eraLSDemoClothing`))
  
      //Measurements
  
      const waistMeasureLS = JSON.parse(localStorage.getItem(`waistMsDemoClothing`))
      const sleeveInSeamLS = JSON.parse(localStorage.getItem(`sleeveMsDemoClothing`))
      const lengthMeasureLS = JSON.parse(localStorage.getItem(`lengthMsDemoClothing`))
      const pitToPitMeasureLS = JSON.parse(localStorage.getItem(`pitToPitMsDemoClothing`))
      const shoulderLS = JSON.parse(localStorage.getItem(`shoulderMsDemoClothing`))
      const hipMeasureLS = JSON.parse(localStorage.getItem(`hipMsDemoClothing`))
      
      if (imageData !== null && s3Images.length === 0) {
        if(projectName!==null){
          setProjectName(projectName);
        }
        //Measurements
        if(waistMeasureLS!==null){
          setWaistMeasure(waistMeasureLS);
        }
        if(sleeveInSeamLS!==null){
          setsleeveInSeam(sleeveInSeamLS);
        }
        if(lengthMeasureLS!==null){
          setLengthMeasure(lengthMeasureLS);
        }
        if(pitToPitMeasureLS!==null){
          setPitToPitMeasure(pitToPitMeasureLS)
        }
        if(hipMeasureLS!== null){
          setHipMeasure(hipMeasureLS)
        }
        if(shoulderLS!==null){
          setShoulderMeasure(shoulderLS)
        }
        if(eraLS!==null){
          setEra(eraLS);
        }
        if(imageData!==null){
          setS3Images(imageData);
        }
        if(backImageLS!==null){
          setBackImage(backImageLS);
        }
        if(supplementaryImagesLS!==null){
          setSupplementaryImages(supplementaryImagesLS);
        }
        if (imageName!==null){
          setImageFileName(imageName);
        }
        if(itemName!==null){
          
          setItemName(itemName);
        }
        if(genericItemType!==null){
          setItemGenericType(genericItemType)
        }
        if(productType!==null)
        {
          setType(productType);
        }
        if(productTaxonomy!==null){
          setTypesTaxoUpdate(productTaxonomy)
        }
        if(brand!==null){
          setBrand(brand);
        }
        if(vendor!==null){
          setVendor(vendor);
        }
        if(size!=null){
          setSize(size);
        }
        if(condition!==null){
          setCondition(condition)
        }
        if(conditionDescription!==null){
          setConditionDescription(conditionDescription)
        }
        if(description!==null){
          setDescription(description)
        }
        if(gender!==null){
          setGender(gender);
        }
        if(color!==null){
          setColor(color);
        }
        if(price!==null){
          setPrice(price);
        }
        if(cost!==null){
          setCostPerItem(cost);
        }
        if(retail!==null){
          setRetailPrice(retail);
        }
        if(additionalInfo!==null){
          setAddtionaInfo(additionalInfo);
        }
        if(weight!==null){
          setVariantGrams(weight);
        }
        if(row!==null){
          setLoading(row);
        }
        if(tag!==null){
          setTags(tag)
        }
        if(materials!==null){
          setMaterial(materials)
        }
        if(lengths!==null){
          setLength(lengths)
        }
        if(styles!==null){
          setStyle(styles)
        }
        if(ages!==null){
          setAge(ages)
        }
        if(designs!==null){
          setDesign(designs)
        }
        if(closures!==null){
          setClosure(closures)
        }
        if(fastenings!==null){
          setFastening(fastenings)
        }
        if(fastening_types!==null){
          setFasteningType(fastening_types)
        }
        if(belts!==null){
          setBelt(belts)
        }
        if(cuts!==null){
          setCut(cuts)
        }
        if(necklines!==null){
          setNeckline(necklines)
        }
        if(sleevess!=null){
          setSleeves(sleevess)
        }
        if(hoods!=null){
          setHood(hoods)
        }
        if(typeObjs!=null) {
          setTypeObj(typeObjs)
        } 
        if(liningLS!=null) {
          setLining(liningLS)
        } 
        if(layersLS!=null) {
          setLayers(layersLS)
        } 
        if(patternLS!=null) {
          setPattern(patternLS)
        } 
        if(skirtLS!=null) {
          setSkirt(skirtLS)
        } 
        if(riseLS!=null) {
          setRise(riseLS)
        } 
        if(heelHeightLS!=null) {
          setHeelHeight(heelHeightLS)
        } 
        if(heelShapeLS!=null) {
          setHeelShape(heelShapeLS)
        } 
        if(heightLS!=null) {
          setHeight(heightLS)
        } 
        if(toeLS!=null) {
          setToe(toeLS)
        }       
        if(objectID!==null){
          setObjectID(objectid)
        }    
      }
    }, [userID]);
  
    useEffect(() => {
      // console.log(types)
      localStorage.setItem(`bulkShopifyProjectNameDemoClothing`, JSON.stringify(projectName));
      localStorage.setItem(`bulkImageLinkDemoClothing`, JSON.stringify(s3Images));
      localStorage.setItem(`backImagesDemoClothing`, JSON.stringify(backImage));
      localStorage.setItem(`supplementaryImagesDemoClothing`, JSON.stringify(supplementaryImages));
      localStorage.setItem(
        `bulkImageNameDemoClothing`,
        JSON.stringify(imageFileName)
      );
    
    // Vintage
      localStorage.setItem(`eraLSDemoClothing`, JSON.stringify(era));
  
      localStorage.setItem(`itemNameDemoClothing`, JSON.stringify(itemName));
      localStorage.setItem(`genericItemTypeDemoClothing`, JSON.stringify(itemGenericType))
      localStorage.setItem(`productTypesDemoClothing`, JSON.stringify(types));
      localStorage.setItem(`typesTaxoDemoClothing`, JSON.stringify(typesTaxoUpdate));
      localStorage.setItem(`clothingBrandDemoClothing`, JSON.stringify(brand));
      localStorage.setItem(`clothingVendorDemoClothing`, JSON.stringify(vendor));
      localStorage.setItem(`sizeDemoClothing`, JSON.stringify(size));
      localStorage.setItem(`conditionDemoClothing`, JSON.stringify(condition));
      localStorage.setItem(`descriptionDemoClothing`, JSON.stringify(description));
      localStorage.setItem(`conditionDescriptionDemoClothing`, JSON.stringify(conditionDescription));
      localStorage.setItem(`genderDemoClothing`, JSON.stringify(genders));
      localStorage.setItem(`colorDemoClothing`, JSON.stringify(colors));
      localStorage.setItem(`priceDemoClothing`, JSON.stringify(price));
      localStorage.setItem(`costDemoClothing`, JSON.stringify(costPerItem));
      localStorage.setItem(`retailDemoClothing`, JSON.stringify(retailPrice));
      localStorage.setItem(`tagsDemoClothing`, JSON.stringify(tags));
      localStorage.setItem(`materialDemoClothing`, JSON.stringify(material));
      localStorage.setItem(`lengthDemoClothing`, JSON.stringify(length));
      localStorage.setItem(`styleDemoClothing`, JSON.stringify(style));
      localStorage.setItem(`ageDemoClothing`, JSON.stringify(age));
      localStorage.setItem(`designDemoClothing`, JSON.stringify(design));
      localStorage.setItem(`closureDemoClothing`, JSON.stringify(closure));
      localStorage.setItem(`fasteningDemoClothing`, JSON.stringify(fastening));
      localStorage.setItem(`fastening_typeDemoClothing`, JSON.stringify(fastening_type));
      localStorage.setItem(`beltDemoClothing`, JSON.stringify(belt));
      localStorage.setItem(`cutDemoClothing`, JSON.stringify(cut));
      localStorage.setItem(`necklineDemoClothing`, JSON.stringify(neckline));
      localStorage.setItem(`sleevesDemoClothing`, JSON.stringify(sleeves));
      localStorage.setItem(`hoodDemoClothing`, JSON.stringify(hood));
      localStorage.setItem(`typeObjDemoClothing`, JSON.stringify(typeObj));
      
      localStorage.setItem(
        `additionalInfoDemoClothing`,
        JSON.stringify(additionalInfos)
      );
      localStorage.setItem(`itemWeightDemoClothing`, JSON.stringify(variantGrams))
      localStorage.setItem(`isLoadingDemoClothing`, JSON.stringify(isLoading));
      localStorage.setItem(`objectIDShopifyDemoClothing`, JSON.stringify(objectID));
  
      localStorage.setItem(`liningDemoClothing`, JSON.stringify(lining))
      localStorage.setItem(`layersDemoClothing`, JSON.stringify(layers));
      localStorage.setItem(`patternDemoClothing`, JSON.stringify(pattern));
      localStorage.setItem(`skirtDemoClothing`, JSON.stringify(skirt))
      localStorage.setItem(`riseDemoClothing`, JSON.stringify(rise));
      localStorage.setItem(`heelHeightDemoClothing`, JSON.stringify(heelHeight));
      localStorage.setItem(`heelShapeDemoClothing`, JSON.stringify(heelShape))
      localStorage.setItem(`heightDemoClothing`, JSON.stringify(height));
      localStorage.setItem(`toeDemoClothing`, JSON.stringify(toe));
  
    // Measurements
      localStorage.setItem(`waistMsDemoClothing`, JSON.stringify(waistMeasure));
      localStorage.setItem(`sleeveMsDemoClothing`, JSON.stringify(sleeveInSeam));
      localStorage.setItem(`pitToPitMsDemoClothing`, JSON.stringify(pitToPitMeasure));
      localStorage.setItem(`shoulderMsDemoClothing`, JSON.stringify(shoulderMeasure));
      localStorage.setItem(`lengthMsDemoClothing`, JSON.stringify(lengthMeasure));
      localStorage.setItem(`hipMsDemoClothing`, JSON.stringify(hipMeasure));
    
      
    }, [
      projectName,
      s3Images,
      backImage,
      supplementaryImages,
      imageFileName,
      itemName,
      itemGenericType,
      types,
      typesTaxoUpdate,
      brand,
      vendor,
      condition,
      description,
      size,
      genders,
      colors,
      price,
      costPerItem,
      retailPrice,
      additionalInfos,
      variantGrams,
      isLoading,
      age, 
      belt, 
      closure, 
      conditionDescription, 
      cut, 
      design, 
      fastening, 
      fastening_type, 
      hood, 
      length, 
      material, 
      neckline, 
      sleeves, 
      style, 
      tags, 
      typeObj,
      userID,
      objectID,
      lining,
      layers,
      pattern,
      skirt,
      rise,
      heelHeight,
      heelShape,
      height,
      toe,
      lengthMeasure,
      sleeveInSeam,
      pitToPitMeasure,
      shoulderMeasure,
      waistMeasure,
      hipMeasure,
      era    
    ]);
  
  
  
    async function getSuggestions(string, name, imageURL) {
      try {
        let filename = name.substring(0, name.lastIndexOf(".")) || name;
        const res = await backendClient.post("/clothingfeatures", {
          imgSrc: string,
          user: 'Demo'
        });            
        // console.log(res.data)
        const imageName = imageFileName;
        imageName.push(filename);
        setImageFileName(imageName);
        
        //Item Name
        const itemNames = itemName;      
  
        //Item Description
        const itemDescriptions = description;
        if(res.data.description){
          itemDescriptions.push(res.data.description)
        } else {
          itemDescriptions.push("");
        }
        setDescription(itemDescriptions)
  
         //Condition Description
         const conditionDescriptions = conditionDescription;
         conditionDescriptions.push("");       
         setConditionDescription(conditionDescriptions)
  
        
         //Era
         const eraTemp = era;
         eraTemp.push("");       
         setEra(eraTemp)
        
         //Measurements 
        const lengthMeasureTemp = lengthMeasure;
        lengthMeasureTemp.push("");       
        setLengthMeasure(lengthMeasureTemp)
        
        const waistMeasureTemp = waistMeasure;
        waistMeasureTemp.push("");       
        setWaistMeasure(waistMeasureTemp)
  
        const hipMeasureTemp = hipMeasure;
        hipMeasureTemp.push("");       
        setHipMeasure(hipMeasureTemp)
        
        const sleeveInSeamTemp = sleeveInSeam;
        sleeveInSeamTemp.push("");       
        setsleeveInSeam(sleeveInSeamTemp)
  
        const pitToPitTemp = pitToPitMeasure;
        pitToPitTemp.push("");       
        setPitToPitMeasure(pitToPitTemp)
  
        const shoulderTemp = shoulderMeasure;
        shoulderTemp.push("");       
        setShoulderMeasure(shoulderTemp)
  
        //s3 Image link
        const images = s3Images;
        images.push(imageURL);
        setS3Images(images);
        
        //Back Image link
        const backImageLinks = backImage;
        backImageLinks.push("");
        setBackImage(backImageLinks);
  
        //Supplementary Image links
        const supplementaryImageLinks = supplementaryImages;
        supplementaryImageLinks.push([]);
        setSupplementaryImages(supplementaryImageLinks);
  
        const firstLetterCapital = (str) =>{        
          return (str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))        
        }
        //Item Generic Type
        const genericType = itemGenericType;      
        // genericType.push(firstLetterCapital(res.data.item.toString().replace(/,/g, " ")));      
        if(res.data.item.toString().replace(/,/g, " ")){
          let typeVal = firstLetterCapital(res.data.item.toString().replace(/,/g, " "));
          // if(typeVal.includes('/')){
          //   typeVal = firstLetterCapital(typeVal.substring(typeVal.indexOf('/') + 1))
          // }
          genericType.push(typeVal);
        }
  
        //Product type
        const type = types;      
        if(res.data.type.length===0){
          if(res.data.item.toString().replace(/,/g, " ")){
            let typeVal = firstLetterCapital(res.data.item.toString().replace(/,/g, " "));
            if(typeVal.includes('/')){
              typeVal = typeVal.substring(typeVal.indexOf('/') + 1)
            }
            type.push(typeVal);
            itemNames.push(ItemNameFiller(userID, typeVal));      
          } else {
            type.push("");
            itemNames.push("");
          }        
        }
        else{
          type.push(firstLetterCapital(res.data.type.toString().replace(/,/g, " ")));        
          itemNames.push(ItemNameFiller('NpGB6zItW1OcpizZJbD88VsfKlf2', firstLetterCapital(res.data.type.toString().replace(/,/g, " "))));
        }
  
        let TaxonomyType=typesTaxoUpdate
        TaxonomyType.push(TaxonomyUpdate('NpGB6zItW1OcpizZJbD88VsfKlf2', res.data.item.toString(), res.data.type.toString()))      
  
        //Product Brand
        let brands = brand;
        brands.push("");
        setBrand(brands);
  
        //Product Vendor
        let vendors = vendor;
        vendors.push("");
        setVendor(vendors);
  
        // Condition
        let conditions = condition;
        if(userID==="CXNDhrE6FfNRdXNm6nUQlmck29f2" || userID==="xHaYXalwv8X8RpBLZju0PaQlWW23"){ //Piece By Piece Kids
          conditions.push("Excellent Used Condition");
        }
        else if(userID==="HDlknVR9NpWxhs9WWlfvnwAQq4b2" || userID==="ZFoq2NIsDmZgVTW4g88O1B8JA822"){
          conditions.push("Very Good");
        }
        else if(userID==="VIa289fKOLZVRQTAEuueJXwvA0y2"){
          conditions.push("Good condition");
        }
        else{
          conditions.push("Excellent");
        }
        setCondition(conditions);
        //Product Price
        let prices = price;
        prices.push("");
        setPrice(prices);
  
        //Cost Per Item
        let cost = costPerItem;
        cost.push("");
        setCostPerItem(cost);
  
        // Retail Price
        let retail = retailPrice;
        retail.push("");
        setRetailPrice(retail)
  
        //Product Size
        let sizes = size;
        if(userID==="VPQROHGh7pNC9du2JnL4fIopeoh1" || userID==="t4gFVUQejYQYBY6dhwOp2mkYMTe2" || userID==='EhZMrcJiDUXrfsWDnrfwfocMVkF3') {//Loose Fade
          sizes.push("M");
        } else{
          sizes.push("");
        }
        setSize(sizes);
  
        //Product Gender
        let gender = genders;
        
        if(res.data.gender.includes("Boys")){
          gender.push("Men");
        }
        if( res.data.gender.includes("Girls")){
          gender.push("Women");
        }else{
          gender.push(firstLetterCapital(res.data.gender.toString().replace(/,/g, " ")));
        }
  
        //Product Color
        let color = colors;
        color.push(firstLetterCapital(res.data.color.toString().replace(/,/g, " ")));
  
        // Additional Information like material etc.
        let additionalInfo = additionalInfos;
        additionalInfo.push("")
        
  
        let materials = material;
        if(res.data.material){
          materials.push(res.data.material.join(', '));
        }
        else {
          materials.push("")
        }
  
        let lengths = length;
        if(res.data.length){
          lengths.push(res.data.length.join(', '));
        }
        else {
          lengths.push("")
        }
  
        let styles = style;
        if(res.data.style){
          styles.push(res.data.style.join(', '));
        }
        else {
          styles.push("")
        }
  
        let ages = age
        if(res.data.age) {
          ages.push(res.data.age.join(', '))
        } else {
          ages.push("")
        }
  
        let designs = design
        if(res.data.design) {
          designs.push(res.data.design.join(', '))
        } else {
          designs.push("")
        }
  
        let closures = closure
        if(res.data.closure) {
          closures.push(res.data.closure.join(', '))
        } else {
          closures.push("")
        }
  
        let fastenings = fastening
        if(res.data.fastening) {
          fastenings.push(res.data.fastening.join(', '))
        } else {
          fastenings.push("")
        }
  
        let fasteningTypes = fastening_type
        if(res.data.fasteningType) {
          fasteningTypes.push(res.data.fasteningType.join(', '))
        } else {
          fasteningTypes.push("")
        }
  
        let belts = belt
        if(res.data.belt) {
          belts.push(res.data.belt.join(', '))
        } else {
          belts.push("")
        }      
        
        let cuts = cut
        if(res.data.cut) {
          cuts.push(res.data.cut.join(', '))
        } else {
          cuts.push("")
        }
        
        let necklines = neckline
        if(res.data.neckline) {
          necklines.push(res.data.neckline.join(', '))
        } else {
          necklines.push("")
        }      
  
        let sleevess = sleeves      
        if(res.data.sleeves) {
          sleevess.push(res.data.sleeves.join(', '))
        } else {
          sleevess.push("")
        }
  
        let hoods = hood
        if(res.data.hood) {
          hoods.push(res.data.hood.join(', '))
        } else {
          hoods.push("")
        }
  
        let linings = lining
        if(res.data.lining) {
          linings.push(res.data.lining.join(', '))
        } else {
          linings.push("")
        }
  
        let layerTemp = layers
        if(res.data.layers) {
          layerTemp.push(res.data.layers.join(', '))
        } else {
          layerTemp.push("")
        }
  
        let patternTemp = pattern
        if(res.data.pattern) {
          patternTemp.push(res.data.pattern.join(', '))
        } else {
          patternTemp.push("")
        }
  
        let skirtTemp = skirt
        if(res.data.skirt) {
          skirtTemp.push(res.data.skirt.join(', '))
        } else {
          skirtTemp.push("")
        }
  
        let riseTemp = rise
        if(res.data.rise) {
          riseTemp.push(res.data.rise.join(', '))
        } else {
          riseTemp.push("")
        }
        
        let heelHeigthTemp = heelHeight
        if(res.data.heelHeight) {
          heelHeigthTemp.push(res.data.heelHeight.join(', '))
        } else {
          heelHeigthTemp.push("")
        } 
        
        let heelShapeTemp = heelShape
        if(res.data.heelShape) {
          heelShapeTemp.push(res.data.heelShape.join(', '))
        } else {
          heelShapeTemp.push("")
        } 
        
        let heightTemp = height
        if(res.data.height) {
          heightTemp.push(res.data.height.join(', '))
        } else {
          heightTemp.push("")
        } 
        
        let toeTemp = toe
        if(res.data.toe) {
          toeTemp.push(res.data.toe.join(', '))
        } else {
          toeTemp.push("")
        }
  
        let typess = typeObj
        if(res.data.types) {
          typess.push(res.data.types.join(', '))        
        } else {
          types.push("")
        }
  
        setMaterial(materials);
        setLength(lengths);
        setStyle(styles);
        setAge(ages);
        setDesign(designs);
        setClosure(closures);
        setFastening(fastenings);
        setFasteningType(fasteningTypes);
        setBelt(belts);
        setCut(cuts);
        setNeckline(necklines);
        setSleeves(sleevess);
        setHood(hoods);
        setTypeObj(typess);
        
        //Variant Grams
        let weight = variantGrams;
        weight.push("");
        setVariantGrams(weight);    
        
        let tag = tags;
        tag.push(res.data.tags);
        setTags(tag)      
        
        setItemName(itemNames);
        setItemGenericType(genericType)    
        setType(type);
        setTypesTaxoUpdate(TaxonomyType)
        setGender(gender);
        setColor(color);
        setAddtionaInfo(additionalInfo);
        
        setLining(linings);
        setLayers(layerTemp);
        setPattern(patternTemp);
        setSkirt(skirtTemp);
        setRise(riseTemp);
        setHeelHeight(heelHeigthTemp);
        setHeelShape(heelShapeTemp);
        setHeight(heightTemp);
        setToe(toeTemp);
      } catch (e) {
        console.log("Please try using a different image");
        console.error(e);
      }
    }
  
    // console.log(imageFiles)
    const uploadImage = async (e, str) => {
      e.preventDefault()
      setLoading(true);
      let file = e.target.files;
      if(str==="drop"){
        file = e.dataTransfer.files;
      }    
      // console.log(e.target.files[0].name)
      const base64 = [];
      //for aws images link
      const imageURL = [];
      for (let index = 0; index < file.length; index++) {
        const imageFile = file[index];
  
        // get secure url from our server
        const { url } = await fetch(
          "https://apie.snapwrite.ca/storeData/s3Url"
        ).then((res) => res.json());
        // console.log(url)
        // let blobData = new Blob([new Uint8Array(imageFile)], {type: 'image/jpeg'})
        // post the image direclty to the s3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "image/jpeg",
          },
          body: imageFile,
        }).catch((err) => console.error(err));
        imageURL.push(url.split("?")[0]);
        // const imageUrl = url.split('?')[0]
        // console.log(imageURL[index])
  
        base64.push(await convertBase64(file[index]));
        await getSuggestions(
          base64[index],
          file[index].name,
          imageURL[index]
        );
      }
      setLoading(false);
      if(objectID===""){
        const sampleArray = Array(s3Images.length).fill("")
        storeData(
          'pre-loved-regular',
          'Demo',
          projectName,
          s3Images,
          backImage,
          supplementaryImages,
          types,
          itemGenericType,
          typesTaxoUpdate,
          itemName,
          brand,
          vendor,
          size,
          sampleArray,
          costPerItem,
          price,
          retailPrice,
          condition,
          conditionDescription,
          description,
          imageFileName,
          colors,
          genders,
          variantGrams,
          tags,
          material,
          sleeves,
          style,
          length,
          age,
          closure,
          fastening,
          fastening_type,
          neckline,
          cut,
          hood,
          typeObj,
          belt,
          design,
          lining,
          layers,
          pattern,
          skirt,
          rise,
          heelHeight,
          heelShape,
          height,
          toe,
          era,
          sampleArray,
          lengthMeasure,
          sleeveInSeam,
          pitToPitMeasure,
          shoulderMeasure,
          waistMeasure,
          hipMeasure,          
          sampleArray,
          sampleArray,
          sampleArray,
          sampleArray,
          setObjectID
        ) 
      }
    };
  
    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };    
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
    const updateVal = (name, event, index, val, setVal) =>{
      const newVal = val.slice();
      if(isNaN(event)){
        if (event.includes(`"`)) {
          event = event.replace(
            /"/g,
            `'`
          );
        }
      }
      newVal[index] = event;
      setVal(newVal);
      if(newVal.length>0 && objectID!==""){
        updateColumnValue(name, newVal, objectID)
      }
    }
  
   
  
    
    const reset = () => {
      setProjectName("");
      setS3Images([]);
      setBackImage([]);
      setSupplementaryImages([])
      setImageFileName([]);
      setItemName([]);
      setItemGenericType([]);
      setType([]);
      setTypesTaxoUpdate([])
      setBrand([]);
      setVendor([]);
      setPrice([]);
      setCostPerItem([]);
      setRetailPrice([]); 
      setSize([]);
      setCondition([]);
      setDescription([]);
      setConditionDescription([])
      setGender([]);
      setColor([]);
      setAddtionaInfo([]);
      setVariantGrams([])
      setLoading(false);
      setTags([]);
      setMaterial([]);
      setLength([]);
      setStyle([]);
      setAge([]);
      setDesign([]);
      setBelt([]);
      setClosure([]);
      setFastening([]);
      setFasteningType([]);
      setSleeves([]);
      setNeckline([]);
      setCut([]);
      setHood([]);
      setTypeObj([]);
      setObjectID("");
      setLining([]);
      setLayers([]);
      setPattern([]);
      setSkirt([]);
      setRise([]);
      setHeelHeight([]);
      setHeelShape([]);
      setHeight([]);
      setToe([]);
      //Vintage
      setEra([]);
      //Measurements
     
      setHipMeasure([])
      setPitToPitMeasure([])
      setShoulderMeasure([])
      setsleeveInSeam([]);
      setLengthMeasure([]);
      setWaistMeasure([]);
      
    };  
  
    const updateProjectName = (event) => {        
      // console.log(event)
  
      setProjectName(event)
        if(objectID!=="") {        
          fetch(`https://apie.snapwrite.ca/storeData/labeling-clothes-categorization-projectNameUpdate`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({        
              id: objectID,      
              projectName: event            
            }),
          })
            .then((response) => response.json())      
            .catch((err) => {
              console.log(`Error ${err}`);
            });      
        }
    }
  
    const generateCSVData = async() =>{    
      setLoading(true);
      const sampleArray = Array(s3Images.length).fill("")
      const priceArray = Array(s3Images.length).fill(20)
      const sizePridiction = Array(s3Images.length).fill('Medium')
      let data = await createcsv('pre-loved-regular', s3Images, 
      itemName, 
      sizePridiction, 
      sampleArray,
      brand, 
      userID, 
      types,
      typesTaxoUpdate, 
      colors, 
      condition, 
      priceArray, 
      genders,
      imageFileName, 
      additionalInfos, 
      costPerItem, 
      variantGrams, 
      itemGenericType, 
      description, 
      vendor, 
      retailPrice, 
      tags, 
      conditionDescription, 
      supplementaryImages, 
      material, 
      era, 
      sampleArray,
      lengthMeasure,
      sleeveInSeam,
      pitToPitMeasure,
      shoulderMeasure,
      waistMeasure,
      hipMeasure,
      sampleArray,
      sampleArray,
      sampleArray,
      sampleArray,
      sleeves,
      style,
      closure,
      fastening_type,
      neckline,
      cut,
      hood,
      belt,
      design,
      lining,
      layers,
      pattern,
      skirt,
      rise,
      heelHeight,
      heelShape,
      height,
      toe
      )      
        SetCsvDownload(data)
        setLoading(false);
    }
   
    return (
      <div className="descriptions" onDrop={(e)=> uploadImage(e, "drop")} onDragOver={(e)=>  e.preventDefault()}>
        {/* {console.log("noOfWordsGenerated = " + noOfWordsGenerated)} */}
        <Header
          reset={reset}
          name={"labels"}
          updateProjectName={updateProjectName}
          setProjectName={setProjectName}
          projectName={projectName}
        />
        {/* <hr width="100%;" /> */}
        {/* ******************************the <main> section****************************** */}
        <div className="interface interfaceFeatureTable">
          <SideMenu />
          {/* The grey section where the user can upload image, edit tags and get the description */}
          <div className="playGround playgroundFeatureTable"   style={{backgroundColor: s3Images.length===0? '#f5f6f8' : 'white'}} id="featureTableWrapper">
          
            {/* <p className="instructionsBar">1 Upload the image 2 Add the tags 3 click on the generate button 4 wait 10 to 20 seconds for the descriptions to appear</p> */}
            <div className="tools ipadView table">
              <div className="bulk-upload bulk-upload-FeatureTable">
                {/* {s3Images.length === 0 && isLoading !== "Loading..." && objectID==="" ? ( */}
                {s3Images.length === 0 && !isLoading ? (
                  <div className="dropPlatform dropPlatformFeatureTable">
                    <label htmlFor="myFile" className="dpc">
                      <div className="drop-zone drop-zoneFeatureTable">
                        <span className="drop-zone__prompt">
                          Click to upload your image
                        </span>
                      </div>
                    </label>
                    <input
                      multiple
                      type="file"
                      name="myFile"
                      id="myFile"
                      className="drop-zone__input"
                      accept="image/*"
                      onChange={(e) => uploadImage(e, "upload")}
                    />
                  </div>
                ) : (
                  <div className="tableLoaded">
                    {isLoading || objectID===""? (
                      <h2 style={{ textAlign: "center" }}>Loading...</h2>
                    ) : (
                      <>
                          <div className="top-generatecsv-button">
                            <ul className="suggestionTags" style={{margin: 0}}>
                              <button
                              className="btn"
                              onClick={async ()=> {await generateCSVData();                             
                                const generateButton = document.getElementById("csvLinkButton")                            
                                generateButton.click()
                              }}
                              style={{padding: "7px", fontSize: "12px", position: 'absolute', zIndex: '1', marginRight:"18%"}}
                          >Generate CSV</button>
                            <CSVLink
                              id="csvLinkButton"
                              hidden
                              className="btn"
                              data={csvDownload}                            
                              filename={`${projectName!==""?projectName : 'Import'}_SnapWriteAI.csv`}                      
                            /> 
                            </ul>
                          </div>
                        <div id="observation-data">
                          <DemoTable
                          setImageFileName={setImageFileName}
                            setS3Images={setS3Images}
                            updateVal={updateVal}
                            setType={setType}                          
                            s3Images={s3Images}
                            supplementaryImages={supplementaryImages}
                            setSupplementaryImages={setSupplementaryImages}
                            backImage={backImage}
                            setBackImage={setBackImage}
                            imageFileName={imageFileName}
                            itemGenericType={itemGenericType}
                            setItemGenericType={setItemGenericType}
                            types={types}
                            typesTaxoUpdate={typesTaxoUpdate} 
                            setTypesTaxoUpdate={setTypesTaxoUpdate}                 
                            brand={brand}
                            setBrand={setBrand}
                            vendor={vendor}
                            setVendor={setVendor}
                            condition={condition}
                            setCondition={setCondition}
                            conditionDescription={conditionDescription}
                            setConditionDescription={setConditionDescription}
                            description={description}
                            setDescription={setDescription}
                            itemName={itemName}       
                            setItemName={setItemName}   
                            size={size}          
                            setSize={setSize}
                            price={price}   
                            setPrice={setPrice}       
                            retailPrice={retailPrice}
                            setRetailPrice={setRetailPrice}
                            costPerItem={costPerItem} 
                            setCostPerItem={setCostPerItem}         
                            genders={genders}             
                            setGender={setGender}       
                            colors={colors}     
                            setColor={setColor}
                            additionalInfos={additionalInfos}
                            setAdditionalInfo={setAddtionaInfo}
                            variantGrams={variantGrams}
                            setVariantGrams={setVariantGrams}
                            tags={tags}
                            setTags={setTags}
                            userID={userID}                          
                            
                            material={material}
                            setMaterial={setMaterial}
                            length={length}
                            setLength={setLength}
                            style={style}
                            setStyle={setStyle}
                            age={age}
                            setAge={setAge}                          
                            design= {design}
                            setDesign={setDesign}
                            closure= {closure}
                            setClosure={setClosure}
                            fastening={fastening}
                            setFastening={setFastening}
                            fasteningType= {fastening_type}
                            setFasteningType={setFasteningType}
                            belt= {belt}
                            setBelt={setBelt}
                            cut= {cut}
                            setCut={setCut}
                            neckline= {neckline}
                            setNeckline={setNeckline}
                            sleeves= {sleeves}
                            setSleeves={setSleeves}
                            hood= {hood}
                            setHood={setHood}
                            typeObj= {typeObj}                              
                            setTypeObj={setTypeObj}
  
                            lining={lining}
                            setLining={setLining}
                            layers={layers}
                            setLayers={setLayers}
                            pattern={pattern}
                            setPattern={setPattern}
                            skirt={skirt}
                            setSkirt={setSkirt}
                            rise={rise}
                            setRise={setRise}
                            heelHeight={heelHeight}
                            setHeelHeight={setHeelHeight}
                            heelShape={heelShape}
                            setHeelShape={setHeelShape}
                            height={height}
                            setHeight={setHeight}
                            toe ={toe}
                            setToe={setToe}
                            
                          // Measurements
  
                            waistMeasure={waistMeasure}
                            setWaistMeasure={setWaistMeasure}
                            hipMeasure={hipMeasure}
                            setHipMeasure={setHipMeasure}
                            sleeveInSeam={sleeveInSeam}
                            setsleeveInSeam={setsleeveInSeam}
                            pitToPitMeasure={pitToPitMeasure}
                            setPitToPitMeasure={setPitToPitMeasure}
                            shoulderMeasure={shoulderMeasure}
                            setShoulderMeasure={setShoulderMeasure}
                            lengthMeasure={lengthMeasure}
                            setLengthMeasure={setLengthMeasure}
                          
                          // Vintage
                            era={era}
                            setEra={setEra}
  
                            isLoading={isLoading}
                            setLoading={setLoading}
                            />
                        </div>
                        <div className="suggestions">
                          <div className="tag-suggestions" >
                          <button
                            className="btn"
                            style={{padding: "7px", fontSize: "12px"}}
                            onClick={async ()=> {await generateCSVData();                               
                              const generateButton = document.getElementById("csvLinkButton")                               
                              generateButton.click()
                            }}
                        >Generate CSV</button>
                          <CSVLink
                            id="csvLinkButton"
                            hidden
                            className="btn"
                            data={csvDownload}
                            // onClick={async ()=> await generateCSVData()}

                            filename={`${projectName!==""?projectName : 'Import'}_SnapWriteAI.csv`}                      
                          />         
                          </div>
                        </div>
                        {/* <div className="backToTop" id="backToTop">
                          <a href="#tableLoaded">
                            <img src={upArrow} />
                          </a>
                        </div> */}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
export default ApperalDemo;

/* ************************************************************* */
