import React, { Component, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../firebase/firebaseDetails";
import "../css/login.css";
import { FormBackground } from "../components/formsBackground/background";

//it resets your password. It doesn’t matter if you are authenticated or not
const PasswordForgetPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const reset = () => {
    setEmail("");
    setError("");
    setEmailSent(false);
  }

  const history = useHistory();
  const backToLogin = () => {
    history.push("login");
  }

  const onSubmit = (event) => {
    
    auth
      .doPasswordReset(email)
      .then(() => {
        reset();
        setEmailSent(true);
        setTimeout(backToLogin, 3000);
      })
      .catch((error) => {
        //console.log(error.code)
        switch(error.code) {
          case 'auth/invalid-email':
                setError("Please check the email")
                break;          
          case 'auth/user-not-found':
                setError('You are not signed up yet! Please click on request access' )
                break;
          default: break;
       }                         
      });

    event.preventDefault();
  };


    return (
      <div className="lgpge">        
        <div className="loginOrSignUpPage">
          <FormBackground />
          <div className="container">
            <div className="centeringDivForContainer">
              <h1>Reset Password</h1>
              <div className="LoginWithId">
                <label htmlFor="email" className="labelForEmail">
                  Email
                </label>
                <br />
                <span style={{color: "red"}}>{error}</span>
                <input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  className="email"
                  placeholder="user@gmail.com"
                  value={email}
                  onChange={(event) =>
                    setEmail(event.target.value)
                  }
                />
              </div>
              <button
                className="logInButtton"
                onClick={(event) => onSubmit(event)}
                disabled={!email}
              >
                Reset Password
              </button>
              <span style={{color: "green"}}>{emailSent ? "Password Reset Email Sent!": ""}</span>
              <hr className="verticalLine"/>
            <p className="registerButton">Don't have an account?&nbsp;
            {/* <Link to="/signup">
                        Create New Account
            </Link>          */}
            <a href="https://snapwrite.ca/survey.html" target="_blank"  rel="noreferrer">
                        Request Access
            </a>
            </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default PasswordForgetPage;


// <form onSubmit={this.onSubmit}>
//   <input
//     value={this.state.email}
//     onChange={event =>
//       this.setState(byPropKey("email", event.target.value))
//     }
//     type="text"
//     placeholder="Email Address"
//   />
//   <button disabled={isInvalid} type="submit">
//     Reset My Password
//   </button>

//   {error && <p>{error.message}</p>}
// </form>
