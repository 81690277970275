// color + type
// design + type
// genedr + type
// age group + type
// brand + tshirt
// if vintage:
// "vintage tshirts"

export const tshirtSynonyms = [
  "T-Shirt",
  "T-Shirts",
  "Tee",
  "Tee Shirt",
  "custom tshirts",
  "custom tshirt",
  "cool tshirt",
  // "Half Sleeves",
  " Top",
  // "Jersey",
  // "graphic tshirts",
  // "Shirt",
  // "Polo shirt",
  // "Crew neck",
  // "Short-sleeve shirt",
  // "Casual shirt",
  // "Baseball shirt",
  // "V-neck shirt",
];

export const rompersSynonyms = [
  "Playsuits",
  "cute rompers",
  "baby romper",
  "infant romper",
  "jumpsuits & rompers",
  "Jumpsuits",
  "One-pieces",
  "Overalls",
  // "Bodysuits",
  // "Shortsuits",
  "Romper"
];

export const sweatshirtSynonyms = [
  "Hoodie",
  "Pullover",
  "Jumper",
  // "Fleece",
  "Sweater",
  "Sweat top",
  "Sweat jumper",
  "Track top",
  "Hooded sweatshirt",
  "Sweatshirt",
  "Sweatshirts",
  "custom sweatshirts",
  "custom sweatshirt",
  "cute sweatshirts",
  "cool sweatshirts"
];

export const dressShirtSynonyms = [
  "Button-down",
  // "Oxford shirt",
  // "Formal shirt",
  // "Business shirt",
  "Dressy shirt",
  // "Tailored shirt",
  "Button-up shirt",
  "Dress blouse",
  "Smart shirt",
  // "Professional shirt",
  "Dress Shirt",
  "Shirts"
];

export const cardiganSynonyms = [
  "Knitwear",
  "Knitted jacket",
  "Button-front sweater",
  "Knit cardigan",
  "Button-up knit",
  "Cardi",
  "Knit jacket",
  "Cardigan",
  "cardigan sweater"
];

export const blouseSynonyms = [
  "Shirtwaist",
  "Blouson top",
  "Shell top",
  "Button-down",
  "Tunic blouse",
  "Dressy top",
  "Peplum top",
  "Ruffled top",
  "Wrap top",
  "Silk top",
  "Blouse"
];

export const shortsSynonyms = [
  // "Bermudas",
  // "Trunks",
  // "Hot pants",
  "Bottoms",
  // "Daisy Dukes",
  // "Board shorts",
  // "Cut-offs",
  // "Culottes",
  // "Gym shorts",
  "Shorts"
];

// export const skirtsSynonyms = [
//   "Pencil skirt",
//   "A-line skirt",
//   "Midi skirt",
//   "Maxi skirt",
//   "Pleated skirt",
//   "Wrap skirt",
//   "Flared skirt",
//   "Circle skirt",
//   "Mini skirt",
//   "Skater skirt",
//   "Skirt"
// ];

export const pantsSynonyms = [
  "Trousers",
  // "Slacks",
  "Bottoms",
  // "Jeans",
  "Chinos",
  // "Denims",
  // "Khakis",
  // "Sweatpants",
  // "Corduroys",
  "Pants"
];

export const leggingsSynonyms = [
  "Tights",
  "Skinny pants",
  "Stretch pants",
  "Yoga pants",
  "Jeggings",
  "Footless tights",
  "Athletic leggings",
  "Workout leggings",
  "Compression pants",
  "Seamless leggings",
  "Leggings"
];

export const jacketsSynonyms = [
  "Coats",
  "Outerwear",
  "Parkas",
  // "Windbreakers",
  "Bombers",
  "Overcoats",
  "Denim jackets",
  // "Leather jackets",
  "Jacket"
];

export const windstoppersSynonyms = [
  "Windbreakers",
  "Wind jackets",
  "Windproof jackets",
  "Wind-resistant jackets",
  "Wind shells",
  "Wind cheaters",
  "Windstoppers"
];

export const blazersSynonyms = [
  "Sport coats",
  "Suit jackets",
  "Sports jackets",
  "Formal jackets",
  "Tailored jackets",
  "Blazer"
];



export const slipDressSynonyms = [
  "Chemise dresses",
  "Camisole dresses",
  "Satin dresses",
  "Silk dresses",
  "Lingerie-inspired dresses",
  "Slip Dress"
];

export const straplessDressSynonyms = [
  "Tube dresses",
  "Bandeau dresses",
  "Off-the-shoulder dresses",
  "Bustier dresses",
  "Sleeveless dresses",
  "Strapless Dress"
];

export const sundressSynonyms = [
  "Summer dresses",
  "Maxi dresses",
  "Floral dresses",
  "Lightweight dresses",
  "Halter dresses",
  "Sundress",
  "Sun Dress"
];

export const gownSynonyms = [
  "Dresses",
  "Ball gowns",
  "Evening gowns",
  "Formal gowns",
  "Elegant dresses",
  "Dressy gowns",
  "Gown"
];

export const baseballCapSynonyms = [
  "Ball caps",
  "Snapback caps",
  "Dad caps",
  "Trucker caps",
  "Sports caps",
  "Brimmed caps",
  'Baseball Cap',
  "Cap"
];

export const beanieSynonyms = [
  "Knit caps",
  "Wool hats",
  "Skull caps",
  "Toboggans",
  "Watch caps",
  "Winter hats",
  "Toques",
  "Beanie"
];

export const bucketHatSynonyms = [
  "Fisherman hats",
  "Sun hats",
  "Reversible hats",
  "Outdoor hats",
  "Boonie hats",
  "Safari hats",
  "Bucket Hat"
];

export const visorSynonyms = [
  "Sun visors",
  "Hat visors",
  "Golf visors",
  "Tennis visors",
  "Sports visors",
  "Cap visors",
  "Visor"
];

export const trainersSynonyms = [
  "Trainers",
  "Athletic shoes",
  "Sports shoes",
  "Tennis shoes",
  "Running shoes",
  "Gym shoes",
  "Kicks",
  "Sneaks",
  // "Joggers",
  "Plimsolls",
  "High-tops",
  "Low-tops",
  "Sneakers",
];

export const bootsSynonyms = [
  "Booties",
  "Ankle boots",
  "Chelsea boots",
  "Combat boots",
  "Work boots",
  "Hiking boots",
  "Cowboy boots",
  "Riding boots",
  "Wellington boots",
  "Knee-high boots",
  "Thigh-high boots",
  "Boots"
];

export const sandalsSynonyms = [
  "Flip-flops",
  "Slippers",
  "Slides",
  "Slip-ons",
  "Beach sandals",
  "Gladiator sandals",
  "Espadrilles",
  "Wedge sandals",
  "Platform sandals",
  "Strappy sandals",
  "Sandals"
];

export const flatsSynonyms = [
  "Ballet flats",
  "Loafers",
  "Slip-ons",
  "Mary Janes",
  "Moccasins",
  "Oxfords",
  "Pointed flats",
  "D'Orsay flats",
  "Skimmer flats",
  "Espadrille flats",
  "Slingback flats",
  "Flats"
];

export const heelsSynonyms = [
  "Pumps",
  "Stilettos",
  "High heels",
  "Platform heels",
  "Wedges",
  "Block heels",
  "Kitten heels",
  "Slingback heels",
  "Peep-toe heels",
  "Ankle-strap heels",
  "Chunky heels",
];

const synonymsArray = [tshirtSynonyms, rompersSynonyms, sweatshirtSynonyms, dressShirtSynonyms, cardiganSynonyms, blouseSynonyms, shortsSynonyms, pantsSynonyms,
                       leggingsSynonyms, jacketsSynonyms, windstoppersSynonyms, blazersSynonyms, slipDressSynonyms, straplessDressSynonyms, sundressSynonyms,
                       gownSynonyms, baseballCapSynonyms, beanieSynonyms, bucketHatSynonyms, visorSynonyms, trainersSynonyms, bootsSynonyms, sandalsSynonyms, 
                       flatsSynonyms, heelsSynonyms]

export const getTypeSynonyms = (type) => {
  let toReturn = []
    for (const synonymList of synonymsArray) {
      if (synonymList === dressShirtSynonyms && type === "T-shirts") {
        continue
      }
        if (synonymList.some(synonym => type.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList]
        }
    }
    return toReturn; // Return an empty array if no synonyms are found
}