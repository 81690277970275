import { sleeveCuffs } from "./sleeveCuffs";
import { sleeveType } from "./sleeveType";

const sleeve = [
    "Arm",
    "Cuff",
    "Bracer",
    "Gauntlet",
    "sleeve"
  ];
  
  const no_sleeve = [
    "Sleeveless",
    "Unsleeved"
  ];

  const synonymsArray = [sleeve, no_sleeve, ...sleeveCuffs, ...sleeveType]

export const getSleeveSynonyms = (sleeve) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => sleeve.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 