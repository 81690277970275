import React, { useEffect, useState } from "react";
import { getAuth } from '@firebase/auth';
import CategorizedTable from "../../components/labelTable";

export function LabelTextCategorization({history}) {
  /* **************************Tags area************************** */
  
  const auth = getAuth();
    const user = auth.currentUser;
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token || !user) {
        history.push('/')
    }
  },[])
    let userID;
    
    if (user !== null) {
      userID= user.uid;
      // console.log(userID)           

        user.providerData.forEach((profile) => {            
            userID = user.uid;                     
        });
      }      

  return (                             
              <CategorizedTable
                userID={userID}
              />                                        
  );
}
export default LabelTextCategorization;
