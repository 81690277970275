const male = [
    "Men",
    "Masculine",
    "Man",
    "Guy",
    "Boy",
    "him",
    "male"
  ];
  
  const female = [
    "Women",
    "Feminine",
    "Ladies",
    "Lady",
    "Woman",
    "Girl",
    "her",
    "female"
  ];

  const synonymsArray = [male, female]

export const getGenderSynonyms = (gender) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => gender.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 