import React, { useEffect, useState } from 'react';
import axios from 'axios';
import createcsv from '../shopify/utils/generateCsv';
import './pdp2.css'; 
import './pdp.css';

const ListingPreview = ({ price, userID, template, objectID, index }) => {
	const [csvData, setCSVData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Function to generate CSV data
				const generateCsvData = async () => {
					return await createcsv(template, userID, objectID, false);
				};

				// Call the function to generate CSV data
				const csvData = await generateCsvData();

				// Extract title and body from CSV data
				const extractedData = csvData.slice(1).map((item) => ({
					title: item[1],
					body: item[2],
				}));
				setCSVData(extractedData);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		// Call the fetchData function when userID changes
		fetchData();
	}, [userID, index]);

	return (
		<>
			{csvData.length > 0 && (
				<div className='product-detail'>
					<h1>{csvData[index].title}</h1>
					<p className='price'>{price || 0} CAD</p>
				</div>
			)}

			<div className='accordion'>
				<h4 className='accordion-title'> Description</h4>
				<hr className='-mt-2 mx-2 text-black' />
				{csvData.length > 0 && (
					<div
						className='mx-3'
						dangerouslySetInnerHTML={{
							__html: csvData[index].body,
						}}
					/>
				)}
			</div>
		</>
	);
};

export default ListingPreview;
