import React, { useState, useEffect } from 'react'
import { getAuth } from '@firebase/auth';
import MultiStep from './StepIndicator' 
import Blog from '../../products/blogs/blogImageToTitle'
import BlogTitleToOutline from '../../products/blogs/blogTitleToOuline'
import BlogOutline from '../../products/blogs/blogOutline';
import { Header } from '../navigation/header'
import SideMenu from '../sideMenu'

// custom styles
const prevStyle = { background: '#33c3f0' }
const nextStyle = { background: '#33c3f0' }
    
const ImageToBlog = ({history}) => {

  const auth = getAuth();
  const user = auth.currentUser;
    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token ||!user) {
            history.push('/')
        }
    },[])

    let userID;
    let userName;

    
    if (user !== null) {
      userID= user.uid;
        user.providerData.forEach((profile) => {            
            userID = user.uid;
            userName = profile.displayName
        });
      }

    const [objectID, setObjectID] = useState("")
    const [noOfWordsGenerated, setNumberOfWordsGenerated] = useState();

    const [projectName, setProjectName] = useState("");
    const [blogTitles, setBlogTitles] = useState(
        ["Blog title will appear in about 10 seconds"]
    );

    const [blogTitle, setBlogTitle] = useState("Title will be here in a few seconds");

    const [blogOutline, setBlogOutline] = useState("Your blog outline will appear in about 5 seconds");

    const [image, setImage] = useState("");
    const [suggestedTags, setSuggestedTags] = useState([""]);
    const [tags, setTags] = useState([]);
    const [activeState, setActiveState] = useState(0)

    const [blogIntro, setBlogIntro] = useState("Blog's introduction will appear here");
    const [blogParaOne, setBlogParaOne] = useState("Blog's paragraph one will appear here");
    const [blogParaTwo, setBlogParaTwo] = useState("Blog's paragraph two will appear here");
    const [blogParaThree, setBlogParaThree] = useState("Blog's paragraph three will appear here");
    const [blogParaFour, setBlogParaFour] = useState("Blog's paragraph four will appear here");
    const [blogParaFive, setBlogParaFive] = useState("Blog's paragraph five will appear here");
    const [blogConclusion, setBlogConclusion] = useState("Blog's conclusion will appear here");
    
    const [titleGenerateClick, setTitleGenerateClick] =useState(0);
    const [outlineGenerateClick, setOutlineGenerateClick] =useState(0);
    const [paragraphGenerateClick, setParagraphGenerateClick] =useState(0);
    const [conclusionGenerateClick, setConclusionGenerateClick] = useState(50);

    const [completeBlog, setCompleteBlog] = useState("")

    useEffect(()=>{    
      //communicate with the backend!
      fetch(`https://apie.snapwrite.ca/storeData/word-count?userID=${userID}`)
      .then(response=>response.json())
      .then(response=>{
        // console.log(response)
  
          setNumberOfWordsGenerated(response.data)
      })    
      .catch(err=>{
          console.log(`Error ${err}`)
      })
  },[ userID])
  

    const MINUTE_MS = 60000;

    useEffect(() => {
      const interval = setInterval(() => {
        setTitleGenerateClick(0);
        setOutlineGenerateClick(0);
        setParagraphGenerateClick(0);        
        setConclusionGenerateClick(0);
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    useEffect(()=>{
      const projectName = JSON.parse(localStorage.getItem("imageToBlogProjectName"))
      const activeState = JSON.parse(localStorage.getItem("imageToBlogActiveState"))
      const imageData = JSON.parse(localStorage.getItem("blogImage"))
      const suggestionData = JSON.parse(localStorage.getItem("blogSuggestedTags"))
      const tagsData = JSON.parse(localStorage.getItem("blogTags"))
      const ideaTitles = JSON.parse(localStorage.getItem("imageTitles"))
      const ideaTitleUsed = JSON.parse(localStorage.getItem("imageTitleUsed"))
      const ideaOutline = JSON.parse(localStorage.getItem("imageOutline"))
      const ideaIntroPara = JSON.parse(localStorage.getItem("imageIntroPara"))
      const ideaPara1 = JSON.parse(localStorage.getItem("imagePara1"))
      const ideaPara2 = JSON.parse(localStorage.getItem("imagePara2"))
      const ideaPara3 = JSON.parse(localStorage.getItem("imagePara3"))
      const ideaPara4 = JSON.parse(localStorage.getItem("imagePara4"))
      const ideaPara5 = JSON.parse(localStorage.getItem("imagePara5"))
      const ideaConclusion = JSON.parse(localStorage.getItem("imageConclusion"))
      const completeBlog = JSON.parse(localStorage.getItem("completeImageBlog"))
      const objectID = JSON.parse(localStorage.getItem("imageBlogObjectID"))
      if(imageData!==null && image===""){
        setProjectName(projectName)
        setActiveState(activeState)
        setImage(imageData)
        setSuggestedTags(suggestionData)
        setTags(tagsData)
        setBlogTitles(ideaTitles)
        setBlogTitle(ideaTitleUsed)
        setBlogOutline(ideaOutline)
        setBlogIntro(ideaIntroPara)
        setBlogParaOne(ideaPara1)
        setBlogParaTwo(ideaPara2)
        setBlogParaThree(ideaPara3)
        setBlogParaFour(ideaPara4)
        setBlogParaFive(ideaPara5)
        setBlogConclusion(ideaConclusion)
        setCompleteBlog(completeBlog)
        setObjectID(objectID)
      }
    }, [])     

    useEffect(() => {
      localStorage.setItem("imageToBlogProjectName", JSON.stringify(projectName))
      localStorage.setItem("imageToBlogActiveState", JSON.stringify(activeState))
      localStorage.setItem("blogImage", JSON.stringify(image))
      localStorage.setItem("blogSuggestedTags", JSON.stringify(suggestedTags))
      localStorage.setItem("blogTags", JSON.stringify(tags))
      localStorage.setItem("imageTitles", JSON.stringify(blogTitles))
      localStorage.setItem("imageTitleUsed", JSON.stringify(blogTitle))
      localStorage.setItem("imageOutline", JSON.stringify(blogOutline))
      localStorage.setItem("imageIntroPara", JSON.stringify(blogIntro))
      localStorage.setItem("imagePara1", JSON.stringify(blogParaOne))
      localStorage.setItem("imagePara2", JSON.stringify(blogParaTwo))
      localStorage.setItem("imagePara3", JSON.stringify(blogParaThree))
      localStorage.setItem("imagePara4", JSON.stringify(blogParaFour))
      localStorage.setItem("imagePara5", JSON.stringify(blogParaFive))
      localStorage.setItem("imageConclusion", JSON.stringify(blogConclusion))
      localStorage.setItem("completeImageBlog", JSON.stringify(completeBlog))
      localStorage.setItem("imageBlogObjectID", JSON.stringify(objectID))
    }, [projectName, activeState, image, suggestedTags, tags, blogTitles, blogTitle, blogOutline, blogIntro, blogParaOne, blogParaTwo, blogParaThree, blogParaFour, blogParaFive, blogConclusion, completeBlog, objectID])

    const steps = [
        { 
            component: <Blog                   
                        noOfWordsGenerated={noOfWordsGenerated}
                        blogTitles={blogTitles}
                        setBlogTitles={setBlogTitles}
                        blogTitle={blogTitle}
                        setBlogTitle={setBlogTitle}
                        blogOutline={blogOutline}
                        setBlogOutline={setBlogOutline}
                        image={image}
                        setImage={setImage}
                        suggestedTags={suggestedTags}
                        setSuggestedTags={setSuggestedTags}
                        tags={tags}
                        setTags={setTags}
                        setTitleGenerateClick={setTitleGenerateClick}
                        titleGenerateClick = {titleGenerateClick}
                        outlineGenerateClick={outlineGenerateClick}
                        setOutlineGenerateClick={setOutlineGenerateClick}
                        user={userID} userName={userName}
                        activeState = {activeState}
                        setActiveState = {setActiveState}
                       /> 
        },
        { component: <BlogTitleToOutline
                      setObjectID={setObjectID}
                      noOfWordsGenerated={noOfWordsGenerated}
                      projectName={projectName}
                      blogTitle={blogTitle}
                      setBlogTitle={setBlogTitle}
                      blogOutline={blogOutline}
                      setBlogOutline={setBlogOutline}
                      setBlogIntro={setBlogIntro}
                      setBlogParaOne={setBlogParaOne}
                      setBlogParaTwo={setBlogParaTwo}
                      setBlogParaThree={setBlogParaThree}
                      setBlogParaFour={setBlogParaFour}
                      setBlogParaFive={setBlogParaFive}
                      setBlogConclusion={setBlogConclusion}  
                      blogIntro={blogIntro}
                      blogParaOne={blogParaOne}
                      blogParaTwo={blogParaTwo}
                      blogParaThree={blogParaThree}
                      blogParaFour={blogParaFour}
                      blogParaFive={blogParaFive}
                      blogConclusion={blogConclusion}                    
                      paragraphGenerateClick={paragraphGenerateClick}
                      setParagraphGenerateClick={setParagraphGenerateClick}                      
                      conclusionGenerateClick={conclusionGenerateClick}
                      setConclusionGenerateClick={setConclusionGenerateClick}
                      activeState = {activeState}
                      imageOrIdea="image"
                      setActiveState = {setActiveState}
                      user={userID} userName={userName}
                      suggestedTags={suggestedTags}
                      tags={tags}
                      blogTitles={blogTitles}
                      image={image}
                     /> 
        },
        { component: <BlogOutline
                      imageOrIdea="image"
                      objectID={objectID}
                      completeBlog={completeBlog}
                      setCompleteBlog={setCompleteBlog}
                      blogTitle={blogTitle}
                      setBlogTitle={setBlogTitle}
                      blogOutline={blogOutline}
                      setBlogOutline={setBlogOutline} 
                      user={userID} userName={userName} 
                      blogIntro={blogIntro}
                      blogParaOne={blogParaOne}
                      blogParaTwo={blogParaTwo}
                      blogParaThree={blogParaThree}
                      blogParaFour={blogParaFour}
                      blogParaFive={blogParaFive}
                      blogConclusion={blogConclusion}
                      />  
                    },    
      ]  
    
    const reset = () => {
      setObjectID("")
        setCompleteBlog("")
        setBlogTitles(["Blog title will appear in about 10 seconds"]);
        setTags([]);
        setImage("");
        setSuggestedTags([""]);      
        setBlogOutline("")
        setActiveState(0)
        setBlogIntro("Blog's introduction will appear here")  
        setBlogParaOne("Blog's paragraph one will appear here")
        setBlogParaTwo("Blog's paragraph two will appear here")
        setBlogParaThree("Blog's paragraph three will appear here")
        setBlogParaFour("Blog's paragraph four will appear here")
        setBlogParaFive("Blog's paragraph five will appear here")
        setBlogConclusion("Blog's conclusion will appear here")
    };
return(
  <div className="App">
            <Header reset={reset} setProjectName={setProjectName} projectName={projectName}/>            
            {/* <hr width="100%;" /> */}
            {/* ******************************the <main> section****************************** */}
            <div className="interface">
                <SideMenu />
                {/* The grey section where the user can upload image, edit tags and get the description */}
                <div className="playGround"> 
                <MultiStep
                activeStep={activeState}
                setActiveStep={setActiveState}
                steps={steps} 
                prevStyle={prevStyle} 
                nextStyle={nextStyle} />
            </div>
        </div>
    </div>
)
}
export default ImageToBlog;