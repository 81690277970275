const attached = [
    "Joined",
    "Affixed",
    "Connected",
    "Linked",
    "Fastened",
    "Secured",
    "Fixed",
    "Adhered",
    "Integrated",
    "attached"
  ];
  
  const detachable = [
    "Removable",
    "Separable",
    "Disconnectable",
    "Unattached",
    "Separated",
    "detachable"
  ];

  export const hoodAttachmentType = [attached, detachable]