import React, { useContext, useState, useEffect, useMemo } from 'react';
import ProjectsGroup from '../Library/projectsGroup';
import { getAuth } from '@firebase/auth';
import GetData from './getData';
import { OrganizationContext } from '../App';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import MainSideMenuHeaderContainer from '../containers/MainSideMenuHeaderContainer';
import { Heading1 } from '../branded-elements/Elements';
import { useMenu } from '../navigation/MenuContext';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


const Projects = () => {
	const history = useHistory()
	let userID;
	const auth = getAuth();
	const user = auth.currentUser;

	const preference = localStorage.getItem('preference');
	const { organization, setOrganization } = useContext(OrganizationContext);

	if (user !== null) {
		userID = auth.currentUser.email;
	}
	const {selectedItem, setSelectedItem} = useMenu()

	const [activeMenu, setActiveMenu] = useState('all');
	const [totalImages, setTotalImages] = useState(0);
	const { caller } = useState('projectsView');

	const [sortedClothingLabels, setSortedClothingLabels] = useState([]);
	const [regularPreLovedApparel, setRegularPreLovedApparel] = useState([]);
	const [vintageApparel, setVintageApparel] = useState([]);
	const [newApparel, setNewApparel] = useState([]);
	const [allProjects, setAllProjects] = useState([]);
	const [lastOrganization, setLastOrganization] = useState({
		id: null,
		name: null,
	});
	const [projectsDisplayed, setProjectsDisplayed] = useState({
		incrementStatus: false,
		value: 20,
	});
	const [mmApparel, setMmApparel] = useState([]); 
	const [mmSportswear, setMmSportswear] = useState([]); 
	const [tempDataHolder, setTempDataHolderCoats] =  useState([])
	const [tempDataHolderSports, setTempDataHolderSports] =  useState([])

	const [sortedLabels, setSortedLabels] = useState([]);

	const [imageDescriptions, setImageDescriptions] = useState([]);

	const [tabValue, setTabValue] = useState('1');
	const isMM =
				userID === 'manteauxmanteauxdemo@snapwrite.ca' ||
				// userID === 'aryamanrastogi01@gmail.com' ||
				userID === 'carmela@manteaux.com' ||
				organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1';

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
		localStorage.setItem('tabValue', newValue);
	};
	const currentPath = history?.location?.pathname
	
	useEffect(() => {
		// Retrieve the lastProjectCardID from history if available, or null if not present
		const lastProjectCardID = history?.location?.state?.lastProjectCardID || null;
	
		// Return early if lastProjectCardID is null or allProjects is empty
		if (lastProjectCardID === null || allProjects.length === 0) return;
	
		// Find the index of lastProjectCardID in allProjects
		const index = allProjects.findIndex((project) => project._id === lastProjectCardID);
	
		// If the index is greater than the current value of projectsDisplayed, update it
		if (index > projectsDisplayed.value) {
			setProjectsDisplayed((prevProjectsDisplayed) => ({
				...prevProjectsDisplayed,
				value: index + 10
			}));
		}
	
		// Scroll to the element with lastProjectCardID if it exists in the DOM
		if (index !== -1) {
			const element = document.getElementById(lastProjectCardID);
			if (element) {
				element.scrollIntoView({ block: "center" });
			}
		}
	}, [history?.location?.state?.lastProjectCardID, allProjects]);
	
	

	const findAndUpdateMMProjects = async (id) => {
		const [foundMmApparelData, foundMmSportswearData] = await Promise.all([
			GetData(id, setTempDataHolderCoats, 'mm-table', 'mm-coats'),
			GetData(id, setTempDataHolderSports, 'mm-sports', 'mm-sports')
		]);
	
		let filteredMmApparel = [];
		let filteredMmSportswear = [];
	
		if (currentPath === '/projects/h3') {
			filteredMmApparel = foundMmApparelData.filter(item =>
				item.season === '' ||
				item.season === 'h3-coats' ||
				item.season === undefined
			);
	
			filteredMmSportswear = foundMmSportswearData.filter(item =>
				item.season === '' ||
				item.season === 'h3-sports' ||
				item.season === undefined
			);
		} else if (currentPath === '/projects') {
			filteredMmApparel = foundMmApparelData.filter(item => item.season === 'p4-coats');
			filteredMmSportswear = foundMmSportswearData.filter(item => item.season === 'p4-sports');
		}
	
		setMmApparel(filteredMmApparel);
		setMmSportswear(filteredMmSportswear);
	};
	

	useEffect(() => {
		if (isMM) {
			findAndUpdateMMProjects(organization?.id);
		}
	}, [selectedItem, currentPath]);

	useEffect(() => {
		const fetchData = async (id) => {
			if (!isMM) {
				const fetchDataPromises = [
					GetData(id, setSortedClothingLabels, 'labeling-clothes-categorization', 'clothing-sort-view'),
					GetData(id, setRegularPreLovedApparel, 'regular-preLoved-apparel', 'preloved-regular-view'),
					GetData(id, setVintageApparel, 'vintage-apparel', 'vintage-apparel-view'),
					GetData(id, setNewApparel, 'new-apparel', 'new-apparel-view')
				];
				if (userID === 'athiya.rastogi@gmail.com' || userID === 'aryamanrastogi01@gmail.com') {
					fetchDataPromises.push(
						GetData(id, setSortedLabels, 'labeling-categorization', 'sorting-view'),
						GetData(id, setImageDescriptions, 'image-description', 'image-description')
					);
				}
				await Promise.all(fetchDataPromises);
			} else {
				findAndUpdateMMProjects(organization?.id);
			}
		};
	
		if (!organization) return;
	
		if (lastOrganization?.id !== organization.id) {
			fetchData(organization.id);
			getImages(organization.id);
			setLastOrganization(organization);
		}
	}, [organization, lastOrganization, isMM, userID]);
	

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			const token = localStorage.getItem('token');

			if (!token || !user) {
				history.push('/');
			}
		}, []);
	}, []);

	useEffect(() => {
		if (
			userID === 'manteauxmanteauxdemo@snapwrite.ca' ||
			userID === 'carmela@manteaux.com' ||
			organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1'
		) {
			return;
		} else {
			setAllProjects(
				[
					...sortedClothingLabels,
					...regularPreLovedApparel,
					...vintageApparel,
					...newApparel,
				].sort(
					(a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
				)
			);
		}
	}, [
		userID,
		organization?.id,
		sortedClothingLabels,
		regularPreLovedApparel,
		vintageApparel,
		newApparel,
	]);

	useEffect(() => {
		if (
			userID === 'manteauxmanteauxdemo@snapwrite.ca' ||
			userID === 'carmela@manteaux.com' ||
			organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1'
		) {
			setAllProjects(
				[...mmApparel, ...mmSportswear].sort(
					(a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
				)
			);
		}
	}, [mmApparel, mmSportswear, userID, organization?.id]);

	const projectCounts = useMemo(() => {
		const projectsCountObj = {
			kids: 0,
			regular: 0,
			vintage: 0,
			new: 0,
			all: 0,
			mmCoats: 0,
			mmSports: 0,
			archived: 0,
		};

		allProjects.forEach((project) => {
			if (!project.isArchived) {
				if (project.endpoint === 'labeling-clothes-categorization') {
					projectsCountObj.kids++;
				} else if (project.endpoint === 'regular-preLoved-apparel') {
					projectsCountObj.regular++;
				} else if (project.endpoint === 'new-apparel') {
					projectsCountObj.new++;
				} else if (project.endpoint === 'vintage-apparel') {
					projectsCountObj.vintage++;
				} else if (project.path === 'mm-coats') {
					projectsCountObj.mmCoats++;
				} else if (project.path === 'mm-sports') {
					projectsCountObj.mmSports++;
				}
			} else {
				projectsCountObj.archived++;
			}
		});

		const isMMCoatsPath =
			allProjects[0]?.path === 'mm-coats' ||
			allProjects[0]?.path === 'mm-coats';

		projectsCountObj.all = isMMCoatsPath
			? projectsCountObj.mmCoats + projectsCountObj.mmSports
			: projectsCountObj.kids +
			  projectsCountObj.regular +
			  projectsCountObj.vintage +
			  projectsCountObj.new;

		return projectsCountObj;
	}, [allProjects]);

	useEffect(() => {
		const section = document.getElementById('main-section');
		if (!section) return;

		const handleScroll = () => {
			const { scrollHeight, scrollTop, clientHeight } = section;
			const scrolledPercentage =
				((scrollTop + clientHeight) / scrollHeight) * 100;
			// Adjust the check to be greater than or equal to 80
			if (scrolledPercentage >= 80) {
				// Perform the increment only if projectsDisplayed hasn't been incremented yet
				if (!projectsDisplayed.incrementStatus) {
					setProjectsDisplayed((prevProjectsDisplayed) => ({
						...prevProjectsDisplayed,
						incrementStatus: true,
						value: prevProjectsDisplayed.value + 20,
					}));
				}
			} else {
				// Reset the flag if the scroll percentage goes below 80%
				if (projectsDisplayed.incrementStatus) {
					setProjectsDisplayed((prevProjectsDisplayed) => ({
						...prevProjectsDisplayed,
						incrementStatus: false,
					}));
				}
			}
		};

		const scrollListener = () => handleScroll();

		section.addEventListener('scroll', scrollListener);

		return () => {
			section.removeEventListener('scroll', scrollListener);
		};
	}, []);
	

const resetProjectDisplay = () => {
	setProjectsDisplayed((prevProjectsDisplayed) => ({
		...prevProjectsDisplayed,
		value: 20,
		incrementStatus: false,
	}));
};

useEffect(() => {
	resetProjectDisplay();
}, [tabValue]);

	// Function to update projectName based on ID
	const handleProjectNameChange = (id, newName, endpoint) => {
		let currentSetterFunction;

		if (endpoint === 'labeling-clothes-categorization') {
			currentSetterFunction = setSortedClothingLabels;
		} else if (endpoint === 'regular-preLoved-apparel') {
			currentSetterFunction = setRegularPreLovedApparel;
		} else if (endpoint === 'new-apparel') {
			currentSetterFunction = setNewApparel;
		} else if (endpoint === 'vintage-apparel') {
			currentSetterFunction = setVintageApparel;
		} else if (endpoint === 'mm-coats') {
			currentSetterFunction = setMmApparel;
		} else if (endpoint === 'mm-sports') {
			currentSetterFunction = setMmSportswear;
		}

		if (currentSetterFunction) {
			currentSetterFunction((previousData) => {
				const updatedData = previousData.map((item) => {
					if (item._id === id) {
						return { ...item, projectName: newName };
					}
					return item;
				});
				return updatedData;
			});
		}
	};

	const getImagesEndpoint = async (endpoint, id) => {
		return fetch(
			`https://apie.snapwrite.ca/storeData/${endpoint}?userID=${id}`
		)
			.then((response) => response.json())
			.then((json) => {
				let number = 0;
				json.data.map((val, index) => {
					// The image for mm-table is stored in s3Images vs other clients which is images
					if (val.images && endpoint !== 'mm-table') {
						number += val.images.length;
					} else if (val.s3Images && endpoint === 'mm-table') {
						number += val.s3Images.length;
					}
				});
				return number;
			})
			.catch((err) => {
				console.log(`Error ${err}`);
			});
	};

	const getImages = async (id) => {
		const dataEndpoints = [
			'labeling-clothes-categorization',
			'regular-preLoved-apparel',
			'vintage-apparel',
			'new-apparel',
			'mm-table',
			'mm-sports',
		];

		const allImages = await Promise.all(
			dataEndpoints.map(
				async (endpoint) => await getImagesEndpoint(endpoint, id)
			)
		);

		setTotalImages(allImages.reduce((cur, sum) => cur + sum, 0));
	};


	return (

<>
<MainSideMenuHeaderContainer
name={undefined}
>
	<Heading1 title={'Projects'} />
					<div className='mb-32' id='project-view-div'>
						{/* For MM  */}
						{userID === 'manteauxmanteauxdemo@snapwrite.ca' ||
						userID === 'carmela@manteaux.com' ||
						organization?.id === 'pDDieoXBhjgkhiJnILJPw4mpb7v1' ? (
							<TabContext value={tabValue} 
							>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: 'divider',
									}}
								>
									<TabList
										onChange={handleChange}
										aria-label='Tabs'
									>
										<Tab
											className='outline-none focus:outline-none'
											label={`All`}
											value='1'
										/>
										<TabChip
											totalCount={projectCounts.mmCoats + projectCounts.mmSports + projectCounts.archived}
										/>
										<Tab
											className='outline-none focus:outline-none'
											label='Coats & Jackets'
											value='2'
										/>
										<TabChip
											totalCount={projectCounts.mmCoats}
										/>
										<Tab
											className='outline-none focus:outline-none'
											label='Sportswear Collection'
											value='3'
										/>
										<TabChip
											totalCount={projectCounts.mmSports}
										/>
										<Tab
											className='outline-none focus:outline-none'
											label='Archived'
											value='4'
										/>
										<TabChip
											totalCount={projectCounts.archived}
										/>
									</TabList>
								</Box>
								{allProjects.length > 0 ? (<div>
								<TabPanel value='1' >
									<ProjectsGroup
										projects={allProjects.slice(0, projectsDisplayed.value)}
										originalProjects={allProjects}
										setProjects={setAllProjects}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>
								<TabPanel value='2'>
									<ProjectsGroup
										projects={mmApparel.slice(0, projectsDisplayed.value)}
										originalProjects={mmApparel}
										setProjects={setMmApparel}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>
								<TabPanel value='3'>
									<ProjectsGroup
										projects={mmSportswear.slice(0, projectsDisplayed.value)}
										originalProjects={mmSportswear}
										setProjects={setMmSportswear}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>

								{/* Archived Projects  */}
								<TabPanel value='4'>
									<ProjectsGroup
										archived
										projects={allProjects}
										originalProjects={allProjects}
										setProjects={setAllProjects}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>
								</div>) : <div className='w-full h-[75dvh] flex justify-center items-center flex-col '>
									{/* // loading */}
									<CircularProgress color='inherit'/>

								</div>}
							</TabContext>
						) : (
							<TabContext value={tabValue}>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: 'divider',
									}}
								>
									{preference === 'vintage' ||
									userID ===
										'cherrypickdummy@snapwritetest.ca' ||
									userID === 'classiquegrails@gmail.com' ||
									userID === 'hello@shopcherry.ca' ||
									userID === 'impeccablevintage@gmail.com' ? (
										<>
											<TabList
												onChange={handleChange}
												aria-label='Tabs'
											>
												<Tab
													className='outline-none focus:outline-none'
													label={`All`}
													value='1'
												/>
												<TabChip
													totalCount={
														projectCounts.all
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Vintage'
													value='4'
												/>
												<TabChip
													totalCount={
														projectCounts.vintage
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Pre-Loved (Kids)'
													value='2'
												/>
												<TabChip
													totalCount={
														projectCounts.kids
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Pre-Loved (Adults)'
													value='3'
												/>
												<TabChip
													totalCount={
														projectCounts.regular
													}
												/>

												<Tab
													className='outline-none focus:outline-none'
													label='New'
													value='5'
												/>
												<TabChip
													totalCount={
														projectCounts.new
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Archived'
													value='6'
												/>
												<TabChip
													totalCount={
														projectCounts.archived
													}
												/>
											</TabList>
										</>
									) : (
										<>
											<TabList
												onChange={handleChange}
												aria-label='Tabs'
											>
												<Tab
													className='outline-none focus:outline-none'
													label={`All`}
													value='1'
												/>
												<TabChip
													totalCount={
														projectCounts.all
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Pre-Loved (Kids)'
													value='2'
												/>
												<TabChip
													totalCount={
														projectCounts.kids
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Pre-Loved (Adults)'
													value='3'
												/>
												<TabChip
													totalCount={
														projectCounts.regular
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Vintage'
													value='4'
												/>
												<TabChip
													totalCount={
														projectCounts.vintage
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='New'
													value='5'
												/>
												<TabChip
													totalCount={
														projectCounts.new
													}
												/>
												<Tab
													className='outline-none focus:outline-none'
													label='Archived'
													value='6'
												/>
												<TabChip
													totalCount={
														projectCounts.archived
													}
												/>
											</TabList>
										</>
									)}
								</Box>
								{allProjects.length > 0 ? (<div>
								{/* All View  */}
								<TabPanel value='1'>
									<ProjectsGroup
										projects={allProjects.slice(0, projectsDisplayed.value)}
										originalProjects = {allProjects}
										setProjects={setAllProjects}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>

								{/* Kids  */}

								<TabPanel value='2'>
									<ProjectsGroup
										projects={sortedClothingLabels.slice(0, projectsDisplayed.value)}
										originalProjects = {sortedClothingLabels}
										setProjects={setSortedClothingLabels}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>
								{/* Adult  */}
								<TabPanel value='3'>
									<ProjectsGroup
										projects={regularPreLovedApparel.slice(0, projectsDisplayed.value)}
										originalProjects = {regularPreLovedApparel}
										setProjects={setRegularPreLovedApparel}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>

								{/* Vintage  */}
								<TabPanel value='4'>
									<ProjectsGroup
										projects={vintageApparel.slice(0, projectsDisplayed.value)}
										originalProjects = {vintageApparel}
										setProjects={setVintageApparel}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>

								{/* New  */}
								<TabPanel value='5'>
									<ProjectsGroup
										projects={newApparel.slice(0, projectsDisplayed.value)}
										originalProjects = {newApparel}
										setProjects={setNewApparel}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>

								{/* Archived Items  */}

								<TabPanel value='6'>
									<ProjectsGroup
										archived
										projects={allProjects}
										originalProjects = {allProjects}
										setProjects={setAllProjects}
										handleProjectNameChange={
											handleProjectNameChange
										}
									/>
								</TabPanel>
								</div> ) : (
									<div className='w-full h-[75dvh] flex justify-center items-center flex-col '>
									{/* // loading */}
									<CircularProgress color='inherit'/>

								</div>
								)}
							</TabContext>
						)}
					</div>
		
</MainSideMenuHeaderContainer>
</>
	);
};

function TabChip({ totalCount }) {
	return (
		<p className='mt-2 ml-[2px] mr-4 bg-slate-100 text-sm rounded-full w-8 h-8 text-center flex items-center justify-center text-slate-600 '>
			{totalCount}
		</p>
	);
}
export default Projects;
