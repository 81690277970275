import updateColumnValue from "./updateAttribute";
// Delete a row from the table
const handleDelete = (indexOfRow, props) => {
	 
  //Image

  props.setS3Images(
    props.s3Images.filter((image, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "images",
    props.s3Images.filter((image, index) => index !== indexOfRow),
    props.objectID
  );

  props.setBackImage(
    props.backImage.filter((backImage, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "backImage",
    props.backImage.filter((backImage, index) => index !== indexOfRow),
    props.objectID
  );

  props.setSupplementaryImages(
    props.supplementaryImages.filter(
      (supplementaryImages, index) => index !== indexOfRow
    )
  );
  updateColumnValue(
    props.template,
    "supplementaryImages",
    props.supplementaryImages.filter(
      (supplementaryImages, index) => index !== indexOfRow
    ),
    props.objectID
  );

  // Item Names

  props.setImageFileName(
    props.imageFileName.filter((file, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "fileNames",
    props.imageFileName.filter((file, index) => index !== indexOfRow),
    props.objectID
  );

  props.setItemName(
    props.itemName.filter((item, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "itemName",
    props.itemName.filter((itemName, index) => index !== indexOfRow),
    props.objectID
  );

  // Item Type
  props.setType(props.types.filter((typeName, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "types",
    props.types.filter((typeName, index) => index !== indexOfRow),
    props.objectID
  );

  props.setTypesTaxoUpdate(
    props.typesTaxoUpdate.filter((taxoName, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "typesTaxoUpdate",
    props.typesTaxoUpdate.filter((taxoName, index) => index !== indexOfRow),
    props.objectID
  );

  props.setItemGenericType(
    props.itemGenericType.filter((genericType, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "itemGenericType",
    props.itemGenericType.filter((genericType, index) => index !== indexOfRow),
    props.objectID
  );

  // brand
  props.setBrand(props.brand.filter((brand, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "brand",
    props.brand.filter((brand, index) => index !== indexOfRow),
    props.objectID
  );

  // sku
  props.setSku(props.sku.filter((sku, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "sku",
    props.sku.filter((sku, index) => index !== indexOfRow),
    props.objectID
  );

  props.setVendor(props.vendor.filter((vendor, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "vendor",
    props.vendor.filter((vendor, index) => index !== indexOfRow),
    props.objectID
  );

  // description
  props.setDescription(
    props.description.filter((itemInfo, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "description",
    props.description.filter((itemInfo, index) => index !== indexOfRow),
    props.objectID
  );

  // Size

  props.setSize(props.size.filter((sizeName, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "size",
    props.size.filter((sizeName, index) => index !== indexOfRow),
    props.objectID
  );

  props.setSizeNotes(
    props.sizeNotes.filter((sizeName, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "sizeNotes",
    props.sizeNotes.filter((sizeName, index) => index !== indexOfRow),
    props.objectID
  );

  // Condition

  props.setCondition(
    props.condition.filter((conditionValue, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "condition",
    props.condition.filter((conditionValue, index) => index !== indexOfRow),
    props.objectID
  );

  props.setConditionDescription(
    props.conditionDescription.filter(
      (conditionDescription, index) => index !== indexOfRow
    )
  );
  updateColumnValue(
    props.template,
    "conditionDescription",
    props.conditionDescription.filter(
      (conditionDescription, index) => index !== indexOfRow
    ),
    props.objectID
  );

  //Price and Cost

  props.setCostPerItem(
    props.costPerItem.filter((cost, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "cost",
    props.costPerItem.filter((cost, index) => index !== indexOfRow),
    props.objectID
  );

  props.setPrice(props.price.filter((priceNum, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "price",
    props.price.filter((priceNum, index) => index !== indexOfRow),
    props.objectID
  );

  props.setRetailPrice(
    props.retailPrice.filter((retailPrice, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "retailPrice",
    props.retailPrice.filter((retailPrice, index) => index !== indexOfRow),
    props.objectID
  );
/** ****************************************************************************************************************** */
  props.setGender(
    props.genders.filter((gender, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "genders",
    props.genders.filter((gender, index) => index !== indexOfRow),
    props.objectID
  );

  props.setColor(props.colors.filter((color, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "colors",
    props.colors.filter((color, index) => index !== indexOfRow),
    props.objectID
  );

  props.setVariantGrams(
    props.variantGrams.filter((grams, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "variantGrams",
    props.variantGrams.filter((grams, index) => index !== indexOfRow),
    props.objectID
  );

  props.setTags(props.tags.filter((tags, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "tags",
    props.tags.filter((tags, index) => index !== indexOfRow),
    props.objectID
  );

  //Attributes

  props.setMaterial(
    props.material.filter((material, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "material",
    props.material.filter((material, index) => index !== indexOfRow),
    props.objectID
  );

  props.setBelt(props.belt.filter((belt, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "belt",
    props.belt.filter((belt, index) => index !== indexOfRow),
    props.objectID
  );

  props.setLength(props.length.filter((length, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "length",
    props.length.filter((length, index) => index !== indexOfRow),
    props.objectID
  );

  props.setStyle(props.style.filter((style, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "style",
    props.style.filter((style, index) => index !== indexOfRow),
    props.objectID
  );

  props.setAge(props.age.filter((age, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "age",
    props.age.filter((age, index) => index !== indexOfRow),
    props.objectID
  );

  props.setClosure(
    props.closure.filter((closure, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "closure",
    props.closure.filter((closure, index) => index !== indexOfRow),
    props.objectID
  );

  props.setFastening(
    props.fastening.filter((fastening, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "fastening",
    props.fastening.filter((fastening, index) => index !== indexOfRow),
    props.objectID
  );

  props.setFasteningType(
    props.fasteningType.filter((fastening_type, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "fasteningType",
    props.fasteningType.filter((fastening_type, index) => index !== indexOfRow),
    props.objectID
  );

  props.setSleeves(
    props.sleeves.filter((sleeves, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "sleeves",
    props.sleeves.filter((sleeves, index) => index !== indexOfRow),
    props.objectID
  );

  props.setNeckline(
    props.neckline.filter((neckline, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "neckline",
    props.neckline.filter((neckline, index) => index !== indexOfRow),
    props.objectID
  );

  props.setCut(props.cut.filter((cut, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "cut",
    props.cut.filter((cut, index) => index !== indexOfRow),
    props.objectID
  );

  props.setHood(props.hood.filter((hood, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "hood",
    props.hood.filter((hood, index) => index !== indexOfRow),
    props.objectID
  );

  props.setTypeObj(
    props.typeObj.filter((typeObj, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "typeObj",
    props.typeObj.filter((typeObj, index) => index !== indexOfRow),
    props.objectID
  );

  props.setDesign(props.design.filter((design, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "design",
    props.design.filter((design, index) => index !== indexOfRow),
    props.objectID
  );

  props.setLining(props.lining.filter((lining, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "lining",
    props.lining.filter((lining, index) => index !== indexOfRow),
    props.objectID
  );

  props.setLayers(props.layers.filter((layers, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "layers",
    props.layers.filter((layers, index) => index !== indexOfRow),
    props.objectID
  );

  props.setPattern(
    props.pattern.filter((pattern, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "pattern",
    props.pattern.filter((pattern, index) => index !== indexOfRow),
    props.objectID
  );

  props.setSkirt(props.skirt.filter((skirt, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "skirt",
    props.skirt.filter((skirt, index) => index !== indexOfRow),
    props.objectID
  );

  props.setRise(props.rise.filter((rise, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "rise",
    props.rise.filter((rise, index) => index !== indexOfRow),
    props.objectID
  );

  props.setHeelHeight(
    props.heelHeight.filter((heelHeight, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "heelHeight",
    props.heelHeight.filter((heelHeight, index) => index !== indexOfRow),
    props.objectID
  );

  props.setHeelShape(
    props.heelShape.filter((heelShape, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "heelShape",
    props.heelShape.filter((heelShape, index) => index !== indexOfRow),
    props.objectID
  );

  props.setHeight(props.height.filter((height, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "height",
    props.height.filter((height, index) => index !== indexOfRow),
    props.objectID
  );

  props.setToe(props.toe.filter((toe, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "toe",
    props.toe.filter((toe, index) => index !== indexOfRow),
    props.objectID
  );

  // Vintage
  props.setEra(props.era.filter((era, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "era",
    props.era.filter((era, index) => index !== indexOfRow),
    props.objectID
  );

  props.setMadeIn(props.madeIn.filter((era, index) => index !== indexOfRow));
  updateColumnValue(
    props.template,
    "madeIn",
    props.madeIn.filter((madeIn, index) => index !== indexOfRow),
    props.objectID
  );

  // Measurements
  props.setPitToPitMeasure(
    props.pitToPitMeasure.filter(
      (pitToPitMeasure, index) => index !== indexOfRow
    )
  );
  updateColumnValue(
    props.template,
    "pitToPitMeasure",
    props.pitToPitMeasure.filter(
      (pitToPitMeasure, index) => index !== indexOfRow
    ),
    props.objectID
  );

  props.setShoulderMeasure(
    props.shoulderMeasure.filter(
      (shoulderMeasure, index) => index !== indexOfRow
    )
  );
  updateColumnValue(
    props.template,
    "shoulderMeasure",
    props.shoulderMeasure.filter(
      (shoulderMeasure, index) => index !== indexOfRow
    ),
    props.objectID
  );

  props.setsleeveInSeam(
    props.sleeveInSeam.filter((sleeveInSeam, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "sleeveInSeam",
    props.sleeveInSeam.filter((sleeveInSeam, index) => index !== indexOfRow),
    props.objectID
  );

  props.setLengthMeasure(
    props.lengthMeasure.filter((lengthMeasure, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "lengthMeasure",
    props.lengthMeasure.filter((lengthMeasure, index) => index !== indexOfRow),
    props.objectID
  );

  props.setWaistMeasure(
    props.waistMeasure.filter((waistMeasure, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "waistMeasure",
    props.waistMeasure.filter((waistMeasure, index) => index !== indexOfRow),
    props.objectID
  );

  props.setHipMeasure(
    props.hipMeasure.filter((hipMeasure, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "hipMeasure",
    props.hipMeasure.filter((hipMeasure, index) => index !== indexOfRow),
    props.objectID
  );

  props.setBottomInSeam(
    props.bottomInSeam.filter((bottomInSeam, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "bottomMeasure",
    props.bottomInSeam.filter((bottomInSeam, index) => index !== indexOfRow),
    props.objectID
  );

  props.setRiseMeasure(
    props.riseMeasure.filter((riseMeasure, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "riseMeasure",
    props.riseMeasure.filter((riseMeasure, index) => index !== indexOfRow),
    props.objectID
  );

  props.setThighMeasure(
    props.thighMeasure.filter((thighMeasure, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "thighMeasure",
    props.thighMeasure.filter((thighMeasure, index) => index !== indexOfRow),
    props.objectID
  );

  props.setLegOpening(
    props.legOpening.filter((legOpening, index) => index !== indexOfRow)
  );
  updateColumnValue(
    props.template,
    "legOpening",
    props.legOpening.filter((legOpening, index) => index !== indexOfRow),
    props.objectID
  );

  props.setAdditionalInfo(
    props.additionalInfos.filter((info, index) => index !== indexOfRow)
  ); //deprecated
};

const handleDeleteIndividual = (indexOfRow, props) => {
	const deleteRow = true;
	const placeholder = false; // updateColumnValue requres some arguments that are not necessary for deleting
  
  updateColumnValue(
    props.template,
		placeholder,
		placeholder,
    props.objectID,
		indexOfRow,
		placeholder,
		deleteRow
  );
	return;
};

export {handleDeleteIndividual};

export default handleDelete;
