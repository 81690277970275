const fit = [
    "Style",
    "Cut",
    "Shape",
    "Design",
    "Silhouette",
    "Tailoring",
    "Model",
    "Form",
    "Pattern",
    "fit"
  ];
  
  const cropped = [
    "Shortened",
    "Trimmed",
    "Cut",
    "Snipped",
    "Reduced",
    "Clipped",
    "Chopped",
    "cropped"
  ];
  
  const relaxed = [
    "Roomy",
    "Comfortable",
    "Loose",
    "Laid-back",
    "Comfort",
    "Casual",
    "relaxed"
  ];
  
  const slim = [
    "Fitted",
    "Tailored",
    "Close",
    "Form",
    "Body",
    "Sleek",
    "Contoured",
    "Trim",
    "slim"
  ];
  
  const straight = [
    "Baggy",
    "Boyfriend",
    "Uncut",
    "Unconoured",
    "Linear",
    "Undeviating",
    "Level",
    "Even",
    "straight"
  ];
  
  const loose = [
    "Relaxed",
    "Relax",
    "Roomy",
    "Baggy",
    "Oversized",
    "Lax",
    "Ample",
    "Generous",
    "loose"
  ];
  
  const oversized = [
    "Large",
    "Big",
    "Bulky",
    "Huge",
    "Roomy",
    "oversized"
  ];

  const synonymsArray = [fit, cropped, relaxed, straight, slim, loose, oversized]

export const getFitSynonyms = (fit) => {
    let toReturn = []
    for (const synonymList of synonymsArray) {
        if (synonymList.some(synonym => fit.toLowerCase().includes(synonym.toLowerCase()))) {
            toReturn = [...toReturn, synonymList];
        }
    }
    return toReturn;
} 